import { AnyAction, Dispatch } from 'redux';
import { IPromotedExperiments } from '../models/promotedExperiments/PromotedExperiments';
import { getPromotedExperiments, putPromotedExperiments } from '../api/promotedExperimentsApi';
import {
    LOADED_PROMOTED_EXPERIMENTS,
    LOADING_PROMOTED_EXPERIMENTS,
    ERROR_LOADING_PROMOTED_EXPERIMENTS,
    UPDATED_PROMOTED_EXPERIMENTS,
    UPDATING_PROMOTED_EXPERIMENTS,
    ERROR_UPDATING_PROMOTED_EXPERIMENTS
} from '../constants/promotedExperimentsActionTypes';

export const updatingPromotedExperiments: AnyAction = {
    type: UPDATING_PROMOTED_EXPERIMENTS
};

export const updatedPromotedExperiments: AnyAction = {
    type: UPDATED_PROMOTED_EXPERIMENTS
};

export const loadingPromotedExperiments: AnyAction = {
    type: LOADING_PROMOTED_EXPERIMENTS
};

export const loadedPromotedExperiments = (promotedExperiments?: IPromotedExperiments): AnyAction => {
    return {
        type: LOADED_PROMOTED_EXPERIMENTS,
        promotedExperiments
    };
};

export const errorLoadingPromotedExperiments = (error: Error): AnyAction => {
    return {
        type: ERROR_LOADING_PROMOTED_EXPERIMENTS,
        error
    };
};

export const errorUpdatingPromotedExperiments = (error: Error): AnyAction => {
    return {
        type: ERROR_UPDATING_PROMOTED_EXPERIMENTS,
        error
    };
};

export const updatePromotedExperimentsAction = (promotedExperiments: IPromotedExperiments) => async (dispatch: Dispatch) => {
    dispatch(updatingPromotedExperiments);

    try {
        await putPromotedExperiments(promotedExperiments);
        dispatch(updatedPromotedExperiments);
    } catch (error) {
        dispatch(errorUpdatingPromotedExperiments(error as Error));
    }
};

export const getPromotedExperimentsAction = () => async (dispatch: Dispatch) => {
    dispatch(loadingPromotedExperiments);

    try {
        const promotedExperiments = await getPromotedExperiments();
        dispatch(loadedPromotedExperiments(promotedExperiments));
    } catch (error) {
        dispatch(errorLoadingPromotedExperiments(error as Error));
    }
};
