import * as React from 'react';
import { ColumnLayout, FormField, Input, RadioGroup } from '@amzn/awsui-components-react';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { RequiredField } from './DescriptionAnnotations';
import { CardActionType, IMobileHomeCardAction, EMPTY_MOBILE_CARD_ACTION } from '../../models/mobileHomeCard/MobileHomeCardAction';
import { IMobileHomeCardConstraint } from '../../models/mobileHomeCard/MobileHomeCardConstraint';
import { IosDevice, AndroidDevice } from '../../constants/mobileHomeCardRelatedConstant';
import { IMobileHomeCardContent } from '../../models/mobileHomeCard/MobileHomeCardContent';

interface MobileHomeCardActionComponent {
    index: number;
    mobileCardActions: IMobileHomeCardAction[];
    shouldDisableInput: boolean;
    mobileHomeCardContent?: IMobileHomeCardContent;
    isEditing?: boolean;
    updateMobileCardActions: (input: IMobileHomeCardAction[]) => void;
    charLimitExceed?: boolean;
    mobileHomeCardConstraint?: IMobileHomeCardConstraint;
    onCardActionAndCharLimitCheckedUpdate?: (cardActionChecked?: boolean, charLimitChecked?: boolean) => void;
    disableNextButton?: (cardActionChecked?: boolean, charLimitChecked?: boolean, onCardActionAndCharLimitCheckedUpdate?: (cardActionChecked?: boolean, charLimitChecked?: boolean) => void) => void;
}

export const MobileHomeCardActionComponent = (props: MobileHomeCardActionComponent) => {
    const { index, mobileCardActions, shouldDisableInput, mobileHomeCardContent, updateMobileCardActions, charLimitExceed, mobileHomeCardConstraint, onCardActionAndCharLimitCheckedUpdate,
        disableNextButton } = props;

    const mobileCardActionsCopy: IMobileHomeCardAction[] = ExperienceUpdateCandidate.deepClone(mobileCardActions);

    const emptyCardAction = ExperienceUpdateCandidate.deepClone(EMPTY_MOBILE_CARD_ACTION);

    const switchToDeepLink = mobileCardActions[index].actionType !== 'DeepLinkAction';

    const targetDevice: string[] = mobileHomeCardConstraint?.targetDevice || [];

    const templateType = mobileHomeCardContent?.templateType;

    return <div>
        <div className='awsui-row'>
            <div className='col-10'>
                <ColumnLayout columns={2}>
                    <div className='awsui-util-mb-m'> Where is the customer taken to after tap? </div>

                    <div data-awsui-column-layout-root='true'>
                        <RadioGroup
                            id='experience.mobile-card-action-type'
                            className='awsui-util-mb-l'
                            value={mobileCardActions[index].actionType}
                            items={getCardActionOptions(shouldDisableInput)}
                            onChange={(e) => {
                                mobileCardActionsCopy[index].actionType = e.detail.value as CardActionType;
                                if (disableNextButton && switchToDeepLink && e.detail.value === 'DeepLinkAction') {
                                    disableNextButton(false, charLimitExceed, onCardActionAndCharLimitCheckedUpdate);
                                } else if (disableNextButton && !switchToDeepLink && e.detail.value !== 'DeepLinkAction') {
                                    disableNextButton(false, charLimitExceed, onCardActionAndCharLimitCheckedUpdate);
                                }
                                updateMobileCardActions(mobileCardActionsCopy);
                            }}
                        >
                        </RadioGroup>

                        {mobileCardActions[index].actionType === 'OpenUrlAction' &&
                            <div className='awsui-util-mb-m'>
                                <FormField
                                    label={<RequiredField fieldName='Url' />}
                                    description='e.g. https://www.amazon.com'>
                                    <Input
                                        id={`experience.mobile-card-action-url-${index}`}
                                        controlId={`experience.mobile-card-action-url-${index}`}
                                        readonly={shouldDisableInput}
                                        value={mobileCardActions[index].url}
                                        onChange={(e) => {
                                            emptyCardAction.actionType = mobileCardActionsCopy[index].actionType;
                                            emptyCardAction.url = e.detail.value.trim();
                                            mobileCardActionsCopy[index] = emptyCardAction;
                                            updateMobileCardActions(mobileCardActionsCopy);
                                        }}
                                    />
                                </FormField>
                            </div>}

                        {mobileCardActions[index].actionType === 'NavigateAction' &&
                            <div className='awsui-util-mb-m'>
                                <FormField
                                    label={<RequiredField fieldName='Route' />}
                                    description='e.g. v2/hunches'>
                                    <Input
                                        id={`experience.mobile-card-action-route-${index}`}
                                        controlId={`experience.mobile-card-action-route-${index}`}
                                        readonly={shouldDisableInput}
                                        value={mobileCardActions[index].route}
                                        onChange={(e) => {
                                            emptyCardAction.actionType = mobileCardActionsCopy[index].actionType;
                                            emptyCardAction.route = e.detail.value.trim();
                                            mobileCardActionsCopy[index] = emptyCardAction;
                                            updateMobileCardActions(mobileCardActionsCopy);
                                        }}
                                    />
                                </FormField>
                            </div>}

                        {mobileCardActions[index].actionType === 'SettingsAction' &&
                            <div className='awsui-util-mb-m'>
                                <FormField
                                    label={<RequiredField fieldName='Android Setting' />}
                                    description='e.g. android.settings.SETTINGS'>
                                    <Input
                                        id={`experience.mobile-card-action-androidSetting-${index}`}
                                        controlId={`experience.mobile-card-action-androidSetting-${index}`}
                                        readonly={shouldDisableInput || templateType === 'DiscoveryTemplate'}
                                        value={mobileCardActions[index].androidSettingsUrl}
                                        onChange={(e) => {
                                            emptyCardAction.actionType = mobileCardActionsCopy[index].actionType;
                                            emptyCardAction.androidSettingsUrl = e.detail.value.trim();
                                            mobileCardActionsCopy[index] = emptyCardAction;
                                            updateMobileCardActions(mobileCardActionsCopy);
                                        }}
                                    />
                                </FormField>
                            </div>}

                        {mobileCardActions[index].actionType === 'DeepLinkAction' &&
                            <div>
                                <div className='awsui-util-mb-m'>
                                    <FormField
                                        label={<RequiredField fieldName='Deep Link URL' />}
                                        description='e.g. https://www.amazon.com'>
                                        <Input
                                            id={`experience.mobile-card-action-deepLinkUrl-${index}`}
                                            controlId={`experience.mobile-card-action-deepLinkUrl-${index}`}
                                            readonly={shouldDisableInput}
                                            value={mobileCardActions[index].deepLinkUrl}
                                            onChange={(e) => {
                                                emptyCardAction.actionType = mobileCardActionsCopy[index].actionType;
                                                emptyCardAction.deepLinkUrl = e.detail.value.trim();
                                                mobileCardActionsCopy[index] = emptyCardAction;
                                                updateMobileCardActions(mobileCardActionsCopy);
                                            }}
                                        />
                                    </FormField>
                                </div>

                                <div>
                                    <FormField
                                        label={<h3>Fallback URLs</h3>}
                                        description={<span>
                                            Fallback URLs are used when the customer's mobile device does not have the necessary App installed to execute the provided Deep Link URL.
                                            <br /><br /></span>}
                                    >
                                    </FormField>
                                </div>

                                {targetDevice.includes(IosDevice) ?
                                    <>
                                        <div className='awsui-util-mb-m'>
                                            <FormField
                                                label={<RequiredField fieldName='App Store Name' />}
                                                description='App name in App Store.'>
                                                <Input
                                                    id={`experience.mobile-card-action-appStoreName-${index}`}
                                                    controlId={`experience.mobile-card-action-appStoreName-${index}`}
                                                    readonly={shouldDisableInput}
                                                    value={mobileCardActions[index].appStoreName}
                                                    onChange={(e) => {
                                                        mobileCardActionsCopy[index].appStoreName = e.detail.value.trim();
                                                        updateMobileCardActions(mobileCardActionsCopy);
                                                    }}
                                                />
                                            </FormField>
                                        </div>

                                        <div className='awsui-util-mb-m'>
                                            <FormField
                                                label={<RequiredField fieldName='App Store URL' />}
                                                description='Fallback URL that directs user to the Apple App Store.'>
                                                <Input
                                                    id={`experience.mobile-card-action-appStoreUrl-${index}`}
                                                    controlId={`experience.mobile-card-action-appStoreUrl-${index}`}
                                                    readonly={shouldDisableInput}
                                                    value={mobileCardActions[index].appStoreUrl}
                                                    onChange={(e) => {
                                                        mobileCardActionsCopy[index].appStoreUrl = e.detail.value.trim();
                                                        updateMobileCardActions(mobileCardActionsCopy);
                                                    }}
                                                />
                                            </FormField>
                                        </div>
                                    </> : null
                                }

                                {targetDevice.includes(AndroidDevice) ?
                                    <>
                                        <div className='awsui-util-mb-m'>
                                            <FormField
                                                label={<RequiredField fieldName='Play Store Name' />}
                                                description='App name in Google Play Store.'>
                                                <Input
                                                    id={`experience.mobile-card-action-playStoreName-${index}`}
                                                    controlId={`experience.mobile-card-action-playStoreName-${index}`}
                                                    readonly={shouldDisableInput}
                                                    value={mobileCardActions[index].playStoreName}
                                                    onChange={(e) => {
                                                        mobileCardActionsCopy[index].playStoreName = e.detail.value.trim();
                                                        updateMobileCardActions(mobileCardActionsCopy);
                                                    }}
                                                />
                                            </FormField>
                                        </div>

                                        <div className='awsui-util-mb-m'>
                                            <FormField
                                                label={<RequiredField fieldName='Play Store URL' />}
                                                description='Fallback URL that directs user to the Google Play Store.'>
                                                <Input
                                                    id={`experience.mobile-card-action-playStoreUrl-${index}`}
                                                    controlId={`experience.mobile-card-action-playStoreUrl-${index}`}
                                                    readonly={shouldDisableInput}
                                                    value={mobileCardActions[index].playStoreUrl}
                                                    onChange={(e) => {
                                                        mobileCardActionsCopy[index].playStoreUrl = e.detail.value.trim();
                                                        updateMobileCardActions(mobileCardActionsCopy);
                                                    }}
                                                />
                                            </FormField>
                                        </div>
                                    </> : null}
                            </div>}
                    </div>
                </ColumnLayout>
            </div>
        </div>
    </div>;
};

const getCardActionOptions = (shouldDisableInput: boolean) => {
    const cardActionOptions = [{
        controlId: 'experience.open-url-action',
        value: 'OpenUrlAction',
        label: 'The web browser in the Alexa app',
        disabled: shouldDisableInput,
    }, {
        controlId: 'experience.navigate-action',
        value: 'NavigateAction',
        label: 'Another screen in the Alexa app',
        disabled: shouldDisableInput,
    }, {
        controlId: 'experience.settings-action',
        value: 'SettingsAction',
        label: "The phone's settings",
        disabled: shouldDisableInput,
    }, {
        controlId: 'experience.deep-link-action',
        value: 'DeepLinkAction',
        label: 'Outside of the Alexa app',
        disabled: shouldDisableInput,
    }];
    return cardActionOptions;
};
