import * as React from 'react';
import _ from 'lodash';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { FormSectionView } from '../../components/common/FormSectionView';
import { FormField, Alert, Checkbox, Spinner, Modal, Button } from '@amzn/awsui-components-react';
import { LinkedHinty, LinkedOdysseyWiki } from '../../components/common/LinksRenderHelper';
import { ExperienceUpdateCandidate, IImpressionTrackingViewAttributes } from '../../models/ExperienceUpdateCandidate';
import { connect } from 'react-redux';
import { AppState } from '../../reducers/index';
import { enableEditingAction, pushImpressionTrackingSectionAction, toggleCapabilitySearchModalVisibility } from '../../actions/experienceEditViewActions';
import { getRole } from '../../util/stringAndMappingHelper';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { InputWrapper } from '../../components/common/InputWrapper';
import { CommonValidator } from '../../util/CommonValidator';
import { ITemplateVariable } from '../../models/TemplateVariable';

interface IImpressionTrackingSectionViewProps {
    dispatch: any;
    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}
interface IContentVariablesSectionViewState {
    templateVariables?: ITemplateVariable[];
    launchButtonModalVisible: boolean;
    switchToCapabilityVisible: boolean;
    webLabInUse: boolean;
}

export class ImpressionTrackingSectionView extends React.Component<IImpressionTrackingSectionViewProps, IContentVariablesSectionViewState> {
    constructor(props: IImpressionTrackingSectionViewProps) {
        super(props);
        const { updateCandidate } = this.props;
        this.state = {
            templateVariables: updateCandidate?.getTemplateVariables(),
            launchButtonModalVisible: false,
            switchToCapabilityVisible: false,
            webLabInUse: false
        };
    }

    updateImpressionTracking(weblab?: string, weblabLaunched?: boolean, hintId?: string, contentId?: string) {
        const { dispatch } = this.props;

        dispatch(pushImpressionTrackingSectionAction(weblab, weblabLaunched, hintId, contentId));
    }

    switchToPromotedCapability = () => {
        const { dispatch, isEditing, experience } = this.props;
        let saveOnConfirm = false;

        if (!isEditing && experience) {
            saveOnConfirm = true;
            dispatch(enableEditingAction(experience));
        }

        this.toggleCapabilityModalVisibility(saveOnConfirm);
    }

    toggleCapabilityModalVisibility = (saveOnConfirm: boolean) => {
        const { dispatch } = this.props;
        dispatch(toggleCapabilitySearchModalVisibility(saveOnConfirm));
    }

    toggleModalVisibility() {
        const { launchButtonModalVisible } = this.state;
        this.setState(prevState => {
            return {
                ...prevState,
                launchButtonModalVisible: !launchButtonModalVisible,
            };
        });
    }

    updateWebLabInUse() {
        this.setState(prevState => {
            return {
                ...prevState,
                webLabInUse: true,
            };
        });
    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate } = this.props;

        if (!experience) {
            return <FormSectionView title='Impression Tracking'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const renderData: IImpressionTrackingViewAttributes = (isEditing && updateCandidate)
            ? updateCandidate.getImpressionTracking()
            : ExperienceUpdateCandidate.extractImpressionTracking(experience);

        const shouldDisableInput = !isEditing || isUpdating;

        const weblabDialupRequirementCif = 'CIF experience dialups ' +
            'should not exceed 10% during experimentation phase.';

        const weblabDialupRequirementNotification = 'Notification experience dialups ' +
            'should not exceed 50% during experimentation phase.';

        const weblabDialupRequirement = experience.type === 'CIF'
            ? weblabDialupRequirementCif
            : weblabDialupRequirementNotification;

        const templateVariables: ITemplateVariable[] = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
            ? updateCandidate.getTemplateVariables() || []
            : experience.templateVariables || []);

        const emptyCapability = _.isEmpty(this.props.experience?.capabilityId);

        return <FormSectionView title='Impression Tracking'>
            <Alert>
                All Odyssey experiences require an experimental phase using a weblab gated
                by the Odyssey system. Please ensure your weblab is randomized by Customer
                (not Session), and has defined one Treatment group. {weblabDialupRequirement}&nbsp;
                See <LinkedOdysseyWiki /> to request an exception.
            </Alert>

            <Modal
                id='modal.launch-button'
                visible={this.state.launchButtonModalVisible}
                header='Launch'
                onDismiss={this.toggleModalVisibility.bind(this)}
            >
                There are other DVP weblab(s) associated with this experience. Please contact the experience owner and see if they want to remove the DVP weblab as well.

            </Modal>

            <FormField label={<RequiredField fieldName='Weblab' />} description='Weblab name can be found in url for weblab'>
                <InputWrapper
                    id='experience.weblab'
                    readonly={shouldDisableInput}
                    placeholder='ex. ALEXA_FA_CIF_PODCAST_COMMUTE_GENERIC_263599'
                    value={renderData.weblab}
                    onChange={(input: string) => this.updateImpressionTracking(input.trim(), undefined, undefined)}
                    validate={CommonValidator.isWeblabValid}
                />
                {getRole(experience.permissionRole) === 'ADMIN' && <Checkbox
                    id='experience.weblab-launched'
                    controlId='experience.weblab-launched'
                    {...(renderData.weblabLaunched === undefined ? {} : { checked: renderData.weblabLaunched })}
                    disabled={shouldDisableInput}
                    className='awsui-util-mt-m awsui-util-mb-xl'
                    onChange={(e) => {
                        this.updateImpressionTracking(undefined, e.detail.checked, undefined);
                        if (e.detail.checked && templateVariables && templateVariables.length > 0) {
                            for (const templateVariable of templateVariables) {
                                if (templateVariable.invocationParameters?.launchWeblab) {
                                    this.updateWebLabInUse();
                                }
                            }
                        }
                        if (e.detail.checked && this.state.webLabInUse) {
                            this.toggleModalVisibility();
                        }
                    }}
                >
                    <div>Launched</div>
                </Checkbox>}
            </FormField>

            { _.isEmpty(this.props.experience?.capabilityId) && !_.isEmpty(this.props.experience?.hintId) ?
            <>
                <Alert visible={emptyCapability} type='warning'>
                    This campaign must switch to Capability ID; Hint ID support is being deprecated.
                    <span className='awsui-util-f-r'><Button className='awsui-alert-action-button' text='Switch to Capability ID' variant='primary' onClick={this.switchToPromotedCapability} /></span>
                </Alert>
                <br></br>
                <FormField
                    label={<RequiredField fieldName='Hint ID' />}
                    description={<span>Hint ID is used for impression tracking and
                        conversion calculation purposes. See <LinkedHinty />&nbsp;
                        to find or create an appropriate hint.</span>} >
                    <InputWrapper
                        id='experience.hint-id'
                        placeholder='ex. amzn1.alexa.hint.c27b7e39-cd19-4900-9afd-79524edfa8aa'
                        value={renderData.hintId}
                        readonly={true}
                        validate={CommonValidator.isValidHintId}
                        onInput={(input: string) => {
                            this.updateImpressionTracking(undefined, undefined, input.trim());
                        }}
                    />
                </FormField>
            </> : <></>}

        </FormSectionView>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState, odysseyExperienceDetailViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || odysseyExperienceDetailViewState.experience,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(ImpressionTrackingSectionView);
