import * as React from 'react';
import { FormSectionView } from '../../common/FormSectionView';
import TestPushNotification from '../../../containers/TestPushNotification';
import TestMobileHomeCard from '../../../containers/TestMobileHomeCard';
import TestRotatingContentPanel from '../../../containers/TestRotatingContentPanel';

export const TestingExperienceSectionView = (props: {}) => {
    return <div>
        <FormSectionView title='Testing Experience' description='You can send yourself a push notification as a test'>
            <TestPushNotification />
        </FormSectionView>
    </div>;
};

export const TestingMobileHomeCardSectionView = (props: {}) => {
    return <div>
        <FormSectionView title='Testing Mobile Home Card' description='You can send yourself a mobile home card as a test'>
            <TestMobileHomeCard />
        </FormSectionView>
    </div>;
};

export const TestingRotatingContentPanelSectionView = (props: {}) => {
    return <div>
        <FormSectionView title='Testing Instructions'>
            <TestRotatingContentPanel />
        </FormSectionView>
    </div>;
};
