import { Action, Dispatch } from 'redux';

import * as AcopsGateway from '../api/acopsGateway';
import * as BullseyeGateway from '../api/bullseyeGateway';
import {
    LOADING_EXPERIENCE_DETAIL,
    LOADED_EXPERIENCE_DETAIL,
    ERROR_LOADING_EXPERIENCE_DETAIL,
    RESET_CHANGE_CONTROL_DECISION,
    UPDATE_MULTI_CLONE_EXPERIENCES,
    ERROR_UPDATE_MULTI_CLONE_EXPERIENCES,
    SUCCESS_UPDATE_MULTI_CLONE_EXPERIENCES,
    CREATING_MULTI_CLONE_EXPERIENCES
} from '../constants/experienceDetailViewActionTypes';
import { IExperience } from '../models/Experience';
import { Region } from '../util/context';
import { IBullseyeSegment } from '../models/BullseyeSegment';
import {
    LOADING_BULLSEYE_METADATA,
    LOADED_BULLSEYE_METADATA,
    ERROR_LOADING_BULLSEYE_METADATA,
    RESET_BULLSEYE_METADATA,
    ERROR_LOADING_EVENTS_METADATA,
    LOADING_EVENTS_METADATA,
    LOADED_EVENTS_METADATA,
    LOADING_EVENT_TRAIL,
    LOADED_EVENT_TRAIL,
    ERROR_LOADING_EVENT_TRAIL,
    LOADING_CHANGE_CONTROL_DECISION,
    LOADED_CHANGE_CONTROL_DECISION,
    ERROR_LOADING_CHANGE_CONTROL_DECISION
} from '../constants/experienceDetailViewActionTypes';
import { EMPTY_EXPERIENCE } from '../data/EmptyExperienceData';
import { IEvent } from '../models/events/Event';
import { EventTrailResponse } from '../models/events/EventTrail';
import {
    CONFIGURING_ALEXA_LAB_METRICS,
    CONFIGURED_ALEXA_LAB_METRICS,
    ERROR_CONFIGURE_ALEXA_LAB_METRICS,
    RESET_ALEXA_LAB_METRICS
} from '../constants/experienceDetailViewActionTypes';
import { errorUpdatingExperience, updatedExperience, updatingExperience } from './experienceEditViewActions';
import { AppState } from '../reducers';
import { treatmentToDialupStageMapping } from '../util/exposureControlAllocationHelper';
import { ExperienceUpdateCandidate, IPromotedCapability } from '../models/ExperienceUpdateCandidate';
import { IMultiCloneExperienceMetadata, transformFromMetaDataToExperiences } from '../util/MultiCloneUtils';
import { getLinkableUrl, PAGE } from '../constants/page';
import { fromSearchIdsToCompressedSearchParams } from '../models/uri/SearchParams';
import { transformExperience } from '../util/stringAndMappingHelper';

export interface ILoadExperienceAction extends Action {
    experience?: IExperience;
    error?: Error;
}

export interface IConfigureAlexaLabAction extends Action {
    alexaLabConfigResponse?: string;
    error?: Error;
}

export interface ILoadBullseyeAction extends Action {
    region?: Region;
    segmentId?: number;
    bullseye?: IBullseyeSegment;
    error?: Error;
}

export interface ILoadEventsAction extends Action {
    events?: IEvent[];
    error?: Error;
}

export interface ILoadEventTrailAction extends Action {
    events?: EventTrailResponse;
    error?: Error;
}

export interface ILoadChangeControlAction extends Action {
    message?: string;
    error?: Error;
}

export interface IMultiCloneAction extends Action {
    multiCloneExperiences?: IMultiCloneExperienceMetadata[];
    errors?: Error[];
    cloneExperienceTitle?: string;
    newExperiencesCount?: number;
}

export interface ICapabilityCatalogSearchAction extends Action {
    capabilities?: IPromotedCapability[];
    selectedCapability?: IPromotedCapability;
    error?: Error;
    saveOnConfirm?: boolean;
}

export const updateMultiCloneExperiences = (multiCloneExperiences?: IMultiCloneExperienceMetadata[]): IMultiCloneAction => {
    return {
        type: UPDATE_MULTI_CLONE_EXPERIENCES,
        multiCloneExperiences
    };
};

export const creatingMultiCloneExperiences = (): IMultiCloneAction => {
    return {
        type: CREATING_MULTI_CLONE_EXPERIENCES
    };
};

export const errorUpdateMultiCloneExperiences = (errors: Error[], cloneExperienceTitle: string, newExperiencesCount: number): IMultiCloneAction => {
    return {
        type: ERROR_UPDATE_MULTI_CLONE_EXPERIENCES,
        errors,
        cloneExperienceTitle,
        newExperiencesCount
    };
};

export const successUpdateMultiCloneExperiences = (cloneExperienceTitle: string, newExperiencesCount: number): IMultiCloneAction => {
    return {
        type: SUCCESS_UPDATE_MULTI_CLONE_EXPERIENCES,
        cloneExperienceTitle,
        newExperiencesCount
    };
};

export const loadingExperienceDetail = (): ILoadExperienceAction => {
    return {
        type: LOADING_EXPERIENCE_DETAIL
    };
};

export const loadedExperienceDetail = (experience: IExperience): ILoadExperienceAction => {
    return {
        type: LOADED_EXPERIENCE_DETAIL,
        experience
    };
};

export const errorLoadingExperienceDetail = (error: Error): ILoadExperienceAction => {
    return {
        type: ERROR_LOADING_EXPERIENCE_DETAIL,
        error
    };
};

export const loadingChangeControlDecision = (): ILoadChangeControlAction => {
    return {
        type: LOADING_CHANGE_CONTROL_DECISION
    };
};

export const loadedChangeControlDecision = (message?: string): ILoadChangeControlAction => {
    return {
        type: LOADED_CHANGE_CONTROL_DECISION,
        message
    };
};

export const errorLoadingChangeControlDecision = (error: Error): ILoadChangeControlAction => {
    return {
        type: ERROR_LOADING_CHANGE_CONTROL_DECISION,
        message: error.toString(),
        error
    };
};

export const resetChangeControlDecision = (): ILoadChangeControlAction => {
    return {
        type: RESET_CHANGE_CONTROL_DECISION
    };
};

export const loadingBullseyeMetadata = (): ILoadBullseyeAction => {
    return {
        type: LOADING_BULLSEYE_METADATA
    };
};

export const loadedBullseyeMetadata = (region: Region, segmentId: number, bullseye: IBullseyeSegment): ILoadBullseyeAction => {
    return {
        type: LOADED_BULLSEYE_METADATA,
        region,
        segmentId,
        bullseye
    };
};

export const errorLoadingBullseyeMetadata = (error: Error): ILoadBullseyeAction => {
    return {
        type: ERROR_LOADING_BULLSEYE_METADATA,
        error
    };
};

export const resetBullseyeMetadata = (): ILoadBullseyeAction => {
    return {
        type: RESET_BULLSEYE_METADATA
    };
};

export const loadingEventsMetadata = (): ILoadEventsAction => {
    return {
        type: LOADING_EVENTS_METADATA
    };
};

export const loadedEventsMetadata = (events: IEvent[]): ILoadEventsAction => {
    return {
        type: LOADED_EVENTS_METADATA,
        events
    };
};

export const errorLoadingEventsMetadata = (error: Error): ILoadEventsAction => {
    return {
        type: ERROR_LOADING_EVENTS_METADATA,
        error
    };
};

export const loadingEventTrail = (): ILoadEventTrailAction => {
    return {
        type: LOADING_EVENT_TRAIL
    };
};

export const loadedEventTrail = (events: EventTrailResponse): ILoadEventTrailAction => {
    return {
        type: LOADED_EVENT_TRAIL,
        events
    };
};

export const errorLoadingEventTrail = (error: Error): ILoadEventTrailAction => {
    return {
        type: ERROR_LOADING_EVENT_TRAIL,
        error
    };
};


export const configuringAlexaLab = (): IConfigureAlexaLabAction => {
    return {
        type: CONFIGURING_ALEXA_LAB_METRICS
    };
};

export const configuredAlexaLab = (alexaLabConfigResponse: string): IConfigureAlexaLabAction => {
    return {
        type: CONFIGURED_ALEXA_LAB_METRICS,
        alexaLabConfigResponse
    };
};

export const resetConfiguredAlexaLab = (): IConfigureAlexaLabAction => {
    return {
        type: RESET_ALEXA_LAB_METRICS
    };
};

export const errorConfiguringAlexaLab = (error: Error): IConfigureAlexaLabAction => {
    return {
        type: ERROR_CONFIGURE_ALEXA_LAB_METRICS,
        error
    };
};

export const getExperienceAction = (id: string) => async (dispatch: Dispatch) => {
    dispatch(loadingExperienceDetail());

    try {
        const data: IExperience = await AcopsGateway.getExperience(id);
        dispatch(resetChangeControlDecision());
        dispatch(loadedExperienceDetail(data));
    } catch (error) {
        dispatch(errorLoadingExperienceDetail(error as Error));
    }
};

export const getNewExperienceAction = () => async (dispatch: Dispatch) => {
    dispatch(loadingExperienceDetail());

    try {
        dispatch(resetChangeControlDecision());
        dispatch(loadedExperienceDetail(EMPTY_EXPERIENCE));
    } catch (error) {
        dispatch(errorLoadingExperienceDetail(error as Error));
    }
};

export const getBullseyeAction = (region: Region, segmentId?: number) => async (dispatch: Dispatch) => {

    // be careful that 0 is falsy
    if (segmentId === undefined) {
        return dispatch(errorLoadingBullseyeMetadata(new Error('No valid bullseye defined')));
    }

    dispatch(loadingBullseyeMetadata());

    try {
        const data: IBullseyeSegment = await BullseyeGateway.getBullseye(region, segmentId);
        dispatch(loadedBullseyeMetadata(region, segmentId, data));
    } catch (error) {
        dispatch(errorLoadingBullseyeMetadata(error as Error));
    }
};

export const getChangeControlDecisionAction = (startDate?: string, region?: Region) => async (dispatch: Dispatch) => {
    dispatch(loadingChangeControlDecision());
    try {
        const decision: string = await AcopsGateway.getChangeControlDecision(startDate, region);
        dispatch(loadedChangeControlDecision(decision));
    } catch (error) {
        dispatch(errorLoadingChangeControlDecision(error as Error));
    }
};

export const configureAlexaLabMetricsAction = (experienceId: string) => async (dispatch: Dispatch) => {
    dispatch(configuringAlexaLab());

    try {
        const response: string = await AcopsGateway.configureAlexaLabMetrics(experienceId);
        dispatch(configuredAlexaLab(response));
    } catch (error) {
        dispatch(errorConfiguringAlexaLab(error as Error));
    }
};

export const updateExposureControlAllocationAction = (treatment: number) => async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(updatingExperience());
    const reduxState = getState();

    const experience = reduxState.experienceDetailViewState.experience;
    if (!experience) {
        dispatch(errorUpdatingExperience(new Error('Experience is not ready to be updated')));
        return;
    }

    const updateCandidate = new ExperienceUpdateCandidate(ExperienceUpdateCandidate.extractExactUpdateCandidate(experience));

    const normalizedTreatment = treatment <= 1 ? treatment : 1;

    const approvedStage = experience.approvalInfo?.approvedStage;
    const lastCheckDate = experience.approvalInfo?.lastCheckDate;
    const lastDialupStage = treatmentToDialupStageMapping[Number(`${treatment}`)] === 'LIVE_HUNDRED_PERCENT' ?
        experience?.approvalInfo?.lastDialupStage : treatmentToDialupStageMapping[Number(`${treatment}`)];
    const lastDialupDate = Date.now();
    updateCandidate.setApprovalInfo({ approvedStage, lastCheckDate, lastDialupDate, lastDialupStage });
    updateCandidate.setLaunchTreatmentPercentage(normalizedTreatment);

    try {
        const userAlias = reduxState.navigationViewState.userAlias;
        await AcopsGateway.putExperience(updateCandidate.transformToExperience(userAlias));
        console.error('Did not encounter error');
        const newExperience = await AcopsGateway.getExperience(experience.id);
        dispatch(updatedExperience(experience.id, false));
        dispatch(loadedExperienceDetail(newExperience));
    } catch (error) {
        console.error('Encountered error', error);
        dispatch(errorUpdatingExperience(error as Error));
    }
};

export const createMultiCloneExperienceAction = () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    if (state.experienceDetailViewState.experience && state.multiCloneState.multiCloneExperiences) {
        const errors: Error[] = [];
        const cloneExperienceIds: string[] = [];
        const userAlias = state.navigationViewState.userAlias;
        const experiences = transformFromMetaDataToExperiences(state.experienceDetailViewState.experience, state.multiCloneState.multiCloneExperiences, userAlias);

        dispatch(updatingExperience());
        dispatch(creatingMultiCloneExperiences());

        for (const exp of experiences) {
            // remove hintId when an experience is cloned
            if (exp.type === 'CIF') {
                delete (exp as IExperience).hintId;
            }

            try {
                const returnedId = await AcopsGateway.putExperience(exp as IExperience);
                cloneExperienceIds.push(returnedId);
            } catch (error) {
                errors.push(error as Error);
            }
        }

        const compressedSearchParams = fromSearchIdsToCompressedSearchParams(cloneExperienceIds);
        const searchCriteriaString = JSON.stringify(compressedSearchParams);
        if (errors.length > 0) {
            dispatch(errorUpdateMultiCloneExperiences(errors, state.experienceDetailViewState.experience.title || '', experiences.length - errors.length));
            window.location.assign(getLinkableUrl(`${PAGE.Experiences}?search=${searchCriteriaString}`));
        } else {
            dispatch(successUpdateMultiCloneExperiences(state.experienceDetailViewState.experience.title || '', experiences.length));

            if (cloneExperienceIds.length === 1) {
                try {
                    const newExperience = await AcopsGateway.getExperience(cloneExperienceIds[0]);
                    setTimeout(() => {
                        state.experienceDetailViewState.experience = transformExperience(newExperience);
                        dispatch(updatedExperience(cloneExperienceIds[0]));
                        window.location.assign(getLinkableUrl(PAGE.Experiences) + '/' + cloneExperienceIds[0]);
                    }, 2000);
                } catch (err) {
                    dispatch(errorUpdatingExperience(err as Error));
                }
            } else {
                window.location.assign(getLinkableUrl(`${PAGE.Experiences}?search=${searchCriteriaString}`));
            }
        }
    } else {
        dispatch(errorUpdatingExperience(new Error('Bad input')));
    }
};
