import { BreadcrumbGroup, Button } from '@amzn/awsui-components-react';
import * as React from 'react';
import { getLinkableUrl, PAGE } from '../../constants/page';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../reducers';
import { disableOnboardingProviderAction, enableOnboardingProviderAction, upsertProviderConfigsAction } from '../../actions/spiProviderOnboardingActions';
import SpiProviderOnboardingSupportedSectionView from '../sections/SpiProviderOnboardingFormFieldViews/SpiProviderOnboardingSupportedSectionView';
import SpiProviderOnboardingEndpointSectionView from '../sections/SpiProviderOnboardingFormFieldViews/SpiProviderOnboardingEndpointSectionView';
import SpiProviderOnboardingSmallFieldSectionView from '../sections/SpiProviderOnboardingFormFieldViews/SpiProviderOnboardingSmallFieldSectionView';

const SpiProviderOnboardingNewProvider: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { currentProviderConfig, formValidation } = useSelector((state: AppState) => state.spiProviderOnboardingState);

    React.useEffect(() => {
        dispatch(enableOnboardingProviderAction());
    }, [dispatch]);

    const cancelButtonClickHandler = () => {
        dispatch(disableOnboardingProviderAction());
        window.location.assign(getLinkableUrl('/admin/spiProviderOnboarding'));
    };

    const createButtonClickHandler = () => {
        if (currentProviderConfig) {
            dispatch(upsertProviderConfigsAction(currentProviderConfig.providerId, currentProviderConfig));
            window.location.assign(getLinkableUrl('/admin/spiProviderOnboarding'));

        }
    };

    const isFormValid = Object.values(formValidation).every(isInvalid => !isInvalid);

    return (
        <div style={{ margin: '30px' }}>
            <div style={{ marginBottom: '20px' }}>
                <BreadcrumbGroup label='Breadcrumbs.newProvider' items={[{
                        text: 'Odyssey',
                        href: getLinkableUrl(PAGE.Home),
                    }, {
                        text: 'Admin Dashboard',
                        href: getLinkableUrl(PAGE.AdminDashboard)
                    }, {
                        text: 'SPI Providers',
                        href: getLinkableUrl(PAGE.SpiProviderOnboarding),
                    }, {
                        text: 'Onboard New Provider',
                        href: getLinkableUrl(location.pathname),
                    }]}
                />
                <div className='awsui-util-f-r'>
                    <Button
                        id='button.cancel'
                        icon='undo'
                        variant='link'
                        onClick={cancelButtonClickHandler}

                        >Cancel</Button>
                    <Button
                        id='button.create'
                        icon='upload'
                        variant='primary'
                        onClick={createButtonClickHandler}
                        disabled={!isFormValid}
                        >Create</Button>
                </div>
                <br/>
            </div>
            <SpiProviderOnboardingSmallFieldSectionView/>
            <SpiProviderOnboardingEndpointSectionView/>
            <SpiProviderOnboardingSupportedSectionView/>
        </div>

    );
};

export default SpiProviderOnboardingNewProvider;
