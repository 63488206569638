import { Button } from '@amzn/awsui-components-react';
import * as React from 'react';
import { Component } from 'react';

import { getLinkableUrl, PAGE } from '../constants/page';

export class EmptyDisplay extends Component {
    public render() {
        return <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-xs awsui-util-mb-xs'>
                <b>
                    No experience
                </b>
            </div>
            <p className='awsui-util-mb-s'>
                No experience to display
            </p>
            <div className='awsui-util-mb-m'>
                <Button href={getLinkableUrl(PAGE.NewExperience)}>Create new experience</Button>
            </div>
        </div>;
    }
}
