import * as React from 'react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { ColumnLayout, FormField, Spinner, Alert, Checkbox, Icon } from '@amzn/awsui-components-react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate, IContentViewAttributes } from '../../models/ExperienceUpdateCandidate';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { pushContentSectionAction, pushEnableVoiceCif } from '../../actions/experienceEditViewActions';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { LOCALE_TO_LANG, isRightToLeftLocale } from '../../constants/locales';
import { contentCharLimit } from '../../constants/componentConstants';
import { TextAreaWrapper } from '../../components/common/TextAreaWrapper';
import { CIFValidator } from '../../util/CIFValidator';
import { TextRenderDirection } from '../../models/htmlElementProps';
import { ExternalLink } from '../../components/common/LinkComponents';
import { isNullOrUndefined } from 'util';
import { ActionType } from '../../models/ActionType';


interface IVoiceContentSectionViewState {
    forcedLtrRendering: boolean;
    cxGuidelinesCheckbox: boolean;
}

interface IVoiceContentSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;

    onContentCXGuidelinesCheckedUpdate?: (checked?: boolean) => void;
    onEnableVoiceCifCheckedUpdate?: (checked?: boolean) => void;
}

export class VoiceContentSectionView extends React.Component<IVoiceContentSectionViewProps, IVoiceContentSectionViewState> {
    constructor(props: IVoiceContentSectionViewProps) {
        super(props);

        this.state = {
            forcedLtrRendering: false,
            cxGuidelinesCheckbox: false
        };
    }

    updateContent(contentType: 'content' | 'referral' | 'none', contentText?: string, referralQuestionContentText?: string,
        referralData?: string, rejectionReferralData?: string, actionType?: ActionType, sonarTemplateURI?: string, sonarTemplateJson?: string, sonarCampaignId?: string, checkCXGuidelines?: boolean) {
        const { dispatch } = this.props;

        const isEntireContentATemplateVariable = CIFValidator.isEntireContentATemplateVariable(contentText);

        dispatch(pushContentSectionAction(contentType, contentText, referralQuestionContentText,
            referralData, rejectionReferralData, actionType, sonarTemplateURI, sonarTemplateJson, sonarCampaignId, checkCXGuidelines, isEntireContentATemplateVariable));
    }

    shouldRenderTextRightToLeft(locale: string): boolean {
        const { forcedLtrRendering } = this.state;

        return isRightToLeftLocale(locale) && !forcedLtrRendering;
    }

    onCXGuidelinesConfirmation(checked: boolean, onContentCXGuidelinesCheckedUpdate?: (checked?: boolean) => void) {
        this.setState({ cxGuidelinesCheckbox: checked });
        if (onContentCXGuidelinesCheckedUpdate) {
            onContentCXGuidelinesCheckedUpdate(checked);
        }
    }

    onEnableVoiceCif(checked: boolean) {
        const { dispatch, onEnableVoiceCifCheckedUpdate } = this.props;

        dispatch(pushEnableVoiceCif(checked));

        if (onEnableVoiceCifCheckedUpdate) {
            onEnableVoiceCifCheckedUpdate(checked);
        }
    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate, onContentCXGuidelinesCheckedUpdate } = this.props;

        if (!experience) {
            return <FormSectionView title={'Content'}>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const contentVariables: string[] = ((isEditing && updateCandidate)
            ? updateCandidate.getTemplateVariables()
            : experience.templateVariables || []).map(v => v.variableName);

        const contentData: IContentViewAttributes = (isEditing && updateCandidate)
            ? updateCandidate.getContent()
            : ExperienceUpdateCandidate.extractContent(experience);

        const enableVoiceCif = (isEditing && updateCandidate)
            ? updateCandidate.getEnableVoiceCif() || false
            : ExperienceUpdateCandidate.extractEnableVoiceCif(experience) || false;

        const shouldDisableInput = !isEditing || isUpdating;

        const targetLocale = ((isEditing && updateCandidate)
            ? updateCandidate.getRegion().locale
            : experience.locale) || 'en_US';

        const textDirection: TextRenderDirection = this.shouldRenderTextRightToLeft(targetLocale) ? 'rtl' : 'ltr';

        return <div lang={LOCALE_TO_LANG[targetLocale]} spellCheck={true} >
            <FormSectionView
                title={
                    <div className='awsui-util-action-stripe'>
                        <div className='awsui-util-action-stripe-title'>
                            <h2 className='awsui-util-d-ib'>
                                Voice Content - <i>optional</i>
                            </h2>
                        </div>
                    </div>}
                description='Content specified below is what Alexa will say to the
                    customer when your use case is triggered.'>

                <ColumnLayout borders='horizontal'>
                    <div data-awsui-column-layout-root='true'>
                        <Checkbox
                            id={'checkbox.enable-voice-cif'}
                            controlId={'checkbox.enable-voice-cif'}
                            checked={enableVoiceCif}
                            disabled={shouldDisableInput}
                            onChange={(e) => {
                                this.onEnableVoiceCif(e.detail.checked);
                            }}
                        >
                            Enable voice CIF
                        </Checkbox>
                        {enableVoiceCif &&
                            <div>
                                {isNullOrUndefined(experience.id)
                                    ? <>
                                        <Alert type='info' className='awsui-util-mb-l'>
                                            Please read the <ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/CIF+CX+Guidelines+and+Recommendations'}
                                                >CIF CX Guidelines and Recommendations</ExternalLink> documentation.
                                            <Checkbox
                                                id={'experience.confirm-read-cx-guidelines'}
                                                controlId={'experience.confirm-read-cx-guidelines'}
                                                checked={contentData.checkCXGuidelines || false}
                                                onChange={(e) => {
                                                    this.onCXGuidelinesConfirmation(e.detail.checked, onContentCXGuidelinesCheckedUpdate);
                                                    this.updateContent('content', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, e.detail.checked);
                                                }}
                                            ><b>I confirm that I have read and will follow the CX Guidelines</b></Checkbox>
                                            <div><div><span className='awsui-util-status-negative'><Icon name='status-warning' />  This box must be checked</span></div><span /></div>
                                        </Alert>
                                    </>
                                    : <>
                                        <Alert type='info' visible={isEditing} className='awsui-util-mb-l'>
                                            Please read the <ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/CIF+CX+Guidelines+and+Recommendations'}
                                                >CIF CX Guidelines and Recommendations</ExternalLink> documentation.
                                        </Alert>
                                    </>}
                            </div>
                        }
                        {enableVoiceCif &&
                            <FormField label={<RequiredField fieldName='Content' />} description='Spoken content in SSML' >
                                <TextAreaWrapper
                                    id='experience.content'
                                    textRenderDirection={textDirection}
                                    readonly={shouldDisableInput}
                                    value={!enableVoiceCif ? '' : contentData.contentText}
                                    characterLimit={contentCharLimit}
                                    onChange={(input: string) => {
                                        this.updateContent('content', input.trim());
                                    }}
                                    validate={(utterance) => CIFValidator.isUtteranceValid(utterance, contentVariables, experience.status)} />
                            </FormField>
                        }
                    </div>
                </ColumnLayout>
            </FormSectionView>
        </div>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(VoiceContentSectionView);
