import * as React from 'react';
import { Component } from 'react';

export class EmptySuggestionsDisplay extends Component {
    public render() {
        return <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-xs awsui-util-mb-xs'>
                <b>
                    No suggestions
                </b>
            </div>
            <p className='awsui-util-mb-s'>
                No suggestions to display
            </p>
        </div>;
    }
}
