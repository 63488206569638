import * as React from 'react';
import VisualInterstitialSectionView from '../../../containers/sections/VisualInterstitialSectionView';
import VoiceContentSectionView from '../../../containers/sections/VoiceContentSectionView';
import ContentVariablesSectionView from '../../../containers/sections/ContentVariablesSectionView';

export const VisualCIFContentTabView = () => {
    return <div>
        <VisualInterstitialSectionView />
        <VoiceContentSectionView />
        <ContentVariablesSectionView />
    </div>;
};
