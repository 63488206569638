import { Stage, getCurrRegionStage } from '../util/context';

const API_ENDPOINTS_MAP: { [S in Stage]: string } = {
    Alpha: 'https://yolrc8j8xb.execute-api.us-west-2.amazonaws.com/alpha',
    Beta: 'https://x3iige4n75.execute-api.us-west-2.amazonaws.com/beta',
    Gamma: 'https://xytooxqlx0.execute-api.us-east-1.amazonaws.com/gamma',
    Prod: 'https://905yce87ne.execute-api.us-east-1.amazonaws.com/prod'
};

export const API_ENDPOINT = API_ENDPOINTS_MAP[getCurrRegionStage().stage];

export const ExperimentsPath = '/api/v1/experiments';

export const ExperimentsPaginatePath = '/api/v1/experimentsPaginate';

export const TestExperimentsPath = '/api/v1/testCifExperiment';

export const EventsPath = '/api/v1/events';

export const BullseyePath = '/api/v1/bullseye';

export const SendNotificationPath = '/api/v1/sendNotificationOverride';

export const EventTrailPath = '/api/v1/event_trail';

export const ConfigureAlexaLabMetricsPath = '/api/v1/configureAlexaLabMetrics';

export const ConfigureCifExperimentPath = '/api/v1/configureCifExperiment';

export const AlexaLabMetricsPath = '/api/v1/metrics/alexalab';

export const CXMetricsPath = '/api/v1/metrics/cx';

export const FilterMetricsPath = '/api/v1/metrics/filter';

export const GET_ODYSSEY_CONFIGURATION_PATH = '/api/v1/odyssey_configuration';

export const PUT_ODYSSEY_CONFIGURATION_PATH = '/api/v1/odyssey_configuration/update';

export const GenerateExperimentApprovalPath = '/api/v1/approvals';

export const GetSimilarExperimentsPath = '/api/v1/similar';

export const GET_PERMISSIONS = '/api/v1/checkPermission';

export const GetSuggestionsPath = '/api/v1/suggestions';

export const GetSuggestionsPaginatePath = '/api/v1/suggestions/paginate';

export const UpdateSuggestionsStatusPath = '/api/v1/suggestions/status';

export const ChangeControlDecisionPath = '/api/v1/change_control_decision';

export const SearchCapabilityCatalogPath = '/api/v1/getCapabilities';

export const GetOdysseyExperiencesPaginatePath = '/api/v1/odysseyExperiences/paginate';

export const OdysseyExperiencePath = '/api/v1/odysseyExperiences';

export const GetOdysseyExperimentPath = '/api/v1/odysseyExperience';

export const TestMobileHomeCardPath = '/api/v1/testMobileHomeCard';

export const GenerateOdysseyExperimentApprovalPath = '/api/v1/odysseyExperiences/approvals';

export const GenerateVcifExperiencesWithAccPath = '/api/v1/generateVcifExperiencesWithAcc';

export const BASE_PROVIDER_CONFIGS_PATH = '/api/v1/provider-configs';