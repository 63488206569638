import * as React from 'react';
import { Button, Modal, Input, Select } from '@amzn/awsui-components-react';

import { AppState } from '../../reducers';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { connect } from 'react-redux';
import { skipExperienceApprovalAction } from '../../actions/experienceEditViewActions';
import { generateExceptionalLaunchSelectOption, generateExceptionalSelectOption, treatmentToDialupStageMapping } from '../../util/exposureControlAllocationHelper';

interface SkipApprovalModalProps {
    dispatch: any;
    experience?: IFlattenedExperience;
    isVisible: boolean;
    isException: boolean;
    hideModal: () => void;
}

interface SkipApprovalModalState {
    confirmSkipInput?: string;
    [key: string]: any;
    requestedStatus?: string;
}

class SkipApprovalModal extends React.Component<SkipApprovalModalProps, SkipApprovalModalState> {
    private static SAFE_SKIP_SECRET_STRING = 'skip approval';
    private static INITIAL_MODAL_STATE: SkipApprovalModalState = {
        confirmSkipInput: undefined,
        requestedStatus: undefined
    };

    constructor(props: SkipApprovalModalProps) {
        super(props);
        this.state = SkipApprovalModal.INITIAL_MODAL_STATE;
    }

    updateState(object: { [key: string]: any }) {
        this.setState(object);
    }

    componentDidUpdate(prevProps: SkipApprovalModalProps) {
        // if modal becomes visible again, need to clear off all previous state to a fresh state
        if (this.props.isVisible === true && prevProps.isVisible === false) {
            this.setState(SkipApprovalModal.INITIAL_MODAL_STATE);
        }
    }

    private validateModalCompletion(): boolean {
        const { confirmSkipInput, requestedStatus } = this.state;
        const { isException } = this.props;

        if (!isException) {
            return confirmSkipInput === SkipApprovalModal.SAFE_SKIP_SECRET_STRING;
        }

        return confirmSkipInput === SkipApprovalModal.SAFE_SKIP_SECRET_STRING && requestedStatus !== undefined;
    }

    private skipApprovalButtonClickHandler() {
        const { dispatch, hideModal } = this.props;
        const { requestedStatus } = this.state;

        if (this.validateModalCompletion()) {
            hideModal();
            dispatch(skipExperienceApprovalAction(requestedStatus));
        }
    }

    private updateRequestedStatus(status: string) {
        this.setState(prevState => {
            return {
                ...prevState,
                requestedStatus: status
            };
        });
    }

    render() {
        const {
            isVisible,
            isException,
            experience,
            hideModal
        } = this.props;
        const { confirmSkipInput } = this.state;
        let { requestedStatus } = this.state;

        if (experience === undefined || experience === null) {
            return <Modal>
                <div className='awsui-util-inactive'>
                    You cannot skip approval right now. Please try again later.
                </div>
            </Modal>;
        }

        return <Modal
            id='modal.skip-approval'
            visible={isVisible}
            header='Skip Approval'
            onDismiss={hideModal.bind(this)}
            footer={
                <span className='awsui-util-f-r'>
                    <Button
                        id={isException ? 'button.exceptional-cancel-skip-approval-modal' : 'button.cancel-skip-approval-modal'}
                        variant='link'
                        onClick={hideModal.bind(this)}>
                        Cancel
                    </Button>
                    <Button
                        id={isException ? 'button.exceptional-skip-experience-in-modal-approval' : 'button.skip-experience-in-modal-approval'}
                        variant='primary'
                        icon='status-warning'
                        disabled={!this.validateModalCompletion()}
                        onClick={this.skipApprovalButtonClickHandler.bind(this)}>
                        Skip Approval for Experience
                    </Button>
                </span>
            }
        >
            <Select
                id='experience.update-exceptional-allocation'
                controlId='experience.update-exceptional-allocation'
                placeholder='Please select your desired dialup below'
                disabled={!isException}
                options={(experience.status === 'LIVE_FIFTY_PERCENT' && experience.approvalInfo?.approvedStage === 'LIVE_FIFTY_PERCENT') ?
                    generateExceptionalLaunchSelectOption() : generateExceptionalSelectOption()}
                onChange={(event) => {
                    const selectedTreatment = Number(event.detail.selectedOption.id);
                    requestedStatus = (treatmentToDialupStageMapping[selectedTreatment]);
                    this.updateRequestedStatus(requestedStatus);
                }}
            />
            <p>
                Only ADMIN should be able to perform this action.

                Please confirm that you want to perform this action by typing in&nbsp;
                <i>{SkipApprovalModal.SAFE_SKIP_SECRET_STRING}</i> in the input box below.
            </p>
            <Input
                id={isException ? 'experience.confirm-exceptional-skip-experience-approval' : 'experience.confirm-skip-experience-approval'}
                controlId={isException ? 'experience.confirm-exceptional-skip-experience-approval' : 'experience.confirm-skip-experience-approval'}
                value={confirmSkipInput}
                placeholder='Please confirm'
                onInput={e => this.updateState({ confirmSkipInput: e.detail.value })} />
        </Modal>;
    }
}

const mapStateToProps = ({ experienceDetailViewState }: AppState) => {
    return {
        experience: experienceDetailViewState.experience
    };
};

export default connect(mapStateToProps)(SkipApprovalModal);
