import { IInvocationParameters, EMPTY_INVOCATION_PARAMETERS } from './InvocationParameters';

export interface IRemoteContextualSegments {
    includeRemoteSegments: IRemoteContextualSegment[];
    excludeRemoteSegments: IRemoteContextualSegment[];
}

export interface IRemoteContextualSegment {
    name: string;
    description: string;
    invocationParameters: IInvocationParameters;
}

export const EMPTY_REMOTE_CONTEXTUAL_SEGMENTS: IRemoteContextualSegments = {
    includeRemoteSegments: [],
    excludeRemoteSegments: []
};

export const EMPTY_REMOTE_CONTEXTUAL_SEGMENT: IRemoteContextualSegment = {
    name: '',
    description: '',
    invocationParameters: EMPTY_INVOCATION_PARAMETERS
};
