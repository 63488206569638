import * as React from 'react';
import _ from 'lodash';
import { FormSectionView } from '../../components/common/FormSectionView';
import { Checkbox, ColumnLayout, FormField, Input, RadioGroup, Select, Textarea } from '@amzn/awsui-components-react';
import { AppState } from '../../reducers/index';
import { useDispatch, useSelector } from 'react-redux';
import { OptionalField, RequiredField } from '../../components/common/DescriptionAnnotations';
import InputWithCharLimit from '../../components/common/InputWithCharLimit';
import { IRotatingContentPanelContent, IRCPTouchAction } from '../../models/rotatingContentPanel/RotatingContentPanelStructure';
import { pushRotatingContentPanelTouchActions } from '../../actions/experienceEditViewActions';
import { WakeWordKey, wakeWords } from '../../util/rotatingContentPanelHelper';

interface IRCPTouchActionsSectionViewState {
    radioGroupState: RadioGroupValues;
    callActionButton: boolean;
    touchAction: IRCPTouchAction;
}

enum RadioGroupValues {
    SKILL = 'skill',
    HINT = 'hint'
}

const wakeWordOptions: Select.Option[] = [
    {id: WakeWordKey.default, description: 'Default wake word is the wake word configured on the csutomer’s device (ie. Alexa, Echo, Computer, or Amazon)', label: 'Default Wake Word'},
    {id: WakeWordKey.disney, description: 'Use "Hey Disney!" as a wake word', label: 'Hey Disney!', disabled: false}
];

// const skillConnectionPlaceholder = 'alexa://amzn1.alexa-speechlet-client.DOMAIN:Music/PlayMusic';
// const skillPayloadPlaceholder = '{"type": "PLAY","content": {"type": "RAW_SELECTION_CRITERIA",
// "criteria": [{"type": "PLAYLIST","value": "Rediscover Rihanna"},{"type": "PROVIDER","value": "AMAZON_MUSIC"}]}}';

const RCPTouchActionsSectionView: React.FC = () => {
    const { isEditing, isUpdating, updateCandidate } = useSelector((state: AppState) => ( state.experienceEditViewState ));
    const { contents } = useSelector((state: AppState) => ({ contents: _.get(state, 'odysseyExperienceDetailViewState.experience.contents[0]', {}) }));
    const dispatch = useDispatch();

    const shouldDisableInput = !isEditing || isUpdating;
    const rotatingContentPanelContent = (!shouldDisableInput) ? updateCandidate?.getRotatingContentPanelContent()
        : (contents as IRotatingContentPanelContent);

    const touchAction = _.get(rotatingContentPanelContent, 'touchActions[0]', {}) ?? {};
    const wakeWord: string | undefined = Object.keys(wakeWords).filter((key) => {
        return rotatingContentPanelContent?.textWrappingDataSource?.hintText?.startsWith(_.get(wakeWords, key));
    })[0] ?? WakeWordKey.default;

    const initialState: IRCPTouchActionsSectionViewState = {
        radioGroupState: !_.isEmpty(touchAction?.skillConnection) ? RadioGroupValues.SKILL : RadioGroupValues.HINT,
        callActionButton: !_.isEmpty(touchAction?.buttonText),
        touchAction: {
            wakeWord,
            ...touchAction
        }
    };

    const [ currentState, setState ] = React.useState(initialState);

    const onChangeHandler = (event: CustomEvent<Textarea.ChangeDetail | Input.ChangeDetail | Select.ChangeDetail>) => {
        const id = (event.target as HTMLElement).getAttribute('id')?.split('.').pop();

        let value = _.get(event.detail, 'value');
        const selected: WakeWordKey = _.get(event.detail, 'selectedId', null) ?? WakeWordKey.default;

        if (id) {
            if (id === 'hintTextPayload') {
                value = value.toLowerCase();
            }
            const newState = {...currentState, touchAction: { ...currentState.touchAction, [id]: value }};
            if (id === 'hintText') {
                newState.touchAction.hintTextPayload = newState.touchAction.hintText?.toLowerCase();
            }
            if (id === 'wakeWord') {
                newState.touchAction.wakeWord = selected;
            }
            setState(newState);
            dispatch(pushRotatingContentPanelTouchActions(newState.touchAction));
        }
    };

    const onRadioGroupChange = (event: CustomEvent<RadioGroup.ChangeDetail>) => {
        const newValue = event.detail.value as RadioGroupValues;
        if (newValue === RadioGroupValues.HINT) {
            const newState = {...currentState, radioGroupState: newValue, touchAction: { ...currentState.touchAction, skillConnection: '', skillPayload: ''}};
            setState(newState);
            dispatch(pushRotatingContentPanelTouchActions(newState.touchAction));
        } else {
            setState({...currentState, radioGroupState: newValue});
        }
    };

    const toggleActionButton = () => {
        setState({...currentState, callActionButton: !currentState.callActionButton});
    };

    const radioButtonGroupItems = [
        {
            'value': RadioGroupValues.HINT,
            'label': 'Use Hint Text Payload',
            'disabled': shouldDisableInput
        }
        // , {
        //     'value': RadioGroupValues.SKILL,
        //     'label': 'Use Skill ID',
        //     'disabled': shouldDisableInput
        // }
    ];

    return <div>
        <FormSectionView title='Touch Actions'>
            <ColumnLayout columns={3}>
                <div data-awsui-column-layout-root='true'>
                    <FormField label={<OptionalField fieldName='Wake Word' />} description='Customize the wake word.'>
                        <Select options={wakeWordOptions} onChange={onChangeHandler} id='experience.touchActions.wakeWord' disabled={shouldDisableInput}
                        selectedOption={wakeWordOptions.find((option, index, options) => option.id === currentState.touchAction.wakeWord )} />
                    </FormField>
                    <FormField label={<RequiredField fieldName='Hint Text' />} description='Do not add "Try", wakeword, or quotes; they are added automatically on save.'>
                        <InputWithCharLimit id='experience.touchActions.hintText' characterLimit={25} readonly={shouldDisableInput}
                            value={currentState.touchAction.hintText} onChange={onChangeHandler} />
                    </FormField>
                    <FormField label={<RequiredField fieldName='Hint Text Payload' />} description='Auto populated based on Hint Text. Payload dictates default click action'>
                        <Input id='experience.touchActions.hintTextPayload' readonly={shouldDisableInput}
                            value={currentState.touchAction.hintTextPayload} onChange={onChangeHandler} />
                    </FormField>
                </div>
            </ColumnLayout>
            <hr/>
            <FormField>
                <Checkbox disabled={shouldDisableInput} onChange={toggleActionButton} checked={currentState.callActionButton}
                    id='experience.touchActions.callToAction'>Use Call to Action Button</Checkbox>
            </FormField>
            <div style={{ display: currentState.callActionButton ? 'block' : 'none' }}>
                <ColumnLayout columns={2}>
                    <div data-awsui-column-layout-root='true'>
                        <FormField label={<RequiredField fieldName='Button Text' />} >
                            <InputWithCharLimit id='experience.touchActions.buttonText' characterLimit={25} readonly={shouldDisableInput}
                                value={currentState.touchAction.buttonText} onChange={onChangeHandler} />
                        </FormField>
                        <FormField label={'Button Text Payload Options'}>
                            <RadioGroup value={currentState.radioGroupState} items={radioButtonGroupItems} id='experience.touchActions.radioGrp'
                                onChange={onRadioGroupChange} />
                            {/* <FormField>
                                <Input id='experience.touchActions.skillConnection' placeholder={skillConnectionPlaceholder}
                                    value={currentState.touchAction.skillConnection} onChange={onChangeHandler}
                                readonly={shouldDisableInput || currentState.radioGroupState !== RadioGroupValues.SKILL} />
                            </FormField>
                            <FormField label={'Skill Payload'}>
                                <Textarea id='experience.touchActions.skillPayload' placeholder={skillPayloadPlaceholder}
                                    value={currentState.touchAction.skillPayload} onChange={onChangeHandler}
                                readonly={shouldDisableInput || currentState.radioGroupState !== RadioGroupValues.SKILL} />
                            </FormField> */}
                        </FormField>
                    </div>
                </ColumnLayout>
            </div>
        </FormSectionView>
    </div>;
};

export default RCPTouchActionsSectionView;
