import * as React from 'react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { ColumnLayout, FormField, Select, Spinner, Checkbox, Multiselect } from '@amzn/awsui-components-react';
import { toSelectOptions } from '../../util/stringAndMappingHelper';
import { AppState } from '../../reducers/index';
import { pushRegionSectionAction, pushRowCountryInfoAction } from '../../actions/experienceEditViewActions';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { connect } from 'react-redux';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { generateRowCountryOptions } from '../../util/rowCountryHelper';
import { LOCALE_TO_MARKETPLACE } from '../../constants/locales';
import { MARKETPLACE_TO_REGION } from '../../constants/marketplaces';

interface IRegionSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

export class RegionSectionView extends React.Component<IRegionSectionViewProps> {

    private updateRegionSection(marketplace?: string, locale?: string, enabled?: boolean) {
        const { dispatch } = this.props;
        dispatch(pushRegionSectionAction(marketplace, locale, enabled));
    }

    private updateRowCountryInfo(rowExperience?: boolean, rowCountryList?: string[]) {
        const { dispatch } = this.props;
        dispatch(pushRowCountryInfoAction(rowExperience, rowCountryList));
    }

    render () {
        const { isEditing, isUpdating, experience, updateCandidate } = this.props;
        if (!experience) {
            return <FormSectionView title='Region' description='You are required to select exactly one locale for your experience.' >
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const shouldDisableInput = experience.status === 'UNDER_REVIEW' || !isEditing || isUpdating;
        const type = experience.type;

        const marketplace = (isEditing && updateCandidate)
            ? updateCandidate.getRegion().marketplace
            : experience.marketplace;

        const locale = (isEditing && updateCandidate)
            ? updateCandidate.getRegion().locale
            : experience.locale;

        const shouldDisableMarketplaceInput = !locale || shouldDisableInput;

        const enabled = (isEditing && updateCandidate)
            ? updateCandidate.getRegion().enabled
            : experience.enabledFilter?.enabled;

        const rowEnabled = (isEditing && updateCandidate)
            ? updateCandidate.getRowCountryInfo()?.rowExperience
            : experience.rowCountryInfo?.rowExperience;

        const rowCountryList = (isEditing && updateCandidate)
            ? updateCandidate.getRowCountryInfo()?.supportedRowCountries
            : experience.rowCountryInfo?.supportedRowCountries;

        const enableMarketplace = (type === 'CIF');
        const isRcp = type === 'RotatingContentPanel';

        return <FormSectionView title='Region' description='You are required to select a locale for your experience.
            Multi-locale marketplaces not yet supported. To host your experience in multiple locales,
            use the ”clone“ button after it has been tested and approved in one locale.' >
            <ColumnLayout columns={enableMarketplace ? 3 : 2}>
                <div data-awsui-column-layout-root='true' style={{ marginBottom: '10px' }}>
                    <div style={{display: isRcp ? 'none' : 'block'}}>
                        <FormField
                            label='Enable Region'
                            description='To enable your experience in this region,
                                check this before you send for review' >
                            <Checkbox
                                id='experience.enable-region'
                                controlId='experience.enable-region'
                                disabled={shouldDisableInput}
                                checked={enabled || false}
                                onChange={(event) => {
                                    this.updateRegionSection(undefined, undefined, event.detail.checked);
                                }}
                                >Enable</Checkbox>
                        </FormField>
                    </div>
                    <div>
                        <FormField
                            label={<RequiredField fieldName='Locale' />}
                            description='The language in which the content will be produced. Please ensure that your content is localized to the newly assigned locale' >
                            <Select
                                id='experience.locale'
                                controlId='experience.locale'
                                disabled={shouldDisableInput}
                                options={toSelectOptions(Object.keys(LOCALE_TO_MARKETPLACE))}
                                selectedId={locale}
                                onChange={(event) => {
                                    const selectedLocale = event.detail.selectedId;
                                    this.updateRegionSection(LOCALE_TO_MARKETPLACE[selectedLocale], selectedLocale);
                                }}
                            />
                        </FormField>
                        {<div className='awsui-util-status-inactive'>
                            * Once your locale and marketplace are sent for review, you may not change your locale and marketplace
                        </div>}
                    </div>
                    {(enableMarketplace || isRcp) && <div>
                        <FormField
                            label={<div>Marketplace <i className='awsui-text-secondary' >(required)</i></div>}
                            description='The geographic area in which customers will be reached'>
                            <Select
                                id='experience.marketplace'
                                controlId='experience.marketplace'
                                disabled={shouldDisableMarketplaceInput}
                                options={toSelectOptions(Object.keys(MARKETPLACE_TO_REGION))}
                                selectedId={marketplace}
                                onChange={(event) => {
                                    const selectedMarketplace = event.detail.selectedId;
                                    this.updateRegionSection(selectedMarketplace);
                                }}
                            />
                        </FormField>
                    </div>}
                </div>
                {(!isRcp)
                    && <div data-awsui-column-layout-root='true'>
                        <div>
                            <FormField
                                label='Enable ROW (Rest of World)'
                                description='
                                    Check this box if you want to target ROW customers' >
                                <Checkbox
                                    id='experience.enable-row'
                                    controlId='experience.enable-row'
                                    disabled={shouldDisableInput}
                                    checked={rowEnabled || false}
                                    onChange={(event) => {
                                        this.updateRowCountryInfo(event.detail.checked, []);
                                    }}
                                    >Include ROW Customers</Checkbox>
                            </FormField>
                        </div>
                        { rowEnabled && <div>
                            <FormField label='ROW Countries' description='The ROW countries you want your experience to reach'>
                                <Multiselect
                                    id='experience.row-country-multiselect'
                                    controlId='experience.row-country-multiselect'
                                    selectedIds={rowCountryList}
                                    options={generateRowCountryOptions()}
                                    disabled={shouldDisableInput}
                                    onChange={(event) => {this.updateRowCountryInfo(rowEnabled, event.detail.selectedIds);}}
                                    filteringType='auto'
                                    checkboxes={true}
                                />
                            </FormField>
                        </div>}
                    </div>}
            </ColumnLayout>
        </FormSectionView>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(RegionSectionView);
