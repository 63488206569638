import * as React from 'react';
import { Button, Modal, Input } from '@amzn/awsui-components-react';

import { AppState } from '../../reducers';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { connect } from 'react-redux';
import { deleteExperienceAction, deleteOdysseyExperienceAction } from '../../actions/experienceEditViewActions';

interface DeleteExperienceModalProps {
    dispatch: any;
    experience?: IFlattenedExperience;
    isVisible: boolean;
    hideModal: () => void;
}

interface DeleteExperienceModalState {
    confirmDeleteInput?: string;
    [key: string]: any;
}

class DeleteExperienceModal extends React.Component<DeleteExperienceModalProps, DeleteExperienceModalState> {
    private static SAFE_DELETE_SECRET_STRING = 'delete me';
    private static INITIAL_MODAL_STATE: DeleteExperienceModalState = {
        confirmDeleteInput: undefined
    };

    constructor(props: DeleteExperienceModalProps) {
        super(props);
        this.state = DeleteExperienceModal.INITIAL_MODAL_STATE;
    }

    updateState(object: { [key: string]: any }) {
        this.setState(object);
    }

    componentDidUpdate(prevProps: DeleteExperienceModalState) {
        // if modal becomes visible again, need to clear off all previous state to a fresh state
        if(this.props.isVisible === true && prevProps.isVisible === false) {
            this.setState(DeleteExperienceModal.INITIAL_MODAL_STATE);
        }
    }

    private validateModalCompletion(): boolean {
        const { confirmDeleteInput } = this.state;

        return confirmDeleteInput === DeleteExperienceModal.SAFE_DELETE_SECRET_STRING;
    }

    private deleteExperienceButtonClickHandler() {
        const { dispatch, hideModal, experience } = this.props;

        // delete action on odyssey expeerience
        if (this.validateModalCompletion() && (experience?.type === 'MobileHomeCard' || experience?.type === 'RotatingContentPanel')) {
            hideModal();
            dispatch(deleteOdysseyExperienceAction());
        }

        if (this.validateModalCompletion()) {
            hideModal();
            dispatch(deleteExperienceAction());
        }
    }

    render() {
        const {
            isVisible,
            experience,
            hideModal
        } = this.props;
        const { confirmDeleteInput } = this.state;

        if (experience === undefined || experience === null) {
            return <Modal>
                <div className='awsui-util-inactive'>
                    You cannot delete an experience right now. Please try again later.
                </div>
            </Modal>;
        }

        return <Modal
            id='modal.delete-experience'
            visible={isVisible}
            header='Delete Experience'
            onDismiss={hideModal.bind(this)}
            footer={
                <span className='awsui-util-f-r'>
                    <Button
                        id='button.cancel-delete-modal'
                        variant='link'
                        onClick={hideModal.bind(this)}>
                        Cancel
                    </Button>
                    <Button
                        id='button.delete-experience-in-modal'
                        variant='primary'
                        icon='status-warning'
                        disabled={!this.validateModalCompletion()}
                        onClick={this.deleteExperienceButtonClickHandler.bind(this)}>
                        Delete Experience Permanently
                    </Button>
                </span>
            }
        >
            <p>
                Only ADMIN should be able to perform this action. And this will delete
                this experience from Odyssey permanently.

                Please confirm that you want to perform this action by typing in&nbsp;
                <i>{DeleteExperienceModal.SAFE_DELETE_SECRET_STRING}</i> in the input box below.
            </p>
            <Input
                id='experience.confirm-delete-experience'
                controlId='experience.confirm-delete-experience'
                value={confirmDeleteInput}
                placeholder='Please confirm'
                onInput={e => this.updateState({ confirmDeleteInput: e.detail.value })}/>
        </Modal>;
    }
}

const mapStateToProps = ({ odysseyExperienceDetailViewState, experienceDetailViewState }: AppState) => {
    return {
        experience: experienceDetailViewState.experience || odysseyExperienceDetailViewState.experience
    };
};

export default connect(mapStateToProps)(DeleteExperienceModal);
