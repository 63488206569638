import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Spinner } from '@amzn/awsui-components-react';
import { AppState } from '../reducers/index';
import { IFlattenedExperience } from '../models/FlattenedExperience';
import { getTypeIconPath } from '../util/stringAndMappingHelper';
import { getFilterDailyMetricsAction } from '../actions/experienceMetricsViewActions';
import { OpportunityFunnelAnalysis } from '../components/metrics/OpportunityFunnelAnalysis';
import { IRawFilterMetricsData, FilterMetricFrequency } from '../models/FilterMetrics';
import { formatDateAndTime } from '../components/metrics/ExperienceMetricsReportTimestamp';

export interface IFilterMetricsProps {
    dispatch: any;

    experience?: IFlattenedExperience;

    filterMetrics?: IRawFilterMetricsData[];
    isFilterMetricsLoading?: boolean;
    filterMetricsError?: Error;
}

export interface IFilterDailyMetricsState {
    fromDate: string;
    toDate: string;
    isFromDateValid: boolean;
    isToDateValid: boolean;
}

class FilterMetrics extends Component<IFilterMetricsProps, IFilterDailyMetricsState> {

    constructor(props: IFilterMetricsProps) {
        super(props);
        this.state = {
            fromDate: '',
            toDate: '',
            isFromDateValid: true,
            isToDateValid: true
        };
    }

    private getFilterMetrics() {
        const { dispatch, experience, isFilterMetricsLoading, filterMetrics, filterMetricsError} = this.props;

        if (experience && !filterMetrics && !isFilterMetricsLoading && !filterMetricsError) {
            const experienceIds = [experience.id];
            dispatch(getFilterDailyMetricsAction(experienceIds, 'CIF', FilterMetricFrequency.Day));
        }
    }

    public componentDidMount() {
        const { experience } = this.props;
        if (experience?.type === 'CIF') {
            this.getFilterMetrics();
        }
    }

    updateDateRange(fromDate: string, toDate: string, isFromDateValid: boolean, isToDateValid: boolean) {
        this.setState(prevState => {
            return {
                ...prevState,
                fromDate,
                toDate,
                isFromDateValid,
                isToDateValid
            };
        });
    }

    public render() {
        const { experience, filterMetrics, isFilterMetricsLoading, filterMetricsError } = this.props;

        if (isFilterMetricsLoading) {
            return <div
                id='metrics.spinner'
                style={{ display: 'flex', justifyContent: 'center' }}
            >
                <span className='awsui-util-status-inactive'><Spinner />Loading metrics...</span>
            </div>;
        } else if (!experience) {
            return <Alert
                id='metrics.alert'
                type='error'
                content='Something happened while trying to load your metrics...'
            />;
        }

        const typeIconPath = getTypeIconPath(experience.type);
        let alert = null;
        if (filterMetricsError) {
            alert = <Alert
                id='metrics.alert'
                type='error'
                content='Filter Metrics for this experience did not load properly.  Please try refreshing the page.'
        />;
        }

        if (filterMetrics && !this.state.fromDate && !this.state.toDate) {
            const metrics = filterMetrics[0]?.metrics;
            const newFromDate = metrics.length >= 7 ? metrics[6].period : metrics[metrics.length-1].period;
            this.updateDateRange(newFromDate, metrics[0].period, true, true);
        }
        const { fromDate, toDate, isFromDateValid, isToDateValid } = this.state;

        return <div className='awsui-grid'>
            {
                alert && <div className='awsui-row'><div className='col-12'>{alert}</div></div>
            }
            <div className='awsui-row'>
                <div className='col-8'>
                    <h1 id='experience-title'>
                        <img src={typeIconPath} alt='' style={{height:'35px',width:'35px',verticalAlign:'bottom'}} className='awsui-util-mr-xs' />{experience.title}
                    </h1>
                    {filterMetrics &&
                        <div className='awsui-util-status-inactive'>
                            {`Refreshed: ${formatDateAndTime(filterMetrics[0]?.metrics[0].reportedAt)}`}
                        </div>
                    }
                </div>
            </div>
            <div id='metrics.filter' className='awsui-row'>
                <div className='col-12'>
                    <OpportunityFunnelAnalysis
                        filterMetrics={filterMetrics}
                        fromDate={fromDate}
                        toDate={toDate}
                        updateFromDate={(newFromDate: string, isNewFromDateValid: boolean) => {
                            this.updateDateRange(newFromDate, toDate, isNewFromDateValid, isToDateValid);
                        }}
                        updateToDate={(newToDate: string, isNewToDateValid: boolean) => {
                            this.updateDateRange(fromDate, newToDate, isFromDateValid, isNewToDateValid);
                        }}
                        isFromDateValid={isFromDateValid}
                        isToDateValid={isToDateValid}
                        />
                </div>
            </div>
        </div>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, filterDailyMetricsState }: AppState) => {
    return {
        experience: experienceDetailViewState.experience || undefined,
        filterMetrics: filterDailyMetricsState.filterMetrics,
        isFilterMetricsLoading: filterDailyMetricsState.isLoading,
        filterMetricsError: filterDailyMetricsState.error
    };
};

export default connect(mapStateToProps)(FilterMetrics);
