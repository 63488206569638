import * as React from 'react';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { Alert, ColumnLayout, FormField, Input } from '@amzn/awsui-components-react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { RequiredField, RecommendedField } from '../../components/common/DescriptionAnnotations';
import { pushBasicInfoSectionAction, pushImpressionTrackingSectionAction } from '../../actions/experienceEditViewActions';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { ExperienceType } from '../../models/Experience';


interface IExperienceInfoSectionViewProps {
    dispatch: any;

    selectedExperienceType?: ExperienceType;
    updateCandidate?: ExperienceUpdateCandidate;

    onExperienceTitleChange?: (type?: string) => void;
    onExperienceOperatorGroupChange?: (type?: string) => void;
}

export class ExperienceInfoSectionView extends React.Component<IExperienceInfoSectionViewProps> {

    updateBasicInfo(title?: string, groupPermissions?: string, onExperienceTitleChange?: (type?: string) => void, onExperienceOperatorGroupChange?: (type?: string) => void) {
        const { dispatch } = this.props;
        dispatch(pushBasicInfoSectionAction(title, groupPermissions));
        if (onExperienceTitleChange) {
            onExperienceTitleChange(title);
        }

        if (onExperienceOperatorGroupChange) {
            onExperienceOperatorGroupChange(groupPermissions);
        }
    }

    updateImpressionTracking(weblab?: string, featureId?: string) {
        const { dispatch } = this.props;

        dispatch(pushImpressionTrackingSectionAction(weblab, undefined, featureId));
    }

    render () {
        const { updateCandidate, selectedExperienceType, onExperienceTitleChange, onExperienceOperatorGroupChange} = this.props;

        return <div>
            <FormSectionView title='Basic information'>
                <Alert>
                    Every experience needs a title at a minimum to be saved.
                </Alert>
                <ColumnLayout columns={2} borders='vertical' >
                    <div data-awsui-column-layout-root='true'>
                        <FormField
                            label={<RequiredField fieldName='Experience title' />}
                            description='This title was set when you first created the experience and cannot be changed. It is used for tracking and searching'>
                            <Input
                                id='experience.title'
                                placeholder='ex. ALEXA_FA_CIF_PODCAST_COMMUTE_GENERIC_263599'
                                value={updateCandidate?.getBasicInfo().title}
                                onChange={(e) => this.updateBasicInfo(e.detail.value.trim(), undefined, onExperienceTitleChange)} />
                        </FormField>

                        {
                            selectedExperienceType === 'RotatingContentPanel' ?  <FormField
                                label={<RequiredField fieldName='Operator Group' />}
                                description='LDAP group that allows members of your team to edit the experiment'>
                                <Input
                                    id='experience.groupImpressions'
                                    placeholder='ex. alexa-fa-dev'
                                    value={updateCandidate?.getBasicInfo().groupImpressions}
                                    onChange={(e) => this.updateBasicInfo(undefined, e.detail.value.trim(), undefined, onExperienceOperatorGroupChange)} />
                            </FormField> :  <FormField
                                label={<RecommendedField fieldName='Operator Group' />}
                                description='LDAP group that allows members of your team to edit the experiment'>
                                <Input
                                    id='experience.groupImpressions'
                                    placeholder='ex. alexa-fa-dev'
                                    value={updateCandidate?.getBasicInfo().groupImpressions}
                                    onChange={(e) => this.updateBasicInfo(undefined, e.detail.value.trim(), undefined, onExperienceOperatorGroupChange)} />
                            </FormField>
                        }
                    </div>

                </ColumnLayout>
            </FormSectionView>
        </div>;
    }
}

const mapStateToProps = ({ experienceEditViewState }: AppState) => {
    return {
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(ExperienceInfoSectionView);
