// RESET
export const RESET_ODYSSEY_ANNOUNCEMENT = 'RESET_ODYSSEY_ANNOUNCEMENT';

// GET
export const LOADED_ODYSSEY_ANNOUNCEMENT = 'LOADED_ODYSSEY_ANNOUNCEMENT';
export const LOADING_ODYSSEY_ANNOUNCEMENT = 'LOADING_ODYSSEY_ANNOUNCEMENT';
export const ERROR_LOADING_ANNOUNCEMENT = 'ERROR_LOADING_ANNOUNCEMENT';

// PUT
export const UPDATED_ODYSSEY_ANNOUNCEMENT = 'UPDATED_ODYSSEY_ANNOUNCEMENT';
export const UPDATING_ODYSSEY_ANNOUNCEMENT = 'UPDATING_ODYSSEY_ANNOUNCEMENT';
export const ERROR_UPDATING_ANNOUNCEMENT = 'ERROR_UPDATING_ANNOUNCEMENT';
