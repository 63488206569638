import * as React from 'react';
import {FormSectionView} from '../../components/common/FormSectionView';
import {Alert, Button, Checkbox, ColumnLayout, FormField, Icon, Input, Select, Spinner, Tooltip} from '@amzn/awsui-components-react';
import {IFlattenedExperience} from '../../models/FlattenedExperience';
import {ExperienceUpdateCandidate, IContentViewAttributes} from '../../models/ExperienceUpdateCandidate';
import {AppState} from '../../reducers/index';
import {connect} from 'react-redux';
import {pushContentSectionAction} from '../../actions/experienceEditViewActions';
import {LinkedOdysseyWiki} from '../../components/common/LinksRenderHelper';
import {OptionalField, RequiredField} from '../../components/common/DescriptionAnnotations';
import {isRightToLeftLocale, LOCALE_TO_LANG} from '../../constants/locales';
import {contentCharLimit, referralContentCharLimit} from '../../constants/componentConstants';
import {TextAreaWrapper} from '../../components/common/TextAreaWrapper';
import {CIFValidator} from '../../util/CIFValidator';
import {bestEffortJsonFormat} from '../../util/stringAndMappingHelper';
import {ActionType} from '../../models/ActionType';
import NotificationSectionView from './NotificationSectionView';
import {TextRenderDirection} from '../../models/htmlElementProps';
import {isCardEmpty} from '../../models/cifExperience/visualExperience/IManagementCard';
import {ExternalLink} from '../../components/common/LinkComponents';
import {isNullOrUndefined} from 'util';
import {FRICTION_TRIGGERED_CIF_USER_GROUP} from '../../constants/userGroups';
import {InputWrapper} from '../../components/common/InputWrapper';


interface IContentSectionViewState {
    forcedLtrRendering: boolean;
    cxGuidelinesCheckbox: boolean;
}

interface IContentSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    permissions?: string[];
    updateCandidate?: ExperienceUpdateCandidate;

    onContentCXGuidelinesCheckedUpdate?: (checked?: boolean) => void;
}

export class ContentSectionView extends React.Component<IContentSectionViewProps, IContentSectionViewState> {
    constructor(props: IContentSectionViewProps) {
        super(props);

        this.state = {
            forcedLtrRendering: false,
            cxGuidelinesCheckbox: false
        };
    }

    updateContent(contentType: 'content' | 'referral' | 'none', contentText?: string, referralQuestionContentText?: string, referralData?: string,
    rejectionReferralData?: string, actionType?: ActionType, sonarTemplateURI?: string, sonarTemplateJson?: string, campaignId?: string, checkCXGuidelines?: boolean, customResponse?: string) {
        const { dispatch } = this.props;

        const isEntireContentATemplateVariable = CIFValidator.isEntireContentATemplateVariable(contentText);

        dispatch(pushContentSectionAction(contentType, contentText, referralQuestionContentText,
            referralData, rejectionReferralData, actionType, sonarTemplateURI, sonarTemplateJson, campaignId, checkCXGuidelines, isEntireContentATemplateVariable, customResponse));
    }

    shouldRenderTextRightToLeft(locale: string): boolean {
        const { forcedLtrRendering } = this.state;

        return isRightToLeftLocale(locale) && !forcedLtrRendering;
    }

    onTextDirectionSwitchButtonClicked() {
        this.setState((prevState) => {
            return {
                forcedLtrRendering: !prevState.forcedLtrRendering
            };
        });
    }

    onCXGuidelinesConfirmation(checked: boolean, onContentCXGuidelinesCheckedUpdate?: (checked?: boolean) => void) {
        this.setState({ cxGuidelinesCheckbox: checked });
        if (onContentCXGuidelinesCheckedUpdate) {
            onContentCXGuidelinesCheckedUpdate(checked);
        }
    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate, onContentCXGuidelinesCheckedUpdate, permissions } = this.props;
        const { forcedLtrRendering } = this.state;

        const operatorGroup = ((isEditing && updateCandidate)
            ? updateCandidate.getBasicInfo().groupImpressions
            : experience?.operatorGroup) || '';

        const forceRenderLtrButton = <Tooltip
            position='top'
            text='Use RTL for natural Arabic text direction; LTR to validate SSML tags'>
            <Button
                id='button.rtl-and-ltr'
                icon={forcedLtrRendering ? 'caret-right' : 'caret-left'}
                onClick={this.onTextDirectionSwitchButtonClicked.bind(this)} >
                Switch to {forcedLtrRendering ? 'Right-to-Left (RTL)' : 'Left-to-Right (LTR)'}
            </Button>
        </Tooltip>;

        if (!experience) {
            return <FormSectionView title={'Content'}>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const contentVariables: string[] = ((isEditing && updateCandidate)
            ? updateCandidate.getTemplateVariables()
            : experience.templateVariables || []).map(v => v.variableName);

        const renderData: IContentViewAttributes = (isEditing && updateCandidate)
            ? updateCandidate.getContent()
            : ExperienceUpdateCandidate.extractContent(experience);

        const visualIsPromptless = (isEditing && updateCandidate)
            ? !updateCandidate.getEnableVoiceCif() || false
            : !ExperienceUpdateCandidate.extractEnableVoiceCif(experience) || false;

        const shouldDisableInput = !isEditing || isUpdating || visualIsPromptless;

        const isContentSelected = renderData.contentType !== 'referral';
        const targetLocale = ((isEditing && updateCandidate)
            ? updateCandidate.getRegion().locale
            : experience.locale) || 'en_US';

        // if Vimo is configured, we can only serve simple text instead of referral.
        const isVimoConfigured = !!updateCandidate?.getVisualInterstitialCards()?.find(x => !isCardEmpty(x));

        const textDirection: TextRenderDirection = this.shouldRenderTextRightToLeft(targetLocale) ? 'rtl' : 'ltr';

        if (isContentSelected) {
            return <div lang={LOCALE_TO_LANG[targetLocale]} spellCheck={true} >
                <FormSectionView
                    title={
                        <div className='awsui-util-action-stripe'>
                            <div className='awsui-util-action-stripe-title'>
                                <h2 className='awsui-util-d-ib'>
                                    Content
                                </h2>
                            </div>
                            <div className='awsui-util-action-stripe-group'>
                                <Button onClick={() => this.updateContent('referral')}
                                    variant='normal'
                                    icon='refresh'
                                    id='button.toggle-content'
                                    disabled={shouldDisableInput || isVimoConfigured}
                                >Change to Yes/No Question</Button>
                            </div>
                        </div>}
                    description='Content specified below is what Alexa will say to the
                        customer when your use case is triggered.'>
                    <div>
                        {isNullOrUndefined(experience.id)
                            ? <>
                                <Alert type='info' className='awsui-util-mb-l'>
                                    Please read the <ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/CIF+CX+Guidelines+and+Recommendations'}
                                        >CIF CX Guidelines and Recommendations</ExternalLink> documentation.
                                    <Checkbox
                                        id={'experience.confirm-read-cx-guidelines'}
                                        controlId={'experience.confirm-read-cx-guidelines'}
                                        checked={renderData.checkCXGuidelines || false}
                                        onChange={(e) => {
                                            this.onCXGuidelinesConfirmation(e.detail.checked, onContentCXGuidelinesCheckedUpdate);
                                            this.updateContent('content', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, e.detail.checked);
                                        }}
                                    ><b>I confirm that I have read and will follow the CX Guidelines</b></Checkbox>
                                    <div><div><span className='awsui-util-status-negative'><Icon name='status-warning' />  This box must be checked</span></div><span /></div>
                                </Alert>
                            </>
                            : <>
                                <Alert type='info' visible={isEditing} className='awsui-util-mb-l'>
                                    Please read the <ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/CIF+CX+Guidelines+and+Recommendations'}
                                        >CIF CX Guidelines and Recommendations</ExternalLink> documentation.
                                </Alert>
                            </>}
                    </div>

                    <Alert type='error' visible={visualIsPromptless} className='awsui-util-mb-l'>
                        You must disable visual promptless toggle under <i>Advanced Configuration -&gt; VIMO</i> to enable content fields.
                    </Alert>


                    {isRightToLeftLocale(targetLocale) && forceRenderLtrButton}

                    <FormField label={<RequiredField fieldName='Content' />} description='Spoken content in SSML' >
                        <TextAreaWrapper
                            id='experience.content'
                            textRenderDirection={textDirection}
                            readonly={shouldDisableInput}
                            value={visualIsPromptless ? '' : renderData.contentText}
                            characterLimit={contentCharLimit}
                            onChange={(input: string) => {
                                this.updateContent('content', input.trim());
                            }}
                            validate={(utterance) => CIFValidator.isUtteranceValid(utterance, contentVariables, experience.status)} />
                    </FormField>
                </FormSectionView>
            </div>;
        } else {
            return <div lang={LOCALE_TO_LANG[targetLocale]} spellCheck={true}>
                <FormSectionView
                    title={
                        <div className='awsui-util-action-stripe'>
                            <div className='awsui-util-action-stripe-title'>
                                <h2 className='awsui-util-d-ib'>
                                    Referral Content
                                </h2>
                            </div>
                            <div className='awsui-util-action-stripe-group'>
                                <Button onClick={() => this.updateContent('content')}
                                    variant='normal'
                                    icon='refresh'
                                    id='button.toggle-content'
                                    disabled={shouldDisableInput}>Change to Simple TTS Content</Button>
                            </div>
                        </div>
                    }
                    description='Content specified below is what Alexa will say to the customer when they receive your experience.'>
                    <div>
                        {isNullOrUndefined(experience.id)
                            ? <>
                                <Alert type='info' className='awsui-util-mb-l'>
                                    Please read the <ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/CIF+CX+Guidelines+and+Recommendations'}
                                        >CIF CX Guidelines and Recommendations</ExternalLink> documentation.
                                    <Checkbox
                                        id={'experience.confirm-read-cx-guidelines'}
                                        controlId={'experience.confirm-read-cx-guidelines'}
                                        checked={renderData.checkCXGuidelines || false}
                                        onChange={(e) => {
                                            this.onCXGuidelinesConfirmation(e.detail.checked, onContentCXGuidelinesCheckedUpdate);
                                            this.updateContent('referral', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, e.detail.checked);
                                        }}
                                    ><b>I confirm that I have read and will follow the CX Guidelines</b></Checkbox>
                                </Alert>
                            </>
                            : <>
                                <Alert type='info' visible={isEditing} className='awsui-util-mb-l'>
                                    Please read the <ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/CIF+CX+Guidelines+and+Recommendations'}
                                        >CIF CX Guidelines and Recommendations</ExternalLink> documentation.
                                </Alert>
                            </>}
                    </div>

                    <Alert type='error' visible={visualIsPromptless} className='awsui-util-mb-l'>
                        You must disable visual promptless toggle under <i>Advanced Configuration -&gt; VIMO</i> to enable content fields.
                    </Alert>

                    {isRightToLeftLocale(targetLocale) && forceRenderLtrButton}

                    <FormField label={<RequiredField fieldName='Referral Content' />} description='Referral question in SSML' >
                        <TextAreaWrapper
                            id='experience.referral-content'
                            readonly={shouldDisableInput}
                            textRenderDirection={textDirection}
                            value={visualIsPromptless ? '' : renderData.referralQuestionContentText}
                            characterLimit={referralContentCharLimit}
                            onChange={(input: string) => {
                                this.updateContent('referral', undefined,
                                    input.trim(),
                                    renderData.referralData,
                                    renderData.rejectionReferralData,
                                    renderData.actionType,
                                    renderData.sonarTemplateURI,
                                    renderData.sonarTemplateJson,
                                    renderData.campaignId);
                            }}
                            validate={(utterance) => CIFValidator.isUtteranceValid(utterance, contentVariables, experience.status)} />
                    </FormField>
                    <FormField
                        label={<OptionalField
                            fieldName='Post-CIF Action'
                            fieldDescription='Specify what action should be taken after injection' />}>
                        <Select
                            id='experience.action-type'
                            controlId='experience.action-type'
                            disabled={shouldDisableInput}
                            selectedId={(renderData.actionType === null || renderData.actionType === undefined) ? 'NONE' : renderData.actionType}
                            options={actionTypeOptions}
                            onChange={(e) => {
                                this.updateContent(
                                    'referral',
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    e.detail.selectedId as ActionType);
                            }} />
                    </FormField>
                    {(operatorGroup === FRICTION_TRIGGERED_CIF_USER_GROUP && permissions?.includes('OPERATOR')) &&
                    <><br /><FormField
                        label={<OptionalField fieldName='Post-CIF Custom Response'
                        fieldDescription='Specify a custom response after injection' />}>
                            <Input
                            id='experience.custom-successful-send-response'
                            value={renderData.customSuccessfulSendResponse}
                            disabled={shouldDisableInput}
                            onChange={(e) => {
                                this.updateContent(
                                    'referral',
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    e.detail.value
                                );
                            }}
                            />
                    </FormField></>}
                    <hr />
                    {(renderData.actionType === null || renderData.actionType === undefined || renderData.actionType === 'NONE') &&
                        <ColumnLayout columns={2}>
                            {CIFValidator.compareReferralAndRejectionReferral(renderData.referralData, renderData.rejectionReferralData)
                                && <Alert type='warning' className='awsui-util-mb-l'>
                                Referral data and Rejection referral data are the same. They should be unique and tested to ensure they execute the correct referral CIF CX.
                              </Alert>
                             }
                            <div data-awsui-column-layout-root='true' spellCheck={false}>
                                <div>
                                    <FormField
                                        label={<RequiredField fieldName='Referral data' fieldDescription='(required if customer says yes)' />}
                                        description={<span>Configure how to link to your skill or Speechlet. See&nbsp;
                                            <LinkedOdysseyWiki /> for more information.</span>} >
                                        <TextAreaWrapper
                                            id='experience.referral-data'
                                            readonly={shouldDisableInput}
                                            value={visualIsPromptless ? '' : bestEffortJsonFormat(renderData.referralData)}
                                            onChange={(input: string) => this.updateContent('referral', undefined,
                                                undefined, input)}
                                            validate={CIFValidator.isReferralDataValid} />
                                    </FormField>
                                </div>
                                <div>
                                    <FormField
                                        label={<RequiredField fieldName='Rejection referral data' fieldDescription='(required if customer says no)' />}
                                        description={<span>Configure how to link to your skill or Speechlet. See&nbsp;
                                            <LinkedOdysseyWiki /> for more information.</span>} >
                                        <TextAreaWrapper
                                            id='experience.rejection-referral-data'
                                            readonly={shouldDisableInput}
                                            value={visualIsPromptless ? '' : bestEffortJsonFormat(renderData.rejectionReferralData)}
                                            onChange={(input: string) =>
                                                this.updateContent(
                                                    'referral',
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    input)
                                            }
                                            validate={CIFValidator.isRejectionReferralDataValid} />
                                    </FormField>
                                </div>
                            </div>
                        </ColumnLayout>}
                    {(renderData.actionType === ActionType.EMAIL) &&
                        <ColumnLayout columns={2}>
                            <div data-awsui-column-layout-root='true' spellCheck={false}>
                                <div>
                                    <Alert type='info' className='awsui-util-mb-l'>
                                        To obtain a Sonar Campaign ID, please follow this <ExternalLink href={'https://w.amazon.com/bin/view/OutboundCommunications/SonarIngressService/'}>
                                        wiki</ExternalLink> for only <b>Step 3</b> and <b>Step 4: Request Sonar Campaign ID for your use cases</b> and stop when you have received a Sonar
                                        Campaign ID. If an approval is needed you can reuse this <ExternalLink href={'https://approvals.amazon.com/Approval/Details/27556513'}>
                                        approval</ExternalLink>. You may only enter a Campaign ID once it is live or the CIF experience will fail.
                                    </Alert>
                                    <FormField
                                        label={<RequiredField
                                            fieldName='Sonar Campaign ID'
                                            fieldDescription='Campaign ID that is assigned to your use case after onboarding with Outbound team.' />}>
                                        <InputWrapper
                                            id='experience.sonar-campaign-id'
                                            readonly={shouldDisableInput}
                                            value={renderData.campaignId}
                                            onInput={(input: string) =>
                                                this.updateContent(
                                                    'referral',
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    ActionType.EMAIL,
                                                    undefined,
                                                    undefined,
                                                    input.trim())
                                            }
                                            validate={CIFValidator.isCampaignIdValid}
                                        />
                                    </FormField>
                                </div>
                                <div>
                                    <FormField
                                        label={<RequiredField
                                            fieldName='Sonar Template URI'
                                            fieldDescription='Deprecated field (Not editable)' />}>
                                        <Input
                                            id='experience.sonar-template-uri'
                                            controlId='experience.sonar-template-uri'
                                            readonly={true}
                                            value={renderData.sonarTemplateURI}
                                            onInput={(e) =>
                                                this.updateContent(
                                                    'referral',
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    ActionType.EMAIL,
                                                    e.detail.value)
                                            }
                                        />
                                    </FormField>
                                </div>
                                <div>
                                    <FormField
                                        label={<OptionalField
                                            fieldName='Sonar Template Json Variables'
                                            fieldDescription='Deprecated field (Not editable)' />}>
                                        <TextAreaWrapper
                                            id='experience.sonar-template-json'
                                            readonly={true}
                                            value={bestEffortJsonFormat(renderData.sonarTemplateJson)}
                                            onChange={(input: string) =>
                                                this.updateContent(
                                                    'referral',
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    ActionType.EMAIL,
                                                    undefined,
                                                    input.trim())
                                            }
                                            validate={CIFValidator.isSonarTemplateJsonDataValid} />
                                    </FormField>
                                </div>
                            </div>
                        </ColumnLayout>}
                    {(renderData.actionType === ActionType.PUSH) &&
                        <NotificationSectionView
                            notificationType='App'
                        />
                    }
                </FormSectionView>
            </div>;
        }
    }
}

const actionTypeOptions: Select.Option[] = [
    { id: ActionType.NONE, label: 'Perform Alexa Link hand-off', tags: ['Hand-off to a user specified speechlet or skill'] },
    { id: ActionType.EMAIL, label: 'Send a Sonar email', tags: ['An email is sent after customer says yes'] },
    { id: ActionType.PUSH, label: 'Send a mobile push notification', tags: ['An Alexa app notification is sent after customer says yes'] }
];

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState, authenticationState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        permissions: authenticationState.permissions || [],
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(ContentSectionView);
