import { CognitoAuthSession } from 'amazon-cognito-auth-js';
import * as Axios from 'axios';
import axiosRateLimit from 'axios-rate-limit';
import { store } from '../reducers';
import { getCurrRegionStage } from '../util/context';
import { THROTTLE_LIMIT } from '../constants/throttleLimit';

const axios = Axios.default.create();

// middleware for refreshing access token and id token
axios.interceptors.request.use(config => {

    // promisify weird async implementation from cognito.
    //      the success handler is actually in a different function,
    //      so promise implementation guarantees that requests
    //      with bad tokens will actually be paused and wait on refresh.
    // failed on refreshing will result in redirecting to auth page
    return new Promise(resolve => {
        const { authenticationState } = store.getState();
        const cognitoAuth = authenticationState.authenticationInfo?.authMap?.[getCurrRegionStage().stage];

        // if cognitoAuth is not present, don't bother running this middleware
        if (cognitoAuth) {
            if (cognitoAuth.isUserSignedIn()) {
                const token = cognitoAuth.getSignInUserSession().getIdToken().getJwtToken();
                config.headers.Authorization = token;
                resolve(config);
            } else {
                console.error('not signed in, refreshing token');
                cognitoAuth.refreshSession(
                    cognitoAuth.getSignInUserSession().getRefreshToken().getToken());
                cognitoAuth.userhandler.onSuccess = (session: CognitoAuthSession) => {
                    const token = session.getIdToken().getJwtToken();
                    config.headers.Authorization = token;
                    resolve(config);
                };
            }
        } else {
            resolve(config);
        }
    });
});

const rateLimitedAxios = axiosRateLimit(axios, { maxRequests: THROTTLE_LIMIT });
export { rateLimitedAxios as axios };
