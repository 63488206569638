import * as React from 'react';
import { RadioGroup, ColumnLayout, Spinner } from '@amzn/awsui-components-react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate, ISpeakerDataAttributes } from '../../models/ExperienceUpdateCandidate';
import { pushSpeakerData } from '../../actions/experienceEditViewActions';
import { UserRole} from '../../models/SpeakerData';
import { LinkedPermissionGroup } from '../../components/common/LinksRenderHelper';


interface IAudienceTypeSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

export class AudienceTypeSectionView extends React.Component<IAudienceTypeSectionViewProps> {
    updateSpeakerData(userRole?: UserRole) {
        const { dispatch } = this.props;
        dispatch(pushSpeakerData(userRole));
    }

    render() {
        const { experience, isEditing, updateCandidate, isUpdating } = this.props;

        if (!experience) {
            return <FormSectionView title='Audience Type'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const shouldDisableInput = !isEditing || isUpdating;

        const speakerData: ISpeakerDataAttributes = (isEditing && updateCandidate)
            ? updateCandidate.getSpeakerData()
            : ExperienceUpdateCandidate.extractSpeakerData(experience);

        return <FormSectionView title='Audience Type' description='FreeTime audiences are devices set to "child mode", and serve content that has been reviewed and deemed adequate for children'>
            <ColumnLayout columns={1} borders='vertical'>
                <div data-awsui-column-layout-root='true'>
                    <div>
                        <div className='awsui-util-mb-m'>
                            <div>The audience type below works for all normal experiences.</div><br />
                            <RadioGroup
                                id='experience.nonfreetime-speakerData'
                                className='awsui-util-mb-l'
                                value={speakerData.userRole}
                                items={[{
                                    controlId: 'experience.speaker-data.user-role.adult',
                                    value: UserRole.ADULT,
                                    label: 'Non-FreeTime audience only (Default)',
                                    disabled: shouldDisableInput,
                                    description: 'This targets ONLY adult audiences.'
                                }]}
                                onChange={
                                    e => {
                                        this.updateSpeakerData(e.detail.value as UserRole);
                                    }}
                                >
                            </RadioGroup>
                            <div>The audience type below will only work if experience creator is under the Freetime  <LinkedPermissionGroup permissionGroup='baker-cdr-dev' /> LDAP group.
                            </div><br />
                            <RadioGroup
                                id='experience.freetime-speakerData'
                                className='awsui-util-mb-l'
                                value={speakerData.userRole}
                                items={[{
                                    controlId: 'experience.speaker-data.user-role.child',
                                    value: UserRole.CHILD,
                                    label: 'FreeTime audiences',
                                    disabled: shouldDisableInput,
                                    description: 'This targets ONLY Freetime audiences and devices, individuals on the Baker team only may use this targeting.'
                                },{
                                    controlId: 'experience.speaker-data.user-role.all',
                                    value: UserRole.ALL,
                                    label: 'All audiences',
                                    disabled:shouldDisableInput,
                                    description: 'This includes targeting all adult audiences and Freetime audiences, individuals on the Baker team only may use this targeting.'
                                } ]}
                                onChange={
                                    e => {
                                        this.updateSpeakerData(e.detail.value as UserRole);
                                    }}
                                >
                            </RadioGroup>
                        </div>
                    </div>
                </div>
            </ColumnLayout>
        </FormSectionView>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState, bullseyeMetadataState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate,
        error: bullseyeMetadataState.error || undefined
    };
};

export default connect(mapStateToProps)(AudienceTypeSectionView);
