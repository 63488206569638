import * as React from 'react';
import { connect } from 'react-redux';
import { IMonitoredExperiments } from '../../models/monitoredExperiments/MonitoredExperiments';
import { AppState } from '../../reducers';
import { FormSectionView } from '../../components/common/FormSectionView';
import { MarkdownRenderer } from '../../components/MarkdownRenderer';
import { Spinner } from '@amzn/awsui-components-react';

interface MonitoredExperimentsViewProps {
    dispatch: any;
    isLoading: boolean;
    isMonitoredExperimentsUpdated: boolean;
    errorUpdating?: Error;
    errorLoading?: Error;
    monitoredExperiments?: IMonitoredExperiments;
}

const mapStateToProps = ({ monitoredExperimentsState }: AppState) => {
    return {
        isLoading: monitoredExperimentsState.isLoading,
        isMonitoredExperimentsUpdated: monitoredExperimentsState.updatedMonitoredExperiments,
        errorUpdating: monitoredExperimentsState.errorUpdating || undefined,
        errorLoading: monitoredExperimentsState.errorLoading || undefined,
        monitoredExperiments: monitoredExperimentsState.monitoredExperiments || undefined,
    };
};

const monitoredExperimentsDescription = 'The experiments will be monitored';

export const MonitoredExperimentsTextOnlySectionView = connect(mapStateToProps)((props: MonitoredExperimentsViewProps) => {
    const { isLoading, errorLoading, monitoredExperiments } = props;
    if (isLoading) {
        return <Spinner size='large' variant='disabled' />;
    }

    if (errorLoading) {
        return <div id='monitoredExperiments.content-render-area.error'>
            <p className='awsui-util-status-negative'>{errorLoading.message}</p>
        </div>;
    }

    if(!monitoredExperiments) {
        return <div id='monitoredExperiments.content-render-area.no-content'>
	        <p className='awsui-util-status-inactive awsui-util-status-negative' >No monitored experiments at the moment...</p>
	    </div>;
    }

    return <div id='monitoredExperiments.content-render-area'>
        <h2> Experiment Ids: </h2>
        <MarkdownRenderer markdownString={monitoredExperiments.experimentIds.toString().replace(/,/g, '<br>')} />
    </div>;
});


export const MonitoredExperimentsSectionView = () => {
    return <FormSectionView title='Monitored Experiments' description= {monitoredExperimentsDescription}>
        <MonitoredExperimentsTextOnlySectionView/>
    </FormSectionView>;
};
