import * as React from 'react';
import moment from 'moment';
import { FormSectionView } from '../../components/common/FormSectionView';
import { FormField, ColumnLayout, DatePicker, TimeInput } from '@amzn/awsui-components-react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../reducers/index';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { pushRotatingContentPanelSchedulingConstraint } from '../../actions/experienceEditViewActions';


export const ActivationTimeDateSectionView: React.FC = () => {
    const { isEditing, isUpdating, updateCandidate } = useSelector((state: AppState) => state.experienceEditViewState);
    const { experience } = useSelector((state: AppState) => state.odysseyExperienceDetailViewState);
    const dispatch = useDispatch();

    const shouldDisableInput = !isEditing || isUpdating;
    const updateCandidateConstraint = updateCandidate?.getRotatingContentPanelSchedulingConstraint();
    const savedConstraint = experience?.constraints?.SchedulingConstraint;

    // ex 2022-12-23 has 10 characters
    const lastDateIndex: number = 9;
    const startDate: string | undefined = (!shouldDisableInput ? updateCandidateConstraint?.startDate.substring(0, lastDateIndex + 1)
        : savedConstraint?.startDate)?.substring(0, lastDateIndex + 1);
    const endDate: string | undefined = (!shouldDisableInput ? updateCandidateConstraint?.endDate.substring(0, lastDateIndex + 1)
        : savedConstraint?.endDate)?.substring(0, lastDateIndex + 1);

    // ex 2022-12-23T23:21:12, 23:21:12 starts at the 11th character
    const firstTimeIndex: number = 11;
    let startTime: string | undefined = (!shouldDisableInput ? updateCandidateConstraint?.startDate.substring(firstTimeIndex)
        : savedConstraint?.startDate)?.substring(firstTimeIndex);
    startTime = startTime === '' ? '00:00:00' : startTime;

    let endTime: string | undefined = (!shouldDisableInput ? updateCandidateConstraint?.endDate.substring(firstTimeIndex)
        : savedConstraint?.endDate)?.substring(firstTimeIndex);
    endTime = endTime === '' ? '00:00:00' : endTime;

    const [ currentState, setState ] = React.useState({
        startDate: startDate || '',
        endDate: endDate || '',
        startTime: startTime || '00:00:00',
        endTime: endTime || '00:00:00'
    });

    const updateSchedule = (event: CustomEvent<TimeInput.ChangeDetail | DatePicker.ChangeDetail>) => {
        const id = (event.target as HTMLElement).getAttribute('id')?.split('.').pop();
        if (id) {
            const newState = {...currentState, [id]: event.detail.value };
            setState(newState);
            dispatch(pushRotatingContentPanelSchedulingConstraint({
                startDate: moment(newState.startDate).format('YYYY-MM-DD') + 'T' + newState.startTime,
                endDate: moment(newState.endDate).format('YYYY-MM-DD') + 'T' + newState.endTime,
                timeWindows: []
            }));
        }
    };

    const datePickerSharedConfig: DatePicker.Props = {
        disabled: shouldDisableInput,
        placeholder: 'YYYY-MM-DD',
        todayLabel: 'Today',
        nextMonthLabel: 'Next month',
        previousMonthLabel: 'Previous month',
        onChange: updateSchedule
    };

    const timeInputSharedConfig: TimeInput.Props = {
        disabled: shouldDisableInput,
        format: 'hh:mm:ss',
        onChange: updateSchedule
    };

    return <FormSectionView title={<RequiredField fieldName={'Activation Dates'} />}>
        <FormField>
            <ColumnLayout columns={2} borders={'horizontal'} >
                <div data-awsui-column-layout-root='true'>
                    <FormField label='Start Date'>
                        <DatePicker {...datePickerSharedConfig} value={currentState.startDate} id='experience.startDate' />
                    </FormField>
                    <FormField label='Start Time'>
                        <TimeInput {...timeInputSharedConfig} value={currentState.startTime} id='experience.startTime' />
                    </FormField>
                    <FormField label='End Date'>
                        <DatePicker {...datePickerSharedConfig} value={currentState.endDate} id='experience.endDate' />
                    </FormField>
                    <FormField label='End Time'>
                        <TimeInput {...timeInputSharedConfig} value={currentState.endTime} id='experience.endTime' />
                    </FormField>
                </div>
            </ColumnLayout>
        </FormField>
    </FormSectionView>;
};

export default ActivationTimeDateSectionView;
