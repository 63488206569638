import { ILoadEventTrailAction } from '../actions/experienceDetailViewActions';
import {
    LOADING_EVENT_TRAIL,
    LOADED_EVENT_TRAIL,
    ERROR_LOADING_EVENT_TRAIL
} from '../constants/experienceDetailViewActionTypes';
import { EventTrailResponse } from '../models/events/EventTrail';

export interface EventTrailState {
    isLoading: boolean;
    events?: EventTrailResponse;
    error?: Error;
}

export const eventTrailReducer = (
    state: EventTrailState = { isLoading: false },
    action: ILoadEventTrailAction
): EventTrailState => {
    switch (action.type) {
        case LOADING_EVENT_TRAIL:
            return {
                isLoading: true
            };
        case LOADED_EVENT_TRAIL:
            const { events } = action;
            // reducer is at top level. it should resolve error
            // and get it ready for rendering.
            if (!events) {
                return {
                    isLoading: false,
                    error: new Error('Loaded `undefined` events, try refreshing the page')
                };
            }
            return {
                isLoading: false,
                events
            };
        case ERROR_LOADING_EVENT_TRAIL:
            return {
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};
