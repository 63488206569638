import React, {Dispatch} from 'react';
import { DatePicker } from '@amzn/awsui-components-react';
import { DataSource } from '../../models/DataSource';
import { ExperienceType } from '../../models/Experience';
import {Region} from '../../util/context';
import {MARKETPLACE_TO_REGION} from '../../constants/marketplaces';
import { getChangeControlDecisionAction } from '../../actions/experienceDetailViewActions';
import { connect } from 'react-redux';
import {AppState} from '../../reducers';

export interface IDatePickerWrapperProps {
    id: string;
    startDate?: string;
    disabled?: boolean;
    isDateEnabled?: DatePicker.IsDateEnabledFunction;
    endDate?: string;
    value?: string;
    type?: ExperienceType;
    dataSource?: DataSource;
    validate?: (startDate?: string, endDate?: string) => void;
    onChange: (event: CustomEvent<DatePicker.ChangeDetail>) => void;
    checkChangeControl?: boolean;
    region?: Region;
    changeControlDecision?: string;
    onChangeControlDecision?: any;
}

const BLOCKED_DECISION: string = 'BLOCKED_RULES_VIOLATED';

class DatePickerWrapper extends React.Component<IDatePickerWrapperProps> {

    constructor(props: IDatePickerWrapperProps) {
        super(props);
        this.updateChangeControlDecision = this.updateChangeControlDecision.bind(this);
    }

    updateChangeControlDecision(event: CustomEvent<any>) {
        const dateString = event.detail.value.toString();
        const { region, checkChangeControl, onChange } = this.props;
        const mappedRegion: Region = MARKETPLACE_TO_REGION[region || 'US'];
        if (dateString && checkChangeControl) {
            const date = new Date(dateString).toISOString();
            this.props.onChangeControlDecision(date, (mappedRegion)?mappedRegion:'NA' );
        }
        if (onChange) {
            onChange(event);
        }
    }

    render () {
        const { id, startDate, endDate, disabled, value, isDateEnabled, validate,
            checkChangeControl, changeControlDecision} = this.props;
        let errorMessage = '';
        let isValid: boolean = true;
        if (!disabled && validate) {
            try {
                validate(startDate, endDate);
                isValid = true;
            } catch (error) {
                isValid = false;
                errorMessage = error.message;
            }
        }
        const changeControlMessage: string | undefined =
            (checkChangeControl && BLOCKED_DECISION === changeControlDecision) ? 'This is a BLOCKED day' : undefined;
        return <div>
            <DatePicker
                disabled={disabled}
                placeholder='YYYY/MM/DD'
                todayLabel='Today'
                nextMonthLabel='Next month'
                previousMonthLabel='Previous month'
                isDateEnabled={isDateEnabled}
                controlId={id}
                id={id}
                value={value}
                invalid={!isValid}
                onChange={this.updateChangeControlDecision}
            />
            <div>
                {errorMessage && <p className='awsui-util-status-negative'>{errorMessage}</p>}
            </div>
            <div>
                {checkChangeControl && changeControlMessage && <p className='awsui-util-status-negative'>{changeControlMessage}</p>}
            </div>
        </div>;
    }
}

const mapStateToProps = ({ changeControlDecisionState }: AppState) => {
    return {
        changeControlDecision: changeControlDecisionState.message
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        onChangeControlDecision: (startDate?: string, region?: Region) => {
            dispatch(getChangeControlDecisionAction(startDate,region));
        }
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(DatePickerWrapper);


