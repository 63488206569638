import { Action } from 'redux';
import {
    LOADED_USER_ALIAS
} from '../constants/navigationViewActionTypes';


export interface INavigationViewActions extends Action {
    type: string;
    userAlias?: string;
}

export const loadedUserAlias = (userAlias: string): INavigationViewActions => {
    return {
        type: LOADED_USER_ALIAS,
        userAlias
    };
};

export const loadUserAlias = (alias: string) => {
    return loadedUserAlias(alias);
};
