export const contentCharLimit = 250;
export const referralContentCharLimit = 250;
export const notificationHeaderLimit = 36;
export const notificationHeaderLimitPNAPI = 100;
export const notificationContentLimit = 250;
export const notificationContentLimitPNAPI = 200;
export const ctaButtonTextLimit = 36;
export const spokenContentLimit = 250;
export const cifTitleCharLimit = 250;
export const deviceTitleCharLimit = 250;
export const notificationTitleCharLimit = 250;
export const dateOffset = '00:00:00';
export const visualCharacterLimit = 35;
export const experiencesPageLimit: number = 2000;
export const odysseyExperiencesPageLimit: number = 500;
export const suggestionsPageLimit: number = 2000;
export const preambleTitleCharacterLimit: number = 40;
export const hintHeadlineCharacterLimit: number = 42;
export const buttonTextCharacterLimit: number = 14;

export const WAKEWORDS = [
    // Default locales
    'Alexa',
    'Echo',
    'Amazon',
    'Computer',
    'Ziggy',
    // ar-SA
    'أليكسا',
    'إيكو',
    'أمازون',
    // ja-JP
    'アレクサ',
    'エコー',
    'アマゾン',
    'コンピュータ'
];
