import * as React from 'react';

export const DomainIllustrationDefaultItems = [
    {
        controlId: 'alarms',
        label: 'Alarms',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_alarms.png' alt='Alarm Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_alarms.png',
    },
    {
        controlId: 'alarms-and-reminders',
        label: 'Alarms and reminders',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_alarms_reminders_timers.png' alt='Alarms and Reminders Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_alarms_reminders_timers.png',
    },
    {
        controlId: 'calenders',
        label: 'Calenders',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_calendar.png' alt='Calenders Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_calendar.png',
    },
    {
        controlId: 'cinema',
        label: 'Cinema',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_cinema.png' alt='Cinema Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_cinema.png',
    },
    {
        controlId: 'suggested-action',
        label: 'Suggested Action',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_comms.png' alt='Suggested Action Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_comms.png',
    },
    {
        controlId: 'suggested-action-generic',
        label: 'Suggested Action Generic',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_generic_01.png' alt='Suggested Action Generic Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_generic_01.png',
    },
    {
        controlId: 'suggested-action-generic-2',
        label: 'Suggested Action Generic 2',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_generic_02.png' alt='Suggested Action Generic 2 Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_generic_02.png',
    },
    {
        controlId: 'suggested-action-generic-3',
        label: 'Suggested Action Generic 3',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_generic_03.png' alt='Suggested Action Generic 3 Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_generic_03.png',
    },
    {
        controlId: 'suggested-action-kindle',
        label: 'Suggested Action Kindle',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_kindle.png' alt='Suggested Action Kindle Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_kindle.png',
    },
    {
        controlId: 'suggested-action-music',
        label: 'Suggested Action Music',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_music.png' alt='Suggested Action Music Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_music.png',
    },
    {
        controlId: 'suggested-action-news',
        label: 'Suggested Action News',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_news.png' alt='Suggested Action News Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_news.png',
    },
    {
        controlId: 'suggested-action-notification',
        label: 'Suggested Action Notification',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_notifications.png' alt='Suggested Action Notification Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_notifications.png',
    },
    {
        controlId: 'suggested-action-original',
        label: 'Suggested Action Original',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_original.png' alt='Suggested Action Original Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_original.png',
    },
    {
        controlId: 'suggested-action-photos',
        label: 'Suggested Action Photos',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_photos.png' alt='Suggested Action Photos Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_photos.png',
    },
    {
        controlId: 'suggested-action-recipes',
        label: 'Suggested Action Recipes',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_recipes.png' alt='Suggested Action Recipes Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_recipes.png',
    },
    {
        controlId: 'suggested-action-reminders',
        label: 'Suggested Action Reminders',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_reminders.png' alt='Suggested Action Reminders Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_reminders.png',
    },
    {
        controlId: 'suggested-action-search',
        label: 'Suggested Action Search',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_search.png' alt='Suggested Action Search Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_search.png',
    },
    {
        controlId: 'suggested-action-shopping',
        label: 'Suggested Action Shopping',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_shopping.png' alt='Suggested Action Shopping Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_shopping.png',
    },
    {
        controlId: 'suggested-action-skills',
        label: 'Suggested Action Skills',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_skills.png' alt='Suggested Action Skills Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_skills.png',
    },
    {
        controlId: 'suggested-action-smart-home',
        label: 'Suggested Action Smart Home',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_smarthome_01.png' alt='Suggested Action Smart Home Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_smarthome_01.png',
    },
    {
        controlId: 'suggested-action-smart-home-2',
        label: 'Suggested Action Smart Home 2',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_smarthome_02.png' alt='Suggested Action Smart Home 2 Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_smarthome_02.png',
    },
    {
        controlId: 'suggested-action-sports',
        label: 'Suggested Action Sports',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_sports.png' alt='Suggested Action Sports Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_sports.png',
    },
    {
        controlId: 'suggested-action-timers',
        label: 'Suggested Action Timers',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_timers.png' alt='Suggested Action Timers Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_timers.png',
    },
    {
        controlId: 'suggested-action-translation',
        label: 'Suggested Action Translation',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_translation.png' alt='Suggested Action Translation Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_translation.png',
    },
    {
        controlId: 'suggested-action-video',
        label: 'Suggested Action Video',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_video.png' alt='Suggested Action Video Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_video.png',
    },
    {
        controlId: 'suggested-action-wallet',
        label: 'Suggested Action Wallet',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_wallet.png' alt='Suggested Action Wallet Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_wallet.png',
    },
    {
        controlId: 'suggested-action-weather',
        label: 'Suggested Action Weather',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_weather_01.png' alt='Suggested Action Weather Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_weather_01.png',
    },
    {
        controlId: 'suggested-action-weather-2',
        label: 'Suggested Action Weather 2',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_weather_02.png' alt='Suggested Action Weather 2 Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_weather_02.png',
    },
    {
        controlId: 'suggested-action-weather-partly-cloudy',
        label: 'Suggested Action Weather Partly Cloudy',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_weather_partly_cloudy.png' alt='Suggested Action Weather Partly Cloudy Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_weather_partly_cloudy.png',
    },
    {
        controlId: 'suggested-action-wiki',
        label: 'Suggested Action Wiki',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_wiki.png' alt='Suggested Action Wiki Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_wiki.png',
    },
    {
        controlId: 'suggested-action-wiki-alt',
        label: 'Suggested Action Wiki Alt',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/wiki_alt.png' alt='Suggested Action Wiki Alt Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/wiki_alt.png',
    },
    {
        controlId: 'alexa-notification',
        label: 'Alexa Notification',
        image: <img src='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_alexa_notifications.png' alt='Suggested Action Wiki Icon' />,
        value: 'https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_alexa_notifications.png',
    },
    {
        controlId: 'audible',
        label: 'Audible',
        image: <img src='https://m.media-amazon.com/images/I/51c7hY94tVL.png' alt='Audible Icon' />,
        value: 'https://m.media-amazon.com/images/I/51c7hY94tVL.png',
    },
    {
        controlId: '3P-skill-radio-disney',
        label: '3P skill: Radio Disney',
        image: <img src='https://images-na.ssl-images-amazon.com/images/I/610R8OtxU4L.png' alt='3P skill: Radio Disney' />,
        value: 'https://images-na.ssl-images-amazon.com/images/I/610R8OtxU4L.png',
    },
    {
        controlId: '3P-skill-disney-hits-challenge',
        label: '3P skill: Disney Hits Challenge',
        image: <img src='https://images-na.ssl-images-amazon.com/images/I/71SJNuWoneL.png' alt='3P skill: Disney Hits Challenge' />,
        value: 'https://images-na.ssl-images-amazon.com/images/I/71SJNuWoneL.png',
    },
    {
        controlId: '3P-skill-good-vibes',
        label: '3P skill: Good Vibes',
        image: <img src='https://images-na.ssl-images-amazon.com/images/I/51IRmyLuHBL.png' alt='3P skill: Good Vibes' />,
        value: 'https://images-na.ssl-images-amazon.com/images/I/51IRmyLuHBL.png',
    },
    {
        controlId: '3P-skill-official-harry-potter-quiz',
        label: '3P skill: Official Harry Potter Quiz',
        image: <img src='https://images-na.ssl-images-amazon.com/images/I/71okaoZx5PL.png' alt='3P skill: Official Harry Potter Quiz' />,
        value: 'https://images-na.ssl-images-amazon.com/images/I/71okaoZx5PL.png',
    },
    {
        controlId: '3P-skill-volley-the-price-is-right',
        label: '3P skill: The Price is Right By Volley Inc.',
        image: <img src='https://m.media-amazon.com/images/I/71m6VoITP1L.png' alt='3P skill: The Price is Right By Volley Inc.' />,
        value: 'https://m.media-amazon.com/images/I/71m6VoITP1L.png',
    },
    {
        controlId: '3P-skill-hugo.fm-mini-games',
        label: '3P skill: Mini Games by Hugo.FM',
        image: <img src='https://m.media-amazon.com/images/I/71GPrtQ3jFL.png' alt='3P skill: Mini Games by HUGO.FM' />,
        value: 'https://m.media-amazon.com/images/I/71GPrtQ3jFL.png',
    },
    {
        controlId: '3P-skill-volley-wheel-of-fortune',
        label: '3P skill: Wheel of Fortune by Volley Inc.',
        image: <img src='https://m.media-amazon.com/images/I/717aoTqBbEL.png' alt='3P skill: Wheel of Fortune by Volley Inc.' />,
        value: 'https://m.media-amazon.com/images/I/717aoTqBbEL.png',
    },
    {
        controlId: '3P-skill-stoked-skills-puzzle-of-the-day',
        label: '3P skill: Puzzle of the Day by Stoked Skills LLC',
        image: <img src='https://m.media-amazon.com/images/I/51wQSMB69dL.png' alt='3P skill: Puzzle of the Day by Stoked Skills LLC' />,
        value: 'https://m.media-amazon.com/images/I/51wQSMB69dL.png',
    },
    {
        controlId: '3P-skill-voice-arcade-trivia-hero',
        label: '3P skill: Trivia Hero by Voice Arcade',
        image: <img src='https://m.media-amazon.com/images/I/61mhLz5bLsL.png' alt='3P skill: Trivia Hero by Voice Arcade' />,
        value: 'https://m.media-amazon.com/images/I/61mhLz5bLsL.png',
    }
];
