import * as React from 'react';
import { Icon } from '@amzn/awsui-components-react';
import { SuggestionStatusBadgeMap } from '../../constants/statusBadgeMap';
import { SuggestionApprovalStatus } from '../../models/homeCardSuggestions/SuggestionApproval';


export const SuggestionStatusBadge = (props: { status: SuggestionApprovalStatus, controlId?: string }) => {
    const { status, controlId } = props;

    if (!SuggestionStatusBadgeMap[status]) {
        return <span id={controlId} >
            <Icon name='status-positive' variant='success' />&nbsp;&nbsp;{status}
        </span>;
    } else {
        const { iconName, iconVariant, beautifiedName } = SuggestionStatusBadgeMap[status];
        return <span id={controlId}>
            <Icon name={iconName} variant={iconVariant} />&nbsp;&nbsp;{beautifiedName}
        </span>;
    }
};
