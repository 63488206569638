import * as React from 'react';
import { Input, Button } from '@amzn/awsui-components-react';
import { connect } from 'react-redux';
import { AppState } from '../reducers/index';
import { getEventTrailAction } from '../actions/getEventTrailActions';
import EventTrailLoadingView from './EventTrailLoading';

interface IInvestigatorProps {
    isLoading: boolean;
    dispatch: any;
    error?: Error;
}

// to include more search criteria, add more states here.
interface IInvestigatorState {
    experienceId: string;
}

export class Investigator extends React.Component<IInvestigatorProps, IInvestigatorState> {
    constructor(props: IInvestigatorProps) {
        super(props);

        this.state = {
            experienceId: ''
        };
    }

    public searchButtonHandler(): void {
        const { dispatch } = this.props;
        const { experienceId } = this.state;

        dispatch(getEventTrailAction(experienceId));
    }

    public experienceIdInputUpdateHandler(event: CustomEvent<Input.ChangeDetail>): void {
        const experienceId = event.detail.value;

        this.setState((prevState) => {
            return {
                ...prevState,
                experienceId
            };
        });
    }

    public render() {
        return <div className='awsui-util-mt-xl awsui-util-ml-xl awsui-util-mr-xl'>
            <div>
                <div className='awsui-util-container'>
                    <div className='awsui-util-container-header'>
                        <div className='awsui-util-action-stripe-large'>
                            <div className='awsui-util-action-stripe-title'>
                                <h2>Odyssey Experience Investigator</h2>
                                <p className='awsui-util-container-header-description'>
                                    Search based on experience IDs and other criteria,
                                    and you can read the history of any experiences.
                                    You can see historical (deleted) experiences as well
                                </p>
                                <Input
                                    placeholder='i.e. 12057a30-2221-4b30-8434-1e1086bb0a7e'
                                    onInput={this.experienceIdInputUpdateHandler.bind(this)}
                                    controlId='investigator.text-input'
                                    id='investigator.text-input'
                                />
                            </div>
                            <div className='awsui-util-action-stripe-group'>
                                <Button
                                    variant='primary'
                                    onClick={this.searchButtonHandler.bind(this)}
                                    id='button.fetch-on-query-params'
                                >Search</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EventTrailLoadingView />
            <div className='awsui-util-mt-l'>
                <div className='awsui-util-container'>
                    <div className='awsui-util-container-header'>
                        <h2>Timeline</h2>
                        <div className='awsui-util-container-header-description'>
                            Type in your user alias, select time window, etc., and get accurate results based on event trail.
                        </div>
                    </div>
                    {/* TODO: https://issues.labcollab.net/browse/ALEXAFA-5648 */}
                    <div className='awsui-util-t-c awsui-util-status-inactive'>Coming soon.</div>
                </div>
            </div>
        </div>;
    }
}

const mapStateToProps = ({ eventTrailState }: AppState) => {
    return {
        isLoading: eventTrailState.isLoading,
        events: eventTrailState.events || undefined,
        error: eventTrailState.error || undefined
    };
};

export default connect(mapStateToProps)(Investigator);
