import { Dispatch } from 'redux';
import { loadingEventTrail, loadedEventTrail, errorLoadingEventTrail } from './experienceDetailViewActions';
import { EventTrailResponse } from '../models/events/EventTrail';
import { getEventTrail } from '../api/acopsGateway';

export const getEventTrailAction = (fullText: string) => async (dispatch: Dispatch) => {

    dispatch(loadingEventTrail());

    try {
        const data: EventTrailResponse = await getEventTrail(fullText);
        dispatch(loadedEventTrail(data));
    } catch (error) {
        dispatch(errorLoadingEventTrail(error));
    }
};
