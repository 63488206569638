import * as React from 'react';
import { Form, FormField, Button, Modal, Alert, Input, Checkbox, ColumnLayout, Textarea } from '@amzn/awsui-components-react';

import { ExternalLink } from '../common/LinkComponents';
import { AppState } from '../../reducers';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { connect } from 'react-redux';
import { generateExperienceApprovalAction } from '../../actions/experienceEditViewActions';
import { REQUESTED_DIALUP_STAGE_OPTIONS } from '../../util/exposureControlAllocationHelper';
import { generateSimApprovalTemplate } from '../../util/simApprovalTemplateHelper';
import { hasOnTheGoDevices } from '../../constants/devices';
import { TESTING_CONFIRMATION_PROMPT } from '../../util/stringAndMappingHelper';

interface SendForApprovalModalProps {
    dispatch: any;
    experience?: IFlattenedExperience;
    isVisible: boolean;
    requiresEmailApproval: boolean;
    hideModal: () => void;
    isPostPaused?: boolean;
    permissions: string[];
}

interface SendForApprovalModalState {
    testingConfirmation?: string;
    userReceivedLegalApproval: boolean;
    userCompletedOutboundBarRaising: boolean;
    userAcknowledgesOtgDevices: boolean;
    cxDescription?: string;
    bullseyeDescription?: string;
    experienceConfigurationDifference?: string;
    isMonetaryExperience?: 'true' | 'false'; // using string boolean for easier checking
    requestedDialupStage?: REQUESTED_DIALUP_STAGE_OPTIONS;
    exceptionReason?: string;
    [key: string]: any;
    enableGrammarSpellingChecks?: 'true' | 'false';
    enableSensitiveContentChecks?: 'true' | 'false';
}

class SendForApprovalModal extends React.Component<SendForApprovalModalProps, SendForApprovalModalState> {
    private static INITIAL_QUESTIONNAIRE_STATE: SendForApprovalModalState = {
        testingConfirmation: undefined,
        userReceivedLegalApproval: false,
        userCompletedOutboundBarRaising: false,
        userAcknowledgesOtgDevices: false,
        cxDescription: undefined,
        bullseyeDescription: undefined,
        experienceConfigurationDifference: undefined,
        isMonetaryExperience: undefined,
        requestedDialupStage: undefined,
        exceptionReason: undefined,
        enableGrammarSpellingChecks: 'true',
        enableSensitiveContentChecks: 'true'
    };

    constructor(props: SendForApprovalModalProps) {
        super(props);
        this.state = SendForApprovalModal.INITIAL_QUESTIONNAIRE_STATE;
    }

    updateState(object: { [key: string]: any }) {
        this.setState(object);
    }

    componentDidUpdate(prevProps: SendForApprovalModalProps) {
        // if modal becomes visible again, need to clear off all previous state to a fresh state
        if(this.props.isVisible === true && prevProps.isVisible === false) {
            this.setState(SendForApprovalModal.INITIAL_QUESTIONNAIRE_STATE);
        }
    }

    private validateTemplateCompletion(): boolean {
        const { experience, requiresEmailApproval, isPostPaused } = this.props;
        const {
            testingConfirmation,
            userReceivedLegalApproval,
            userCompletedOutboundBarRaising,
            userAcknowledgesOtgDevices,
            cxDescription,
            bullseyeDescription,
            experienceConfigurationDifference,
            isMonetaryExperience,
            requestedDialupStage,
            exceptionReason,
            enableGrammarSpellingChecks,
            enableSensitiveContentChecks
        } = this.state;

        const hasOtgDevices = hasOnTheGoDevices((experience?.enabledFilter?.deviceTypes) || []);

        return testingConfirmation === TESTING_CONFIRMATION_PROMPT
                && (!requiresEmailApproval || (userReceivedLegalApproval && userCompletedOutboundBarRaising))
                && !!cxDescription
                && !!bullseyeDescription
                && (isPostPaused ? !!experienceConfigurationDifference : true)
                && !!isMonetaryExperience
                && ((requestedDialupStage !== undefined) === !!exceptionReason)
                && (hasOtgDevices === userAcknowledgesOtgDevices)
                && !!enableGrammarSpellingChecks
                && !!enableSensitiveContentChecks;
    }

    private approveButtonClickHandler() {
        const { dispatch, hideModal, experience, requiresEmailApproval, isPostPaused } = this.props;
        const {
            testingConfirmation,
            userReceivedLegalApproval,
            userCompletedOutboundBarRaising,
            userAcknowledgesOtgDevices,
            cxDescription,
            bullseyeDescription,
            experienceConfigurationDifference,
            isMonetaryExperience,
            requestedDialupStage,
            exceptionReason,
            enableGrammarSpellingChecks,
            enableSensitiveContentChecks
        } = this.state;

        const userHasTestedExperience = testingConfirmation === TESTING_CONFIRMATION_PROMPT;
        const shouldGrammarSpellingChecksBeEnabled = (enableGrammarSpellingChecks === 'true');
        const shouldSensitiveContentChecksBeEnabled = (enableSensitiveContentChecks === 'true');

        if (this.validateTemplateCompletion()) {
            hideModal();
            dispatch(generateExperienceApprovalAction(
                requestedDialupStage !== undefined,
                requestedDialupStage,
                exceptionReason,
                shouldGrammarSpellingChecksBeEnabled,
                shouldSensitiveContentChecksBeEnabled,
                generateSimApprovalTemplate(
                    experience!, userHasTestedExperience, requiresEmailApproval, userReceivedLegalApproval,
                    userCompletedOutboundBarRaising, userAcknowledgesOtgDevices, cxDescription!, bullseyeDescription!,
                    isMonetaryExperience!, requestedDialupStage, exceptionReason!, isPostPaused ? experienceConfigurationDifference! : undefined)
                )
            );
        }
    }

    render() {
        const {
            isVisible,
            requiresEmailApproval,
            experience,
            hideModal,
            isPostPaused,
            permissions
        } = this.props;
        const {
            testingConfirmation,
            userReceivedLegalApproval,
            userCompletedOutboundBarRaising,
            cxDescription,
            bullseyeDescription,
            experienceConfigurationDifference,
            isMonetaryExperience,
            enableGrammarSpellingChecks,
            enableSensitiveContentChecks
        } = this.state;

        if (experience === undefined || experience === null) {
            return <Modal>
                <div className='awsui-util-inactive'>
                    You cannot send an experience for approval right now. Please try again later.
                </div>
            </Modal>;
        }

        if ( typeof experience.referralContent?.sonarTemplateURI === 'string' && experience.referralContent?.sonarTemplateURI !== ''
        && !experience.referralContent?.sonarTemplateURI?.includes('/LAYOUT-TEMPLATES/')){
            return <Modal
                id='modal.suggest-sonar-v2-migration'
                visible={isVisible}
                header='Migrate Sonar to V2'
                onDismiss={hideModal.bind(this)}
            >
                <div className='awsui-util-mt-l'>
                    Sonar Template V1 has been deprecated on Odyssey. Kindly upgrade to V2. <br/>
                    https://w.amazon.com/bin/view/OutboundCommunications/Sonar/Templatev2/.
                </div>
            </Modal>;
        }

        const isAdmin: boolean = (permissions.includes('ADMIN'));
        const bullseyeConfigured = (experience?.bullseyeSegment != null);

        const hasOtgDevices = hasOnTheGoDevices((experience?.enabledFilter?.deviceTypes) || []);

        return <Modal
            id='modal.send-for-approval'
            visible={isVisible}
            header='Send for Approval'
            onDismiss={hideModal.bind(this)}
        >

            {!bullseyeConfigured &&
                <Alert
                    visible={true}
                    type='error'
                    id='alert.bullseye-not-configured'>
                        Warning: Your experience does not have bullseye configured which may result in
                        non-relevant suggestions
                </Alert>}
            <Form
                actions={<div className='awsui-util-mt-l'>
                    <Button
                        id='button.send-for-approval-cancel'
                        variant='link'
                        onClick={hideModal.bind(this)}>
                        Cancel
                    </Button>
                    <Button variant='primary'
                        id='button.send-for-approval-submit'
                        disabled={!this.validateTemplateCompletion()}
                        onClick={this.approveButtonClickHandler.bind(this)}>
                        Send for approval
                    </Button>
                </div>}
            >
                <ColumnLayout>
                    <div data-awsui-column-layout-root='true'>
                        <p>
                            Visit&nbsp;
                            <ExternalLink href={'https://w.amazon.com/bin/view/FindingYourCustomerID/'}>here</ExternalLink>
                            to find your Customer ID if logged in to your Amazon shopping account.
                        </p>
                        <FormField
                            label='Testing Confirmation'
                            description='Please type `I have fully tested this experience` to confirm testing.'>
                            <Input
                                id='experience.send-for-approval-user-has-tested'
                                placeholder='I have fully tested this experience'
                                name='testingConfirmation'
                                value={testingConfirmation}
                                onChange={e => this.updateState({ testingConfirmation: e.detail.value.trim() })}
                            />
                        </FormField>
                        <p>Please note that the experience owner is solely responsible for the testing and verification of the experience being sent for approval</p>
                        {requiresEmailApproval && <Checkbox
                            id='experience.send-for-approval-user-has-legal'
                            checked={userReceivedLegalApproval}
                            onChange = { e => this.updateState({
                                userReceivedLegalApproval: e.detail.checked
                            })}
                        >
                            I have approval from my legal team for sending the sonar template <b>{experience.referralContent?.campaignId || experience.referralContent?.sonarTemplateURI}</b>.
                        </Checkbox>}
                        {requiresEmailApproval && <Checkbox
                            id='experience.send-for-approval-user-has-obr'
                            checked={userCompletedOutboundBarRaising}
                            onChange = { e => this.updateState({
                                userCompletedOutboundBarRaising: e.detail.checked
                            })}
                        >
                            I have approval from an <ExternalLink href={'https://w.amazon.com/bin/view/Email/Marketing/Find_Your_OBR/'}>email bar raiser</ExternalLink> for
                            sending the sonar template <b>{experience.referralContent?.campaignId || experience.referralContent?.sonarTemplateURI}</b>.
                        </Checkbox>}
                        {hasOtgDevices && <Checkbox
                            id='experience.send-for-approval-user-has-otg-device'
                            checked={this.state.userAcknowledgesOtgDevices}
                            onChange = {e => this.updateState({
                                userAcknowledgesOtgDevices: e.detail.checked
                            })}>
                            I acknowledge that I have added OTG (On-The-Go) devices as part of my experience configuration.
                        </Checkbox>}
                    </div>
                </ColumnLayout>
                <hr />
                <ColumnLayout>
                    <div data-awsui-column-layout-root='true'>
                        <FormField label='Describe the CX in detail'>
                            <Textarea
                                id='approval.cxDescription'
                                placeholder='Experience CX description'
                                name='cxDescription'
                                value={cxDescription}
                                onChange={e => this.updateState({ cxDescription: e.detail.value })}
                            />
                        </FormField>
                        <FormField label='Describe your Bullseye segment in detail'>
                            <Textarea
                                id='approval.bullseyeDescription'
                                placeholder='What segment of customers is your Bullseye intended to target?'
                                name='bullseyeDescription'
                                value={bullseyeDescription}
                                onChange={e => this.updateState({ bullseyeDescription: e.detail.value })}
                            />
                        </FormField>
                        {isPostPaused && <FormField label='Describe changes made to experience post pause'>
                            <Textarea
                                id='approval.experienceConfigurationDifference'
                                placeholder='What changes are made post pause?'
                                name='experienceConfigurationDifference'
                                value={experienceConfigurationDifference}
                                onChange={e => this.updateState({ experienceConfigurationDifference: e.detail.value })}
                            />
                        </FormField>}
                        <FormField label='Does this experience provide any monetary value (cash rewards, discounts, etc.) to Alexa customers?'>
                            <div className='awsui-grid'>
                                <div className='awsui-row awsui-util-t-c'>
                                    <div className='col-3'>
                                        <Checkbox
                                            id='approval.isMonetaryExperience.y'
                                            name='isMonetaryExperience.y'
                                            checked={isMonetaryExperience === 'true'}
                                            onChange={e => this.updateState({ isMonetaryExperience: String(e.detail.checked) })}
                                        >
                                            Yes
                                        </Checkbox>
                                    </div>
                                    <div className='col-3'>
                                        <Checkbox
                                            id='approval.isMonetaryExperience.n'
                                            name='isMonetaryExperience.n'
                                            checked={isMonetaryExperience === 'false'}
                                            onChange={e => this.updateState({ isMonetaryExperience: String(!e.detail.checked) })}
                                        >
                                            No
                                        </Checkbox>
                                    </div>
                                </div>
                            </div>
                        </FormField>
                        <FormField label='Should the experience content have grammar/spelling checks enabled?'>
                            <div className='awsui-grid'>
                                <div className='awsui-row awsui-util-t-c'>
                                    <div className='col-3'>
                                        <Checkbox
                                            id='approval.enableGrammarSpellingChecks.y'
                                            name='enableGrammarSpellingChecks.y'
                                            checked={enableGrammarSpellingChecks === 'true'}
                                            onChange={e => this.updateState({ enableGrammarSpellingChecks: String(e.detail.checked) })}
                                        >
                                            Yes
                                        </Checkbox>
                                    </div>
                                    <div className='col-3'>
                                        <Checkbox
                                            id='approval.enableGrammarSpellingChecks.n'
                                            name='enableGrammarSpellingChecks.n'
                                            checked={enableGrammarSpellingChecks === 'false'}
                                            onChange={e => this.updateState({ enableGrammarSpellingChecks: String(!e.detail.checked) })}
                                        >
                                            No
                                        </Checkbox>
                                    </div>
                                </div>
                            </div>
                        </FormField>
                        {isAdmin &&
                        <FormField label='Should the experience content have sensitive content checks enabled?'>
                            <div className='awsui-grid'>
                                <div className='awsui-row awsui-util-t-c'>
                                    <div className='col-3'>
                                        <Checkbox
                                            id='approval.enableSensitiveContentChecks.y'
                                            name='enableSensitiveContentChecks.y'
                                            checked={enableSensitiveContentChecks === 'true'}
                                            onChange={e => this.updateState({ enableSensitiveContentChecks: String(e.detail.checked) })}
                                        >
                                            Yes
                                        </Checkbox>
                                    </div>
                                    <div className='col-3'>
                                        <Checkbox
                                            id='approval.enableSensitiveContentChecks.n'
                                            name='enableSensitiveContentChecks.n'
                                            checked={enableSensitiveContentChecks === 'false'}
                                            onChange={e => this.updateState({ enableSensitiveContentChecks: String(!e.detail.checked) })}
                                        >
                                            No
                                        </Checkbox>
                                    </div>
                                </div>
                            </div>
                        </FormField> }
                    </div>
                </ColumnLayout>
                {/* TODO: add exceptional approval in Send for Approval screen once we prioritize this feature */}
                {/* <hr />
                <ColumnLayout>
                    <div data-awsui-column-layout-root='true'>
                        <FormField label='Do you need to make an exception request for increasing the exposure control treatment? You must provide a qualifying reason.' >
                            <Select
                                id='approval.requestedDialupStage'
                                controlId=''
                                options={DIRECT_EXCEPTION_APPROVAL_ALLOCATION_DROPDOWN_OPTIONS}
                                selectedId={requestedDialupStage === undefined ? 'NO_EXCEPTION' : requestedDialupStage}
                                onChange={e => this.updateState({
                                    requestedDialupStage: e.detail.selectedId === 'NO_EXCEPTION'
                                        ? undefined
                                        : e.detail.selectedId
                                })} />
                        </FormField>
                        {requestedDialupStage !== undefined && <FormField label='Provide qualifying reason'>
                            <Textarea
                                id='approval.exceptionReason'
                                controlId='approval.exceptionReason'
                                placeholder='Why do you want more than 10% exposure on approval?'
                                name='exceptionReason'
                                value={exceptionReason}
                                onChange={e => this.updateState({ exceptionReason: e.detail.value })}
                            />
                        </FormField>}
                    </div>
                </ColumnLayout> */}
            </Form>
        </Modal>;
    }
}

const mapStateToProps = ({ experienceDetailViewState,  authenticationState }: AppState) => {
    return {
        experience: experienceDetailViewState.experience,
        permissions: authenticationState.permissions || []
    };
};

export default connect(mapStateToProps)(SendForApprovalModal);
