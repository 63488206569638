import * as React from 'react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { ColumnLayout, FormField, Button, Spinner, Alert } from '@amzn/awsui-components-react';
import { InvocationParameterView } from '../../components/common/InvocationParametersView';
import { IRemoteContextualSegments, EMPTY_REMOTE_CONTEXTUAL_SEGMENT } from '../../models/RemoteContextualSegments';
import { IInvocationParameters } from '../../models/InvocationParameters';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { pushRemoteTagsSectionAction } from '../../actions/experienceEditViewActions';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { InputWrapper } from '../../components/common/InputWrapper';
import { TextAreaWrapper } from '../../components/common/TextAreaWrapper';
import { CIFValidator } from '../../util/CIFValidator';

interface IRemoteTagsSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

interface IRemoteTagsSectionViewState {
    remoteContextualSegments?: IRemoteContextualSegments;
}

export class RemoteTagsSectionView extends React.Component<IRemoteTagsSectionViewProps, IRemoteTagsSectionViewState> {

    constructor(props: IRemoteTagsSectionViewProps) {
        super(props);
        const { updateCandidate } = this.props;
        this.state = {remoteContextualSegments: updateCandidate?.getRemoteTags()};
    }

    updateRemoteTags(remoteContextualSegments: IRemoteContextualSegments) {
        const { dispatch } = this.props;
        dispatch(pushRemoteTagsSectionAction(remoteContextualSegments));
        this.setState({remoteContextualSegments});
    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate } = this.props;

        if (!experience) {
            return <FormSectionView title={'Remote Tags'}>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const remoteContextualSegments = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
            ? updateCandidate.getRemoteTags()
            : ExperienceUpdateCandidate.extractRemoteContextualSegments(experience));

        const includeRemoteSegments = remoteContextualSegments.includeRemoteSegments || [];
        const excludeRemoteSegments = remoteContextualSegments.excludeRemoteSegments || [];

        const shouldDisableInput = !isEditing || isUpdating;

        return <FormSectionView
            title='Remote Tags'
            description='Tags provided by your remote service at runtime.
                You will be requested to provide service invocation parameters below.'>

            {(includeRemoteSegments.length > 0 || excludeRemoteSegments.length > 0) &&
            <Alert>
                The launch Weblab for an invocation parameter is checked before the configured DVP is called, if T1 is returned the call is made.
                If not T1 the experience is filtered out.
                The intention is to use this when initially integrating a DVP to allow service to control how ACOPS is calling them.
                If a DVP is configured across multiple experiences this is a more convenient way to control traffic to that DVP
            </Alert>}
            <br></br>
            <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root='true'>
                    <FormSectionView className='awsui-util-mb-xl'
                        title='Inclusion tags'
                        description='List of remote tags that ALL MUST evaluate to TRUE for this experience to match the incoming opportunity.' >
                        {(includeRemoteSegments && includeRemoteSegments.length > 0)
                            ? includeRemoteSegments.map((remoteTag, index) =>
                                <div className='awsui-grid'>
                                    <div className='awsui-row'>
                                        <div className='col-10'>
                                            <ColumnLayout columns={3}>
                                                <div data-awsui-column-layout-root='true'>
                                                    <FormField label={<RequiredField fieldName='Tag name' />}>
                                                        <InputWrapper
                                                            id={`experience.inclusion-tag-name-${index}`}
                                                            readonly={shouldDisableInput}
                                                            value={remoteTag.name}
                                                            onInput={(input: string) => {
                                                                includeRemoteSegments[index].name = input;
                                                                this.updateRemoteTags({includeRemoteSegments,
                                                                    excludeRemoteSegments});
                                                            }}
                                                            validate={CIFValidator.isRemoteSegmentNameValid} />
                                                    </FormField>
                                                    <FormField label={<RequiredField fieldName='Tag description' />}>
                                                        <TextAreaWrapper
                                                            id={`experience.inclusion-tag-description-${index}`}
                                                            readonly={shouldDisableInput}
                                                            value={remoteTag.description}
                                                            onInput={(input: string) => {
                                                                includeRemoteSegments[index].description = input;
                                                                this.updateRemoteTags({includeRemoteSegments,
                                                                    excludeRemoteSegments});
                                                            }}
                                                            validate={CIFValidator.isRemoteSegmentDescriptionValid} />
                                                    </FormField>
                                                </div>
                                            </ColumnLayout>
                                            {remoteTag.invocationParameters &&
                                                <InvocationParameterView
                                                    arrayKey={'' + index + '-inclusion'}
                                                    invocationParameters={remoteTag.invocationParameters}
                                                    isNotificationExperience={false}
                                                    disableInput={shouldDisableInput}
                                                    updateInvocationParameters={
                                                    (invocationParameters: IInvocationParameters) => {
                                                            includeRemoteSegments[index]
                                                                .invocationParameters = invocationParameters;
                                                            this.updateRemoteTags({includeRemoteSegments,
                                                                excludeRemoteSegments});
                                                    }} />}
                                        </div>
                                        <div className='col-2' style={{ paddingTop: '35px' }}>
                                            <Button
                                                id={`button.remove-inclusion-remote-tag-${index}`}
                                                disabled={shouldDisableInput}
                                                onClick={() => {
                                                    includeRemoteSegments.splice(index, 1);
                                                    this.updateRemoteTags({includeRemoteSegments, excludeRemoteSegments});
                                            }}>Remove</Button>
                                        </div>
                                    </div>
                                    <hr />
                                </div>)
                            : <p className='awsui-util-status-inactive'>
                                No inclusion remote tags configured for this experience</p>}
                        <div className='awsui-util-mt-l awsui-util-mb-l'>
                            <Button
                                id='button.add-inclusion-remote-tag'
                                disabled={shouldDisableInput}
                                onClick={() => {
                                    includeRemoteSegments.push(EMPTY_REMOTE_CONTEXTUAL_SEGMENT);
                                    this.updateRemoteTags({includeRemoteSegments, excludeRemoteSegments});
                                }} >Add inclusion tag</Button>
                        </div>
                    </FormSectionView>
                    <FormSectionView className='awsui-util-mb-xl'
                        title='Exclusion tags'
                        description='List of remote tags that ALL MUST evaluate to FALSE for this experience to match the incoming opportunity.' >
                        {(excludeRemoteSegments && excludeRemoteSegments.length > 0)
                            ? excludeRemoteSegments.map((remoteTag, index) =>
                                <div className='awsui-grid'>
                                    <div className='awsui-row'>
                                        <div className='col-10'>
                                            <ColumnLayout columns={3}>
                                                <div data-awsui-column-layout-root='true'>
                                                    <FormField label={<RequiredField fieldName='Tag name' />}>
                                                        <InputWrapper
                                                            id={`experience.exclusion-tag-name-${index}`}
                                                            readonly={shouldDisableInput}
                                                            value={remoteTag.name}
                                                            onInput={(input: string) => {
                                                                excludeRemoteSegments[index].name = input;
                                                                this.updateRemoteTags({includeRemoteSegments,
                                                                    excludeRemoteSegments});
                                                            }}
                                                            validate={CIFValidator.isRemoteSegmentNameValid} />
                                                    </FormField>
                                                    <FormField label={<RequiredField fieldName='Tag description' />}>
                                                        <TextAreaWrapper
                                                            id={`experience.exclusion-tag-description-${index}`}
                                                            readonly={shouldDisableInput}
                                                            value={remoteTag.description}
                                                            onInput={(input: string) => {
                                                                excludeRemoteSegments[index].description = input;
                                                                this.updateRemoteTags({includeRemoteSegments,
                                                                    excludeRemoteSegments});
                                                            }}
                                                            validate={CIFValidator.isRemoteSegmentDescriptionValid} />
                                                    </FormField>
                                                </div>
                                            </ColumnLayout>
                                            {remoteTag.invocationParameters &&
                                                <InvocationParameterView
                                                    arrayKey={'' + index + '-exclusion'}
                                                    invocationParameters={remoteTag.invocationParameters}
                                                    isNotificationExperience={false}
                                                    disableInput={shouldDisableInput}
                                                    updateInvocationParameters={
                                                        (invocationParameters: IInvocationParameters) => {
                                                            excludeRemoteSegments[index]
                                                                .invocationParameters = invocationParameters;
                                                            this.updateRemoteTags({includeRemoteSegments,
                                                                excludeRemoteSegments});
                                                    }} />}
                                        </div>
                                        <div className='col-2' style={{ paddingTop: '35px' }}>
                                            <Button
                                                id={`button.remove-exclusion-remote-tag-${index}`}
                                                disabled={shouldDisableInput}
                                                onClick={() => {
                                                    excludeRemoteSegments.splice(index, 1);
                                                    this.updateRemoteTags({includeRemoteSegments, excludeRemoteSegments});
                                            }}>Remove</Button>
                                        </div>
                                    </div>
                                    <hr />
                                </div>)
                            : <p className='awsui-util-status-inactive'>
                                No exclusion remote tags configured for this experience</p>}
                        <div className='awsui-util-mt-l awsui-util-mb-l'>
                            <Button
                                id='button.add-exclusion-remote-tag'
                                disabled={shouldDisableInput}
                                onClick={() => {
                                    excludeRemoteSegments.push(EMPTY_REMOTE_CONTEXTUAL_SEGMENT);
                                    this.updateRemoteTags({includeRemoteSegments, excludeRemoteSegments});
                                }} >Add exclusion tag</Button>
                        </div>
                    </FormSectionView>
                </div>
            </ColumnLayout>
        </FormSectionView>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(RemoteTagsSectionView);
