import { axios } from './axios';
import { API_ENDPOINT, GET_ODYSSEY_CONFIGURATION_PATH, PUT_ODYSSEY_CONFIGURATION_PATH } from '../constants/lambdaEndpoints';
import { IBypassPauseList } from '../models/bypassPauseList/BypassPauseList';

export const BYPASS_LIST_CONFIGURATION_FILE = 'bypasspause';

export async function getBypassPauseList(): Promise<IBypassPauseList | undefined> {
    const response = await axios.get(`${API_ENDPOINT}${GET_ODYSSEY_CONFIGURATION_PATH}/${BYPASS_LIST_CONFIGURATION_FILE}`);

    if (response.status === 204) {
        return undefined;
    }

    return response.data;
}

export async function putBypassPauseList(bypassPauseList: IBypassPauseList): Promise<void> {
    const response = await axios.put(`${API_ENDPOINT}${PUT_ODYSSEY_CONFIGURATION_PATH}/${BYPASS_LIST_CONFIGURATION_FILE}`, bypassPauseList);

    return response.data;
}
