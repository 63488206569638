import * as React from 'react';
import RCPDisplayElementsSectionView from '../../../containers/sections/RCPDisplayElementsSectionView';
import MetadataSectionView from '../../../containers/sections/MetadataSectionView';
import TouchActionsSectionView from '../../../containers/sections/RCPTouchActionsSectionView';
import RCPDevicesSectionView from '../../../containers/sections/RCPDevicesSectionView';
import PromotedCapabilitySectionView from '../../../containers/sections/PromotedCapabilitySectionView';

export const RotatingContentPanelContentTabView = () => {
    return <div>
        <RCPDisplayElementsSectionView />
        <TouchActionsSectionView />
        <PromotedCapabilitySectionView />
        <RCPDevicesSectionView />
        <MetadataSectionView />
    </div>;
};
