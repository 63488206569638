import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, ColumnLayout, FormField, Input } from '@amzn/awsui-components-react';
import _ from 'lodash';
import { updateCurrentProviderConfigAction, updateFormValidationSectionAction } from '../../../actions/spiProviderOnboardingActions';
import { IProviderConfig } from '../../../models/spiProviderConfig/ISpiProviderOnboarding';
import { validateProviderIdAndCti } from '../../../util/spiProviderOnboardingHelper';
import { AppState } from '../../../reducers';
import { FormSectionView } from '../../../components/common/FormSectionView';

const SpiProviderOnboardingSmallFieldSectionView: React.FC = () => {

    const { currentProviderConfig, isEditing, isOnboardingProvider } = useSelector((state: AppState) => state.spiProviderOnboardingState);
    const dispatch = useDispatch();

    const [currentFieldsState, setFieldsState] = React.useState({
        providerId: currentProviderConfig?.providerId,
        cti: currentProviderConfig?.cti
    });

    React.useEffect(() => {
        setFieldsState({
            providerId: currentProviderConfig?.providerId,
            cti: currentProviderConfig?.cti
        });
    }, [currentProviderConfig]);

    const initializeValidationState =  React.useCallback(() => {
        if (!isOnboardingProvider) { // updating config case
          return {
            invalidProviderId: false,
            invalidCti: false,
          };
        } else {
          return { // creating config case
            invalidProviderId: true,
            invalidCti: true,
          };
        }
    }, [isOnboardingProvider]);

    const [validationState, setValidationState] = React.useState(initializeValidationState());

    React.useEffect(() => {
        setValidationState(initializeValidationState());
    }, [isEditing, initializeValidationState]);

    React.useEffect(() => {
        const isSectionValid = Object.values(validationState).every(value => !value);
        dispatch(updateFormValidationSectionAction('invalidSmallFieldSection', !isSectionValid));
    }, [validationState, dispatch]);

    const onChangeHandler = (event: CustomEvent<Input.ChangeDetail>) => {
        const field = (event.target as HTMLElement).getAttribute('id')?.split('.').pop();
        if (field) {
            const newFieldsState = _.cloneDeep(currentFieldsState);
            switch (field) {
                case 'providerId':
                    newFieldsState.providerId = event.detail.value;
                    break;
                case 'cti':
                    newFieldsState.cti = event.detail.value;
                    break;
            }
            setFieldsState(newFieldsState);
            dispatch(updateCurrentProviderConfigAction(newFieldsState as IProviderConfig));
            validateProviderIdAndCti(field, validationState, newFieldsState, setValidationState);
        }
    };

    const onCheckboxChangeHandler = (event: CustomEvent<Checkbox.ChangeDetail>) => {
        dispatch(updateCurrentProviderConfigAction({ isBatchProvider: event.detail.checked } as IProviderConfig));
    };

    return(
        <div>
            <FormSectionView title={'Provider Details'}>
                {isOnboardingProvider? // creating config requires providerId field
                    <ColumnLayout columns={3} borders={'vertical'}>
                        <div data-awsui-column-layout-root='true'>
                            <FormField
                                label={<div><strong>Provider Id </strong> <i className='awsui-text-secondary'> (required) </i></div>}>
                                <Input
                                    id='create.providerConfig.providerId'
                                    placeholder={'ExampleProvider'}
                                    invalid={validationState.invalidProviderId}
                                    value={currentFieldsState.providerId}
                                    readonly={!isEditing}
                                    onChange={onChangeHandler}
                                />
                            </FormField>
                            <FormField
                                label={<div><strong>CTI </strong> <i className='awsui-text-secondary'> (required) </i></div>}>
                                <Input
                                    id='create.providerConfig.cti'
                                    placeholder={'Alexa/Example/Experiences'}
                                    invalid={validationState.invalidCti}
                                    value={currentFieldsState.cti}
                                    readonly={!isEditing}
                                    onChange={onChangeHandler}
                                />
                            </FormField>
                            <div>
                                <br/>
                                <Checkbox
                                    label={<strong>Is Batch Provider </strong>}
                                    id='create.providerConfig.isBatchProvider'
                                    checked={!!(currentProviderConfig?.isBatchProvider)}
                                    disabled={!isEditing}
                                    onChange={onCheckboxChangeHandler}
                                />
                            </div>
                        </div>
                    </ColumnLayout>
                    :
                    // should not be able to modify provider Id when updating config
                    <ColumnLayout columns={2} borders={'vertical'}>
                        <div data-awsui-column-layout-root='true'>
                            <FormField
                                label={<div><strong>CTI </strong> <i className='awsui-text-secondary'> (required) </i></div>}>
                                <Input
                                    id='update.providerConfig.cti'
                                    placeholder={'Alexa/Example/Experiences'}
                                    invalid={validationState.invalidCti}
                                    value={currentFieldsState.cti}
                                    readonly={!isEditing}
                                    onChange={onChangeHandler}
                                />
                            </FormField>
                            <div>
                                <br/>
                                <Checkbox
                                    label={<strong>Is Batch Provider </strong>}
                                    id='update.providerConfig.isBatchProvider'
                                    checked={!!(currentProviderConfig?.isBatchProvider)}
                                    disabled={!isEditing}
                                    onChange={onCheckboxChangeHandler}
                                />
                            </div>
                        </div>
                    </ColumnLayout>
                }
            </FormSectionView>
        </div>
    );
};

export default SpiProviderOnboardingSmallFieldSectionView;