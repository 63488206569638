import * as React from 'react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { FormSectionView } from '../../components/common/FormSectionView';
import { FormField, ColumnLayout, Input, Spinner, Button, Alert } from '@amzn/awsui-components-react';
import { connect } from 'react-redux';
import { AppState } from '../../reducers/index';
import { ExperienceUpdateCandidate, IBasicInfoViewAttributes } from '../../models/ExperienceUpdateCandidate';
import { pushBasicInfoSectionAction } from '../../actions/experienceEditViewActions';
import { getRole } from '../../util/stringAndMappingHelper';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { CommonValidator } from '../../util/CommonValidator';
import DatePickerWrapper from '../../components/common/DatePickerWrapper';
import moment from 'moment';


interface IBasicInfoSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    isCloning: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

export const isNotPastDate = (date: Date) => {
    const today = new Date();
    today.setHours(0,0,0,0);
    return date >= today;
};

export const cifActivationDateDescription = 'Date range over which experience will be served, ' +
                'An CIF experience can run for a maximum of 60 days before it is evaluated to be dialed up or down. ' +
                'Once an experience is launched, then end date can be edited or removed.';

export const isNotFutureDate = (date: Date) => {
    const future = new Date();
    future.setDate(future.getDate() + 14);
    return isNotPastDate(date) && date < future;
};

export class BasicInfoSectionView extends React.Component<IBasicInfoSectionViewProps> {
    updateBasicInfo(title?: string, groupPermissions?: string, startDate?: string, endDate?: string) {
        const { dispatch } = this.props;
        if (this.props.updateCandidate !== undefined &&
            this.props.updateCandidate.getType() === 'CIF' && startDate) {
            const dateTime:Date = new Date(startDate);
            dateTime.setDate(dateTime.getDate() + 60);
            endDate = moment(dateTime.toLocaleDateString()).format('YYYY-MM-DD');
        }
        dispatch(pushBasicInfoSectionAction(title, groupPermissions, startDate, endDate));
    }
    removeEndDateButtonClickHandler () {
        this.updateBasicInfo(undefined, undefined, undefined, '');
    }

    isWithinSixtyDaysAfterStartDate = (date: Date) => {
        const currentStartDate: string|undefined = this.props.updateCandidate?.getBasicInfo().startDate;
        const startDateOrNow: Date = new Date(currentStartDate? currentStartDate : '');
        const sixtyDaysFromStartDate: Date = new Date(currentStartDate? currentStartDate : '');
        sixtyDaysFromStartDate.setDate(sixtyDaysFromStartDate.getDate() + 60);
        return startDateOrNow <= date && date <= sixtyDaysFromStartDate;
    }

    render () {
        const { experience, isEditing, isUpdating, updateCandidate, isCloning } = this.props;

        if (!experience) {
            return <FormSectionView title='Basic Information'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const renderData: IBasicInfoViewAttributes = (isEditing && updateCandidate)
            ? updateCandidate.getBasicInfo()
            : ExperienceUpdateCandidate.extractBasicInfo(experience);

        const shouldRestrictDateRange = updateCandidate !== undefined &&
            updateCandidate.getType() !== 'CIF' && updateCandidate.getDataSourceInfo().dataSource === 'SINGLE_EXECUTION_BULLSEYE';
        const shouldDisableInput = !isEditing || isUpdating;
        const activationDateLabel = experience.type === 'CIF' ? 'Activation Dates' : <RequiredField fieldName='Activation Dates' />;
        const notificationActivationDateDescription = 'Define the date range when your notification will be sent to customers.';
        const activationDateDescription = experience.type === 'CIF'
            ? cifActivationDateDescription
            : notificationActivationDateDescription;
        const isSameDayActivation = renderData.startDate && renderData.startDate === moment(new Date()).format('YYYY-MM-DD');

        return <FormSectionView title='Basic Information'>
            <div className='awsui-util-mb-m'>
                <FormField
                    label={<RequiredField fieldName='Experience title' />}
                    description='This title was set when you first created the experience and cannot be changed. It is used for tracking and searching.'
                >
                    {/* title is only changed on cloning or legacy experience that doesn't have title */}
                    <Input
                        readonly={!isEditing || (!isCloning && !!renderData.title)}
                        placeholder='ex. ALEXA_FA_CIF_PODCAST_COMMUTE_GENERIC_263599'
                        value={renderData.title}
                        onChange={(e) => this.updateBasicInfo(e.detail.value.trim())}
                        controlId='experience.title'
                        id='experience.title' />
                </FormField>
            </div>

            <div className='awsui-util-mb-m'>
                <FormField
                    label='Operator Group'
                    description='All members of this LDAP group will have access to edit this experience.'
                >
                    <Input
                        readonly={(shouldDisableInput
                            || ['VIEWER', 'OPERATOR'].includes(getRole(experience.permissionRole))) && !isCloning}
                        placeholder='ex. alexa-fa-dev'
                        value={renderData.groupImpressions}
                        onChange={(e) => this.updateBasicInfo(undefined, e.detail.value)}
                        controlId='experience.groupImpressions'
                        id='experience.groupImpressions'
                    />
                </FormField>
            </div>
            {experience.type !== 'MobileHomeCard' && <FormField label={activationDateLabel} description={activationDateDescription}>
                <ColumnLayout columns={3}>
                    <div data-awsui-column-layout-root='true'>
                        <FormField label='Start Date'>
                            <DatePickerWrapper
                                onChange={(e) => {
                                    this.updateBasicInfo(undefined, undefined, e.detail.value);
                                }}
                                id='experience.start-date'
                                disabled={shouldDisableInput}
                                startDate={renderData.startDate}
                                value={renderData.startDate || ''}
                                endDate={renderData.endDate}
                                isDateEnabled={shouldRestrictDateRange ? isNotFutureDate : isNotPastDate}
                                checkChangeControl= {true}
                                region = {renderData.region}
                            />
                            {!shouldDisableInput && experience.type !== 'CIF' && isSameDayActivation &&
                                    <Alert
                                        id='experience.same-day-activation'
                                        visible={true}
                                        type='info'
                                    >
                                    Notifications need time to be scheduled and may not be sent at the same day.
                            </Alert>}
                        </FormField>
                        <FormField label='End Date'>
                            <DatePickerWrapper
                                onChange={(e) => {
                                    this.updateBasicInfo(undefined, undefined, undefined, e.detail.value);
                                }}
                                id='experience.end-date'
                                disabled={shouldDisableInput || (!renderData.startDate && experience.type === 'CIF')}
                                startDate={renderData.startDate}
                                endDate={renderData.endDate}
                                value={renderData.endDate || ''}
                                isDateEnabled={shouldRestrictDateRange
                                    ? isNotFutureDate
                                    : experience.type === 'CIF'
                                        ? this.isWithinSixtyDaysAfterStartDate
                                        : isNotPastDate
                                }
                                validate={(experience.approvalInfo?.approvedStage === 'LIVE_TEN_PERCENT' || experience.approvalInfo?.approvedStage === 'LIVE_FIFTY_PERCENT') && experience.type === 'CIF'
                                    ? CommonValidator.isActivationEndDateEmpty
                                    : CommonValidator.isActivationEndDateAfterStartDate
                                }
                            />
                        </FormField>

                        {(experience.approvalInfo?.approvedStage === 'LIVE_HUNDRED_PERCENT' || experience.approvalInfo?.approvedStage === 'LAUNCHED')
                        && experience.type === 'CIF' && renderData.endDate &&
                            <FormField label=' '>
                                <Button
                                    id= 'button.remove-end-date'
                                    onClick= {this.removeEndDateButtonClickHandler.bind(this)}
                                    disabled= {shouldDisableInput}
                                >Remove End Date</Button>
                            </FormField>
                        }
                    </div>
                </ColumnLayout>
            </FormField>}
        </FormSectionView>;
    }
}

const mapStateToProps = ({ odysseyExperienceDetailViewState, experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        isCloning: experienceEditViewState.isCloning,
        experience: experienceDetailViewState.experience || odysseyExperienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(BasicInfoSectionView);
