import * as React from 'react';
import marked from 'marked';


/*
    Links inside the markdown should open in a new tab
 */
const renderer = new marked.Renderer();
renderer.link = (href: string | null, title: string | null, text: string) => {
    return `<a href=${href} rel='noopener noreferrer' target='_blank'>${text}</a>`;
};

// taken some code from here, but not everything yet. If we want to support more emojis, syntax highlighting etc, we need
// to take more stuff from the following commits.
// https://code.amazon.com/packages/MarkdownViewer/trees/mainline
// https://code.amazon.com/packages/MaxisWebsite/commits/bfc714c38dc1f38a821557be44d87cd6ce3703ab#source/javascripts/helpers/rich-text-helpers.js
marked.setOptions({ sanitize: false, renderer });

export const MarkdownRenderer = (props: { markdownString: string }) => {
    const { markdownString } = props;
    if (!markdownString) {
        return null;
    }
    return <div dangerouslySetInnerHTML={{ __html: marked(markdownString) }} />;
};
