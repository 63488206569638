import * as React from 'react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { FormSectionView } from '../../components/common/FormSectionView';
import { FormField, ColumnLayout, DatePicker, Spinner, Button, Alert } from '@amzn/awsui-components-react';
import { connect } from 'react-redux';
import { AppState } from '../../reducers/index';
import { ExperienceUpdateCandidate, IBasicInfoViewAttributes } from '../../models/ExperienceUpdateCandidate';
import { pushBasicInfoSectionAction } from '../../actions/experienceEditViewActions';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { isNotFutureDate, isNotPastDate, cifActivationDateDescription} from './BasicInfoSectionView';
import DatePickerWrapper from '../../components/common/DatePickerWrapper';
import moment from 'moment';


interface IActivationDatesSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    isCloning: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

export class ActivationDatesSectionView extends React.Component<IActivationDatesSectionViewProps> {
    updateBasicInfo(title?: string, groupPermissions?: string, startDate?: string, endDate?: string) {
        const { dispatch } = this.props;
        if (this.props.updateCandidate !== undefined &&
            this.props.updateCandidate.getType() === 'CIF' && startDate) {
            const dateTime:Date = new Date(startDate);
            dateTime.setDate(dateTime.getDate() + 60);
            endDate = moment(dateTime.toLocaleDateString()).format('YYYY-MM-DD');
        }
        dispatch(pushBasicInfoSectionAction(title, groupPermissions, startDate, endDate));
    }

    removeEndDateButtonClickHandler () {
        this.updateBasicInfo(undefined, undefined, undefined, '');
    }

    isWithinSixtyDaysAfterStartDate = (date: Date) => {
        const currentStartDate: string|undefined = this.props.updateCandidate?.getBasicInfo().startDate;
        const startDateOrNow: Date = new Date(currentStartDate? currentStartDate : '');
        const sixtyDaysFromStartDate: Date = new Date(currentStartDate? currentStartDate : '');
        sixtyDaysFromStartDate.setDate(sixtyDaysFromStartDate.getDate() + 60);
        return startDateOrNow <= date && date <= sixtyDaysFromStartDate;
    }

    render () {
        const { experience, isEditing, isUpdating, updateCandidate } = this.props;

        if (!experience) {
            return <FormSectionView title='Basic Info'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const renderData: IBasicInfoViewAttributes = (isEditing && updateCandidate)
            ? updateCandidate.getBasicInfo()
            : ExperienceUpdateCandidate.extractBasicInfo(experience);

            const shouldRestrictDateRange = updateCandidate !== undefined &&
            updateCandidate.getType() !== 'CIF' && updateCandidate.getDataSourceInfo().dataSource === 'SINGLE_EXECUTION_BULLSEYE';
        const shouldDisableInput = !isEditing || isUpdating;
        const notificationActivationDateDescription = 'Date range over which experience will be served';
        const activationDateDescription = experience.type === 'CIF' ? cifActivationDateDescription : notificationActivationDateDescription;
        const datePickerSharedConfig = {
            disabled: shouldDisableInput,
            placeholder: 'YYYY/MM/DD',
            todayLabel: 'Today',
            nextMonthLabel: 'Next month',
            previousMonthLabel: 'Previous month',
            isDateEnabled: shouldRestrictDateRange ? isNotFutureDate : isNotPastDate
        };

        const datePickerSharedConfigForEndDate = {
            disabled: shouldDisableInput,
            placeholder: 'YYYY/MM/DD',
            todayLabel: 'Today',
            nextMonthLabel: 'Next month',
            previousMonthLabel: 'Previous month',
            isDateEnabled: shouldRestrictDateRange ? isNotFutureDate : this.isWithinSixtyDaysAfterStartDate
        };

        const isSameDayActivation = renderData.startDate && renderData.startDate === moment(new Date()).format('YYYY-MM-DD');

        return <FormSectionView
                    title={updateCandidate?.getIsQuickCreateWorkflow() ?
                        'Activation Dates' : <RequiredField fieldName={'Activation Dates'} />}
                    description={activationDateDescription}>
            <FormField>
                <ColumnLayout columns={3}>
                    <div data-awsui-column-layout-root='true'>
                        <FormField label='Start Date'>
                            {/* something is wrong with the the value prop,
                                        setting it to undefined causes the app to crash */}
                            <DatePickerWrapper
                                {...(renderData.startDate ?
                                    { value: renderData.startDate, ...datePickerSharedConfig }
                                    : { value:'', ...datePickerSharedConfig }
                                )}
                                onChange={(e) => this.updateBasicInfo(undefined, undefined, e.detail.value)}
                                id='experience.start-date'
                                checkChangeControl={true}
                            />
                            {!shouldDisableInput && experience.type !== 'CIF' && isSameDayActivation &&
                                    <Alert
                                        id='experience.same-day-activation'
                                        visible={true}
                                        type='info'
                                    >
                                    Notifications need time to be scheduled and may not be sent at the same day.
                            </Alert>}
                        </FormField>
                        <FormField label='End Date'>
                            {/* something is wrong with the the value prop,
                                            setting it to undefined causes the app to crash */}
                            <DatePicker
                                {...(renderData.endDate && renderData.startDate?
                                    { value: renderData.endDate, ...datePickerSharedConfigForEndDate }
                                    : { ...datePickerSharedConfigForEndDate }
                                )}
                                disabled= {shouldDisableInput || (!renderData.startDate && experience.type === 'CIF')}
                                onChange={(e) => this.updateBasicInfo(undefined, undefined, undefined, e.detail.value)}
                                controlId='experience.end-date'
                                id='experience.end-date'
                            />
                        </FormField>

                        {(experience.approvalInfo?.approvedStage === 'LIVE_HUNDRED_PERCENT' || experience.approvalInfo?.approvedStage === 'LAUNCHED')
                        && experience.type === 'CIF' && renderData.endDate &&
                            <FormField label=' '>
                                <Button
                                    id= 'button.remove-end-date'
                                    onClick= {this.removeEndDateButtonClickHandler.bind(this)}
                                    disabled= {shouldDisableInput}
                                >Remove End Date</Button>
                            </FormField>
                        }
                    </div>
                </ColumnLayout>
            </FormField>
        </FormSectionView>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        isCloning: experienceEditViewState.isCloning,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(ActivationDatesSectionView);
