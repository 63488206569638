import * as React from 'react';
import { Parser } from 'json2csv';
import {
	Table, TablePropertyFiltering, TableSorting, TablePagination,
	TablePreferences, TableContentSelector, TablePageSizeSelector, TableWrapLines, ButtonDropdown
} from '@amzn/awsui-components-react';
import { useLocation } from 'react-router-dom';
import { NoMatchDisplay } from '../../NoMatchDisplay';
import { AppState } from '../../../reducers/index';
import { useDispatch, useSelector } from 'react-redux';
import { fromCompressedSearchParamsToPropertyFilters, fromPropertyFiltersToCompressedSearchParams } from '../../../models/uri/SearchParams';
import { odysseyExperiencesPageLimit } from '../../../constants/componentConstants';
import { countTextFunction, fromTimestampToFormattedDateTime } from '../../../util/stringAndMappingHelper';
import { getOdysseyExperiencesWithTypePaginateAction, unsetOdysseyExperiences } from '../../../actions/v2/odysseyExperienceListViewActions';
import { COLUMN_DEFINITIONS, FILTERING_OPTIONS, SELECTABLE_COLUMNS, SORTABLE_COLUMNS } from '../../../constants/v2/odysseyExperienceTableColumnConfiguration';
import { EmptyDisplay } from '../../EmptyDisplay';
import { ExportType } from '../../../models/ExportType';
import { saveAs } from 'file-saver';
import { getPermissionAction } from '../../../actions/authenticationActions';
import { PAGE } from '../../../constants/page';
import { MOBILE_HOME_CARD_USER_GROUP, RCP_CREATORS_USER_GROUP } from '../../../constants/userGroups';
import { isNullOrUndefined } from 'util';


interface IOdysseyExperiencesProps {
	// initialized
	updateFilteringCriteria: (searchCriteriaString: string) => void;
	searchCriteriaString: string | null;
}

const OdysseyExperienceTable: React.FC<IOdysseyExperiencesProps> = (props: IOdysseyExperiencesProps) => {
    const dispatch = useDispatch();
    const { isLoading, flattenedExperiences, lastEvaluatedKey } = useSelector((state: AppState) => state.odysseyExperienceListViewState);
    const { updateFilteringCriteria, searchCriteriaString } = props;
    const currentLocation = useLocation();
    const shouldStopRefresh : boolean = !isNullOrUndefined(lastEvaluatedKey) && isNullOrUndefined(flattenedExperiences);

    React.useEffect(() => {
        if (!shouldStopRefresh) {
            switch (currentLocation.pathname) {
                case PAGE.MhcExperiences:
                    dispatch(getOdysseyExperiencesWithTypePaginateAction('MobileHomeCard', odysseyExperiencesPageLimit, lastEvaluatedKey));
                    dispatch(getPermissionAction(MOBILE_HOME_CARD_USER_GROUP));
                    break;
                case PAGE.RcpExperiences:
                    dispatch(getOdysseyExperiencesWithTypePaginateAction('RotatingContentPanel', odysseyExperiencesPageLimit, lastEvaluatedKey));
                    dispatch(getPermissionAction(RCP_CREATORS_USER_GROUP));
                    break;
            }
        }

        return () => {
            dispatch(unsetOdysseyExperiences());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, currentLocation.pathname, dispatch]);

    const exportToJsonOrCSV = (type?: string) => {
		const json2csvParser = new Parser();
		if (flattenedExperiences) {
			const fileName = `Experiences Dump ${fromTimestampToFormattedDateTime(Date.now())}.${ExportType.JSON_FILE === type ? 'json' : 'csv'}`;
			const blob = type === ExportType.JSON_FILE
				? new Blob([JSON.stringify(flattenedExperiences)], { type: 'text/plain;charset=utf-8' })
				: new Blob([json2csvParser.parse(flattenedExperiences)], { type: 'text/plain;charset=utf-8' });
			saveAs(blob, fileName);
		}
	};

    const isPagesLoading: boolean = (lastEvaluatedKey !== undefined && lastEvaluatedKey !== null);
    let searchParams: TablePropertyFiltering.ChangeDetail | undefined;
    try {
        if (searchCriteriaString) {
            const tokens = JSON.parse(searchCriteriaString);
            searchParams = fromCompressedSearchParamsToPropertyFilters(tokens);
        }

    } catch (error) {
        // if it fails, we keep tokens as undefined, then will resolve to [], which is still safe
    }

    return (
        <Table
            id='table.odysseyExperience'
            header={<div className='awsui-util-t-r'>
                <ButtonDropdown
                    id='dropdown.export-experiences'
                    loading={isLoading || isPagesLoading}
                    disabled={isLoading || isPagesLoading}
                    items={[{ id: ExportType.JSON_FILE, text: '.JSON' }, { id: ExportType.CSV_FILE, text: '.CSV' }]}
                    onItemClick={e => exportToJsonOrCSV(e.detail.id)}
                > Export </ButtonDropdown>
            </div>}
            columnDefinitions={COLUMN_DEFINITIONS}
            items={flattenedExperiences ?? []}
            loadingText='Loading Experiences'
            loading={isLoading}
            empty={<EmptyDisplay />}
            noMatch={<NoMatchDisplay />}
            resizableColumns={true}
            stickyHeader={true}
            wrapLines={false}
        >
            <TablePropertyFiltering
                filteringOptions={FILTERING_OPTIONS}
                groupValuesText='Values'
                groupPropertiesText='Properties'
                operationAndText='and'
                operationNotAndText='and not'
                operationOrText='or'
                operationNotOrText='or not'
                clearFiltersText='Clear filter'
                placeholder='Filter experiences by properties'
                allowFreeTextFiltering={true}
                filteringCountTextFunction={countTextFunction}
                operation={searchParams ? searchParams.operation as TablePropertyFiltering.Operation : 'and'}
                tokens={(searchParams ? searchParams.tokens : [])}
                disabled={isLoading}
                onPropertyFilteringChange={(event) => {
                    const compressedSearchParams = fromPropertyFiltersToCompressedSearchParams(event.detail);
                    const updatedSearchCriteriaString = JSON.stringify(compressedSearchParams);
                    updateFilteringCriteria(updatedSearchCriteriaString);
                }}
            />
            <TableSorting
                sortableColumns={SORTABLE_COLUMNS}
            />
            <TablePagination pageSize={40} openEnd={isPagesLoading} />
            <TablePreferences
                title='Preferences'
                confirmLabel='Confirm'
                cancelLabel='Cancel'
            >
                <TablePageSizeSelector
                    title='Page size'
                    options={[
                        { value: 40, label: '40 items' },
                        { value: 60, label: '60 items' },
                        { value: 80, label: '80 items' },
                        { value: 100, label: '100 items' }
                    ]}
                />
                <TableWrapLines
                    label='Wrap lines'
                    description='Enable to wrap table cell content, disable to truncate text.'
                />
                <TableContentSelector
                    title='Select visible columns'
                    options={[
                        {
                            label: 'Properties',
                            options: SELECTABLE_COLUMNS
                        }
                    ]}
                />
            </TablePreferences>
        </Table>
    );
};

export default OdysseyExperienceTable;
