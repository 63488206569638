import { IEnabledTimeWindow } from '../v2/constraint/IEnabledTimeWindow';
import { IRCPOverrideAccount } from '../v2/constraint/IRCPOverrideAccount';
import { SubscriptionUpsellType } from '../SubscriptionUpsellType';
import { IBusinessMetadata } from '../BusinessInfo';

export interface AplTemplate {
    aplTemplateId?: string;
    aplTemplateName?: string;
}

export enum IRCPDataSourceTypes {
    TextWrappingDataSource = 'TextWrappingDataSource'
}

export interface RCPMetadata {
    [key: string]: string;
}

export type IRCPDataSource = TextWrappingDataSource;

export interface TextWrappingDataSource {
    type: IRCPDataSourceTypes;
    headerText?: string;
    primaryText?: string;
    secondaryText?: string;
    tertiaryText?: string;
    thumbnailRatio?: string;
    enablePlayback?: boolean;
    attributionText?: string;
    attributionIcon?: string;
    hintText?: string;
    backgroundImageSource?: string;
    backgroundColor?: string;
}

export interface IRCPTouchAction {
    wakeWord?: string;
    hintText?: string;
    hintTextPayload?: string;
    buttonText?: string;
    skillConnection?: string;
    skillPayload?: string;
}

export interface IRotatingContentPanelContent {
    contentId?: string;
    marketPlace?: string;
    locale?: string;
    aplTemplate?: AplTemplate;
    rcpMetaData?: RCPMetadata;
    touchActions?: IRCPTouchAction[];
    textWrappingDataSource?: TextWrappingDataSource;
}

export interface IRotatingContentPanelTestingConstraint {
    rcpOverrideAccounts: IRCPOverrideAccount[];
}

export interface IRotatingContentPanelBusinessContext {
    vertical?: string;
    domain?: string;
}

// A newer context in place of IRotatingContentPanelBusinessContext
export interface IRotatingContentPanelContext {
    businessInfo?: IBusinessMetadata[];
    subscriptionUpsellType?: SubscriptionUpsellType;
}

export interface IRotatingContentPanelDevicesConstraint {
    deviceTypes?: string[];
}

export interface IRotatingContentPanelSchedulingConstraint {
    startDate: string;
    endDate: string;
    timeWindows: IEnabledTimeWindow[];
}
