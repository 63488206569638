import * as React from 'react';

import { ActivationTimeDateSectionView } from '../../../containers/sections/ActivationTimeDateSectionView';
import ImpressionTrackingSectionView from '../../../containers/sections/ImpressionTrackingSectionView';
import BullseyeSectionView from '../../../containers/sections/BullseyeSectionView';

export const RotatingContentPanelCustomerTargetingTabView = () => {
    return <div>
        <ActivationTimeDateSectionView />
        <ImpressionTrackingSectionView />
        <BullseyeSectionView />
    </div>;
};
