import { ITestMobileHomeCardAction } from '../actions/testMobileHomeCardActions';
import { ERROR_SENDING_MOBILE_HOME_CARD, SENT_MOBILE_HOME_CARD, RESET_SENDING_MOBILE_HOME_CARD_STATUS, SENDING_MOBILE_HOME_CARD } from '../constants/testMobileHomeCardActionTypes';

export interface ITestMobileHomeCardState {
	isLoading: boolean;
	result?: string;
	error?: Error;
}

export const testMobileHomeCardReducer = (
	state: ITestMobileHomeCardState = { isLoading: false },
	action: ITestMobileHomeCardAction
): ITestMobileHomeCardState => {
	switch (action.type) {
		case SENDING_MOBILE_HOME_CARD:
			return {
				...state,
				isLoading: true
			};
		case SENT_MOBILE_HOME_CARD:
			const { result } = action;
			// reducer is at top level. it should resolve error
			// and get it ready for rendering.
			if (result !== '200') {
				return {
					...state,
					isLoading: false,
					error: new Error('Unknown problem sending mobile home card')
				};
			}
			return {
				...state,
				isLoading: false,
				result
			};
		case ERROR_SENDING_MOBILE_HOME_CARD:
			return {
				...state,
				isLoading: false,
				error: action.error
			};
		case RESET_SENDING_MOBILE_HOME_CARD_STATUS:
			return {
				...state,
				isLoading: false,
				result: action.result
			};
		default:
			return state;
	}
};
