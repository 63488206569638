import { Button, ColumnLayout, FormField, Textarea } from '@amzn/awsui-components-react';
import * as React from 'react';
import { FormSectionView } from '../components/common/FormSectionView';
import { generateVcifExperiencesUsingAcc } from '../api/acopsGateway';
import { POEMS_ENDPOINTS_MAP } from '../constants/odysseyEndpoints';
import { getCurrRegionStage } from '../util/context';
import { Alert } from '@amzn/awsui-components-react';

interface GenerateVcifUsingAccState {
    capIdsList: string[];
    intentsList: string[];
    createdExperiences: string[];
    loading: boolean;
    errorMessage?: string;
}

export const GenerateVcifUsingAcc: React.FC = () => {
    const initialState: GenerateVcifUsingAccState = {
        capIdsList: [],
        intentsList: [],
        createdExperiences: [],
        loading: false
    };

    const [currentState, setState] = React.useState(initialState);

    const toggleLoading = () => {
        setState({ ...currentState, loading: !currentState.loading });
    };

    const onBtnClick = () => {
        if (currentState.capIdsList.length === 0 || currentState.intentsList.length === 0) return;
        toggleLoading();

        Promise.all(currentState.capIdsList.map((capability) => {
            return generateVcifExperiencesUsingAcc([capability], currentState.intentsList);
        })).catch((error) => {
            setState({ ...currentState, errorMessage: (error as Error).message});
            console.error(error);
        }).finally(() => {
            setState({ ...currentState, loading: false });
        });
    };

    const viewGeneratedExperiences = () => {
        window.open(`${POEMS_ENDPOINTS_MAP[getCurrRegionStage().stage]}/#/experiences?search=%7B"op":"and","toks":%5B%7B"v":"%5BAuto%20Generated%20VCIF%5D","neg":false,"fr":true%7D%5D%7D`, '_blank');
    };

    const onTextChange = (e: CustomEvent<Textarea.ChangeDetail>) => {
        const id = (e.target as HTMLElement).getAttribute('id');

        if (id === null) return;

        setState({
            ...currentState,
            [id]: e.detail.value.trim().split('\n')
        });
    };

    return (
        <>
            {currentState.errorMessage && <Alert type='error'>{currentState.errorMessage}</Alert>}
            <div className='awsui-util-m-xl'>
                <FormSectionView title={'Generate VCIF Experiences Using ACC'} description='Capability IDs and Intents are new line delimited.'>
                    <ColumnLayout columns={2}>
                        <div data-awsui-column-layout-root='true'>
                            <FormField label='Capability IDs'>
                                <Textarea id='capIdsList' onChange={onTextChange} value={currentState.capIdsList.join('\n')} />
                            </FormField>
                            <FormField label='Intents'>
                                <Textarea id='intentsList' onChange={onTextChange} value={currentState.intentsList.join('\n')} />
                            </FormField>
                        </div>
                    </ColumnLayout>
                    <FormField>
                        <Button id='submit' loading={currentState.loading} onClick={onBtnClick}>Generate Experiences</Button>
                        <Button onClick={viewGeneratedExperiences}>View Generated Experiences</Button>
                    </FormField>
                </FormSectionView>
            </div>
        </>);
};

