import { Stage } from '../util/context';
import { ExperienceType } from '../models/Experience';

export const APPROVALS_MAP: { [S in Stage]: string } = {
    Alpha: 'https://approvalstage.amazon.com/Approval/Details/',
    Beta: 'https://approvalstage.amazon.com/Approval/Details/',
    Gamma: 'https://approvalstage.amazon.com/Approval/Details/',
    Prod: 'https://approvals.amazon.com/Approval/Details/'
};

export const APPROVAL_SIM_TEMPLATE_IDS: { [type in ExperienceType]: string } = {
    CIF: 'ab9d11c4-8564-4c0d-a067-a007121b2a31',
    AppNotification: 'daa29e7a-2e2b-4d37-bb69-521edf1532f3',
    DeviceNotification: 'ab9d11c4-8564-4c0d-a067-a007121b2a31',
    MobileHomeCard: '33ccce43-e831-4078-a3bc-7b35930cd49c',
    RotatingContentPanel: 'ab9d11c4-8564-4c0d-a067-a007121b2a31'
};
