import { ILoadFilterDailyMetricsAction } from '../actions/experienceMetricsViewActions';
import {
    LOADING_FILTER_DAILY_METRICS,
    LOADED_FILTER_DAILY_METRICS,
    ERROR_LOADING_FILTER_DAILY_METRICS
} from '../constants/experienceMetricsViewActionTypes';
import { IRawFilterMetricsData } from '../models/FilterMetrics';

export interface IExperienceFilterDailyMetricsViewState {
    isLoading: boolean;
    filterMetrics?: IRawFilterMetricsData[];
    error?: Error;
}

export const experienceFilterDailyMetricsViewReducer = (
    state: IExperienceFilterDailyMetricsViewState = { isLoading: false },
    action: ILoadFilterDailyMetricsAction
): IExperienceFilterDailyMetricsViewState => {
    switch (action.type) {
        case LOADING_FILTER_DAILY_METRICS:
            return {
                ...state,
                isLoading: true
            };
        case LOADED_FILTER_DAILY_METRICS:
            const { filterMetrics } = action;
            return {
                ...state,
                isLoading: false,
                filterMetrics
            };
        case ERROR_LOADING_FILTER_DAILY_METRICS:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};
