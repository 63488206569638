export interface IFilter {
    deviceTypes?: string[];
    bullseye?: number;
    contextualSegments: IContextualSegments;
    enabled: boolean;
    activationConfiguration: IActivationConfiguration;
    status: string;
    snsTopic?: string;
    publisherRoleForAutoCreatedSnsTopic?: string;
    bullseyeVersion?: number;
}

export interface IActivationConfiguration {
    startDate?: string;
    endDate?: string;
}

export enum ManagementLogicalOperator {
    AND = 'AND',
    OR = 'OR'
}

export interface IContextualSegments {
    includeSegments: string[];
    excludeSegments: string[];
    includeSegmentsLogicalOperator: ManagementLogicalOperator;
    excludeSegmentsLogicalOperator: ManagementLogicalOperator;
}

export const EMPTY_CONTEXTUAL_SEGMENTS: IContextualSegments = {
    includeSegments: [],
    excludeSegments: [],
    includeSegmentsLogicalOperator: ManagementLogicalOperator.AND,
    excludeSegmentsLogicalOperator: ManagementLogicalOperator.AND
};
