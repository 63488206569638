import * as React from 'react';
import { FormSectionView } from '../common/FormSectionView';
import { Select, Spinner, Modal, Button, Alert, Flashbar, FormField, Tiles } from '@amzn/awsui-components-react';
import { flip, getRole, isLiveExperience } from '../../util/stringAndMappingHelper';
import {
    getAllocationMessage, isWeblabAllocationValid, isWeblabLaunched, populateStaticAllocationMessages, checkIfExperienceApprovedForFurtherDialup,
    treatmentToDialupStageMapping, generateExceptionalSelectOption, generateExceptionalLaunchSelectOption, REQUESTED_DIALUP_STAGE_OPTIONS
} from '../../util/exposureControlAllocationHelper';
import { AppState } from '../../reducers/index';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { connect } from 'react-redux';
import { syncDialedUpWeblabToFullExposureAction, generateExperienceApprovalAction } from '../../actions/experienceEditViewActions';
import { KeyValuePairMultipleColumnView } from '../common/GridKeyValuePair';
import { LinkedWeblab, LinkedBullseye, LinkedAlexaLab, LinkedApproval } from '../common/LinksRenderHelper';
import { ExternalLink } from '../../components/common/LinkComponents';
import { CIF_SETUP_WORKFLOW_LINK } from '../../constants/exposureControlConstants';
import { isNullOrUndefined } from 'util';
import { TextAreaWrapper } from '../common/TextAreaWrapper';
import { generateSimExceptionApprovalTemplate } from '../../util/simApprovalTemplateHelper';
import { updateExposureControlAllocationAction } from '../../actions/experienceDetailViewActions';
import SkipApprovalModal from '../modals/SkipApprovalModal';

interface IExposureControlViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

interface IExposureControlViewState {
    exposureControlModalVisible: boolean;
    exceptionalApprovalModalVisible: boolean;
    existingApprovalModalVisible: boolean;
    exceptionalReason: string;
    requestedDialupStage?: REQUESTED_DIALUP_STAGE_OPTIONS;
    newTreatment?: number;
    skipApprovalModalVisible: boolean;
}

const generateTileOptions = (treatmentsToMessages: { [key: string]: string },
    isExperienceApprovedForFurtherDialup: { furtherDialupPercentage: number },
    isEverGreenExperience: boolean): Tiles.TileDefinition[] => {
    const options = Object.keys(treatmentsToMessages).map(key => {
        return {
            value: key,
            controlId: key,
            id: key,
            label: treatmentsToMessages[key],
            disabled: (isExperienceApprovedForFurtherDialup.furtherDialupPercentage < parseInt(key, 10)) ||
                (isExperienceApprovedForFurtherDialup.furtherDialupPercentage === 100 && key === 'ExceptionalApprovalCase') ||
                (isEverGreenExperience && key === '0.2')
        };
    });
    options.sort((a, b) => Number(a.id) - Number(b.id));
    return options;
};

export class ExposureControlView extends React.Component<IExposureControlViewProps, IExposureControlViewState> {

    constructor(props: IExposureControlViewProps) {
        super(props);
        this.state = {
            exposureControlModalVisible: false,
            exceptionalApprovalModalVisible: false,
            existingApprovalModalVisible: false,
            exceptionalReason: '',
            requestedDialupStage: undefined,
            skipApprovalModalVisible: false
        };
    }

    private showExposureControlModal() {
        this.setState(prevState => {
            return {
                ...prevState,
                exposureControlModalVisible: true
            };
        });
    }

    private showExceptionalApprovalModal() {
        this.setState(prevState => {
            return {
                ...prevState,
                exceptionalApprovalModalVisible: true
            };
        });
    }

    private showExistingApprovalModal() {
        this.setState(prevState => {
            return {
                ...prevState,
                existingApprovalModalVisible: true
            };
        });
    }

    private updateExceptionalReason(reason: string) {
        this.setState(prevState => {
            return {
                ...prevState,
                exceptionalReason: reason
            };
        });
    }

    private updateRequestedDialupStage(stage: REQUESTED_DIALUP_STAGE_OPTIONS) {

        this.setState(prevState => {
            return {
                ...prevState,
                requestedDialupStage: stage
            };
        });
    }

    private generateExceptionalApproval() {
        const { dispatch } = this.props;
        const { exceptionalReason, requestedDialupStage } = this.state;
        if (requestedDialupStage) {
            dispatch(generateExperienceApprovalAction(true, requestedDialupStage, exceptionalReason, false, false, generateSimExceptionApprovalTemplate(exceptionalReason, requestedDialupStage)));
        }
    }

    private syncDialedUpWeblabToFullExposure() {
        const { dispatch } = this.props;

        dispatch(syncDialedUpWeblabToFullExposureAction());
    }

    private updateTreatment(treatment: number) {
        this.setState(prevState => {
            return {
                ...prevState,
                newTreatment: treatment
            };
        });
    }

    private dismissModal() {
        this.setState(prevState => {
            return {
                ...prevState,
                exposureControlModalVisible: false,
                exceptionalApprovalModalVisible: false,
                existingApprovalModalVisible: false
            };
        });
    }

    private adjustExposureControlAllocation(treatment: number) {
        const { dispatch } = this.props;
        dispatch(updateExposureControlAllocationAction(treatment));
    }

    private toggleSkipApprovalModalVisibility() {
        const { skipApprovalModalVisible } = this.state;
        this.setState({ skipApprovalModalVisible: !skipApprovalModalVisible });
    }

    public render() {
        const { isEditing, experience } = this.props;
        const { newTreatment, exposureControlModalVisible, exceptionalApprovalModalVisible, existingApprovalModalVisible,
            exceptionalReason, requestedDialupStage, skipApprovalModalVisible } = this.state;

        if (!experience) {
            return <FormSectionView title='ExposureControl' description='Something went wrong with experience.' >
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const isLive = isLiveExperience(experience.status) || experience.status === 'SCHEDULED';
        const isLegacyExperience = isNullOrUndefined(experience.launchTreatmentPercentage);
        const shouldRenderLegacy = (isLegacyExperience || experience.weblab.launched) && experience.status !== 'DRAFT' && experience.status !== 'TESTABLE';
        const legacyDescription = '[Legacy] This experience is a legacy experience, please control the exposure of experience through existing weblab.';
        const isAdmin = getRole(experience.permissionRole) === 'ADMIN';

        // For live legacy experience, we will disable the interaction of exposure control section and make it a view instead
        if (experience.type !== 'CIF' || shouldRenderLegacy) {
            return <FormSectionView
                title='Exposure Control'
                description={isLegacyExperience ? legacyDescription : undefined}
            >
                <KeyValuePairMultipleColumnView
                    data={[[{
                        key: 'Weblab',
                        value: <LinkedWeblab weblab={experience.weblab ? experience.weblab.name : undefined} />
                    }], [{
                        key: 'Bullseye',
                        value: <LinkedBullseye
                            bullseye={experience.enabledFilter && experience.enabledFilter.bullseye} region={experience.region} />
                    }], [{
                        key: 'AlexaLab',
                        value: <LinkedAlexaLab weblab={experience.weblab ? experience.weblab.name : undefined} />
                    }]]}
                />
            </FormSectionView>;
        }

        const treatmentsToMessages = populateStaticAllocationMessages();
        const messagesToTreatments = flip(treatmentsToMessages);

        const originalLaunchTreatmentPercentage = experience.launchTreatmentPercentage || 0.2;
        const originalAllocation = getAllocationMessage(experience.weblabAllocation, originalLaunchTreatmentPercentage);
        const originalTreatment = Number(messagesToTreatments[originalAllocation]);
        const newAllocation = newTreatment ? treatmentsToMessages[newTreatment] : '';

        const errorInWeblab = !isWeblabAllocationValid(experience.weblabAllocation) || (isWeblabLaunched(experience.weblabAllocation) && originalLaunchTreatmentPercentage !== 1);
        const isEverGreenExperience = isWeblabLaunched(experience.weblabAllocation);
        const isExperienceApprovedForFurtherDialup = checkIfExperienceApprovedForFurtherDialup(experience.approvalInfo);

        const isApprovedForFullDialup = experience.approvalInfo?.approvedStage === 'LIVE_HUNDRED_PERCENT';
        const exposureControlHeader = newTreatment === 100 ? 'Update Weblab allocation to C: 0%, T1: 100%' : 'You are changing your customer allocation to ' + newAllocation;

        return <FormSectionView
            title='Exposure Control'
            description="Using Odyssey to control your experience's exposure. Follow instructions closely for best results"
        >
            {isExperienceApprovedForFurtherDialup.approvedForDialup && experience.status !== 'TESTABLE' && <Flashbar items={[{
                header: (isExperienceApprovedForFurtherDialup.furtherDialupPercentage === 50) ? `Your experience is eligible to expose to 50% of your C1 and 50%
                of your T1 groups` : 'Your experience is eligible to expose to 100% of your T1 group',
                type: 'success',
                dismissible: false
            }]} />}
            <div style={{ marginTop: '10px' }}>
                <KeyValuePairMultipleColumnView
                    data={[[{
                        key: 'Weblab',
                        value: <LinkedWeblab weblab={experience.weblab ? experience.weblab.name : undefined} />
                    }, {
                        key: '',
                        value: !isApprovedForFullDialup ? <Flashbar items={[{
                            content: 'Please make sure your allocation in Weblab is C: 50% and T1: 50% ',
                            type: 'warning',
                            dismissible: false
                        }]} /> : ''
                    }, {
                        key: 'Bullseye',
                        value: <LinkedBullseye
                            bullseye={experience.enabledFilter && experience.enabledFilter.bullseye} region={experience.region} />
                    }, {
                        key: 'AlexaLab',
                        value: <LinkedAlexaLab weblab={experience.weblab ? experience.weblab.name : undefined} />
                    }], [{
                        key: 'Customer allocation (please wait 15 minutes to reflect changes)',
                        value: <div>
                            {isEditing
                                ? <p id='allocation-in-edit-warning' className='awsui-util-status-inactive'>You cannot change allocation percentage when editing your experience</p>
                                : !isLive
                                    ? <p id='allocation-before-live-warning' className='awsui-util-status-inactive'>You cannot change allocation percentage on non-LIVE experiences</p>
                                    : !errorInWeblab &&
                                    <Tiles
                                        id='experience.update-allocation'
                                        columns={1}
                                        items={generateTileOptions(treatmentsToMessages, isExperienceApprovedForFurtherDialup, isEverGreenExperience)}
                                        value={errorInWeblab
                                            ? null
                                            : ((isEditing && newTreatment)
                                                ? String(newTreatment)
                                                : String(originalTreatment))}
                                        onChange={(event) => {
                                            const selectedTreatment = Number(event.detail.value);
                                            this.updateTreatment(selectedTreatment);
                                            if (experience.approvalId) {
                                                this.showExistingApprovalModal();
                                            } else {
                                                this.showExposureControlModal();
                                            }
                                        }}
                                    />
                            }
                            {isLive && !isEditing && errorInWeblab &&
                                <Alert
                                    visible={true}
                                    type='error'
                                >
                                    Your customer allocation submission cannot be processed because your
                                    Weblab allocation is incorrect. To learn more, click&nbsp;
                                    <ExternalLink href={CIF_SETUP_WORKFLOW_LINK}>here</ExternalLink>
                                </Alert>}
                            {(isLive && !isEditing && isEverGreenExperience && originalLaunchTreatmentPercentage === 0.2) && (
                                isApprovedForFullDialup
                                    ? <Alert
                                        buttonText='One-click to fix it'
                                        onButtonClick={this.syncDialedUpWeblabToFullExposure.bind(this)}
                                        visible={true}
                                        type='error'
                                        id='alert.need-to-fix-exposure.full-dialed-up-weblab'
                                    >
                                        Your weblab is dialed up to 100% while the experience's exposure is still not 100%.
                                        Click on the 'One-click to fix it' button to fix it.
                                    </Alert>
                                    : <Alert
                                        visible={true}
                                        type='error'
                                        id='alert.need-to-dial-down-weblab' >
                                        Your weblab is manually dialed up to 100% without obtaining the necessary approvals.
                                        Your experience must first meet the required metrics to launch and automatically be
                                        approved by Odyssey before you can dial up your weblab over 50% T1 50% C. Please dial down
                                        your weblab to 50% T1 50% C <b>immediately</b>.
                                        <br /><br />
                                        After dialing down, please wait ~ 15 minutes to reflect your new weblab allocation.
                                    </Alert>
                            )}
                        </div>
                    }, {
                        key: 'Request increased allocation exception',
                        value: <Button
                            id='btn.request-exposure-exception'
                            onClick={this.showExceptionalApprovalModal.bind(this)}
                            disabled={isEditing || isApprovedForFullDialup}>
                            Request exception
                        </Button>
                    }, {
                        key: 'Skip Approval (ADMIN ONLY)',
                        value: <span className='awsui-util-f-l awsui-util-pr-m'>
                            <Button
                                icon='status-warning'
                                id='button.exceptional-skip-approval'
                                variant='primary'
                                onClick={this.toggleSkipApprovalModalVisibility.bind(this)}
                                disabled={!isAdmin || experience.status === 'DRAFT'}
                            >Skip Approval (ADMIN ONLY)</Button>
                        </span>
                    }]]}
                />
            </div>
            <SkipApprovalModal isVisible={skipApprovalModalVisible} isException={true} hideModal={() => this.toggleSkipApprovalModalVisibility()} />
            <Modal
                id='modal.exposure-confirmation'
                onDismiss={() => {
                    this.dismissModal();
                    this.updateTreatment(originalTreatment);
                }}
                visible={exposureControlModalVisible}
                size='medium'
                footer={originalTreatment === 1 && newTreatment === 100 ? <span className='awsui-util-f-r'>
                    <Button
                        id='experience.close-updating-allocation'
                        variant='primary'
                        onClick={() => {
                            this.dismissModal();
                            this.updateTreatment(originalTreatment);
                        }}>
                        Close
                    </Button>
                </span> : <span className='awsui-util-f-r'>
                    <Button
                        id='experience.cancel-updating-allocation'
                        variant='link'
                        onClick={() => {
                            this.dismissModal();
                            this.updateTreatment(originalTreatment);
                        }}>
                        Cancel
                    </Button>
                    <Button
                        id='experience.confirm-updating-allocation'
                        variant='primary'
                        icon='envelope'
                        onClick={() => {
                            this.dismissModal();
                            if (newTreatment !== undefined) {
                                this.adjustExposureControlAllocation(newTreatment);
                            }
                        }}>
                        Confirm
                    </Button>
                </span>
                }
                header={`${exposureControlHeader}`}
            >
                <div className='awsui-util-spacing-v-s'>
                    {originalTreatment === 1 && newTreatment === 0.2 ?
                        <div>
                            Your current allocation is <b>{originalAllocation}</b>.
                            <h3>Do NOT Touch your Weblab</h3>
                            <p>
                                Changing your customer allocation affects your exposure control, not your weblab allocation. Your Weblab allocation should remain at C:50% and T1: 50%.
                                To learn more, <ExternalLink href={CIF_SETUP_WORKFLOW_LINK}> click here</ExternalLink>
                            </p>
                        </div>
                        : <div>
                            Your current allocation is <b>{originalAllocation}</b>.
                            {newTreatment === 100 &&
                                <div>
                                    <h3>Update Weblab Allocation</h3>
                                    <p>
                                        Go to your weblab <LinkedWeblab weblab={experience.weblab ? experience.weblab.name : undefined}></LinkedWeblab> and
                                        manually update allocation to C: 0%, T1: 100%. Wait 15 mins for status to update in Odyssey.
                                    </p>
                                    <b>Please note:</b>
                                    <p>
                                        In the future, if you decide to reduce your customer allocation, you will need to cancel then clone this experience with a new Weblab.
                                    </p>
                                </div>}
                            {originalTreatment === 0.2 && newTreatment === 1 &&
                                <div>
                                    <h3>Do NOT Touch your Weblab</h3>
                                    <p>
                                        Changing your customer allocation affects your exposure control, not your weblab allocation.
                                        Your Weblab allocation should remain at C:50% and T1: 50%. To learn more,&nbsp;
                                        <ExternalLink href={CIF_SETUP_WORKFLOW_LINK}>click here</ExternalLink>
                                    </p>
                                </div>}
                            {originalTreatment === 100 && newTreatment === 1 &&
                                <div>
                                    <p>
                                        If you want to dial down your weblab to Live 50%, please go to your
                                        weblab <LinkedWeblab weblab={experience.weblab ? experience.weblab.name : undefined}></LinkedWeblab> and dial down to C: 50% and T1: 50%.
                                    </p>
                                </div>}
                        </div>}
                </div>
            </Modal>
            <Modal
                id='modal.exceptional-approval'
                onDismiss={() => {
                    this.dismissModal();
                }}
                visible={exceptionalApprovalModalVisible}
                size='medium'
                footer={<span className='awsui-util-f-r'>
                    <Button
                        id='experience.cancel-requesting-exceptional-approval'
                        variant='link'
                        onClick={() => {
                            this.dismissModal();
                        }}>
                        Cancel
                    </Button>
                    <Button
                        id='experience.confirm-requesting-exceptional-approval'
                        variant='primary'
                        icon='envelope'
                        disabled={exceptionalReason === '' || requestedDialupStage === undefined}
                        onClick={() => {
                            this.generateExceptionalApproval();
                            this.dismissModal();
                        }}>
                        Send for Review
                    </Button>
                </span>
                }
                header='You are requesting exceptional approval to dial up your experience.'>
                <div className='awsui-util-spacing-v-s'>
                    <Select
                        id='experience.update-exceptional-allocation'
                        controlId='experience.update-exceptional-allocation'
                        placeholder='Please select your desired dialup below'
                        options={(experience.status === 'LIVE_FIFTY_PERCENT' && experience.approvalInfo?.approvedStage === 'LIVE_FIFTY_PERCENT') ?
                            generateExceptionalLaunchSelectOption() : generateExceptionalSelectOption()}
                        onChange={(event) => {
                            const selectedTreatment = Number(event.detail.selectedOption.id);
                            this.updateRequestedDialupStage(treatmentToDialupStageMapping[selectedTreatment]);
                        }}
                    />
                    <FormField
                        label='Exceptional Approval Reason'
                        description='This experience has not yet met the required metrics to be automatically approved for further dial up.
                    To request an exception to dial up, please provide a detailed explanation as to why your experience should be approved for
                    further dial up now. Your Odyssey locale approver will review your request and make a decision accordingly.'>
                        <TextAreaWrapper
                            id='experience.exceptional-approval-reason'
                            readonly={false}
                            validate={item => item !== ''}
                            value={exceptionalReason}
                            onChange={(input: string) => this.updateExceptionalReason(input)} />
                    </FormField>
                </div>
            </Modal>
            <Modal
                id='modal.existing-approval'
                onDismiss={() => {
                    this.dismissModal();
                }}
                visible={existingApprovalModalVisible}
                size='medium'
                footer={<span className='awsui-util-f-r'>
                    <Button
                        id='experience.existing-approval-cancel'
                        variant='link'
                        onClick={() => {
                            this.dismissModal();
                        }}>
                        Cancel
                    </Button>
                </span>
                }
                header={'Approval in Progress'}>
                <div className='awsui-util-spacing-v-s'>
                    <div>
                        This experience already has an Approval <LinkedApproval
                            approvalId={experience.approvalId} /> in progress. First cancel the approval before requesting a new one.
                    </div>
                </div>
            </Modal>
        </FormSectionView>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(ExposureControlView);
