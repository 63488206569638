import * as React from 'react';
import { FormField, Multiselect, Select, Input } from '@amzn/awsui-components-react';
import { RequiredField, OptionalField } from '../../components/common/DescriptionAnnotations';
import { DevicesTagsView } from '../../containers/sections/CifDevicesSectionView';

interface DeviceComponentProps {
    shouldDisableInput: boolean;
    nonAvsDevices: string[];
    avsDevices: string[];
    deviceSelections?: Select.OptionsGroup[];
    avsDeviceInput?: string;
    devices: string[];
    updateDevices: (devices: string[]) => void;
    setAVSDeviceInput: (event: CustomEvent<Input.ChangeDetail>) => void;
    onAVSDeviceInputEnter: (devices: string[]) => void;
}

export const DeviceComponent = (props: DeviceComponentProps) => {
    const {shouldDisableInput, nonAvsDevices, avsDevices, deviceSelections, avsDeviceInput, devices,
        updateDevices, setAVSDeviceInput, onAVSDeviceInputEnter} = props;

    return <div>
        <FormField label={<RequiredField fieldName='Echo Family and Fire TV Devices' />} description='Target Echo multimodal, headless, on-the-go and Fire TV devices'>
                <Multiselect
                    id='experience.devices-non-avs-multiselect'
                    controlId='experience.devices-non-avs-multiselect'
                    selectedIds={nonAvsDevices}
                    options={deviceSelections}
                    disabled={shouldDisableInput}
                    onChange={(event) => {
                        updateDevices(event.detail.selectedIds.concat(avsDevices));
                    }}
                    filteringType='auto'
                    checkboxes={true}
                />
            </FormField>
            <FormField label={<OptionalField fieldName='AVS Devices' />} description='Target AVS devices by using the device type id'>
                <Input
                    id='experience.devices-avs-input'
                    controlId='experience.devices-avs-input'
                    disabled={shouldDisableInput}
                    onInput={(e) => setAVSDeviceInput(e)}
                    value={avsDeviceInput}
                    onKeyup={(e) => {
                        if (e.detail.keyCode === 13) {
                            onAVSDeviceInputEnter(devices);
                        }
                    }}/>
                <DevicesTagsView
                    tags={avsDevices}
                    tokenGroupId='experience.devices-avs-token-group'
                    disableTag={shouldDisableInput}
                    onDismiss={(e) => {
                        avsDevices.splice(e.detail.itemIndex, 1);
                        updateDevices(nonAvsDevices.concat(avsDevices));
                    }} />
            </FormField>
        </div>;
};
