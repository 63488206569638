import * as React from 'react';
import { IInvocationParameters, AuthenticationMethod, EncryptionMethod, SerializationMethod,
    isAuthenticationMethodIamAuth,
    isAuthenticationMethodAAA,
    isAuthenticationMethodCloudAuth } from '../../models/InvocationParameters';
import { ColumnLayout, FormField, Input, Select, Checkbox } from '@amzn/awsui-components-react';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { RequiredField, RecommendedField } from './DescriptionAnnotations';
import { InputWrapper } from './InputWrapper';
import { CommonValidator } from '../../util/CommonValidator';
import { SelectWrapper } from './SelectWrapper';

interface IInvocationParametersViewProps {
    arrayKey: string;
    invocationParameters: IInvocationParameters;
    isNotificationExperience: boolean;
    updateInvocationParameters: (input: IInvocationParameters) => void;
    disableInput: boolean;
}

export const InvocationParameterView = (props: IInvocationParametersViewProps) => {
    const { invocationParameters, isNotificationExperience, updateInvocationParameters, disableInput, arrayKey } = props;
    const invocationParametersCopy: IInvocationParameters = ExperienceUpdateCandidate.deepClone(invocationParameters);

    const validateInvocationParameters = () => {
        invocationParametersCopy.validationErrorMessage = undefined;
        if(invocationParametersCopy.authenticationMethod === AuthenticationMethod.IAM_AUTH) {
            if(invocationParametersCopy.serializationMethod === SerializationMethod.CORAL) {
                invocationParametersCopy.validationErrorMessage = 'IAM authentication method and Coral serialization method are not supported together.';
            }
            if(invocationParametersCopy.isRedCertified) {
                invocationParametersCopy.validationErrorMessage = 'IAM authentication method and RED data are not supported together.';
            }
        }
    };

    return <div>
        <h3 className='awsui-util-mt-xl awsui-util-mb-l'>Invocation Parameters</h3>
        {invocationParametersCopy.validationErrorMessage &&
            <p className='awsui-util-status-negative'>
                {invocationParametersCopy.validationErrorMessage}
            </p>
        }
        <ColumnLayout columns={1}>
            <FormField label='Serialization method'>
                <SelectWrapper
                    id={`experience.invocation-parameters.serialization-method-${arrayKey}`}
                    disabled={disableInput}
                    selectedId={invocationParameters.serializationMethod
                        ? invocationParameters.serializationMethod
                        : SerializationMethod.REST}
                    options={Object.values(SerializationMethod)
                        .map(option => { return { id: option, label: option }; })}
                    onChange={(e) => {
                        invocationParametersCopy.serializationMethod = e.detail.selectedId as SerializationMethod;
                        if(invocationParametersCopy.serializationMethod === SerializationMethod.CORAL) {
                            invocationParametersCopy.AAAOperationName = 'GetDomainInformation';
                        }
                        validateInvocationParameters();
                        updateInvocationParameters(invocationParametersCopy);
                    }}
                    validate={(serialization) => CommonValidator.isSerializationMethodValid(serialization as SerializationMethod, invocationParametersCopy.authenticationMethod)}/>
            </FormField>
        </ColumnLayout>
        <ColumnLayout columns={1}>
            <div data-awsui-column-layout-root='true'>
                <FormField label='Authentication method'>
                    <Select
                        id={`experience.invocation-parameters.authentication-method-${arrayKey}`}
                        controlId={`experience.invocation-parameters.authentication-method-${arrayKey}`}
                        disabled={disableInput}
                        selectedId={invocationParameters.authenticationMethod}
                        options={Object.values(AuthenticationMethod)
                            .map(option => { return { id: option, label: option }; })}
                        onChange={(e) => {
                            invocationParametersCopy.authenticationMethod = e.detail.selectedId as AuthenticationMethod;
                            validateInvocationParameters();
                            updateInvocationParameters(invocationParametersCopy);
                        }}/>
                </FormField>
            </div>
        </ColumnLayout>
        { isAuthenticationMethodIamAuth(invocationParameters) &&
        <div>
        <ColumnLayout columns={2}>
            <div data-awsui-column-layout-root='true'>
                <FormField label={<RequiredField fieldName='Role ARN' />}>
                    <InputWrapper readonly={disableInput}
                            id={`experience.invocation-parameters.role-arn-${arrayKey}`}
                            value={invocationParameters.assumeRoleARN}
                            placeholder='-'
                            onInput={(input: string) => {
                                invocationParametersCopy.assumeRoleARN = input;
                                invocationParametersCopy.AAAServiceName = '';
                                invocationParametersCopy.AAAOperationName = '';
                                validateInvocationParameters();
                                updateInvocationParameters(invocationParametersCopy);
                            }}
                            validate={CommonValidator.isAssumeRoleArnValid}/>
                </FormField>
            </div>
        </ColumnLayout>
        </div>
        }
        { (isAuthenticationMethodAAA(invocationParameters) || isAuthenticationMethodCloudAuth(invocationParameters)) &&
        <ColumnLayout columns={3}>
            <div data-awsui-column-layout-root='true'>
                <FormField label={<RequiredField fieldName='AAA service name' />}>
                    <InputWrapper
                        id={`experience.invocation-parameters.aaa-service-name-${arrayKey}`}
                        readonly={disableInput}
                        value={invocationParameters.AAAServiceName}
                        placeholder='-'
                        onInput={(input: string) => {
                            invocationParametersCopy.AAAServiceName = input;
                            invocationParametersCopy.assumeRoleARN = '';
                            validateInvocationParameters();
                            updateInvocationParameters(invocationParametersCopy);
                        }}
                        validate={CommonValidator.isAAAServiceNameValid}/>
                </FormField>
                <FormField label={<RequiredField fieldName='AAA operation name' />}>
                    <InputWrapper
                        id={`experience.invocation-parameters.aaa-operation-name-${arrayKey}`}
                        readonly={disableInput}
                        value={invocationParameters.AAAOperationName}
                        placeholder='-'
                        onInput={(input: string) => {
                            invocationParametersCopy.AAAOperationName = input;
                            validateInvocationParameters();
                            updateInvocationParameters(invocationParametersCopy);
                        }}
                        validate={(operation) => {CommonValidator.isAAAOperationNameValid(operation, invocationParametersCopy.serializationMethod);}}
                    />
                </FormField>
            </div>
        </ColumnLayout>
            }
        <ColumnLayout columns={1}>
            <FormField label={<RequiredField fieldName='CTI' />} description='Must be in format C/T/I e.g. Alexa/ACOPS/Service'>
                <InputWrapper
                    id={`experience.invocation-parameters.cti-${arrayKey}`}
                    readonly={disableInput}
                    value={invocationParameters.CTI}
                    placeholder='e.g. Alexa/ACOPS/Service'
                    onInput={(input: string) => {
                        invocationParametersCopy.CTI = input;
                        validateInvocationParameters();
                        updateInvocationParameters(invocationParametersCopy);
                    }}
                    validate={CommonValidator.isCTIValid}
                />
            </FormField>
            <FormField
                label={<RecommendedField fieldName='Launch weblab' />}
                description='Experience is filtered out if C is returned. Useful to control traffic and assist in initial integration launches.'>
                <Input
                    id={`experience.invocation-parameters.launch-weblab-${arrayKey}`}
                    controlId={`experience.invocation-parameters.launch-weblab-${arrayKey}`}
                    readonly={disableInput}
                    value={invocationParameters.launchWeblab}
                    placeholder='-'
                    onChange={(e) => {
                        invocationParametersCopy.launchWeblab = e.detail.value;
                        validateInvocationParameters();
                        updateInvocationParameters(invocationParametersCopy);
                    }}/>
            </FormField>
            <FormField label='NA endpoint' description='Specify in at least one region'>
                <InputWrapper
                    id={`experience.invocation-parameters.na-endpoint-${arrayKey}`}
                    readonly={disableInput}
                    value={invocationParameters.endpoint.NA}
                    placeholder='-'
                    onInput={(input: string) => {
                        if (input === '') delete invocationParametersCopy.endpoint.NA;
                        else invocationParametersCopy.endpoint.NA = input;
                        validateInvocationParameters();
                        updateInvocationParameters(invocationParametersCopy);
                    }}
                    validate={(e) => {CommonValidator.atLeastOneEndpointConfigured(invocationParametersCopy.endpoint);
                                      CommonValidator.isEndpointValid(e);}}/>
            </FormField>
            <FormField label='EU endpoint' description='Specify in at least one region'>
                <InputWrapper
                    id={`experience.invocation-parameters.eu-endpoint-${arrayKey}`}
                    readonly={disableInput}
                    value={invocationParameters.endpoint.EU}
                    placeholder='-'
                    onInput={(input: string) => {
                        if (input === '') delete invocationParametersCopy.endpoint.EU;
                        else invocationParametersCopy.endpoint.EU = input;
                        validateInvocationParameters();
                        updateInvocationParameters(invocationParametersCopy);
                    }}
                    validate={(e) => {CommonValidator.atLeastOneEndpointConfigured(invocationParametersCopy.endpoint);
                                      CommonValidator.isEndpointValid(e);}}/>
            </FormField>
            <FormField label='FE endpoint' description='Specify in at least one region'>
                <InputWrapper
                    id={`experience.invocation-parameters.fe-endpoint-${arrayKey}`}
                    readonly={disableInput}
                    value={invocationParameters.endpoint.FE}
                    placeholder='-'
                    onInput={(input: string) => {
                        if (input === '') delete invocationParametersCopy.endpoint.FE;
                        else invocationParametersCopy.endpoint.FE = input;
                        validateInvocationParameters();
                        updateInvocationParameters(invocationParametersCopy);
                    }}
                    validate={(e) => {
                        CommonValidator.atLeastOneEndpointConfigured(invocationParametersCopy.endpoint);
                        CommonValidator.isEndpointValid(e);
                    }}/>
            </FormField>
            {/* We do not currently allow RED DATA in notification content */}
            {!isNotificationExperience &&
                <div>
                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Checkbox
                            id={`experience.invocation-parameters.red-certified-${arrayKey}`}
                            controlId={`experience.invocation-parameters.red-certified-${arrayKey}`}
                            disabled={disableInput}
                            checked={invocationParameters.isRedCertified || false}
                            onChange={(e) => {
                                invocationParametersCopy.isRedCertified = e.detail.checked;
                                if (e.detail.checked) {
                                    invocationParametersCopy.encryptionMethod = EncryptionMethod.KeyMaster;
                                } else {
                                    delete invocationParametersCopy.encryptionMethod;
                                }
                                validateInvocationParameters();
                                updateInvocationParameters(invocationParametersCopy);
                            }}
                            description='Whether your service is certified to handle RED (critical) data or not. If set, then we may start sending you RED data.'
                        >Red certified</Checkbox>
                    </div>
                    <FormField
                        label='Encryption Method'
                        description='The encryption method to use for encrypting RED data. Only KeyMaster is supported, default set to KeyMaster if sending RED data.'>
                        <SelectWrapper
                            id={`experience.invocation-parameters.encryption-method-${arrayKey}`}
                            disabled={true} // TODO: set to disableInput when we support more methods
                            selectedId={invocationParameters.encryptionMethod}
                            options={Object.values(EncryptionMethod)
                                .map(option => { return { id: option, label: option }; })}
                            onChange={(e) => {
                                invocationParametersCopy.encryptionMethod = e.detail.selectedId as EncryptionMethod;
                                validateInvocationParameters();
                                updateInvocationParameters(invocationParametersCopy);
                            }}
                            validate={(method) => CommonValidator.isEncryptionMethodValid(method as EncryptionMethod, invocationParametersCopy.authenticationMethod)}/>
                    </FormField>
                </div>}
        </ColumnLayout>
    </div>;
};
