import React from 'react';
import { Checkbox } from '@amzn/awsui-components-react';

export interface ICheckboxWrapperProps {
    id: string;
    checked: boolean;
    disabled: boolean;
    validate: (input?: boolean) => void;
    onChange: (event: CustomEvent<Checkbox.ChangeDetail>) => void;
    children: React.ReactNode;
}

export class CheckboxWrapper extends React.Component<ICheckboxWrapperProps> {
    render () {
        const { id, checked, disabled, validate, onChange, children } = this.props;
        let errorMessage= '';

        if (!disabled) {
            try {
                validate(checked);
            } catch (error) {
                errorMessage = error.message;
            }
        }

        return <div>
            <Checkbox
                controlId={id}
                id={id}
                disabled={disabled}
                checked={checked}
                onChange={onChange} > {children} </Checkbox>
            <div className='awsui-grid'>
                <div className='awsui-row'>
                    <div className='col-12'>
                        {errorMessage && <p className='awsui-util-status-negative'>{errorMessage}</p>}
                    </div>
                </div>
            </div>
        </div>;
    }
}
