import * as React from 'react';
import ActivationDatesSectionView from '../../../containers/sections/ActivationDatesSectionView';
import DayTimeWindowSectionView from '../../../containers/sections/DayTimeWindowSectionView';
import RegionSectionView from '../../../containers/sections/RegionSectionView';
import BullseyeSectionView from '../../../containers/sections/BullseyeSectionView';
import NluOpportunityTriggeringSectionView from '../../../containers/sections/NluOpportunityTriggeringSectionView';
import VCIFDevicesSectionView from '../../../containers/sections/VCIFDevicesSectionView';

export const VisualCIFCustomerTargetingTabView = () => {
    return <div>
        <ActivationDatesSectionView />
        <DayTimeWindowSectionView />
        <RegionSectionView />
        <BullseyeSectionView />
        <VCIFDevicesSectionView />
        <NluOpportunityTriggeringSectionView triggerGrouping={'VisualCIFTriggers'} />
    </div>;
};
