import * as React from 'react';
import {Wizard} from '@amzn/awsui-components-react';

import ActivationDatesSectionView from '../sections/ActivationDatesSectionView';
import ContentVariablesSectionView from '../sections/ContentVariablesSectionView';
import DayTimeWindowSectionView from '../sections/DayTimeWindowSectionView';
import RegionSectionView from '../sections/RegionSectionView';
import DataSourceTypeSectionView from '../sections/DataSourceTypeSectionView';
import NotificationSectionView from '../sections/NotificationSectionView';
import SuggestedFeatureSectionView from '../sections/SuggestedFeatureSectionView';

import { ExperienceTypeSectionView } from '../../components/detail/creation/ExperienceTypeSectionView';
import { ExperienceType } from '../../models/Experience';
import NotificationDevicesSectionView from '../sections/NotificationDevicesSectionView';
import PromotedCapabilitySectionView from '../sections/PromotedCapabilitySectionView';

export const DeviceNotificationWorkflow: Wizard.Step[] = [
    {
        title: 'Notification placement',
        content: <div>
            <ActivationDatesSectionView />
            <DayTimeWindowSectionView />
        </div>
    },
    {
        title: 'Customer targeting',
        content: <div>
            <RegionSectionView />
            <DataSourceTypeSectionView />
            <NotificationDevicesSectionView />
        </div>
    },
    {
        title: 'Create experience',
        content: <div>
            <NotificationSectionView />
            <ContentVariablesSectionView />
            <PromotedCapabilitySectionView />
            <SuggestedFeatureSectionView />
        </div>
    },
    {
        title: 'Review experience',
        content: <div>
            <ExperienceTypeSectionView
                    selectedExperienceType={'DeviceNotification' as ExperienceType}
                    disableTypeSelection={true} />
            <ActivationDatesSectionView />
            <DayTimeWindowSectionView />

            <RegionSectionView />
            <DataSourceTypeSectionView />
            <NotificationDevicesSectionView />

            <NotificationSectionView />
            <ContentVariablesSectionView />
            <PromotedCapabilitySectionView />
            <SuggestedFeatureSectionView />
        </div>
    }
];
