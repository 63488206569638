import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { getLinkableUrl, PAGE } from '../constants/page';
import { getExperienceAction } from '../actions/experienceDetailViewActions';
import { AppState } from '../reducers/index';
import { IFlattenedExperience } from '../models/FlattenedExperience';
import { BreadcrumbGroup, Flash, Flashbar, Tabs } from '@amzn/awsui-components-react';
import ExperienceDetail from './ExperienceDetail';
import ExperienceMetrics from './ExperienceMetrics';
import FilterMetrics from './FilterMetrics';
import { AnnouncementAlertView } from './sections/OdysseyAnnouncementView';
import { getOdysseyAnnouncementAction } from '../actions/odysseyAnnouncementActions';
import { ExperienceType } from '../models/Experience';

export interface IExperienceProps {
    match: { params: { id: string } };

    dispatch: any;

    experience?: IFlattenedExperience;
    isLoading: boolean;
    error?: Error;

    // from edit
    isUpdating: boolean;
}

export interface IExperienceState {
    activeTabId: string;
}

class Experience extends Component<IExperienceProps, IExperienceState> {
    constructor(props: IExperienceProps) {
        super(props);

        this.state = {
            activeTabId: 'experience-configuration',
        };
    }

    public componentDidMount() {
        const { match, dispatch } = this.props;

        dispatch(getExperienceAction(match.params.id));
        dispatch(getOdysseyAnnouncementAction());
    }

    public componentDidUpdate() {
        const { dispatch, experience, isLoading, error, match } = this.props;

        if (!experience && !isLoading && !error) {
            dispatch(getExperienceAction(match.params.id));
        }
    }

    render() {
        const { isLoading, error, isUpdating, experience } = this.props;
        const { activeTabId } = this.state;

        if (error) {
            return <Flashbar id='experience.error-bar' items={[{
                header: 'Loading failed',
                content: 'Failed to find your experience',
                type: 'error'
            }]} />;
        }

        if (isLoading) {
            return <Flash id='experience.loading-bar' dismissible={false} >Loading...</Flash>;
        }

        if (isUpdating) {
            return <Flash id='experience.updating-bar' dismissible={false} >Updating...</Flash>;
        }

        if (!experience) {
            return <Flash dismissible={true} type='error' id='experience.error-bar' >
                Something happened while trying to load your experience...
            </Flash>;
        }

        return <div>
            <AnnouncementAlertView />
            <div style={{ margin: '30px' }}>
                <BreadcrumbGroup label='Breadcrumbs' items={[{
                    text: 'Odyssey',
                    href: getLinkableUrl(PAGE.Home),
                }, {
                    text: 'Experiences',
                    href: getLinkableUrl(PAGE.Experiences),
                }, {
                    text: experience.title || '-',
                    href: getLinkableUrl(PAGE.Experiences) + '/' + experience.id
                }]} />
                <Tabs
                    id='experience.tabs'
                    activeTabId={activeTabId}
                    tabs={getExperienceTabs(experience.type, this.props)}
                    onChange={(e) => this.setState({ ...this.state, activeTabId: e.detail.activeTabId }) }/>
            </div>
        </div>;
    }
}

const getExperienceTabs = (type: ExperienceType, props: IExperienceProps): Tabs.Tab[] => {

    if (type === 'CIF') {
        return [{
            label: 'Experience Configuration',
            id: 'experience-configuration',
            content: <ExperienceDetail {...props} />
        }, {
            label: 'Metrics',
            id: 'metrics',
            content: <ExperienceMetrics {...props} />
        }, {
            label: 'Funnel Analysis',
            id: 'funnel-analysis',
            content: <FilterMetrics {...props} />
        }];
    } else if (type === 'AppNotification' || type === 'DeviceNotification') {
        return [{
            label: 'Experience Configuration',
            id: 'experience-configuration',
            content: <ExperienceDetail {...props} />
        }, {
            label: 'Metrics',
            id: 'metrics',
            content: <ExperienceMetrics {...props} />
        }];
    } else {
        return [];
    }
};

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isLoading has default value set in reducer, so no default
        // value needed
        experience: experienceDetailViewState.experience || undefined,
        isLoading: experienceDetailViewState.isLoading,
        error: experienceDetailViewState.error || undefined,

        isUpdating: experienceEditViewState.isUpdating,
    };
};

export default connect(mapStateToProps)(Experience);
