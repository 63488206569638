import { IUpdateExperienceAction } from '../actions/experienceEditViewActions';
import {
    UPDATING_EXPERIENCE,
    UPDATED_EXPERIENCE,
    ERROR_UPDATING_EXPERIENCE,
    ENABLE_EDITING,
    ENABLE_CLONING,
    CANCEL_EDITING,
    PUSH_REGION,
    PUSH_ROW_COUNTRY_INFO,
    PUSH_DAYTIME_WINDOW,
    PUSH_BASIC_INFO,
    PUSH_IMPRESSION_TRACKING,
    PUSH_SPEAKER_DATA,
    PUSH_SUGGESTED_FEATURE,
    PUSH_EXPOSURE_CONTROL_RATE,
    PUSH_APPROVAL_INFO,
    PUSH_JOURNEY_METADATA,
    PUSH_NOTIFICATION_GUARDRAILS,
    PUSH_BUSINESS_INFO,
    PUSH_PROMOTED_CAPABILITY,
    PUSH_BYPASS_CAPABILITY_FILTER,
    PUSH_USE_TOUCH_ACTION_TEMPLATE,
    PUSH_USE_NON_CTA_TEMPLATE,
    PUSH_CUSTOM_DOMAIN_ILLUSTRATION_SOURCE,
    PUSH_OVERRIDE_DEFAULT_BACKGROUND_IMAGE,
    PUSH_ACKNOWLEDGE_BACKGROUND_IMAGE,
    DISABLING_SAVE_BUTTON,
    PUSH_MOBILE_HOME_CARD_CONSTRAINT,
    PUSH_MOBILE_HOME_CARD_AB_EXPERIMENT,
    PUSH_MOBILE_HOME_CARD_CONTENT,
    PUSH_MOBILE_HOME_CARD_TARGETING,
    PUSH_MOBILE_HOME_CARD_ACTION,
    PUSH_MOBILE_HOME_CARD_CONTEXT,
    PUSH_ROTATING_CONTENT_PANEL_DISPLAY_ELEMENTS,
    PUSH_ROTATING_CONTENT_PANEL_TOUCH_ACTIONS,
    PUSH_ROTATING_CONTENT_PANEL_OVERRIDE_ACCOUNTS,
    PUSH_ROTATING_CONTENT_PANEL_METADATA,
    PUSH_ROTATING_CONTENT_PANEL_BUSINESS_INFO,
    PUSH_ROTATING_CONTENT_PANEL_CONTEXT,
    PUSH_ROTATING_CONTENT_PANEL_DEVICES,
    PUSH_ROTATING_CONTENT_PANEL_ACTIVATION_TIME, PUSH_SUBSCRIPTION_UPSELL_TYPE,
} from '../constants/experienceEditViewActionTypes';
import { ExperienceUpdateCandidate } from '../models/ExperienceUpdateCandidate';
import { PUSH_TYPE, ENABLE_CREATING, PUSH_METADATA, DELETING_EXPERIENCE, PUSH_STATUS, PUSH_OPPORTUNITY_INCLUSION, PUSH_OPPORTUNITY_EXCLUSION, PUSH_ENABLE_VOICE_CIF } from '../constants/experienceEditViewActionTypes';
import {
    PUSH_FEATURE_HINT,
    PUSH_BULLSEYE,
    PUSH_NLU_OPPORTUNITY_INCLUSION,
    PUSH_NLU_OPPORTUNITY_EXCLUSION,
    PUSH_CONTENT,
    PUSH_CONTENT_VARIABLES,
    PUSH_DEVICES,
    PUSH_OVERRIDE_ACCOUNTS,
    PUSH_OVERRIDE_FILTERS,
    PUSH_DOMAIN_TAGS,
    PUSH_REMOTE_TAGS,
    PUSH_NOTIFICATION_CONTENT,
    PUSH_DATA_SOURCE,
    PUSH_VISUAL_INTERSTITIAL_VARIABLES,
    PUSH_BYOR_SNS_TOPIC_ARN,
    PUSH_INCLUDING_ODYSSEY_FIELDS
} from '../constants/experienceEditViewActionTypes';
import { getDefaultDayTimeGuardrails } from '../models/TimeRange';
import { isStringEmpty } from '../util/stringAndMappingHelper';
import {isNullOrUndefined} from 'util';
import { PROACTIVE_NOTIFICATIONS_USER_GROUP } from '../constants/userGroups';

export interface IExperienceEditViewState {
    updateCandidate?: ExperienceUpdateCandidate;
    isEditing: boolean;
    isUpdating: boolean;
    isCloning: boolean;
    id?: string;
    error?: Error;
    shouldDisableSave: boolean;
}

export const experienceEditViewReducer = (
    state: IExperienceEditViewState = {
        isUpdating: false,
        isCloning: false,
        isEditing: false,
        shouldDisableSave: false
    },
    action: IUpdateExperienceAction
): IExperienceEditViewState => {
    switch (action.type) {
        /* Actions related to putCifExperiment */
        case DISABLING_SAVE_BUTTON:
            return {
                ...state,
                shouldDisableSave: action.shouldDisableSave || false
            };
        case UPDATING_EXPERIENCE:
            return {
                ...state,
                isUpdating: true
            };
        case DELETING_EXPERIENCE:
            return {
                ...state,
                isUpdating: true
            };
        case UPDATED_EXPERIENCE:
            // reducer is at top level. it should resolve error
            // and get it ready for rendering.
            if (!action.id) {
                return {
                    ...state,
                    isUpdating: false,
                    isEditing: false,
                    error: new Error('Updating `undefined` experience, something went wrong')
                };
            }
            return {
                ...state,
                isUpdating: false,
                isEditing: false,
                id: action.id,
                error: undefined
            };
        case ERROR_UPDATING_EXPERIENCE:
            return {
                ...state,
                isUpdating: false,
                error: action.error
            };
        case ENABLE_EDITING:
            if (!action.experience) {
                return {
                    ...state,
                    error: new Error('No experience to edit')
                };
            }
            return {
                ...state,
                isEditing: true,
                isCloning: false,
                id: undefined,
                updateCandidate: new ExperienceUpdateCandidate(ExperienceUpdateCandidate.extractUpdateCandidate(action.experience))
            };

        case CANCEL_EDITING:
            return {
                ...state,
                isEditing: false,
                isCloning: false,
                error: undefined,
                updateCandidate: undefined
            };
        case ENABLE_CLONING:
            if (!action.experience) {
                return {
                    ...state,
                    error: new Error('No experience to clone')
                };
            }
            return {
                ...state,
                isEditing: true,
                isCloning: true,
                updateCandidate: ExperienceUpdateCandidate.cloneExperience(action.experience)
            };
        case ENABLE_CREATING:
            if (!action.experience) {
                return {
                    ...state,
                    error: new Error('No experience template')
                };
            }
            return {
                ...state,
                isEditing: true,
                isCloning: false,
                updateCandidate: ExperienceUpdateCandidate.createExperience(action.experience)
            };
        /* Actions related to push to update candidate */
        case PUSH_REGION:
            if (!state.updateCandidate || !action.region) {
                return {
                    ...state,
                    error: new Error('Could not update region')
                };
            }

            state.updateCandidate.setRegion(action.region);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_ROW_COUNTRY_INFO:
            if (!state.updateCandidate || !action.rowCountryInfo) {
                return {
                    ...state,
                    error: new Error('Could not update row country info')
                };
            }

            state.updateCandidate.setRowCountryInfo(action.rowCountryInfo);
            return {...state, updateCandidate: state.updateCandidate.getClone()};

        case PUSH_DAYTIME_WINDOW:
            if (!state.updateCandidate || !action.timeRanges) {
                return {
                    ...state,
                    error: new Error('Could not update daytime window')
                };
            }

            state.updateCandidate.setTimeRanges(action.timeRanges);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_BASIC_INFO:
            if (!state.updateCandidate || !action.basicInfo) {
                return {
                    ...state,
                    error: new Error('Could not update basicInfo')
                };
            }
            if (!isStringEmpty(state.updateCandidate.getNotificationContent().interactionPayload) &&
                    !isNullOrUndefined(action.basicInfo.groupImpressions) &&
                    action.basicInfo.groupImpressions !== PROACTIVE_NOTIFICATIONS_USER_GROUP) {
                state.updateCandidate.setNotificationContent({...state.updateCandidate.getNotificationContent(), interactionPayload: ''});
            }
            state.updateCandidate.setBasicInfo(action.basicInfo);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_NOTIFICATION_GUARDRAILS:
            if (!state.updateCandidate || !action.notificationGuardrails) {
                return {
                    ...state,
                    error: new Error('Could not update notification guardrails')
                };
            }
            state.updateCandidate.setNotificationGuardrails(action.notificationGuardrails);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_TYPE:
            if (!state.updateCandidate || !action.experienceType) {
                return {
                    ...state,
                    error: new Error('Could not update experience type')
                };
            }

            state.updateCandidate.setType(action.experienceType);
            state.updateCandidate.setTimeRanges(getDefaultDayTimeGuardrails(action.experienceType, action.isVisualCIF));

            if (action.isQuickCreateWorkflow !== undefined) {
                state.updateCandidate.setIsQuickCreateWorkflow(action.isQuickCreateWorkflow);
            }
            if (action.isVisualCIF !== undefined) {
                state.updateCandidate.setIsVisualCIF(action.isVisualCIF);

                if (action.isVisualCIF) {
                    state.updateCandidate.setEnableVoiceCif(false);
                }
            }

            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_STATUS:
            if (!state.updateCandidate || !action.experienceStatus) {
                return {
                    ...state,
                    error: new Error('Could not update experience status')
                };
            }

            state.updateCandidate.setStatus(action.experienceStatus);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_METADATA:
            if (!state.updateCandidate || !action.metadata) {
                return {
                    ...state,
                    error: new Error('Could not update experience metadata')
                };
            }

            state.updateCandidate.setMetadata(action.metadata);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_IMPRESSION_TRACKING:
            if (!state.updateCandidate || !action.impressionTracking) {
                return {
                    ...state,
                    error: new Error('Could not update Impression Tracking')
                };
            }

            state.updateCandidate.setImpressionTracking(action.impressionTracking);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_PROMOTED_CAPABILITY:
            if (!state.updateCandidate || !action.promotedCapability) {
                return {
                    ...state,
                    error: new Error('Could not update Promoted Capability')
                };
            }
            state.updateCandidate.setPromotedCapability(action.promotedCapability.capabilityId);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_BYPASS_CAPABILITY_FILTER:
            if (!state.updateCandidate || action.bypassCapabilityFilter === undefined) {
                return {
                    ...state,
                    error: new Error('Could not update Capability Filter Bypass')
                };
            }
            state.updateCandidate.setbypassCapabilityFilter(action.bypassCapabilityFilter);
            return {...state, updateCandidate: state.updateCandidate.getClone()};

        case PUSH_SUGGESTED_FEATURE:
            if (!state.updateCandidate || !action.suggestedFeature) {
                return {
                    ...state,
                    error: new Error('Could not update suggested feature')
                };
            }

            state.updateCandidate.setSuggestedFeature(action.suggestedFeature);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_CONTENT:
            if (!state.updateCandidate || !action.content) {
                return {
                    ...state,
                    error: new Error('Could not update Content')
                };
            }

            state.updateCandidate.setContent(action.content);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_CONTENT_VARIABLES:
            if (!state.updateCandidate || !action.templateVariables) {
                return {
                    ...state,
                    error: new Error('Could not update Template Variables')
                };
            }

            state.updateCandidate.setTemplateVariables(action.templateVariables);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_DEVICES:
            if (!state.updateCandidate || !action.devices) {
                return {
                    ...state,
                    error: new Error('Could not update Devices')
                };
            }

            state.updateCandidate.setDevices(action.devices);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_OVERRIDE_ACCOUNTS:
            if (!state.updateCandidate || !action.overrideAccounts) {
                return {
                    ...state,
                    error: new Error('Could not update Override Accounts')
                };
            }

            state.updateCandidate.setOverrideAccounts(action.overrideAccounts);
            state.updateCandidate.setVerboseLoggingEnabled(action.verboseLoggingEnabled || false);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_OVERRIDE_FILTERS:
            if (!state.updateCandidate || !action.overrideSelectiveFilters) {
                return {
                    ...state,
                    error: new Error('Could not update Override Filters')
                };
            }

            state.updateCandidate.setOverrideFilters(action.overrideSelectiveFilters);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_DOMAIN_TAGS:
            if (!state.updateCandidate || !action.contextualSegments) {
                return {
                    ...state,
                    error: new Error('Could not update Domain Tags')
                };
            }

            state.updateCandidate.setDomainTags(action.contextualSegments);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_REMOTE_TAGS:
            if (!state.updateCandidate || !action.remoteContextualSegments) {
                return {
                    ...state,
                    error: new Error('Could not update Remote Tags')
                };
            }

            state.updateCandidate.setRemoteTags(action.remoteContextualSegments);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_FEATURE_HINT:
            if (!state.updateCandidate || !action.featureHints) {
                return {
                    ...state,
                    error: new Error('Could not update Feature Hints')
                };
            }

            state.updateCandidate.setFeatureHints(action.featureHints);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_BULLSEYE:
            if (!state.updateCandidate || (action.bullseye === undefined)) {
                return {
                    ...state,
                    error: new Error('Could not update Bullseye')
                };
            }

            state.updateCandidate.setBullseye(action.bullseye);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_OPPORTUNITY_INCLUSION:
            if (!state.updateCandidate || !action.opportunityTriggerInclusions) {
                return {
                    ...state,
                    error: new Error('Could not update Opportunity Trigger Inclusions')
                };
            }

            state.updateCandidate.setTriggerInclusions(action.opportunityTriggerInclusions);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_OPPORTUNITY_EXCLUSION:
            if (!state.updateCandidate || !action.opportunityTriggerExclusions) {
                return {
                    ...state,
                    error: new Error('Could not update Opportunity Trigger Exclusions')
                };
            }

            state.updateCandidate.setTriggerExclusions(action.opportunityTriggerExclusions);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_NLU_OPPORTUNITY_INCLUSION:
            if (!state.updateCandidate || !action.nluOpportunityTargetingInclusions) {
                return {
                    ...state,
                    error: new Error('Could not update Nlu Targeting Inclusions')
                };
            }

            state.updateCandidate.setTargetingInclusions(action.nluOpportunityTargetingInclusions);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_NLU_OPPORTUNITY_EXCLUSION:
            if (!state.updateCandidate || !action.nluOpportunityTargetingExclusions) {
                return {
                    ...state,
                    error: new Error('Could not update Nlu Targeting Exclusions')
                };
            }

            state.updateCandidate.setTargetingExclusions(action.nluOpportunityTargetingExclusions);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_SPEAKER_DATA:
            if(!state.updateCandidate || !action.speakerData) {
                return {
                    ...state,
                    error: new Error('Could not update speaker data')
                };
            }

            state.updateCandidate.setSpeakerData(action.speakerData);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_NOTIFICATION_CONTENT:
            if (!state.updateCandidate || !action.notificationContent) {
                return {
                    ...state,
                    error: new Error('Could not update notification content')
                };
            }

            state.updateCandidate.setNotificationContent(action.notificationContent);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_DATA_SOURCE:
            if (!state.updateCandidate || !action.dataSource) {
                return {
                    ...state,
                    error: new Error('Could not update Data Source Type')
                };
            }

            if (action.dataSource.dataSource === 'AUTO_CREATED_SNS_TOPIC' && state.updateCandidate.getDataSourceInfo().snsTopic) {
                state.updateCandidate.resetSnsTopic();
            }

            state.updateCandidate.setDataSourceInfo(action.dataSource);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_VISUAL_INTERSTITIAL_VARIABLES:
            if (!state.updateCandidate || !action.visualInterstitialCards) {
                return {
                    ...state,
                    error: new Error('Could not update Visual Interstitial Cards')
                };
            }
            state.updateCandidate.setVisualInterstitialCards(action.visualInterstitialCards);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_ENABLE_VOICE_CIF:
            if (!state.updateCandidate) {
                return {
                    ...state,
                    error: new Error('Could not update Visual promptless boolean')
                };
            }

            state.updateCandidate.setEnableVoiceCif(action.enableVoiceCif || false);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_INCLUDING_ODYSSEY_FIELDS:
            if (!state.updateCandidate) {
                return {
                    ...state,
                    error: new Error('Could not update include OdysseyFields boolean')
                };
            }

            state.updateCandidate.setIncludingOdysseyFields(action.includingOdysseyFields || false);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_EXPOSURE_CONTROL_RATE:
            if (!state.updateCandidate || !action.launchTreatmentPercentage) {
                return {
                    ...state,
                    error: new Error('Could not update exposure control rate')
                };
            }
            state.updateCandidate.setLaunchTreatmentPercentage(action.launchTreatmentPercentage);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_APPROVAL_INFO:
            if (!state.updateCandidate || !action.approvalInfo) {
                return {
                    ...state,
                    error: new Error('Could not update approval info')
                };
            }
            state.updateCandidate.setApprovalInfo(action.approvalInfo);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_BYOR_SNS_TOPIC_ARN:
            if (!state.updateCandidate || action.snsTopicBYORDataSource === undefined) {
                return {
                    ...state,
                    error: new Error('Could not update BYOR SNS topic arn')
                };
            }
            state.updateCandidate.setSnsTopicBYORDataSource(action.snsTopicBYORDataSource);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_JOURNEY_METADATA:
            if (!state.updateCandidate || !action.journeyMetadata) {
                return {
                    ...state,
                    error: new Error('Could not update journey metadata')
                };
            }
            state.updateCandidate.setJourneyMetadata(action.journeyMetadata);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_BUSINESS_INFO:
            if (!state.updateCandidate || !action.businessInfo) {
                return {
                    ...state,
                    error: new Error('Could not update business info')
                };
            }
            state.updateCandidate.setBusinessInfo(action.businessInfo);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_SUBSCRIPTION_UPSELL_TYPE:
            if (!state.updateCandidate || !action.subscriptionUpsellType) {
                return {
                    ...state,
                    error: new Error('Could not update subscription upsell type')
                };
            }
            state.updateCandidate.setSubscriptionUpsellType(action.subscriptionUpsellType);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_USE_TOUCH_ACTION_TEMPLATE:
            if (!state.updateCandidate) {
                return {
                    ...state,
                    error: new Error('Could not update use touch action template')
                };
            }
            state.updateCandidate.setUseTouchActionTemplate(action.useTouchActionTemplate || false);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_USE_NON_CTA_TEMPLATE:
            if (!state.updateCandidate) {
                return {
                    ...state,
                    error: new Error('Could not update use non CTA template')
                };
            }
            state.updateCandidate.setUseNonCTATemplate(action.useNonCTATemplate || false);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_CUSTOM_DOMAIN_ILLUSTRATION_SOURCE:
            if (!state.updateCandidate) {
                return {
                    ...state,
                    error: new Error('Could not update toggle domain illustration source')
                };
            }
            state.updateCandidate.setDomainIllustrationCustomSource(action.domainIllustrationCustomSource || [false, false, false]);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_OVERRIDE_DEFAULT_BACKGROUND_IMAGE:
            if (!state.updateCandidate) {
                return {
                    ...state,
                    error: new Error('Could not update override default background image state')
                };
            }
            state.updateCandidate.setOverrideBackgroundImage(action.overrideBackgroundImage || [false, false, false]);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_ACKNOWLEDGE_BACKGROUND_IMAGE:
            if (!state.updateCandidate) {
                return {
                    ...state,
                    error: new Error('Could not update acknowledge background image state')
                };
            }
            state.updateCandidate.setAcknowledgeBackgroundImage(action.acknowledgeBackgroundImage || [false, false, false]);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_MOBILE_HOME_CARD_ACTION:
            if (!state.updateCandidate || !action.mobileHomeCardActions) {
                return {
                    ...state,
                    error: new Error('Could not update mobile home card action')
                };
            }
            state.updateCandidate.setMobileCardActions(action.mobileHomeCardActions);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_MOBILE_HOME_CARD_CONSTRAINT:
            if (!state.updateCandidate || !action.mobileHomeCardConstraint) {
                return {
                    ...state,
                    error: new Error('Could not update mobile home card constraint')
                };
            }
            state.updateCandidate.setMobileHomeCardConstraint(action.mobileHomeCardConstraint);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_MOBILE_HOME_CARD_AB_EXPERIMENT:
            if (!state.updateCandidate || !action.mobileHomeCardAbExperiment) {
                return {
                    ...state,
                    error: new Error('Could not update mobile home card ab experiment')
                };
            }
            state.updateCandidate.setMobileHomeCardAbExperiment(action.mobileHomeCardAbExperiment);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_MOBILE_HOME_CARD_CONTENT:
            if (!state.updateCandidate || !action.mobileHomeCardContent) {
                return {
                    ...state,
                    error: new Error('Could not update mobile home card content')
                };
            }
            state.updateCandidate.setMobileHomeCardContent(action.mobileHomeCardContent);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_MOBILE_HOME_CARD_TARGETING:
            if (!state.updateCandidate || !action.mobileHomeCardTargeting) {
                return {
                    ...state,
                    error: new Error('Could not update mobile home card customer targeting')
                };
            }
            state.updateCandidate.setMobileHomeCardTargeting(action.mobileHomeCardTargeting);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_MOBILE_HOME_CARD_CONTEXT:
            if(!state.updateCandidate || ! action.mobileHomeCardContext) {
                return {
                    ...state,
                    error: new Error('Could not update mobile home card context')
                };
            }
            state.updateCandidate.setMobileHomeCardContext(action.mobileHomeCardContext);
            return {...state, updateCandidate: state.updateCandidate.getClone()};
        case PUSH_ROTATING_CONTENT_PANEL_DISPLAY_ELEMENTS:
            if (!state.updateCandidate || !action.rotatingContentPanelContent) {
                return {
                    ...state,
                    error: new Error('Could not update RCP display elements')
                };
            }
            state.updateCandidate.setRotatingContentPanelContent({ ...action.rotatingContentPanelContent });
            return { ...state, updateCandidate: state.updateCandidate.getClone() };
        case PUSH_ROTATING_CONTENT_PANEL_TOUCH_ACTIONS:
            if (!state.updateCandidate || !action.rotatingContentPanelContent) {
                return {
                    ...state,
                    error: new Error('Could not update RCP touch action')
                };
            }
            state.updateCandidate.setRotatingContentPanelContent({ ...action.rotatingContentPanelContent });
            return { ...state, updateCandidate: state.updateCandidate.getClone() };
        case PUSH_ROTATING_CONTENT_PANEL_OVERRIDE_ACCOUNTS:
            if (!state.updateCandidate || !action.rotatingContentPanelTestingConstraint) {
                return {
                    ...state,
                    error: new Error('Could not update RCP override accounts')
                };
            }
            state.updateCandidate.setRotatingContentPanelTestingConstraint({ rcpOverrideAccounts: action.rotatingContentPanelTestingConstraint.rcpOverrideAccounts });
            return { ...state, updateCandidate: state.updateCandidate.getClone() };
        case PUSH_ROTATING_CONTENT_PANEL_METADATA:
            if (!state.updateCandidate || !action.rotatingContentPanelContent) {
                return {
                    ...state,
                    error: new Error('Could not update RCP metadata')
                };
            }
            state.updateCandidate.setRotatingContentPanelContent({ ...action.rotatingContentPanelContent });
            return { ...state, updateCandidate: state.updateCandidate.getClone() };
        case PUSH_ROTATING_CONTENT_PANEL_BUSINESS_INFO:
            if (!state.updateCandidate || !action.rotatingContentPanelBusinessContext) {
                return {
                    ...state,
                    error: new Error('Could not update RCP business info')
                };
            }
            state.updateCandidate.setRotatingContentPanelBusinessContext({ ...action.rotatingContentPanelBusinessContext });
            return { ...state, updateCandidate: state.updateCandidate.getClone() };
        case PUSH_ROTATING_CONTENT_PANEL_CONTEXT:
            if (!state.updateCandidate || !action.rotatingContentPanelContext) {
                return {
                    ...state,
                    error: new Error('Could not update RCP context')
                };
            }
            state.updateCandidate.setRotatingContentPanelContext({ ...action.rotatingContentPanelContext});
            return { ...state, updateCandidate: state.updateCandidate.getClone() };
        case PUSH_ROTATING_CONTENT_PANEL_DEVICES:
            if (!state.updateCandidate || !action.rotatingContentPanelDevicesConstraint) {
                return {
                    ...state,
                    error: new Error('Could not update RCP devices')
                };
            }
            state.updateCandidate.setRotatingContentPanelDevicesConstraint(action.rotatingContentPanelDevicesConstraint.deviceTypes || []);
            return { ...state, updateCandidate: state.updateCandidate.getClone() };
        case PUSH_ROTATING_CONTENT_PANEL_ACTIVATION_TIME:
            if (!state.updateCandidate || !action.rotatingContentPanelSchedulingConstraint) {
                return {
                    ...state,
                    error: new Error('Could not update RCP activation time')
                };
            }
            state.updateCandidate.setRotatingContentPanelSchedulingConstraint(action.rotatingContentPanelSchedulingConstraint);
            return { ...state, updateCandidate: state.updateCandidate.getClone() };
        default:
            return state;
    }
};
