import * as React from 'react';
import { Table, TablePagination } from '@amzn/awsui-components-react';
import { ISimilarExperiencesViewableData } from '../../models/SimilarExperiencesData';
import { getLinkableUrl, PAGE } from '../../constants/page';
import { OutputMetricData } from './ExperienceCifMetrics';
import { CifMetricNames } from '../../models/CifMetrics';
import { ExternalLink } from '../common/LinkComponents';


const similarExperiencesDisplayColumnDefinitions = () => {
    return [
        {
            id: 'experience',
            header: 'Experience Title',
            cell: (display: ISimilarExperiencesViewableData) =>  {
                return  <ExternalLink href ={`${getLinkableUrl(PAGE.Experiences)}/${display.experienceId}`}> {display.experienceTitle !== undefined ? display.experienceTitle : '-'} </ExternalLink>;
            },
            width: 300,
        },
        {
            id: 'totalImpression',
            header: 'Total Impression',
            cell: (display: ISimilarExperiencesViewableData) => display.guardRailMetrics.totalImpression !== undefined ? display.guardRailMetrics.totalImpression : '-',
            width: 175,
        },
        {
            id: 'distinctCustomers',
            header: 'Distinct Customers',
            cell: (display: ISimilarExperiencesViewableData) => display.guardRailMetrics.distinctCustomers !== undefined ? display.guardRailMetrics.distinctCustomers : '-',
            width: 175,
        },
        {
            id: 'interruptionRate',
            header: 'Interruption Rate',
            cell: (display: ISimilarExperiencesViewableData) => {
                if (display.guardRailMetrics.interruptionRate === undefined) {
                    return '-';
                } else {
                    return <OutputMetricData metricValue={display.guardRailMetrics.interruptionRate} metricName={CifMetricNames.InterruptionRate}/>;
                }
            },
            width: 175,
        },
        {
            id: 'negativeFeedBack',
            header: 'Negative Feedback Rate',
            cell: (display: ISimilarExperiencesViewableData) => {
                if (display.guardRailMetrics.negativeFeedBack === undefined) {
                    return '-';
                } else {
                    return <OutputMetricData metricValue={display.guardRailMetrics.negativeFeedBack} metricName={CifMetricNames.NegativeFeedback}/>;
                }
            },
            width: 175,
        }
    ];
};

export const ExperienceSimilarDisplay = ({ isLoading, similarExperiencesViewableData }: { isLoading?: boolean, similarExperiencesViewableData?: ISimilarExperiencesViewableData[] }) => {
    return <div id='similar.cif' className='awsui-util-container awsui-util-no-gutters' style={{ marginBottom: '0' }}>
        <div className='awsui-util-container-header'>
            <div>
                Similar CIF Experiences
            </div>
            <div className='awsui-util-container-header-description' style = {{ marginTop: '5px'}}>
                <div >
                    Showing up to 10 similar experiences per page, with 50 experiences max.
                    The metrics data shown are weekly averages across the last 7 weeks.
                    The list is sorted with the most similar experiecnes at the top of the list.
                </div>
            </div>
        </div>
        <Table
            id='table.similar-experiences'
            columnDefinitions={similarExperiencesDisplayColumnDefinitions()}
            items={similarExperiencesViewableData || []}
            loadingText='Loading Similar CIF Experiences'
            loading={isLoading}
            empty={<div>No Similar CIF Experiences Found</div>}
            resizableColumns={true}
        >
            <TablePagination pageSize={10}/>
        </Table>
    </div>;
};


