import * as React from 'react';
import MobileHomeCardDisplayElementsSectionView from '../../../containers/sections/MobileHomeCardDisplayElementsSectionView';
import MobileHomeCardActionSectionView from '../../../containers/sections/MobileHomeCardActionSectionView';
import MobileHomeCardExperienceDetailsSectionView from '../../../containers/sections/MobileHomeCardExperienceDetailsSectionView';

export const MobileHomeCardTabView = () => {
    return <div>
        <MobileHomeCardExperienceDetailsSectionView />
        <MobileHomeCardDisplayElementsSectionView />
        <MobileHomeCardActionSectionView />
    </div>;
};
