import * as React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { Button, Modal } from '@amzn/awsui-components-react';
import CapabilitySearchComponent from '../common/CapabilitySearchComponent';
import { cancelEditingAction, pushPromotedCapabilityAction, saveExperienceAction, toggleCapabilitySearchModalVisibility } from '../../actions/experienceEditViewActions';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { ICapabilityCatalogSearchState } from '../../reducers/capabilityCatalogSearchReducer';


interface CapabilitySearchModalProps {
    dispatch: any;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
    capabilityCatalogSearchState: ICapabilityCatalogSearchState;
}

class CapabilitySearchModal extends React.Component<CapabilitySearchModalProps> {

    toggleModalVisibility = () => {
        const {dispatch} = this.props;
        dispatch(toggleCapabilitySearchModalVisibility());
    }

    onModalConfirm = () => {
        const { dispatch, capabilityCatalogSearchState, experience } = this.props;
        dispatch(pushPromotedCapabilityAction(capabilityCatalogSearchState.selectedCapability));
        this.toggleModalVisibility();

        if (_.isEmpty(experience?.capabilityId) && capabilityCatalogSearchState.saveOnConfirm && experience?.type !== 'RotatingContentPanel') {
            dispatch(saveExperienceAction());
        }
    }

    onDismiss = () => {
        const { dispatch, capabilityCatalogSearchState } = this.props;
        this.toggleModalVisibility();

        if (capabilityCatalogSearchState.saveOnConfirm) {
            dispatch(cancelEditingAction());
        }
    }

    render(): React.ReactNode {
        const { isVisible } = this.props.capabilityCatalogSearchState;

        return (
            <Modal
                id='modal.switch-to-capability'
                visible={isVisible}
                onDismiss={this.onDismiss}
                header='Promoted Capability'
                size='large'
                footer={<span className='awsui-util-f-r'>
                <Button
                    id='cancel-capability-modal'
                    variant='link'
                    onClick={this.onDismiss}>
                  Cancel
                </Button>
                <Button variant='primary'
                    id='confirm-capability-modal'
                    onClick={this.onModalConfirm}
                    disabled={_.isEmpty(this.props.capabilityCatalogSearchState.selectedCapability)}
                >
                  Confirm
                </Button>
              </span>}
            >
                <CapabilitySearchComponent />
            </Modal>
        );
    }

}


const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState, capabilityCatalogSearchState }: AppState) => {
    return {
        capabilityCatalogSearchState,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(CapabilitySearchModal);
