import * as React from 'react';
import { Component } from 'react';

import {
    Table, TablePropertyFiltering, TableSorting, TablePagination,
    TablePreferences, TableContentSelector, TablePageSizeSelector, TableWrapLines
} from '@amzn/awsui-components-react';

import { NoMatchDisplay } from '../NoMatchDisplay';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { fromCompressedSearchParamsToPropertyFilters, fromPropertyFiltersToCompressedSearchParams } from '../../models/uri/SearchParams';
import { COLUMN_DEFINITIONS_SUGGESTIONS, FILTERING_OPTIONS, SELECTABLE_COLUMNS_SUGGESTIONS, SORTABLE_COLUMNS_SUGGESTIONS } from '../../constants/suggestionsTableColumnConfiguration';
import { getSuggestionsAction, unsetSuggestions } from '../../actions/homeCardSuggestionsListViewActions';
import { IHomeCardSuggestion } from '../../models/HomeCardSuggestion';
import { suggestionsPageLimit } from '../../constants/componentConstants';
import { EmptySuggestionsDisplay } from '../EmptySuggestionsDisplay';
import { countTextFunction } from '../../util/stringAndMappingHelper';
import { getPermissionAction } from '../../actions/authenticationActions';
import { HOME_CARD_APPROVERS_USER_GROUP } from '../../constants/userGroups';


interface ISuggestionsProps {
    // from Redux
    isLoading: boolean;
    suggestions: IHomeCardSuggestion[];
    lastEvaluatedKey?: string;
    error?: Error;

    // Redux inherited
    dispatch: any;

    // initialized
    updateFilteringCriteria: (searchCriteriaString: string) => void;
    searchCriteriaString: string | null;
}

export class HomeCardSuggestionsTable extends Component<ISuggestionsProps> {
    public componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getPermissionAction(HOME_CARD_APPROVERS_USER_GROUP));
        dispatch(getSuggestionsAction(suggestionsPageLimit));
    }

    public componentDidUpdate() {
        const { dispatch, lastEvaluatedKey, isLoading } = this.props;

        if (!isLoading && lastEvaluatedKey) {
            dispatch(getSuggestionsAction(suggestionsPageLimit, lastEvaluatedKey));
        }

    }

    public componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(unsetSuggestions());
    }

    public render() {
        const { isLoading, lastEvaluatedKey, suggestions, updateFilteringCriteria, searchCriteriaString } = this.props;

        const isPagesLoading: boolean = (lastEvaluatedKey !== undefined);
        let searchParams: TablePropertyFiltering.ChangeDetail | undefined;
        try {
            if (searchCriteriaString) {
                const tokens = JSON.parse(searchCriteriaString);
                searchParams = fromCompressedSearchParamsToPropertyFilters(tokens);
            }

        } catch (error) {
            // if it fails, we keep tokens as undefined, then will resolve to [], which is still safe
        }

        return (
            <Table
                id='table.suggestions'
                columnDefinitions={COLUMN_DEFINITIONS_SUGGESTIONS}
                items={suggestions}
                loadingText='Loading Suggestions'
                loading={isLoading}
                empty={<EmptySuggestionsDisplay />}
                noMatch={<NoMatchDisplay />}
                resizableColumns={true}
                stickyHeader={true}
                wrapLines={false}
            >
            <TablePropertyFiltering
                filteringOptions={FILTERING_OPTIONS}
                groupValuesText='Values'
                groupPropertiesText='Properties'
                operationAndText='and'
                operationNotAndText='and not'
                operationOrText='or'
                operationNotOrText='or not'
                clearFiltersText='Clear filter'
                placeholder='Filter suggestions by suggestion id'
                allowFreeTextFiltering={true}
                filteringCountTextFunction={countTextFunction}
                operation={searchParams ? searchParams.operation as TablePropertyFiltering.Operation : 'and'}
                tokens={(searchParams ? searchParams.tokens : [])}
                disabled={isLoading}
                onPropertyFilteringChange={(event) => {
                    const compressedSearchParams = fromPropertyFiltersToCompressedSearchParams(event.detail);
                    const updatedSearchCriteriaString = JSON.stringify(compressedSearchParams);
                    updateFilteringCriteria(updatedSearchCriteriaString);
                    }}
                />
            <TableSorting
                sortableColumns={SORTABLE_COLUMNS_SUGGESTIONS}
            />
            <TablePagination pageSize={40} openEnd={isPagesLoading} />
            <TablePreferences
                title='Preferences'
                confirmLabel='Confirm'
                cancelLabel='Cancel'
            >
                <TablePageSizeSelector
                    title='Page size'
                    options={[
                        { value: 40, label: '40 items' },
                        { value: 60, label: '60 items' },
                        { value: 80, label: '80 items' },
                        { value: 100, label: '100 items' }
                    ]}
                />
                <TableWrapLines
                    label='Wrap lines'
                    description='Enable to wrap table cell content, disable to truncate text.'
                />
                <TableContentSelector
                    title='Select visible columns'
                    options={[
                        {
                            label: 'Properties',
                            options: SELECTABLE_COLUMNS_SUGGESTIONS
                        }
                    ]}
                />
            </TablePreferences>
        </Table>
        );
    }
}

const mapStateToProps = ({ homeCardSuggestionsListViewState }: AppState) => {
    return {
        isLoading: homeCardSuggestionsListViewState.isLoading || false,
        suggestions: homeCardSuggestionsListViewState.suggestions || [],
        lastEvaluatedKey: homeCardSuggestionsListViewState.lastEvaluatedKey || undefined,
        error: homeCardSuggestionsListViewState.error || undefined
    };
};

export default connect(mapStateToProps)(HomeCardSuggestionsTable);
