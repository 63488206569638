import '@amzn/awsui-components-react/index.css';
import './index.css';
import { AwsUi } from '@amzn/awsui-components-react/polaris/runtime'; // ES2015 format
import { getCurrRegionStage } from './util/context';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import Root from './components/Root';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';

configureRumAnalytics();

AwsUi.config(
    process.env.NODE_ENV === 'production'
        ? { production: true, logLevel: 3 }
        : { production: false, logLevel: 2 },
);

ReactDOM.render(
    <Root />,
    document.getElementById('root'),
);

function configureRumAnalytics() {
    try {
        const { stage } = getCurrRegionStage();
        let APPLICATION_ID: string = '';
        const APPLICATION_VERSION: string = '1.0.0';
        let APPLICATION_REGION: string = '';
        let config: AwsRumConfig = {
            sessionSampleRate: 1,
            telemetries: ['performance','errors','http'],
            allowCookies: true,
            enableXRay: false,
            pageIdFormat: 'PATH_AND_HASH' as any
        };

        switch (stage) {
            case 'Prod':
                config = {
                    ...config,
                    guestRoleArn: 'arn:aws:iam::457827731266:role/RUM-Monitor-us-east-1-457827731266-6508535660561-Unauth',
                    identityPoolId: 'us-east-1:ae2fd112-8169-450e-91cf-98e14cfa1501',
                    endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
                };

                APPLICATION_ID = 'b185b701-0823-4929-b52b-aed0bf5ad8c7';
                APPLICATION_REGION = 'us-east-1';
                break;
            case 'Gamma':
                config = {
                    ...config,
                    guestRoleArn: 'arn:aws:iam::303174856479:role/RUM-Monitor-us-east-1-303174856479-2843305660561-Unauth',
                    identityPoolId: 'us-east-1:fcf8c041-7520-40b6-9790-633f622f3ccf',
                    endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
                };

                APPLICATION_ID = 'faea804a-2b22-48f5-909a-b91bbbab7734';
                APPLICATION_REGION = 'us-east-1';
                break;
            case 'Beta':
                config = {
                    ...config,
                    guestRoleArn: 'arn:aws:iam::763647653649:role/RUM-Monitor-us-west-2-763647653649-0297674660561-Unauth',
                    identityPoolId: 'us-west-2:12b3eff3-c4c4-44ce-b733-3b4e7554aa16',
                    endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
                };

                APPLICATION_ID = '553d9058-b1aa-4d92-a67c-8d0773d9b1a4';
                APPLICATION_REGION = 'us-west-2';
                break;
            case 'Alpha':
            default:
                config = {
                    ...config,
                    guestRoleArn: 'arn:aws:iam::595834045012:role/RUM-Monitor-us-west-2-595834045012-8234653660561-Unauth',
                    identityPoolId: 'us-west-2:ec5d696d-8146-4a3e-9dc8-2f1619c4ccd5',
                    endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
                };

                APPLICATION_ID = 'f4951593-7c17-429d-bd74-397e89df8846';
                APPLICATION_REGION = 'us-west-2';
                break;
        }

        const awsRumInstance: AwsRum = new AwsRum(
            APPLICATION_ID,
            APPLICATION_VERSION,
            APPLICATION_REGION,
            config
        );
        if (awsRumInstance === undefined) {
            // tslist demands we do something
            throw new Error('Rum instance did not initialize');
        }
    } catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
    }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
