import { Button } from '@amzn/awsui-components-react';
import * as React from 'react';
import { Component } from 'react';

export class NoMatchDisplay extends Component {
    public render() {
        return <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-xs awsui-util-mb-xs'>
                <b>
                    No matches
                </b>
            </div>
            <p className='awsui-util-mb-s'>
                We can’t find a match.
            </p>
            <div className='awsui-util-mb-m'>
                {/* TODO: href */}
                <Button>Clear filter</Button>
            </div>
        </div>;
    }
}
