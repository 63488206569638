import * as React from 'react';
import { useState } from 'react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { FormField, ColumnLayout } from '@amzn/awsui-components-react';
import { RequiredField } from './DescriptionAnnotations';
import { LinkedMediaCenterWiki } from './LinksRenderHelper';
import { IMobileHomeCardContent } from '../../models/mobileHomeCard/MobileHomeCardContent';
import { HeroCardTitleCharLimit, HeroCardSubtitleCharLimit, HeroCardCallToActionButtonCharLimit } from '../../constants/mobileHomeCardRelatedConstant';
import { InputWrapper } from './InputWrapper';
import { CommonValidator } from '../../util/CommonValidator';
import { toSentenceCase } from '../../util/mobileHomeCardHelper';
import { TextAreaWrapper } from './TextAreaWrapper';
import { TextRenderDirection } from '../../models/htmlElementProps';
import { isNullOrUndefined } from 'util';
import { CharLimit } from '../../containers/sections/MobileHomeCardDisplayElementsSectionView';

interface MobileHomeCardHeroElementComponentProps {
    mobileHomeCardContent?: IMobileHomeCardContent;
    textDirection: TextRenderDirection;
    shouldDisableInput: boolean;
    experience: IFlattenedExperience;
    updateMobileHomeCardContent: (mobileHomeCardContent?: IMobileHomeCardContent) => void;
    onCardActionAndCharLimitCheckedUpdate?: (cardActionChecked?: boolean, charLimitChecked?: boolean) => void;
    disableNextButton: (characterLimit: CharLimit, cardActionConfirmation?: boolean, onCardActionAndCharLimitCheckedUpdate?: (cardActionChecked?: boolean, charLimitChecked?: boolean) => void) => void;
}

export const MobileHomeCardHeroElementComponent = (props: MobileHomeCardHeroElementComponentProps) => {
    const { mobileHomeCardContent, textDirection, shouldDisableInput, experience, updateMobileHomeCardContent, onCardActionAndCharLimitCheckedUpdate, disableNextButton } = props;
    const [titleExceedCharLimit, setTitleExceedCharLimitState] = useState(false);
    const [subtitleExceedCharLimit, setSubtitleExceedCharLimitState] = useState(false);
    const [callToActionExceedCharLimit, setCallToActionExceedCharLimitState] = useState(false);

    const cardActionConfirmation = mobileHomeCardContent?.cardActionConfirmation;

    return <div>

        <br></br>
        <img src='/images/MobileHomeCardHero.png' alt='Conversation Icon' />
        <div className='awsui-row'>
            <div className='col-10'>
                <ColumnLayout columns={2}>
                    <div data-awsui-column-layout-root='true' style={{ marginBottom: '10px' }}>
                        <FormField
                            label={<RequiredField fieldName='Title' />}
                            description='22 characters or less recommended for good performance'>
                            <InputWrapper
                                id='experience.cardTitle'
                                placeholder='e.g. Type with Alexa'
                                readonly={shouldDisableInput}
                                textRenderDirection={textDirection}
                                value={mobileHomeCardContent?.cardTitle}
                                characterLimit={HeroCardTitleCharLimit}
                                onInput={(input: string) => {
                                    const exceedCharLimit = (isNullOrUndefined(input) ? false : input.length > HeroCardTitleCharLimit);
                                    updateMobileHomeCardContent({ ...mobileHomeCardContent, cardTitle: input });
                                    disableNextButton({ titleExceedCharLimit: exceedCharLimit, subtitleExceedCharLimit, callToActionExceedCharLimit },
                                        cardActionConfirmation, onCardActionAndCharLimitCheckedUpdate);
                                    setTitleExceedCharLimitState(exceedCharLimit);
                                }}
                                validate={(value) => { return CommonValidator.isStringLengthExceeded(HeroCardTitleCharLimit, value); }}
                            />
                        </FormField>

                        <FormField
                            label={<RequiredField fieldName='Call-to-action-button' />}
                            description='Start with a verb and indicate the action that tapping the button will perform'>
                            <InputWrapper
                                id={'experience.call-to-action-button'}
                                placeholder='Learn More'
                                readonly={shouldDisableInput}
                                textRenderDirection={textDirection}
                                value={mobileHomeCardContent?.callToActionButton}
                                characterLimit={HeroCardCallToActionButtonCharLimit}
                                onInput={(input: string) => {
                                    const exceedCharLimit = (isNullOrUndefined(input) ? false : input.length > HeroCardCallToActionButtonCharLimit);
                                    updateMobileHomeCardContent({ ...mobileHomeCardContent, callToActionButton: toSentenceCase(input) });
                                    disableNextButton({ titleExceedCharLimit, subtitleExceedCharLimit, callToActionExceedCharLimit: exceedCharLimit },
                                        cardActionConfirmation, onCardActionAndCharLimitCheckedUpdate);
                                    setCallToActionExceedCharLimitState(exceedCharLimit);
                                }}
                                validate={(value) => { return CommonValidator.isStringLengthExceeded(HeroCardCallToActionButtonCharLimit, value); }}
                            />
                        </FormField>

                        <FormField
                            label={<RequiredField fieldName='Subtitle' />}
                            description={'32 characters or less, max 3 lines, 2 lines recommended for good performance'} >
                            <TextAreaWrapper
                                id='experience.heroSubtitle'
                                experienceType={experience.shortenedType}
                                readonly={shouldDisableInput}
                                textRenderDirection={textDirection}
                                placeholder='Now you can speak ...'
                                value={mobileHomeCardContent?.cardSubtitle}
                                characterLimit={HeroCardSubtitleCharLimit}
                                onInput={(input: string) => {
                                    const exceedCharLimit = (isNullOrUndefined(input) ? false : input.length > HeroCardSubtitleCharLimit);
                                    updateMobileHomeCardContent({ ...mobileHomeCardContent, cardSubtitle: input });
                                    disableNextButton({ titleExceedCharLimit, subtitleExceedCharLimit: exceedCharLimit, callToActionExceedCharLimit },
                                        cardActionConfirmation, onCardActionAndCharLimitCheckedUpdate);
                                    setSubtitleExceedCharLimitState(exceedCharLimit);
                                }}
                                validate={(value) => { return CommonValidator.isStringLengthExceeded(HeroCardSubtitleCharLimit, value); }}
                            />
                        </FormField>

                        <FormField
                            label={<RequiredField fieldName='Image URL' />}
                            description={<span>Upload your image to <LinkedMediaCenterWiki />&nbsp;and share the link.</span>}>
                            <InputWrapper
                                id='experience.imageUrl'
                                placeholder='https://m.media-amazon.com/images/G/01/AlexaSkills/2022/LandingPages/SoundDetection/App_invitation_routine_1025x768.png'
                                readonly={shouldDisableInput}
                                value={mobileHomeCardContent?.cardImageUrl}
                                onChange={(input: string) => {
                                    updateMobileHomeCardContent({ ...mobileHomeCardContent, cardImageUrl: input.trim() });
                                }}
                                validate={(value) => { return CommonValidator.isImageUrlValid(value); }}
                            />
                        </FormField>
                    </div>
                </ColumnLayout>
            </div>
        </div>
    </div>;
};

