import { Alert, Spinner } from '@amzn/awsui-components-react';
import * as React from 'react';
import { connect } from 'react-redux';
import { IOdysseyAnnouncement } from '../../models/odysseyAnnouncement/Announcement';
import { AppState } from '../../reducers';
import { FormSectionView } from '../../components/common/FormSectionView';
import { MarkdownRenderer } from '../../components/MarkdownRenderer';
import { simpleDateFormat } from '../../util/stringAndMappingHelper';
import { Link } from 'react-router-dom';
import { PAGE } from '../../constants/page';


interface AnnouncementViewProps {
    isTitleOnly: boolean;
    dispatch: any;
    isLoading: boolean;
    isAnnouncementUpdated: boolean;
    errorUpdating?: Error;
    errorLoading?: Error;
    announcement?: IOdysseyAnnouncement;
}

const mapStateToProps = ({ odysseyAnnouncementState }: AppState) => {
    return {
        isLoading: odysseyAnnouncementState.isLoading,
        isAnnouncementUpdated: odysseyAnnouncementState.updatedAnnouncement,
        errorUpdating: odysseyAnnouncementState.errorUpdating || undefined,
        errorLoading: odysseyAnnouncementState.errorLoading || undefined,
        announcement: odysseyAnnouncementState.announcement || undefined,
    };
};

export const AnnouncementTextOnlyView = connect(mapStateToProps)((props: AnnouncementViewProps) => {
    const { isLoading, errorLoading, announcement, isTitleOnly } = props;
    if (isLoading) {
        return <Spinner size='large' variant='disabled' />;
    }

    if (errorLoading) {
        return <div id='announcement.content-render-area.error'>
            <p className='awsui-util-status-negative'>{errorLoading.message}</p>
        </div>;
    }

    if (!announcement) {
        return <div id='announcement.content-render-area.no-content'>
            <p className='awsui-util-status-inactive awsui-util-status-negative' >No new announcement from the Odyssey team at the moment...</p>
        </div>;
    }

    return <div id={`announcement.content-render-area-${isTitleOnly ? 'summary' : 'full'}`} >
        <h1>{announcement.title}</h1>
        <p className='awsui-util-status-inactive'>{simpleDateFormat(announcement.timestamp)}</p>
        {!isTitleOnly && <MarkdownRenderer markdownString={announcement.detail} />}
    </div>;
});

export const AnnouncementSectionView = () => {
    return <FormSectionView title='Feature announcements and updates'>
        <AnnouncementTextOnlyView isTitleOnly={false} />
    </FormSectionView>;
};

export const AnnouncementAlertView = () => {
    return <Alert header='Feature announcements and updates' >
        <AnnouncementTextOnlyView isTitleOnly={true} />
        <Link to={PAGE.Home}>Read more details</Link>
    </Alert>;
};
