import * as React from 'react';
import { Component } from 'react';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { Button, Alert, Flashbar } from '@amzn/awsui-components-react';
import { getLinkableUrl, PAGE } from '../../constants/page';
import ExperienceDetailGlobalView from '../../components/detail/ExperienceDetailGlobalView';
import OdysseyExperienceExposureControlSectionView from '../../components/detail/OdysseyExperienceExposureControlSectionView';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { getRole, getTypeIconPath } from '../../util/stringAndMappingHelper';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { enableEditingAction, cancelEditingAction, saveOdysseyExperienceAction, launchOdysseyExperienceAction } from '../../actions/experienceEditViewActions';
import CancelExperienceModal from '../../components/modals/CancelExperienceModal';
import DeleteExperienceModal from '../../components/modals/DeleteExperienceModal';
import MultiCloneModal from '../../components/modals/MultiCloneModal';
import { RcpValidationModal } from '../../components/modals/RcpValidationModal';
import MhcValidationModal from '../../components/modals/MhcValidationModal';
import { MobileHomeCardExperienceTabsView } from '../../components/detail/MobileHomeCardExperienceTabsView';
import RotatingContentPanelTabsView from '../../components/detail/RotatingContentPanelTabsView';
import { LinkedApproval, LinkedSimTicket } from '../../components/common/LinksRenderHelper';
import { ExternalLink } from '../../components/common/LinkComponents';
import { APPROVAL_SIM_TEMPLATE_IDS } from '../../constants/approvals';
import CapabilitySearchModal from '../../components/modals/CapabilitySearchModal';
import { getBullseyeAction } from '../../actions/experienceDetailViewActions';
import { MARKETPLACES, MARKETPLACE_TO_REGION } from '../../constants/marketplaces';

export interface IOdysseyExperienceDetailProps {
    dispatch: any;

    experience?: IFlattenedExperience;
    permissions: string[];
    rcpPermissions: string[];
    mhcPermissions: string[];

    // from edit
    isEditing: boolean;
    isUpdating: boolean;
    updateError?: Error;
    id?: string;
    updateCandidate?: ExperienceUpdateCandidate;
    shouldDisableSave: boolean;
}

export interface IOdysseyExperienceDetailState {
    cancelExperienceModalVisible: boolean;

    deleteExperienceModalVisible: boolean;
    deleteExperienceConfirmationString?: string;
    activeTabId?: string;
    multiCloneModalVisible: boolean;
    rcpValidationModalVisible: boolean;
    mhcValidationModalVisible: boolean;
}

class OdysseyExperienceDetail extends Component<IOdysseyExperienceDetailProps, IOdysseyExperienceDetailState> {
    constructor(props: IOdysseyExperienceDetailProps) {
        super(props);

        this.state = {
            cancelExperienceModalVisible: false,
            deleteExperienceModalVisible: false,
            multiCloneModalVisible: false,
            rcpValidationModalVisible: false,
            mhcValidationModalVisible: false
        };
    }

    private editButtonClickHandler() {
        const { dispatch, experience } = this.props;

        if (experience) {
            dispatch(enableEditingAction(experience));
        }
    }

    private cloneButtonClickHandler() {
        const { experience } = this.props;

        if (experience) {
            window.location.assign(getLinkableUrl(PAGE.NewExperience) + '/' + experience.id);
        }
    }

    private cancelButtonClickHandler() {
        const { dispatch } = this.props;
        dispatch(cancelEditingAction());
    }

    private saveButtonClickHandler() {
        const { dispatch } = this.props;
        dispatch(saveOdysseyExperienceAction());

    }

    private toggleCancelModalVisibility() {
        const { cancelExperienceModalVisible } = this.state;
        this.setState({ cancelExperienceModalVisible: !cancelExperienceModalVisible });
    }

    private toggleDeleteModalVisibility() {
        const { deleteExperienceModalVisible } = this.state;
        this.setState({ deleteExperienceModalVisible: !deleteExperienceModalVisible });
    }

    private toggleMultiCloneModalVisibility() {
        const { multiCloneModalVisible } = this.state;
        this.setState({ multiCloneModalVisible: !multiCloneModalVisible });
    }

    private toggleRcpValidationModalVisibility() {
        const { rcpValidationModalVisible } = this.state;
        this.setState({ rcpValidationModalVisible: !rcpValidationModalVisible });
    }

    private toggleMhcValidationModalVisibility() {
        const { mhcValidationModalVisible } = this.state;
        this.setState({ mhcValidationModalVisible: !mhcValidationModalVisible });
    }

    private launchedMobileHomeCard() {
        const { dispatch } = this.props;
        dispatch(launchOdysseyExperienceAction());
    }

    private launchedRotatingContentPanel() {
        const { dispatch } = this.props;
        dispatch(launchOdysseyExperienceAction());
    }

    public componentDidMount() {
        const { experience, dispatch } = this.props;

        if (experience && experience.type === 'RotatingContentPanel' && experience.constraints
            && experience.constraints.CustomerSegmentConstraint.bullseyeSegmentId
            && experience.contents?.[0].marketPlace) {
                const bullseyeId = experience.constraints.CustomerSegmentConstraint.bullseyeSegmentId;
                dispatch(getBullseyeAction(MARKETPLACE_TO_REGION[MARKETPLACES[experience.contents?.[0].marketPlace] || 'US'], Number(bullseyeId)));
        }
    }

    public render() {
        const { experience, isEditing, shouldDisableSave, updateError, id, updateCandidate, permissions } = this.props;

        const { cancelExperienceModalVisible, deleteExperienceModalVisible, multiCloneModalVisible, rcpValidationModalVisible, mhcValidationModalVisible } = this.state;

        if (!experience) {
            return null;
        }

        const isCanceled = experience.status === 'CANCELED';
        const isViewer = getRole(experience.permissionRole) === 'VIEWER';
        const isLaunched = experience.status === 'LAUNCHED' && (getRole(experience.permissionRole) === 'OWNER' || getRole(experience.permissionRole) === 'OPERATOR');

        const typeIconPath = getTypeIconPath(experience.type);
        const isAdmin: boolean = (permissions.includes('ADMIN'));

        return <div>
            {updateError && <Flashbar items={[{
                header: 'Save failed',
                // TODO: get rid of the ternary operator once Lambda passes back the real error message
                content: updateError.message !== 'Network Error' ? updateError.message : 'Failed to update',
                type: 'error',
                dismissible: true
            }]} />}
            {id && <Flashbar items={[{
                header: 'Success',
                // TODO: get rid of the ternary operator once Lambda passes back the real error message
                content: `Experience ${id} has been updated`,
                type: 'success',
                dismissible: true
            }]} />}
            {isEditing && (experience.status === 'DRAFT' || experience.status === 'TESTABLE') && <Flashbar items={[{
                content: <div>
                    <p>You will be required to set a Locale for this experience before making changes to it.</p>
                    <p>Once you have configured all required information and save your draft,
                        your experience will be moved into TESTABLE state.
                        At this point, you can enter a Testing Account (under the Testing tab)
                        and preview your experience on a live device.</p>
                </div>,
                type: 'info'
            }]} />}


            <CapabilitySearchModal />
            <CancelExperienceModal isVisible={cancelExperienceModalVisible} hideModal={() => this.toggleCancelModalVisibility()} />
            <DeleteExperienceModal isVisible={deleteExperienceModalVisible} hideModal={() => this.toggleDeleteModalVisibility()} />
            <MultiCloneModal isVisible={multiCloneModalVisible} hideModal={() => this.toggleMultiCloneModalVisibility()} />
            <RcpValidationModal isVisible={rcpValidationModalVisible} hideModal={() => this.toggleRcpValidationModalVisibility()} />
            <MhcValidationModal isVisible={mhcValidationModalVisible} hideModal={() => this.toggleMhcValidationModalVisibility()} />

            <div className='awsui-grid'>
                <div className='awsui-util-mb-m' >
                    {(experience.status === 'UNDER_REVIEW' && experience.approvalId) &&
                        (experience.approvalInfo?.simDocumentId
                            ? <Alert id='alert.pending-approval' dismissible={false} type='info' header={'Pending Approval'}>
                                Your experience is waiting for approval - <LinkedApproval approvalId={experience.approvalId} />,
                                SIM ticket is <LinkedSimTicket simDocumentId={experience.approvalInfo?.simDocumentId} />
                            </Alert>
                            : <Alert id='alert.pending-approval' dismissible={false} type='info' header={'Pending Approval'}>
                                Your experience is waiting for approval: Approval ID is <LinkedApproval approvalId={experience.approvalId} />&nbsp;
                                Make sure to cut a <ExternalLink
                                    href={`https://sim.amazon.com/issues/create?template=${APPROVAL_SIM_TEMPLATE_IDS[experience.type]}`}>sim intake</ExternalLink> to get your experience approved
                            </Alert>
                        )
                    }
                    {(experience.status === 'CANCELED' && experience.cancellationReason) &&
                        <Alert type='info' header='Cancellation Reason' dismissible={false} >
                            {experience.cancellationReason}
                        </Alert>
                    }

                </div>
                <h1 id='experience-title'>
                    <img src={typeIconPath} alt='' style={{ height: '35px', width: '35px', verticalAlign: 'bottom' }} className='awsui-util-mr-xs' />{experience.title}
                </h1>
                <ExperienceDetailGlobalView
                    experience={experience}
                    isEditing={isEditing}
                    updateCandidate={updateCandidate} />
                <OdysseyExperienceExposureControlSectionView />
                <div className='awsui-row'>

                    <div className='col-12'>
                        <span className='awsui-util-f-r'>
                            {/* valid feature but wait until edit view is ready */}
                            {isEditing
                                ? <>
                                    <Button
                                        id='button.cancel-editing'
                                        icon='undo'
                                        onClick={this.cancelButtonClickHandler.bind(this)}
                                    >Cancel</Button>
                                    <Button
                                        id='button.save-experience'
                                        icon='upload'
                                        variant='primary'
                                        onClick={this.saveButtonClickHandler.bind(this)}
                                        disabled={shouldDisableSave}
                                    >Save</Button>
                                </>
                                : <>
                                    <Button
                                        id='button.clone-experience'
                                        icon='copy'
                                        onClick={this.toggleMultiCloneModalVisibility.bind(this)}>
                                        Clone
                                    </Button>
                                    {<Button
                                        icon='edit'
                                        id='button.edit-experience'
                                        disabled={isCanceled || isViewer || isLaunched}
                                        onClick={this.editButtonClickHandler.bind(this)}>Edit</Button>}

                                    {((getRole(experience.permissionRole) === 'ADMIN' || getRole(experience.permissionRole) === 'MHC_ADMIN')
                                        && experience.status === 'CANCELED')
                                        && <Button
                                            icon='lock-private'
                                            id='button.delete-experience'
                                            variant='primary'
                                            onClick={this.toggleDeleteModalVisibility.bind(this)}
                                        >Delete Experience</Button>}
                                </>}
                        </span>
                    </div>
                </div>
                {generateTabsView(experience, isAdmin, this, permissions)}

                    <div className='awsui-util-f-l'>
                        {!isEditing && (experience.status !== 'CANCELED' && experience.status !== 'UNDER_REVIEW') &&
                            <Button
                                id='button.cancel-experience'
                                onClick={this.toggleCancelModalVisibility.bind(this)}
                                disabled={isViewer}
                            >Cancel Experience</Button>}
                    </div>

                    <div className='awsui-util-f-r'>
                        {(!isEditing) && experience.status === 'TESTABLE' && experience.type === 'MobileHomeCard' &&
                            <span className='awsui-util-ph-s'>
                                <Button
                                    id='button.send-mhc-experience-for-approval'
                                    variant='primary'
                                    onClick={this.toggleMhcValidationModalVisibility.bind(this)}
                                >Send for Approval</Button>
                            </span>
                        }
                        {(!isEditing) && experience.status === 'TESTABLE' && experience.type === 'RotatingContentPanel' &&
                            <span className='awsui-util-ph-s'>
                                <Button
                                    id='button.send-rcp-experience-for-approval'
                                    variant='primary'
                                    onClick={this.toggleRcpValidationModalVisibility.bind(this)}
                                >Send for Approval</Button>
                            </span>
                        }
                        {/* keep launch button under testable status to bypass the approval process for admin to quick launch & testing purpose */}
                        {(experience.permissionRole?.includes('ADMIN') || getRole(experience.permissionRole) === 'MHC_ADMIN') &&
                            experience.type === 'MobileHomeCard' && !isEditing && experience.status === 'TESTABLE' &&
                            <span className='awsui-util-f-l'>
                                <Button
                                    id='button.launch-experience'
                                    variant='primary'
                                    onClick={this.launchedMobileHomeCard.bind(this)}
                                    disabled={experience.status !== 'TESTABLE'}
                                >Launch</Button>
                            </span>}
                        {experience.permissionRole?.includes('ADMIN') && !isEditing && experience.status === 'UNDER_REVIEW' &&
                            <span className='awsui-util-f-l'>
                                <Button
                                    id='button.launch-experience'
                                    variant='primary'
                                    onClick={this.launchedRotatingContentPanel.bind(this)}
                                >Launch</Button>
                            </span>
                        }
                    </div>

            </div>
        </div>;
    }
}

const generateTabsView = (experience: IFlattenedExperience, isAdmin: boolean, component: Component<IOdysseyExperienceDetailProps, IOdysseyExperienceDetailState>, permissions: string[]) => {

    const MhcTabs = <MobileHomeCardExperienceTabsView
        experience={experience}
        isAdmin={isAdmin}
        activeTabId={component.state.activeTabId || 'mobile-card-content'}
        onTabChange={(d) => {
            component.setState(prevState => {
                return { ...prevState, activeTabId: d.activeTabId };
            });
        }} />;

    const RcpTabs = <RotatingContentPanelTabsView
        experience={experience}
        activeTabId={component.state.activeTabId || 'rotating-content-panel-content'}
        onTabChange={(d) => {
            component.setState(prevState => {
                return { ...prevState, activeTabId: d.activeTabId };
            });
        }} />;

    switch (experience.type) {
        case 'MobileHomeCard':
            return MhcTabs;
        case 'RotatingContentPanel':
            return RcpTabs;
    }
};

const mapStateToProps = ({ odysseyExperienceDetailViewState, authenticationState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isLoading has default value set in reducer, so no default
        // value needed
        experience: odysseyExperienceDetailViewState.experience || undefined,
        isEditing: experienceEditViewState.isEditing,
        updateError: experienceEditViewState.error || undefined,
        id: experienceEditViewState.id || undefined,
        permissions: authenticationState.permissions || [],
        rcpPermissions: authenticationState.rcpPermissions || [],
        mhcPermissions: authenticationState.mhcPermissions || [],
        isUpdating: experienceEditViewState.isUpdating,
        updateCandidate: experienceEditViewState.updateCandidate,
        shouldDisableSave: experienceEditViewState.shouldDisableSave
    };
};

export default connect(mapStateToProps)(OdysseyExperienceDetail);
