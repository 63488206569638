import * as React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../reducers/index';
import { ColumnLayout, FormField, FormSection, Input, Select } from '@amzn/awsui-components-react';
import {
    isCardConfigurationIncomplete,
    pushRotatingContentPanelBusinessInfo,
    pushRotatingContentPanelContext,
    pushRotatingContentPanelMetaData
} from '../../actions/experienceEditViewActions';
import { OptionalField, RequiredField } from '../../components/common/DescriptionAnnotations';
import {
    BUSINESS_DOMAIN_DROPDOWN_OPTIONS,
    BUSINESS_VERTICAL_DROPDOWN_OPTIONS
} from '../../constants/businessVerticalDomains';
import { SUBSCRIPTION_UPSELL_TYPE_OPTIONS } from '../../constants/subscriptionUpsellTypeOptions';
import { extractRotatingContentPanelContext } from '../../util/rotatingContentPanelHelper';
import { SubscriptionUpsellType } from '../../models/SubscriptionUpsellType';
import { IRotatingContentPanelContext } from '../../models/rotatingContentPanel/RotatingContentPanelStructure';
import { CommonValidator } from '../../util/CommonValidator';
import { SelectWrapper } from '../../components/common/SelectWrapper';

const MetadataSectionView: React.FC = () => {
    const dispatch = useDispatch();

    const { isEditing, isUpdating, updateCandidate } = useSelector((state: AppState) => state.experienceEditViewState);
    const { experience } = useSelector((state: AppState) => state.odysseyExperienceDetailViewState);

    const shouldDisableInput = !isEditing || isUpdating;
    const values = (shouldDisableInput) ? {
        ..._.get(experience, 'contexts.BusinessInfo', {}),
        ..._.get(experience, 'contents[0].rcpMetaData', {})
    } : {
        ...updateCandidate?.getRotatingContentPanelBusinessInfo(),
        ...updateCandidate?.getRotatingContentPanelContent()?.rcpMetaData
    };

    const rcpContext = shouldDisableInput ? extractRotatingContentPanelContext(experience) : updateCandidate?.getRotatingContentPanelContext();

    const updateRotatingContentPanelContext = (rotatingContentPanelContext : IRotatingContentPanelContext) => {
        dispatch(pushRotatingContentPanelContext(rotatingContentPanelContext));
    };

    const onInputChangeHandler = (event: CustomEvent<Input.ChangeDetail>) => {
        const id = (event.target as HTMLElement).getAttribute('id')?.split('.').pop();
        if (id) {
            const newState = {...values, [id]: event.detail.value };
            dispatch(pushRotatingContentPanelMetaData({ 'intent': newState.intent, 'topic': newState.topic }));
            dispatch(isCardConfigurationIncomplete(_.isEmpty(newState.intent)));
        }
    };

    const onSelectChangeHandler = (event: CustomEvent<Select.ChangeDetail>) => {
        const id = (event.target as HTMLElement).getAttribute('id')?.split('.').pop();
        if (id) {
            const newState = {...values, [id]: event.detail.selectedId };
            if (id === 'vertical') {
                newState.domain = '';
            }
            dispatch(pushRotatingContentPanelBusinessInfo({ vertical: newState.vertical, domain: newState.domain}));
            updateRotatingContentPanelContext({
                ...rcpContext,
                businessInfo: [{ vertical: newState.vertical, domain: newState.domain}]
            });
        }
    };

    const getBusinessDomainOptions = () => {
        if (values && values.vertical && BUSINESS_DOMAIN_DROPDOWN_OPTIONS[values.vertical]) {
            return BUSINESS_DOMAIN_DROPDOWN_OPTIONS[values.vertical];
         } else {
            return [];
         }
    };

    return <FormSection header='Meta Data'>
        <ColumnLayout columns={4} >
            <div data-awsui-column-layout-root='true'>
                <FormField label='Business Vertical'>
                    <Select
                        id='RotatingContentPanel.metadata.vertical'
                        placeholder='Select vertical'
                        options={BUSINESS_VERTICAL_DROPDOWN_OPTIONS}
                        disabled={shouldDisableInput}
                        selectedOption={{
                            id: values ? (values.vertical ? values.vertical : '') : '',
                            label: values ? (values.vertical ? values.vertical : '') : ''
                        }}
                        onChange={onSelectChangeHandler} />
                </FormField>
                <FormField label='Business Domain' >
                    <Select
                        id='RotatingContentPanel.metadata.domain'
                        placeholder='Select domain'
                        options={getBusinessDomainOptions()}
                        disabled={shouldDisableInput}
                        selectedOption={{
                            id: values ? (values.domain ? values.domain : '') : '',
                            label: values ? (values.domain ? values.domain : '') : ''
                        }}
                        onChange={onSelectChangeHandler} />
                </FormField>
                <FormField label={<RequiredField fieldName='Subscription Upsell Type' />}>
                    <SelectWrapper
                        id='RotatingContentPanel.context.subscriptionUpsellTypeRCP'
                        options={SUBSCRIPTION_UPSELL_TYPE_OPTIONS}
                        selectedId={rcpContext?.subscriptionUpsellType}
                        disabled={shouldDisableInput}
                        validate={(subscriptionUpsellType) => {
                            CommonValidator.isSubscriptionUpsellFieldValid(subscriptionUpsellType);
                        }}
                        onChange={e => {
                            updateRotatingContentPanelContext({ ...rcpContext, subscriptionUpsellType: e.detail.selectedId as SubscriptionUpsellType});
                        }}
                    />
                </FormField>
                <FormField label={<RequiredField fieldName='NLU Intent' />} >
                    <Input id='RotatingContentPanel.metadata.intent' value={values.intent}
                        onChange={onInputChangeHandler} disabled={shouldDisableInput}
                    />
                </FormField>
                <FormField label={<OptionalField fieldName='Topic' />} description='Corresponds to value returned by AlexaTopicService'>
                    <Input id='RotatingContentPanel.metadata.topic' value={values.topic}
                        onChange={onInputChangeHandler} disabled={shouldDisableInput}
                    />
                </FormField>
            </div>
        </ColumnLayout>
    </FormSection>;
};

export default MetadataSectionView;
