import { ILoadCXDailyMetricsAction } from '../actions/experienceMetricsViewActions';
import {
    LOADING_CX_DAILY_METRICS,
    LOADED_CX_DAILY_METRICS,
    ERROR_LOADING_CX_DAILY_METRICS
} from '../constants/experienceMetricsViewActionTypes';
import { IRawCifMetricsData } from '../models/CifMetrics';

export interface IExperienceCXDailyMetricsViewState {
    isLoading: boolean;
    cxDailyMetrics?: IRawCifMetricsData[];
    error?: Error;
}

export const experienceCXDailyMetricsViewReducer = (
    state: IExperienceCXDailyMetricsViewState = { isLoading: false },
    action: ILoadCXDailyMetricsAction
): IExperienceCXDailyMetricsViewState => {
    switch (action.type) {
        case LOADING_CX_DAILY_METRICS:
            return {
                ...state,
                isLoading: true
            };
        case LOADED_CX_DAILY_METRICS:
            const { cxDailyMetrics } = action;
            return {
                ...state,
                isLoading: false,
                cxDailyMetrics
            };
        case ERROR_LOADING_CX_DAILY_METRICS:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};
