export enum ITargetingType {
    NluInclusionTargeting = 'NluInclusionTargeting',
    NluExclusionTargeting = 'NluExclusionTargeting',
    ContextualSegmentsTargeting = 'ContextualSegmentsTargeting',
    RemoteContextualSegmentsTargeting = 'RemoteContextualSegmentsTargeting',
    SpeakerRoleTargeting = 'SpeakerRoleTargeting',
    NotificationTargeting = 'NotificationTargeting',
    AutoCreatedSnsTargeting = 'AutoCreatedSnsTargeting',
    BullseyeTargeting = 'BullseyeTargeting',
    SimpleSnsTargeting = 'SimpleSnsTargeting',
    MobileHomeCardTargeting = 'MobileHomeCardTargeting'
}
