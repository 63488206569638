export enum ExperienceFilterType {
    ActivationFilter = 'ActivationFilter',
    BullseyeFilter = 'BullseyeFilter',
    ContextualSegmentsFilter = 'ContextualSegmentsFilter',
    DayTimeRangeGuardrailsFilter = 'DayTimeRangeGuardrailsFilter',
    DomainInformationFilter = 'DomainInformationFilter',
    FeatureHintsFilter = 'FeatureHintsFilter',
    CapabilityFilter = 'CapabilityFilter',
    StatusFilter = 'StatusFilter',
    TemplateVariableFilter = 'TemplateVariableFilter',
    TimerFilter = 'TimerFilter',
    WeblabFilter = 'WeblabFilter',
    ALL_FILTERS = 'ALL_FILTERS'
}

export const EXPERIENCE_FILTERS: { [key in ExperienceFilterType]: string } =  {
    'ActivationFilter': 'Bypass activation dates, marketplace enablement, and device enablement. Experience may be served to override account on any day, marketplace, and device type.',
    'BullseyeFilter': 'Bypass bullseye check, override account need not belong to bullseye segment',
    'ContextualSegmentsFilter': 'Bypass per marketplace include & exclude tags',
    'DayTimeRangeGuardrailsFilter': 'Bypass daytime range guardrails, experience may be served to override account at any time',
    'DomainInformationFilter': 'Bypass making remote calls for remote template variables and remote tags',
    'FeatureHintsFilter': 'Bypass feature hints, experience will be served even if override account has tried these features',
    'CapabilityFilter': 'Bypass capability engagements, experience will be served even if override account has tried these capabilities',
    'StatusFilter': 'Bypass experience status check, draft experiences may be served to override account',
    'TemplateVariableFilter': 'Bypass proper template variable resolution; variables will be replaced with default value',
    'TimerFilter': 'Bypass filter for whether timer set is less than one minute',
    'WeblabFilter': 'Bypass weblab check; override account need not belong to treatment group for weblab.',
    'ALL_FILTERS': 'Overrides all filters'
};

export interface IGuardrails {
    overrideAccounts: string[];
    overrideSelectiveFilters?: ExperienceFilterType[];
    verboseLoggingEnabled?: boolean;
}
