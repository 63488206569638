import * as React from 'react';
import _ from 'lodash';
import { Dispatch } from 'redux';
import { FormSectionView } from '../../components/common/FormSectionView';
import { Spinner, Button, Alert } from '@amzn/awsui-components-react';
import { IntentToGroupMap } from '../../components/common/NluOpportunityTriggeringSingleIntentView';
import NluOpportunityTriggeringSingleIntentView from '../../components/common/NluOpportunityTriggeringSingleIntentView';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { AppState } from '../../reducers/index';
import { ExternalLink } from '../../components/common/LinkComponents';
import { connect } from 'react-redux';
import { pushOpportunityTriggerInclusionSectionAction, pushOpportunityTriggerExclusionSectionAction,
    pushNluOpportunityTargetingInclusionSectionAction, pushNluOpportunityTargetingExclusionSectionAction,
    pushUseNonCTATemplate, pushUseTouchActionTemplate, pushIncludeOdysseyFields } from '../../actions/experienceEditViewActions';
import { IOpportunityTrigger, buildIntentTrigger, IIntentTrigger, IQAOrPhaticIntentTrigger, OpportunityTriggerGroup, processNluOpportunityTrigger, processOpportunityTrigger, IFrictionTrigger } from '../../models/NluOpportunityTrigger';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { UtteranceIntentOptions } from './../../components/common/NluOpportunityTriggeringSingleIntentView';
import { DISABLING_SAVE_BUTTON } from '../../constants/experienceEditViewActionTypes';
import { CIFValidator } from '../../util/CIFValidator';
import { INluOpportunityTargeting } from '../../models/NluOpportunityTargeting';

interface INluOpportunityTriggeringSectionViewProps {
    dispatch: any;
    triggerGrouping: OpportunityTriggerGroup;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

export const isInvalidTriggerConfiguration = (shouldDisableSave: boolean) => (dispatch: Dispatch) => {
    dispatch({
        type: DISABLING_SAVE_BUTTON,
        shouldDisableSave
    });
};

export const isInvalidFrictionTrigger = (opportunityTriggers: IOpportunityTrigger[]): boolean => {
    let missingFrictionPromptDetails = false;
    if (opportunityTriggers && opportunityTriggers.length === 1 && opportunityTriggers[0].opportunityTrigger === 'FrictionTrigger') {
        const frictionTrigger = opportunityTriggers[0] as IFrictionTrigger;
        if (_.isEmpty(frictionTrigger.bluIntent) || _.isEmpty(frictionTrigger.frictionPromptDetails)
            || _.isEmpty(frictionTrigger.frictionPromptDetails[0].promptId) || _.isEmpty(frictionTrigger.frictionPromptDetails[0].promptNamespace)) {
                missingFrictionPromptDetails = true;
        }
    }
    return missingFrictionPromptDetails;
};

export const isInvalidIntent = (opportunityTriggers: IOpportunityTrigger[]) => {
    let similarIntents = false;
    if (opportunityTriggers && opportunityTriggers.length < 2) {
        return similarIntents;
    }

    const bluIntentGroups = _.groupBy(opportunityTriggers, (opportunity) => (_.get(opportunity, 'bluIntent') ?? '').toLowerCase());
    UtteranceIntentOptions.forEach((intentOption) => {
        const intentGroup = _.get(bluIntentGroups, intentOption.label.toLowerCase(), null);
        if (intentGroup !== null) {
            if (intentGroup.length > 1) {
                const opportunityTriggerGroups = _.keys(_.groupBy(intentGroup, 'opportunityTrigger'));
                similarIntents = _.includes(opportunityTriggerGroups, 'Intent') && _.includes(opportunityTriggerGroups, 'QAIntentOrPhaticIntent');
            }
            return;
        }
    });
    return similarIntents;
};

interface INluOpportunityTriggeringSectionViewState {
    disableCreateTrigger: boolean;
}

export class NluOpportunityTriggeringSectionView extends React.Component<INluOpportunityTriggeringSectionViewProps, INluOpportunityTriggeringSectionViewState> {
    constructor(props: INluOpportunityTriggeringSectionViewProps) {
        super(props);
        this.state = {
            disableCreateTrigger: false
        };
      }

    updateTriggerExclusions(opportunityTriggers: IOpportunityTrigger[]) {
        const { dispatch, updateCandidate} = this.props;
        dispatch(pushOpportunityTriggerExclusionSectionAction(opportunityTriggers));

        if (updateCandidate) {

            dispatch(pushNluOpportunityTargetingExclusionSectionAction(
                updateCandidate.getOpportunityTriggerExclusions()
                    .map(trigger => processNluOpportunityTrigger(trigger))
                )
            );
        }
    }

    updateNonCTAAndTouchActionsTemplate(checked: boolean) {
        const { dispatch } = this.props;
        dispatch(pushUseNonCTATemplate(checked));
        dispatch(pushUseTouchActionTemplate(!checked));
        if (checked) {
            dispatch(pushIncludeOdysseyFields(false));
        }
        this.forceUpdate();
    }

    updateTriggerInclusions(opportunityTriggers: IOpportunityTrigger[], triggerGrouping: string) {
        const { dispatch, updateCandidate} = this.props;
        dispatch(pushOpportunityTriggerInclusionSectionAction(opportunityTriggers));

        if (updateCandidate) {

            dispatch(pushNluOpportunityTargetingInclusionSectionAction(
                updateCandidate.getOpportunityTriggerInclusions()
                    .map(trigger => processNluOpportunityTrigger(trigger))
                )
            );
            if (triggerGrouping === 'VisualCIFTriggers') {
                let isBlocked = false;
                if (opportunityTriggers) {
                    for (const eachTrigger of opportunityTriggers) {
                        if((eachTrigger as IIntentTrigger).bluIntent?.toLowerCase() === 'playmusicintent') {
                            isBlocked = true;
                        }
                    }
                }
                if (isBlocked && this.props.updateCandidate && !this.props.updateCandidate.getUseNonCTATemplate()) {
                    this.updateNonCTAAndTouchActionsTemplate(true);
                } else if (!isBlocked && this.props.updateCandidate && this.props.updateCandidate.getUseNonCTATemplate()) {
                    this.updateNonCTAAndTouchActionsTemplate(false);
                }
            }
        }
    }

    render() {
        const { triggerGrouping, experience, isEditing, updateCandidate, isUpdating, dispatch } = this.props;
        const { disableCreateTrigger } = this.state;

        if (!experience) {
            return <FormSectionView title={
                triggerGrouping === 'InclusionTriggers'
                    ? 'NLU opportunity triggering'
                    : triggerGrouping === 'ExclusionTriggers'
                        ? 'NLU opportunity exclusions'
                        : 'Triggering'
                }>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const InclusionTriggersOrVCIFTriggers = (triggerGrouping === 'InclusionTriggers' || triggerGrouping === 'VisualCIFTriggers');
        const opportunityTriggers = InclusionTriggersOrVCIFTriggers
            ? (ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
                ? updateCandidate.getOpportunityTriggerInclusions() || []
                : processOpportunityTrigger(experience.nluOpportunityTargetings) || []))
            : (ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
                ? updateCandidate.getOpportunityTriggerExclusions() || []
                : processOpportunityTrigger(experience.nluOpportunityTargetingExclusions) || []));

        const shouldDisableInput = !isEditing || isUpdating;

        const isQuickCreateWorkflow = updateCandidate?.getIsQuickCreateWorkflow();

        const limitFrictionTriggers = InclusionTriggersOrVCIFTriggers && opportunityTriggers.length > 0
            && (opportunityTriggers as INluOpportunityTargeting[]).find(x => x.frictionPromptDetails) !== undefined;

        if (this.state.disableCreateTrigger !== limitFrictionTriggers) {
            this.setState({
                disableCreateTrigger: limitFrictionTriggers
            });
        }

        const ifSimilarIntentsConfiguredForTriggers = (): boolean => {
            const similarIntents = isInvalidIntent(opportunityTriggers);
            dispatch(isInvalidTriggerConfiguration(similarIntents));
            return similarIntents;
        };

        const ifMissingFrictionPromptDetails = (): boolean => {
            const missingFrictionPromptDetails = isInvalidFrictionTrigger(opportunityTriggers);
            dispatch(isInvalidTriggerConfiguration(missingFrictionPromptDetails));
            return missingFrictionPromptDetails;
        };

        return <FormSectionView title={
            triggerGrouping === 'InclusionTriggers'
                ? (updateCandidate?.getIsQuickCreateWorkflow()
                    ? 'NLU opportunity triggering'
                    : <RequiredField fieldName='NLU opportunity triggering'/>)
                : triggerGrouping === 'ExclusionTriggers'
                    ? 'NLU opportunity exclusions'
                    : 'Triggering'
            } >

                <Alert id={'alert.invalidFriction'} visible={!shouldDisableInput && ifMissingFrictionPromptDetails()} type='error'>
                    Friction Trigger requires an intent and at least one Prompt Namespace and one Prompt ID
                </Alert>
                <Alert id={'alert.similarIntents'} visible={!shouldDisableInput && InclusionTriggersOrVCIFTriggers && ifSimilarIntentsConfiguredForTriggers()} type='error'>
                Intent Trigger and Utterance Intent cannot target the same Intent. If this is a requirement for your campaign, please create 2 separate campaigns; 1 campaign per each Triggering type.
                </Alert>
                <Alert id={'alert.blockedIntent'} visible={triggerGrouping === 'InclusionTriggers' && CIFValidator.isIntentBlocked(opportunityTriggers, isEditing, experience)} type='error'>
                            SetNotificationIntent, VolumeUpIntent, VolumeDownIntent, and SetVolumeIntent are blocked by the Audio CIF channel.
                             Use cif-support slack channel to determine if your campaign qualifies for an exception,
  			                 or use a different intent for triggering your CIF content
  			    </Alert>

            {(triggerGrouping && triggerGrouping === 'InclusionTriggers') &&
                <div className='awsui-util-mb-l'>
                    <ul>
                        <li>Choose Trigger for customer experience delivery. Click <ExternalLink href='https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+CIF+Experience+Setup#Workflows-1739990606'>
                            here</ExternalLink> for more info.</li>
                        <li>For complete list of Trigger Intents, access the <ExternalLink href='https://w.amazon.com/bin/view/Alexa/NLU/NLUConsole/NluConcole_UserGuide'>
                            NLU Console</ExternalLink> via the <ExternalLink href='https://sensai.a2z.com/app'>Sensai App</ExternalLink>.</li>
                        <li style={{ color: 'red'}}>WhatTimeIntent is blocked at opportunity fulfillment level for ALL regions for Audio CIFs.
                         Click <ExternalLink href='https://wiki.labcollab.net/confluence/display/Doppler/CIF+Intent+Targeting+Reference#CIFIntentTargetingReference-SupportedIntents'>
                            here</ExternalLink> for more info about other blocked Intents</li>
                    </ul>
                </div>
            }

            {(triggerGrouping && triggerGrouping === 'ExclusionTriggers') &&
                <div className='awsui-util-mb-l'>
                        You may specify details about the triggers you configured above, that will not trigger your use case. For example, if you
                        have setup a trigger for the GetWeatherForecastIntent but do not want this to apply when the customer asks for a specific city,
                         you may setup an exclusion for the WeatherLocationCity slot here. We do not support utterance text exclusions.
                </div>
            }

            {(triggerGrouping && triggerGrouping === 'VisualCIFTriggers') &&
                <div className='awsui-util-mb-l'>
                    <ul>
                        <li>You are required to create at least one Trigger that defines when a customer will receive your experience.</li>
                        <li>List of triggers available for Visual CIF can be found in
                            the <ExternalLink href='https://wiki.labcollab.net/confluence/display/Doppler/Visual+Suggestions+%28VCIF%29+Intent+Targeting+Reference'>
                            Visual Suggestions (VCIF) Intent Targeting Reference Wiki</ExternalLink>.</li>
                    </ul>
                </div>
            }

            {opportunityTriggers && opportunityTriggers.length > 0
            ? opportunityTriggers.map((trigger, index) =>
                <TriggerView
                    key={`trigger_view_${index}`}
                    index={index}
                    trigger={trigger}
                    triggerGrouping={triggerGrouping}
                    shouldDisableInput={shouldDisableInput}
                    isVisualCIF={triggerGrouping === 'VisualCIFTriggers'}
                    isQuickCreateWorkflow={isQuickCreateWorkflow}
                    onRemoveTrigger={(removeTrigger, removeIndex) => {
                        opportunityTriggers.splice(removeIndex, 1);
                        (triggerGrouping === 'InclusionTriggers' || triggerGrouping === 'VisualCIFTriggers')
                            ? this.updateTriggerInclusions(opportunityTriggers, triggerGrouping)
                            : this.updateTriggerExclusions(opportunityTriggers);
                    }}
                    onUpdateTrigger={(updateTrigger, updateIndex) => {
                        opportunityTriggers[index] = updateTrigger;
                        (triggerGrouping === 'InclusionTriggers' || triggerGrouping === 'VisualCIFTriggers')
                            ? this.updateTriggerInclusions(opportunityTriggers, triggerGrouping)
                            : this.updateTriggerExclusions(opportunityTriggers);
                    }}
                />)
            : <div /> }

            <div>
                <Button
                    id={
                        (triggerGrouping === 'InclusionTriggers' || triggerGrouping === 'VisualCIFTriggers')
                            ? 'button.add-intent-inclusion'
                            : 'button.add-intent-exclusion'
                    }
                    disabled={shouldDisableInput || disableCreateTrigger}
                    icon='add-plus'
                    onClick={() => {
                        opportunityTriggers.push(buildIntentTrigger());
                        (triggerGrouping === 'InclusionTriggers' || triggerGrouping === 'VisualCIFTriggers')
                            ? this.updateTriggerInclusions(opportunityTriggers, triggerGrouping)
                            : this.updateTriggerExclusions(opportunityTriggers);
                        }}>
                    Create trigger
                </Button>
            </div>

        </FormSectionView>;
    }
}

interface ITokenGroupTargetViewProps {
    index: number;
    trigger: IOpportunityTrigger;
    triggerGrouping: OpportunityTriggerGroup;
    shouldDisableInput?: boolean;
    isVisualCIF?: boolean;
    isQuickCreateWorkflow?: boolean;
    onRemoveTrigger: (trigger: IOpportunityTrigger, index: number) => void;
    onUpdateTrigger: (trigger: IOpportunityTrigger, index: number) => void;
}

const TriggerView = (props: ITokenGroupTargetViewProps) => {
    const { index, trigger, triggerGrouping, shouldDisableInput, isVisualCIF, isQuickCreateWorkflow, onRemoveTrigger, onUpdateTrigger } = props;

    if (trigger.isEditable) {
        return <div className='awsui-util-container awsui-util-shadow awsui-util-pb-l'>
            <Alert id={'alert.vcifBlockedIntent'} visible={triggerGrouping === 'VisualCIFTriggers' && CIFValidator.isVCIFIntentBlocked(trigger)} type='error'>
                        PlayMusicIntent is blocked from using touch actions, adding it will automatically remove touch actions if configured
            </Alert>
            <div>
                    <NluOpportunityTriggeringSingleIntentView
                        trigger={trigger}
                        index={index}
                        key={`trigger-${index}`}
                        arrayKey={'trigger-' + index}
                        type={(triggerGrouping === 'InclusionTriggers' || triggerGrouping === 'VisualCIFTriggers') ? 'inclusion' : 'exclusion'}
                        disableInput={shouldDisableInput}
                        isQuickCreateWorkflow={isQuickCreateWorkflow}
                        isVisualCIF={isVisualCIF}
                        updateOpportunityTriggering={(triggerInclusion) => {
                            onUpdateTrigger(triggerInclusion, index);
                        }}
                    />
                </div>
                <div className='awsui-row awsui-util-no-gutters awsui-util-pb-l'>
                        {index !== 0 &&
                            <Button
                                id={(triggerGrouping === 'InclusionTriggers' || triggerGrouping === 'VisualCIFTriggers') ? `button.remove-trigger-inclusion-${index}` : `button.remove-trigger-exclusion-${index}`}
                                disabled={shouldDisableInput}
                                className='awsui-util-f-l'
                                icon='close'
                                onClick={() => onRemoveTrigger(trigger, index)}
                            >
                                Remove trigger
                            </Button>
                        }
                        <Button
                            id={(triggerGrouping === 'InclusionTriggers' || triggerGrouping === 'VisualCIFTriggers') ? `button.save-trigger-inclusion-${index}` : `button.save-trigger-exclusion-${index}`}
                            disabled={shouldDisableInput}
                            className='awsui-util-f-l'
                            icon='file'
                            onClick={() => {
                                trigger.isEditable = !trigger.isEditable;
                                onUpdateTrigger(trigger, index);
                            }}
                        >
                            Save Trigger
                        </Button>
                </div>
            </div>;
    }

    let summaryText: string;
    switch(trigger.opportunityTrigger) {
        case 'Intent':
            const intentTrigger = trigger as IIntentTrigger;
            summaryText = 'Trigger on Intent: ' + intentTrigger.bluIntent;
            break;
        case 'QAIntentOrPhaticIntent':
            const qaOrPhaticTrigger = trigger as IQAOrPhaticIntentTrigger;
            summaryText = 'Trigger on Utterance Intent: ' + qaOrPhaticTrigger.bluIntent;
            break;
        case 'SkillCategory':
            summaryText = 'Trigger on Skill Categories';
            break;
        case 'SkillID':
            summaryText = 'Trigger on Skill Id\'s';
            break;
        case 'FrictionTrigger':
            summaryText = 'Friction Trigger';
            break;
    }

    return <div className='awsui-util-container awsui-util-shadow'>
        <div className='awsui-util-container-header'>
            {(index > 0) && 'OR ' }
            {summaryText}
            <Button
                id={triggerGrouping === 'InclusionTriggers' ? `button.edit-trigger-inclusion-${index}` : `button.edit-trigger-exclusion-${index}`}
                className='awsui-util-f-r'
                onClick={() => {
                    trigger.isEditable = !trigger.isEditable;
                    onUpdateTrigger(trigger, index);
                }}
            >
                Edit
            </Button>
        </div>
        {IntentToGroupMap((trigger as IIntentTrigger).bluIntent) && triggerGrouping === 'InclusionTriggers' &&
            <li style={{ color: 'red'}}>This intent requires additional approval by {IntentToGroupMap((trigger as IIntentTrigger).bluIntent)}.</li>
        }
    </div>;
};

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(NluOpportunityTriggeringSectionView);
