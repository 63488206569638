import { ClientStage, ConfigFiles, OfflineConfigManager } from '@amzn/amazon-config-store-type-script-offline-adapter';
import {
    MarketplaceConfigClient,
    MarketplaceConfigClientProvider,
    MarketplaceConfigContext
} from '@amzn/marketplace-config-type-script-client/dist/marketplaceConfig';

import {
    CountryCodeToMarketplaceIdMapClient,
    CountryCodeToMarketplaceIdMapClientProvider,
    CountryCodeToMarketplaceIdMapContext
} from '@amzn/country-code-to-marketplace-id-map-type-script-client/dist/countryCodeToMarketplaceIdMap';

import CountryCodeToMarketplaceIdMapDoc from '@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/acs_CountryCodeToMarketplaceIdMap/documents.json';
import CountryCodeToMarketplaceIdMapHQ from '@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/acs_CountryCodeToMarketplaceIdMap/hierarchical_queries.json';
import CountryCodeToMarketplaceIdMapQueries from '@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/acs_CountryCodeToMarketplaceIdMap/queries.json';

import MarketplaceConfigDoc from '@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/acs_MarketplaceConfig/documents.json';
import MarketplaceConfigHQ from '@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/acs_MarketplaceConfig/hierarchical_queries.json';
import MarketplaceConfigQueries from '@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/acs_MarketplaceConfig/queries.json';

import { Region } from '../util/context';
import { flip } from '../util/stringAndMappingHelper';


const configManager: OfflineConfigManager = new OfflineConfigManager();
const marketplaceConfigClient: MarketplaceConfigClient = configManager.createClient(
    new MarketplaceConfigClientProvider(), {
        load(stage: ClientStage, tableName: string): ConfigFiles {
            return {
                exactQueries: MarketplaceConfigQueries.queries,
                hierarchicalFallbackQueries: MarketplaceConfigHQ.queries,
                documents: MarketplaceConfigDoc.documents
            };
        }
    }
);

const countryCodeToMarketplaceIdMapClient: CountryCodeToMarketplaceIdMapClient = configManager.createClient(
    new CountryCodeToMarketplaceIdMapClientProvider(), {
        load(stage: ClientStage, tableName: string): ConfigFiles {
            return {
                exactQueries: CountryCodeToMarketplaceIdMapQueries.queries,
                hierarchicalFallbackQueries: CountryCodeToMarketplaceIdMapHQ.queries,
                documents: CountryCodeToMarketplaceIdMapDoc.documents
            };
        }
    }
);

export const marketplaceIdToCountryCode = (marketplaceId: string): string | undefined => {
    return marketplaceConfigClient.getMarketplace(new MarketplaceConfigContext(marketplaceId))?.defaultCountryCode;
};

export const countryCodeToMarketplaceId = (countryCode: string): string | undefined => {
    return countryCodeToMarketplaceIdMapClient.getObfuscatedMarketplaceId(new CountryCodeToMarketplaceIdMapContext('retail', countryCode)) || undefined;
};

const supportedCountryCodes = [ 'SA', 'US', 'CA', 'UK', 'DE', 'IN', 'JP', 'AU', 'FR', 'ES', 'IT', 'MX', 'BR', 'CN', 'NL', 'RU' ];

export const MARKETPLACES: { [obfuscatedMarketplace: string]: string } = Object.assign({}, ...supportedCountryCodes.map((countryCode) => (
    {[countryCodeToMarketplaceId(countryCode) as string]: countryCode})));

export const MARKETPLACE_TO_REGION: { [marketplace: string]: Region } = {
    'SA': 'NA',
    'DE': 'EU',
    'CA': 'NA',
    'GB': 'EU',
    'UK': 'EU',
    'IN': 'EU',
    'US': 'NA',
    'ES': 'EU',
    'MX': 'NA',
    'FR': 'EU',
    'IT': 'EU',
    'BR': 'NA',
    'JP': 'FE',
    'AU': 'FE',
    'CN': 'CN',
    'NL': 'EU',
    'RU': 'FE',
};

export const MARKETPLACE_TO_REALM: { [marketplace: string]: string } = {
    'SA': 'SAAmazon',
    'DE': 'DEAmazon',
    'CA': 'CAAmazon',
    'GB': 'GBAmazon',
    'UK': 'GBAmazon',
    'IN': 'INAmazon',
    'US': 'USAmazon',
    'ES': 'ESAmazon',
    'MX': 'MXAmazon',
    'FR': 'FRAmazon',
    'IT': 'ITAmazon',
    'BR': 'BRAmazon',
    'JP': 'JPAmazon',
    'AU': 'AUAmazon',
    'CN': 'CNAmazon',
    'NL': 'NLAmazon',
    'RU': 'RUAmazon',
};

export const MARKETPLACES_FLIPPED: { [marketplaceCode: string]: string } = flip(MARKETPLACES);

export const DEFAULT_MARKETPLACE: string = 'US';
