import * as React from 'react';
import { Component } from 'react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { Tabs } from '@amzn/awsui-components-react';
import { MobileHomeCardExperienceDetailsTabView } from './tabs/ExperienceDetailsTabView';
import { TestingTabView } from './tabs/TestingTabView';
import { AdvancedConfigurationTabView } from './tabs/AdvancedConfigurationTabView';
import { MobileHomeCardTabView } from './tabs/MobileHomeCardTabView';
import MobileHomeCardCustomerTargetingSectionView from '../../containers/sections/MobileHomeCardCustomerTargetingSectionView';

export class MobileHomeCardExperienceTabsView extends Component<{
    experience: IFlattenedExperience,
    activeTabId: string | undefined,
    isAdmin: boolean,
    onTabChange?: (detail: Tabs.ChangeDetail) => void
}
> {

    render() {
        const { experience, activeTabId, isAdmin, onTabChange } = this.props;
        return <Tabs
            tabs={getMobileHomeCardExperienceTabs(experience, isAdmin)}
            activeTabId={activeTabId}
            onChange={(e) => onTabChange ? onTabChange(e.detail) : null} />;
    }
}

const getMobileHomeCardExperienceTabs = (experience: IFlattenedExperience, isAdmin: boolean): Tabs.Tab[] => {
    return [{
        label: 'Card Details',
        id: 'mobile-card-content',
        content: <MobileHomeCardTabView />
    }, {
        label: 'Customer Targeting',
        id: 'customer-targeting',
        content: <MobileHomeCardCustomerTargetingSectionView />
    }, {
        label: 'Experience Details',
        id: 'experience-details',
        content: <MobileHomeCardExperienceDetailsTabView isAdmin={isAdmin} experience={experience} />
    }, {
        label: 'Advanced Configuration',
        id: 'advanced-configuration',
        content: <AdvancedConfigurationTabView experience={experience} />
    }, {
        label: 'Testing',
        id: 'testing',
        content: <TestingTabView experience={experience} />
    }
    ];
};
