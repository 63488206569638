import { Action, Dispatch } from 'redux';
import { getPermission } from '../api/permissionApi';

import { SET_SHARED_AUTHENTICATION_DATA, LOADED_USER_PERMISSIONS,
    LOADING_USER_PERMISSIONS, ERROR_LOADING_USER_PERMISSIONS,
    LOADED_MHC_PERMISSIONS,
    LOADED_RCP_PERMISSIONS} from '../constants/authenticationActionTypes';
import { IAuthenticationInfo } from '../models/AuthenticationInfo';
import { AppState } from '../reducers';
import { MOBILE_HOME_CARD_USER_GROUP, RCP_CREATORS_USER_GROUP } from '../constants/userGroups';

export interface IAuthenticationAction extends Action {
    authenticationInfo?: IAuthenticationInfo;
    permissions?: string[];
    mhcPermissions?: string[];
    rcpPermissions?: string[];
}

// Action creator for SET_SHARED_AUTHENTICATION
export const setSharedAuthenticationAction = (authenticationInfo: IAuthenticationInfo): IAuthenticationAction => ({
    type: SET_SHARED_AUTHENTICATION_DATA,
    authenticationInfo
});

export const setSharedAuthenticationInfo = (authenticationInfo: IAuthenticationInfo) => {
    return (setSharedAuthenticationAction(authenticationInfo));
};

export const loadingPermission = (): IAuthenticationAction => ({
    type: LOADING_USER_PERMISSIONS
});

export const errorLoadingPermission = (): IAuthenticationAction => {
    return {
        type: ERROR_LOADING_USER_PERMISSIONS
    };
};

export const loadedPermission = (permissions: string[]): IAuthenticationAction => {
    return {
        type: LOADED_USER_PERMISSIONS,
        permissions
    };
};

export const getPermissionAction = (operatorGroup?: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    const userAlias = getState().navigationViewState.userAlias;
    dispatch(loadingPermission());

    try {
        const data: string[] = await getPermission(userAlias, operatorGroup);
        if (operatorGroup?.toLowerCase() === MOBILE_HOME_CARD_USER_GROUP) {
            dispatch(loadedMhcPermission(data));
        } else if (operatorGroup?.toLowerCase() === RCP_CREATORS_USER_GROUP) {
            dispatch(loadedRCPPermission(data));
        } else {
            dispatch(loadedPermission(data));
        }
    } catch (error) {
        dispatch(errorLoadingPermission());
    }
};

export const loadedMhcPermission = (mhcPermissions: string[]): IAuthenticationAction => {
    return {
        type: LOADED_MHC_PERMISSIONS,
        mhcPermissions
    };
};

export const loadedRCPPermission = (rcpPermissions: string[]): IAuthenticationAction => {
    return {
        type: LOADED_RCP_PERMISSIONS,
        rcpPermissions
    };
};
