import { IProviderSkill, IProviderConfig, ISpiProviderConfigs, IVersionedObject } from '../models/spiProviderConfig/ISpiProviderOnboarding';

export const testProviderSkill: IProviderSkill = {
    skillId: 'skill123',
    skillStage: 'live'
};

export const testProviderConfig1: IProviderConfig = {
    providerId: 'provider123',
    isBatchProvider: false,
    defaultEndpoint: testProviderSkill,
    cti: 'someCti',
    supportedLocales: ['en-US', 'en-GB'],
    supportedExperienceTypes: ['type1', 'type1'],
    supportedContentTypes: ['contentType1', 'contentType2'],
    supportedEndpointTypes: ['endpointType1', 'endpointType2']
};

export const testProviderConfig1Updated: IProviderConfig = {
    providerId: 'provider123',
    isBatchProvider: true,
    defaultEndpoint: testProviderSkill,
    cti: 'someUpdatedCti',
    supportedLocales: ['en-USUpdated', 'en-GB'],
    supportedExperienceTypes: ['type1Updated', 'type1'],
    supportedContentTypes: ['contentType1Updated', 'contentType2'],
    supportedEndpointTypes: ['endpointType1Updated', 'endpointType2']
};

export const testProviderConfig2: IProviderConfig = {
    providerId: 'provider456',
    isBatchProvider: true,
    defaultEndpoint: testProviderSkill,
    cti: 'randomCti',
    supportedLocales: ['en-EU', 'en-AU'],
    supportedExperienceTypes: ['type3', 'type4'],
    supportedContentTypes: ['contentType3', 'contentType4'],
    supportedEndpointTypes: ['endpointType3', 'endpointType4']
};

export const testSpiProviderConfigs: ISpiProviderConfigs = {
    providerConfigs: {
        'provider123': testProviderConfig1, 'provider456': testProviderConfig2
    }
};

export const testVersionedObject1: IVersionedObject<IProviderConfig> = {
    providerId: 'provider123',
    versionId: 'version1',
    object: testProviderConfig1,
    lastModified: 'today'
};

export const testVersionedObject2: IVersionedObject<IProviderConfig> = {
    providerId: 'provider123',
    versionId: 'version2',
    object: testProviderConfig1Updated,
    lastModified: 'yesterday'
};

export const testVersionHistory: { [key: string]: { [key: string]: IVersionedObject<IProviderConfig> }} = {
    'provider123': {
        'version1': testVersionedObject1,
        'version2': testVersionedObject2
    }
};

export const testVersionHistoryIndexed: { [key: string]: IVersionedObject<IProviderConfig> } = {
    'version1': testVersionedObject1,
    'version2': testVersionedObject2
};

export const emptyConfig: IProviderConfig = {
    providerId: '',
    isBatchProvider: false,
    defaultEndpoint: {
        skillId: '',
        skillStage: ''
    },
    cti: '',
    supportedLocales: [],
    supportedExperienceTypes: [],
    supportedContentTypes: [],
    supportedEndpointTypes: []
};

export const emptyNaEndpointConfig: IProviderConfig = {
    providerId: 'testId',
    isBatchProvider: true,
    defaultEndpoint: {
        skillId: 'some-skill-id',
        skillStage: 'gamma'
    },
    naEndpoint: {
        skillId: '',
        skillStage: ''
    },
    cti: 'testCti',
    supportedLocales: ['testLocale'],
    supportedExperienceTypes: ['testExperienceType'],
    supportedContentTypes: ['testContentType'],
    supportedEndpointTypes: ['testEndpointType']
};

export const validNaEndpointSkillIdConfig: IProviderConfig = {
    providerId: 'testId',
    isBatchProvider: true,
    defaultEndpoint: {
        skillId: 'some-skill-id',
        skillStage: 'gamma'
    },
    naEndpoint: {
        skillId: 'testSkillId',
        skillStage: 'testSkillStage'
    },
    cti: 'testCti',
    supportedLocales: ['testLocale'],
    supportedExperienceTypes: ['testExperienceType'],
    supportedContentTypes: ['testContentType'],
    supportedEndpointTypes: ['testEndpointType']
};

export const undefinedNaEndpointSkillIdConfig = {
    providerId: 'testId',
    isBatchProvider: true,
    defaultEndpoint: {
        skillId: 'some-skill-id',
        skillStage: 'gamma'
    },
    naEndpoint: {
        skillStage: 'testSkillStage'
    },
    cti: 'testCti',
    supportedLocales: ['testLocale'],
    supportedExperienceTypes: ['testExperienceType'],
    supportedContentTypes: ['testContentType'],
    supportedEndpointTypes: ['testEndpointType']
};

export const undefinedNaEndpointSkillStageConfig = {
    providerId: 'testId',
    isBatchProvider: true,
    defaultEndpoint: {
        skillId: 'some-skill-id',
        skillStage: 'gamma'
    },
    naEndpoint: {
        skillId: 'testSkillId'
    },
    cti: 'testCti',
    supportedLocales: ['testLocale'],
    supportedExperienceTypes: ['testExperienceType'],
    supportedContentTypes: ['testContentType'],
    supportedEndpointTypes: ['testEndpointType']
};