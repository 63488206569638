import {ILoadChangeControlAction} from '../actions/experienceDetailViewActions';
import {
    ERROR_LOADING_CHANGE_CONTROL_DECISION,
    LOADED_CHANGE_CONTROL_DECISION,
    LOADING_CHANGE_CONTROL_DECISION,
    RESET_CHANGE_CONTROL_DECISION
} from '../constants/experienceDetailViewActionTypes';

export interface IChangeControlDecisionState {
    isLoading: boolean;
    message?: string;
    error?: Error;
}

export const changeControlDecisionReducer = (
    state: IChangeControlDecisionState = { isLoading: false },
    action: ILoadChangeControlAction
): IChangeControlDecisionState => {
    switch (action.type) {
        case LOADING_CHANGE_CONTROL_DECISION:
            return {
                isLoading: true
            };
        case LOADED_CHANGE_CONTROL_DECISION:
            return {
                isLoading: false,
                message: action.message
            };
        case ERROR_LOADING_CHANGE_CONTROL_DECISION:
            return {
                isLoading: false,
                message: action.message,
                error: action.error
            };
        case RESET_CHANGE_CONTROL_DECISION:
            return {
                isLoading: false,
                error: undefined
            };
        default:
            return state;
    }
};
