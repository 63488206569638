import { IAuthenticationAction } from '../actions/authenticationActions';
import { SET_SHARED_AUTHENTICATION_DATA, LOADED_USER_PERMISSIONS, LOADED_MHC_PERMISSIONS, LOADED_RCP_PERMISSIONS } from '../constants/authenticationActionTypes';
import { IAuthenticationInfo } from '../models/AuthenticationInfo';

/*
Reducer for any globally shared state such as the Cognito session
 */

export interface IAuthenticationState {
    authenticationInfo?: IAuthenticationInfo;
    permissions?: string[];
    mhcPermissions?: string[];
    rcpPermissions?: string[];
}

// Reducer function takes in a current state and action, returns a new state depending on action
export const authenticationReducer = (
    state: IAuthenticationState = {},
    action: IAuthenticationAction): IAuthenticationState => {
    switch (action.type) {
        case SET_SHARED_AUTHENTICATION_DATA:
            const { authenticationInfo } = action;
            return {
                ...state,
                authenticationInfo,
            };
        case LOADED_USER_PERMISSIONS:
            const { permissions } = action;
            return {
                ...state,
                permissions,
            };
        case LOADED_MHC_PERMISSIONS:
            const { mhcPermissions } = action;
            return {
                ...state,
                mhcPermissions,
            };
        case LOADED_RCP_PERMISSIONS:
            const { rcpPermissions } = action;
            return {
                ...state,
                rcpPermissions,
            };

        default:
            return state;
    }
};
