export enum UserRole {
    ADULT =   'ADULT',
    CHILD =    'CHILD',
    ALL   =      'ALL'
}

export interface ISpeakerData {
    /**
     * User Role Information
     */
    userRole: UserRole;
}

 /* Utility Methods */
export function buildSpeakerData(userRoleObj: UserRole): ISpeakerData {
    return {
        userRole: userRoleObj
    };
}
