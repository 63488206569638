import { ILoadAlexaLabMetricsAction } from '../actions/experienceMetricsViewActions';
import {
    LOADING_ALEXALAB_METRICS,
    LOADED_ALEXALAB_METRICS,
    ERROR_LOADING_ALEXALAB_METRICS
} from '../constants/experienceMetricsViewActionTypes';
import { IAlexaLabMetrics } from '../models/AlexaLabMetrics';

export interface IExperienceMetricsViewState {
    isLoading: boolean;
    alexaLabMetrics?: IAlexaLabMetrics;
    error?: Error;
}

export const experienceMetricsViewReducer = (
    state: IExperienceMetricsViewState = { isLoading: false },
    action: ILoadAlexaLabMetricsAction
): IExperienceMetricsViewState => {
    switch (action.type) {
        case LOADING_ALEXALAB_METRICS:
            return {
                ...state,
                isLoading: true
            };
        case LOADED_ALEXALAB_METRICS:
            const { alexaLabMetrics } = action;

            return {
                ...state,
                isLoading: false,
                alexaLabMetrics
            };
        case ERROR_LOADING_ALEXALAB_METRICS:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};
