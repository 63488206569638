import {
    ExperimentsPath,
    API_ENDPOINT,
    AlexaLabMetricsPath,
    CXMetricsPath,
    FilterMetricsPath,
    EventTrailPath,
    ConfigureAlexaLabMetricsPath,
    ExperimentsPaginatePath,
    GenerateExperimentApprovalPath,
    GetSimilarExperimentsPath,
    ChangeControlDecisionPath,
    TestExperimentsPath,
    ConfigureCifExperimentPath,
    SearchCapabilityCatalogPath,
    GenerateVcifExperiencesWithAccPath
} from '../constants/lambdaEndpoints';
import { IExperience, IExperiencePage } from '../models/Experience';
import { EventTrailResponse } from '../models/events/EventTrail';
import { IAlexaLabMetrics, AlexaLabTreatment, createMappedAlexaLabMetrics } from '../models/AlexaLabMetrics';
import { axios } from './axios';
import { ExperienceMetricFrequency, IRawCifMetricsData } from '../models/CifMetrics';
import { ExperienceType } from '../models/Experience';
import { FilterMetricFrequency, IRawFilterMetricsData } from '../models/FilterMetrics';
import { REQUESTED_DIALUP_STAGE_OPTIONS } from '../util/exposureControlAllocationHelper';
import { ISimilarExperiencesData } from '../models/SimilarExperiencesData';
import { Region } from '../util/context';
import { IPromotedCapability } from '../models/ExperienceUpdateCandidate';
import { IRawRcpMetricsData, OdysseyExperienceMetricFrequency } from '../models/v2/metric/RCPMetrics';

export async function getChangeControlDecision(startDate?: string, region?: Region) : Promise<string> {
    const searchParams = new URLSearchParams();
    const alexaRegion: string | undefined = (region !== undefined)?'Alexa ' + region.toString():undefined;
    appendParamIfPresent(searchParams, 'startDate', startDate);
    appendParamIfPresent(searchParams, 'region', alexaRegion);

    const response = await axios.get(`${API_ENDPOINT}${ChangeControlDecisionPath}?${searchParams.toString()}`);
    return response.data;
}

export async function getExperiencesPaginate(limit: number, exclusiveStartKey?: string): Promise<IExperiencePage> {
    const searchParams = new URLSearchParams();

    appendParamIfPresent(searchParams, 'limit', limit.toString());
    appendParamIfPresent(searchParams, 'exclusiveStartKey', exclusiveStartKey);

    const response = await axios.get(`${API_ENDPOINT}${ExperimentsPaginatePath}?${searchParams.toString()}`);
    return response.data;
}

export async function getExperience(id: string): Promise<IExperience> {
    const response = await axios.get(`${API_ENDPOINT}${ExperimentsPath}/${id}`);
    return response.data;
}

export async function deleteExperience(id: string): Promise<string> {
    const response = await axios.delete(`${API_ENDPOINT}${ExperimentsPath}/${id}`);
    return response.data;
}

// returns ID
export async function putExperience(experience: IExperience): Promise<string> {
    try {
        const response = await axios.put(`${API_ENDPOINT}${ExperimentsPath}`, experience);
        return response.data;  // experience ID
    } catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data);
        } else {
            throw error;
        }
    }
}

export async function testCifExperiment(
    segmentId: string,
    experienceId: string,
): Promise<string> {
    const searchParams = new URLSearchParams();
    // Temporary hack for backwards compatibility
    searchParams.append('customerId', segmentId);
    searchParams.append('segmentId', segmentId);
    searchParams.append('experimentId', experienceId);

    const response = await axios.post(`${API_ENDPOINT}${TestExperimentsPath}?${searchParams.toString()}`);
    return response.data.successful;
}

export async function configureAlexaLabMetrics(experienceId: string): Promise<string> {
    const response = await axios.post(`${API_ENDPOINT}${ConfigureAlexaLabMetricsPath}/${experienceId}`, null);
    if (response.data.isSuccessful) {
        return response.data.message;
    } else {
        throw new Error(response.data.message);
    }
}

export async function configureCifExperiment(
    experienceId: string,
    message: string,
    configurationType: string,
): Promise<string> {
    const searchParams = new URLSearchParams();
    searchParams.append('message', message);
    searchParams.append('configurationType', configurationType);

    try {
        const response = await axios.post(`${API_ENDPOINT}${ConfigureCifExperimentPath}/${experienceId}?${searchParams.toString()}`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data);
        } else {
            throw error;
        }
    }
}

export async function getEventTrail(id: string): Promise<EventTrailResponse> {
    const response = await axios.get(`${API_ENDPOINT}${EventTrailPath}/${id}`);

    return response.data;
}

export async function getAlexaLabMetrics(
    weblabId: string,
    realm: string,
    treatment: AlexaLabTreatment,
    baseline: AlexaLabTreatment,
    dimension?: string,
): Promise<IAlexaLabMetrics | undefined> {
    const searchParams = new URLSearchParams();
    appendParamIfPresent(searchParams, 'treatment', treatment);
    appendParamIfPresent(searchParams, 'baseline', baseline);
    appendParamIfPresent(searchParams, 'dimension', dimension);

    const response = await axios.get(`${API_ENDPOINT}${AlexaLabMetricsPath}/${weblabId}/realm/${realm}?${searchParams.toString()}`);

    if (response.status === 204) {
        return undefined;
    }

    return createMappedAlexaLabMetrics(response.data);
}

export async function getCXMetrics(
    experienceIds: string[],
    experienceType: ExperienceType,
    periodType: ExperienceMetricFrequency,
    numPeriods: number,
    metricFilter: string[],
    endPeriod?: string
): Promise<IRawCifMetricsData[] | undefined> {
    const searchParams = new URLSearchParams();
    appendParamIfPresent(searchParams, 'experienceType', experienceType);
    appendParamIfPresent(searchParams, 'periodType', periodType);
    appendParamIfPresent(searchParams, 'endPeriod', endPeriod);
    appendParamIfPresent(searchParams, 'numPeriods', JSON.stringify(numPeriods));
    appendParamIfPresent(searchParams, 'experienceIds', JSON.stringify(experienceIds));
    appendParamIfPresent(searchParams, 'metricFilter', JSON.stringify(metricFilter));

    const response = await axios.get(`${API_ENDPOINT}${CXMetricsPath}?${searchParams.toString()}`);

    if (response.status === 204) {
        return undefined;
    }

    return (response.data);
}

export async function getRcpCXMetrics(
    experienceIds: string[],
    experienceType: ExperienceType,
    periodType: OdysseyExperienceMetricFrequency,
    numPeriods: number,
    metricFilter: string[],
    endPeriod?: string
): Promise<IRawRcpMetricsData[] | undefined> {
    const searchParams = new URLSearchParams();
    appendParamIfPresent(searchParams, 'experienceType', experienceType);
    appendParamIfPresent(searchParams, 'periodType', periodType);
    appendParamIfPresent(searchParams, 'endPeriod', endPeriod);
    appendParamIfPresent(searchParams, 'numPeriods', JSON.stringify(numPeriods));
    appendParamIfPresent(searchParams, 'experienceIds', JSON.stringify(experienceIds));
    appendParamIfPresent(searchParams, 'metricFilter', JSON.stringify(metricFilter));

    const response = await axios.get(`${API_ENDPOINT}${CXMetricsPath}?${searchParams.toString()}`);

    if (response.status === 204) {
        return undefined;
    }

    return (response.data);
}

export async function getFilterMetrics(
    experienceIds: string[],
    experienceType: ExperienceType,
    periodType: FilterMetricFrequency,
    numPeriods?: number,
    endPeriod?: string
): Promise<IRawFilterMetricsData[] | undefined> {
    const searchParams = new URLSearchParams();
    appendParamIfPresent(searchParams, 'experienceType', experienceType);
    appendParamIfPresent(searchParams, 'periodType', periodType);
    appendParamIfPresent(searchParams, 'endPeriod', endPeriod);
    appendParamIfPresent(searchParams, 'numPeriods', JSON.stringify(numPeriods));
    appendParamIfPresent(searchParams, 'experienceIds', JSON.stringify(experienceIds));

    const response = await axios.get(`${API_ENDPOINT}${FilterMetricsPath}?${searchParams.toString()}`);

    if (response.status === 204) {
        return undefined;
    }

    return (response.data);
}

export async function generateExperimentApproval(
    experienceId: string,
    isExceptionRequest: boolean,
    requestedDialupStage?: REQUESTED_DIALUP_STAGE_OPTIONS,
    simDescription?: string,
    exceptionReason?: string,
    enableGrammarSpellingChecks?: boolean,
    enableSensitiveContentChecks?: boolean
): Promise<string> {
    const searchParams = new URLSearchParams();
    appendParamIfPresent(searchParams, 'experienceId', experienceId);
    appendParamIfPresent(searchParams, 'approvalSimDescription', simDescription);
    appendParamIfPresent(searchParams, 'exceptionRequest',  String(isExceptionRequest));
    appendParamIfPresent(searchParams, 'exceptionReason', exceptionReason);
    appendParamIfPresent(searchParams, 'requestedDialupStage', requestedDialupStage);
    appendParamIfPresent(searchParams, 'enableGrammarSpellingChecks', String(enableGrammarSpellingChecks));
    appendParamIfPresent(searchParams, 'enableSensitiveContentChecks', String(enableSensitiveContentChecks));

    try {
        const response = await axios.post(
            `${API_ENDPOINT}${GenerateExperimentApprovalPath}?${searchParams.toString()}`,
            null,
            {
                validateStatus: (status) => {
                    // customized logic to force Status Code 207 to throw error
                    return status >= 200 && status < 300 && status !== 207;
                }
            }
        );
        return response.data;  // approval ID
    } catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data);
        } else {
            throw error;
        }
    }
}

export async function getSimilarExperiments(
    targetId: string,
    targetLevels: string[]
): Promise<ISimilarExperiencesData[] | undefined> {
    const searchParams = new URLSearchParams();
    appendParamIfPresent(searchParams, 'targetLevels',  JSON.stringify(targetLevels));

    try {
        const response = await axios.get(`${API_ENDPOINT}${GetSimilarExperimentsPath}/${targetId}?${searchParams.toString()}`);
        return response.data.data;
    } catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data);
        } else {
            throw error;
        }
    }
}

export const appendParamIfPresent = (params: URLSearchParams, key: string, value?: string) => {
    if (value) {
        params.append(key, value);
    }
};

export async function getCapabilityCatalogs(searchTerm: string, marketplace: string, locale: string, pageSize?: number): Promise<IPromotedCapability[] | undefined> {
    const searchParams = new URLSearchParams();
    appendParamIfPresent(searchParams, 'searchTerm', searchTerm);
    appendParamIfPresent(searchParams, 'country', marketplace);
    appendParamIfPresent(searchParams, 'locale', locale);
    appendParamIfPresent(searchParams, 'pageSize', pageSize?.toString());

    try {
        const response = await axios.get(`${API_ENDPOINT}${SearchCapabilityCatalogPath}?${searchParams.toString()}`);
        const output: IPromotedCapability[] = [];
        for (const responseData of response.data) {
            output.push({
                capabilityName: responseData.attributes?.name as string | undefined,
                capabilityLocale: locale as string | undefined,
                capabilityId: responseData.capabilityId as string | undefined,
                NLUIntent: responseData.voiceIngress?.variants?.[0]?.nluintent as string | undefined,
                NLUDomain: responseData.voiceIngress?.variants?.[0]?.nludomain as string | undefined,
                supportedDevices: responseData.attributes?.supportedDevices as string[] | undefined,
                businessDomain: responseData.attributes?.businessDomain as string | undefined,
                businessVertical: responseData.attributes?.businessVertical as string | undefined,
                confidenceScore: responseData.rankingContext?.confidenceScore as number | undefined,
                goldenUtterance: responseData.voiceIngress?.variants?.[0]?.utterance as string | undefined
            });
        }

        return output;
    } catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data);
        } else {
            throw error;
        }
    }
}

export async function generateVcifExperiencesUsingAcc(capabilitiesList: string[], intentsList: string[]): Promise<any> {
    const searchParams = new URLSearchParams();
    appendParamIfPresent(searchParams, 'capabilitiesList', JSON.stringify(capabilitiesList));
    appendParamIfPresent(searchParams, 'intentsList', JSON.stringify(intentsList));

    try {
        const response = await axios.post(`${API_ENDPOINT}${GenerateVcifExperiencesWithAccPath}?${searchParams.toString()}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}
