import React from 'react';
import { Button } from '@amzn/awsui-components-react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ValidationError } from '../../exceptions/ValidationErrors';

export interface IButtonWrapper {
    id: string;
    displayText: string;
    icon?: Button.Icon;
    isLoading?: boolean;  // isLoading for long network call
    isDisabled?: boolean; // isDisabled to prevent user clicking
    experience: IFlattenedExperience;
    validate: (experience: IFlattenedExperience) => void;
    onClick?: (event: CustomEvent<Button.ClickDetail>) => void;
}

export class ButtonWrapper extends React.Component<IButtonWrapper> {
    render () {
        const { id, displayText, experience, isDisabled, isLoading, icon, validate, onClick } = this.props;
        let errorDisplayNodes: React.ReactNode;
        let isValid: boolean = true;

        if (!isLoading || !isDisabled) {
            try {
                validate(experience);
                isValid = true;
            } catch (error) {
                if (error instanceof ValidationError) {

                    errorDisplayNodes = <p>
                        Please fix the following errors:
                            <ul>
                                {error.getErrorMessages().map((errorMessage) => {
                                    return <li>{errorMessage}</li>;
                                })}
                            </ul>
                    </p>;
                } else {
                    errorDisplayNodes = error.message;
                }

                isValid = false;
            }
        }

        return <div>
            <Button
                id={id}
                icon={icon}
                loading={isLoading}
                disabled={isDisabled || !isValid}
                onClick={onClick}>
                {displayText}
            </Button>

            {errorDisplayNodes && <p className='awsui-util-status-negative'>{errorDisplayNodes}</p>}
        </div>;
    }
}
