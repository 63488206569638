import { IExperienceSlot, EMPTY_SLOT, processSlots } from './ExperienceSlot';
import { ITargetPublisherData, EMPTY_TARGET_PUBLISHER_DATA, processTargetPublisherData } from './TargetPublisherData';
import { INluOpportunityTargeting } from './NluOpportunityTargeting';
import { IExperienceTargetUtterance, UtteranceMatcher, processTargetUtterances } from './ExperienceTargetUtterance';
import { filterEmptyStrings } from '../util/stringAndMappingHelper';
import { PromptDetails } from './PromptDetails';
import _ from 'lodash';

/* NLU Trigger Types */

export type OpportunityTriggerGroup =
    | 'InclusionTriggers'
    | 'ExclusionTriggers'
    | 'VisualCIFTriggers';

export type OpportunityTriggerType =
    | 'Intent'
    | 'QAIntentOrPhaticIntent'
    | 'SkillCategory'
    | 'SkillID'
    | 'FrictionTrigger';

export type IntentTargetType =
    | 'SlotName'
    | 'PublisherDataKey'
    | 'SkillCategory'
    | 'SpecifiedSkill'
    | 'PromptDetails';

export type UtteranceType =
    | 'Question'
    | 'Interpretation';

export type MatchingMethod =
    | 'ExactMatch'
    | 'Prefix'
    | 'Suffix'
    | 'Contains';

/* NLU Trigger Interfaces */

export interface IOpportunityTrigger {
    /**
     * Is Editing (Default new trigger is editable)
     */
    isEditable: boolean;

    /**
     * OpportunityTriggerType
     */
    opportunityTrigger: OpportunityTriggerType;
}

export interface IIntentTrigger extends IOpportunityTrigger {
    /**
     * BluIntent to match.
     */
    bluIntent: string;

    /**
     * Slot, Publisher, Skill Category, SkillID target(s) grouping
     */
    intentTargets: IIntentTarget[];

    /**
     * Whether this intent trigger has slot name matching configured
     */
    exactSlotNameMatching: boolean;
}

export interface IQAOrPhaticIntentTrigger extends IOpportunityTrigger {
    /**
     * BluIntent to match.
     */
    bluIntent: string;

    /**
     * Utterance Type selected.
     */
    utteranceType?: UtteranceType;

    /**
     * Utterance Matching Targets
     */
    matchingTargets: UtteranceMatchingTarget[];
}

export interface ISkillCategoryTrigger extends IOpportunityTrigger {
    /**
     * SkillID's to match
     */
    skillCategory: string[];
}

export interface ISkillIdTrigger extends IOpportunityTrigger {
    /**
     * SkillID's to match
     */
    skillId: string[];
}

export interface IFrictionTrigger extends IOpportunityTrigger {
    /**
     * List of prompt details
     */
    frictionPromptDetails: PromptDetails[];

    /**
     * BluIntent to match.
     */
    bluIntent: string;
}

export interface UtteranceMatchingTarget {
    /**
     * How do we match try and match the values.
     */
    matchingMethod?: MatchingMethod;

    /**
     * Target Utterance(s)
     */
    targetUtterance?: string;
}

export interface IIntentTarget {
    /**
     * IntentTargetType
     */
    intentTargetType: IntentTargetType;

    /**
     * Should Intent Value(s) be visible to the user
     */
    showValues: boolean;

    /**
     * Intent Value target
     */
    intentTargetValue?: IExperienceSlot | ITargetPublisherData | string[] | undefined;
}

/* Trigger Utility Methods */

export function buildIntentTrigger(): IIntentTrigger {
    return { opportunityTrigger: 'Intent', isEditable: true, bluIntent: '', intentTargets: [], exactSlotNameMatching: false };
}

export function buildPhaticOrQAIntentTrigger(): IQAOrPhaticIntentTrigger {
    return { opportunityTrigger: 'QAIntentOrPhaticIntent', bluIntent: '', isEditable: true, matchingTargets: [{}] };
}

export function buildSkillCategoryTrigger(): ISkillCategoryTrigger {
    return { opportunityTrigger: 'SkillCategory', isEditable: true, skillCategory: [] };
}

export function buildSkillIdTrigger(): ISkillIdTrigger {
    return { opportunityTrigger: 'SkillID', isEditable: true, skillId: [] };
}

export function buildFrictionTrigger(): IFrictionTrigger {
    return { opportunityTrigger: 'FrictionTrigger', isEditable: true, bluIntent: '', frictionPromptDetails: [{promptId: '', promptNamespace: ''}] };
}

/* Intent Target Utility Helpers */

export function buildSlotTarget(): IIntentTarget {
    return { intentTargetType: 'SlotName', showValues: false, intentTargetValue: EMPTY_SLOT };
}

export function buildPublisherDataTarget(): IIntentTarget {
    return { intentTargetType: 'PublisherDataKey', showValues: false, intentTargetValue: EMPTY_TARGET_PUBLISHER_DATA };
}

export function buildKeyValueTarget(targetType: IntentTargetType): IIntentTarget {
    return { intentTargetType: targetType, showValues: false, intentTargetValue: [] };
}

export function buildPromptDetails(): PromptDetails {
    return { promptId: '', promptNamespace: '' };
}

/* Helper Converters */

/**
 * This method allows for backwards compatibility with the existing Nlu Opportunity Targeting data type. We have to make backend changes in order to
 * fully incorporate the new trigger model and can then remove the conversion logic between the old and new data types
 *
 * @param nluOpportunityTargetings to convert into new IOpportunityTrigger data type.
 */

export const processOpportunityTrigger = (nluOpportunityTargetings: INluOpportunityTargeting[]): IOpportunityTrigger[] => {

    const opportunityTriggers = [] as IOpportunityTrigger[];

    if (nluOpportunityTargetings && nluOpportunityTargetings.length) {
        nluOpportunityTargetings.forEach(function (targeting) {
            opportunityTriggers.push(convertToOpportunityTrigger(targeting));
        });
    }

    return opportunityTriggers;
};

export const convertToOpportunityTrigger = (nluOpportunityTargeting: INluOpportunityTargeting): IOpportunityTrigger => {

    if (nluOpportunityTargeting.bluIntent) {
        if (nluOpportunityTargeting.frictionPromptDetails) {
            return convertToFrictionTrigger(nluOpportunityTargeting);
        }
        else if (nluOpportunityTargeting.targetUtteranceSlot || (nluOpportunityTargeting.targetUtterances && nluOpportunityTargeting.targetUtterances?.length)) {
            return convertToQAIntentOrPhaticIntentTrigger(nluOpportunityTargeting);
        } else {
            return convertToIntentTrigger(nluOpportunityTargeting);
        }
    } else if (nluOpportunityTargeting.categoryTargeting && nluOpportunityTargeting.categoryTargeting.length) {
        return convertToSkillCategoryTrigger(nluOpportunityTargeting);
    } else {
        return convertToSkillIdTrigger(nluOpportunityTargeting);
    }

};

export const convertToFrictionTrigger = (nluOpportunityTargeting: INluOpportunityTargeting): IFrictionTrigger => {
    return {
        bluIntent: nluOpportunityTargeting.bluIntent || '',
        frictionPromptDetails: nluOpportunityTargeting.frictionPromptDetails || [],
        isEditable: true,
        opportunityTrigger: 'FrictionTrigger'
    };
};

export const convertToQAIntentOrPhaticIntentTrigger = (nluOpportunityTargeting: INluOpportunityTargeting): IQAOrPhaticIntentTrigger => {

    return {
        ...nluOpportunityTargeting,
        bluIntent: !nluOpportunityTargeting.bluIntent ? '' : nluOpportunityTargeting.bluIntent,
        isEditable: true,
        opportunityTrigger: 'QAIntentOrPhaticIntent',
        matchingTargets: convertToMatchingTargets(nluOpportunityTargeting)
    };
};

export const convertToIntentTrigger = (nluOpportunityTargeting: INluOpportunityTargeting): IIntentTrigger => {

    return {
        ...nluOpportunityTargeting,
        bluIntent: !nluOpportunityTargeting.bluIntent ? '' : nluOpportunityTargeting.bluIntent,
        isEditable: true,
        exactSlotNameMatching: (nluOpportunityTargeting.exactSlotNameMatching === undefined) ? false : nluOpportunityTargeting.exactSlotNameMatching,
        opportunityTrigger: 'Intent',
        intentTargets: convertToIntentTargets(nluOpportunityTargeting)
    };
};

export const convertToSkillCategoryTrigger = (nluOpportunityTargeting: INluOpportunityTargeting): ISkillCategoryTrigger => {

    return {
        ...nluOpportunityTargeting,
        isEditable: true,
        opportunityTrigger: 'SkillCategory',
        skillCategory: nluOpportunityTargeting.categoryTargeting ? nluOpportunityTargeting.categoryTargeting : []
    };
};

export const convertToSkillIdTrigger = (nluOpportunityTargeting: INluOpportunityTargeting): ISkillIdTrigger => {

    return {
        ...nluOpportunityTargeting,
        isEditable: true,
        opportunityTrigger: 'SkillID',
        skillId: nluOpportunityTargeting.publisherIdTargeting ? nluOpportunityTargeting.publisherIdTargeting : []
    };
};

export const convertToIntentTargets = (nluOpportunityTargeting: INluOpportunityTargeting): IIntentTarget[] => {

    const convertedIntentTarget: IIntentTarget[] = [];

    if (nluOpportunityTargeting.slots && nluOpportunityTargeting.slots.length) {
        nluOpportunityTargeting.slots.forEach(function (slot) {
            convertedIntentTarget.push({ intentTargetType: 'SlotName', showValues: true, intentTargetValue: slot as IExperienceSlot });
        });
    }
    if (nluOpportunityTargeting.targetPublisherData && nluOpportunityTargeting.targetPublisherData.length) {
        nluOpportunityTargeting.targetPublisherData.forEach(function (targetPublisherData) {
            convertedIntentTarget.push({ intentTargetType: 'PublisherDataKey', showValues: true, intentTargetValue: targetPublisherData as ITargetPublisherData });
        });
    }
    if (nluOpportunityTargeting.categoryTargeting && nluOpportunityTargeting.categoryTargeting.length) {
        convertedIntentTarget.push({ intentTargetType: 'SkillCategory', showValues: false, intentTargetValue: filterEmptyStrings(nluOpportunityTargeting.categoryTargeting) });
    }
    if (nluOpportunityTargeting.publisherIdTargeting && nluOpportunityTargeting.publisherIdTargeting.length) {
        convertedIntentTarget.push({ intentTargetType: 'SpecifiedSkill', showValues: false, intentTargetValue: filterEmptyStrings(nluOpportunityTargeting.publisherIdTargeting) });
    }

    return convertedIntentTarget;
};

export const convertToMatchingTargets = (nluOpportunityTargeting: INluOpportunityTargeting): UtteranceMatchingTarget[] => {

    const convertedMatchingTargets: UtteranceMatchingTarget[] = [];

    if (nluOpportunityTargeting.targetUtterances && nluOpportunityTargeting.targetUtterances.length) {
        nluOpportunityTargeting.targetUtterances.forEach(function (target) {
            convertedMatchingTargets.push({ targetUtterance: target.utterance, matchingMethod: 'ExactMatch' });
            switch (target.utteranceMatcher) {
                case UtteranceMatcher.Prefix_Match:
                    convertedMatchingTargets[convertedMatchingTargets.length - 1].matchingMethod = 'Prefix';
                    break;
                case UtteranceMatcher.Suffix_Match:
                    convertedMatchingTargets[convertedMatchingTargets.length - 1].matchingMethod = 'Suffix';
                    break;
                case UtteranceMatcher.Contains_Match:
                    convertedMatchingTargets[convertedMatchingTargets.length - 1].matchingMethod = 'Contains';
                    break;
            }
        });
    }

    return convertedMatchingTargets;
};

/**
 * This method allows for backwards compatibility with existing "Create" flows, but presents a potential
 * problem for edit flows. We need to completely rebuild the Crete/Edit API interfaces to take advantage
 * of the new structure IFF we want things to continue to build gracefully.
 *
 * @param trigger to convert into NLU Opportunity.
 */
export const processNluOpportunityTrigger = (trigger: IOpportunityTrigger): INluOpportunityTargeting => {
    switch (trigger.opportunityTrigger) {
        case 'Intent':
            const intentTrigger = trigger as IIntentTrigger;
            return processIntentTrigger(intentTrigger);
        case 'QAIntentOrPhaticIntent':
            const qaOrPhaticTrigger = trigger as IQAOrPhaticIntentTrigger;
            return processQAOrPhaticIntentTrigger(qaOrPhaticTrigger);
        case 'SkillCategory':
            const skillCategoryTrigger = trigger as ISkillCategoryTrigger;
            return processSkillCategoryTrigger(skillCategoryTrigger);
        case 'SkillID':
            const skillIdTrigger = trigger as ISkillIdTrigger;
            return processSkillIdCategoryTrigger(skillIdTrigger);
        case 'FrictionTrigger':
            const frictionTrigger = trigger as IFrictionTrigger;
            return processFrictionTrigger(frictionTrigger);
    }
};

export const processFrictionTrigger = (frictionTrigger: IFrictionTrigger): INluOpportunityTargeting => {
    frictionTrigger.frictionPromptDetails = frictionTrigger.frictionPromptDetails.filter((promptDetails) => {
        return !_.isEmpty(promptDetails.promptId) && !_.isEmpty(promptDetails.promptNamespace);
    });

    return {
        ...frictionTrigger
    };
};

export const processSkillIdCategoryTrigger = (skillIdTrigger: ISkillIdTrigger): INluOpportunityTargeting => {
    return {
        ...skillIdTrigger,
        bluIntent: undefined,
        slots: undefined,
        targetUtterances: undefined,
        publisherIdTargeting: filterEmptyStrings(skillIdTrigger.skillId),
        categoryTargeting: undefined,
        targetPublisherData: undefined,
        exactSlotNameMatching: false
    };
};

export const processSkillCategoryTrigger = (skillCategoryTrigger: ISkillCategoryTrigger): INluOpportunityTargeting => {
    return {
        ...skillCategoryTrigger,
        bluIntent: undefined,
        slots: undefined,
        targetUtterances: undefined,
        publisherIdTargeting: undefined,
        categoryTargeting: filterEmptyStrings(skillCategoryTrigger.skillCategory),
        targetPublisherData: undefined,
        exactSlotNameMatching: false
    };
};

export const processQAOrPhaticIntentTrigger = (qaOrPhaticTrigger: IQAOrPhaticIntentTrigger): INluOpportunityTargeting => {
    const convertedTargetUtterance = [] as IExperienceTargetUtterance[];

    for (const target of qaOrPhaticTrigger.matchingTargets) {
        // Force the next element on no utterance match.
        if (!target.targetUtterance) {
            continue;
        }

        switch (target.matchingMethod) {
            case 'Prefix':
                convertedTargetUtterance.push(
                    { utterance: target.targetUtterance ? target.targetUtterance : '', utteranceMatcher: 'Prefix_Match' as UtteranceMatcher });
                break;
            case 'Suffix':
                convertedTargetUtterance.push(
                    { utterance: target.targetUtterance ? target.targetUtterance : '', utteranceMatcher: 'Suffix_Match' as UtteranceMatcher });
                break;
            case 'ExactMatch':
                convertedTargetUtterance.push(
                    { utterance: target.targetUtterance ? target.targetUtterance : '', utteranceMatcher: 'Exact_Match' as UtteranceMatcher });
                break;
            case 'Contains':
                convertedTargetUtterance.push(
                    { utterance: target.targetUtterance ? target.targetUtterance : '', utteranceMatcher: 'Contains_Match' as UtteranceMatcher });
                break;
        }
    }

    return {
        ...qaOrPhaticTrigger,
        bluIntent: qaOrPhaticTrigger.bluIntent,
        targetUtteranceSlot: qaOrPhaticTrigger.bluIntent === 'QAIntent' ? 'Question' : 'InterpretationFromEvi',
        slots: undefined,
        targetUtterances: processTargetUtterances(convertedTargetUtterance),
        publisherIdTargeting: undefined,
        categoryTargeting: undefined,
        targetPublisherData: undefined,
        exactSlotNameMatching: false
    };
};

export const processIntentTrigger = (intentTrigger: IIntentTrigger): INluOpportunityTargeting => {
    const convertedSlots = [] as IExperienceSlot[];
    const convertedPublisherIdTargeting = [] as string[];
    const convertedCategoryTargeting = [] as string[];
    const convertedTargetPublisherData = [] as ITargetPublisherData[];
    for (const target of intentTrigger.intentTargets) {
        switch (target.intentTargetType) {
            case 'SlotName':
                convertedSlots.push(target.intentTargetValue as IExperienceSlot);
                break;
            case 'PublisherDataKey':
                convertedTargetPublisherData.push(target.intentTargetValue as ITargetPublisherData);
                break;
            case 'SkillCategory':
                convertedCategoryTargeting.push(...target.intentTargetValue as string[]);
                break;
            case 'SpecifiedSkill':
                convertedPublisherIdTargeting.push(...target.intentTargetValue as string[]);
                break;
        }
    }

    return {
        ...intentTrigger,
        bluIntent: intentTrigger.bluIntent,
        slots: processSlots(convertedSlots),
        targetUtterances: undefined,
        publisherIdTargeting: filterEmptyStrings(convertedPublisherIdTargeting),
        categoryTargeting: filterEmptyStrings(convertedCategoryTargeting),
        targetPublisherData: processTargetPublisherData(convertedTargetPublisherData),
        exactSlotNameMatching: intentTrigger.exactSlotNameMatching
    };
};
