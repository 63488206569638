export const LOADING_EXPERIENCE_DETAIL = 'LOADING_EXPERIENCE_DETAIL';
export const LOADED_EXPERIENCE_DETAIL = 'LOADED_EXPERIENCE_DETAIL';
export const ERROR_LOADING_EXPERIENCE_DETAIL = 'ERROR_LOADING_DETAIL';

export const UPDATE_MULTI_CLONE_EXPERIENCES = 'UPDATE_MULTI_CLONE_EXPERIENCES';
export const CREATING_MULTI_CLONE_EXPERIENCES = 'CREATING_MULTI_CLONE_EXPERIENCES';
export const ERROR_UPDATE_MULTI_CLONE_EXPERIENCES = 'ERROR_UPDATE_MULTI_CLONE_EXPERIENCES';
export const SUCCESS_UPDATE_MULTI_CLONE_EXPERIENCES = 'SUCCESS_UPDATE_MULTI_CLONE_EXPERIENCES';

export const LOADING_BULLSEYE_METADATA = 'LOADING_BULLSEYE_METADATA';
export const LOADED_BULLSEYE_METADATA = 'LOADED_BULLSEYE_METADATA';
export const ERROR_LOADING_BULLSEYE_METADATA = 'ERROR_LOADING_BULLSEYE_METADATA';
export const RESET_BULLSEYE_METADATA = 'RESET_BULLSEYE_METADATA';

export const CONFIGURING_ALEXA_LAB_METRICS = 'CONFIGURING_ALEXA_LAB_METRICS';
export const CONFIGURED_ALEXA_LAB_METRICS = 'CONFIGURED_ALEXA_LAB_METRICS';
export const RESET_ALEXA_LAB_METRICS = 'RESET_ALEXA_LAB_METRICS';
export const ERROR_CONFIGURE_ALEXA_LAB_METRICS = 'ERROR_CONFIGURE_ALEXA_LAB_METRICS';

export const LOADING_EVENTS_METADATA = 'LOADING_EVENTS_METADATA';
export const LOADED_EVENTS_METADATA = 'LOADED_EVENTS_METADATA';
export const ERROR_LOADING_EVENTS_METADATA = 'ERROR_LOADING_EVENTS_METADATA';

export const LOADING_CATEGORIES_METADATA = 'LOADING_CATEGORIES_METADATA';
export const LOADED_CATEGORIES_METADATA = 'LOADED_CATEGORIES_METADATA';
export const ERROR_LOADING_CATEGORIES_METADATA = 'ERROR_LOADING_CATEGORIES_METADATA';

export const LOADING_EVENT_TRAIL = 'LOADING_EVENT_TRAIL';
export const LOADED_EVENT_TRAIL = 'LOADED_EVENT_TRAIL';
export const ERROR_LOADING_EVENT_TRAIL = 'ERROR_LOADING_EVENT_TRAIL';

export const LOADING_CHANGE_CONTROL_DECISION = 'LOADING_CHANGE_CONTROL_DECISION';
export const LOADED_CHANGE_CONTROL_DECISION = 'LOADED_CHANGE_CONTROL_DECISION';
export const ERROR_LOADING_CHANGE_CONTROL_DECISION = 'ERROR_LOADING_CHANGE_CONTROL_DECISION';
export const RESET_CHANGE_CONTROL_DECISION = 'RESET_CHANGE_CONTROL_DECISION';
