import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../reducers/index';
import { Button, ColumnLayout, FormField, Input, Table, TableSelection, Alert } from '@amzn/awsui-components-react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate, IPromotedCapability } from '../../models/ExperienceUpdateCandidate';
import {
    pushPromotedCapabilityAction,
    selectPromotedCapabilityAction,
    pushSuggestedFeature,
    pushBusinessInfo,
    pushDevicesSectionAction,
    pushRotatingContentPanelBusinessInfo,
    pushRotatingContentPanelDevicesConstraint,
    pushRotatingContentPanelMetaData
} from '../../actions/experienceEditViewActions';
import { ExternalLink } from './LinkComponents';
import { getCapabilityCatalogSearchAction } from '../../actions/capabilityCatalogSearchActions';
import { ICapabilityCatalogSearchState } from '../../reducers/capabilityCatalogSearchReducer';
import { LinkedCapabilityId } from './LinksRenderHelper';
import { DEVICES } from '../../constants/devices';

interface ICapabilitySearchComponentProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
    capabilityCatalogSearchState: ICapabilityCatalogSearchState;
}

interface ICapabilitySearchComponentState {
    searchKeyword: string;
}

class CapabilitySearchComponent extends React.Component<ICapabilitySearchComponentProps, ICapabilitySearchComponentState> {
    tableDef = [
        {
            id: 'capabilityName',
            header: 'Capability Name',
            cell: (item: IPromotedCapability) => item.capabilityName || '-'
        },
        {
            id: 'capabilityId',
            header: 'Capability ID',
            cell: (item: IPromotedCapability) => <LinkedCapabilityId capabilityId={item.capabilityId} locale={item.capabilityLocale} /> || '-'
        },
        {
            id: 'nluIntent',
            header: 'NLU Intent',
            cell: (item: IPromotedCapability) => item.NLUIntent || '-'
        },
        {
            id: 'nluDomain',
            header: 'NLU Domain',
            cell: (item: IPromotedCapability) => item.NLUDomain || '-'
        },
        {
            id: 'businessVertical',
            header: 'Business Vertical',
            cell: (item: IPromotedCapability) => item.businessVertical || '-'
        },
        {
            id: 'businessDomain',
            header: 'Business Domain',
            cell: (item: IPromotedCapability) => item.businessDomain || '-'
        }
    ];

    constructor(props: ICapabilitySearchComponentProps) {
        super(props);

        this.state = {
            searchKeyword: ''
        };
    }

    onChangeTableSelectionChangeHandler = (event: CustomEvent) => {
        if (event.detail.selectedItems.length === 1) {
            const { dispatch } = this.props;
            const capability: IPromotedCapability = event.detail.selectedItems[0];
            capability.supportedDevices = capability.supportedDevices?.filter(device => DEVICES[device] !== undefined);

            dispatch(selectPromotedCapabilityAction(capability));
            dispatch(pushPromotedCapabilityAction(capability));

            // auto-fills suggested nlu info and business info
            if (this.props.updateCandidate?.getType() === 'RotatingContentPanel') {
                dispatch(pushRotatingContentPanelBusinessInfo({
                    vertical: capability.businessVertical, domain: capability.businessDomain
                }));
                dispatch(pushRotatingContentPanelMetaData({
                    'intent': capability.NLUIntent || ''
                }));
            } else {
                dispatch(pushSuggestedFeature(capability.NLUDomain, capability.NLUIntent));
                dispatch(pushBusinessInfo(capability.businessVertical, capability.businessDomain));
            }

            // auto-fills targted devices for CIF experiences
            if (this.props.updateCandidate?.getType() === 'DeviceNotification' || this.props.updateCandidate?.getType() === 'AppNotification') {
                // don't auto-fill for device and app notifications
                return;
            } else if (this.props.updateCandidate?.getIsVisualCIF() || this.props.updateCandidate?.getType() === 'RotatingContentPanel') {
                // only auto-fills multimodal devices for Visual CIF
                capability.supportedDevices = capability.supportedDevices?.filter(device => DEVICES[device].type === 'Multimodal');
                if (this.props.updateCandidate.getType() === 'RotatingContentPanel') {
                    dispatch(pushRotatingContentPanelDevicesConstraint(capability.supportedDevices ?? []));
                } else {
                    dispatch(pushDevicesSectionAction(capability.supportedDevices));
                }
            } else {
                // auto-fills for CIF experiences
                dispatch(pushDevicesSectionAction(capability.supportedDevices));
            }
        }
    }

    onChangeSearchKeywordHandler = (event: CustomEvent) => {
        this.setState((prevState) => ({
            ...prevState,
            searchKeyword: event.detail.value
        }));
    }

    onSearchButtonClick = async () => {
        if (this.state.searchKeyword === '') return;

        const marketplace = this.props.updateCandidate?.getRegion().marketplace || this.props.experience?.marketplace || 'US';
        const locale = this.props.updateCandidate?.getRegion().locale || this.props.experience?.locale || 'en_US';

        const { dispatch } = this.props;
        dispatch(getCapabilityCatalogSearchAction(this.state.searchKeyword, marketplace, locale));
    }

    showSearchResult = () => {
        const { capabilities } = this.props.capabilityCatalogSearchState;
        const { selectedCapability } = this.props.capabilityCatalogSearchState;
        const createAccSimUrl = 'https://sim.amazon.com/issues/create?template=74fd4082-e2c9-4fca-b92c-2dea022a631f';

        if (capabilities) {
            if (capabilities.length > 0) {
                return (
                    <div>
                        <br />
                        <h3>Search Results</h3>
                        <small>
                            <span>Choose the capability being promoted by this campaign. If capability does not exist,&nbsp;
                                <ExternalLink href={createAccSimUrl}>create a SIM ticket</ExternalLink>&nbsp;
                                to add to the Alexa Capability Catalog.</span>
                        </small>
                        <Table
                            id='capability-search-output-table'
                            loadingText='Loading resources'
                            columnDefinitions={this.tableDef}
                            items={capabilities}
                            features={['selection']}
                            variant='borderless'
                        >
                            <TableSelection
                                selectionType={'single'}
                                selectedItems={(selectedCapability) ? [selectedCapability] : []}
                                onSelectionChange={this.onChangeTableSelectionChangeHandler}
                                trackBy='capabilityId'
                            />
                        </Table>
                    </div>
                );
            } else {
                return <div><h3>No capability found</h3><small><span>
                    <ExternalLink href={createAccSimUrl}>Create a SIM ticket</ExternalLink>&nbsp;
                    to add to the Alexa Capability Catalog.</span></small></div>;
            }
        }
    }

    render() {
        const searchOutput = this.showSearchResult();
        const { isLoading } = this.props.capabilityCatalogSearchState;
        const promotedCapabilityWarning = `Selecting a capability will auto-fill and modify existing Suggested NLU Intent, NLU Domain, Business Vertical, Business Domain and Targeted Devices if applicable.
        Please make sure the information auto-filled suits your needs and make manual changes if necessary.`;

        return (
            <>
                <Alert>{promotedCapabilityWarning}</Alert>
                <br></br>
                <FormField label='Search By Capability Id or Utterance in English'>
                    <ColumnLayout columns={2}>
                        <div data-awsui-column-layout-root='true'>
                            <Input id='capability-search-input' type='search' value={this.state.searchKeyword} onChange={this.onChangeSearchKeywordHandler} />
                            <Button id='capability-search-button' loading={isLoading} onClick={this.onSearchButtonClick}>Search</Button>
                        </div>
                    </ColumnLayout>
                </FormField>

                <div id='capability-search-result'>
                    {searchOutput}
                </div>
            </>

        );
    }
}


const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState, capabilityCatalogSearchState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        capabilityCatalogSearchState,
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(CapabilitySearchComponent);
