import * as React from 'react';

interface DiffLineProps {
    line: {
        old: string;
        new: string;
        type: 'same' | 'added' | 'removed'
    };
    type: 'old' | 'new';
}

const DiffLine: React.FC<DiffLineProps> = ({ line, type}) => {
    let backgroundColor = 'transparent';
    if (line.type === 'added' && type === 'new') backgroundColor = '#b2dfb4';
    if (line.type === 'removed' && type === 'old') backgroundColor = '#ffa4a2';

    return (
        <div style={{ backgroundColor, whiteSpace: 'pre-wrap' }}>
            {type === 'old' ? line.old : line.new}
        </div>
    );
};

export default DiffLine;
