import { getCurrRegionStage, Region } from '../util/context';
import { PAGE_ENDPOINTS_MAP } from '../constants/pageEndpoints';

// TODO: add back KMS
export enum EncryptionMethod {
    KeyMaster = 'KeyMaster',
    // KMS = 'KMS'
}

export enum AuthenticationMethod {
    AAA = 'AAA',
    CLOUD_AUTH = 'CLOUD_AUTH',
    IAM_AUTH = 'IAM_AUTH',
}

export enum SerializationMethod {
    REST = 'REST',
    CORAL = 'CORAL'
}

export interface IInvocationParameters {
    authenticationMethod: AuthenticationMethod;
    AAAServiceName: string;
    AAAOperationName: string;
    assumeRoleARN: string;
    endpoint: {[key in Region]?: string};
    CTI: string;
    launchWeblab?: string;
    isRedCertified?: boolean;
    encryptionMethod?: EncryptionMethod;
    serializationMethod?: SerializationMethod;
    validationErrorMessage?: string;
}

export const EMPTY_INVOCATION_PARAMETERS: IInvocationParameters = {
    authenticationMethod: AuthenticationMethod.CLOUD_AUTH,
    AAAServiceName: '',
    AAAOperationName: '',
    assumeRoleARN: '',
    endpoint: {},
    CTI: ''
};

export const BYOR_INVOCATION_PARAMETERS: IInvocationParameters = {
    serializationMethod: SerializationMethod.REST,
    authenticationMethod: AuthenticationMethod.CLOUD_AUTH,
    AAAServiceName: 'AlexaPageProviderService',
    AAAOperationName: 'getPageDomainInformation',
    CTI: 'Alexa/PAGE/BYOR',
    launchWeblab: '',
    endpoint: {'NA': PAGE_ENDPOINTS_MAP[getCurrRegionStage().stage].NA,
                'EU': PAGE_ENDPOINTS_MAP[getCurrRegionStage().stage].EU,
                'FE': PAGE_ENDPOINTS_MAP[getCurrRegionStage().stage].FE},
    isRedCertified: false,
    assumeRoleARN: ''
};

export function isAuthenticationMethodIamAuth(invocationParameters: IInvocationParameters): boolean {
    return (invocationParameters.authenticationMethod === AuthenticationMethod.IAM_AUTH);
}

export function isAuthenticationMethodAAA(invocationParameters: IInvocationParameters): boolean {
    return (invocationParameters.authenticationMethod === AuthenticationMethod.AAA);
}

export function isAuthenticationMethodCloudAuth(invocationParameters: IInvocationParameters): boolean {
    return (invocationParameters.authenticationMethod === AuthenticationMethod.CLOUD_AUTH);
}
