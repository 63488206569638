export class ValidationError extends Error {
    private errors: string[];

    constructor(fields: string[], ...params: any[]) {
        super(...params);
        this.errors = fields;
    }

    getErrorMessages() {
        return this.errors;
    }
}
