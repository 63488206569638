import * as React from 'react';
import { Component } from 'react';


export class ExpiredTokenPage extends Component {
    public render() {
        return (
            <div className='awsui'>
                <div className='awsui-util-t-c' >
                    <img src='/odyssey-loch-nessie-big.svg' alt='Loch Nessie' style={{
                        height: '350px',
                        width: '350px',
                        marginTop: '150px'
                    }} />
                    <h1>Your auth credentials expired, reloading...</h1>
                </div>
            </div>
        );
    }
}
