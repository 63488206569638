import * as React from 'react';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { Spinner, Checkbox, Alert, Icon } from '@amzn/awsui-components-react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { EMPTY_MOBILE_CARD_ACTION, IMobileHomeCardAction } from '../../models/mobileHomeCard/MobileHomeCardAction';
import { MobileHomeCardActionComponent } from '../../components/common/MobileHomeCardActionComponent';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { pushMobileHomeCardAction, pushMobileHomeCardContent } from '../../actions/experienceEditViewActions';
import { isNullOrUndefined } from 'util';
import { ExternalLink } from '../../components/common/LinkComponents';
import { extractMobileHomeCardAction, extractMobileHomeCardConstraint, extractMobileHomeCardContent } from '../../util/mobileHomeCardHelper';
import { IMobileHomeCardConstraint } from '../../models/mobileHomeCard/MobileHomeCardConstraint';
import { IMobileHomeCardContent } from '../../models/mobileHomeCard/MobileHomeCardContent';

interface IMobileHomeCardActionSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
    onCardActionAndCharLimitCheckedUpdate?: (cardActionChecked?: boolean, charLimitChecked?: boolean) => void;
}

interface IMobileHomeCardActionSectionViewState {
    mobileHomeCardActions?: IMobileHomeCardAction[];
}

export class MobileHomeCardActionSectionView extends React.Component<
    IMobileHomeCardActionSectionViewProps,
    IMobileHomeCardActionSectionViewState> {

    constructor(props: IMobileHomeCardActionSectionViewProps) {
        super(props);
        const { updateCandidate } = this.props;
        this.state = {
            mobileHomeCardActions: updateCandidate?.getMobileHomeCardActions(),
        };
    }

    componentDidMount() {
        const { experience } = this.props;
        const mobileHomeCardContent = experience && experience.contents?.[0];
        if (experience) {
            this.setState({ mobileHomeCardActions: mobileHomeCardContent?.cardAction });
        }

    }

    updateMobileCardActions(mobileHomeCardActions?: IMobileHomeCardAction[]) {
        const { dispatch } = this.props;
        dispatch(pushMobileHomeCardAction(mobileHomeCardActions));
        this.setState({ mobileHomeCardActions });
    }

    disableNextButton(cardActionConfirmation?: boolean, charLimitChecked?: boolean, onCardActionAndCharLimitCheckedUpdate?: (cardActionChecked?: boolean, charLimitChecked?: boolean) => void) {
        const { dispatch } = this.props;

        if (onCardActionAndCharLimitCheckedUpdate) {
            onCardActionAndCharLimitCheckedUpdate(cardActionConfirmation, charLimitChecked);
        }

        dispatch(pushMobileHomeCardContent({ cardActionConfirmation }));

    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate, onCardActionAndCharLimitCheckedUpdate } = this.props;

        if (!experience) {
            return <FormSectionView title='Card Action'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const mobileHomeCardActions: IMobileHomeCardAction[] = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
            ? updateCandidate.getMobileHomeCardActions() || [EMPTY_MOBILE_CARD_ACTION]
            : extractMobileHomeCardAction(experience) || [EMPTY_MOBILE_CARD_ACTION]);

        const isChecked: boolean | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getMobileHomeCardContent()?.cardActionConfirmation
            : extractMobileHomeCardContent(experience)?.cardActionConfirmation;

        const charLimitExceed: boolean | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getMobileHomeCardContent()?.characterLimitExceed
            : extractMobileHomeCardContent(experience)?.characterLimitExceed;

        const mobileHomeCardConstraint: IMobileHomeCardConstraint | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getMobileHomeCardConstraint()
            : extractMobileHomeCardConstraint(experience);

        const mobileHomeCardContent: IMobileHomeCardContent | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getMobileHomeCardContent()
            : extractMobileHomeCardContent(experience);

        const shouldDisableInput = !isEditing || isUpdating;

        const shouldShowDeepLinkDoc = mobileHomeCardActions && mobileHomeCardActions.length > 0 && mobileHomeCardActions[0]?.actionType === 'DeepLinkAction';

        return <FormSectionView title='Card Action' description='Action taken after tap. User can be directed to website,
        destination within app, or phone settings, etc. Select template first.'>

            {shouldShowDeepLinkDoc ?
                <div>
                    {isNullOrUndefined(experience.id)
                        ? <>
                            <Alert type='info' className='awsui-util-mb-l'>
                                Please read the <ExternalLink href={'https://wiki.labcollab.net/confluence/display/ALEXAAPP/Legal+Guidance+for+external+weblinks'}
                                >DeepLink  Guidelines and Recommendations</ExternalLink> documentation.
                                <Checkbox
                                    id={'experience.confirm-card-action'}
                                    controlId={'experience.confirm-card-action'}
                                    checked={isChecked}
                                    onChange={(e) => {
                                        if (onCardActionAndCharLimitCheckedUpdate) {
                                            this.disableNextButton(e.detail.checked, charLimitExceed, onCardActionAndCharLimitCheckedUpdate);
                                        }
                                    }}
                                ><b>I confirm that I have read and will follow the DeepLink  Guidelines and Recommendations</b></Checkbox>
                                <div><div><span className='awsui-util-status-negative'><Icon name='status-warning' />  This box must be checked</span></div><span /></div>
                            </Alert>
                        </>
                        : <>
                            <Alert type='info' visible={isEditing} className='awsui-util-mb-l'>
                                Please read the <ExternalLink href={'https://wiki.labcollab.net/confluence/display/ALEXAAPP/Legal+Guidance+for+external+weblinks'}
                                >DeepLink  Guidelines and Recommendations</ExternalLink> documentation.
                            </Alert>
                        </>}
                </div>

                :
                <div>
                    {isNullOrUndefined(experience.id)
                        ? <>
                            <Alert type='info' className='awsui-util-mb-l'>
                                <Checkbox
                                    id={'experience.confirm-card-action'}
                                    controlId={'experience.confirm-card-action'}
                                    checked={isChecked}
                                    onChange={(e) => {
                                        this.disableNextButton(e.detail.checked, charLimitExceed, onCardActionAndCharLimitCheckedUpdate);
                                    }}
                                ><b>I confirm that this link (and the page it ingresses to) has been verified in all regions the card is launching to.</b></Checkbox>
                                <div><div><span className='awsui-util-status-negative'><Icon name='status-warning' />  This box must be checked</span></div><span /></div>
                            </Alert>
                        </>
                        : <>
                            <Alert type='info' visible={isEditing} className='awsui-util-mb-l'>
                                I confirm that this link (and the page it ingresses to) has been verified in all regions the card is launching to.
                            </Alert>
                        </>}
                </div>
            }
            <br></br>
            {mobileHomeCardActions && mobileHomeCardActions.length > 0 &&
                mobileHomeCardActions.map((mobileCardAction, index) =>
                    <div className='awsui-grid' key={index}>
                        <MobileHomeCardActionComponent
                            index={index}
                            mobileCardActions={mobileHomeCardActions}
                            shouldDisableInput={shouldDisableInput}
                            mobileHomeCardContent={mobileHomeCardContent}
                            isEditing={isEditing}
                            updateMobileCardActions={(mobileCardActionsCopy: IMobileHomeCardAction[]) => {
                                this.updateMobileCardActions(mobileCardActionsCopy);
                            }}
                            charLimitExceed={charLimitExceed}
                            mobileHomeCardConstraint={mobileHomeCardConstraint}
                            disableNextButton={(cardActionChecked, charLimitChecked, CardActionAndCharLimitCheckedUpdate) => {
                                this.disableNextButton(cardActionChecked, charLimitChecked, CardActionAndCharLimitCheckedUpdate);
                            }}
                            onCardActionAndCharLimitCheckedUpdate={onCardActionAndCharLimitCheckedUpdate}
                        ></MobileHomeCardActionComponent>
                    </div>)
            }
        </FormSectionView>;
    }
}

const mapStateToProps = ({ odysseyExperienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: odysseyExperienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(MobileHomeCardActionSectionView);
