import * as React from 'react';
import { Textarea } from '@amzn/awsui-components-react';

export interface ITextareaWithCharLimitProps extends Textarea.Props {
    characterLimit: number;
}

const TextareaWithCharLimit: React.FC<ITextareaWithCharLimitProps> = (props: ITextareaWithCharLimitProps) => {
    const { characterLimit } = props;
    const [currentValue, setValue] = React.useState(props.value || '');

    const onInputEvent = (e: CustomEvent<Textarea.ChangeDetail>) => {
        setValue(e.detail.value);
        if (props.onInput) {
            props.onInput(e);
        }
    };

    return <div>
        <Textarea {...props}
            onInput={onInputEvent}
            value={currentValue}
            invalid={(currentValue.length > characterLimit) || (props.invalid || false)}>
        </Textarea>
        <small>{currentValue.length} of {characterLimit} characters max</small>
    </div>;
};

export default TextareaWithCharLimit;
