import { AnyAction, Dispatch } from 'redux';
import { IBypassPauseList } from '../models/bypassPauseList/BypassPauseList';
import { getBypassPauseList, putBypassPauseList } from '../api/bypassPauseListApi';
import {
    LOADED_BYPASS_PAUSE_LIST,
    LOADING_BYPASS_PAUSE_LIST,
    ERROR_LOADING_BYPASS_PAUSE_LIST,
    UPDATED_BYPASS_PAUSE_LIST,
    UPDATING_BYPASS_PAUSE_LIST,
    ERROR_UPDATING_BYPASS_PAUSE_LIST
} from '../constants/bypassPauseListActionTypes';

export const updatingBypassPauseList: AnyAction = {
    type: UPDATING_BYPASS_PAUSE_LIST
};

export const updatedBypassPauseList: AnyAction = {
    type: UPDATED_BYPASS_PAUSE_LIST
};

export const loadingBypassPauseList: AnyAction = {
    type: LOADING_BYPASS_PAUSE_LIST
};

export const loadedBypassPauseList = (bypassPauseList?: IBypassPauseList): AnyAction => {
    return {
        type: LOADED_BYPASS_PAUSE_LIST,
        bypassPauseList
    };
};

export const errorLoadingBypassPauseList = (error: Error): AnyAction => {
    return {
        type: ERROR_LOADING_BYPASS_PAUSE_LIST,
        error
    };
};

export const errorUpdatingBypassPauseList = (error: Error): AnyAction => {
    return {
        type: ERROR_UPDATING_BYPASS_PAUSE_LIST,
        error
    };
};

export const updateBypassPauseListAction = (bypassPauseList: IBypassPauseList) => async (dispatch: Dispatch) => {
    dispatch(updatingBypassPauseList);

    try {
        await putBypassPauseList(bypassPauseList);
        dispatch(updatedBypassPauseList);
    } catch (error) {
        dispatch(errorUpdatingBypassPauseList(error));
    }
};

export const getBypassPauseListAction = () => async (dispatch: Dispatch) => {
    dispatch(loadingBypassPauseList);

    try {
        const bypassPauseList = await getBypassPauseList();
        dispatch(loadedBypassPauseList(bypassPauseList));
    } catch (error) {
        dispatch(errorLoadingBypassPauseList(error));
    }
};
