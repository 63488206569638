export interface ITargetPublisherData {
    key: string;
    values: string[];
}

export const EMPTY_TARGET_PUBLISHER_DATA = {
    key: '',
    values: []
};

export const processTargetPublisherData = (targetPublisherData?: ITargetPublisherData[])
    : ITargetPublisherData[] | undefined => {

    if (targetPublisherData == null || targetPublisherData === undefined || targetPublisherData.length === 0) {
        return undefined;
    }

    const targetPublisherDataMap: {[key: string]: string[]} = {};

    targetPublisherData.filter(datum => datum.key !== '')
        .forEach((datum) => {
        if (Object.keys(targetPublisherDataMap).includes(datum.key)) {
            targetPublisherDataMap[datum.key] = targetPublisherDataMap[datum.key].concat(datum.values);
        } else {
            targetPublisherDataMap[datum.key] = datum.values;
        }

        targetPublisherDataMap[datum.key] = Array.from(new Set(targetPublisherDataMap[datum.key]));
    });

    return Object.keys(targetPublisherDataMap).map((key) => {
        return {key, values: targetPublisherDataMap[key]} as ITargetPublisherData;
    });
};
