import { Stage, Region } from '../util/context';

export const BULLSEYE_ENDPOINTS_MAP: { [S in Stage]: {[R in Region]: string} } = {
    Alpha: {
        NA: 'https://bullseye2-devo.integ.amazon.com/segment?id=',
        EU: 'https://bullseye2-devo.integ.amazon.com/segment?id=',
        FE: 'https://bullseye2-devo.integ.amazon.com/segment?id=',
        CN: 'https://bullseye2-devo.integ.amazon.com/segment?id='
    },
    Beta: {
        NA: 'https://bullseye2-devo.integ.amazon.com/segment?id=',
        EU: 'https://bullseye2-devo.integ.amazon.com/segment?id=',
        FE: 'https://bullseye2-devo.integ.amazon.com/segment?id=',
        CN: 'https://bullseye2-devo.integ.amazon.com/segment?id='
    },
    Gamma: {
        NA: 'https://bullseye2-na.amazon.com/segment?id=',
        EU: 'https://bullseye2-eu.amazon.com/segment?id=',
        FE: 'https://bullseye2-fe.amazon.com/segment?id=',
        CN: 'https://bullseye2-cn.amazon.com/segment?id='
    },
    Prod: {
        NA: 'https://bullseye2-na.amazon.com/segment?id=',
        EU: 'https://bullseye2-eu.amazon.com/segment?id=',
        FE: 'https://bullseye2-fe.amazon.com/segment?id=',
        CN: 'https://bullseye2-cn.amazon.com/segment?id='
    }
};
