import { API_ENDPOINT, GetSuggestionsPath, UpdateSuggestionsStatusPath, GetSuggestionsPaginatePath } from '../constants/lambdaEndpoints';
import { IHomeCardSuggestion, ISuggestionPage } from '../models/HomeCardSuggestion';
import { appendParamIfPresent } from './acopsGateway';
import { axios } from './axios';

export async function getSuggestions(
    ): Promise<IHomeCardSuggestion[]> {
        const searchParams = new URLSearchParams();
        appendParamIfPresent(searchParams, 'providerId',  undefined);

        const response = await axios.get(`${API_ENDPOINT}${GetSuggestionsPath}?${searchParams.toString()}`);

        return (response.data);
    }

export async function getSuggestionsPaginate(limit: number, exclusiveStartKey?: string): Promise<ISuggestionPage> {
        const searchParams = new URLSearchParams();
        appendParamIfPresent(searchParams, 'providerId', undefined);
        appendParamIfPresent(searchParams, 'limit', limit.toString());
        appendParamIfPresent(searchParams, 'exclusiveStartKey', exclusiveStartKey);

        const response = await axios.get(`${API_ENDPOINT}${GetSuggestionsPaginatePath}?${searchParams.toString()}`);

        return (response.data);
    }

export async function updateSuggestionStatus(
    suggestionId: string,
    suggestionStatus: string,
    rejectionString?: string,
): Promise<string> {
    const searchParams = new URLSearchParams();
    appendParamIfPresent(searchParams, 'id', suggestionId);
    appendParamIfPresent(searchParams, 'status', suggestionStatus);
    appendParamIfPresent(searchParams, 'rejectionString',  rejectionString);

    try {
        const response = await axios.post(
            `${API_ENDPOINT}${UpdateSuggestionsStatusPath}?${searchParams.toString()}`);
        return response.data;  // suggestion ID
    } catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data);
        } else {
            throw error;
        }
    }
}

export async function getSuggestion(id: string): Promise<IHomeCardSuggestion>  {
    const response = await axios.get(`${API_ENDPOINT}${GetSuggestionsPath}/${id}`);
    return response.data;
}
