import * as React from 'react';
import { Component } from 'react';
import { FormField, Input, Button, Form, Checkbox, Tooltip } from '@amzn/awsui-components-react';
import { connect } from 'react-redux';
import { sendPushNotificationAction, errorSendingNotification } from '../actions/pushNotificationActions';
import { AppState } from '../reducers/index';
import { IFlattenedExperience } from '../models/FlattenedExperience';

interface ITestPushNotificationProps {
    dispatch: any;
    isLoading: boolean;
    isSending: boolean;
    result?: string;
    error?: Error;
    experience?: IFlattenedExperience;
    isEditing: boolean;
    isUpdating: boolean;
}

class TestPushNotification extends Component<ITestPushNotificationProps, { cid: string; checked: boolean }> {
    constructor(props: ITestPushNotificationProps) {
        super(props);
        this.state = {
            cid: '',
            checked: false
        };
    }

    sendPushNotification() {
        const { dispatch, experience } = this.props;
        const { cid } = this.state;

        if (experience && experience.locale) {
            const filterKey = Object.keys(experience.filters);
            const deviceTypeList = (experience && experience.filters && experience.filters[filterKey[0]] && experience.filters[filterKey[0]].deviceTypes) || [];
            dispatch(sendPushNotificationAction(cid, experience.id, experience.locale, deviceTypeList));
        } else {
            dispatch(errorSendingNotification(new Error('Something went wrong')));
        }
    }

    updateCid(cid: string) {
        this.setState(prevState => {
            return {
                ...prevState,
                cid
            };
        });
    }

    updateVerification(checked: boolean) {
        this.setState(prevState => {
            return {
                ...prevState,
                checked
            };
        });
    }

    render() {
        const { isLoading, isSending, error, result, isEditing, isUpdating, experience } = this.props;
        const { cid, checked } = this.state;

        const isExperienceInDraft = experience ? experience.status === 'DRAFT' : false;

        const shouldDisableTestingButton =
            isLoading       // when experience hasn't finished loading
            || isExperienceInDraft // when experience is in DRAFT
            || isEditing    // when user is editing
            || isUpdating  // when the experience is actively being updated
            || isSending // when notification is being sent
            || !cid.length  // when cid length <= 0
            || !checked;    // when user hasn't agreed to the verification

        const tooltipString = shouldDisableTestingButton
            ? (isExperienceInDraft
                ? 'Only experiences in a testable state can send test notifications'
                : (isEditing
                    ? 'You must save the experience first in order to send a test notification'
                    : 'Make sure you fill out the form'))
            : 'Click to send a test notification';

        return <div>
            <Form>
                <FormField label='Customer ID' description='Send a quick push notification for visualized testing'>
                    <Input
                        id='testing.customer-id'
                        controlId='testing.customer-id'
                        placeholder='Your CID here'
                        name='cid-input'
                        onChange={e => this.updateCid(e.detail.value)}
                    />
                </FormField>
                <div className='awsui-util-mt-xl awsui-util-mb-xl'>
                    <Checkbox
                        id='testing.confirmation-checkbox'
                        controlId='testing.confirmation-checkbox'
                        onChange={e => this.updateVerification(e.detail.checked)}>
                        I have verified this customer ID is associated with a test account and test device
                    </Checkbox>
                </div>
                <Tooltip text={tooltipString} position='right'>
                    <Button
                        id='button.send-test-notification'
                        icon='caret-right-filled'
                        onClick={this.sendPushNotification.bind(this)}
                        disabled={shouldDisableTestingButton}
                    >
                        Test experience
                    </Button>
                </Tooltip>
                {error && <p className='awsui-util-status-negative'>Failed sending test notification</p>}
                {result && <p className='awsui-util-status-positive'>Notification sent successfully</p>}
            </Form>
        </div>;
    }
}

const mapStateToProps = ({
    pushNotificationState,
    experienceDetailViewState,
    experienceEditViewState
}: AppState) => {
    return {
        isSending: pushNotificationState.isLoading,
        result: pushNotificationState.result || undefined,
        error: pushNotificationState.error || undefined,
        experience: experienceDetailViewState.experience || undefined,
        isLoading: experienceDetailViewState.isLoading,
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating
    };
};

export default connect(mapStateToProps)(TestPushNotification);
