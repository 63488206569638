import React from 'react';
import { TimeInput } from '@amzn/awsui-components-react';
import { ITimeRange } from '../../models/TimeRange';

export interface ITimeInputWrapperProps {
    id: string;
    value?: string;
    dayId: number;
    disabled: boolean;
    timeRanges?: {[key: number]: ITimeRange[]};
    onChange: (event: CustomEvent<TimeInput.ChangeDetail>) => void;
    validate: (timeRanges: {[key: number]: ITimeRange[]}, dayId: number, disabled: boolean) => void;
}

export class TimeInputWrapper extends React.Component<ITimeInputWrapperProps> {
    render() {
        const {id, value, dayId, disabled, timeRanges, validate, onChange} = this.props;
        let errorMessage = '';
        let isValid: boolean = true;
        if (!disabled) {
            try {
                validate(timeRanges || {}, dayId, disabled);
                isValid= true;
            } catch (error) {
                isValid = false;
                errorMessage = error.message;
            }
        }
        return <div>
            <TimeInput
                controlId={id}
                id={id}
                format='hh:mm'
                placeholder='HH:MM'
                value={value}
                onChange={onChange}
                disabled={disabled}
                invalid={!isValid}
            />
            <div>
                {errorMessage && <p className='awsui-util-status-negative'>{errorMessage}</p>}
            </div>
        </div>;
    }
}
