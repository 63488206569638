
export interface IEventTrail {
    value: string;
    username: string;
    type: string;
    eventSource: string;
    experienceId: string;
    timeEpochSeconds: number;
}

interface PaginationContext {
    nextToken: string;
}

export interface EventTrailResponse {
    pagination: PaginationContext;
    results: IEventTrail[];
}

export function toEventTrail(event: EventTrailResponse): IEventTrail[] {
    const eventExists: boolean = (event !== undefined && event !== null) ? true : false;
    if (eventExists && event.results.length > 0) {
        return event.results.map(item => {
            return {
                value: item.value,
                username: item.username,
                type: item.type,
                eventSource: item.eventSource,
                experienceId: item.experienceId,
                timeEpochSeconds: Number(item.timeEpochSeconds || '0')
            };
        }).sort((a, b) => b.timeEpochSeconds - a.timeEpochSeconds); // default to descending time sorting
    }
    return [];
}
