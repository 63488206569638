import { ICifData, IRawCifMetricsData } from './CifMetrics';
import { IExperience } from './Experience';

export interface ISimilarExperiencesData {
    similarity: number;
    experiment: IExperience;
    metrics: IRawCifMetricsData;
}

export interface IGuardRailMetrics {
    totalImpression?: number;
    distinctCustomers?: number;
    interruptionRate?: number;
    negativeFeedBack?: number;
}

export interface ISimilarExperiencesViewableData {
    experienceId: string;
    experienceTitle?: string;
    guardRailMetrics: IGuardRailMetrics;
}

// this will be used to process and fill the table with data
export const createSimilarExperiencesViewableData = (similarExperiencesData?: ISimilarExperiencesData[]): ISimilarExperiencesViewableData[] => {
    if (similarExperiencesData === undefined) {
        return [];
    }
    const displayEntries: ISimilarExperiencesViewableData[] = similarExperiencesData.map(data => {
        const cifMetrics: ICifData[] = data.metrics.metrics;
        let totalImpressionCount: number = 0;
        let distinctCustomersCount: number = 0;
        let interruptionRateCount: number = 0;
        let negativeFeedBackCount: number = 0;
        let totalImpression: number = 0;
        let distinctCustomers: number = 0;
        let interruptionRate: number = 0;
        let negativeFeedBack: number = 0;
        cifMetrics.forEach((entry: ICifData) => {
            totalImpressionCount += entry.data.total_impression !== undefined ? 1 : 0;
            distinctCustomersCount += entry.data.distinct_customer !== undefined ? 1 : 0;
            interruptionRateCount += entry.data.interruption_rate !== undefined ? 1 : 0;
            negativeFeedBackCount += entry.data.negative_feedback !== undefined ? 1 : 0;
            totalImpression += entry.data.total_impression !== undefined ? entry.data.total_impression : 0;
            distinctCustomers += entry.data.distinct_customer !== undefined ? entry.data.distinct_customer : 0;
            interruptionRate += entry.data.interruption_rate !== undefined ? entry.data.interruption_rate : 0;
            negativeFeedBack += entry.data.negative_feedback !== undefined ? entry.data.negative_feedback : 0;
        });

        const guardRailMetricsEntry: IGuardRailMetrics = {
            totalImpression: totalImpressionCount === 0 ? undefined : Math.round(totalImpression / totalImpressionCount),
            distinctCustomers: distinctCustomersCount === 0 ? undefined : Math.round(distinctCustomers / distinctCustomersCount),
            interruptionRate: interruptionRateCount === 0 ? undefined : interruptionRate / interruptionRateCount,
            negativeFeedBack: negativeFeedBackCount === 0 ? undefined : negativeFeedBack / negativeFeedBackCount
        };

        const displayEntry: ISimilarExperiencesViewableData = {
            experienceId: data.experiment.id,
            experienceTitle: data.experiment.title,
            guardRailMetrics: guardRailMetricsEntry
        };
        return displayEntry;
    });

    return displayEntries;
};