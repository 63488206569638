export const ENABLE_EDITING = 'ENABLE_EDITING';
export const ENABLE_CLONING = 'ENABLE_CLONING';
export const ENABLE_CREATING = 'ENABLE_CREATING';
export const UPDATING_EXPERIENCE = 'UPDATING_EXPERIENCE';
export const DISABLING_SAVE_BUTTON = 'DISABLING_SAVE_BUTTON';
export const UPDATED_EXPERIENCE = 'UPDATED_EXPERIENCE';
export const DELETING_EXPERIENCE = 'DELETING_EXPERIENCE';
export const DELETED_EXPERIENCE = 'DELETED_EXPERIENCE';
export const ERROR_UPDATING_EXPERIENCE = 'ERROR_UPDATING_EXPERIENCE';
export const CANCEL_EDITING = 'CANCEL_EDITING';
export const PUSH_REGION = 'PUSH_REGION';
export const PUSH_ROW_COUNTRY_INFO = 'PUSH_ROW_COUNTRY_INFO';
export const PUSH_DAYTIME_WINDOW = 'PUSH_DAYTIME_WINDOW';
export const PUSH_TYPE = 'PUSH_TYPE';
export const PUSH_STATUS = 'PUSH_STATUS';
export const PUSH_METADATA = 'PUSH_METADATA';
export const PUSH_BASIC_INFO = 'PUSH_BASIC_INFO';
export const PUSH_NOTIFICATION_GUARDRAILS = 'PUSH_NOTIFICATION_GUARDRAILS';
export const PUSH_SUGGESTED_FEATURE = 'PUSH_SUGGESTED_FEATURE';
export const PUSH_IMPRESSION_TRACKING = 'PUSH_IMPRESSION_TRACKING';
export const PUSH_PROMOTED_CAPABILITY = 'PUSH_PROMOTED_CAPABILITY';
export const PUSH_BYPASS_CAPABILITY_FILTER = 'PUSH_BYPASS_CAPABILITY_FILTER';
export const PUSH_BULLSEYE = 'PUSH_BULLSEYE';
export const PUSH_CONTENT = 'PUSH_CONTENT';
export const PUSH_CONTENT_VARIABLES = 'PUSH_CONTENT_VARIABLES';
export const PUSH_DEVICES = 'PUSH_DEVICES';
export const PUSH_DOMAIN_TAGS = 'PUSH_DOMAIN_TAGS';
export const PUSH_FEATURE_HINT = 'PUSH_FEATURE_HINT';
export const PUSH_OPPORTUNITY_INCLUSION = 'PUSH_OPPORTUNITY_INCLUSION';
export const PUSH_OPPORTUNITY_EXCLUSION = 'PUSH_OPPORTUNITY_EXCLUSION';
export const PUSH_NLU_OPPORTUNITY_INCLUSION = 'PUSH_NLU_OPPORTUNITY_INCLUSION';
export const PUSH_NLU_OPPORTUNITY_EXCLUSION = 'PUSH_NLU_OPPORTUNITY_EXCLUSION';
export const PUSH_OVERRIDE_ACCOUNTS = 'PUSH_OVERRIDE_ACCOUNTS';
export const PUSH_OVERRIDE_FILTERS = 'PUSH_OVERRIDE_FILTERS';
export const PUSH_REMOTE_TAGS = 'PUSH_REMOTE_TAGS';
export const PUSH_NOTIFICATION_CONTENT = 'PUSH_NOTIFICATION_CONTENT';
export const PUSH_DATA_SOURCE = 'PUSH_DATA_SOURCE';
export const PUSH_SPEAKER_DATA = 'PUSH_SPEAKER_DATA';
export const PUSH_VISUAL_INTERSTITIAL_VARIABLES = 'PUSH_VISUAL_INTERSTITIAL_VARIABLES';
export const PUSH_ENABLE_VOICE_CIF = 'PUSH_ENABLE_VOICE_CIF';
export const PUSH_INCLUDING_ODYSSEY_FIELDS = 'PUSH_INCLUDING_ODYSSEY_FIELDS';
export const PUSH_EXPOSURE_CONTROL_RATE = 'PUSH_EXPOSURE_CONTROL_RATE';
export const PUSH_APPROVAL_INFO = 'PUSH_APPROVAL_INFO';
export const PUSH_BYOR_SNS_TOPIC_ARN = 'PUSH_BYOR_SNS_TOPIC_ARN';
export const PUSH_JOURNEY_METADATA = 'PUSH_JOURNEY_METADATA';
export const PUSH_BUSINESS_INFO = 'PUSH_BUSINESS_INFO';
export const PUSH_SUBSCRIPTION_UPSELL_TYPE = 'PUSH_SUBSCRIPTION_UPSELL_TYPE';
export const PUSH_USE_TOUCH_ACTION_TEMPLATE = 'PUSH_USE_TOUCH_ACTION_TEMPLATE';
export const PUSH_USE_NON_CTA_TEMPLATE = 'PUSH_USE_NON_CTA_TEMPLATE';
export const PUSH_CUSTOM_DOMAIN_ILLUSTRATION_SOURCE = 'PUSH_CUSTOM_DOMAIN_ILLUSTRATION_SOURCE';
export const PUSH_OVERRIDE_DEFAULT_BACKGROUND_IMAGE = 'PUSH_OVERRIDE_DEFAULT_BACKGROUND_IMAGE';
export const PUSH_ACKNOWLEDGE_BACKGROUND_IMAGE = 'PUSH_ACKNOWLEDGE_BACKGROUND_IMAGE';

// mobilehomeCard
export const PUSH_MOBILE_HOME_CARD_ACTION = 'PUSH_MOBILE_HOME_CARD_ACTION';
export const PUSH_MOBILE_HOME_CARD_CONSTRAINT = 'PUSH_MOBILE_HOME_CARD_CONSTRAINT';
export const PUSH_MOBILE_HOME_CARD_AB_EXPERIMENT = 'PUSH_MOBILE_HOME_CARD_AB_EXPERIMENT';
export const PUSH_MOBILE_HOME_CARD_CONTENT = 'PUSH_MOBILE_HOME_CARD_CONTENT';
export const PUSH_MOBILE_HOME_CARD_TARGETING = 'PUSH_MOBILE_HOME_CARD_TARGETING';
export const PUSH_MOBILE_HOME_CARD_CONTEXT = 'PUSH_MOBILE_HOME_CARD_CONTEXT';

// RCP
export const PUSH_ROTATING_CONTENT_PANEL_DISPLAY_ELEMENTS = 'PUSH_ROTATING_CONTENT_PANEL_DISPLAY_ELEMENTS';
export const PUSH_ROTATING_CONTENT_PANEL_TOUCH_ACTIONS = 'PUSH_ROTATING_CONTENT_PANEL_TOUCH_ACTIONS';
export const PUSH_ROTATING_CONTENT_PANEL_OVERRIDE_ACCOUNTS = 'PUSH_ROTATING_CONTENT_PANEL_OVERRIDE_ACCOUNTS';
export const PUSH_ROTATING_CONTENT_PANEL_DEVICES = 'PUSH_ROTATING_CONTENT_PANEL_DEVICES';
export const PUSH_ROTATING_CONTENT_PANEL_METADATA = 'PUSH_ROTATING_CONTENT_PANEL_METADATA';
export const PUSH_ROTATING_CONTENT_PANEL_BUSINESS_INFO = 'PUSH_ROTATING_CONTENT_PANEL_BUSINESS_INFO';
export const PUSH_ROTATING_CONTENT_PANEL_CONTEXT = 'PUSH_ROTATING_CONTENT_PANEL_CONTEXT';
export const PUSH_ROTATING_CONTENT_PANEL_ACTIVATION_TIME = 'PUSH_ROTATING_CONTENT_PANEL_ACTIVATION_TIME';
