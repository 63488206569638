import * as React from 'react';
import { IProviderConfig, IVersionedObject } from '../../models/spiProviderConfig/ISpiProviderOnboarding';
import { Table, TableSorting } from '@amzn/awsui-components-react';
import { getLinkableUrlByProviderIdAndVersion } from '../page';
import { defaultStringComparator } from '../tableColumnConfiguration';

export const defaultDateComparator = (a?: Date, b?: Date) => {
    if (a && b) {
        return a.getTime() - b.getTime();
    }

    return !a && !b ? 0 : (!a ? -1 : 1);
};

interface IColumnConfig {
    id: string;
    header: string;
    width: number;
    minWidth: string;
    renderingFunction: (item: IVersionedObject<IProviderConfig>,) => React.ReactNode;
    sortingComparator: (a: IVersionedObject<IProviderConfig>, b: IVersionedObject<IProviderConfig>) => number;
}

const versionIdColumnConfig: IColumnConfig = {
    id: 'versionId',
    header: 'Version Id',
    width: 350,
    minWidth: '350px',
    renderingFunction: item => {
        const [providerId] = item.providerId.split('/');
        return <a href={getLinkableUrlByProviderIdAndVersion(providerId, item.versionId)}>{item.versionId || '-'}</a>;
    },
    sortingComparator: (a, b) => defaultStringComparator(a.versionId, b.versionId)
};

const modifiedDateColumnConfig: IColumnConfig = {
    id: 'modifiedDate',
    header: 'Modified Date',
    width: 250,
    minWidth: '250px',
    renderingFunction: item => {
        const lastModifiedDate = new Date(item.lastModified);
        return lastModifiedDate.toLocaleString();
      },
    sortingComparator: (a, b) => defaultDateComparator(new Date (a.lastModified), new Date (b.lastModified))
};


const columnConfigs: IColumnConfig[] = [
    versionIdColumnConfig,
    modifiedDateColumnConfig
];

export const COLUMN_DEFINITIONS: Table.ColumnDefinition<IVersionedObject<IProviderConfig>>[] = columnConfigs.map(
    (config: IColumnConfig) => {
        return {
            id: config.id,
            header: config.header,
            minWidth: config.minWidth,
            cell: config.renderingFunction
        };
    });


export const SORTABLE_COLUMNS: TableSorting.SortableColumn<IVersionedObject<IProviderConfig>>[] = columnConfigs.map(
    (config: IColumnConfig) => {
        return {
            id: config.id,
            field: config.id,
            comparator: config.sortingComparator
        };
    });