import React from 'react';
import { Icon, Input } from '@amzn/awsui-components-react';
import { TextRenderDirection } from '../../models/htmlElementProps';

export interface IAsyncInputWrapperProps {
    id: string;
    value?: string;
    readonly: boolean;
    placeholder?: string;
    textRenderDirection?: TextRenderDirection;
    onSuccessText?: string;
    skipValidationOnEmpty?: boolean;
    validateOnReadonly?: boolean;
    validate: (input?: string) => Promise<void>;
    onInput?: (inputString: string) => void;
    onChange?: (inputString: string) => void;
}

export enum AsyncInputWrapperValidationStage {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    FAILED = 'FAILED',
    SUCCEEDED = 'SUCCEEDED'
}

export interface IAsyncInputWrapperState {
    stage: AsyncInputWrapperValidationStage;
    errorMessage: string;
}

export class AsyncInputWrapper extends React.Component<IAsyncInputWrapperProps, IAsyncInputWrapperState> {

    private mounted: boolean;

    constructor(props : IAsyncInputWrapperProps, state : IAsyncInputWrapperState) {
        super(props);
        this.state = {
            stage: AsyncInputWrapperValidationStage.NOT_STARTED,
            errorMessage: '',
        };
        this.mounted = false;
    }

    componentDidMount(): void {
        const { value, validate, readonly, validateOnReadonly } = this.props;
        this.mounted = true;
        if (!readonly || validateOnReadonly) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    stage: AsyncInputWrapperValidationStage.IN_PROGRESS
                };
            });
            validate(value)
            .then(resolved => {
                if (this.mounted) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            stage: AsyncInputWrapperValidationStage.SUCCEEDED
                        };
                    });
                }
            }).catch(rejected => {
                if (this.mounted) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            stage: AsyncInputWrapperValidationStage.FAILED,
                            errorMessage: rejected.message
                        };
                    });
                }
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<IAsyncInputWrapperProps>, prevState: Readonly<IAsyncInputWrapperState>, snapshot?: any): void {
        if (this.props.value !== prevProps.value)
            this.componentDidMount();
    }

    componentWillUnmount(): void {
        this.setState(prevState => {
            return {
                ...prevState,
                stage: AsyncInputWrapperValidationStage.NOT_STARTED
            };
        });
        this.mounted = false;
    }

    render () {
        const {
            id, value, readonly, textRenderDirection,
            placeholder, onInput, onChange, onSuccessText,
            skipValidationOnEmpty
        } = this.props;

        const { errorMessage, stage } = this.state;

        const RightToLeftRenderedInput = <input
            dir='rtl'
            style={{ direction: 'rtl' }}
            id={id}
            readOnly={readonly}
            value={value}
            placeholder={placeholder}
            className={`awsui-input ${readonly ? 'awsui-input-readonly' : ''} awsui-input-${stage === AsyncInputWrapperValidationStage.FAILED  ? 'in' : ''}valid awsui-input-type-text`}
            type='text'
            aria-describedby={id}
            onInput={(event: React.FormEvent<HTMLInputElement>) => {
                if (onInput) {
                    onInput((event.target as HTMLInputElement).value);
                }
            }}
            onChange={(event: React.FormEvent<HTMLInputElement>) => {
                if (onChange) {
                    onChange((event.target as HTMLInputElement).value);
                }
            }}
        />;

        const LeftToRightRenderedInput = <Input
            controlId={id}
            id={id}
            readonly={readonly}
            value={value}
            placeholder={placeholder}
            invalid={stage === AsyncInputWrapperValidationStage.FAILED}
            onInput={(event: CustomEvent<Input.ChangeDetail>) => {
                if (onInput) {
                    onInput(event.detail.value);
                }
            }}
            onChange={(event: CustomEvent<Input.ChangeDetail>) => {
                if (onChange) {
                    onChange(event.detail.value);
                }
            }} />;

        return <div>
            {textRenderDirection === 'rtl' ? RightToLeftRenderedInput : LeftToRightRenderedInput}

            <div className='awsui-grid'>
                <div className='awsui-row'>
                    <div className={'col-12'}>
                        {stage === AsyncInputWrapperValidationStage.IN_PROGRESS && <em>{'Validating...'}</em>}
                        {stage === AsyncInputWrapperValidationStage.FAILED && <p className='awsui-util-status-negative'>{errorMessage}</p>}
                    </div>
                </div>
                <div>
                {stage === AsyncInputWrapperValidationStage.SUCCEEDED && !(skipValidationOnEmpty && !value) &&
                    <span className='awsui-util-status-positive'><Icon name='status-positive'/>&nbsp;{onSuccessText}</span>
                }
                </div>
            </div>
        </div>;
    }
}
