import { API_ENDPOINT } from '../constants/lambdaEndpoints';
import { IBullseyeSegment } from '../models/BullseyeSegment';
import { Region } from '../util/context';
import { axios } from './axios';
import { BullseyePath } from '../constants/lambdaEndpoints';

export async function getBullseye(
    region: Region,
    segmentId: number
): Promise<IBullseyeSegment> {
    const response = await axios.get(`${API_ENDPOINT}${BullseyePath}/${segmentId}?region=${region}`);
    return response.data;
}
