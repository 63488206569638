import { IUpdateSuggestionAction } from '../actions/homeCardSuggestionsEditViewActions';
import { UPDATED_SUGGESTION, UPDATING_SUGGESTION, ERROR_UPDATING_SUGGESTION } from '../constants/homeCardSuggestionsEditViewActionTypes';

export interface ISuggestionEditViewState {
    isUpdating: boolean;
    id?: string;
    error?: Error;
}

export const homeCardSuggestionsEditViewReducer = (
    state: ISuggestionEditViewState = {
        isUpdating: false
    },
    action: IUpdateSuggestionAction
): ISuggestionEditViewState => {
    switch (action.type) {
        /* Actions related to putCifExperiment */
        case UPDATING_SUGGESTION:
            return {
                ...state,
                isUpdating: true
            };
        case UPDATED_SUGGESTION:
            // reducer is at top level. it should resolve error
            // and get it ready for rendering.
            if (!action.id) {
                return {
                    ...state,
                    isUpdating: false,
                    error: new Error('Updating `undefined` suggestion, something went wrong')
                };
            }
            return {
                ...state,
                isUpdating: false,
                id: action.id,
                error: undefined
            };
        case ERROR_UPDATING_SUGGESTION:
            return {
                ...state,
                isUpdating: false,
                error: action.error
            };
        default:
            return state;
    }
};
