import * as React from 'react';
import { Component } from 'react';

import { SideNavigation } from '@amzn/awsui-components-react';
import { connect } from 'react-redux';
import { getLinkableUrl, PAGE } from '../constants/page';

import { INavigationViewState } from '../reducers/navigationViewReducer';
import { IAuthenticationState } from '../reducers/authenticationReducer';


const PHONETOOL_IMG_CDN = 'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=';
const PHONETOOL_PAGE = 'https://phonetool.amazon.com/users/';
export const CIF_SETUP_WIKI = 'https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+Content+Injection+Framework';
export const PUSH_SETUP_WIKI = 'https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+Push+Notifications+Intake+Wiki';
export const DEVICE_SETUP_WIKI = 'https://wiki.labcollab.net/confluence/display/Doppler/Alexa+Odyssey#AlexaOdyssey-DeviceNotifications-UseCaseSetup';
export const ODYSSEY_LINK = 'https://wiki.labcollab.net/confluence/display/Doppler/Alexa+Odyssey';
export const EXPERIENCE_EXAMPLE_LINK = 'https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+Experience+-+Example+Configurations';
export const A4PC_SOP_LINK = 'https://wiki.labcollab.net/confluence/display/Doppler/Alexa+for+PC+Toast+Notification+SOP';
export const TROUBLESHOOTING_WIKI_LINK = 'https://w.amazon.com/bin/view/AlexaFeatureDiscovery/Debug/';
export const SURVEY_LINK = 'https://trawler.amazon.com/alexaodyssey-uifeedback';
export const MHC_SETUP_WIKI = 'https://wiki.labcollab.net/confluence/display/ALEXAAPP/Odyssey+Home+Cards+Onboarding+Guide';

interface INavigationProps {
    userAlias: string;
    permissions: string[];
    mhcPermissions: string[];
    rcpPermissions: string[];
    dispatch?: any;
}

class Navigation extends Component<INavigationProps> {

    private shouldRender(item: SideNavigation.Item | SideNavigation.Link | SideNavigation.Divider | SideNavigation.Section | SideNavigation.LinkGroup | SideNavigation.ExpandableLinkGroup) {
        const { permissions, mhcPermissions,rcpPermissions, userAlias } = this.props;
        if (item.type === 'link') {
            const link = item as SideNavigation.Link;
            if (link.text === '3P Home Card Suggestions') {
                return permissions.includes('ADMIN') || permissions.includes('OPERATOR');
            } else if (link.text === 'Browse MHC Experiences') {
                return mhcPermissions.includes('OPERATOR') || permissions.includes('ADMIN') || userAlias === 'svc-poems-test-user';
            } else if (link.text === 'Browse RCP Experiences') {
                return rcpPermissions.includes('ADMIN') || rcpPermissions.includes('OPERATOR');
            }
        }
        return true;
    }
    public render() {
        const { userAlias } = this.props;
        const allItems = [{
            type: 'link',
            text: 'Browse Experiences',
            href: getLinkableUrl(PAGE.Experiences)
        }, {
            type: 'link',
            text: 'Create Experience',
            href: getLinkableUrl(PAGE.NewExperience)
        }, {
            type: 'link',
            text: '3P Home Card Suggestions',
            href: getLinkableUrl(PAGE.HomeCardSuggestions)
        }, {
            type: 'link',
            text: 'Browse MHC Experiences',
            href: getLinkableUrl(PAGE.MhcExperiences)
        }, {
            type: 'link',
            text: 'Browse RCP Experiences',
            href: getLinkableUrl(PAGE.RcpExperiences)
        }, {
            type: 'section',
            text: 'Tools',
            items: [{
                type: 'link',
                text: 'AlexaLab',
                href: 'https://alexalab.aka.amazon.com',
                external: true
            }, {
                type: 'link',
                text: 'ASK Console',
                href: 'https://developer.amazon.com/alexa/console/ask?',
                external: true
            }, {
                type: 'link',
                text: 'Atocha',
                href: 'https://atochadp.amazon.com/alexa/advancedSearch?_operation=advancedSearch&_search=TimeWindow%3Dtoday%3A_result_size%3D10%3Aservice%3Dafdcps%3Aapi%3D*getContent%3AapolloStage%3DProd&_queryId=&_timezone=America/Los_Angeles',
                external: true
            }, {
                type: 'link',
                text: 'CIF Insights',
                href: 'https://skynet.db.amazon.com/#/views/CIFInsights360/3_1_ProviderbyContentType?:iid=1',
                external: true
            }, {
                type: 'link',
                text: 'Alexa Capabilities Catalog',
                href: 'https://console.harmony.a2z.com/acc-console/',
                external: true
            }, {
                type: 'link',
                text: 'NLU Console',
                href: 'https://nluconsole.aka.amazon.com/',
                external: true
            }, {
                type: 'link',
                text: 'Weblab',
                href: 'https://weblab.amazon.com/',
                external: true
            }]
        }, {
            type: 'divider'
        }, {
            type: 'link',
            text: '',
            href: `${PHONETOOL_PAGE}${userAlias}`,
            info: <div className='awsui-row'>
                <a href={`${PHONETOOL_PAGE}${userAlias}`}>
                    <img
                        className='col-md-3'
                        src={`${PHONETOOL_IMG_CDN}${userAlias}`}
                        alt='Profile Icon'
                        style={{
                            borderRadius: '50%',
                            width: '30px',
                            height: '30px',
                            objectFit: 'cover',
                            verticalAlign: 'middle'
                        }}
                    />
                </a>
                <span
                    className='col-md-9 awsui-util-label'
                    style={{ paddingLeft: '18px' }}
                >
                    {userAlias}
                </span>
            </div>
        }, {
            type: 'section',
            text: 'Documentation',
            items: [{
                type: 'link',
                text: 'Troubleshooting Guide',
                href: TROUBLESHOOTING_WIKI_LINK,
                external: true
            }, {
                type: 'link',
                text: 'Alexa Odyssey',
                external: true,
                href: ODYSSEY_LINK
            }, {
                type: 'link',
                text: 'Experience Examples',
                href: EXPERIENCE_EXAMPLE_LINK,
                external: true
            }]
        }, {
            type: 'section',
            text: 'How-to',
            items: [{
                type: 'link',
                text: 'CIF Experience Setup',
                href: CIF_SETUP_WIKI,
                external: true
            }, {
                type: 'link',
                text: 'App Notification Setup',
                href: PUSH_SETUP_WIKI,
                external: true
            }, {
                type: 'link',
                text: 'Mobile Home Card Setup',
                href: MHC_SETUP_WIKI,
                external: true
            }, {
                type: 'link',
                text: 'Alexa for PC Setup',
                href: A4PC_SOP_LINK,
                external: true
            }]
        }, {
            type: 'divider'
        }, {
            type: 'section',
            text: 'Having issues?',
            items: [{
                type: 'link',
                text: 'Technical Issues',
                href: 'https://wiki.labcollab.net/confluence/pages/viewpage.action?spaceKey=Doppler&title=Alexa+Feature+Awareness+-+Odyssey+Office+Hours',
                external: true
            }, {
                type: 'link',
                text: 'Help Questions',
                href: 'https://sim.amazon.com/issues/create?template=74cda653-2189-47cc-8e8e-37ffde272969',
                external: true
            }, {
                type: 'link',
                text: 'Approval Requests',
                href: 'https://sim.amazon.com/issues/create?template=ab9d11c4-8564-4c0d-a067-a007121b2a31',
                external: true
            }]
        }];

        const items = allItems.filter(item => this.shouldRender(item));
        return <SideNavigation
            className='awsui-util-side-navigation'
            activeHref={getLinkableUrl(PAGE.Home)}
            header={{
                text: 'ALEXA ODYSSEY',
                href: getLinkableUrl(PAGE.Home)
            }}
            items={items} />;
    }
}

const mapStateToProps = ({ navigationViewState, authenticationState }: { navigationViewState: INavigationViewState, authenticationState: IAuthenticationState }) => {
    return {
        userAlias: navigationViewState.userAlias || 'nobody',
        permissions: authenticationState.permissions || [],
        mhcPermissions: authenticationState.mhcPermissions || [],
        rcpPermissions: authenticationState.rcpPermissions || [],
    };
};

export default connect(mapStateToProps)(Navigation);
