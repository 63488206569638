import { Action, Dispatch } from 'redux';
import { getCapabilityCatalogs } from '../api/acopsGateway';
import { LOADING_CAPABILITY_CATALOGS, LOADED_CAPABILITY_CATALOGS, ERROR_LOADING_CAPABILITY_CATALOGS } from '../constants/capabilitySearchActionTypes';
import { IPromotedCapability } from '../models/ExperienceUpdateCandidate';

export interface ILoadCapabilityCatalogsAction extends Action {
    capabilities?: IPromotedCapability[];
    error?: Error;
}

export const loadingCapabilityCatalogs = (): ILoadCapabilityCatalogsAction => {
    return {
        type: LOADING_CAPABILITY_CATALOGS
    };
};

export const loadedCapabilityCatalogs = (capabilities: IPromotedCapability[] | undefined): ILoadCapabilityCatalogsAction => {
    return {
        type: LOADED_CAPABILITY_CATALOGS,
        capabilities
    };
};

export const errorCapabilityCatalogs = (error: Error): ILoadCapabilityCatalogsAction => {
    return {
        type: ERROR_LOADING_CAPABILITY_CATALOGS,
        error
    };
};

export const getCapabilityCatalogSearchAction = (
    searchTerm: string,
    marketplace: string,
    locale: string,
    pageSize?: number
) => async (dispatch: Dispatch) => {
    dispatch(loadingCapabilityCatalogs());

    try {
        // set default page size
        pageSize = pageSize === undefined ? 3 : pageSize;
        let capabilities: IPromotedCapability[] | undefined = await getCapabilityCatalogs(searchTerm, marketplace, locale, pageSize);
        if (capabilities !== undefined) {
            // if the result returns a capability with id that matches with the searchTerm, that is THE capability the user wants.
            if (capabilities.length > 0 && capabilities[0].capabilityId === searchTerm) {
                capabilities = capabilities.slice(0,1);
                dispatch(loadedCapabilityCatalogs(capabilities));
                return;
            }
            // search again using en_US as locale if applicable for wider matches
            if (locale !== 'en_US') {
                let defaultLocaleCapabilities: IPromotedCapability[] | undefined = await getCapabilityCatalogs(searchTerm, marketplace, 'en_US', pageSize);
                if (defaultLocaleCapabilities !== undefined) {
                    // if the result returns a capability with id that matches with the searchTerm, that is THE capability the user wants.
                    if (defaultLocaleCapabilities.length > 0 && defaultLocaleCapabilities[0].capabilityId === searchTerm) {
                        defaultLocaleCapabilities = defaultLocaleCapabilities.slice(0, 1);
                        dispatch(loadedCapabilityCatalogs(defaultLocaleCapabilities));
                        return;
                    }
                    // merge the two arrays
                    let combinedCapabilities: IPromotedCapability[] = [];
                    combinedCapabilities = combinedCapabilities.concat(capabilities);
                    combinedCapabilities = combinedCapabilities.concat(defaultLocaleCapabilities);
                    // remove duplicates and sort based on confidence score
                    combinedCapabilities = combinedCapabilities.filter((capability, index, self) =>
                        index === self.findIndex(element => element.capabilityId === capability.capabilityId)
                    )
                    .sort((capability1, capability2) => {
                        const score2 = capability2.confidenceScore === undefined ? 0 : capability2.confidenceScore;
                        const score1 = capability1.confidenceScore === undefined ? 0 : capability1.confidenceScore;
                        return score2 - score1;
                    });
                    // only returns pageSize number of results
                    combinedCapabilities = combinedCapabilities.slice(0, combinedCapabilities.length > pageSize ? pageSize : combinedCapabilities.length);
                    dispatch(loadedCapabilityCatalogs(combinedCapabilities));
                    return;
                }
            }
        }
        dispatch(loadedCapabilityCatalogs(capabilities));
    } catch (error) {
        dispatch(errorCapabilityCatalogs(error as Error));
    }
};