import { axios } from './axios';
import { API_ENDPOINT, BASE_PROVIDER_CONFIGS_PATH } from '../constants/lambdaEndpoints';
import { IProviderConfig, ISpiProviderConfigs, IVersionedObject } from '../models/spiProviderConfig/ISpiProviderOnboarding';

export async function getAllProviderConfigs(): Promise<ISpiProviderConfigs> {
    const response = await axios.get(`${API_ENDPOINT}${BASE_PROVIDER_CONFIGS_PATH}`);
    return response.data;
}

export async function getProviderConfigVersions(providerId: string): Promise<{ [key: string]: IVersionedObject<IProviderConfig> }> {
    const response = await axios.get(`${API_ENDPOINT}${BASE_PROVIDER_CONFIGS_PATH}/${providerId}/versions`);
    return response.data;
}

export async function upsertProviderConfigs(providerId: string, providerConfig: IProviderConfig): Promise<void> {
    const response = await axios.put(`${API_ENDPOINT}${BASE_PROVIDER_CONFIGS_PATH}/${providerId}`, providerConfig);
    return response.data;
}

export async function deleteProviderConfigs(providerId: string): Promise<void> {
    const response = await axios.delete(`${API_ENDPOINT}${BASE_PROVIDER_CONFIGS_PATH}/${providerId}`);
    return response.data;
}

export async function getProviderConfigById(providerId: string): Promise<IProviderConfig> {
    const response = await axios.get(`${API_ENDPOINT}${BASE_PROVIDER_CONFIGS_PATH}/${providerId}`);
    return response.data;
}

export async function getProviderConfigByIdAndVersion(providerId: string, versionId: string): Promise<IProviderConfig> {
    const params = { s3Version: versionId };
    const response = await axios.get(`${API_ENDPOINT}${BASE_PROVIDER_CONFIGS_PATH}/${providerId}`, { params });
    return response.data;
}