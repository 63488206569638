import { IFlattenedExperience } from './FlattenedExperience';
import { ExperienceStatus, ExperienceType, IExperience } from './Experience';
import { ISpeakerData, UserRole } from './SpeakerData';
import { getDefaultDayTimeGuardrails, ITimeRange } from './TimeRange';
import { IMetadata } from './Metadata';
import { ITemplateVariable, processTemplateVariables } from './TemplateVariable';
import { ExperienceFilterType } from './Guardrails';
import { EMPTY_CONTEXTUAL_SEGMENTS, IContextualSegments } from './Filter';
import { EMPTY_REMOTE_CONTEXTUAL_SEGMENTS, IRemoteContextualSegments } from './RemoteContextualSegments';
import {
    INluOpportunityTargeting,
    isNotEmptyTargeting,
    processNluOpportunityTargeting
} from './NluOpportunityTargeting';
import { INotificationExperienceContent } from './appDeviceExperience/NotificationExperienceContent';
import { DataSource } from './DataSource';
import { isLiveExperience } from '../util/stringAndMappingHelper';
import { IOpportunityTrigger, processOpportunityTrigger } from './NluOpportunityTrigger';
import { INluInfo } from './NluInfo';
import { ActionType, isDefaultActionType } from './ActionType';
import { NotificationPlatform } from './NotificationPlatform';
import { ManagementTemplateIdentifier } from './cifExperience/visualExperience/IManagementVisualContent';
import { IManagementCard, isCardEmpty } from './cifExperience/visualExperience/IManagementCard';
import { IWeblabAllocation } from '../models/WeblabAllocation';
import { IApprovalMetadata } from './ApprovalInfo';
import { IRowCountryInfo } from './RowCountryInfo';
import { MARKETPLACES_FLIPPED } from '../constants/marketplaces';
import { isNullOrUndefined } from 'util';
import { IJourneyMetadata } from './JourneyMetadata';
import { INotificationGuardrails } from './NotificationGuardrails';
import { IBusinessMetadata } from './BusinessInfo';
import { Region } from '../util/context';
import { isNonCTATemplateExperience, isTouchActionTemplate } from '../util/cifExperienceHelper';
import { IMobileHomeCardContent } from './mobileHomeCard/MobileHomeCardContent';
import { IMobileHomeCardContext } from './mobileHomeCard/MobileHomeCardContext';
import { IMobileHomeCardAbExperiment } from './mobileHomeCard/MobileHomeCardAbExperiment';
import { IMobileHomeCardTargeting } from './mobileHomeCard/MobileHomeCardTargeting';
import { IMobileHomeCardAction } from './mobileHomeCard/MobileHomeCardAction';
import { IMobileHomeCardConstraint } from './mobileHomeCard/MobileHomeCardConstraint';
import { IOdysseyExperience } from './v2/IOdysseyExperience';
import {
    constructMobileHomeCardData,
    extractMobileHomeCardAbExperiment,
    extractMobileHomeCardAction,
    extractMobileHomeCardConstraint,
    extractMobileHomeCardContent,
    extractMobileHomeCardContext,
    extractMobileHomeCardTargeting
} from '../util/mobileHomeCardHelper';
import {
    IRotatingContentPanelBusinessContext,
    IRotatingContentPanelContent,
    IRotatingContentPanelContext,
    IRotatingContentPanelDevicesConstraint,
    IRotatingContentPanelSchedulingConstraint,
    IRotatingContentPanelTestingConstraint
} from './rotatingContentPanel/RotatingContentPanelStructure';
import {DomainIllustrationDefaultItems} from '../constants/domainIllustrationDefaultItems';
import {
    constructRotatingContentPanelData,
    extractBullseyeSegmentId,
    extractRotatingContentPanelBusinessContext,
    extractRotatingContentPanelContent,
    extractRotatingContentPanelContext,
    extractRotatingContentPanelDevicesConstraint,
    extractRotatingContentPanelSchedulingConstraint,
    extractRotatingContentPanelTestingConstraint
} from '../util/rotatingContentPanelHelper';
import _ from 'lodash';
import {SubscriptionUpsellType} from './SubscriptionUpsellType';

export interface IRegionViewAttributes {
    marketplace?: string;
    locale?: string;
    enabled?: boolean;
}

export interface IBasicInfoViewAttributes {
    title?: string;
    groupImpressions?: string;
    startDate?: string;
    endDate?: string;
    region?: Region;
}

export interface IImpressionTrackingViewAttributes {
    weblab?: string;
    weblabLaunched?: boolean;
    hintId?: string;
    contentId?: string;
}

export interface IPromotedCapability {
    capabilityName?: string;
    capabilityLocale?: string;
    capabilityId?: string;
    NLUIntent?: string;
    NLUDomain?: string;
    supportedDevices?: string[];
    businessDomain?: string;
    businessVertical?: string;
    confidenceScore?: number;
    goldenUtterance?: string;
}

export type IContentType = 'content' | 'referral' | 'none';

export interface IContentViewAttributes {
    contentText?: string;
    referralQuestionContentText?: string;
    referralData?: string;
    rejectionReferralData?: string;
    contentType: IContentType;
    actionType?: ActionType;
    sonarTemplateURI?: string;
    sonarTemplateJson?: string;
    campaignId?: string;
    notificationPlatform?: NotificationPlatform;
    checkCXGuidelines?: boolean;
    isEntireContentATemplateVariable?: boolean;
    customSuccessfulSendResponse?: string;
}

export interface IDataSourceViewAttributes {
    dataSource?: DataSource;
    bullseyeId?: number;
    snsTopic?: string;
    publisherRoleForAutoCreatedSnsTopic?: string;
    bullseyeVersion?: number;
    ttl?: number;
}

export interface ISpeakerDataAttributes {
    userRole?: UserRole;
}

export interface IExperienceUpdateCandidate {
    id: string;
    type: ExperienceType;
    status: ExperienceStatus;
    metadata?: IMetadata;
    approvalId?: string;
    approvalInfo?: IApprovalMetadata;
    businessInfo?: IBusinessMetadata;
    subscriptionUpsellType?: SubscriptionUpsellType;
    cancellationReason?: string;
    region: IRegionViewAttributes;
    timeRanges: { [key: number]: ITimeRange[] };
    basicInfo: IBasicInfoViewAttributes;
    impressionTracking: IImpressionTrackingViewAttributes;
    capabilityId?: string;
    bypassCapabilityFilter?: boolean;
    suggestedFeature?: INluInfo;
    content: IContentViewAttributes;
    templateVariables: ITemplateVariable[];
    devices: string[];
    launchTreatmentPercentage?: number;
    weblabAllocation?: IWeblabAllocation;
    rowCountryInfo?: IRowCountryInfo;
    snsTopicBYORDataSource?: string;
    journeyMetadata?: IJourneyMetadata;

    overrideAccounts: string[];
    overrideSelectiveFilters: ExperienceFilterType[];
    verboseLoggingEnabled: boolean;

    visualInterstitialCards?: IManagementCard[];
    enableVoiceCif?: boolean;
    includingOdysseyFields?: boolean;

    contextualSegments: IContextualSegments;
    remoteContextualSegments: IRemoteContextualSegments;

    featureHints: string[];
    bullseye?: number;
    speakerData: ISpeakerDataAttributes;

    opportunityTriggerInclusions: IOpportunityTrigger[];
    opportunityTriggerExclusions: IOpportunityTrigger[];
    nluOpportunityTargetingInclusions: INluOpportunityTargeting[];
    nluOpportunityTargetingExclusions: INluOpportunityTargeting[];
    notificationContent: INotificationExperienceContent;
    notificationGuardrails: INotificationGuardrails;

    // named this instead of dataSource because
    // dataSource.dataSource is confusing
    dataSourceInfo: IDataSourceViewAttributes;

    isQuickCreateWorkflow?: boolean;
    isVisualCIF?: boolean;

    useTouchActionTemplate?: boolean;
    useNonCTATemplate?: boolean;

    // custom domain illustration and background image selection
    domainIllustrationCustomSource?: boolean[];
    overrideBackgroundImage?: boolean[];
    acknowledgeBackgroundImage?: boolean[];

    // mobile home card
    mobileHomeCardContent?: IMobileHomeCardContent;
    mobileHomeCardActions?: IMobileHomeCardAction[];
    mobileHomeCardConstraint?: IMobileHomeCardConstraint;
    mobileHomeCardAbExperiment?: IMobileHomeCardAbExperiment;
    mobileHomeCardTargeting?: IMobileHomeCardTargeting;
    mobileHomeCardContext?: IMobileHomeCardContext;

    // Rotating Content Panel
    rotatingContentPanelContent?: IRotatingContentPanelContent;
    rotatingContentPanelTestingConstraint?: IRotatingContentPanelTestingConstraint;
    rotatingContentPanelDevicesConstraint?: IRotatingContentPanelDevicesConstraint;
    rotatingContentPanelSchedulingConstraint?: IRotatingContentPanelSchedulingConstraint;
    rotatingContentPanelBusinessContext?: IRotatingContentPanelBusinessContext;
    rotatingContentPanelContext?: IRotatingContentPanelContext;
}

export class ExperienceUpdateCandidate {
    private props: IExperienceUpdateCandidate;

    constructor(props: IExperienceUpdateCandidate) {
        this.props = ExperienceUpdateCandidate.deepClone(props);
    }

    setStatus(status: ExperienceStatus) {
        this.props.status = status;
    }

    setRegion(region: IRegionViewAttributes) {
        const { marketplace, locale, enabled } = region;
        if (marketplace) this.props.region.marketplace = marketplace;
        if (locale) this.props.region.locale = locale;
        if (enabled !== undefined) this.props.region.enabled = enabled;
    }

    setRowCountryInfo(rowCountryInfo: IRowCountryInfo) {
        if (rowCountryInfo.rowExperience !== undefined) this.props.rowCountryInfo = rowCountryInfo;
    }

    setJourneyMetadata(journeyMetadata: IJourneyMetadata) {
        const { journeyName, journeyId } = journeyMetadata;
        if (this.props.journeyMetadata === null || this.props.journeyMetadata === undefined) {
            this.props.journeyMetadata = journeyMetadata;
            this.props.journeyMetadata.journeyId = '';
            this.props.journeyMetadata.journeyName = '';
        }

        if (!isNullOrUndefined(journeyId)) this.props.journeyMetadata.journeyId = journeyId;
        if (!isNullOrUndefined(journeyName)) this.props.journeyMetadata.journeyName = journeyName;

        if (this.props.journeyMetadata.journeyId?.length === 0 && this.props.journeyMetadata.journeyName?.length === 0) {
            this.props.journeyMetadata = undefined;
        }
    }

    setBusinessInfo(businessInfo: IBusinessMetadata) {
        const { vertical, domain } = businessInfo;
        if (this.props.businessInfo === undefined || this.props.businessInfo === null) {
            this.props.businessInfo = businessInfo;
        }
        if (vertical !== undefined) this.props.businessInfo.vertical = vertical || undefined;
        if (domain === undefined && vertical !== undefined) this.props.businessInfo.domain = undefined;
        if (domain !== undefined) this.props.businessInfo.domain = domain || undefined;
    }

    setSubscriptionUpsellType(subscriptionUpsellType: SubscriptionUpsellType) {
        this.props.subscriptionUpsellType = subscriptionUpsellType;
    }

    setNotificationGuardrails(notificationGuardrails: INotificationGuardrails) {
        const { overrideFrequencyGuardrail, overrideContentGuardrail,
            frequencyGuardrailOverrideDays, contentGuardrailOverrideDays, notificationExpirationOverrideDays } = notificationGuardrails;
        if (notificationGuardrails.overrideFrequencyGuardrail !== undefined) {
            this.props.notificationGuardrails.overrideFrequencyGuardrail = overrideFrequencyGuardrail || undefined;
            if (!overrideFrequencyGuardrail) {
                this.props.notificationGuardrails.frequencyGuardrailOverrideDays = undefined;
            }
        }
        if (notificationGuardrails.overrideContentGuardrail !== undefined) {
            this.props.notificationGuardrails.overrideContentGuardrail = overrideContentGuardrail || undefined;
            if (!overrideContentGuardrail) {
                this.props.notificationGuardrails.contentGuardrailOverrideDays = undefined;
            }
        }
        if (notificationGuardrails.frequencyGuardrailOverrideDays !== undefined) {
            this.props.notificationGuardrails.frequencyGuardrailOverrideDays = frequencyGuardrailOverrideDays || undefined;
        }
        if (notificationGuardrails.contentGuardrailOverrideDays !== undefined) {
            this.props.notificationGuardrails.contentGuardrailOverrideDays = contentGuardrailOverrideDays || undefined;
        }
        if (notificationGuardrails.notificationExpirationOverrideDays !== undefined) {
            this.props.notificationGuardrails.notificationExpirationOverrideDays = notificationExpirationOverrideDays || undefined;
        }
    }

    setLaunchTreatmentPercentage(exposureRate: number) {
        this.props.launchTreatmentPercentage = exposureRate;
    }

    setWeblabAllocation(webLabAllocationDetail: IWeblabAllocation) {
        this.props.weblabAllocation = webLabAllocationDetail;
    }

    setTimeRanges(timeRanges: { [key: number]: ITimeRange[] }) {
        this.props.timeRanges = timeRanges;
    }

    setBasicInfo(basicInfo: IBasicInfoViewAttributes) {
        const { title, groupImpressions, startDate, endDate } = basicInfo;
        // if it is an empty string rather than undefined, that means it is a valid user input
        // however, we want to update our attribute to be undefined instead of empty string
        if (title !== undefined) this.props.basicInfo.title = title || undefined;
        if (!isNullOrUndefined(groupImpressions)) this.props.basicInfo.groupImpressions = groupImpressions;
        if (startDate !== undefined) this.props.basicInfo.startDate = startDate || undefined;
        if (endDate !== undefined) this.props.basicInfo.endDate = endDate || undefined;
    }

    setSpeakerData(speakerData: ISpeakerData) {
        this.props.speakerData = speakerData;
    }

    setType(type: ExperienceType) {
        this.props.type = type;
    }

    setMetadata(metadata: IMetadata) {
        this.props.metadata = metadata;
    }

    setSuggestedFeature(suggestedFeature: INluInfo) {
        const { nluDomain, nluIntent, nluSlotName, featureUtterance } = suggestedFeature;
        if (this.props.suggestedFeature === null || this.props.suggestedFeature === undefined) this.props.suggestedFeature = suggestedFeature;
        if (!isNullOrUndefined(nluDomain)) this.props.suggestedFeature.nluDomain = nluDomain;
        if (!isNullOrUndefined(nluIntent)) this.props.suggestedFeature.nluIntent = nluIntent;
        if (!isNullOrUndefined(nluSlotName)) this.props.suggestedFeature.nluSlotName = nluSlotName || [];
        if (!isNullOrUndefined(featureUtterance)) this.props.suggestedFeature.featureUtterance = featureUtterance;

    }

    setImpressionTracking(impressionTracking: IImpressionTrackingViewAttributes) {
        const { weblab, weblabLaunched, hintId, contentId } = impressionTracking;
        // if it is an empty string rather than undefined, that means it is a valid user input
        // however, we want to update our attribute to be undefined instead of empty string
        if (weblab !== undefined) this.props.impressionTracking.weblab = weblab || undefined;
        if (weblabLaunched !== undefined) this.props.impressionTracking.weblabLaunched = weblabLaunched;
        if (hintId !== undefined) this.props.impressionTracking.hintId = hintId || undefined;
        if (contentId !== undefined) this.props.impressionTracking.contentId = contentId || undefined;
    }

    setPromotedCapability(promotedCapability: string | undefined) {
        this.props.capabilityId = promotedCapability;
        if (this.props.type === 'RotatingContentPanel') {
            if (this.props.rotatingContentPanelContent) {
                this.props.rotatingContentPanelContent.contentId = promotedCapability;
            } else {
                this.props.rotatingContentPanelContent = { contentId: promotedCapability };
            }
        }
    }

    setbypassCapabilityFilter(bypassCapabilityFilter: boolean) {
        this.props.bypassCapabilityFilter = bypassCapabilityFilter;
    }

    setContent(content: IContentViewAttributes) {
        const { contentText, referralQuestionContentText, referralData, rejectionReferralData,
            contentType, actionType, sonarTemplateURI, sonarTemplateJson, campaignId, checkCXGuidelines, isEntireContentATemplateVariable, customSuccessfulSendResponse } = content;

        this.props.content.contentType = contentType;

        if (contentText !== undefined) {
            this.props.content.contentText = contentText || undefined;
        }

        if (referralQuestionContentText !== undefined) {
            this.props.content.referralQuestionContentText = referralQuestionContentText || undefined;
        }

        if (actionType !== undefined) {
            this.props.content.actionType = actionType;
        }

        if (checkCXGuidelines !== undefined) {
            this.props.content.checkCXGuidelines = checkCXGuidelines;
        }

        if (isEntireContentATemplateVariable !== undefined) {
            this.props.content.isEntireContentATemplateVariable = isEntireContentATemplateVariable;
        }

        if (customSuccessfulSendResponse !== undefined) {
            this.props.content.customSuccessfulSendResponse = customSuccessfulSendResponse;
        }

        switch (actionType) {
            case ActionType.EMAIL:
                if (sonarTemplateURI !== undefined) this.props.content.sonarTemplateURI = sonarTemplateURI || undefined;
                if (sonarTemplateJson !== undefined) this.props.content.sonarTemplateJson = sonarTemplateJson || undefined;
                if (campaignId !== undefined) this.props.content.campaignId = campaignId || undefined;

                delete this.props.content.referralData;
                delete this.props.content.rejectionReferralData;
                break;
            case ActionType.PUSH:
                delete this.props.content.referralData;
                delete this.props.content.rejectionReferralData;
                delete this.props.content.sonarTemplateURI;
                delete this.props.content.sonarTemplateJson;
                delete this.props.content.campaignId;

                // need to change this when we want to support DEVICE platform as well
                this.props.content.notificationPlatform = NotificationPlatform.APP;
                break;
            default:
                if (referralData !== undefined) this.props.content.referralData = referralData || undefined;
                if (rejectionReferralData !== undefined) {
                    this.props.content.rejectionReferralData = rejectionReferralData || undefined;
                }
        }
    }

    setTemplateVariables(templateVariables: ITemplateVariable[]) {
        this.props.templateVariables = templateVariables;
    }

    setDevices(devices: string[]) {
        this.props.devices = devices;
    }

    setOverrideAccounts(overrideAccounts: string[]) {
        this.props.overrideAccounts = overrideAccounts;
    }

    setOverrideFilters(overrideSelectiveFilters: ExperienceFilterType[]) {
        this.props.overrideSelectiveFilters = overrideSelectiveFilters;
    }

    setVerboseLoggingEnabled(verboseLoggingEnabled: boolean) {
        this.props.verboseLoggingEnabled = verboseLoggingEnabled;
    }

    setVisualInterstitialCards(visualInterstitialCards: IManagementCard[]) {
        this.props.visualInterstitialCards = visualInterstitialCards;
    }

    setEnableVoiceCif(enableVoiceCif: boolean) {
        this.props.enableVoiceCif = enableVoiceCif;
        if (!enableVoiceCif) {
            this.props.content.contentType = 'none';
        }
    }

    setIncludingOdysseyFields(includeOdysseyFields: boolean) {
        this.props.includingOdysseyFields = includeOdysseyFields;
    }

    setDomainTags(contextualSegments: IContextualSegments) {
        this.props.contextualSegments = contextualSegments;
    }

    setRemoteTags(remoteContextualSegments: IRemoteContextualSegments) {
        this.props.remoteContextualSegments = remoteContextualSegments;
    }

    setFeatureHints(featureHints: string[]) {
        this.props.featureHints = featureHints;
    }

    setApprovalInfo(approvalInfo: IApprovalMetadata) {
        const { approvedStage, lastDialupStage, lastCheckDate, lastDialupDate, requestedDialupStage } = approvalInfo;
        if (this.props.approvalInfo === undefined || this.props.approvalInfo === null) {
            this.props.approvalInfo = approvalInfo;
        }
        this.props.approvalInfo.approvedStage = approvedStage;
        this.props.approvalInfo.lastDialupStage = lastDialupStage;
        this.props.approvalInfo.lastCheckDate = lastCheckDate;
        this.props.approvalInfo.lastDialupDate = lastDialupDate;
        this.props.approvalInfo.requestedDialupStage = requestedDialupStage;
    }

    setBullseye(bullseye: number) {
        if (bullseye !== undefined) {
            if (Number.isNaN(bullseye)) {
                this.props.bullseye = undefined;
            } else {
                this.props.bullseye = bullseye;
            }
        } else {
            this.props.bullseye = undefined;
        }
    }

    setTriggerInclusions(opportunityTriggerInclusions: IOpportunityTrigger[]) {
        this.props.opportunityTriggerInclusions = opportunityTriggerInclusions;
    }

    setTriggerExclusions(opportunityTriggerExclusions: IOpportunityTrigger[]) {
        this.props.opportunityTriggerExclusions = opportunityTriggerExclusions;
    }

    setTargetingInclusions(nluOpportunityTargetingInclusions: INluOpportunityTargeting[]) {
        this.props.nluOpportunityTargetingInclusions = nluOpportunityTargetingInclusions;
    }

    setTargetingExclusions(nluOpportunityTargetingExclusions: INluOpportunityTargeting[]) {
        this.props.nluOpportunityTargetingExclusions = nluOpportunityTargetingExclusions;
    }

    setNotificationContent(notificationContent: INotificationExperienceContent) {
        if (notificationContent.title !== undefined) {
            this.props.notificationContent.title = notificationContent.title || undefined;
        }
        if (notificationContent.displayContent !== undefined) {
            this.props.notificationContent.displayContent = notificationContent.displayContent || undefined;
        }
        if (notificationContent.checkAppMobileNotificationsGuidelines !== undefined) {
            this.props.notificationContent.checkAppMobileNotificationsGuidelines = notificationContent.checkAppMobileNotificationsGuidelines || undefined;
        }
        if (notificationContent.spokenContent !== undefined) {
            this.props.notificationContent.spokenContent = notificationContent.spokenContent || undefined;
        }
        if (notificationContent.url !== undefined) {
            this.props.notificationContent.url = notificationContent.url || undefined;
        }
        if (notificationContent.deepLinkUrl !== undefined) {
            this.props.notificationContent.deepLinkUrl = notificationContent.deepLinkUrl || undefined;
        }
        if (notificationContent.webUrl !== undefined) {
            this.props.notificationContent.webUrl = notificationContent.webUrl || undefined;
        }
        if (notificationContent.storeUrls?.appleUrl !== undefined) {
            this.props.notificationContent.storeUrls = {
                amazonUrl: this.props.notificationContent.storeUrls?.amazonUrl,
                appleUrl: notificationContent.storeUrls.appleUrl,
                googleUrl: this.props.notificationContent.storeUrls?.googleUrl
            };
        }
        if (notificationContent.storeUrls?.amazonUrl !== undefined) {
            this.props.notificationContent.storeUrls = {
                amazonUrl: notificationContent.storeUrls.amazonUrl,
                appleUrl: this.props.notificationContent.storeUrls?.appleUrl,
                googleUrl: this.props.notificationContent.storeUrls?.googleUrl
            };
        }
        if (notificationContent.storeUrls?.googleUrl !== undefined) {
            this.props.notificationContent.storeUrls = {
                amazonUrl: this.props.notificationContent.storeUrls?.amazonUrl,
                appleUrl: this.props.notificationContent.storeUrls?.appleUrl,
                googleUrl: notificationContent.storeUrls.googleUrl
            };
        }
        if (this.props.notificationContent.storeUrls === null || this.props.notificationContent.storeUrls === undefined) {
            this.props.notificationContent.storeUrls = {
                amazonUrl: '',
                appleUrl: '',
                googleUrl: ''
            };
        }
        if (notificationContent.appNotificationType !== undefined) {
            this.props.notificationContent.appNotificationType = notificationContent.appNotificationType || undefined;

            if (notificationContent.appNotificationType === 'Desktop') {
                if (notificationContent.ctaButtonTitle !== undefined) {
                    this.props.notificationContent.ctaButtonTitle = notificationContent.ctaButtonTitle || undefined;
                }
                if (notificationContent.ctaButtonAction !== undefined) {
                    this.props.notificationContent.ctaButtonAction = notificationContent.ctaButtonAction || undefined;
                }
            }
        }

        if (notificationContent.interactionPayload !== undefined) {
            this.props.notificationContent.interactionPayload = notificationContent.interactionPayload || undefined;
        }

        if (notificationContent.notificationThumbnail !== undefined) {
            this.props.notificationContent.notificationThumbnail = notificationContent.notificationThumbnail || undefined;
        }

        if (notificationContent.banyanCampaign !== undefined) {
            this.props.notificationContent.banyanCampaign = notificationContent.banyanCampaign || false;
        }

        if (notificationContent.imageUrl !== undefined) {
            this.props.notificationContent.imageUrl = notificationContent.imageUrl || undefined;
        }
    }

    setContextualSegments(contextualSegments: IContextualSegments) {
        this.props.contextualSegments = contextualSegments;
    }

    setRemoteContextualSegments(remoteContextualSegments: IRemoteContextualSegments) {
        this.props.remoteContextualSegments = remoteContextualSegments;
    }

    setDataSourceInfo(dataSourceInfo: IDataSourceViewAttributes) {
        if (dataSourceInfo.dataSource) this.props.dataSourceInfo.dataSource = dataSourceInfo.dataSource;
        // 0 is falsy but valid input here
        if (dataSourceInfo.bullseyeId !== undefined) this.props.dataSourceInfo.bullseyeId = dataSourceInfo.bullseyeId;
        if (dataSourceInfo.snsTopic !== undefined) this.props.dataSourceInfo.snsTopic = dataSourceInfo.snsTopic;
        if (dataSourceInfo.publisherRoleForAutoCreatedSnsTopic !== undefined) {
            this.props.dataSourceInfo.publisherRoleForAutoCreatedSnsTopic = dataSourceInfo.publisherRoleForAutoCreatedSnsTopic;
        }
        if (dataSourceInfo.ttl !== undefined) {
            this.props.dataSourceInfo.ttl = Number.isNaN(dataSourceInfo.ttl) ? undefined : dataSourceInfo.ttl;
        }
    }

    resetSnsTopic() {
        this.props.dataSourceInfo.snsTopic = undefined;
    }

    setSnsTopicBYORDataSource(snsTopicBYORDataSource: string) {
        this.props.snsTopicBYORDataSource = snsTopicBYORDataSource;
    }

    setIsQuickCreateWorkflow(isQuickCreateWorkflow: boolean) {
        this.props.isQuickCreateWorkflow = isQuickCreateWorkflow;
    }

    setIsVisualCIF(isVisualCIF: boolean) {
        this.props.isVisualCIF = isVisualCIF;
    }

    setUseTouchActionTemplate(useTouchActionTemplate: boolean) {
        this.props.useTouchActionTemplate = useTouchActionTemplate;
    }

    setUseNonCTATemplate(useNonCTATemplate: boolean) {
        this.props.useNonCTATemplate = useNonCTATemplate;
    }

    setDomainIllustrationCustomSource(domainIllustrationCustomSource: boolean[]) {
        this.props.domainIllustrationCustomSource = domainIllustrationCustomSource;
    }

    setOverrideBackgroundImage(overrideBackgroundImage: boolean[]) {
        this.props.overrideBackgroundImage = overrideBackgroundImage;
    }

    setAcknowledgeBackgroundImage(acknowledgeBackgroundImage: boolean[]) {
        this.props.acknowledgeBackgroundImage = acknowledgeBackgroundImage;
    }

    setMobileHomeCardContent(mobileHomeCardContent: IMobileHomeCardContent) {
        const { contentId, contentProvider, contentType, jiraLink, templateType, section, cardTitle, cardSubtitle, cardImageUrl, cardName, callToActionButton,
            cardActionConfirmation, characterLimitExceed, campaignTag, businessInfo, leftIconUrl, cardBgColor, labelText, labelBgColor } = mobileHomeCardContent;

        if (this.props.mobileHomeCardContent === undefined || this.props.mobileHomeCardContent === null) {
            this.props.mobileHomeCardContent = mobileHomeCardContent;
            this.props.mobileHomeCardContent.cardActionConfirmation = false;
            this.props.mobileHomeCardContent.characterLimitExceed = false;
        }
        if (contentId !== undefined) this.props.mobileHomeCardContent.contentId = contentId || undefined;
        if (contentProvider !== undefined) this.props.mobileHomeCardContent.contentProvider = contentProvider || undefined;
        if (contentType !== undefined) this.props.mobileHomeCardContent.contentType = contentType || undefined;
        if (jiraLink !== undefined) this.props.mobileHomeCardContent.jiraLink = jiraLink || undefined;
        if (section !== undefined) this.props.mobileHomeCardContent.section = section || undefined;
        if (cardTitle !== undefined) this.props.mobileHomeCardContent.cardTitle = cardTitle || cardTitle;
        if (cardSubtitle !== undefined) this.props.mobileHomeCardContent.cardSubtitle = cardSubtitle || undefined;
        if (cardImageUrl !== undefined) this.props.mobileHomeCardContent.cardImageUrl = cardImageUrl || undefined;
        if (cardName !== undefined) this.props.mobileHomeCardContent.cardName = cardName || undefined;
        if (callToActionButton !== undefined) this.props.mobileHomeCardContent.callToActionButton = callToActionButton || undefined;
        if (cardActionConfirmation !== undefined) this.props.mobileHomeCardContent.cardActionConfirmation = cardActionConfirmation || false;
        if (characterLimitExceed !== undefined) this.props.mobileHomeCardContent.characterLimitExceed = characterLimitExceed || false;
        if (businessInfo !== undefined) this.props.mobileHomeCardContent.businessInfo = businessInfo || [];
        if (campaignTag !== undefined) this.props.mobileHomeCardContent.campaignTag = campaignTag || undefined;
        if (leftIconUrl !== undefined) this.props.mobileHomeCardContent.leftIconUrl = leftIconUrl || undefined;
        if (cardBgColor !== undefined) this.props.mobileHomeCardContent.cardBgColor = cardBgColor || undefined;
        if (labelText !== undefined) this.props.mobileHomeCardContent.labelText = labelText || undefined;
        if (labelBgColor !== undefined) this.props.mobileHomeCardContent.labelBgColor = labelBgColor || undefined;

        if (labelText === '' || labelBgColor === '') {
            this.props.mobileHomeCardContent.labelText = undefined;
            this.props.mobileHomeCardContent.labelBgColor = undefined;
        }

        if (templateType !== this.props.mobileHomeCardContent.templateType && templateType === 'HeroTemplate') {
            this.props.mobileHomeCardContent.businessInfo = undefined;
            this.props.mobileHomeCardContent.campaignTag = undefined;
            this.props.mobileHomeCardContent.leftIconUrl = undefined;
            this.props.mobileHomeCardContent.cardBgColor = undefined;
            this.props.mobileHomeCardContent.labelText = undefined;
            this.props.mobileHomeCardContent.labelBgColor = undefined;
            this.props.mobileHomeCardContent.templateType = templateType || undefined;
        }

        if (templateType !== this.props.mobileHomeCardContent.templateType && templateType === 'DiscoveryTemplate') {
            this.props.mobileHomeCardContent.cardName = undefined;
            this.props.mobileHomeCardContent.callToActionButton = undefined;
            this.props.mobileHomeCardContent.templateType = templateType || undefined;
        }

    }

    setMobileCardActions(mobileHomeCardActions: IMobileHomeCardAction[]) {
        this.props.mobileHomeCardActions = mobileHomeCardActions;
    }

    setMobileHomeCardConstraint(mobileHomeCardConstraint: IMobileHomeCardConstraint) {
        const { targetDevice, appReleaseStart, appReleaseEnd, startTime, endTime } = mobileHomeCardConstraint;
        if (this.props.mobileHomeCardConstraint === undefined || this.props.mobileHomeCardConstraint === null) {
            this.props.mobileHomeCardConstraint = mobileHomeCardConstraint;
        }
        if (targetDevice !== undefined) this.props.mobileHomeCardConstraint.targetDevice = targetDevice || undefined;
        if (appReleaseStart !== undefined) this.props.mobileHomeCardConstraint.appReleaseStart = appReleaseStart || undefined;
        if (appReleaseEnd !== undefined) this.props.mobileHomeCardConstraint.appReleaseEnd = appReleaseEnd || undefined;
        if (startTime !== undefined) this.props.mobileHomeCardConstraint.startTime = startTime || undefined;
        if (endTime !== undefined) this.props.mobileHomeCardConstraint.endTime = endTime || undefined;
    }

    setMobileHomeCardAbExperiment(mobileHomeCardAbExperiment: IMobileHomeCardAbExperiment) {
        const { weblabName, abExperimentCategory, abExperimentGroup, launched } = mobileHomeCardAbExperiment;
        if (this.props.mobileHomeCardAbExperiment === undefined || this.props.mobileHomeCardAbExperiment === null) {
            this.props.mobileHomeCardAbExperiment = mobileHomeCardAbExperiment;
            this.props.mobileHomeCardAbExperiment.launched = false;
        }

        if (weblabName !== undefined) this.props.mobileHomeCardAbExperiment.weblabName = weblabName || undefined;
        if (abExperimentCategory !== undefined) this.props.mobileHomeCardAbExperiment.abExperimentCategory = abExperimentCategory || undefined;
        if (abExperimentGroup !== undefined) this.props.mobileHomeCardAbExperiment.abExperimentGroup = abExperimentGroup || undefined;
        if (launched !== undefined) this.props.mobileHomeCardAbExperiment.launched = launched || false;

        if (abExperimentCategory === 'TreatmentShow') {
            this.props.mobileHomeCardAbExperiment.abExperimentGroup = undefined;
        }

        if (weblabName === '') {
            this.props.mobileHomeCardAbExperiment.abExperimentCategory = undefined;
            this.props.mobileHomeCardAbExperiment.abExperimentGroup = undefined;
        }

    }

    setMobileHomeCardTargeting(mobileHomeCardTargeting: IMobileHomeCardTargeting) {
        const { antiTarget, bullseye } = mobileHomeCardTargeting;
        if (this.props.mobileHomeCardTargeting === undefined || this.props.mobileHomeCardTargeting === null) {
            this.props.mobileHomeCardTargeting = mobileHomeCardTargeting;
            this.props.mobileHomeCardTargeting.antiTarget = false;
        }

        if (antiTarget !== undefined) this.props.mobileHomeCardTargeting.antiTarget = antiTarget;
        if (bullseye !== undefined) this.props.mobileHomeCardTargeting.bullseye = bullseye || undefined;

    }

    setMobileHomeCardContext(mobileHomeCardContext: IMobileHomeCardContext) {
        const { dismissible, subscriptionUpsellType } = mobileHomeCardContext;
        if (this.props.mobileHomeCardContext === undefined || this.props.mobileHomeCardContext === null) {
            this.props.mobileHomeCardContext = mobileHomeCardContext;
            this.props.mobileHomeCardContext.dismissible = false;
            this.props.mobileHomeCardContext.subscriptionUpsellType = SubscriptionUpsellType.NOT_UPSELL;
        }
        if(dismissible !== undefined) this.props.mobileHomeCardContext.dismissible = dismissible || false;
        if(subscriptionUpsellType !== undefined) this.props.mobileHomeCardContext.subscriptionUpsellType = subscriptionUpsellType;
    }

    setRotatingContentPanelContent(content: IRotatingContentPanelContent) {
        this.props.rotatingContentPanelContent = {
            ...this.props.rotatingContentPanelContent,
            ...content
        };
    }

    setRotatingContentPanelTestingConstraint(rcpOverrideAccounts: IRotatingContentPanelTestingConstraint) {
        this.props.rotatingContentPanelTestingConstraint = {
            ...rcpOverrideAccounts
        };
    }

    setRotatingContentPanelBusinessContext(businessInfo: IRotatingContentPanelBusinessContext) {
        this.props.rotatingContentPanelBusinessContext = {
            ...businessInfo
        };
    }

    setRotatingContentPanelContext(rcpContext: IRotatingContentPanelContext) {
        this.props.rotatingContentPanelContext = {
            ...rcpContext
        };
    }

    setRotatingContentPanelDevicesConstraint(deviceTypes: string[]) {
        this.props.rotatingContentPanelDevicesConstraint = {
            deviceTypes
        };
    }

    setRotatingContentPanelSchedulingConstraint( schedule: IRotatingContentPanelSchedulingConstraint) {
        this.props.rotatingContentPanelSchedulingConstraint = {
            endDate: schedule.endDate,
            startDate: schedule.startDate,
            timeWindows: schedule.timeWindows
        };
    }

    constructContents() {
        if (this.props.type === 'CIF') {
            return {
                ...(this.props.content.contentType === 'content' && this.props.region.locale
                    ? {
                        content: {
                            [this.props.region.locale]: {
                                text: this.props.content.contentText || ''
                            }
                        },
                    }
                    : {
                        content: {}
                    }
                ),
                ...(this.props.content.contentType === 'referral' && this.props.region.locale
                    ? {
                        referralContent: {
                            localizedQuestion: {
                                [this.props.region.locale]: {
                                    text: this.props.content.referralQuestionContentText || ''
                                }
                            },
                            referralData: this.props.content.referralData,
                            rejectionReferralData: this.props.content.rejectionReferralData,
                            actionType: this.props.content.actionType,
                            sonarTemplateURI: this.props.content.sonarTemplateURI,
                            sonarTemplateJson: this.props.content.sonarTemplateJson,
                            campaignId: this.props.content.campaignId,
                            notificationPlatform: this.props.content.notificationPlatform,
                            customSuccessfulSendResponse: this.props.content.customSuccessfulSendResponse
                        }
                    }
                    : {
                        referralContent: undefined
                    }),
                ...(this.props.content.contentType === 'referral' && this.props.region.locale && this.props.content.actionType === ActionType.PUSH
                    ? {
                        notificationContent: this.props.region.locale ? {
                            [this.props.region.locale]: this.props.notificationContent
                        } : {}
                    }
                    : {
                        notificationContent: undefined
                    })
            };
        } else if ((this.props.type === 'AppNotification') || (this.props.type === 'DeviceNotification')) {
            return {
                content: {},
                referralContent: undefined,
                notificationContent: this.props.region.locale ? {
                    [this.props.region.locale]: this.props.notificationContent
                } : {}
            };
        } else {
            return {
                content: {},
                referralContent: undefined,
                notificationContent: {}
            };
        }
    }

    constructVisualContents() {
        // We can only store Visual Content IFF a locale has been set.
        if (this.props.type === 'CIF'
            && this.props.region.locale
            && this.props.visualInterstitialCards
            && !this.props.visualInterstitialCards.every((card) => isCardEmpty(card)) /* if even one card is configured, continue */) {
            return {
                ...(this.props.visualInterstitialCards.length > 0
                    ? {
                        visualContent: {
                            [this.props.region.locale]: {
                                aplDataSource: {
                                    cards: this.props.visualInterstitialCards
                                },
                                // Setting Defaults at the moment as we're not sure exactly
                                // where these are being added
                                aplDocument: {
                                    // We may need to set a default value or
                                    // this may be filled in by ACOPS.
                                    aplDocumentStr: ''
                                },
                                templateIdentifier: 'STATIC_CARDS_TEMPLATE' as ManagementTemplateIdentifier,
                                promptlessContent: !this.props.enableVoiceCif || false,
                                includingOdysseyFields: this.props.includingOdysseyFields || false,
                                nonCTAContent: this.props.useNonCTATemplate || false
                            }
                        },
                    }
                    : {
                        visualContent: undefined
                    }
                )
            };
        }

        return {
            visualContent: undefined
        };
    }

    constructFilters() {
        return {
            [this.props.type === 'CIF'
                ? MARKETPLACES_FLIPPED[this.props.region.marketplace as string]
                : this.props.region.locale as string]: {  // CIF ? obfuscated marketplace : locale
                deviceTypes: this.props.devices,
                bullseye: (this.props.type === 'CIF') ? this.props.bullseye
                    : (this.props.dataSourceInfo.dataSource === 'SINGLE_EXECUTION_BULLSEYE' || this.props.dataSourceInfo.dataSource === 'REPEATABLE_BULLSEYE') ? this.props.dataSourceInfo.bullseyeId
                        : undefined,
                contextualSegments: this.props.contextualSegments,
                enabled: this.props.region.enabled || false,
                activationConfiguration: {
                    startDate: this.props.basicInfo.startDate,
                    endDate: this.props.basicInfo.endDate
                },
                status: 'DRAFT', // only enabled filter is configurable
                snsTopic: (this.props.dataSourceInfo.dataSource === 'SNS_TOPIC' || this.props.dataSourceInfo.dataSource === 'AUTO_CREATED_SNS_TOPIC') ? this.props.dataSourceInfo.snsTopic : undefined,
                bullseyeVersion: this.props.dataSourceInfo.bullseyeVersion
            }
        };
    }

    constructWeblab() {
        return this.props.impressionTracking.weblab
            ? {
                name: this.props.impressionTracking.weblab,
                launched: this.props.impressionTracking.weblabLaunched,
                launchTreatmentPercentage: (this.props.launchTreatmentPercentage || isLiveExperience(this.props.status) || this.props.type !== 'CIF') ? this.props.launchTreatmentPercentage : 0.2,
                weblabAllocationDetail: this.props.weblabAllocation
            }
            : { name: undefined };
    }

    constructSpeakerData() {
        return { userRole: this.props.speakerData.userRole ? this.props.speakerData.userRole : UserRole.ADULT };
    }

    getClone() {
        return new ExperienceUpdateCandidate(this.props);
    }

    getSpeakerData() {
        return this.props.speakerData;
    }
    getStatus() { return this.props.status; }

    getRegion() { return this.props.region; }

    getTimeRanges() { return this.props.timeRanges; }

    getBasicInfo() { return this.props.basicInfo; }

    getType() { return this.props.type; }

    getMetadata() { return this.props.metadata; }

    getImpressionTracking() { return this.props.impressionTracking; }

    getPromotedCapability() { return this.props.capabilityId; }

    getBypassCapabilityFilter() { return this.props.bypassCapabilityFilter; }

    getContent() { return this.props.content; }

    getTemplateVariables() { return this.props.templateVariables; }

    getDevices() { return this.props.devices; }

    getSuggestedFeature() { return this.props.suggestedFeature; }

    getOverrideAccounts() { return this.props.overrideAccounts; }

    getOverrideFilters() { return this.props.overrideSelectiveFilters; }

    getVerboseLoggingEnabled() { return this.props.verboseLoggingEnabled; }

    getVisualInterstitialCards() { return this.props.visualInterstitialCards; }

    getEnableVoiceCif() { return this.props.enableVoiceCif; }

    getIncludingOdysseyFields() { return this.props.includingOdysseyFields; }

    getDomainTags() { return this.props.contextualSegments; }

    getRemoteTags() { return this.props.remoteContextualSegments; }

    getFeatureHints() { return this.props.featureHints; }

    getBullseye() { return this.props.bullseye; }

    getOpportunityTriggerInclusions() { return this.props.opportunityTriggerInclusions; }

    getOpportunityTriggerExclusions() { return this.props.opportunityTriggerExclusions; }

    getTargetingInclusions() { return this.props.nluOpportunityTargetingInclusions; }

    getTargetingExclusions() { return this.props.nluOpportunityTargetingExclusions; }

    getDataSourceInfo() { return this.props.dataSourceInfo; }

    getNotificationContent() { return this.props.notificationContent; }

    getLaunchTreatmentPercentage() { return this.props.launchTreatmentPercentage; }

    getApprovalInfo() { return this.props.approvalInfo; }

    getBusinessInfo() { return this.props.businessInfo; }

    getSubscriptionUpsellType() { return this.props.subscriptionUpsellType; }

    getRowCountryInfo() { return this.props.rowCountryInfo; }

    getJourneyMetadata() { return this.props.journeyMetadata; }

    getNotificationGuardrails() { return this.props.notificationGuardrails; }

    getWeblabAllocation() { return this.props.weblabAllocation; }

    getSnsTopicBYORDataSource() { return this.props.snsTopicBYORDataSource; }

    getIsQuickCreateWorkflow() { return this.props.isQuickCreateWorkflow; }

    getIsVisualCIF() { return this.props.isVisualCIF; }

    getUseTouchActionTemplate() { return this.props.useTouchActionTemplate; }

    getUseNonCTATemplate() { return this.props.useNonCTATemplate; }

    getDomainIllustrationCustomSource() { return this.props.domainIllustrationCustomSource; }

    getOverrideBackgroundImage() { return this.props.overrideBackgroundImage; }

    getAcknowledgeBackgroundImage() { return this.props.acknowledgeBackgroundImage; }

    removeBullseye() { delete this.props.bullseye; }

    removeWeblab() { delete this.props.impressionTracking.weblab; }

    removeReferralContent() {
        delete this.props.content.referralData;
        delete this.props.content.referralQuestionContentText;
        delete this.props.content.rejectionReferralData;
        delete this.props.content.sonarTemplateJson;
        delete this.props.content.sonarTemplateURI;
        delete this.props.content.campaignId;
        delete this.props.content.actionType;
        delete this.props.content.notificationPlatform;
        this.setContent({
            contentType: 'content'
        });
    }

    getMobileHomeCardAbExperiment() { return this.props.mobileHomeCardAbExperiment; }

    getMobileHomeCardActions() { return this.props.mobileHomeCardActions; }

    getMobileHomeCardContent() { return this.props.mobileHomeCardContent; }

    getMobileHomeCardTargeting() { return this.props.mobileHomeCardTargeting; }

    getMobileHomeCardConstraint() { return this.props.mobileHomeCardConstraint; }

    getMobileHomeCardContext() { return this.props.mobileHomeCardContext; }

    getRotatingContentPanelContent() { return this.props.rotatingContentPanelContent; }

    getRotatingContentPanelTestingConstraint(): IRotatingContentPanelTestingConstraint | undefined {
        return this.props.rotatingContentPanelTestingConstraint;
    }

    getRotatingContentPanelSchedulingConstraint(): IRotatingContentPanelSchedulingConstraint | undefined {
        return this.props.rotatingContentPanelSchedulingConstraint;
    }

    getRotatingContentPanelBusinessInfo() { return { ...this.props.rotatingContentPanelBusinessContext }; }

    getRotatingContentPanelContext(): IRotatingContentPanelContext | undefined {
        return { ...this.props.rotatingContentPanelContext};
    }

    getRotatingContentPanelDevices() {
        return this.props.rotatingContentPanelDevicesConstraint?.deviceTypes ?? [];
    }

    validateCifExperiment() {
        if (!this.props.region.marketplace) {
            throw new Error('Customer Targeting : Region : For CIF Experience, you need to select a locale');
        }

        if (this.props.content.contentType === 'referral' && this.props.content.actionType === ActionType.EMAIL && !this.props.content.campaignId) {
            throw new Error('Referral Content : For Sonar email Post-Cif action, Campaign ID must be set');
        }

        // Set a placeholder text for contentText if not defined by user Per Gus's requirement
        if (!this.props.region.locale !== !(this.props.content.contentText || this.props.content.referralQuestionContentText)) {
            this.props.content.contentText = '.';
        }
        // Clear content field for promptless
        if (!this.props.enableVoiceCif) {
            this.props.content.contentText = '';
        }

        // If unchecking useTouchActionTemplate,
        // clear button text, touch action and syntheticUtteranceOverrideText
        if (!this.props.useTouchActionTemplate) {
            this.props.visualInterstitialCards?.forEach(card => {
                card.buttonText = undefined;
                card.touchTargets = undefined;
                card.syntheticUtteranceOverrideText = undefined;
                return card;
            });
        }

        // If choose 'Synthetic Utterance' tab, clear touch action
        this.props.visualInterstitialCards?.filter(card => !_.isEmpty(card.syntheticUtteranceOverrideText)).forEach(card => card.touchTargets = undefined);

        // If choose 'Skill Connection' tab, clear synthetic Utterance Override Text
        this.props.visualInterstitialCards?.filter(card => !_.isEmpty(card.touchTargets)).forEach(card => card.syntheticUtteranceOverrideText = undefined);

        if (isDefaultActionType(this.props.content.actionType) &&
            (((this.props.content.referralQuestionContentText === undefined) !== (this.props.content.referralData === undefined)) ||
                ((this.props.content.referralQuestionContentText === null) !== (this.props.content.referralData === null)))) {
            throw new Error('CIF Injection : Content : For Yes/No Question, you need to provide both referral question and referral data');
        }
    }

    validateCampaign() {
        if (!this.props.region.locale) {
            throw new Error('Customer Targeting : Region : Notification experience must have locale configured');
        }
    }

    transformToExperience(userAlias: string): IExperience {
        // this is not to validate if this is a "valid" request for ACOPS
        // this is to validate if the candidate has all the requirements
        // filled out to have our transform function run without throwing
        if (!this.props.basicInfo.title || !this.props.basicInfo.title.trim()) {
            throw new Error('Experience Details : Basic Information : Experience must have a title');
        }

        if (this.props.type === 'CIF') {
            this.validateCifExperiment();
        } else if ((this.props.type === 'AppNotification' || this.props.type === 'DeviceNotification')) {
            this.validateCampaign();
        }

        if (this.props.metadata) {
            this.props.metadata.modifiedBy = userAlias;
        } else {
            this.props.metadata = {
                createdBy: userAlias,
                createdAt: Date.now(),
                modifiedBy: userAlias,
                modifiedAt: Date.now(),
                changedStateAt: Date.now()
            };
        }

        // preset for QuickCreateWorkflow
        if (this.props.isQuickCreateWorkflow) {
            this.props.region.enabled = true;
        }
        return {
            // common
            id: this.props.id,
            type: this.props.type,
            title: this.props.basicInfo.title,
            status: this.props.status,
            metadata: this.props.metadata,
            approvalId: this.props.approvalId,
            approvalInfo: this.props.approvalInfo,
            businessInfo: this.props.businessInfo,
            subscriptionUpsellType: this.props.subscriptionUpsellType,
            capabilityId: this.props.capabilityId,
            bypassCapabilityFilter: this.props.bypassCapabilityFilter,
            cancellationReason: this.props.cancellationReason,
            dayTimeRangeGuardrails: this.props.timeRanges,
            operatorGroup: this.props.basicInfo.groupImpressions,
            weblab: this.constructWeblab(),
            filters: this.constructFilters(),
            hintId: this.props.impressionTracking.hintId,
            contentId: this.props.impressionTracking.contentId,
            suggestedFeature: this.props.suggestedFeature,
            rowCountryInfo: this.props.rowCountryInfo,
            journeyMetadata: this.props.journeyMetadata,
            snsTopicBYORDataSource: this.props.snsTopicBYORDataSource,

            speakerData: this.constructSpeakerData(),
            // contents for CIF or Notifications
            ...this.constructContents(),

            // contents for VisualContent for CIF
            ...this.constructVisualContents(),

            // CIF
            guardrails: {
                overrideAccounts: this.props.overrideAccounts.map(cid => cid.trim()).filter(cid => cid),
                overrideSelectiveFilters: this.props.overrideSelectiveFilters,
                verboseLoggingEnabled: this.props.verboseLoggingEnabled
            },

            // TODO This should become the main source of updates
            // on the wire.
            opportunityTriggers: this.props.opportunityTriggerInclusions,
            opportunityTriggerExclusions: this.props.opportunityTriggerExclusions,

            nluOpportunityTargetings: this.props.nluOpportunityTargetingInclusions
                .filter(target => isNotEmptyTargeting(target))
                .map(target => processNluOpportunityTargeting(target)),
            nluOpportunityTargetingExclusions: this.props.nluOpportunityTargetingExclusions
                .filter(target => isNotEmptyTargeting(target))
                .map(target => processNluOpportunityTargeting(target)),
            featureHints: this.props.featureHints,
            templateVariables: processTemplateVariables(this.props.templateVariables),
            remoteContextualSegments: this.props.remoteContextualSegments,

            // Notifications
            dataSource: this.props.dataSourceInfo.dataSource,
            expiryTimeInHours: this.props.dataSourceInfo.ttl,
            publisherRoleForAutoCreatedSnsTopic: this.props.dataSourceInfo.publisherRoleForAutoCreatedSnsTopic,
            notificationGuardrails: this.props.notificationGuardrails
        };
    }

    transformToOdysseyExperience(userAlias: string): IOdysseyExperience {

        if (!this.props.basicInfo.title || !this.props.basicInfo.title.trim()) {
            throw new Error('Experience Details : Basic Information : Experience must have a title');
        }

        if (this.props.metadata) {
            this.props.metadata.modifiedAt = Date.now();
            this.props.metadata.modifiedBy = userAlias;
        } else {
            this.props.metadata = {
                createdBy: userAlias,
                createdAt: Date.now(),
                modifiedBy: userAlias,
                modifiedAt: Date.now(),
                changedStateAt: Date.now()
            };
        }

        let experienceSpecificProperties = {};
        switch (this.props.type) {
            case 'MobileHomeCard':
                experienceSpecificProperties = constructMobileHomeCardData(this.props.basicInfo, this.props.metadata, this.props.approvalInfo, this.props.approvalId, this.props.cancellationReason,
                    this.props.region, this.props.mobileHomeCardContent, this.props.mobileHomeCardActions, this.props.mobileHomeCardTargeting, this.props.mobileHomeCardConstraint,
                    this.props.mobileHomeCardAbExperiment, this.props.mobileHomeCardContext);
                break;
            case 'RotatingContentPanel':
                experienceSpecificProperties = constructRotatingContentPanelData(this.props);
        }
        return {
            id: this.props.id,
            type: this.props.type,
            title: this.props.basicInfo.title,
            status: this.props.status,
            operatorGroup: this.props.basicInfo.groupImpressions,
            ownerGsi: userAlias,
            ...experienceSpecificProperties
        };
    }

    static kickBackToDraft(status: ExperienceStatus): ExperienceStatus {
        return ['DRAFT', 'TESTABLE', 'UNDER_REVIEW'].includes(status)
            ? status
            : 'DRAFT';
    }

    static extractRegion(experience: IFlattenedExperience): IRegionViewAttributes {
        return {
            marketplace: experience.marketplace,
            locale: (experience.type === 'RotatingContentPanel') ? experience.contents?.[0].locale : experience.locale,
            enabled: experience.enabledFilter?.enabled || false
        };
    }

    static extractBasicInfo(experience: IFlattenedExperience): IBasicInfoViewAttributes {
        return {
            title: experience.title,
            groupImpressions: experience.operatorGroup,
            startDate: (experience.enabledFilter && experience.enabledFilter.activationConfiguration)
                ? experience.enabledFilter.activationConfiguration.startDate
                : undefined,
            endDate: (experience.enabledFilter && experience.enabledFilter.activationConfiguration)
                ? experience.enabledFilter.activationConfiguration.endDate
                : undefined,
            region: experience.region,
        };
    }

    static extractSpeakerData(experience: IFlattenedExperience): ISpeakerDataAttributes {
        return {
            userRole: (experience.speakerData && experience.speakerData.userRole)
                ? experience.speakerData.userRole
                : UserRole.ADULT
        };
    }

    static extractImpressionTracking(experience: IFlattenedExperience): IImpressionTrackingViewAttributes {
        const impressionData = {
            weblab: experience.weblab ? experience.weblab.name : experience.constraints?.GeneralConstraint.weblabName,
            weblabLaunched: experience.weblab ? experience.weblab.launched : undefined,
            hintId: experience.hintId || experience.contentId,
            contentId: experience.contentId
        };
        return impressionData;
    }

    static extractPromotedCapability(experience: IFlattenedExperience): string {
        return experience.capabilityId || '';
    }

    static extractBypassCapabilityFilter(experience: IFlattenedExperience): boolean {
        return experience.bypassCapabilityFilter || false;
    }

    static extractContentType(experience: IFlattenedExperience): IContentType {
        if (experience.content) {
            return 'content';
        } else if (experience.referralContent) {
            return 'referral';
        } else {
            return 'none';
        }
    }

    static extractContent(experience: IFlattenedExperience): IContentViewAttributes {
        return {
            contentText: (experience.locale && experience.content)
                ? experience.content[experience.locale]?.text
                : undefined,
            referralQuestionContentText: (experience.locale
                && experience.referralContent
                && experience.referralContent.localizedQuestion)
                ? experience.referralContent.localizedQuestion[experience.locale]?.text
                : undefined,
            referralData: (experience.locale && experience.referralContent)
                ? experience.referralContent.referralData
                : undefined,
            rejectionReferralData: (experience.locale && experience.referralContent)
                ? experience.referralContent.rejectionReferralData
                : undefined,
            contentType: this.extractContentType(experience),
            actionType: (experience.locale && experience.referralContent)
                ? experience.referralContent.actionType
                : undefined,
            sonarTemplateURI: (experience.locale && experience.referralContent)
                ? experience.referralContent.sonarTemplateURI
                : undefined,
            sonarTemplateJson: (experience.locale && experience.referralContent)
                ? experience.referralContent.sonarTemplateJson
                : undefined,
            campaignId: (experience.locale && experience.referralContent)
                ? experience.referralContent.campaignId
                : undefined,
            notificationPlatform: (experience.locale && experience.referralContent)
                ? experience.referralContent.notificationPlatform
                : undefined,
            customSuccessfulSendResponse: (experience.locale && experience.referralContent)
                ? experience.referralContent.customSuccessfulSendResponse
                : undefined,
        };
    }

    static extractVisualInterstitialCards(experience: IFlattenedExperience): IManagementCard[] {
        return (experience.locale &&
            experience.visualContent &&
            experience.visualContent[experience.locale] &&
            experience.visualContent[experience.locale].aplDataSource)
            ? experience.visualContent[experience.locale].aplDataSource.cards
            : [];
    }

    static extractCustomDomainIllustration(experience: IFlattenedExperience): boolean[] {
        const customDomainIllustration: boolean[] = [];
        const cards = this.extractVisualInterstitialCards(experience);
        if (cards.length === 0) {
            return customDomainIllustration;
        }
        const preConfiguredUrls: Set<string> = new Set(DomainIllustrationDefaultItems.map((option) => { return option.value; }));
        for (const card of cards) {
            // if (a card has imageLink and the link is in the list of preconfigured links) OR (it does not have an imageLink)
            // then we consider it to be preconfigured and NOT custom.
            customDomainIllustration.push(!((card.imageLink && preConfiguredUrls.has(card.imageLink)) || !card.imageLink));
        }
        return customDomainIllustration;
    }

    static extractOverrideDefaultBackgroundImages(experience: IFlattenedExperience): boolean[] {
        const overrideDefaultBackgroundImages: boolean[] = [];
        const cards = this.extractVisualInterstitialCards(experience);
        if (cards.length === 0) {
            return overrideDefaultBackgroundImages;
        }
        for (const card of cards) {
            overrideDefaultBackgroundImages.push(
                !!card.hubLandscapeSmallBackgroundImage || !!card.hubLandscapeMediumBackgroundImage ||
                !!card.hubLandscapeLargeBackgroundImage || !!card.hubLandscapeXLargeBackgroundImage
            );
        }
        return overrideDefaultBackgroundImages;
    }

    static extractEnableVoiceCif(experience: IFlattenedExperience): boolean {
        return (experience.locale &&
            experience.visualContent &&
            experience.visualContent[experience.locale])
            ? !experience.visualContent[experience.locale].promptlessContent
            : true;
    }

    static extractIncludingOdysseyFields(experience: IFlattenedExperience): boolean {
        return (experience.locale &&
            experience.visualContent &&
            experience.visualContent[experience.locale])
            ? experience.visualContent[experience.locale].includingOdysseyFields || false
            : false;
    }

    static extractDevices(experience: IFlattenedExperience): string[] {
        return experience.enabledFilter
            ? experience.enabledFilter.deviceTypes || []
            : [];
    }

    static extractBusinessInfo(experience: IFlattenedExperience): IBusinessMetadata {
        return experience.businessInfo
            ? experience.businessInfo
            : {};
    }

    static extractLaunchTreatmentPercentage(experience: IFlattenedExperience): number | undefined {
        return experience.launchTreatmentPercentage;
    }

    static extractContextualSegments(experience: IFlattenedExperience): IContextualSegments {
        return experience.enabledFilter
            ? experience.enabledFilter?.contextualSegments || EMPTY_CONTEXTUAL_SEGMENTS
            : EMPTY_CONTEXTUAL_SEGMENTS;
    }

    static extractRemoteContextualSegments(experience: IFlattenedExperience): IRemoteContextualSegments {
        return experience.remoteContextualSegments || EMPTY_REMOTE_CONTEXTUAL_SEGMENTS;
    }

    static extractNotificationContent(experience: IFlattenedExperience): INotificationExperienceContent {
        const locale = experience.locale;
        if (!locale) return {
            title: undefined,
            displayContent: undefined,
            spokenContent: undefined,
            url: undefined,
            webUrl: undefined,
            deepLinkUrl: undefined,
            storeUrls: undefined,
            appNotificationType: undefined,
            ctaButtonTitle: undefined,
            ctaButtonAction: undefined,
            interactionPayload: undefined,
            notificationThumbnail: undefined,
        };

        return experience.notificationContent
            ? ExperienceUpdateCandidate.deepClone(experience.notificationContent[locale] || {})
            : {};
    }

    static extractDataSource(experience: IFlattenedExperience): IDataSourceViewAttributes {
        const { enabledFilter, dataSource } = experience;
        const bullseyeId = enabledFilter && enabledFilter.bullseye; // bullseye === 0 is valid
        const snsTopic = enabledFilter && enabledFilter.snsTopic;
        const publisherRoleForAutoCreatedSnsTopic = experience.publisherRoleForAutoCreatedSnsTopic;
        const bullseyeVersion = enabledFilter && enabledFilter.bullseyeVersion;
        const ttl = experience.expiryTimeInHours;
        return {
            dataSource,
            bullseyeId,
            snsTopic,
            publisherRoleForAutoCreatedSnsTopic,
            bullseyeVersion, // should not be changed
            ttl
        };
    }

    static extractsnsTopicBYORDataSource(experience: IFlattenedExperience): string | undefined {
        return experience.snsTopicBYORDataSource;
    }

    static extractJourneyMetadata(experience: IFlattenedExperience): IJourneyMetadata {
        return experience.journeyMetadata ?
            {
                journeyId: experience.journeyMetadata?.journeyId,
                journeyName: experience.journeyMetadata?.journeyName
            } : {};
    }

    static extractNotificationGuardrails(experience: IFlattenedExperience): INotificationGuardrails {
        return experience.notificationGuardrails ?
            {
                overrideContentGuardrail: experience.notificationGuardrails.overrideContentGuardrail,
                overrideFrequencyGuardrail: experience.notificationGuardrails.overrideFrequencyGuardrail,
                contentGuardrailOverrideDays: experience.notificationGuardrails.contentGuardrailOverrideDays,
                frequencyGuardrailOverrideDays: experience.notificationGuardrails.frequencyGuardrailOverrideDays,
                notificationExpirationOverrideDays: experience.notificationGuardrails.notificationExpirationOverrideDays
            } : {};
    }

    static cloneExperience(experience: IFlattenedExperience): ExperienceUpdateCandidate {
        experience = ExperienceUpdateCandidate.deepClone(experience);

        // remove all relevant fields when cloning
        delete experience.id;
        delete experience.approvalId;
        delete experience.approvalInfo;
        delete experience.title;
        delete experience.cancellationReason;
        delete experience.enabledFilter?.bullseyeVersion;
        delete experience.weblab.launched;
        delete experience.launchTreatmentPercentage;
        delete experience.notificationGuardrails?.overrideContentGuardrail;
        delete experience.notificationGuardrails?.contentGuardrailOverrideDays;
        delete experience.notificationGuardrails?.overrideFrequencyGuardrail;
        delete experience.notificationGuardrails?.frequencyGuardrailOverrideDays;
        experience.status = 'DRAFT';

        const candidate = new ExperienceUpdateCandidate(ExperienceUpdateCandidate.extractUpdateCandidate(experience));
        delete candidate.props.metadata;
        if (experience.dataSource === 'AUTO_CREATED_SNS_TOPIC') {
            delete candidate.props.dataSourceInfo.snsTopic;
        }
        return candidate;
    }

    static createExperience(experience: IFlattenedExperience): ExperienceUpdateCandidate {
        experience = ExperienceUpdateCandidate.deepClone(experience);

        // remove all relevant fields when creating
        delete experience.id;
        delete experience.title;
        experience.status = 'DRAFT';

        const candidate = new ExperienceUpdateCandidate(ExperienceUpdateCandidate.extractUpdateCandidate(experience));
        delete candidate.props.metadata;

        // Making Quick create workflow as default
        candidate.setIsQuickCreateWorkflow(true);

        // Making enable voice cif as default
        candidate.setEnableVoiceCif(true);

        // Making touch action template as default
        candidate.setUseTouchActionTemplate(true);

        // Making isNonCTA template to false as default
        candidate.setUseNonCTATemplate(false);

        return candidate;
    }

    static deepClone<T>(d: T): T {
        return JSON.parse(JSON.stringify(d));
    }

    static extractExactUpdateCandidate(experience: IFlattenedExperience): IExperienceUpdateCandidate {
        return {
            id: experience.id,
            type: experience.type,
            status: experience.status,
            metadata: experience.metadata,
            approvalId: experience.approvalId,
            approvalInfo: experience.approvalInfo,
            businessInfo: experience.businessInfo,
            subscriptionUpsellType: experience.subscriptionUpsellType,
            cancellationReason: experience.cancellationReason,
            region: ExperienceUpdateCandidate.extractRegion(experience),
            timeRanges: experience.dayTimeRangeGuardrails || getDefaultDayTimeGuardrails(experience.type, experience.visualContent? true: false),
            basicInfo: ExperienceUpdateCandidate.extractBasicInfo(experience),
            impressionTracking: ExperienceUpdateCandidate.extractImpressionTracking(experience),
            capabilityId: ExperienceUpdateCandidate.extractPromotedCapability(experience),
            bypassCapabilityFilter: ExperienceUpdateCandidate.extractBypassCapabilityFilter(experience),
            suggestedFeature: experience.suggestedFeature,
            content: ExperienceUpdateCandidate.extractContent(experience),
            templateVariables: experience.templateVariables || [],
            devices: ExperienceUpdateCandidate.extractDevices(experience),
            launchTreatmentPercentage: experience.launchTreatmentPercentage,
            rowCountryInfo: experience.rowCountryInfo,
            snsTopicBYORDataSource: experience.snsTopicBYORDataSource,
            journeyMetadata: experience.journeyMetadata,

            overrideAccounts: experience.guardrails?.overrideAccounts || [],
            overrideSelectiveFilters: experience.guardrails?.overrideSelectiveFilters || [],
            verboseLoggingEnabled: experience.guardrails?.verboseLoggingEnabled || false,

            visualInterstitialCards: ExperienceUpdateCandidate.extractVisualInterstitialCards(experience),

            enableVoiceCif: ExperienceUpdateCandidate.extractEnableVoiceCif(experience),
            includingOdysseyFields: ExperienceUpdateCandidate.extractIncludingOdysseyFields(experience),

            contextualSegments: ExperienceUpdateCandidate.extractContextualSegments(experience),
            remoteContextualSegments: ExperienceUpdateCandidate.extractRemoteContextualSegments(experience),

            featureHints: experience.featureHints || [],

            bullseye: experience.enabledFilter?.bullseye || extractBullseyeSegmentId(experience),
            speakerData: ExperienceUpdateCandidate.extractSpeakerData(experience),

            /** We need to make backend changes to incorporate the new NLU trigger model to completely leverage the model and avoid
             * the following conversion logic between the edit states
             */

            opportunityTriggerInclusions: processOpportunityTrigger(experience.nluOpportunityTargetings) || [],
            opportunityTriggerExclusions: processOpportunityTrigger(experience.nluOpportunityTargetingExclusions) || [],
            nluOpportunityTargetingInclusions: experience.nluOpportunityTargetings || [],
            nluOpportunityTargetingExclusions: experience.nluOpportunityTargetingExclusions || [],
            notificationContent: ExperienceUpdateCandidate.extractNotificationContent(experience),
            notificationGuardrails: ExperienceUpdateCandidate.extractNotificationGuardrails(experience),
            dataSourceInfo: ExperienceUpdateCandidate.extractDataSource(experience),

            isVisualCIF: (experience.visualContent && experience.visualContent !== null) || false,

            useTouchActionTemplate: isTouchActionTemplate(experience),
            useNonCTATemplate: isNonCTATemplateExperience(experience),
            domainIllustrationCustomSource: ExperienceUpdateCandidate.extractCustomDomainIllustration(experience),
            overrideBackgroundImage: ExperienceUpdateCandidate.extractOverrideDefaultBackgroundImages(experience),
            acknowledgeBackgroundImage: ExperienceUpdateCandidate.extractOverrideDefaultBackgroundImages(experience),

            // mobile home card
            mobileHomeCardContent: extractMobileHomeCardContent(experience),
            mobileHomeCardActions: extractMobileHomeCardAction(experience),
            mobileHomeCardConstraint: extractMobileHomeCardConstraint(experience),
            mobileHomeCardAbExperiment: extractMobileHomeCardAbExperiment(experience),
            mobileHomeCardTargeting: extractMobileHomeCardTargeting(experience),
            mobileHomeCardContext: extractMobileHomeCardContext(experience),

            // RCP
            rotatingContentPanelContent: extractRotatingContentPanelContent(experience),
            rotatingContentPanelTestingConstraint: extractRotatingContentPanelTestingConstraint(experience),
            rotatingContentPanelSchedulingConstraint: extractRotatingContentPanelSchedulingConstraint(experience),
            rotatingContentPanelDevicesConstraint: extractRotatingContentPanelDevicesConstraint(experience),
            rotatingContentPanelBusinessContext: extractRotatingContentPanelBusinessContext(experience),
            rotatingContentPanelContext: extractRotatingContentPanelContext(experience)
        };
    }
    static extractUpdateCandidate(experience: IFlattenedExperience): IExperienceUpdateCandidate {
        return {
            ...ExperienceUpdateCandidate.extractExactUpdateCandidate(experience),
            status: experience.status
        };
    }

    isEqualTo(updateCandidate: ExperienceUpdateCandidate): boolean {
        return JSON.stringify(this) === JSON.stringify(updateCandidate);
    }
}
