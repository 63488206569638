import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { getLinkableUrl, PAGE } from '../../constants/page';
import { AppState } from '../../reducers/index';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { BreadcrumbGroup, Flash, Flashbar, Tabs } from '@amzn/awsui-components-react';
import { AnnouncementAlertView } from './../sections/OdysseyAnnouncementView';
import { getOdysseyAnnouncementAction } from '../../actions/odysseyAnnouncementActions';
import { ExperienceType } from '../../models/Experience';
import OdysseyExperienceDetail from './OdysseyExperienceDetail';
import { getOdysseyExperienceAction } from '../../actions/v2/odysseyExperienceDetailViewActions';
import { getPermissionAction } from '../../actions/authenticationActions';
import { cancelEditingAction } from '../../actions/experienceEditViewActions';
import OdysseyExperienceMetrics from './OdysseyExperienceMetrics';
import { getRcpCXDailyMetricsAction, getRcpCXWeeklyMetricsAction } from '../../actions/experienceMetricsViewActions';
import { OdysseyExperienceMetricFrequency, RcpMetricDDBNames } from '../../models/v2/metric/RCPMetrics';
import { MOBILE_HOME_CARD_USER_GROUP, RCP_CREATORS_USER_GROUP } from '../../constants/userGroups';

export interface IOdysseyExperienceProps {
    match: { params: { id: string } };
    experiencesLinkableUrl: string;

    dispatch: any;

    experience?: IFlattenedExperience;
    isLoading: boolean;
    error?: Error;

    // from edit
    isUpdating: boolean;

    isCXDailyMetricsLoading?: boolean;
    isCXWeeklyMetricsLoading?: boolean;
    cxDailyMetricsError?: Error;
    cxWeeklyMetricsError?: Error;
}

export interface IOdysseyExperienceState {
    activeTabId: string;
}

class OdysseyExperience extends Component<IOdysseyExperienceProps, IOdysseyExperienceState> {
    constructor(props: IOdysseyExperienceProps) {
        super(props);

        this.state = {
            activeTabId: 'experience-configuration',
        };
    }

    public componentDidMount() {
        const { match, dispatch } = this.props;

        dispatch(getOdysseyExperienceAction(match.params.id));
        dispatch(getOdysseyAnnouncementAction());
        dispatch(getPermissionAction(MOBILE_HOME_CARD_USER_GROUP));
        dispatch(getPermissionAction(RCP_CREATORS_USER_GROUP));
    }

    public componentDidUpdate() {
        const { dispatch, experience, isLoading, error, match } = this.props;

        if (!experience && !isLoading && !error) {
            dispatch(getOdysseyExperienceAction(match.params.id));
        }
    }

    render() {
        const { isLoading, error, isUpdating, experience, experiencesLinkableUrl, dispatch,
            isCXDailyMetricsLoading, isCXWeeklyMetricsLoading, cxDailyMetricsError, cxWeeklyMetricsError } = this.props;
        const { activeTabId } = this.state;

        if (error) {
            return <Flashbar id='experience.error-bar' items={[{
                header: 'Loading failed',
                content: 'Failed to find your experience',
                type: 'error'
            }]} />;
        }

        if (isLoading) {
            return <Flash id='experience.loading-bar' dismissible={false} >Loading...</Flash>;
        }

        if (isUpdating) {
            return <Flash id='experience.updating-bar' dismissible={false} >Updating...</Flash>;
        }

        if (!experience) {
            return <Flash dismissible={true} type='error' id='experience.error-bar' >
                Something happened while trying to load your experience...
            </Flash>;
        }

        return <div>
            <AnnouncementAlertView />
            <div style={{ margin: '30px' }}>
                <BreadcrumbGroup label='Breadcrumbs' items={[{
                    text: 'Odyssey',
                    href: getLinkableUrl(PAGE.Home),
                }, {
                    text: 'Experiences',
                    href: getLinkableUrl(experiencesLinkableUrl),
                }, {
                    text: experience.title || '-',
                    href: getLinkableUrl(experiencesLinkableUrl) + '/' + experience.id
                }]} />
                <Tabs
                    id='odyssey.experience.tabs'
                    activeTabId={activeTabId}
                    tabs={getExperienceTabs(experience.type, this.props)}
                    onChange={(e) => {
                        this.setState({ activeTabId: e.detail.activeTabId });
                        if (e.detail.activeTabId === 'metrics') {
                            dispatch(cancelEditingAction());

                            if (experience && !isCXDailyMetricsLoading && !isCXWeeklyMetricsLoading && !cxDailyMetricsError && !cxWeeklyMetricsError) {
                                const experienceIds = [experience.id];
                                const metricFilter = ([RcpMetricDDBNames.Impression, RcpMetricDDBNames.Voice, RcpMetricDDBNames.Touch]);

                                dispatch(getRcpCXDailyMetricsAction(experienceIds, 'RotatingContentPanel', OdysseyExperienceMetricFrequency.Day, 7, metricFilter));
                                dispatch(getRcpCXWeeklyMetricsAction(experienceIds, 'RotatingContentPanel', OdysseyExperienceMetricFrequency.Week, 7, metricFilter));
                            }
                        }

                    }} />
            </div>
        </div>;
    }
}

const getExperienceTabs = (type: ExperienceType, props: IOdysseyExperienceProps): Tabs.Tab[] => {
    if (type === 'RotatingContentPanel') {
        return [{
            label: 'Experience Configuration',
            id: 'experience-configuration',
            content: <OdysseyExperienceDetail {...props} />
        }, {
            label: 'Metrics',
            id: 'metrics',
            content: <OdysseyExperienceMetrics {...props} />
        }];
    } else {
        return [{
            label: 'Experience Configuration',
            id: 'experience-configuration',
            content: <OdysseyExperienceDetail {...props} />
        }];
    }
};

const mapStateToProps = ({ odysseyExperienceDetailViewState, experienceEditViewState, rcpCXDailyMetricsState, rcpCXWeeklyMetricsState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isLoading has default value set in reducer, so no default
        // value needed
        experience: odysseyExperienceDetailViewState.experience || undefined,
        isLoading: odysseyExperienceDetailViewState.isLoading,
        error: odysseyExperienceDetailViewState.error || undefined,
        isUpdating: experienceEditViewState.isUpdating,

        isCXDailyMetricsLoading: rcpCXDailyMetricsState.isLoading,
        isCXWeeklyMetricsLoading: rcpCXWeeklyMetricsState.isLoading,
        cxDailyMetricsError: rcpCXDailyMetricsState.error,
        cxWeeklyMetricsError: rcpCXWeeklyMetricsState.error
    };
};

export default connect(mapStateToProps)(OdysseyExperience);
