import { AnyAction, Dispatch } from 'redux';
import { IProviderConfig, ISpiProviderConfigs, IVersionedObject } from '../models/spiProviderConfig/ISpiProviderOnboarding';
import {
    LOADING_PROVIDER_CONFIGS,
    LOADED_PROVIDER_CONFIGS,
    ERROR_LOADING_PROVIDER_CONFIGS,
    LOADING_PROVIDER_VERSION_HISTORY,
    LOADED_PROVIDER_VERSION_HISTORY,
    ERROR_LOADING_PROVIDER_VERSION_HISTORY,
    UPSERTING_PROVIDER_CONFIG,
    UPSERTED_PROVIDER_CONFIG,
    ERROR_UPSERTING_PROVIDER_CONFIG,
    DELETING_PROVIDER_CONFIG,
    DELETED_PROVIDER_CONFIG,
    ERROR_DELETING_PROVIDER_CONFIG,
    LOADING_PROVIDER_CONFIG_BY_ID,
    LOADED_PROVIDER_CONFIG_BY_ID,
    ERROR_LOADING_PROVIDER_CONFIG_BY_ID,
    ENABLE_CONFIG_EDIT,
    DISABLE_CONFIG_EDIT,
    CANCEL_CONFIG_EDIT,
    UPDATE_CURRENT_PROVIDER_CONFIG,
    SET_CONFIG_TO_VERSION,
    LOADING_PROVIDER_CONFIG_BY_ID_AND_VERSION,
    LOADED_PROVIDER_CONFIG_BY_ID_AND_VERSION,
    ERROR_LOADING_PROVIDER_CONFIG_BY_ID_AND_VERSION,
    ENABLE_ONBOARDING_PROVIDER,
    DISABLE_ONBOARDING_PROVIDER,
    UPDATE_FORM_VALIDATION_SECTION,
    SET_COMPARE_VERSIONS
} from '../constants/spiProviderOnboardingActionTypes';
import {
    getAllProviderConfigs, getProviderConfigVersions, upsertProviderConfigs, deleteProviderConfigs, getProviderConfigById,
    getProviderConfigByIdAndVersion} from '../api/spiProviderOnboardingApi';
import { AppState } from '../reducers';
import { filterProviderConfigEndpoints } from '../util/spiProviderOnboardingHelper';
import { Table } from '@amzn/awsui-components-react';

// action creators for loading provider configs
export const loadingProviderConfigs = (): AnyAction => ({
    type: LOADING_PROVIDER_CONFIGS
});

export const loadedProviderConfigs = (spiProviderConfigs: ISpiProviderConfigs): AnyAction => ({
    type: LOADED_PROVIDER_CONFIGS,
    payload: spiProviderConfigs
});

export const errorLoadingProviderConfigs = (error: Error): AnyAction => ({
    type: ERROR_LOADING_PROVIDER_CONFIGS,
    payload: error
});

// action creators for loading version history
export const loadingProviderConfigVersionHistory = (): AnyAction => ({
    type: LOADING_PROVIDER_VERSION_HISTORY
});

export const loadedProviderConfigVersionHistory = (providerId: string, versionHistory: { [key: string]: IVersionedObject<IProviderConfig> }): AnyAction => ({
    type: LOADED_PROVIDER_VERSION_HISTORY,
    payload: { providerId, versionHistory }
});

export const errorLoadingProviderConfigVersionHistory = (error: Error): AnyAction => ({
    type: ERROR_LOADING_PROVIDER_VERSION_HISTORY,
    payload: error
});

// action creators for updating a provider config
export const upsertingProviderConfig = (): AnyAction => ({
    type: UPSERTING_PROVIDER_CONFIG
});

export const upsertedProviderConfig = (providerId: string, providerConfig: IProviderConfig): AnyAction => ({
    type: UPSERTED_PROVIDER_CONFIG,
    payload: { providerId, providerConfig }
});

export const errorUpsertingProviderConfig = (error: Error): AnyAction => ({
    type: ERROR_UPSERTING_PROVIDER_CONFIG,
    payload: error
});

// action creators for deleting a provider config
export const deletingProviderConfig = (): AnyAction => ({
    type: DELETING_PROVIDER_CONFIG
});

export const deletedProviderConfig = (providerId: string): AnyAction => ({
    type: DELETED_PROVIDER_CONFIG,
    payload: providerId
});

export const errorDeletingProviderConfig = (error: Error): AnyAction => ({
    type: ERROR_DELETING_PROVIDER_CONFIG,
    payload: error
});

// action creators for loading a provider config by id
export const loadingProviderConfigById = (): AnyAction => ({
    type: LOADING_PROVIDER_CONFIG_BY_ID
});

export const loadedProviderConfigById = (providerConfig: IProviderConfig): AnyAction => ({
    type: LOADED_PROVIDER_CONFIG_BY_ID,
    payload: providerConfig
});

export const errorLoadingProviderConfigById = (error: Error): AnyAction => ({
    type: ERROR_LOADING_PROVIDER_CONFIG_BY_ID,
    payload: error
});

// action creators for loading a provider config by version
export const loadingProviderConfigByIdAndVersion = (): AnyAction => ({
    type: LOADING_PROVIDER_CONFIG_BY_ID_AND_VERSION
});

export const loadedProviderConfigByIdAndVersion = (providerConfig: IProviderConfig): AnyAction => ({
    type: LOADED_PROVIDER_CONFIG_BY_ID_AND_VERSION,
    payload: providerConfig
});

export const errorLoadingProviderConfigByIdAndVersion = (error: Error): AnyAction => ({
    type: ERROR_LOADING_PROVIDER_CONFIG_BY_ID_AND_VERSION,
    payload: error
});


// thunk action creators
export const getAllProviderConfigsAction = () => async (dispatch: Dispatch) => {
    dispatch(loadingProviderConfigs());

    try {
        const spiProviderConfigs = await getAllProviderConfigs();
        dispatch(loadedProviderConfigs(spiProviderConfigs));
    } catch (error) {
        dispatch(errorLoadingProviderConfigs(error as Error));
    }
};

export const getProviderConfigVersionsAction = (providerId: string) => async (dispatch: Dispatch) => {
    dispatch(loadingProviderConfigVersionHistory());

    try {
        const versions = await getProviderConfigVersions(providerId);
        dispatch(loadedProviderConfigVersionHistory(providerId, versions));
    } catch (error) {
        dispatch(errorLoadingProviderConfigVersionHistory(error as Error));
    }
};

export const deleteProviderConfigsAction = (providerId: string) => async (dispatch: Dispatch) => {
    dispatch(deletingProviderConfig());

    try {
        await deleteProviderConfigs(providerId);
        dispatch(deletedProviderConfig(providerId));
    } catch (error) {
        dispatch(errorDeletingProviderConfig(error as Error));
    }
};

export const upsertProviderConfigsAction = (providerId: string, providerConfig: IProviderConfig) => async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(upsertingProviderConfig());
    const state = getState().spiProviderOnboardingState;
    const currentProviderConfig = state.currentProviderConfig;
    if (currentProviderConfig) {
        try {
            const filteredConfig = filterProviderConfigEndpoints(currentProviderConfig);
            await upsertProviderConfigs(providerId, filteredConfig);
            dispatch(upsertedProviderConfig(providerId, filteredConfig));
        } catch (error) {
            dispatch(errorUpsertingProviderConfig(error as Error));
        }
    }
};

export const getProviderConfigByIdAction = (providerId: string) => async (dispatch: Dispatch) => {
    dispatch(loadingProviderConfigById());

    try {
        const providerConfig = await getProviderConfigById(providerId);
        dispatch(loadedProviderConfigById(providerConfig));
    } catch (error) {
        dispatch(errorLoadingProviderConfigById(error as Error));
    }
};

export const getProviderConfigByIdAndVersionAction = (providerId: string, versionId: string) => async (dispatch: Dispatch) => {
    dispatch(loadingProviderConfigByIdAndVersion());

    try {
        const providerConfig = await getProviderConfigByIdAndVersion(providerId, versionId);
        dispatch(loadedProviderConfigByIdAndVersion(providerConfig));
    } catch (error) {
        dispatch(errorLoadingProviderConfigByIdAndVersion(error as Error));
    }
};

export const setCurrentConfigToVersionAction = (config: IProviderConfig) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_CONFIG_TO_VERSION,
        payload: config
    });
};

export const enableConfigEditingAction = () => async (dispatch: Dispatch) => {
    dispatch({
        type: ENABLE_CONFIG_EDIT
    });
};

// to make sure edit is disabled by default when opening an existing config page
export const disableConfigEditingAction = () => async (dispatch: Dispatch) => {
    dispatch({
        type: DISABLE_CONFIG_EDIT
    });
};

// For the cancel button
export const cancelConfigEditingAction = (providerId: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: CANCEL_CONFIG_EDIT,
        payload: providerId
    });
};

export const updateCurrentProviderConfigAction = (currentProviderConfig: IProviderConfig) => async (dispatch: Dispatch) => {
    dispatch({
        type: UPDATE_CURRENT_PROVIDER_CONFIG,
        payload: currentProviderConfig
    });
};

export const enableOnboardingProviderAction = () => async (dispatch: Dispatch) => {
    dispatch({
        type: ENABLE_ONBOARDING_PROVIDER
    });
};

export const disableOnboardingProviderAction = () => async (dispatch: Dispatch) => {
    dispatch({
        type: DISABLE_ONBOARDING_PROVIDER
    });
};

export const updateFormValidationSectionAction = (section: string, isInvalid: boolean) => async (dispatch: Dispatch) => {
    dispatch({
        type: UPDATE_FORM_VALIDATION_SECTION,
        payload: { section, isInvalid }
    });
};

export const setCompareVersionsAction = (newVersions: Table.Item[]) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_COMPARE_VERSIONS,
        payload: newVersions
    });
};