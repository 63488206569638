export enum SubscriptionUpsellType {
    NOT_UPSELL = 'Not Upsell',
    BANYAN = 'Banyan',
    PRIME = 'Prime',
    MUSIC = 'Music',
    AUDIBLE = 'Audible',
    KIDS_PLUS = 'Kids+',
    ALEXA_TOGETHER = 'Alexa Together',
    IN_SKILL_PURCHASE = 'In Skill Purchase'
}
