import {
    GetOdysseyExperiencesPaginatePath, OdysseyExperiencePath, API_ENDPOINT, GetOdysseyExperimentPath, GenerateOdysseyExperimentApprovalPath
} from '../constants/lambdaEndpoints';
import { axios } from './axios';
import { IOdysseyExperience, IOdysseyExperiencePage } from '../models/v2/IOdysseyExperience';

export interface PutOdysseyExperienceResponse {
    id: string;
}

export interface GetOdysseyExperienceResponse {
    experiment: IOdysseyExperience;
}

export interface DeleteOdysseyExperienceResponse {
    succeeded: boolean;
}

export interface GenerateOdysseyExperimentApprovalResponse {
    approvalId: string;
}

export interface GenerateOdysseyExperimentApprovalRequest {
    experienceId: string;
    approvalSimDescription: string;
}

export async function getOdysseyExperiencesPaginate(limit: number, exclusiveStartKey?: string): Promise<IOdysseyExperiencePage> {
    const searchParams = new URLSearchParams();

    appendParamIfPresent(searchParams, 'limit', limit.toString());
    appendParamIfPresent(searchParams, 'exclusiveStartKey', exclusiveStartKey);

    const response = await axios.get(`${API_ENDPOINT}${GetOdysseyExperiencesPaginatePath}?${searchParams.toString()}`);
    return response.data;
}

export async function getOdysseyExperience(id: string): Promise<GetOdysseyExperienceResponse> {
    const response = await axios.get(`${API_ENDPOINT}${GetOdysseyExperimentPath}/${id}`);
    return response.data;
}

export async function deleteOdysseyExperience(id: string): Promise<DeleteOdysseyExperienceResponse> {
    const response = await axios.delete(`${API_ENDPOINT}${OdysseyExperiencePath}/${id}`);
    return response.data;
}

// returns ID
export async function putOdysseyExperience(experience: IOdysseyExperience): Promise<PutOdysseyExperienceResponse> {
    try {
        const response = await axios.post(`${API_ENDPOINT}${OdysseyExperiencePath}`, experience);
        return response.data;  // experience ID
    } catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data);
        } else {
            throw error;
        }
    }
}

export async function generateOdysseyExperimentApproval(
    experienceId: string,
    simDescription: string
): Promise<GenerateOdysseyExperimentApprovalResponse> {
    const searchParams = new URLSearchParams();
    appendParamIfPresent(searchParams, 'experienceId', experienceId);
    appendParamIfPresent(searchParams, 'approvalSimDescription', simDescription);
    try {
        const response = await axios.post(`${API_ENDPOINT}${GenerateOdysseyExperimentApprovalPath}?${searchParams.toString()}`);
        return response.data;  // approval ID
    } catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data);
        } else {
            throw error;
        }
    }
}


export const appendParamIfPresent = (params: URLSearchParams, key: string, value?: string) => {
    if (value) {
        params.append(key, value);
    }
};
