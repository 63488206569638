import * as React from 'react';
import { connect } from 'react-redux';
import { PromotedExperimentsSectionView } from './sections/PromotedExperimentsView';
import { getPromotedExperimentsAction, updatePromotedExperimentsAction } from '../actions/promotedExperimentsActions';
import { IPromotedExperiments, IPromotedExperimentsInfo } from '../models/promotedExperiments/PromotedExperiments';
import { AppState } from '../reducers';
import { Button, ColumnLayout, Flash, FormField, Icon, Modal } from '@amzn/awsui-components-react';
import { InputWrapper } from '../components/common/InputWrapper';
import { CommonValidator } from '../util/CommonValidator';


interface PromotedExperimentsUpdateWidgetProps {
    dispatch: any;
    isLoading: boolean;
    isPromotedExperimentsUpdated: boolean;
    errorUpdating?: Error;
    errorLoading?: Error;
    promotedExperiments?: IPromotedExperiments;
}

interface PromotedExperimentsUpdateWidgetState {
    updatePromotedExperimentsInfoList?: IPromotedExperimentsInfo[];
    isShowingConfirmationModal: boolean;
}

class PromotedExperimentsUpdateWidget extends React.Component<PromotedExperimentsUpdateWidgetProps, PromotedExperimentsUpdateWidgetState> {
    constructor(props: PromotedExperimentsUpdateWidgetProps) {
        super(props);

        this.state = {
            updatePromotedExperimentsInfoList: undefined,
            isShowingConfirmationModal: false
        };
    }

    dismissConfirmationModal() {
        this.setState({
            isShowingConfirmationModal: false
        });
    }

    showConfirmationModal() {
        this.setState({
            isShowingConfirmationModal: true
        });
    }

    updatePromotedExperiments(value: IPromotedExperimentsInfo[]) {
        this.setState({
            updatePromotedExperimentsInfoList: value
        });
    }

    copyPromotedExperimentsToEditPane() {
        const { promotedExperiments } = this.props;
        this.setState({
            updatePromotedExperimentsInfoList: promotedExperiments?.promotedExperimentsInfoList || []
        });
    }

    savePromotedExperiments() {
        const { dispatch } = this.props;
        const { updatePromotedExperimentsInfoList } = this.state;

        if (updatePromotedExperimentsInfoList !== undefined) {
            dispatch(updatePromotedExperimentsAction({
                promotedExperimentsInfoList: updatePromotedExperimentsInfoList,
                type: 'PromotedExperiments'
            }));
            this.resetPromotedExperiments();
        }
        this.dismissConfirmationModal();
    }

    resetPromotedExperiments() {
        this.setState({
            updatePromotedExperimentsInfoList: undefined
        });
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(getPromotedExperimentsAction());
    }

    componentDidUpdate() {
        const { isPromotedExperimentsUpdated, dispatch, isLoading } = this.props;
        if (isPromotedExperimentsUpdated && !isLoading) {
            dispatch(getPromotedExperimentsAction());
        }
    }

    render() {
        const { isLoading, errorUpdating } = this.props;

        const { updatePromotedExperimentsInfoList } = this.state;
        const updatePromotedExperimentsInfoListCopy: IPromotedExperimentsInfo[] = updatePromotedExperimentsInfoList
            ? JSON.parse(JSON.stringify(updatePromotedExperimentsInfoList))
            : undefined;

        return <div className='awsui-util-no-gutter'>
            {errorUpdating && <Flash type='error' dismissible={true} >{errorUpdating.message}</Flash>}
            <div className='awsui-util-m-xl'>
                <PromotedExperimentsSectionView />
                <div className='awsui-util-m-l'>
                    <Button
                        id='button.edit-promoted-experiments'
                        onClick={this.copyPromotedExperimentsToEditPane.bind(this)}
                        icon='copy' disabled={isLoading}
                    >Edit promoted experiments</Button>
                </div>
                {updatePromotedExperimentsInfoListCopy?.map((promotedExperiment, index) =>
                    <div>
                        <ColumnLayout columns={2}>
                            <div data-awsui-column-layout-root='true'>
                                <FormField label='Experience Id'>
                                    <InputWrapper
                                        id={`input.promoted-experiment-id-${index}`}
                                        readonly={false}
                                        value={promotedExperiment.experimentId}
                                        onInput={(input: string) => {
                                            updatePromotedExperimentsInfoListCopy[index].experimentId = input;
                                            this.updatePromotedExperiments(updatePromotedExperimentsInfoListCopy);

                                        }}
                                        validate={(value) => { return CommonValidator.isExperienceIdValid(value); }} />
                                </FormField>
                                <FormField label='MCM Link'>
                                    <InputWrapper
                                        id={`input.promoted-experiment-mcm-${index}`}
                                        readonly={false}
                                        value={promotedExperiment.mcm}
                                        onInput={(input: string) => {
                                            updatePromotedExperimentsInfoListCopy[index].mcm = input;
                                            this.updatePromotedExperiments(updatePromotedExperimentsInfoListCopy);

                                        }}
                                        validate={(value) => { return CommonValidator.isMcmLinkValid(value); }} />
                                </FormField>
                            </div>
                        </ColumnLayout>
                        <div className='col-2' style={{ paddingBottom: '35px' }}>
                            <Button
                                id={`button.remove-promoted-experiment-${index}`}
                                disabled={false}
                                onClick={() => {
                                    updatePromotedExperimentsInfoListCopy.splice(index, 1);
                                    this.updatePromotedExperiments(updatePromotedExperimentsInfoListCopy);
                                }}>Remove</Button>
                        </div>
                        <hr />
                    </div>)
                }
                {( updatePromotedExperimentsInfoListCopy !== undefined) && <div className='awsui-grid'>
                    <div className='awsui-row'>
                        <div className='col-12'>
                            <Button
                                id='button.add-promoted-experiments'
                                onClick={() => {
                                    updatePromotedExperimentsInfoListCopy.push({} as IPromotedExperimentsInfo);
                                    this.updatePromotedExperiments(updatePromotedExperimentsInfoListCopy);
                                }}
                                variant='normal'
                                icon='add-plus'
                                disabled={isLoading} >Add Promoted Experiment</Button>
                            <Button id='button.pre-save-promoted-experiments' onClick={this.showConfirmationModal.bind(this)} variant='primary' icon='upload' disabled={isLoading} >Save</Button>
                            <Button id='button.pre-cancel-promoted-experiments' onClick={this.resetPromotedExperiments.bind(this)} variant='normal' icon='close' disabled={isLoading} >Cancel</Button>
                        </div>
                    </div>
                </div>}
            </div>
            <Modal
                id='modal.update-promoted-experiments-confirmation'
                visible={this.state.isShowingConfirmationModal}
                header='Publish promoted experiments list'
                footer={<span className='awsui-util-f-r'>
                    <Button id='button.save-promoted-experiments' onClick={this.savePromotedExperiments.bind(this)} variant='primary' icon='upload' disabled={isLoading} >Save</Button>
                    <Button id='button.cancel-promoted-experiments' onClick={this.dismissConfirmationModal.bind(this)} variant='normal' icon='close' disabled={isLoading} >Cancel</Button>
                </span>}
                onDismiss={this.dismissConfirmationModal.bind(this)}>

                <p>
                    <Icon size='large' name='status-warning' variant='warning' /> By clicking save, promoted experiments will take effect <em>immediately</em>. Are you sure?
                </p>
            </Modal>
        </div>;
    }
}

const mapStateToProps = ({ promotedExperimentsState }: AppState) => {
    return {
        isLoading: promotedExperimentsState.isLoading,
        isPromotedExperimentsUpdated: promotedExperimentsState.updatedPromotedExperiments,
        errorUpdating: promotedExperimentsState.errorUpdating || undefined,
        errorLoading: promotedExperimentsState.errorLoading || undefined,
        promotedExperiments: promotedExperimentsState.promotedExperiments || undefined,
    };
};

export default connect(mapStateToProps)(PromotedExperimentsUpdateWidget);
