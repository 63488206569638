import * as React from 'react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { FormSectionView } from '../../components/common/FormSectionView';
import { FormField, Spinner, Select, ColumnLayout, FormSection } from '@amzn/awsui-components-react';
import { connect } from 'react-redux';
import { AppState } from '../../reducers/index';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { LinkedMobileCardTemplateTypeWiki, LinkedSectionlineWiki } from '../../components/common/LinksRenderHelper';
import { IMobileHomeCardContent, TemplateType } from '../../models/mobileHomeCard/MobileHomeCardContent';
import { pushMobileHomeCardContent } from '../../actions/experienceEditViewActions';
import { extractMobileHomeCardContent } from '../../util/mobileHomeCardHelper';
import { isRightToLeftLocale } from '../../constants/locales';
import { TextRenderDirection } from '../../models/htmlElementProps';
import { MobileHomeCardHeroElementComponent } from '../../components/common/MobileHomeCardHeroElementComponent';
import { MobileHomeCardDiscoveryElementComponent } from '../../components/common/MobileHomeCardDiscoveryElementComponent';

interface IMobileHomeCardDisplayElementsSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
    onCardActionAndCharLimitCheckedUpdate?: (cardActionChecked?: boolean, charLimitChecked?: boolean) => void;
}

export const TemplateMapToSection: { [templateType: string]: string } = {
    'HeroTemplate': 'DiscoverMore',
    'DiscoveryTemplate': 'Discovery'
};

export const SectionOption = [
    {
        'label': 'Getting Start',
        'id': 'GettingStarted',
    },
    {
        'label': 'Discover More',
        'id': 'DiscoverMore',
    },
    {
        'label': 'Discovery',
        'id': 'Discovery',
    },
    {
        'label': 'Activity',
        'id': 'Activity',
    },
    {
        'label': 'ActivityStandBy',
        'id': 'ActivityStandby',
    },
    {
        'label': 'Override',
        'id': 'Override',
    },
    {
        'label': 'Home Channel',
        'id': 'HomeChannel',
    },
    {
        'label': 'MARS',
        'id': 'MARS',
    }
] as Select.Option[];

interface IMobileHomeCardDisplayElementsSectionViewState {
    forcedLtrRendering: boolean;
}

export interface CharLimit {
    titleExceedCharLimit?: boolean;
    subtitleExceedCharLimit?: boolean;
    callToActionExceedCharLimit?: boolean;
}

export class MobileHomeCardDisplayElementsSectionView extends React.Component<IMobileHomeCardDisplayElementsSectionViewProps, IMobileHomeCardDisplayElementsSectionViewState> {
    constructor(props: IMobileHomeCardDisplayElementsSectionViewProps) {
        super(props);

        this.state = {
            forcedLtrRendering: false
        };
    }

    updateMobileHomeCardContent(mobileHomeCardContent?: IMobileHomeCardContent) {
        const { dispatch } = this.props;
        dispatch(pushMobileHomeCardContent(mobileHomeCardContent));
    }

    disableNextButton(charLimit: CharLimit, cardActionConfirmation?: boolean, onCardActionAndCharLimitCheckedUpdate?: (cardActionChecked?: boolean, charLimitChecked?: boolean) => void) {
        const { dispatch } = this.props;
        const { titleExceedCharLimit, subtitleExceedCharLimit, callToActionExceedCharLimit } = charLimit;

        const characterLimitExceed = titleExceedCharLimit || subtitleExceedCharLimit || callToActionExceedCharLimit;

        if (onCardActionAndCharLimitCheckedUpdate) {
            onCardActionAndCharLimitCheckedUpdate(cardActionConfirmation, characterLimitExceed);
            dispatch(pushMobileHomeCardContent({ characterLimitExceed }));
        }

    }

    shouldRenderTextRightToLeft(locale: string): boolean {
        const { forcedLtrRendering } = this.state;

        return isRightToLeftLocale(locale) && !forcedLtrRendering;
    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate, onCardActionAndCharLimitCheckedUpdate } = this.props;

        if (!experience) {
            return <FormSectionView title='Display Element'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const mobileHomeCardContent: IMobileHomeCardContent | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getMobileHomeCardContent()
            : extractMobileHomeCardContent(experience);

        const targetLocale = ((isEditing && updateCandidate)
            ? updateCandidate.getRegion().locale
            : experience.locale) || 'en_US';

        const shouldDisableInput = !isEditing || isUpdating;

        const templateType = mobileHomeCardContent?.templateType;

        const textDirection: TextRenderDirection = this.shouldRenderTextRightToLeft(targetLocale) ? 'rtl' : 'ltr';


        return <FormSection header={'Display Element'} description={<span>For more details, See <LinkedMobileCardTemplateTypeWiki /></span>}>
            <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root='true' style={{ marginBottom: '10px' }}>
                    <FormField
                        label={<RequiredField fieldName='Template Type' />}
                        description={'Select the templateType'}>
                        <Select
                            id='experience.templateType'
                            controlId='experience.templateType'
                            options={TemplateTypeOption}
                            disabled={shouldDisableInput}
                            placeholder={'Select'}
                            selectedId={mobileHomeCardContent?.templateType}
                            onChange={e => {
                                this.updateMobileHomeCardContent({
                                    ...mobileHomeCardContent,
                                    templateType: e.detail.selectedId as TemplateType,
                                    section: TemplateMapToSection[e.detail.selectedId],
                                });

                            }}
                        />
                    </FormField>

                    <FormField
                        label={<RequiredField fieldName='Section' />}
                        description={<span>See <LinkedSectionlineWiki /> to select appropriate option</span>}>
                        <Select
                            id='experience.section'
                            controlId='experience.section'
                            options={SectionOption}
                            placeholder='Select Section'
                            disabled={true}
                            selectedId={mobileHomeCardContent?.section}
                            onChange={e => {
                                this.updateMobileHomeCardContent({ ...mobileHomeCardContent, section: e.detail.selectedId });
                            }}
                        />
                    </FormField>
                </div>
            </ColumnLayout>


            {templateType === 'HeroTemplate' ?
                <MobileHomeCardHeroElementComponent
                    mobileHomeCardContent={mobileHomeCardContent}
                    textDirection={textDirection}
                    shouldDisableInput={shouldDisableInput}
                    experience={experience}
                    updateMobileHomeCardContent={(homeCardContent) => {
                        this.updateMobileHomeCardContent(homeCardContent);
                    }}
                    disableNextButton={(characterLimit, cardActionConfirmation, CardActionAndCharLimitCheckedUpdate) => {
                        this.disableNextButton(characterLimit, cardActionConfirmation, CardActionAndCharLimitCheckedUpdate);
                    }}
                    onCardActionAndCharLimitCheckedUpdate={onCardActionAndCharLimitCheckedUpdate}
                ></MobileHomeCardHeroElementComponent> :

                <MobileHomeCardDiscoveryElementComponent
                    mobileHomeCardContent={mobileHomeCardContent}
                    textDirection={textDirection}
                    shouldDisableInput={shouldDisableInput}
                    updateMobileHomeCardContent={(homeCardContent) => {
                        this.updateMobileHomeCardContent(homeCardContent);
                    }}
                    disableNextButton={(characterLimit, cardActionConfirmation, CardActionAndCharLimitCheckedUpdate) => {
                        this.disableNextButton(characterLimit, cardActionConfirmation, CardActionAndCharLimitCheckedUpdate);
                    }}
                    onCardActionAndCharLimitCheckedUpdate={onCardActionAndCharLimitCheckedUpdate}
                    isEditing={isEditing}
                ></MobileHomeCardDiscoveryElementComponent>
            }


        </FormSection>;
    }
}

export const getTemplateTypeDescription = (type?: TemplateType): string => {
    if (type === 'HeroTemplate') {
        return 'Hero template is to show a single feature with a brief description, illustration, and call to action';
    } else {
        return 'Discovery template to show title, subtitle, left icon, right image, optional label and call to action';
    }
};

export const TemplateTypeOption = [
    {
        'label': 'Hero',
        'id': 'HeroTemplate',
        'description': getTemplateTypeDescription('HeroTemplate')
    },
    {
        'label': 'Discovery',
        'id': 'DiscoveryTemplate',
        'description': getTemplateTypeDescription('DiscoveryTemplate')
    }
] as Select.Option[];



const mapStateToProps = ({ odysseyExperienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: odysseyExperienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(MobileHomeCardDisplayElementsSectionView);
