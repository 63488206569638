import { ExperienceType } from './Experience';

export interface ITimeRange {
    /**
     * Start Hour value for timeRange when the experience should be enabled.
     */
    startHour: number;
    /**
     * Start Minute value for timeRange when the experience should be enabled.
     */
    startMin: number;
    /**
     * End Hour value for timeRange when the experience should be enabled.
     */
    endHour: number;
    /**
     * End Minute value for timeRange when the experience should be enabled.
     */
    endMin: number;
}

export const DEFAULT_TIME_RANGE: ITimeRange = {startHour: 7, startMin: 0, endHour: 21, endMin: 59 } as ITimeRange;
export const DEFAULT_VCIF_TIME_RANGE: ITimeRange = {startHour: 0, startMin: 0, endHour: 23, endMin: 59 } as ITimeRange;
export const DEFAULT_TIME_RANGE_NOTIFICATIONS: ITimeRange = {startHour: 9, startMin: 0, endHour: 21, endMin: 59 } as ITimeRange;

export const getDefaultTimeRange = (experienceType: ExperienceType, isVisualCIF?: boolean): ITimeRange => {
    if (experienceType === 'AppNotification' || experienceType === 'DeviceNotification') {
        return DEFAULT_TIME_RANGE_NOTIFICATIONS;
    } else if (isVisualCIF) {
        return DEFAULT_VCIF_TIME_RANGE;
    }
    return DEFAULT_TIME_RANGE;
};

export const getDefaultDayTimeGuardrails = (experienceType: ExperienceType, isVisualCIF?: boolean): {[key: number]: ITimeRange[]} => {
    const defaultTimeRange = getDefaultTimeRange(experienceType, isVisualCIF);
    return {
        1: [defaultTimeRange],
        2: [defaultTimeRange],
        3: [defaultTimeRange],
        4: [defaultTimeRange],
        5: [defaultTimeRange],
        6: [defaultTimeRange],
        7: [defaultTimeRange],
    };
};
