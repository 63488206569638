import { IMultiCloneAction } from '../actions/experienceDetailViewActions';
import { CREATING_MULTI_CLONE_EXPERIENCES, ERROR_UPDATE_MULTI_CLONE_EXPERIENCES, SUCCESS_UPDATE_MULTI_CLONE_EXPERIENCES, UPDATE_MULTI_CLONE_EXPERIENCES } from '../constants/experienceDetailViewActionTypes';
import { IMultiCloneExperienceMetadata } from '../util/MultiCloneUtils';

export interface IMultiCloneState {
    isCreating: boolean;
    multiCloneExperiences?: IMultiCloneExperienceMetadata[];
    errors?: Error[];
    cloneExperienceTitle?: string;
    newExperiencesCount?: number;
    errorLoading?: Error;
    errorUpdating?: Error;
}

export const multiCloneReducer = (
    state: IMultiCloneState = { isCreating: false, multiCloneExperiences: [] },
    action: IMultiCloneAction
): IMultiCloneState => {
    switch (action.type) {
        case UPDATE_MULTI_CLONE_EXPERIENCES:
            return {
                ...state,
                multiCloneExperiences: action.multiCloneExperiences
            };
        case CREATING_MULTI_CLONE_EXPERIENCES:
            return {
                ...state,
                isCreating: true,
                errors: undefined,
                cloneExperienceTitle: undefined,
                newExperiencesCount: undefined,
                multiCloneExperiences: action.multiCloneExperiences
            };
        case ERROR_UPDATE_MULTI_CLONE_EXPERIENCES:
            return {
                ...state,
                isCreating: false,
                errors: action.errors,
                cloneExperienceTitle: action.cloneExperienceTitle,
                newExperiencesCount: action.newExperiencesCount
            };
        case SUCCESS_UPDATE_MULTI_CLONE_EXPERIENCES:
            return {
                ...state,
                isCreating: false,
                cloneExperienceTitle: action.cloneExperienceTitle,
                newExperiencesCount: action.newExperiencesCount
            };
        default:
            return state;
    }
};
