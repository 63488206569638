import { Action, Dispatch } from 'redux';
import { getAlexaLabMetrics, getCXMetrics, getSimilarExperiments, getFilterMetrics, getRcpCXMetrics } from '../api/acopsGateway';
import {
    LOADING_ALEXALAB_METRICS,
    LOADED_ALEXALAB_METRICS,
    ERROR_LOADING_ALEXALAB_METRICS,
    LOADING_CX_WEEKLY_METRICS,
    LOADED_CX_WEEKLY_METRICS,
    ERROR_LOADING_CX_WEEKLY_METRICS,
    LOADING_CX_DAILY_METRICS,
    LOADED_CX_DAILY_METRICS,
    ERROR_LOADING_CX_DAILY_METRICS,
    LOADING_SIMILAR_EXPERIENCES,
    LOADED_SIMILAR_EXPERIENCES,
    ERROR_LOADING_SIMILAR_EXPERIENCES,
    LOADING_FILTER_DAILY_METRICS,
    LOADED_FILTER_DAILY_METRICS,
    ERROR_LOADING_FILTER_DAILY_METRICS,
    LOADING_RCP_CX_DAILY_METRICS,
    LOADED_RCP_CX_DAILY_METRICS,
    ERROR_LOADING_RCP_CX_DAILY_METRICS,
    LOADING_RCP_CX_WEEKLY_METRICS,
    LOADED_RCP_CX_WEEKLY_METRICS,
    ERROR_LOADING_RCP_CX_WEEKLY_METRICS
} from '../constants/experienceMetricsViewActionTypes';
import { IAlexaLabMetrics, AlexaLabTreatment } from '../models/AlexaLabMetrics';
import { ExperienceMetricFrequency, IRawCifMetricsData } from '../models/CifMetrics';
import { ExperienceType } from '../models/Experience';
import { FilterMetricFrequency, IRawFilterMetricsData } from '../models/FilterMetrics';
import { ISimilarExperiencesData } from '../models/SimilarExperiencesData';
import { IRawRcpMetricsData, OdysseyExperienceMetricFrequency } from '../models/v2/metric/RCPMetrics';

export interface ILoadAlexaLabMetricsAction extends Action {
    alexaLabMetrics?: IAlexaLabMetrics;
    error?: Error;
}

export interface ILoadCXWeeklyMetricsAction extends Action {
    cxWeeklyMetrics?: IRawCifMetricsData[];
    error?: Error;
}

export interface ILoadCXDailyMetricsAction extends Action {
    cxDailyMetrics?: IRawCifMetricsData[];
    error?: Error;
}

export interface ILoadFilterDailyMetricsAction extends Action {
    filterMetrics?: IRawFilterMetricsData[];
    error?: Error;
}

export interface ILoadSimilarExperiencesAction extends Action {
    similarExperiencesData?: ISimilarExperiencesData[];
    error?: Error;
}

export interface ILoadRcpCXDailyMetricsAction extends Action {
    cxDailyMetrics?: IRawRcpMetricsData[];
    error?: Error;
}

export interface ILoadRcpCXWeeklyMetricsAction extends Action {
    cxWeeklyMetrics?: IRawRcpMetricsData[];
    error?: Error;
}

export const loadingAlexaLabMetrics = (): ILoadAlexaLabMetricsAction => {
    return {
        type: LOADING_ALEXALAB_METRICS
    };
};

export const loadedAlexaLabMetrics = (alexaLabMetrics: IAlexaLabMetrics | undefined): ILoadAlexaLabMetricsAction => {
    return {
        type: LOADED_ALEXALAB_METRICS,
        alexaLabMetrics
    };
};

export const errorLoadingAlexaLabMetrics = (error: Error): ILoadAlexaLabMetricsAction => {
    return {
        type: ERROR_LOADING_ALEXALAB_METRICS,
        error
    };
};

export const loadingCXWeeklyMetrics = (): ILoadCXWeeklyMetricsAction => {
    return {
        type: LOADING_CX_WEEKLY_METRICS
    };
};

export const loadedCXWeeklyMetrics = (cxWeeklyMetrics: IRawCifMetricsData[] | undefined): ILoadCXWeeklyMetricsAction => {
    return {
        type: LOADED_CX_WEEKLY_METRICS,
        cxWeeklyMetrics
    };
};

export const errorLoadingCXWeeklyMetrics = (error: Error): ILoadCXWeeklyMetricsAction => {
    return {
        type: ERROR_LOADING_CX_WEEKLY_METRICS,
        error
    };
};

export const loadingCXDailyMetrics = (): ILoadCXDailyMetricsAction => {
    return {
        type: LOADING_CX_DAILY_METRICS
    };
};

export const loadedCXDailyMetrics = (cxDailyMetrics: IRawCifMetricsData[] | undefined): ILoadCXDailyMetricsAction => {
    return {
        type: LOADED_CX_DAILY_METRICS,
        cxDailyMetrics
    };
};

export const errorLoadingCXDailyMetrics = (error: Error): ILoadCXDailyMetricsAction => {
    return {
        type: ERROR_LOADING_CX_DAILY_METRICS,
        error
    };
};

export const loadingRcpCXDailyMetrics = (): ILoadCXDailyMetricsAction => {
    return {
        type: LOADING_RCP_CX_DAILY_METRICS
    };
};

export const loadedRcpCXDailyMetrics = (cxDailyMetrics: IRawRcpMetricsData[] | undefined): ILoadRcpCXDailyMetricsAction => {
    return {
        type: LOADED_RCP_CX_DAILY_METRICS,
        cxDailyMetrics
    };
};

export const errorLoadingRcpCXDailyMetrics = (error: Error): ILoadCXDailyMetricsAction => {
    return {
        type: ERROR_LOADING_RCP_CX_DAILY_METRICS,
        error
    };
};

export const loadingRcpCXWeeklyMetrics = (): ILoadCXWeeklyMetricsAction => {
    return {
        type: LOADING_RCP_CX_WEEKLY_METRICS
    };
};

export const loadedRcpCXWeeklyMetrics = (cxWeeklyMetrics: IRawRcpMetricsData[] | undefined): ILoadRcpCXWeeklyMetricsAction => {
    return {
        type: LOADED_RCP_CX_WEEKLY_METRICS,
        cxWeeklyMetrics
    };
};

export const errorLoadingRcpCXWeeklyMetrics = (error: Error): ILoadCXWeeklyMetricsAction => {
    return {
        type: ERROR_LOADING_RCP_CX_WEEKLY_METRICS,
        error
    };
};

export const loadingFilterDailyMetrics = (): ILoadFilterDailyMetricsAction => {
    return {
        type: LOADING_FILTER_DAILY_METRICS
    };
};

export const loadedFilterDailyMetrics = (filterMetrics: IRawFilterMetricsData[] | undefined): ILoadFilterDailyMetricsAction => {
    return {
        type: LOADED_FILTER_DAILY_METRICS,
        filterMetrics
    };
};

export const errorLoadingFilterDailyMetrics = (error: Error): ILoadFilterDailyMetricsAction => {
    return {
        type: ERROR_LOADING_FILTER_DAILY_METRICS,
        error
    };
};

export const loadingSimilarExperiences = (): ILoadSimilarExperiencesAction => {
    return {
        type: LOADING_SIMILAR_EXPERIENCES,
    };
};

export const loadedSimilarExperiences = (similarExperiencesData: ISimilarExperiencesData[] | undefined): ILoadSimilarExperiencesAction => {
    return {
        type: LOADED_SIMILAR_EXPERIENCES,
        similarExperiencesData
    };
};

export const errorLoadingSimilarExperiences = (error: Error): ILoadSimilarExperiencesAction => {
    return {
        type: ERROR_LOADING_SIMILAR_EXPERIENCES,
        error
    };
};

export const getAlexaLabMetricsAction = (
    weblabId: string,
    realm: string,
    treatment: AlexaLabTreatment,
    baseline: AlexaLabTreatment,
    dimension?: string
) => async (dispatch: Dispatch) => {
    dispatch(loadingAlexaLabMetrics());

    try {
        const data: IAlexaLabMetrics | undefined = await getAlexaLabMetrics(weblabId, realm, treatment, baseline, dimension);
        dispatch(loadedAlexaLabMetrics(data));
    } catch (error) {
        dispatch(errorLoadingAlexaLabMetrics(error));
    }
};

export const getCXWeeklyMetricsAction = (
    experienceIds: string[],
    experienceType: ExperienceType,
    periodType: ExperienceMetricFrequency,
    numPeriods: number,
    metricFilter: string[],
    endPeriod?: string,
) => async (dispatch: Dispatch) => {
    dispatch(loadingCXWeeklyMetrics());

    try {
        const data: IRawCifMetricsData[] | undefined = await getCXMetrics(experienceIds, experienceType, periodType, numPeriods, metricFilter, endPeriod);
        dispatch(loadedCXWeeklyMetrics(data));
    } catch (error) {
        dispatch(errorLoadingCXWeeklyMetrics(error));
    }
};

export const getCXDailyMetricsAction = (
    experienceIds: string[],
    experienceType: ExperienceType,
    periodType: ExperienceMetricFrequency,
    numPeriods: number,
    metricFilter: string[],
    endPeriod?: string,
) => async (dispatch: Dispatch) => {
    dispatch(loadingCXDailyMetrics());

    try {
        const data: IRawCifMetricsData[] | undefined = await getCXMetrics(experienceIds, experienceType, periodType, numPeriods, metricFilter, endPeriod);
        dispatch(loadedCXDailyMetrics(data));
    } catch (error) {
        dispatch(errorLoadingCXDailyMetrics(error));
    }
};

export const getRcpCXDailyMetricsAction = (
    experienceIds: string[],
    experienceType: ExperienceType,
    periodType: OdysseyExperienceMetricFrequency,
    numPeriods: number,
    metricFilter: string[],
    endPeriod?: string,
) => async (dispatch: Dispatch) => {
    dispatch(loadingRcpCXDailyMetrics());

    try {
        const data: IRawRcpMetricsData[] | undefined = await getRcpCXMetrics(experienceIds, experienceType, periodType, numPeriods, metricFilter, endPeriod);
        dispatch(loadedRcpCXDailyMetrics(data));
    } catch (error) {
        dispatch(errorLoadingCXDailyMetrics(error));
    }
};

export const getRcpCXWeeklyMetricsAction = (
    experienceIds: string[],
    experienceType: ExperienceType,
    periodType: OdysseyExperienceMetricFrequency,
    numPeriods: number,
    metricFilter: string[],
    endPeriod?: string,
) => async (dispatch: Dispatch) => {
    dispatch(loadingRcpCXWeeklyMetrics());

    try {
        const data: IRawRcpMetricsData[] | undefined = await getRcpCXMetrics(experienceIds, experienceType, periodType, numPeriods, metricFilter, endPeriod);
        dispatch(loadedRcpCXWeeklyMetrics(data));
    } catch (error) {
        dispatch(errorLoadingRcpCXWeeklyMetrics(error));
    }
};

export const getFilterDailyMetricsAction = (
    experienceIds: string[],
    experienceType: ExperienceType,
    periodType: FilterMetricFrequency,
    numPeriods?: number,
    endPeriod?: string,
) => async (dispatch: Dispatch) => {
    dispatch(loadingFilterDailyMetrics());

    try {
        const data: IRawFilterMetricsData[] | undefined = await getFilterMetrics(experienceIds, experienceType, periodType, numPeriods, endPeriod);
        dispatch(loadedFilterDailyMetrics(data));
    } catch (error) {
        dispatch(errorLoadingFilterDailyMetrics(error));
    }
};

export const getSimilarExperiencesAction = (
    targetId: string,
    targetLevels: string[],

) => async (dispatch: Dispatch) => {
    dispatch(loadingSimilarExperiences());

    try {
        const data: ISimilarExperiencesData[] | undefined = await getSimilarExperiments(targetId, targetLevels);
        dispatch(loadedSimilarExperiences(data));
    } catch (error) {
        dispatch(errorLoadingSimilarExperiences(error));
    }
};
