import { Spinner } from '@amzn/awsui-components-react';
import * as React from 'react';
import { connect } from 'react-redux';
import { IBypassPauseList } from '../../models/bypassPauseList/BypassPauseList';
import { AppState } from '../../reducers';
import { MarkdownRenderer } from '../../components/MarkdownRenderer';
import { FormSectionView } from '../../components/common/FormSectionView';

interface BypassPauseListViewProps {
    dispatch: any;
    isLoading: boolean;
    isBypassPauseListUpdated: boolean;
    errorUpdating?: Error;
    errorLoading?: Error;
    bypassPauseList?: IBypassPauseList;
}

const mapStateToProps = ({ bypassPauseListState }: AppState) => {
    return {
        isLoading: bypassPauseListState.isLoading,
        isBypassPauseListUpdated: bypassPauseListState.updatedBypassPause,
        errorUpdating: bypassPauseListState.errorUpdating || undefined,
        errorLoading: bypassPauseListState.errorLoading || undefined,
        bypassPauseList: bypassPauseListState.bypassPauseList || undefined,
    };
};

const bypassPauseListDescription = 'This is OR logic, do not need to enter a complete list of experimentID along with its operator group. ' +
    'Put experiment ids under Experiment Ids if bypassing pause for specific experiences OR ' +
    'put operator group under Operator Group if bypassing pause for all experiences with a specific operator group.';

export const BypassPauseListTextOnlySectionView = connect(mapStateToProps)((props: BypassPauseListViewProps) => {
    const { isLoading, errorLoading, bypassPauseList } = props;
    if (isLoading) {
        return <Spinner size='large' variant='disabled' />;
    }

    if (errorLoading) {
        return <div id='bypassPauseList.content-render-area.error'>
            <p className='awsui-util-status-negative'>{errorLoading.message}</p>
        </div>;
    }

    if(!bypassPauseList) {
        return <div id='bypassPauseList.content-render-area.no-content'>
	        <p className='awsui-util-status-inactive awsui-util-status-negative' >No bypass pause list at the moment...</p>
	    </div>;
    }

    return <div id='bypassPauseList.content-render-area'>
        <h2> Experiment Ids: </h2>
        <MarkdownRenderer markdownString={bypassPauseList.experimentIds.toString().replace(/,/g, '<br>')} />
        <h2> Operator Groups: </h2>
        <MarkdownRenderer markdownString={bypassPauseList.groupIds.toString().replace(/,/g, '<br>')} />
    </div>;
});

export const BypassPauseListSectionView = () => {
    return <FormSectionView title='Bypass pause list and updates' description= {bypassPauseListDescription}>
        <BypassPauseListTextOnlySectionView/>
    </FormSectionView>;
};

