import { IRawCifMetricsData, IIntentMetric, CifMetricDDBNames } from './CifMetrics';
import moment from 'moment';

// Combine all key-value pair(intent name - intent metric pair) with same intent name into a single key-value pair
export const constructDailyIntentMetrics = (cifMetrics: IRawCifMetricsData[]) : IRawCifMetricsData[] => {
    if (!cifMetrics[0]) {
        return [];
    }

    // Copy all data to "constructedIntentMetrics"
    const constructedIntentMetrics: IRawCifMetricsData[] = JSON.parse(JSON.stringify(cifMetrics));

    // Empty "intentMetrics" to leave the space for newly constructed data
    constructedIntentMetrics[0].intentMetrics = {};

    // Create an empty list to store intent names
    const names: string[] = [];

    // Find all intent names
    Object.entries(cifMetrics[0].intentMetrics).forEach(pair => {
        const name: string = pair[0].split('-', 2)[0];
        if (names.findIndex(each => each === name) === -1) {
            names.push(name);
        }
    });

    // Create new key-value pairs(intent name - intent metric pairs) in "intentMetrics" with empty value
    names.forEach(name => {
        constructedIntentMetrics[0].intentMetrics[name] = [];
    });

    // Set value for key-value pair
    Object.entries(cifMetrics[0].intentMetrics).forEach(pair => {
        const name: string = pair[0].split('-', 2)[0];
        constructedIntentMetrics[0].intentMetrics[name].unshift(pair[1][0]);
    });

    // Sort the key-value pair by time
    Object.entries(constructedIntentMetrics[0].intentMetrics).forEach(pair => {
        pair[1].sort((metric1, metric2) => moment(metric1.period).isAfter(moment(metric2.period)) ? 1 : -1);
    });

    return constructedIntentMetrics;
};

// Combine all key-value pair(intent name - intent metric pair) with same intent name into a single key-value pair
export const constructWeeklyIntentMetrics = (cifMetrics: IRawCifMetricsData[]) : IRawCifMetricsData[] => {
    if (!cifMetrics[0]) {
        return [];
    }

    // Copy all data to "constructedIntentMetrics"
    const constructedIntentMetrics: IRawCifMetricsData[] = JSON.parse(JSON.stringify(cifMetrics));

    // Empty "intentMetrics" to leave the space for newly constructed data
    constructedIntentMetrics[0].intentMetrics = {};

    // Create an empty list to store intent names
    const names: string[] = [];

    // Find all intent names
    Object.entries(cifMetrics[0].intentMetrics).forEach(pair => {
        const name: string = pair[0].split('-', 2)[0];
        if (names.findIndex(each => each === name) === -1) {
            names.push(name);
        }
    });

    // Create new key-value pairs(intent name - intent metric pairs) in "intentMetrics" with empty value
    names.forEach(name => {
        constructedIntentMetrics[0].intentMetrics[name] = [];
    });

    // Set value for key-value pair
    Object.entries(cifMetrics[0].intentMetrics).forEach(pair => {
        const name: string = pair[0].split('-', 2)[0];
        constructedIntentMetrics[0].intentMetrics[name].unshift(pair[1][0]);
    });

    // Sort the key-value pair by time
    Object.entries(constructedIntentMetrics[0].intentMetrics).forEach(pair => {
        pair[1].sort((metric1, metric2) => moment(metric1.weekStart).isAfter(moment(metric2.weekStart)) ? 1 : -1);
    });

    return constructedIntentMetrics;
};

// Build 7-day metric. If the intent has missing metrics, then fill in empty metrics instead
export const constructDailyIntentMetricsForTableView = (cifMetrics: IRawCifMetricsData[]) : IRawCifMetricsData[] => {
    if (!cifMetrics[0]) {
        return [];
    }

     // Find the most recent day
    let mostRecentDay: string | undefined = '2000-01-01';
    Object.entries(cifMetrics[0].intentMetrics).forEach(pair => {
        for (let eachMetric = pair[1].length - 1; eachMetric >= 0; eachMetric--) {
            if (moment(pair[1][eachMetric].period).isAfter(moment(mostRecentDay))) {
                mostRecentDay = pair[1][eachMetric].period;
                break;
            } else if (moment(pair[1][eachMetric].period).isSameOrBefore(moment(mostRecentDay))) {
                break;
            }
        }
    });

    // constant for 7 days
    const CIF_METRICS_PERIODS_INDEX_RANGE = 6;

    // Copy all data to "constructedDailyMetrics"
    const constructedDailyMetrics = JSON.parse(JSON.stringify(cifMetrics));

    // For each intent, loop 7 times from the last day to the first day to find missing metrics and replace by empty metrics
    Object.entries(cifMetrics[0].intentMetrics).forEach(pair => {
        // Empty value list for this key-value pair(intent name - intent metric pair) to leave the space for new data
        constructedDailyMetrics[0].intentMetrics[pair[0]] = [];

        for (let constructedMetricsIterator = 0, day = pair[1][pair[1].length - 1].period;
                constructedMetricsIterator <= CIF_METRICS_PERIODS_INDEX_RANGE; constructedMetricsIterator++) {
            // Find the index of metric for this day
            const index = pair[1].findIndex(metric => moment(metric.period).isSame(moment(day)));

            // If the next week cannot be found, then set a empty metric for this week
            if (index === -1) {
                const constructedDailyMetricsForMissingPeriod = {
                    period: day,
                    reportedAt: moment().format('MM/DD/YYYY hh:mm A z'),
                    data: {}
                };
                constructedDailyMetrics[0].intentMetrics[pair[0]].push(constructedDailyMetricsForMissingPeriod);
            } else {
                constructedDailyMetrics[0].intentMetrics[pair[0]].push(pair[1][index]);
            }

            // Renew the iterator to be next moment of day
            day = moment(day).subtract(1, 'days').format('YYYY-MM-DD');
        }
    });

    return constructedDailyMetrics;
};

// Build 7-week metric. If the intent has missing metrics, then fill in empty metrics instead
export const constructWeeklyIntentMetricsForTableView = (cifMetrics: IRawCifMetricsData[]) : IRawCifMetricsData[] => {
    if (!cifMetrics[0]) {
        return [];
    }

    // Find the most recent week
    let mostRecentWeekStart: string | undefined = '2000-01-01';
    Object.entries(cifMetrics[0].intentMetrics).forEach(pair => {
        for (let eachMetric = pair[1].length - 1; eachMetric >= 0; eachMetric--) {
            if (moment(pair[1][eachMetric].weekStart).isAfter(moment(mostRecentWeekStart))) {
                mostRecentWeekStart = pair[1][eachMetric].weekStart;
                break;
            } else if (moment(pair[1][eachMetric].weekStart).isSameOrBefore(moment(mostRecentWeekStart))) {
                break;
            }
        }
    });

    // constant for 7 week
    const CIF_METRICS_PERIODS_INDEX_RANGE = 6;

    // Copy all data to "constructedWeeklyMetrics"
    const constructedWeeklyMetrics = JSON.parse(JSON.stringify(cifMetrics));

    // For each intent, loop 7 times from the last week to the first week to find missing metrics and replace by empty metrics
    Object.entries(cifMetrics[0].intentMetrics).forEach(pair => {
        // Empty value list for this key-value pair(intent name - intent metric pair) to leave the space for new data
        constructedWeeklyMetrics[0].intentMetrics[pair[0]] = [];

        for (let constructedMetricsIterator = 0, weekStart = mostRecentWeekStart, weekEnd = moment(mostRecentWeekStart).add(6, 'days').format('YYYY-MM-DD');
                constructedMetricsIterator <= CIF_METRICS_PERIODS_INDEX_RANGE; constructedMetricsIterator++) {
            // Find the index of metric for this weekStart
            const index = pair[1].findIndex(metric => moment(metric.weekStart).isSame(moment(weekStart)));

            // If the next week cannot be found, then set a empty metric for this week
            if (index === -1) {
                const weekPrefixString = (moment(weekStart).add(1, 'days').isoWeek() >= 1 && moment(weekStart).add(1, 'days').isoWeek() <= 9) ? '-W0' : '-W';
                const constructedWeeklyMetricsForMissingPeriod = {
                    period: moment(weekStart).year() + weekPrefixString + moment(weekStart).add(1, 'days').isoWeek(),
                    reportedAt: moment().format('MM/DD/YYYY hh:mm A z'),
                    weekStart,
                    weekEnd,
                    data: {}
                };
                constructedWeeklyMetrics[0].intentMetrics[pair[0]].push(constructedWeeklyMetricsForMissingPeriod);
            } else {
                constructedWeeklyMetrics[0].intentMetrics[pair[0]].push(pair[1][index]);
            }

            // Renew the iterator to be next moment of weekStart and weekEnd
            weekStart = moment(weekStart).subtract(7, 'days').format('YYYY-MM-DD');
            weekEnd = moment(weekEnd).subtract(7, 'days').format('YYYY-MM-DD');
        }
    });

    return constructedWeeklyMetrics;
};

export const createMappedItems = (cifMetrics: IRawCifMetricsData[], tableName: CifMetricDDBNames): IIntentMetric[] => {
    if (!cifMetrics || !cifMetrics[0]) {
        return [];
    }

    // Create a new list to prepare for mapped items
    const mappedItems: IIntentMetric[] = [];

    // Fill values into mappedItems
    Object.entries(cifMetrics[0].intentMetrics).forEach(pair => {
        // Create a new list to prepare for values
        let values: (number | undefined)[];

        // this list is for calculating weighted average
        let totalImpressions: (number | undefined)[] = [];

        // For different tables, fill different values
        switch (tableName) {
            case CifMetricDDBNames.TotalImpressions:
                values = pair[1].map(metric => metric.data.total_impression);
                break;
            case CifMetricDDBNames.DistinctCustomers:
                values = pair[1].map(metric => metric.data.distinct_customer);
                break;
            case CifMetricDDBNames.ConversionRate:
                values = pair[1].map(metric => metric.data.conversion_rate);
                totalImpressions = pair[1].map(metric => metric.data.total_impression);
                break;
            case CifMetricDDBNames.InterruptionRate:
                values = pair[1].map(metric => metric.data.interruption_rate);
                totalImpressions = pair[1].map(metric => metric.data.total_impression);
                break;
            case CifMetricDDBNames.NegativeFeedback:
                values = pair[1].map(metric => metric.data.negative_feedback);
                totalImpressions = pair[1].map(metric => metric.data.total_impression);
                break;
            default:
                values = [];
        }

        // Build appropriate type of value for "mappedItems"
        const eachIntentMetric: IIntentMetric = {
            intentName: pair[0].split('-', 2)[0],
            values,
            totalImpressions
        };

        mappedItems.push(eachIntentMetric);
    });

    // Calculate Total Or Weighted Average
    if (tableName === CifMetricDDBNames.TotalImpressions || tableName === CifMetricDDBNames.DistinctCustomers) {
        // Create a new list for calculating 7 total metrics
        const counts: number[] = new Array(7).fill(0);

        // For each intent, add the 7 metric number into 7 different slots of "count" list
        mappedItems.forEach(eachIntentMetric => {
            let countsIterator = 0;
            eachIntentMetric.values.forEach(value => {
                counts[countsIterator] += value ? value : 0;
                countsIterator++;
            });
        });

        // Insert a new row called "Total" into mappedItems
        const intentMetric: IIntentMetric = {
            intentName: 'Total',
            values: counts,
            totalImpressions: []
        };
        mappedItems.push(intentMetric);
    } else {
        // Create a new list for calculating 7 Total Impression
        const totalImpressions: number[] = new Array(7).fill(0);

        // For each intent, add the 7 totalImpressions into 7 different slots of "totalImpressions" list
        mappedItems.forEach(eachIntentMetric => {
            let countsIterator = 0;
            eachIntentMetric.totalImpressions.forEach(value => {
                totalImpressions[countsIterator] += value ? value : 0;
                countsIterator++;
            });
        });

        // Create a new list for calculating 7 average number
        const averages: number[] = new Array(7).fill(0);

        // For each intent, loop 7 times.
        // In each loop, multiply value(a decimal less than 1) by totalImpression(a integer) to get the result of weighted number.
        // Then, add the result to 7 different slots of "averages" list.
        mappedItems.forEach(eachIntentMetric => {
            for (let countsIterator = 0; countsIterator < 7; countsIterator++) {
                let count = eachIntentMetric.values[countsIterator];
                const totalImpression = eachIntentMetric.totalImpressions[countsIterator];
                if (count !== undefined && totalImpression !== undefined) {
                    count *= totalImpression;
                    averages[countsIterator] += count;
                }
            }
        });

        // Calculate total average using "Total" divide by "Total Impression"
        for (let averageIterator = 0; averageIterator < 7; averageIterator++) {
            if (totalImpressions[averageIterator] === 0) {
                continue;
            }

            averages[averageIterator] /= totalImpressions[averageIterator];
        }

        // Insert a new row called "Total" into mappedItems
        const intentMetric: IIntentMetric = {
            intentName: 'Total',
            values: averages,
            totalImpressions: []
        };
        mappedItems.push(intentMetric);
    }

    return mappedItems;
};

