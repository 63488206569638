import * as React from 'react';
import { getLinkableUrl, PAGE } from '../../constants/page';
import { BreadcrumbGroup, Button, Flash } from '@amzn/awsui-components-react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../reducers';
import { getProviderConfigByIdAndVersionAction, upsertProviderConfigsAction } from '../../actions/spiProviderOnboardingActions';
import SpiProviderOnboardingSmallFieldSectionView from '../sections/SpiProviderOnboardingFormFieldViews/SpiProviderOnboardingSmallFieldSectionView';
import SpiProviderOnboardingEndpointSectionView from '../sections/SpiProviderOnboardingFormFieldViews/SpiProviderOnboardingEndpointSectionView';
import SpiProviderOnboardingSupportedSectionView from '../sections/SpiProviderOnboardingFormFieldViews/SpiProviderOnboardingSupportedSectionView';

const SpiProviderConfigVersion = () => {

    const { providerId, versionId } = useParams<{ providerId: string, versionId: string }>();
    const { isLoading, currentProviderConfig } = useSelector((state: AppState) => state.spiProviderOnboardingState);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(getProviderConfigByIdAndVersionAction(providerId, versionId));
    }, [dispatch, providerId, versionId]);

    const saveButtonClickHandler = () => {
        if (currentProviderConfig) {
            dispatch(upsertProviderConfigsAction(providerId, currentProviderConfig));
            window.location.assign(getLinkableUrl(`/admin/spiProviderOnboarding/${providerId}`));
        }
    };

    return (
        <div style={{ margin: '30px' }}>
            <div style={{ marginBottom: '20px' }}>
                <BreadcrumbGroup label='Breadcrumbs.providerVersion' items={[{
                    text: 'Odyssey',
                    href: getLinkableUrl(PAGE.Home),
                }, {
                    text: 'Admin Dashboard',
                    href: getLinkableUrl(PAGE.AdminDashboard)
                }, {
                    text: 'SPI Providers',
                    href: getLinkableUrl(PAGE.SpiProviderOnboarding),
                }, {
                    text: providerId,
                    href: getLinkableUrl(PAGE.SpiProviderOnboarding + '/' + providerId),
                }, {
                    text: versionId,
                    href: getLinkableUrl(PAGE.SpiProviderOnboarding + '/' + providerId + '/' + versionId),
                }
                ]} />
            </div>
            {isLoading
                ?
                <div><Flash dismissible={false} >Loading...</Flash></div>
                :
                <div>
                    <span className='awsui-util-f-r'>
                        <Button
                            id='button.setVersionAsLive'
                            icon='upload'
                            onClick={saveButtonClickHandler}
                            variant='primary'
                        >Set Version as Live</Button>
                    </span>
                    <br/>
                    <br/>
                    <div>
                        <SpiProviderOnboardingSmallFieldSectionView/>
                        <SpiProviderOnboardingEndpointSectionView/>
                        <SpiProviderOnboardingSupportedSectionView/>
                    </div>
                </div>
            }
        </div>

    );
};

export default SpiProviderConfigVersion;
