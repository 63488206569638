import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers';
import _ from 'lodash';
import { updateCurrentProviderConfigAction, updateFormValidationSectionAction } from '../../../actions/spiProviderOnboardingActions';
import { IProviderConfig } from '../../../models/spiProviderConfig/ISpiProviderOnboarding';
import { ColumnLayout, FormField, Input } from '@amzn/awsui-components-react';
import { validateEndpointFields } from '../../../util/spiProviderOnboardingHelper';
import { FormSectionView } from '../../../components/common/FormSectionView';

const SpiProviderOnboardingEndpointSectionView: React.FC = () => {
    const { currentProviderConfig, isEditing, isOnboardingProvider } = useSelector((state: AppState) => state.spiProviderOnboardingState);
    const dispatch = useDispatch();

    const [currentFieldsState, setCurrentFieldsState] = React.useState({
        defaultEndpoint: currentProviderConfig?.defaultEndpoint,
        naEndpoint: currentProviderConfig?.naEndpoint,
        euEndpoint: currentProviderConfig?.euEndpoint,
        feEndpoint: currentProviderConfig?.feEndpoint,
    });

    React.useEffect(() => {
        setCurrentFieldsState({
            defaultEndpoint: currentProviderConfig?.defaultEndpoint,
            naEndpoint: currentProviderConfig?.naEndpoint,
            euEndpoint: currentProviderConfig?.euEndpoint,
            feEndpoint: currentProviderConfig?.feEndpoint,
        });
    }, [currentProviderConfig]);

    const initializeValidationState = React.useCallback(() => {
        if (!isOnboardingProvider) { // updating config case
          return {
            invalidDefaultEndpointSkillId: false,
            invalidDefaultEndpointSkillStage: false,
            invalidNaEndpointSkillId: false,
            invalidNaEndpointSkillStage: false,
            invalidEuEndpointSkillId: false,
            invalidEuEndpointSkillStage: false,
            invalidFeEndpointSkillId: false,
            invalidFeEndpointSkillStage: false,
          };
        } else {
          return { // creating config case
            invalidDefaultEndpointSkillId: true,
            invalidDefaultEndpointSkillStage: true,
            invalidNaEndpointSkillId: false,
            invalidNaEndpointSkillStage: false,
            invalidEuEndpointSkillId: false,
            invalidEuEndpointSkillStage: false,
            invalidFeEndpointSkillId: false,
            invalidFeEndpointSkillStage: false,
          };
        }
    }, [isOnboardingProvider]);

    const [validationState, setValidationState] = React.useState(initializeValidationState());

    React.useEffect(() => {
        setValidationState(initializeValidationState());
    }, [isEditing, initializeValidationState]);

    React.useEffect(() => {
        const isSectionValid = Object.values(validationState).every(value => !value);
        dispatch(updateFormValidationSectionAction('invalidEndpointSection', !isSectionValid));
    }, [validationState, dispatch]);

    const onChangeHandler = (event: CustomEvent<Input.ChangeDetail>) => {
        const parts = (event.target as HTMLElement).getAttribute('id')?.split('.');
        const field = parts?.pop();
        const endpoint = parts?.pop();

        if (endpoint && field) {
            const newFieldsState = _.cloneDeep(currentFieldsState);
            _.set(newFieldsState, `${endpoint}.${field}`, event.detail.value);
            setCurrentFieldsState(newFieldsState);
            dispatch(updateCurrentProviderConfigAction(newFieldsState as IProviderConfig));
            validateEndpointFields(endpoint+field, validationState, newFieldsState, setValidationState);
        }
    };

    return(
        <div>
            <FormSectionView
                title='Endpoints'
                description={"Skill Id Must Start With 'amzn1.ask.skill.' or 'amzn1.echo-sdk-ams.app.'"}
            >
                <div>
                    <ColumnLayout columns={2}>
                        <div><strong>Default Endpoint</strong> <i className='awsui-text-secondary'>(required)</i></div>
                        <div data-awsui-column-layout-root='true'>
                            <FormField label='Skill Id'>
                                <Input
                                    id='providerConfig.defaultEndpoint.skillId'
                                    placeholder={'amzn1.ask.skill.abcd123-e456-789f-g12h-3ijk4lm5no67'}
                                    value={currentProviderConfig?.defaultEndpoint?.skillId}
                                    readonly={!isEditing}
                                    onChange={onChangeHandler}
                                    invalid={validationState.invalidDefaultEndpointSkillId}
                                />
                            </FormField>
                            <FormField
                                label='Skill Stage'>
                                <Input
                                    id='providerConfig.defaultEndpoint.skillStage'
                                    placeholder={'live'}
                                    value={currentProviderConfig?.defaultEndpoint?.skillStage}
                                    readonly={!isEditing}
                                    onChange={onChangeHandler}
                                    invalid={validationState.invalidDefaultEndpointSkillStage}
                                />
                            </FormField>
                        </div>
                        <hr/>
                        <div><strong>NA Endpoint</strong> <i className='awsui-text-secondary'>(optional)</i></div>
                        <div data-awsui-column-layout-root='true'>
                            <FormField label='Skill Id'>
                                <Input
                                    id='providerConfig.naEndpoint.skillId'
                                    placeholder={'amzn1.ask.skill.abcd123-e456-789f-g12h-3ijk4lm5no67'}
                                    value={currentProviderConfig?.naEndpoint?.skillId}
                                    readonly={!isEditing}
                                    onChange={onChangeHandler}
                                    invalid={validationState.invalidNaEndpointSkillId}
                                />
                            </FormField>
                            <FormField label='Skill Stage'>
                                <Input
                                    id='providerConfig.naEndpoint.skillStage'
                                    placeholder={'live'}
                                    value={currentProviderConfig?.naEndpoint?.skillStage}
                                    readonly={!isEditing}
                                    onChange={onChangeHandler}
                                    invalid={validationState.invalidNaEndpointSkillStage}
                                    />
                            </FormField>
                        </div>
                        <hr/>
                        <div><strong>EU Endpoint</strong> <i className='awsui-text-secondary'>(optional)</i></div>
                        <div data-awsui-column-layout-root='true'>
                            <FormField label='Skill Id'>
                                <Input
                                    id='providerConfig.euEndpoint.skillId'
                                    placeholder={'amzn1.echo-sdk-ams.app.abcd123-e456-789f-g12h-3ijk4lm5no67'}
                                    value={currentProviderConfig?.euEndpoint?.skillId}
                                    readonly={!isEditing}
                                    onChange={onChangeHandler}
                                    invalid={validationState.invalidEuEndpointSkillId}
                                />
                            </FormField>
                            <FormField label='Skill Stage'>
                                <Input
                                    id='providerConfig.euEndpoint.skillStage'
                                    placeholder={'development'}
                                    value={currentProviderConfig?.euEndpoint?.skillStage}
                                    readonly={!isEditing}
                                    onChange={onChangeHandler}
                                    invalid={validationState.invalidEuEndpointSkillStage}
                                />
                            </FormField>
                        </div>
                        <hr/>
                        <div><strong>FE Endpoint</strong> <i className='awsui-text-secondary'>(optional)</i></div>
                        <div data-awsui-column-layout-root='true'>
                            <FormField label='Skill Id'>
                                <Input
                                    id='providerConfig.feEndpoint.skillId'
                                    placeholder={'amzn1.echo-sdk-ams.app.abcd123-e456-789f-g12h-3ijk4lm5no67'}
                                    value={currentProviderConfig?.feEndpoint?.skillId}
                                    readonly={!isEditing}
                                    onChange={onChangeHandler}
                                    invalid={validationState.invalidFeEndpointSkillId}
                                />
                            </FormField>
                            <FormField label='Skill Stage'>
                                <Input
                                    id='providerConfig.feEndpoint.skillStage'
                                    placeholder={'development'}
                                    value={currentProviderConfig?.feEndpoint?.skillStage}
                                    readonly={!isEditing}
                                    onChange={onChangeHandler}
                                    invalid={validationState.invalidFeEndpointSkillStage}
                                />
                            </FormField>
                        </div>
                    </ColumnLayout>
                </div>
            </FormSectionView>
        </div>
  );
};

export default SpiProviderOnboardingEndpointSectionView;

