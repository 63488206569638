import React from 'react';
import { Popover } from '@amzn/awsui-components-react';
import { formatDate, fromTimestampToFormattedDateTime } from '../../util/stringAndMappingHelper';
import { IAlexaLabMetric } from '../../models/AlexaLabMetrics';

const Timestamp = ({ alexaLabMetric }: { alexaLabMetric: IAlexaLabMetric }) => {
  const startDate = formatDate(alexaLabMetric.metadata.reportStartTime);
  const endDate = formatDate(alexaLabMetric.metadata.reportEndTime);
  const refreshTime = fromTimestampToFormattedDateTime(alexaLabMetric.metadata.apiExecutionTime);

  return <div>
    <p>{`Date range: ${startDate} to ${endDate}`}</p>
    <p>{`Refreshed: ${refreshTime}`}</p>
  </div>;
};

export default ({
  children, alexaLabMetric
}: {
  children: React.ReactNode, alexaLabMetric: IAlexaLabMetric
}) => (
  <Popover
    size='large'
    position='top'
    dismissButton={false}
    content={<Timestamp alexaLabMetric={alexaLabMetric} />}
  >
    {children}
  </Popover>
);
