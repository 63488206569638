import { getOdysseyExperienceLink } from '../constants/odysseyEndpoints';
import { IFlattenedExperience } from '../models/FlattenedExperience';

export const generateMhcSimApprovalTemplate = (
    experience: IFlattenedExperience,
    userHasTestedExperience: boolean,
    cxDescription: string,
    bullseyeDescription: string,
    isMonetaryExperience: string,
) => {
    return `### General information
| Name | Value |
|--|--|
| Experience link | [${experience.id}](${getOdysseyExperienceLink(experience.id)}) |
| Experience Type | ${experience.type} |
| Experience locale | ${experience.locale || 'Un-specified'} |
| Does this experience give any monetary value (discounts, coupons, etc.) to customers? | ${isMonetaryExperience === 'true' ? 'Yes' : 'No'} |
---

### Tell us more about your experience

#### CX description of the content provided to the customer

${cxDescription}

#### What customer segment are you targeting specifically with your Bullseye?

${bullseyeDescription}

---

### Testing questions
| Name | Value |
|--|--|
| Has this user tested the experience fully? | ${userHasTestedExperience ? 'Yes' : 'No'}

---

#### After approval, what is the desired stage?
Launched
`;
};