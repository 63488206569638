import { Button, Checkbox, FormField, Input, Spinner, Alert } from '@amzn/awsui-components-react';
import * as React from 'react';
import { connect } from 'react-redux';
import { pushOverrideAccountSectionAction } from '../../actions/experienceEditViewActions';
import { FormSectionView } from '../../components/common/FormSectionView';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { AppState } from '../../reducers/index';
import { getRole } from '../../util/stringAndMappingHelper';
import { LinkedCIFTestingWiki } from '../../components/common/LinksRenderHelper';

export const accountLimit = 10;

interface IOverrideAccountSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

interface IOverrideAccountSectionViewState {
    overrideAccounts: string[];
    verboseLoggingEnabled: boolean;
    isAdmin: boolean;
}

export class OverrideAccountSectionView extends
    React.Component<IOverrideAccountSectionViewProps,IOverrideAccountSectionViewState> {

    constructor(props: IOverrideAccountSectionViewProps) {
        super(props);
        this.state = {
            overrideAccounts: props.experience?.guardrails?.overrideAccounts || [],
            verboseLoggingEnabled: props.experience?.guardrails?.verboseLoggingEnabled || false,
            isAdmin: props.experience?.permissionRole === 'ADMIN' || false,
        };
    }

    updateOverrideAccounts(overrideAccounts: string[], verboseLoggingEnabled: boolean) {
        const { dispatch } = this.props;
        dispatch(pushOverrideAccountSectionAction(overrideAccounts, verboseLoggingEnabled));
        this.setState({overrideAccounts, verboseLoggingEnabled});
    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate } = this.props;

        if (!experience) {
            return <FormSectionView title={'Testing Accounts'}>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const overrideAccounts: string[] = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
            ? updateCandidate.getOverrideAccounts() || []
            : experience.guardrails?.overrideAccounts || []);

        const verboseLoggingEnabled: boolean = ((isEditing && updateCandidate) ?
            updateCandidate.getVerboseLoggingEnabled() : experience.guardrails?.verboseLoggingEnabled) || false;

        const shouldDisableInput = !isEditing;
        const shouldDisableVerboseLogging = !isEditing || isUpdating;

        const isAdmin: boolean = getRole(experience.permissionRole) === 'ADMIN';

        const customerIdsRemainEditableMsg = 'Generally, updating testers will not affect the last modified time of an experience.';

        return <FormSectionView title='Testing Accounts' description='Test your use case on a production device
            to validate it is triggering correctly, and the voice injection works as you intended.
            Only use cases in the "TESTABLE" state can be tested. Please allow up to 20 minutes
            after a Customer ID is added to this list.' >
            <Alert>
                Please employ a 4-way testing strategy for CIF Campaigns. See <LinkedCIFTestingWiki />  for details.
            </Alert>
            <br />
            {overrideAccounts.map((cid, index) => {
                return <div className='awsui-util-mb-l' key={index}>
                    <FormField label='Customer ID' className='awsui-grid' description={index === 0 ? customerIdsRemainEditableMsg : ''} >
                        <div className='awsui-row'>
                            <div className='col-4'>
                                <Input
                                    id={`experience.testing-account-${index}`}
                                    controlId={`experience.testing-account-${index}`}
                                    readonly={shouldDisableInput}
                                    value={cid}
                                    onChange={(e) => {
                                        if (e.detail.value.length > 0 ) {
                                            overrideAccounts[index] = e.detail.value;
                                        }
                                        this.updateOverrideAccounts(overrideAccounts, verboseLoggingEnabled);
                                    }}/>
                            </div>
                        </div>
                    </FormField>
                </div>;
            })}

            <Button
                id='button.add-test-account'
                disabled={shouldDisableInput || (overrideAccounts.length >= accountLimit && !isAdmin)}
                onClick={() => {
                    this.updateOverrideAccounts(overrideAccounts.concat(['']), verboseLoggingEnabled);
                }}>Add tester</Button>
            <Button
                id='button.remove-test-account'
                disabled={shouldDisableInput}
                onClick={() => {
                    this.updateOverrideAccounts(overrideAccounts.slice(0, -1), verboseLoggingEnabled);
                }}>Remove tester</Button>
            <Button
                id='button.remove-all-test-accounts'
                disabled={shouldDisableInput}
                onClick={() => {
                    this.updateOverrideAccounts([], verboseLoggingEnabled);
                }}>Remove all testers</Button>
            {!isAdmin ? (
            <p className='awsui-text-secondary'>
                May add {overrideAccounts.length >= accountLimit
                    ? 0
                    : accountLimit - overrideAccounts.length} more testers
            </p>) : null}
            <hr />
            <Checkbox
                id='experience.enable-verbose-logging'
                controlId='experience.enable-verbose-logging'
                disabled={shouldDisableVerboseLogging}
                checked={verboseLoggingEnabled}
                description='Enable verbose logging to get more detailed opportunity
                    and filtering information in Atocha for override accounts.
                    See the Odyssey Wiki for more information on accessing this data.'
                onChange={(e) => {
                    this.updateOverrideAccounts(overrideAccounts, e.detail.checked);
                }}> Enable Verbose Logging
            </Checkbox>

        </FormSectionView>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(OverrideAccountSectionView);
