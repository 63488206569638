import moment from 'moment';
import { IExperience, ExperienceStatus, ExperienceType } from '../models/Experience';
import { cifExperienceContentEmpty } from '../models/cifExperience/CifExperienceContent';
import { appExperienceContentEmpty, deviceExperienceContentEmpty } from '../models/appDeviceExperience/NotificationExperienceContent';
import { ITemplateVariable } from '../models/TemplateVariable';
import { ShortenedExperienceType, IFlattenedExperience, ContentType, FeatureType } from '../models/FlattenedExperience';
import { LOCALE_TO_MARKETPLACE } from '../constants/locales';
import { MARKETPLACES, MARKETPLACES_FLIPPED, MARKETPLACE_TO_REGION } from '../constants/marketplaces';
import { getCurrRegionStage } from './context';
import { Select } from '@amzn/awsui-components-react';
import { IFilter } from '../models/Filter';
import { PermissionRole } from '../models/PermissionRole';
import _ from 'lodash';
import { isNullOrUndefined } from 'util';
import { IOdysseyExperience } from '../models/v2/IOdysseyExperience';
import { IMetadata } from '../models/Metadata';
import { IMetadataInfo } from '../models/v2/metadata/IMetadata';
import { IApprovalMetadata } from '../models/ApprovalInfo';

export const DAYS: { [key: number]: string } = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday'
};

export const skillIDRegex = new RegExp('amzn\\d\\.((ask\\.skill)|(echo-sdk-ams\\.app))\\.(\\w){8}-(\\w){4}-(\\w){4}-(\\w){4}-(\\w){12}');

export const extractMarketplaceFromFlattenedExperience = (experience: IFlattenedExperience) => {
    return (experience.locale && LOCALE_TO_MARKETPLACE[experience.locale]) || experience.marketplace || 'US';
};

export const fromTimestampToFormattedDateTime = (timestamp: number | string) => {
    return moment(timestamp).format('MM/DD/YYYY HH:mm UTCZ');
};

export const fromUnixTimetoFormattedDateTime = (timestamp: number) => {
    return moment.unix(timestamp).format('MM/DD/YYYY HH:mm UTCZ');
};

export const extractConfiguredLocaleFromExperience = (experience: IExperience) => {
    let foundLocale: string | undefined;
    if (experience.type === 'CIF') {
        if (experience.content) {
            Object.keys(experience.content)
                .filter(locale => (!cifExperienceContentEmpty(experience.content[locale])))
                .forEach(locale => {
                    foundLocale = locale;
                });
        } else if (experience.referralContent && experience.referralContent.localizedQuestion) {
            const localizedQuestions = experience.referralContent.localizedQuestion;
            Object.keys(experience.referralContent.localizedQuestion)
                .filter(locale => !experience.referralContent
                    || !cifExperienceContentEmpty(localizedQuestions[locale]))
                .forEach(locale => {
                    foundLocale = locale;
                });
        } else if (experience.visualContent) {
            Object.keys(experience.visualContent)
                .filter(locale => (experience.visualContent && (experience.visualContent[locale].promptlessContent || false)))
                .forEach(locale => {
                    foundLocale = locale;
                });
        }
    } else if (experience.type === 'AppNotification' && experience.notificationContent) {
        const contents = experience.notificationContent;
        Object.keys(contents)
            .filter(locale => !appExperienceContentEmpty(contents[locale]))
            .forEach(locale => {
                foundLocale = locale;
            });
    } else if (experience.type === 'DeviceNotification' && experience.notificationContent) {
        const contents = experience.notificationContent;
        Object.keys(contents)
            .filter(locale => !deviceExperienceContentEmpty(contents[locale]))
            .forEach(locale => {
                foundLocale = locale;
            });
    }

    const enabledLocale = (experience.type === 'AppNotification' || experience.type === 'DeviceNotification')
        ? extractFilterKeyFromExperience(experience) : undefined;

    return foundLocale || enabledLocale;
};

export const extractConfiguredContentFromExperience = (experience: IExperience) => {
    let content: string | undefined;
    if (experience.type === 'CIF') {
        if (experience.content) {
            Object.keys(experience.content)
                .filter(locale => (!cifExperienceContentEmpty(experience.content[locale])))
                .forEach(locale => {
                    content = experience.content[locale].text;
                });
        } else if (experience.referralContent && experience.referralContent.localizedQuestion) {
            const localizedQuestions = experience.referralContent.localizedQuestion;
            Object.keys(experience.referralContent.localizedQuestion)
                .filter(locale => !cifExperienceContentEmpty(localizedQuestions[locale]))
                .forEach(locale => {
                    content = localizedQuestions[locale].text;
                });
        }
    } else if (experience.type === 'AppNotification' && experience.notificationContent) {
        const contents = experience.notificationContent;
        Object.keys(contents)
            .filter(locale => !appExperienceContentEmpty(contents[locale]))
            .forEach(locale => {
                content = contents[locale].displayContent;
            });
    } else if (experience.type === 'DeviceNotification' && experience.notificationContent) {
        const contents = experience.notificationContent;
        Object.keys(experience.notificationContent)
            .filter(locale => !deviceExperienceContentEmpty(contents[locale]))
            .forEach(locale => {
                content = contents[locale].spokenContent;
            });
    }

    return content;
};

export const extractStartDateFromFilter = (enabledFilter: IFilter | undefined) => {
    return enabledFilter?.activationConfiguration?.startDate;
};

export const extractEndDateFromFilter = (enabledFilter: IFilter | undefined) => {
    return enabledFilter?.activationConfiguration?.endDate;
};

export const extractDeviceTypesFromFilter = (enabledFilter: IFilter | undefined) => {
    return enabledFilter?.deviceTypes?.toString();
};

export const extractTriggerIntentFromExperience = (experience: IExperience) => {
    let data: string | undefined;
    const nluOpportunityTargetings = experience.nluOpportunityTargetings;
    if (experience.type === 'CIF' && nluOpportunityTargetings) {
        data = nluOpportunityTargetings.map(item => item.bluIntent).toString();
    }
    return data;
};

export const extractTriggerSkillsFromExperience = (experience: IExperience) => {
    let data: string | undefined;
    const nluOpportunityTargetings = experience.nluOpportunityTargetings;
    if (experience.type === 'CIF' && nluOpportunityTargetings) {
        data = nluOpportunityTargetings.flatMap(item => item.publisherIdTargeting).toString();
    }
    return data;
};

export const extractTriggerSkillCategoriesFromExperience = (experience: IExperience) => {
    let data: string | undefined;
    const nluOpportunityTargetings = experience.nluOpportunityTargetings;
    if (experience.type === 'CIF' && nluOpportunityTargetings) {
        data = nluOpportunityTargetings.flatMap(item => item.categoryTargeting).toString();
    }
    return data;
};

export const extractTriggerSlotNameFromExperience = (experience: IExperience) => {
    let data: string | undefined;
    const nluOpportunityTargetings = experience.nluOpportunityTargetings;
    if (experience.type === 'CIF' && nluOpportunityTargetings) {
        data = nluOpportunityTargetings.flatMap(item => item.slots?.map(slot => slot.name)).toString();
    }
    return data;
};

export const extractTriggerPublisherDataFromExperience = (experience: IExperience) => {
    let data: string | undefined;
    const nluOpportunityTargetings = experience.nluOpportunityTargetings;
    if (experience.type === 'CIF' && nluOpportunityTargetings) {
        data = nluOpportunityTargetings.flatMap(item => item.targetPublisherData?.map(publisherData => publisherData.key)).toString();
    }
    return data;
};

export const extractContentTypeFromExperience = (experience: IExperience): ContentType => {
    const { type } = experience;
    switch (type) {
        case 'CIF':
            return (experience.referralContent && experience.referralContent.localizedQuestion) ? 'Referral' : 'Non-Referral';
        case 'AppNotification':
        case 'DeviceNotification':
            return 'Non-Referral';
        default:
            return 'Non-Referral';
    }
};

export const extractFeatureTypeFromExperience = (experience: IExperience): FeatureType => {
    if (experience.capabilityId) {
        if (experience.capabilityId === experience.id) {
            return 'Campaign Override ID';
        } else if (skillIDRegex.test(experience.capabilityId)){
            return 'Skill ID';
        } else {
            return 'Capability ID';
        }
    }
    if (experience.hintId) {
        return 'Hint ID';
    } else {
        return 'Other';
    }
};

export const extractBullseyeSegmentFromExperience = (enabledFilter: IFilter | undefined) => {
    return enabledFilter?.bullseye?.toString();
};

export const extractCurrentStateDurationFromExperience = (experience: IExperience) => {
    if (experience.metadata.changedStateAt === 0) {
        return undefined;
    }

    const now = moment();
    const changedStateDate = moment(experience.metadata.changedStateAt);
    const duration = Math.ceil(now.diff(changedStateDate, 'days', true));

    return duration;
};

export const extractVisualInterstitialContentFromExperience = (experience: IExperience) => {
    const visualContent = experience.visualContent;
    if (experience.type === 'CIF' && visualContent) {
        for (const locale in Object.keys(visualContent)) {
            if (visualContent.hasOwnProperty(locale)) {
                return visualContent[locale].aplDataSource.cards;
            }
        }
    }

    return [];
};

export const extractAAAServiceNameFromExperience = (experience: IExperience) => {
    let data: string | undefined;
    const templateVariables = experience.templateVariables || [];
    if (templateVariables && anyRemoteVariable(templateVariables)) {
        data = templateVariables.filter(variable => variable.variableType === 'REMOTE_SERVICE')
            .flatMap(templateVariable => templateVariable.invocationParameters?.AAAServiceName).toString();
    }
    return data;
};

export const extractAAAOperationNameFromExperience = (experience: IExperience) => {
    let data: string | undefined;
    const templateVariables = experience.templateVariables || [];
    if (templateVariables && anyRemoteVariable(templateVariables)) {
        data = templateVariables.filter(variable => variable.variableType === 'REMOTE_SERVICE')
            .flatMap(templateVariable => templateVariable.invocationParameters?.AAAOperationName).toString();
    }
    return data;
};

export const extractCTIFromExperience = (experience: IExperience) => {
    let data: string | undefined;
    const templateVariables = experience.templateVariables || [];
    if (templateVariables && anyRemoteVariable(templateVariables)) {
        data = templateVariables.filter(variable => variable.variableType === 'REMOTE_SERVICE')
            .flatMap(templateVariable => templateVariable.invocationParameters?.CTI).toString();
    }
    return data;
};

export const extractIsDynamicExperienceFromExperience = (experience: IExperience) => {
    const content = extractConfiguredContentFromExperience(experience);
    const reg = /\${.*?}/g;
    const result = content?.match(reg)?.toString();
    return result ? 'True' : 'False';
};

export const extractEndPointFromExperience = (experience: IExperience) => {
    const endpointList: string[] = [];
    const templateVariables = experience.templateVariables || [];
    if (templateVariables && anyRemoteVariable(templateVariables)) {
        templateVariables.flatMap(templateVariable => {
            const endpoints = templateVariable.invocationParameters?.endpoint;
            if (endpoints) {
                if (endpoints.NA) {
                    endpointList.push(endpoints.NA);
                }
                if (endpoints.FE) {
                    endpointList.push(endpoints.FE);
                }
                if (endpoints.EU) {
                    endpointList.push(endpoints.EU);
                }
            }
        });
    }
    return endpointList.toString();
};

export const anyRemoteVariable = (variables: ITemplateVariable[]) => {
    return variables.filter(variable => variable.variableType === 'REMOTE_SERVICE').length > 0;
};

/**
 * For type CIF only. Take the enabled locale first:
 * if one marketplace is configured:
 *      use that (no matter what locale is enabled)
 * if multiple marketplace is configured, and configured locale is one of them:
 *      use the marketplace that matches the locale
 * if multiple marketplace is configured, and configured locale is not one of them:
 *      ?? (still uses default locale -> marketplace for now)
 * if no marketplace configured:
 *      use configured locale -> marketplace
 * if no marketplace nor locale is configured:
 *      default to en_US and US
 */
export const extractConfiguredMarketplaceFromExperience = (experience: IExperience) => {
    const configuredLocale = extractConfiguredLocaleFromExperience(experience);
    if (experience.type === 'CIF' && experience.filters) {
        const filters = Object.keys(experience.filters);

        if (filters.length > 1) {
            if (configuredLocale) {
                return MARKETPLACES_FLIPPED[LOCALE_TO_MARKETPLACE[configuredLocale]];
            } else {
                return undefined;
            }
        } else if (filters.length === 1) {
            return filters[0];
        } else {
            return undefined;
        }
    }
};

export const extractEnabledRegionFromExperience = (experience: IExperience) => {
    if (experience.type === 'CIF') {
        const foundMarketplace = extractConfiguredMarketplaceFromExperience(experience);
        if (foundMarketplace) {
            return MARKETPLACE_TO_REGION[MARKETPLACES[foundMarketplace]];
        }
    }

    const foundLocale = extractConfiguredLocaleFromExperience(experience);
    if (!foundLocale) return undefined;

    return MARKETPLACE_TO_REGION[LOCALE_TO_MARKETPLACE[foundLocale]];
};

export const extractEnabledFilterFromExperience = (experience: IExperience): IFilter | undefined => {
    const filterKeys = Object.keys(experience.filters);

    if (filterKeys.length === 1) {
        return experience.filters[filterKeys[0]];
    }

    const enabledFilterKeys = Object.keys(experience.filters).filter(key => {
        return experience.filters[key].enabled;
    });

    return (enabledFilterKeys.length > 0) ? experience.filters[enabledFilterKeys[0]] : undefined;
};

export const extractFilterKeyFromExperience = (experience: IExperience): string | undefined => {

    const filterKeys = Object.keys(experience.filters);

    return (filterKeys.length > 0) ? filterKeys[0] : undefined;
};

export const shortenExperienceType = (experience: IExperience | IOdysseyExperience): ShortenedExperienceType => {
    const { type } = experience;
    switch (type) {
        case 'CIF':
            if ('visualContent' in experience && (experience as IExperience).visualContent) {
                return 'VCIF';
            }
            return 'CIF';
        case 'AppNotification':
            return 'App';
        case 'DeviceNotification':
            return 'Device';
        case 'MobileHomeCard':
            return 'MHC';
        case 'RotatingContentPanel':
            return 'RCP';
        default:
            return 'CIF';
    }
};

export function toSelectOptions(values: string[]): Select.Option[] {
    return values.map((value: string) => {
        return {
            id: value.toString(),
            label: value.toString()
        };
    });

}

export function flip(obj: { [key: string]: string }) {
    const ret: { [key: string]: string } = {};
    Object.keys(obj).forEach((key: string) => {
        ret[obj[key]] = key;
    });
    return ret;
}

export function filterEmptyStrings(values?: string[]): string[] | undefined {
    if (values == null || values === undefined || values.length === 0) {
        return undefined;
    }

    return values.filter(value => (value != null) && (value !== ''));
}

export function urlSearchParams(): URLSearchParams {
    const split = window.location.href.split('?');
    return new URLSearchParams(split.length > 1 ? '?' + split[1] : '');
}

export function isExperienceApproved(status: ExperienceStatus): boolean {
    return isLiveExperience(status) || status === 'SCHEDULED' || status === 'SCHEDULING';
}

export function getRole(role?: PermissionRole): PermissionRole {
    if (role == null) { return PermissionRole.VIEWER; }

    const urlParams = urlSearchParams();

    if (role === PermissionRole.ADMIN) {
        if (urlParams.has('viewer')) {
            return PermissionRole.VIEWER;
        } else if (urlParams.has('operator')) {
            return PermissionRole.OPERATOR;
        } else if (urlParams.has('owner')) {
            return PermissionRole.OWNER;
        } else {
            return PermissionRole.ADMIN;
        }
    }

    return role;
}

export function getTypeIconPath(type: ExperienceType): string {
    switch (type) {
        case 'AppNotification':
        case 'MobileHomeCard':
            return '/how-to-notification.svg';
        case 'DeviceNotification':
            return '/how-to-device.svg';
        default:
            return '/how-to-CIF.svg';
    }
}

export function isStringEmpty(str?: string) {
    return !str || str.length === 0;
}

export function isListEmpty(lst?: any[]) {
    return !lst || lst.length === 0;
}

export function countTextFunction(count: number) { return `${count} matched`; }

/* transformation function can be moved to a different file
 * leaving it here for now.
 */
export const transformExperience = (experience: IExperience): IFlattenedExperience => {
    const locale = extractConfiguredLocaleFromExperience(experience);
    const marketplaceObfuscated = extractConfiguredMarketplaceFromExperience(experience);
    const enabledFilter = extractEnabledFilterFromExperience(experience);
    return {
        ...experience,
        shortenedType: shortenExperienceType(experience),
        stage: getCurrRegionStage().stage,
        locale,
        marketplace: marketplaceObfuscated ? MARKETPLACES[marketplaceObfuscated] : undefined,
        contentString: extractConfiguredContentFromExperience(experience),
        createdBy: experience.metadata.createdBy,
        createdDate: fromTimestampToFormattedDateTime(experience.metadata.createdAt),
        modifiedBy: experience.metadata.modifiedBy,
        modifiedDate: fromTimestampToFormattedDateTime(experience.metadata.modifiedAt),
        testerAlias: experience.metadata.testerAlias,
        testerCustomerId: experience.metadata.testerCustomerId,
        enabledFilter,
        region: extractEnabledRegionFromExperience(experience),
        weblabName: experience.weblab ? experience.weblab.name : undefined,
        startDate: extractStartDateFromFilter(enabledFilter),
        endDate: extractEndDateFromFilter(enabledFilter),
        deviceTypes: extractDeviceTypesFromFilter(enabledFilter),
        triggerIntents: extractTriggerIntentFromExperience(experience),
        triggerSkills: extractTriggerSkillsFromExperience(experience),
        triggerSkillCategories: extractTriggerSkillCategoriesFromExperience(experience),
        triggerSlots: extractTriggerSlotNameFromExperience(experience),
        triggerPublisherData: extractTriggerPublisherDataFromExperience(experience),
        contentType: extractContentTypeFromExperience(experience),
        featureType: extractFeatureTypeFromExperience(experience),
        bullseyeSegment: extractBullseyeSegmentFromExperience(enabledFilter),
        currentStateDuration: extractCurrentStateDurationFromExperience(experience),
        launchTreatmentPercentage: experience.weblab?.launchTreatmentPercentage,
        weblabAllocation: experience.weblab?.weblabAllocationDetail,
        aaaServiceName: extractAAAServiceNameFromExperience(experience),
        aaaOperationName: extractAAAOperationNameFromExperience(experience),
        cti: extractCTIFromExperience(experience),
        dynamicContentVariables: extractIsDynamicExperienceFromExperience(experience),
        endpoint: extractEndPointFromExperience(experience)
    };
};

export const transformOdysseyExperience = (experience: IOdysseyExperience): IFlattenedExperience => {
    const generalConstraint = experience.constraints?.GeneralConstraint;
    const approvalInfoMetadata = experience.metadataInfo?.ApprovalInfoMetadata;
    const experienceMetadata = experience.metadataInfo?.ExperienceMetadata as IMetadata;
    const customerSegmentConstraint = experience.constraints?.CustomerSegmentConstraint;
    const experienceConstraints = experience.constraints?.ExperienceConstraints;

    const flattenedExperience = {
        ...experience,
        shortenedType: shortenExperienceType(experience),
        stage: getCurrRegionStage().stage,
        locale: extractLocale(experience),
        marketplace: generalConstraint && generalConstraint.marketplace ? MARKETPLACES[generalConstraint.marketplace] : undefined,
        createdBy: experienceMetadata?.createdBy,
        createdDate: fromTimestampToFormattedDateTime(experienceMetadata?.createdAt),
        modifiedBy: experienceMetadata?.modifiedBy,
        modifiedDate: fromTimestampToFormattedDateTime(experienceMetadata?.modifiedAt),
        testerAlias: experienceMetadata?.testerAlias,
        testerCustomerId: experienceMetadata?.testerCustomerId,
        weblabName: generalConstraint?.weblabName,
        startDate: experienceConstraints?.startDate,
        endDate: experienceConstraints?.endDate,
        bullseyeSegment: customerSegmentConstraint?.bullseyeSegmentId?.toString(),
        opportunityTriggers: [],
        opportunityTriggerExclusions: [],
        nluOpportunityTargetings: [],
        nluOpportunityTargetingExclusions: [],
        region: MARKETPLACE_TO_REGION[LOCALE_TO_MARKETPLACE[extractLocale(experience)]],
        filters: {},
        content: {},
        weblab: {},
        cancellationReason: approvalInfoMetadata?.cancellationReason,
        approvalInfo: extraApprovalInfo(approvalInfoMetadata),
        approvalId: approvalInfoMetadata?.approvalId,
        metadata: experienceMetadata
    };

    switch(experience.type) {
        case 'MobileHomeCard':
            return transformMhcOdysseyExperience(experience, flattenedExperience);
        case 'RotatingContentPanel':
            return transformRcpOdysseyExperience(experience, flattenedExperience);
        default:
            return flattenedExperience;
    }
};

export const extraApprovalInfo = (approvalInfoMetadata?: IMetadataInfo): IApprovalMetadata | undefined => {
    return approvalInfoMetadata ? {
        simDocumentId: approvalInfoMetadata.simDocumentId
    } : undefined;
};

export const transformRcpOdysseyExperience = (experience: IOdysseyExperience, flattenedExperience: IFlattenedExperience): IFlattenedExperience => {
    flattenedExperience.weblab.name = experience.constraints?.GeneralConstraint.weblabName;
    return flattenedExperience;
};

export const transformMhcOdysseyExperience = (experience: IOdysseyExperience, flattenedExperience: IFlattenedExperience): IFlattenedExperience => {
    const mobileHomeCardConstraint = experience.constraints?.MobileHomeCardConstraint;

    return {
        ...flattenedExperience,
        startDate: extraDate(mobileHomeCardConstraint?.startTime),
        endDate: extraDate(mobileHomeCardConstraint?.endTime)
    };
};

export const extraDate = (time?: number): string => {
    return time ? moment(time).format('YYYY-MM-DD') : '';
};

export const extractLocale = (experience: IOdysseyExperience): string => {

    switch (experience.type) {
        case 'MobileHomeCard':
            const mobileHomeCardConstraint = experience.targeting?.MobileHomeCardTargeting;
            return mobileHomeCardConstraint && mobileHomeCardConstraint.locale ? mobileHomeCardConstraint.locale : 'en_US';
        default:
            return 'en_US';
    }
};

// if parsing fails, it returns the original string
export const bestEffortJsonFormat = (jsonInputStr?: string) => {
    if (!jsonInputStr) return undefined;

    try {
        const obj = JSON.parse(jsonInputStr);
        return JSON.stringify(obj, null, 2);
    } catch (error) {
        return jsonInputStr;
    }
};

export const isSubset = (aSubset: any, aSuperset: { [x: string]: any; }) => {
    return _.every(aSubset, (val, key) => _.isEqual(val, aSuperset[key]));
};

export const trimAndDedupeStringList = (inStrArr: string[]): string[] => {
    return Array.from(new Set(inStrArr.filter(str => str.length > 0).map(str => str.trim())));
};

export const formatDate = (dateString: string): string => {
    return moment(dateString).format('MM/DD/YYYY UTCZ');
};

export const simpleDateFormat = (timestamp: number): string => {
    return moment(timestamp).format('MMM DD YYYY');
};

export const isLiveExperience = (status?: ExperienceStatus): boolean => {
    return status?.includes('LIVE') || status === 'LAUNCHED' || status === 'PAUSED' || status === 'SCHEDULED' || status === 'EXPIRED';
};

export const isNotificationExperience = (type?: ExperienceType): boolean => {
    return type === 'AppNotification' || type === 'DeviceNotification';
};

export const bulkAddDeviceExperience = (status?: ExperienceStatus): boolean => {
    return isLiveExperience(status) || status === 'DRAFT' || status === 'UNDER_REVIEW' || status === 'TESTABLE';
};

export const experienceWithAlexaLabDialUpMetric = (experience: IFlattenedExperience): boolean => {
    if (experience.status !== 'LIVE') {
        return experience.status === 'LIVE_FIFTY_PERCENT' || experience.status === 'EXPIRED' || experience.status === 'PAUSED';
    } else {
        const lastDialupStage = experience.approvalInfo?.lastDialupStage;
        return lastDialupStage === 'LIVE_FIFTY_PERCENT';
    }
};

export const isCifLegacyTestExperience = (experience: IFlattenedExperience): boolean => {
    return isNullOrUndefined(experience.launchTreatmentPercentage) && experience.type === 'CIF' && experience.status === 'LIVE' && experience.approvalInfo?.lastDialupStage !== undefined;
};

export const getRightExposureFromStatus = (status?: ExperienceStatus): number => {
    if (status === 'LIVE_FIFTY_PERCENT' || status === 'LIVE_HUNDRED_PERCENT') {
        return 1;
    } else if (status === 'LIVE_TEN_PERCENT') {
        return 0.2;
    }
    return 0;
};

export const TESTING_CONFIRMATION_PROMPT = 'I have fully tested this experience';
