import { ILoadCXWeeklyMetricsAction } from '../actions/experienceMetricsViewActions';
import {
    LOADING_CX_WEEKLY_METRICS,
    LOADED_CX_WEEKLY_METRICS,
    ERROR_LOADING_CX_WEEKLY_METRICS,
} from '../constants/experienceMetricsViewActionTypes';
import { IRawCifMetricsData } from '../models/CifMetrics';

export interface IExperienceCXWeeklyMetricsViewState {
    isLoading: boolean;
    cxWeeklyMetrics?: IRawCifMetricsData[];
    error?: Error;
}

export const experienceCXWeeklyMetricsViewReducer = (
    state: IExperienceCXWeeklyMetricsViewState = { isLoading: false },
    action: ILoadCXWeeklyMetricsAction
): IExperienceCXWeeklyMetricsViewState => {
    switch (action.type) {
        case LOADING_CX_WEEKLY_METRICS:
            return {
                ...state,
                isLoading: true
            };
        case LOADED_CX_WEEKLY_METRICS:
            const { cxWeeklyMetrics } = action;

            return {
                ...state,
                isLoading: false,
                cxWeeklyMetrics
            };
        case ERROR_LOADING_CX_WEEKLY_METRICS:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};
