import * as React from 'react';
import { Wizard } from '@amzn/awsui-components-react';
import ActivationDatesWithTimeSectionView from '../sections/ActivationTimeDateSectionView';
import RegionSectionView from '../sections/RegionSectionView';
import BullseyeSectionView from '../sections/BullseyeSectionView';
import RCPDevicesSectionView from '../sections/RCPDevicesSectionView';
import RCPDisplayElementsSectionView from '../sections/RCPDisplayElementsSectionView';
import { ExperienceTypeSectionView } from '../../components/detail/creation/ExperienceTypeSectionView';
import { ExperienceType } from '../../models/Experience';
import PromotedCapabilitySectionView from '../sections/PromotedCapabilitySectionView';
import MetadataSectionView from '../sections/MetadataSectionView';
import TouchActionsSectionView from '../sections/RCPTouchActionsSectionView';

export const RotatingContentPanelWorkflow = (permissions: string[] | undefined): Wizard.Step[] => {
    return [
    {
        title: 'Customer targeting ',
        content: <div>
            <ActivationDatesWithTimeSectionView />
            <RegionSectionView />
            <BullseyeSectionView />
        </div>
    },
    {
        title: 'Create experience',
        content: <div>
            <RCPDisplayElementsSectionView />
            <TouchActionsSectionView />
            <PromotedCapabilitySectionView />
            <RCPDevicesSectionView />
            <MetadataSectionView />
        </div>
    },
    {
        title: 'Review experience',
        content: <div>
            <ExperienceTypeSectionView
                selectedExperienceType={'RotatingContentPanel' as ExperienceType}
                disableTypeSelection={true}
                permissions={permissions}
            />
            <ActivationDatesWithTimeSectionView />
            <RegionSectionView />
            <BullseyeSectionView />
            <RCPDisplayElementsSectionView />
            <TouchActionsSectionView />
            <PromotedCapabilitySectionView />
            <RCPDevicesSectionView />
            <MetadataSectionView />
        </div>
    }
];
};
