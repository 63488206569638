import { isStringEmpty } from './stringAndMappingHelper';
import {
    EMPTY_STRING,
    MEDIA_CENTRAL_IMAGE_URL_PREFIX,
    NOTIFICATION_ERROR_MESSAGES,
    TEMPLATE_PREFIX,
    TEMPLATE_SUFFIX,
    WILDCARD_REGEX
} from '../constants/validatorConstants';


export class NotificationValidator {
    // Notification Content Validation

    static isTitleValid(title?: string, variables?: string[], locale?: string, enabled?: boolean) {
        if (enabled && isStringEmpty(title)) {
            throw new Error(NOTIFICATION_ERROR_MESSAGES.MISSING_TITLE_TEMPLATE.replace('%s', locale || ''));
        }

        if (title) {
            this.validateTemplateVariablesResolveInContent(title, NOTIFICATION_ERROR_MESSAGES.INVALID_TITLE_CONTENT_TEMPLATE, variables);
        }
    }

    static isDisplayContentValid(displayContent?: string, variables?: string[], locale?: string, enabled?: boolean) {
        if (enabled && isStringEmpty(displayContent)) {
            throw new Error(NOTIFICATION_ERROR_MESSAGES.MISSING_DISPLAY_CONTENT_TEMPLATE.replace('%s', locale || ''));
        }

        if (displayContent) {
            this.validateTemplateVariablesResolveInContent(displayContent, NOTIFICATION_ERROR_MESSAGES.INVALID_DISPLAY_CONTENT_TEMPLATE, variables);
        }
    }

    static isCtaButtonTitleValid(ctaButtonTitle?: string, variables?: string[], locale?: string, enabled?: boolean) {
        if (enabled && isStringEmpty(ctaButtonTitle)) {
            throw new Error(NOTIFICATION_ERROR_MESSAGES.MISSING_CTA_BUTTON_TITLE_TEMPLATE.replace('%s', locale || ''));
        }

        if (ctaButtonTitle) {
            this.validateTemplateVariablesResolveInContent(ctaButtonTitle, NOTIFICATION_ERROR_MESSAGES.INVALID_CTA_BUTTON_TITLE_TEMPLATE, variables);
        }
    }

    static isCtaButtonActionValid(ctaButtonAction?: string, variables?: string[], locale?: string, enabled?: boolean) {
        if (enabled && isStringEmpty(ctaButtonAction)) {
            throw new Error(NOTIFICATION_ERROR_MESSAGES.MISSING_CTA_BUTTON_ACTION_TEMPLATE.replace('%s', locale || ''));
        }

        if (ctaButtonAction) {
            this.validateTemplateVariablesResolveInContent(ctaButtonAction, NOTIFICATION_ERROR_MESSAGES.INVALID_CTA_BUTTON_ACTION_TEMPLATE, variables);
        }
    }

    static isSpokenContentValid(spokenContent?: string, variables?: string[], locale?: string, enabled?: boolean) {
        if (enabled && isStringEmpty(spokenContent)) {
            throw new Error(NOTIFICATION_ERROR_MESSAGES.MISSING_SPOKEN_CONTENT_TEMPLATE.replace('%s', locale || ''));
        }

        if (spokenContent) {
            this.validateTemplateVariablesResolveInContent(spokenContent, NOTIFICATION_ERROR_MESSAGES.INVALID_SPOKEN_CONTENT_TEMPLATE, variables);
        }
    }

    static isInteractionPayloadValid(interactionPayload?: string) {
        if (!isStringEmpty(interactionPayload)) {
            JSON.parse(interactionPayload || '');
        }
    }

    static isThumbnailImageValid(thumbnailImage?: string) {
        if (!isStringEmpty(thumbnailImage) && !thumbnailImage?.startsWith(MEDIA_CENTRAL_IMAGE_URL_PREFIX)) {
            throw new Error(NOTIFICATION_ERROR_MESSAGES.INVALID_THUMBNAIL_IMAGE_PREFIX);
        }
    }

    static isUrlValid(url?: string, variables?: string[], locale?: string, enabled?: boolean) {
        if (enabled && isStringEmpty(url)) {
            throw new Error(NOTIFICATION_ERROR_MESSAGES.MISSING_URL_TEMPLATE.replace('%s', locale || ''));
        }

        if (url) {
            this.validateTemplateVariablesResolveInContent(url, NOTIFICATION_ERROR_MESSAGES.INVALID_URL_TEMPLATE, variables);
        }
    }

    // Data Source Validation

    static isBullseyeValid(bullseye?: string, locale?: string, enabled?: boolean) {
        if (enabled && (isStringEmpty(bullseye) || bullseye === '0')) {
            throw new Error(NOTIFICATION_ERROR_MESSAGES.MISSING_BULLSEYE.replace('%s', locale || ''));
        }
    }

    static isSNSTopicValid(snsTopic?: string, locale?: string, enabled?: boolean) {
        if (enabled && isStringEmpty(snsTopic)) {
            throw new Error(NOTIFICATION_ERROR_MESSAGES.MISSING_SNS.replace('%s', locale || ''));
        }
    }

    static isPublisherRoleForAutoCreatedSnsTopicValid(publisherRoleForAutoCreatedSnsTopic?: string, locale?: string, enabled?: boolean) {
        const IAM_USER_ROLE_REGEX = /^arn:aws:iam::\d{12}:(user|role)\/([+=,.@\w-]{1,64})$/;

        if (enabled && isStringEmpty(publisherRoleForAutoCreatedSnsTopic)) {
            throw new Error(NOTIFICATION_ERROR_MESSAGES.EMPTY_AUTO_CREATED_SNS_PERMISSION.replace('%s', locale || ''));
        } else if (publisherRoleForAutoCreatedSnsTopic && publisherRoleForAutoCreatedSnsTopic.trim() !== publisherRoleForAutoCreatedSnsTopic) {
            throw new Error(NOTIFICATION_ERROR_MESSAGES.INVALID_AUTO_CREATED_SNS_PERMISSION_WHITESPACE);
        }else if (publisherRoleForAutoCreatedSnsTopic && !IAM_USER_ROLE_REGEX.test(publisherRoleForAutoCreatedSnsTopic)) {
            throw new Error(NOTIFICATION_ERROR_MESSAGES.INVALID_AUTO_CREATED_SNS_PERMISSION.replace('%s', publisherRoleForAutoCreatedSnsTopic).replace('%r', IAM_USER_ROLE_REGEX.toString()));
        }
    }

    // TODO: properly integrate this with common component validation CR: https://code.amazon.com/reviews/CR-29916278
    static isEndDateValid(endDate?: string, locale?: string, enabled?: boolean) {
        if (enabled && isStringEmpty(endDate)) {
            throw new Error(NOTIFICATION_ERROR_MESSAGES.MISSING_END_DATE.replace('%s', locale || ''));
        }
    }

    static validateTemplateVariablesResolveInContent(content: string, errorMessage: string, variables?: string[]) {
        let resolvedContent = content;
        for (const variable of variables || []) {
            resolvedContent = resolvedContent.replace(new RegExp(getFormalizedVariableName(variable), 'g'), EMPTY_STRING);
        }

        if (new RegExp(WILDCARD_REGEX).test(resolvedContent)) {
            throw new Error(errorMessage.replace('%s', content));
        }
    }
}

const getFormalizedVariableName = (variableName: string) => {
    return `${TEMPLATE_PREFIX}${variableName}${TEMPLATE_SUFFIX}`;
};
