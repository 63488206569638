import { AnyAction } from 'redux';
import {
    LOADED_BYPASS_PAUSE_LIST,
    LOADING_BYPASS_PAUSE_LIST,
    ERROR_LOADING_BYPASS_PAUSE_LIST,
    UPDATED_BYPASS_PAUSE_LIST,
    UPDATING_BYPASS_PAUSE_LIST,
    ERROR_UPDATING_BYPASS_PAUSE_LIST
} from '../constants/bypassPauseListActionTypes';
import { IBypassPauseList } from '../models/bypassPauseList/BypassPauseList';

export interface IBypassPauseListState {
    isLoading: boolean;
    updatedBypassPause: boolean;
    bypassPauseList?: IBypassPauseList;
    errorLoading?: Error;
    errorUpdating?: Error;
}

export const bypassPauseListReducer = (
    state: IBypassPauseListState = { isLoading: false, updatedBypassPause: false },
    action: AnyAction
): IBypassPauseListState => {
    switch (action.type) {
        case LOADING_BYPASS_PAUSE_LIST:
            return {
                ...state,
                isLoading: true,
                updatedBypassPause: false
            };
        case UPDATING_BYPASS_PAUSE_LIST:
            return {
                ...state,
                updatedBypassPause: false,
                isLoading: true
            };
        case LOADED_BYPASS_PAUSE_LIST:
            return {
                ...state,
                isLoading: false,
                bypassPauseList: action.bypassPauseList,
                errorLoading: undefined,
                errorUpdating: undefined
            };
        case UPDATED_BYPASS_PAUSE_LIST:
            return {
                ...state,
                isLoading: false,
                updatedBypassPause: true,
                errorLoading: undefined,
                errorUpdating: undefined
            };
        case ERROR_LOADING_BYPASS_PAUSE_LIST:
            return {
                ...state,
                isLoading: false,
                updatedBypassPause: false,
                errorLoading: action.error,
                errorUpdating: undefined
            };
        case ERROR_UPDATING_BYPASS_PAUSE_LIST:
            return {
                ...state,
                isLoading: false,
                updatedBypassPause: false,
                errorUpdating: action.error,
                errorLoading: undefined
            };
        default:
            return state;
    }
};