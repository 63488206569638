import { axios } from './axios';
import { API_ENDPOINT } from '../constants/lambdaEndpoints';
import { SendNotificationPath } from '../constants/lambdaEndpoints';
import { MARKETPLACE_TO_REGION } from '../constants/marketplaces';
import { LOCALE_TO_MARKETPLACE } from '../constants/locales';

export const sendPushNotification = async (
    cid: string,
    experienceId: string,
    locale: string,
    deviceTypes : string[]
): Promise<string> => {
    const searchParams = new URLSearchParams();
    searchParams.append('customerId', cid);
    searchParams.append('experimentId', experienceId);
    searchParams.append('locale', locale);
    searchParams.append('referenceId', experienceId);
    searchParams.append('region', MARKETPLACE_TO_REGION[LOCALE_TO_MARKETPLACE[locale]]);
    searchParams.append('deviceTypes', JSON.stringify(deviceTypes));

    const response = await axios.post(`${API_ENDPOINT}${SendNotificationPath}?${searchParams.toString()}`);
    return response.data.notificationSent;
};
