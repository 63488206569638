import { Select } from '@amzn/awsui-components-react';

export const BUSINESS_VERTICAL_DROPDOWN_OPTIONS: Select.Option[] = [
    {
        id: 'Alexa For Business',
        label: 'Alexa For Business'
    },
    {
        id: 'Audio',
        label: 'Audio'
    },
    {
        id: 'Auto',
        label: 'Auto'
    },
    {
        id: 'Communications',
        label: 'Communications'
    },
    {
        id: 'Games',
        label: 'Games'
    },
    {
        id: 'Health',
        label: 'Health'
    },
    {
        id: 'Home Productivity',
        label: 'Home Productivity'
    },
    {
        id: 'Kids',
        label: 'Kids'
    },
    {
        id: 'Kitchen',
        label: 'Kitchen'
    },
    {
        id: 'Knowledge',
        label: 'Knowledge'
    },
    {
        id: 'Language Services',
        label: 'Language Services'
    },
    {
        id: 'Local Information',
        label: 'Local Information'
    },
    {
        id: 'News',
        label: 'News'
    },
    {
        id: 'Other',
        label: 'Other'
    },
    {
        id: 'Personal Data',
        label: 'Personal Data'
    },
    {
        id: 'Personal Mobility',
        label: 'Personal Mobility'
    },
    {
        id: 'Personality',
        label: 'Personality'
    },
    {
        id: 'Shopping',
        label: 'Shopping'
    },
    {
        id: 'Smart Home',
        label: 'Smart Home'
    },
    {
        id: 'Social',
        label: 'Social'
    },
    {
        id: 'Video',
        label: 'Video'
    }
];

export const BUSINESS_DOMAIN_DROPDOWN_OPTIONS: { [key: string]: Select.Option[] } = {
    'Alexa For Business': [{
        id: 'NO_SKILLS',
        label: 'No skills'
    }],
    'Audio': [{
        id: 'Audible',
        label: 'Audible'
    },
    {
        id: 'Kindle on Alexa',
        label: 'Kindle on Alexa'
    },
    {
        id: 'Music',
        label: 'Music'
    },
    {
        id: 'Music & Audio Skills',
        label: 'Music & Audio Skills'
    }],
    'Auto': [{
        id: 'Car Control',
        label: 'Car Control'
    },
    {
        id: 'Connected Car Skills',
        label: 'Connected Car Skills'
    }],
    'Communications': [{
        id: 'Audible',
        label: 'Audible'
    }],
    'Games': [{
        id: 'Games, Trivia, & Accessories Skills',
        label: 'Games, Trivia, & Accessories Skills'
    }],
    'Health': [{
        id: 'Health',
        label: 'Health'
    },
    {
        id: 'Health & Fitness Skills',
        label: 'Health & Fitness Skills'
    }],
    'Home Productivity': [{
        id: 'Alexa Email',
        label: 'Alexa Email'
    },
    {
        id: 'Business & Finance Skills',
        label: 'Business & Finance Skills'
    },
    {
        id: 'Calendar',
        label: 'Calendar'
    },
    {
        id: 'Productivity Skills',
        label: 'Productivity Skills'
    },
    {
        id: 'Reminders',
        label: 'Reminders'
    },
    {
        id: 'Tell Me When',
        label: 'Tell Me When'
    },
    {
        id: 'Timers & Alarms',
        label: 'Timers & Alarms'
    },
    {
        id: 'Utilities Skills',
        label: 'Utilities Skills'
    },
    {
        id: 'Weather',
        label: 'Weather'
    },
    {
        id: 'Weather Skills',
        label: 'Weather Skills'
    },
    {
        id: 'What Time What Day',
        label: 'What Time What Day'
    }],
    'Kids': [{
        id: 'Kids Skills',
        label: 'Kids Skills'
    }],
    'Kitchen': [{
        id: 'Food & Drink Skills',
        label: 'Food & Drink Skills'
    },
    {
        id: 'Kitchen',
        label: 'Kitchen'
    },
    {
        id: 'Lifestyle Skills',
        label: 'Lifestyle Skills'
    }],
    'Knowledge': [{
        id: 'Business, Politics, & Religion',
        label: 'Business, Politics, & Religion'
    },
    {
        id: 'Help',
        label: 'Help'
    },
    {
        id: 'Knowledge Catapult',
        label: 'Knowledge Catapult'
    },
    {
        id: 'Knowledge Literature',
        label: 'Knowledge Literature'
    },
    {
        id: 'Knowledge Music',
        label: 'Knowledge Music'
    },
    {
        id: 'Knowledge Video',
        label: 'Knowledge Video'
    },
    {
        id: 'Miscellaneous',
        label: 'Miscellaneous'
    },
    {
        id: 'Places',
        label: 'Places'
    },
    {
        id: 'Reference',
        label: 'Reference'
    },
    {
        id: 'Sports',
        label: 'Sports'
    },
    {
        id: 'Education & Reference Skills',
        label: 'Education & Reference Skills'
    },
    {
        id: 'Sports Skills',
        label: 'Sports Skills'
    }],
    'Language Services': [{
        id: 'No Skills',
        label: 'No Skills'
    }],
    'Local Information': [{
        id: 'Local Information',
        label: 'Local Information'
    },
    {
        id: 'Local Skills',
        label: 'Local Skills'
    }],
    'News': [{
        id: 'Flash Briefing',
        label: 'Flash Briefing'
    },
    {
        id: 'News Skills',
        label: 'News Skills'
    },
    {
        id: 'What\'s Up',
        label: 'What\'s Up'
    }],
    'Other': [{
        id: 'Bluetooth',
        label: 'Bluetooth'
    },
    {
        id: 'Global',
        label: 'Global'
    },
    {
        id: 'IFTTT',
        label: 'IFTTT'
    },
    {
        id: 'Notification Service',
        label: 'Notification Service'
    },
    {
        id: 'Other',
        label: 'Other'
    },
    {
        id: 'Other Skills',
        label: 'Other Skills'
    },
    {
        id: 'Photos',
        label: 'Photos'
    },
    {
        id: 'Routines',
        label: 'Routines'
    },
    {
        id: 'Skills Management',
        label: 'Skills Management'
    }],
    'Personal Data': [{
        id: 'No Skills',
        label: 'No Skills'
    }],
    'Personal Mobility': [{
        id: 'Navigation',
        label: 'Navigation'
    },
    {
        id: 'Travel & Transportation Skills',
        label: 'Travel & Transportation Skills'
    }],
    'Personality': [{
        id: 'AboutAlexa',
        label: 'AboutAlexa'
    },
    {
        id: 'Jokes',
        label: 'Jokes'
    },
    {
        id: 'Novelty & Humor Skills',
        label: 'Novelty & Humor Skills'
    },
    {
        id: 'Originals',
        label: 'Originals'
    },
    {
        id: 'Phatic',
        label: 'Phatic'
    }],
    'Shopping': [{
        id: 'Shopping',
        label: 'Shopping'
    },
    {
        id: 'Shopping Skills',
        label: 'Shopping Skills'
    },
    {
        id: 'To-Do',
        label: 'To-Do'
    }],
    'Smart Home': [{
        id: 'Smart Home',
        label: 'Smart Home'
    },
    {
        id: 'Smart Home Skills',
        label: 'Smart Home Skills'
    }],
    'Social': [{
        id: 'Social Skills',
        label: 'Social Skills'
    }],
    'Video': [{
        id: 'Movies & TV Skills',
        label: 'Movies & TV Skills'
    },
    {
        id: 'Video',
        label: 'Video'
    }],
};
