import * as React from 'react';
import _ from 'lodash';
import { Button, FormField, Input } from '@amzn/awsui-components-react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../reducers';
import { useState } from 'react';
import { IRCPOverrideAccount } from '../models/v2/constraint/IRCPOverrideAccount';
import { pushRotatingContentPanelOverrideAccounts } from '../actions/experienceEditViewActions';

interface TestRotatingContentPanelState {
    customerInputId: string[];
}

const TEST_ACCOUNTS_COUNT_LIMIT = 10;

const TestRotatingContentPanel: React.FC = () => {
    const { isUpdating, isEditing, updateCandidate } = useSelector((state: AppState) => state.experienceEditViewState);
    const { experience } = useSelector((state: AppState) => state.odysseyExperienceDetailViewState);
    const dispatch = useDispatch();

    const shouldDisableInput = !isEditing || isUpdating;

    const overrideAccounts: IRCPOverrideAccount[] = (!shouldDisableInput) ?
        updateCandidate?.getRotatingContentPanelTestingConstraint()?.rcpOverrideAccounts ?? [''] :
        _.get(experience, 'constraints.RCPTestingConstraint.rcpOverrideAccounts', ['']) ?? [''];

    if (overrideAccounts.length === 0) overrideAccounts.push({ overrideAccountId: '', overrideActive: true });

    const initialState: TestRotatingContentPanelState = {
        customerInputId: overrideAccounts.map((account) => account.overrideAccountId)
    };

    const [currentState, setState] = useState(initialState);

    const updateOverrideAccounts = (newState: TestRotatingContentPanelState) => {
        const newOverrideAccounts: IRCPOverrideAccount[] = newState.customerInputId
            .filter((cid) => cid !== '')
            .map((cid): IRCPOverrideAccount => ({overrideAccountId: cid, overrideActive: true}));
        dispatch(pushRotatingContentPanelOverrideAccounts({
            rcpOverrideAccounts: newOverrideAccounts
        }));
    };

    const onAddingCustomerId = () => {
        setState(() => {
            const accountIds = _.cloneDeep(currentState.customerInputId);
            accountIds.push('');
            const newState = { customerInputId: accountIds };
            updateOverrideAccounts(newState);
            return newState;
        });
    };

    const onRemovingCustomerId = (event: CustomEvent<Button.ClickDetail>) => {
        const targetId = ((event.target as HTMLElement).getAttribute('id'));
        const targetIndex = Number(targetId?.split('.')[3]);

        setState(() => {
            const accountIds = _.cloneDeep(currentState.customerInputId);
            accountIds.splice(targetIndex, 1);
            const newState = { customerInputId: accountIds };
            updateOverrideAccounts(newState);
            return newState;
        });
    };

    const onInputChange = (event: CustomEvent<Input.ChangeDetail>) => {
        const targetId = ((event.target as HTMLElement).getAttribute('id'));
        const targetIndex = Number(targetId?.split('.')[3]);

        setState(() => {
            const accountIds = _.cloneDeep(currentState.customerInputId);
            accountIds[targetIndex] = event.detail.value;
            const newState = { customerInputId: accountIds };
            updateOverrideAccounts(newState);
            return newState;
        });
    };

    return <div>
        <div className='awsui-util-pv-s'>
            <div>
                <div className='awsui-util-ml-s'><strong>1. Complete required fields</strong></div>
                <div className='awsui-util-ml-m'>Draft status will change to Testable</div>
            </div>
        </div>
        <hr />
        <div className='awsui-util-pv-s'>
            <div>
                <div className='awsui-util-ml-s'><strong>2. Enter Customer ID</strong></div>
                <div className='awsui-util-ml-m'>
                    {currentState.customerInputId.map((account, index) => {
                        return <FormField key={`overrideAccount-${index}`} id={`overrideAccount-${index}`}>
                            <Input className='col-4' disabled={shouldDisableInput}
                                onChange={onInputChange} value={account} id={`experience.testing.customer-id-input.${index}`}/>
                            {index === 0 ? <Button disabled={shouldDisableInput || currentState.customerInputId.length === TEST_ACCOUNTS_COUNT_LIMIT}
                                id={'experience.testing.add-customer-btn'} icon='add-plus' className='col-8' onClick={onAddingCustomerId}>
                                Add another customer
                            </Button>
                            :
                            <Button disabled={shouldDisableInput}
                                id={`experience.testing.remove-customer-btn.${index}`} icon='close' className='col-8' onClick={onRemovingCustomerId}>
                                Remove customer
                            </Button>
                            }
                        </FormField>;
                    })}
                </div>
            </div>
        </div>
        <div className='awsui-util-pv-s'>
            <div>
                <div className='awsui-util-ml-s'><strong>3. Configure Device</strong></div>
                <div className='awsui-util-ml-m'>
                    On test device, go to <br />
                    <ul>
                        <li>Settings > Home & Clock > Home Content</li>
                        <li>Turn off all content providers</li>
                        <li>Turn on "Alexa Suggests" (toggle if already on)</li>
                    </ul>
                </div>
            </div>
        </div>
        <hr />
        <div className='awsui-util-pv-s'>
            <div>
                <div className='awsui-util-ml-s'><strong>4. Your experience should appear on your device</strong></div>
            </div>
        </div>

    </div>;
};

export default TestRotatingContentPanel;
