import { ILoadSimilarExperiencesAction } from '../actions/experienceMetricsViewActions';
import { ISimilarExperiencesViewableData, createSimilarExperiencesViewableData } from '../models/SimilarExperiencesData';
import {
    LOADING_SIMILAR_EXPERIENCES,
    LOADED_SIMILAR_EXPERIENCES,
    ERROR_LOADING_SIMILAR_EXPERIENCES
} from '../constants/experienceMetricsViewActionTypes';

export interface IExperienceSimilarExperimentsViewState {
    isLoading: boolean;
    similarExperiencesViewableData?: ISimilarExperiencesViewableData[];
    error?: Error;
}

export const experienceSimilarViewReducer = (
    state:  IExperienceSimilarExperimentsViewState = { isLoading: false },
    action: ILoadSimilarExperiencesAction
): IExperienceSimilarExperimentsViewState => {
    switch (action.type) {
        case LOADING_SIMILAR_EXPERIENCES:
            return {
                ...state,
                isLoading: true
            };
        case LOADED_SIMILAR_EXPERIENCES:
            const { similarExperiencesData } = action;
            const similarExperiencesViewableData = createSimilarExperiencesViewableData(similarExperiencesData);
            return {
                ...state,
                isLoading: false,
                similarExperiencesViewableData
            };
        case ERROR_LOADING_SIMILAR_EXPERIENCES:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};
