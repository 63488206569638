import * as React from 'react';
import { IFlattenedExperience } from '../../../models/FlattenedExperience';
import DayTimeWindowSectionView from '../../../containers/sections/DayTimeWindowSectionView';
import NluOpportunityTriggeringSectionView from '../../../containers/sections/NluOpportunityTriggeringSectionView';

export const PlacementsTabView = (props: { experience: IFlattenedExperience }) => {
    const { experience } = props;
    return <div>
        {
            experience.type === 'CIF' && <div>
                <NluOpportunityTriggeringSectionView triggerGrouping={'InclusionTriggers'}/>
                <NluOpportunityTriggeringSectionView triggerGrouping={'ExclusionTriggers'}/>
            </div>
        }
        <DayTimeWindowSectionView />
    </div>;
};
