import { Action, Dispatch } from 'redux';

import { FINISHED_SENDING_NOTIFICATION, SENDING_NOTIFICATION, ERROR_SENDING_NOTIFICATION } from '../constants/pushNotificationActionTypes';
import { sendPushNotification } from '../api/pushNotificationGateway';


export interface IPushNotificationAction extends Action {
    result?: string;
    error?: Error;
}

export const sendingNotification = (): IPushNotificationAction => {
    return {
        type: SENDING_NOTIFICATION
    };
};

export const finishedSendingNotification = (result: string): IPushNotificationAction => {
    return {
        type: FINISHED_SENDING_NOTIFICATION,
        result
    };
};

export const errorSendingNotification = (error: Error): IPushNotificationAction => {
    return {
        type: ERROR_SENDING_NOTIFICATION,
        error
    };
};

export const sendPushNotificationAction = (
    cid: string, experienceId: string, locale: string, deviceTypes: string[]
) => async (dispatch: Dispatch) => {
    dispatch(sendingNotification());

    try {
        await new Promise(resolve => {
            setTimeout(resolve, 5000);
        });
        const data: string = await sendPushNotification(cid, experienceId, locale, deviceTypes);
        dispatch(finishedSendingNotification(data));
    } catch (error) {
        dispatch(errorSendingNotification(error));
    }
};
