// load current provider configurations
export const LOADING_PROVIDER_CONFIGS = 'LOADING_PROVIDER_CONFIGS';
export const LOADED_PROVIDER_CONFIGS = 'LOADED_PROVIDER_CONFIGS';
export const ERROR_LOADING_PROVIDER_CONFIGS = 'ERROR_LOADING_PROVIDER_CONFIGS';

// load version history of provider
export const LOADING_PROVIDER_VERSION_HISTORY = 'LOADING_PROVIDER_VERSION_HISTORY';
export const LOADED_PROVIDER_VERSION_HISTORY = 'LOADED_PROVIDER_VERSION_HISTORY';
export const ERROR_LOADING_PROVIDER_VERSION_HISTORY = 'ERROR_LOADING_PROVIDER_VERSION_HISTORY';

// upsert existing provider config
export const UPSERTING_PROVIDER_CONFIG = 'UPSERTING_PROVIDER_CONFIG';
export const UPSERTED_PROVIDER_CONFIG = 'UPSERTED_PROVIDER_CONFIG';
export const ERROR_UPSERTING_PROVIDER_CONFIG = 'ERROR_UPSERTING_PROVIDER_CONFIG';


// delete existing provider config
export const DELETING_PROVIDER_CONFIG = 'DELETING_PROVIDER_CONFIG';
export const DELETED_PROVIDER_CONFIG = 'DELETED_PROVIDER_CONFIG';
export const ERROR_DELETING_PROVIDER_CONFIG = 'ERROR_DELETING_PROVIDER_CONFIG';

// load provider by providerId
export const LOADING_PROVIDER_CONFIG_BY_ID = 'LOADING_PROVIDER_CONFIG_BY_ID';
export const LOADED_PROVIDER_CONFIG_BY_ID= 'LOADED_PROVIDER_CONFIG_BY_ID';
export const ERROR_LOADING_PROVIDER_CONFIG_BY_ID = 'ERROR_LOADING_PROVIDER_CONFIG_BY_ID';

// load config by versionId
export const LOADING_PROVIDER_CONFIG_BY_ID_AND_VERSION = 'LOADING_PROVIDER_CONFIG_BY_ID_AND_VERSION';
export const LOADED_PROVIDER_CONFIG_BY_ID_AND_VERSION = 'LOADED_PROVIDER_CONFIG_BY_ID_AND_VERSION';
export const ERROR_LOADING_PROVIDER_CONFIG_BY_ID_AND_VERSION = 'ERROR_LOADING_PROVIDER_CONFIG_BY_ID_AND_VERSION';

// editing mode
export const ENABLE_CONFIG_EDIT = 'ENABLE_CONFIG_EDIT';
export const DISABLE_CONFIG_EDIT = 'DISABLE_CONFIG_EDIT'; // to make sure edit is disabled by default when opening an existing config page
export const CANCEL_CONFIG_EDIT = 'CANCEL_CONFIG_EDIT'; // for the cancel button
export const UPDATE_CURRENT_PROVIDER_CONFIG = 'UPDATE_CURRENT_PROVIDER_CONFIG';

// onboarding new provider
export const ENABLE_ONBOARDING_PROVIDER = 'ENABLE_ONBOARDING_PROVIDER';
export const DISABLE_ONBOARDING_PROVIDER = 'DISABLE_ONBOARDING_PROVIDER';

export const UPDATE_FORM_VALIDATION_SECTION = 'UPDATE_FORM_VALIDATION_SECTION';
export const SET_CONFIG_TO_VERSION = 'SET_CONFIG_TO_VERSION';

export const SET_COMPARE_VERSIONS = 'SET_COMPARE_VERSIONS';