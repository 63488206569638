import * as React from 'react';
import ProviderVersionsTable from '../../list/v2/ConfigVersionsTable';
import { useDispatch, useSelector } from 'react-redux';
import { cancelConfigEditingAction } from '../../../actions/spiProviderOnboardingActions';
import { useParams } from 'react-router-dom';
import { AppState } from '../../../reducers';
import { Flash } from '@amzn/awsui-components-react';
export const SpiProviderOnboardingVersionHistoryTabView = () => {
    const dispatch = useDispatch();
    const { providerId } = useParams<{ providerId: string }>();
    const { isLoading, isLoadingVersionHistory } = useSelector((state: AppState) => state.spiProviderOnboardingState);

    React.useEffect(() => {
        dispatch(cancelConfigEditingAction(providerId));
    }, [dispatch, providerId]);

    return (
        <div>
            {isLoadingVersionHistory || isLoading
                ?
                <div><Flash dismissible={false} >Loading...</Flash></div>
                :
                <div>
                    <ProviderVersionsTable/>
                </div>
            }
        </div>
    );
};


