import * as React from 'react';
import { connect } from 'react-redux';
import { AnnouncementSectionView } from './sections/OdysseyAnnouncementView';
import { errorUpdatingAnnouncement, getOdysseyAnnouncementAction, updateOdysseyAnnouncementAction } from '../actions/odysseyAnnouncementActions';
import { IOdysseyAnnouncement } from '../models/odysseyAnnouncement/Announcement';
import { AppState } from '../reducers';
import { Button, Flash, Icon, Input, Modal, Textarea } from '@amzn/awsui-components-react';
import { MarkdownRenderer } from '../components/MarkdownRenderer';
import { ExternalLink } from '../components/common/LinkComponents';
import { FormSectionView } from '../components/common/FormSectionView';
import { simpleDateFormat } from '../util/stringAndMappingHelper';


interface OdysseyAnnouncementUpdateWidgetProps {
    dispatch: any;
    isLoading: boolean;
    isAnnouncementUpdated: boolean;
    errorUpdating?: Error;
    errorLoading?: Error;
    announcement?: IOdysseyAnnouncement;
}

interface OdysseyAnnouncementUpdateWidgetState {
    title?: string;
    updateAnnouncementText?: string;
    isShowingConfirmationModal: boolean;
}

class OdysseyAnnouncementUpdateWidget extends React.Component<OdysseyAnnouncementUpdateWidgetProps, OdysseyAnnouncementUpdateWidgetState> {
    constructor(props: OdysseyAnnouncementUpdateWidgetProps) {
        super(props);

        this.state = {
            title: undefined,
            updateAnnouncementText: undefined,
            isShowingConfirmationModal: false
        };
    }

    dismissConfirmationModal() {
        this.setState({
            isShowingConfirmationModal: false
        });
    }

    showConfirmationModal() {
        this.setState({
            isShowingConfirmationModal: true
        });
    }

    updateAnnouncementTitle(value: string) {
        this.setState({
            title: value
        });
    }

    updateAnnouncementDetail(value: string) {
        this.setState({
            updateAnnouncementText: value
        });
    }

    resetAnnouncement() {
        this.setState({
            title: undefined,
            updateAnnouncementText: undefined
        });
    }

    copyAnnouncementToEditPane() {
        const { announcement } = this.props;
        if (announcement) {
            this.setState({
                title: announcement.title,
                updateAnnouncementText: announcement.detail
            });
        }
    }

    newAnnouncementToEditPane() {
        this.setState({
            title: '',
            updateAnnouncementText: ''
        });
    }

    saveAnnouncement() {
        const { dispatch } = this.props;
        const { title, updateAnnouncementText } = this.state;

        if (title && updateAnnouncementText) {
            dispatch(updateOdysseyAnnouncementAction({
                title,
                detail: updateAnnouncementText,
                timestamp: Date.now(),
                type: 'Announcements'
            }));

            this.resetAnnouncement();
        } else {
            dispatch(errorUpdatingAnnouncement(new Error('Title and Detail fields must not be empty')));
        }

        this.dismissConfirmationModal();
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(getOdysseyAnnouncementAction());
    }

    componentDidUpdate() {
        const { isAnnouncementUpdated, dispatch, isLoading } = this.props;
        if (isAnnouncementUpdated && !isLoading) {
            dispatch(getOdysseyAnnouncementAction());
        }
    }

    render() {
        const { isLoading, errorUpdating, announcement } = this.props;

        const { title, updateAnnouncementText } = this.state;
        return <div className='awsui-util-no-gutter'>
            {errorUpdating && <Flash type='error' dismissible={true} >{errorUpdating.message}</Flash>}
            <div className='awsui-util-m-xl'>
                {announcement
                    ? <AnnouncementSectionView />
                    : <p className='awsui-util-status-inactive'>No announcement at the moment. Create one by clicking the button below...</p>}
                <div className='awsui-util-m-l'>
                    <Button onClick={this.newAnnouncementToEditPane.bind(this)} icon='add-plus'>Create new announcement</Button>
                    <Button onClick={this.copyAnnouncementToEditPane.bind(this)}  icon='copy' disabled={!announcement || isLoading} >Edit existing announcement</Button>
                </div>
                {(title !== undefined && updateAnnouncementText !== undefined) && <div className='awsui-grid'>
                    <div className='awsui-row'>
                        <div className='col-6 awsui-util-spacing-v-s'>
                            <Input placeholder='Announcement title' value={title} onInput={(e: CustomEvent<Input.ChangeDetail>) => {
                                this.updateAnnouncementTitle(e.detail.value);
                            }}/>
                            <Textarea placeholder='Announcement detail, markdown enabled' value={updateAnnouncementText} onInput={(event: CustomEvent<Textarea.ChangeDetail>) => {
                                this.updateAnnouncementDetail(event.detail.value);
                            }} />
                        </div>
                        <div className='col-6'>
                            <FormSectionView title='Feature announcements and updates'>
                                {!!updateAnnouncementText
                                    ? <div>
                                        <h2>{title}</h2>
                                        <p className='awsui-util-status-inactive'>{simpleDateFormat(Date.now())}</p>
                                        <MarkdownRenderer markdownString={updateAnnouncementText} />
                                    </div>
                                    : <p className='awsui-util-status-inactive'>Your preview will be rendered here...</p>}
                            </FormSectionView>
                        </div>
                    </div>
                    <div className='awsui-row'>
                        <div className='col-12'>
                            <ExternalLink href='https://w.amazon.com/bin/view/IssueManagement/SIM/SIMFlavoredMarkdown/'>
                                Markdown guide (from SIM)
                            </ExternalLink>
                        </div>
                    </div>
                    <div className='awsui-row'>
                        <div className='col-12'>
                            <Button onClick={this.showConfirmationModal.bind(this)} variant='primary' icon='upload' disabled={isLoading} >Save</Button>
                            <Button onClick={this.resetAnnouncement.bind(this)} variant='normal' icon='close' disabled={isLoading} >Cancel</Button>
                        </div>
                    </div>
                </div>}
            </div>
            <Modal
                id='modal.update-announcement-confirmation'
                visible={this.state.isShowingConfirmationModal}
                header='Publish announcement'
                footer={<span className='awsui-util-f-r'>
                    <Button onClick={this.saveAnnouncement.bind(this)} variant='primary' icon='upload' disabled={isLoading} >Save</Button>
                    <Button onClick={this.dismissConfirmationModal.bind(this)} variant='normal' icon='close' disabled={isLoading} >Cancel</Button>
                </span>}
                onDismiss={this.dismissConfirmationModal.bind(this)}>

                <p>
                    <Icon size='large' name='status-warning' variant='warning' /> By clicking save, this announcement will <em>immediately</em> go LIVE. Are you sure?
                </p>
            </Modal>
        </div>;
    }
}

const mapStateToProps = ({ odysseyAnnouncementState }: AppState) => {
    return {
        isLoading: odysseyAnnouncementState.isLoading,
        isAnnouncementUpdated: odysseyAnnouncementState.updatedAnnouncement,
        errorUpdating: odysseyAnnouncementState.errorUpdating || undefined,
        errorLoading: odysseyAnnouncementState.errorLoading || undefined,
        announcement: odysseyAnnouncementState.announcement || undefined,
    };
};

export default connect(mapStateToProps)(OdysseyAnnouncementUpdateWidget);
