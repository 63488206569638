import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';

import { BreadcrumbGroup, Flash, Select } from '@amzn/awsui-components-react';
import { ExperienceGraph } from '../components/visual/ExperiencesGraph';
import { IFlattenedExperience } from '../models/FlattenedExperience';

import { AppState } from '../reducers';
import { getLinkableUrl, PAGE } from '../constants/page';
import { ExperienceType, EXPERIENCE_STATUS, ExperienceStatus, EXPERIENCE_TYPES } from '../models/Experience';
import { getExperiencesPaginateAction, requestedExperiencesPaginate } from '../actions/experienceListViewActions';
import { experiencesPageLimit } from '../constants/componentConstants';

const hardCodedBreadcrumbs: BreadcrumbGroup.Item[] = [{
    text: 'Odyssey',
    href: getLinkableUrl(PAGE.Home),
}, {
    text: 'Dashboard',
    href: getLinkableUrl(PAGE.Dashboard),
}];

interface IDashboardProps {
    flattenedExperiences: IFlattenedExperience[];
    lastEvaluatedKey?: string;
    isLoading: boolean;
    dispatch: any;
    error?: Error;
}

interface IDashboardState {
    selectedType: ExperienceType;
    selectedStatus: ExperienceStatus;
}

export class Dashboard extends Component<IDashboardProps, IDashboardState> {

    constructor(props: IDashboardProps) {
        super(props);
        this.state = {
            selectedType: 'AppNotification',
            selectedStatus: 'LIVE'
        };
    }

    public componentDidMount() {
        const { dispatch } = this.props;
        dispatch(requestedExperiencesPaginate());
        dispatch(getExperiencesPaginateAction(experiencesPageLimit));
    }

    public componentDidUpdate() {
        const { dispatch, lastEvaluatedKey, isLoading } = this.props;

        if (!isLoading && lastEvaluatedKey) {
            dispatch(getExperiencesPaginateAction(experiencesPageLimit, lastEvaluatedKey));
        }

    }

    public handleTypeChange = (event: CustomEvent<Select.ChangeDetail>) => {
        this.setState({selectedType: event.detail.selectedId as ExperienceType});
    }

    public handleStatusChange = (event: CustomEvent<Select.ChangeDetail>) => {
        this.setState({selectedStatus: event.detail.selectedId as ExperienceStatus});
    }

    public render() {
        const { flattenedExperiences, isLoading, error } = this.props;
        const { selectedType, selectedStatus } = this.state;

        if (isLoading) {
            return <Flash dismissible={false} >Loading...</Flash>;
        }

        const experienceTypeOptions: Select.Option[] = EXPERIENCE_TYPES.map((type) => {
            return {label: type, id: type};
        });

        const experienceStatusOptions: Select.Option[] = EXPERIENCE_STATUS.map((status) => {
            return {label: status.replace('_', ' '), id: status};
        });

        return (
            <div>
                {/* TODO: Flash component should only appear when Redux passes down something to say. */}
                {error && <Flash dismissible={true} type='error' >{error.message}</Flash>}

                <div className='awsui-grid' style={{ margin: '30px' }}>
                    <div className='awsui-row' style={{ marginBottom: '20px' }}>
                        <div className='col-4'>
                            <BreadcrumbGroup label='Breadcrumbs' items={hardCodedBreadcrumbs} />
                        </div>
                        <div className='col-4'>
                            <Select
                                id='dashboard.select-type'
                                disabled={false}
                                options={experienceTypeOptions}
                                selectedId={selectedType}
                                onChange={this.handleTypeChange}/>
                        </div>
                        <div className='col-4'>
                            <Select
                                id='dashboard.select-status'
                                disabled={false}
                                options={experienceStatusOptions}
                                selectedId={selectedStatus}
                                onChange={this.handleStatusChange}/>
                        </div>
                    </div>
                    <div className='awsui-util-mb-m'>
                        <ExperienceGraph flattenedExperiences={flattenedExperiences}
                                         type={selectedType}
                                         status={selectedStatus}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ experienceListViewState }: AppState) => {
    return {
        isLoading: experienceListViewState.isLoading || false,
        flattenedExperiences: experienceListViewState.flattenedExperiences || [],
        lastEvaluatedKey: experienceListViewState.lastEvaluatedKey || undefined,
        error: experienceListViewState.error || undefined,
    };
};

export default connect(mapStateToProps)(Dashboard);
