import { Action, Dispatch } from 'redux';
import { sendMobileHomeCard } from '../api/testMobileHomeCardGateway';
import { ERROR_SENDING_MOBILE_HOME_CARD, SENT_MOBILE_HOME_CARD, RESET_SENDING_MOBILE_HOME_CARD_STATUS, SENDING_MOBILE_HOME_CARD } from '../constants/testMobileHomeCardActionTypes';

export interface ITestMobileHomeCardAction extends Action {
	result?: string;
	error?: Error;
}

export const sendingMobileHomeCard = (): ITestMobileHomeCardAction => {
	return {
		type: SENDING_MOBILE_HOME_CARD
	};
};

export const finishedSendingMobileHomeCard = (result: string): ITestMobileHomeCardAction => {
	return {
		type: SENT_MOBILE_HOME_CARD,
		result
	};
};

export const errorSendingMobileHomeCard = (error: Error): ITestMobileHomeCardAction => {
	return {
		type: ERROR_SENDING_MOBILE_HOME_CARD,
		error
	};
};

export const resetSendingMobileHomeCardStatus = (result: string): ITestMobileHomeCardAction => {
	return {
		type: RESET_SENDING_MOBILE_HOME_CARD_STATUS,
		result
	};
};

export const sendMobileHomeCardAction = (
	cid: string, cardItem: string, experimentId: string
) => async (dispatch: Dispatch) => {
	dispatch(sendingMobileHomeCard());

	try {
		await new Promise(resolve => {
			setTimeout(resolve, 5000);
		});

		const data: string = await sendMobileHomeCard(cid, cardItem, experimentId);
		dispatch(finishedSendingMobileHomeCard(data));

		await new Promise(resolve => {
			setTimeout(resolve, 5000);
		});

		dispatch(resetSendingMobileHomeCardStatus(''));

	} catch (error) {
		dispatch(errorSendingMobileHomeCard(error));
	}
};
