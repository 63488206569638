import { ILoadOdysseyExperiencesAction } from '../../actions/v2/odysseyExperienceListViewActions';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { UNSET_ODYSSEY_EXPERIENCES, REQUESTED_ODYSSEY_EXPERIENCES_PAGINATE, RECEIVED_ODYSSEY_EXPERIENCES_PAGINATE, ERROR_FETCHING_ODYSSEY_EXPERIENCES_PAGINATE, UPDATED_PROGRESS } from '../../constants/v2/odysseyExperienceListViewActionTypes';
import { transformOdysseyExperience } from '../../util/stringAndMappingHelper';
import _ from 'lodash';

export interface IExperienceListViewState {
    isLoading: boolean;
    flattenedExperiences?: IFlattenedExperience[];
    lastEvaluatedKey?: string;
    error?: Error;
    succeeded?: number;
    failed?: number;
}

export const odysseyExperienceListViewReducer = (
    state: IExperienceListViewState = { isLoading: false },
    action: ILoadOdysseyExperiencesAction
): IExperienceListViewState => {
    switch (action.type) {
        case REQUESTED_ODYSSEY_EXPERIENCES_PAGINATE:
            return {
                ...state,
                isLoading: true
            };
        case RECEIVED_ODYSSEY_EXPERIENCES_PAGINATE:
            // reducer is at top level. it should resolve error
            // and get it ready for rendering.
            if (!action.odysseyExperiences) {
                return {
                    ...state,
                    isLoading: false,
                    error: new Error('Loaded `undefined` experiences, something went wrong')
                };
            }

            const flattenedExperiences = (state.flattenedExperiences || []).concat(action.odysseyExperiences.map(odysseyExperience => transformOdysseyExperience(odysseyExperience)));

            return {
                ...state,
                isLoading: false,
                lastEvaluatedKey: action.lastEvaluatedKey,
                flattenedExperiences: _.uniqBy(flattenedExperiences, (experience) => { return experience.id; }),
                error: undefined
            };
        case ERROR_FETCHING_ODYSSEY_EXPERIENCES_PAGINATE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case UNSET_ODYSSEY_EXPERIENCES:
            return {
                ...state,
                flattenedExperiences: undefined,
                error: undefined,
                isLoading: false
            };
        case UPDATED_PROGRESS:
            return {
                ...state,
                succeeded: action.succeeded,
                failed: action.failed
            };
        default:
            return state;
    }
};
