import * as React from 'react';
import { Icon } from '@amzn/awsui-components-react';

export const ExternalLink = (props: { href: string, children: React.ReactNode }) => {
    return <a href={props.href} rel='noopener noreferrer' target='_blank'>
        {props.children}&nbsp;<Icon name='external'/>
    </a>;
};

export const InternalLink = (props: { href: string, children: React.ReactNode }) => {
    return <a href={props.href}>{props.children}</a>;
};
