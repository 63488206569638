import { IExperience} from '../models/Experience';
import { ExperienceUpdateCandidate } from '../models/ExperienceUpdateCandidate';
import { ISpeakerData } from '../models/SpeakerData';
import { isLiveExperience, isNotificationExperience, transformExperience } from '../util/stringAndMappingHelper';

/**
 * Determine if we should toggle the experience transition modal, and enforce status transition depending on the experience status, type, and fields updated
 */
export const enforceStatusTransition = (experience: IExperience, updateCandidate?: ExperienceUpdateCandidate) => {
    if (isLiveExperience(experience.status)) {
        // Do not enforce status transition for LIVE notification experience
        if (isNotificationExperience(experience.type)) {
            return false;
        }
        // For CIF experience, if major fields are changed, enforce status transition
        if (!minorFieldChangesOnly(experience, updateCandidate)) {
            return true;
        }
    }
    return false;
};

/**
 * Checking if we only change minor fields.
 * minor fields: launchTreatmentPercentage, devices, test CIDs, override filters, daytime window, audience type, feature hints,
 * LDAP groups, Feature slot name, Feature golden utterance, start/end dates, domain tags and remote tags
 * @param experience: previous experience
 * @param updateCandidate: updated experience
 * @returns true: only change minor fields. false: not only change minor fields
 */
export const minorFieldChangesOnly = (experience: IExperience, updateCandidate?: ExperienceUpdateCandidate) => {
    if (!updateCandidate) {
        return false;
    }

    const originalUpdateCandidate = new ExperienceUpdateCandidate(ExperienceUpdateCandidate.extractUpdateCandidate(transformExperience(experience)));
    // We want to compare the rest props except exposure control rate and other minor fields change.
    // So we set launchTreatmentPercentage, devices, test CIDs, override filters, daytime window, audience type, feature hints, LDAP groups, Feature slot name,
    // Feature golden utterance, domain tags and remote tags as same and compare
    const updatedLaunchTreatmentPercentage = updateCandidate.getLaunchTreatmentPercentage();
    const updatedApprovalInfo = updateCandidate.getApprovalInfo();
    const updatedDevices = updateCandidate.getDevices();
    const updatedOverrideAccounts = updateCandidate.getOverrideAccounts();
    const updatedOverrideFilters = updateCandidate.getOverrideFilters();
    const updatedDayTimeWindow = updateCandidate.getTimeRanges();
    const updatedSpeakerData = updateCandidate.getSpeakerData();
    const updatedFeatureHits = updateCandidate.getFeatureHints();
    const updatedOperatorGroup = updateCandidate.getBasicInfo().groupImpressions;
    const updateSuggestedFeature = updateCandidate.getSuggestedFeature();
    const updatedDomainTags = updateCandidate.getDomainTags();
    const updatedRemoteTags = updateCandidate.getRemoteTags();
    const updatedStartDate = updateCandidate.getBasicInfo().startDate;
    const updatedEndDate = updateCandidate.getBasicInfo().endDate;
    const updatedSubscriptionUpsellType = updateCandidate.getSubscriptionUpsellType();

    if (updatedLaunchTreatmentPercentage) {
        originalUpdateCandidate.setLaunchTreatmentPercentage(updatedLaunchTreatmentPercentage);
    }
    originalUpdateCandidate.setDevices(updatedDevices);
    originalUpdateCandidate.setOverrideAccounts(updatedOverrideAccounts);
    originalUpdateCandidate.setOverrideFilters(updatedOverrideFilters);
    originalUpdateCandidate.setTimeRanges(updatedDayTimeWindow);
    if (updatedApprovalInfo) {
        originalUpdateCandidate.setApprovalInfo(updatedApprovalInfo);
    }
    if (updatedSpeakerData && updatedSpeakerData.userRole) {
        const speakerData: ISpeakerData = {userRole: updatedSpeakerData.userRole};
        originalUpdateCandidate.setSpeakerData(speakerData);
    }
    originalUpdateCandidate.setFeatureHints(updatedFeatureHits);
    originalUpdateCandidate.getBasicInfo().groupImpressions = updatedOperatorGroup;
    originalUpdateCandidate.getBasicInfo().startDate = updatedStartDate;
    originalUpdateCandidate.getBasicInfo().endDate = updatedEndDate;
    if (updateSuggestedFeature) {
        originalUpdateCandidate.setSuggestedFeature(updateSuggestedFeature);
    }
    if (updatedSubscriptionUpsellType) {
        originalUpdateCandidate.setSubscriptionUpsellType(updatedSubscriptionUpsellType);
    }
    originalUpdateCandidate.setContextualSegments(updatedDomainTags);
    originalUpdateCandidate.setRemoteContextualSegments(updatedRemoteTags);

    return originalUpdateCandidate.isEqualTo(updateCandidate);
};

export const isTouchActionTemplate = (experience: IExperience) => {
    const visualContent = experience.visualContent;
    if (experience.type === 'CIF' && visualContent) {
        for (const locale of Object.keys(visualContent)) {
            const cards = visualContent[locale].aplDataSource.cards;
            if (cards.filter(card => card.touchTargets).length > 0 || cards.filter(card => card.syntheticUtteranceOverrideText).length > 0) {
                return true;
            }
        }
    }

    return false;
};

export const isNonCTATemplateExperience = (experience: IExperience) => {
    const visualContent = experience.visualContent;
    if (experience.type === 'CIF' && visualContent) {
        for (const locale of Object.keys(visualContent)) {
            if (visualContent[locale].nonCTAContent) {
                return true;
            }
        }
    }
    return false;
};
