import * as React from 'react';
import { Spinner, FormField, Alert } from '@amzn/awsui-components-react';

import { IBullseyeSegment } from '../models/BullseyeSegment';
import { AppState } from '../reducers/index';
import { connect } from 'react-redux';
import { Region } from '../util/context';
import { LinkedBullseye } from '../components/common/LinksRenderHelper';
import { IFlattenedExperience } from '../models/FlattenedExperience';
import { ExternalLink } from '../components/common/LinkComponents';

interface IBullseyeSectionProps {
    isLoading: boolean;
    bullseye?: IBullseyeSegment;
    error?: Error;
    region?: Region;
    segmentId?: number;
    experience?: IFlattenedExperience;
}

/* connects to redux store */
export const BullseyeSegmentLoadingView = (props: IBullseyeSectionProps) => {
    const { isLoading, bullseye, error, region, segmentId, experience } = props;

    const cifBullseyeDescription = <p>Visit <ExternalLink href={'https://tiny.amazon.com/1fiab350u/wikilabcnetconfdispDoppOdy'}>
    here</ExternalLink> for more information on configuring your Bullseye with Odyssey.  For CIF experiences, an Online Ready bullseye segment is required.</p>;

    const notificationBullseyeDescription = <p>Visit <ExternalLink href={'https://tiny.amazon.com/w17i4l3r/wikilabcnetconfdispDoppOdys'}>
    here</ExternalLink> for more information on configuring your Bullseye with Odyssey.  For Notification experiences, an Offline Ready bullseye segment is required.</p>;

    const mobileHomeCardBullseyeDescription = <p>Visit <ExternalLink href={'https://tiny.amazon.com/srur80jb/wamazbinviewBullUser'}>
    here</ExternalLink> for more information on configuring your Bullseye with Odyssey.  For Mobile Home Card experiences, an Online Ready bullseye segment is required.</p>;

    const rotatingContentPanelDescription = <p>For RCP experiences, an Online Ready bullseye segment is required.</p>;

    let bullseyeDescription;

    if (experience?.type === 'CIF') {
        bullseyeDescription = cifBullseyeDescription;
    } else if (experience?.type === 'AppNotification' || experience?.type === 'DeviceNotification') {
        bullseyeDescription = notificationBullseyeDescription;
    } else if (experience?.type === 'MobileHomeCard') {
        bullseyeDescription = mobileHomeCardBullseyeDescription;
    } else if (experience?.type === 'RotatingContentPanel') {
        bullseyeDescription = rotatingContentPanelDescription;
    }

    return <FormField
        label='Bullseye segment information'
        description='Dynamically loaded bullseye segment information'
        className='awsui-util-mb-m'
    >
        <Alert>
            {bullseyeDescription}
        </Alert>
        {isLoading && <Spinner variant='disabled' size='large' />}
        {bullseye && <div className='awsui-util-status-inactive'>
            <p>Segment ID: <LinkedBullseye bullseye={segmentId} region={region} /></p>
            <p>Name: {bullseye.name || '-'}</p>
            <p>Version: {bullseye.version || '-'}</p>
            <p>Size: {bullseye.size ? bullseye.size.toLocaleString('en-US') : '-'}</p>
            <p>Status: {bullseye.status || '-'}</p>
            {(experience?.type === 'CIF' || experience?.type === 'MobileHomeCard'
                || experience?.type === 'RotatingContentPanel') ? <p>Online Status: {bullseye.onlineStatus || '-'}</p> :
                <p>Offline Status: {bullseye.offlineStatus || '-'}</p>}
        </div>}
        {(!bullseye && !isLoading && !error) && <div className='awsui-util-status-inactive'>
            Bullseye information will be displayed here if configured
        </div>}
        {error && <p className='awsui-util-status-negative'>Invalid Bullseye</p>}
    </FormField>;
};

const mapStateToPropsForBullseye = ({ bullseyeMetadataState, experienceDetailViewState, odysseyExperienceDetailViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isLoading has default value set in reducer, so no default
        // value needed
        isLoading: bullseyeMetadataState.isLoading,
        bullseye: bullseyeMetadataState.bullseye || undefined,
        error: bullseyeMetadataState.error || undefined,
        region: bullseyeMetadataState.region || undefined,
        segmentId: bullseyeMetadataState.segmentId || undefined,
        experience: experienceDetailViewState.experience || odysseyExperienceDetailViewState.experience || undefined
    };
};

export default connect(mapStateToPropsForBullseye)(BullseyeSegmentLoadingView);
