import { Action, Dispatch } from 'redux';
import * as SuggestionsGateway from '../api/suggestionsGateway';
import { ERROR_UPDATING_SUGGESTION, UPDATED_SUGGESTION, UPDATING_SUGGESTION } from '../constants/homeCardSuggestionsEditViewActionTypes';
import { getLinkableUrl, PAGE } from '../constants/page';
import { IHomeCardSuggestion } from '../models/HomeCardSuggestion';
import { IConstraint } from '../models/homeCardSuggestions/Constraint';
import { IProvider } from '../models/homeCardSuggestions/Provider';
import { IRecipient } from '../models/homeCardSuggestions/Recipient';
import { ISuggestion } from '../models/homeCardSuggestions/Suggestion';
import { ISuggestionApproval, SuggestionApprovalStatus } from '../models/homeCardSuggestions/SuggestionApproval';
import { AppState } from '../reducers';


export interface IUpdateSuggestionAction extends Action {
    id?: string;
    error?: Error;
    suggestionApproval?: ISuggestionApproval;
    suggestion?: ISuggestion;
    recipient?: IRecipient;
    constraints?: IConstraint[];
    provider?: IProvider;
    homeCardsSuggestion?: IHomeCardSuggestion;
}

export const updatingSuggestion = (): IUpdateSuggestionAction => {
    return {
        type: UPDATING_SUGGESTION
    };
};

export const updatedSuggestion = (id: string): IUpdateSuggestionAction => {
    return {
        type: UPDATED_SUGGESTION,
        id
    };
};

export const errorUpdatingSuggestion = (error: Error): IUpdateSuggestionAction => {
    return {
        type: ERROR_UPDATING_SUGGESTION,
        error
    };
};

export const updateSuggestionStatusAction = (suggestionId: string,
    suggestionStatus: SuggestionApprovalStatus, rejectionString?: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    if (state.homeCardSuggestionsDetailViewState.suggestion) {
        try {
            dispatch(updatingSuggestion());
            const returnedId = await SuggestionsGateway.updateSuggestionStatus(suggestionId, suggestionStatus, rejectionString);
            const newSuggestion = await SuggestionsGateway.getSuggestion(returnedId);
            setTimeout(() => {
                state.homeCardSuggestionsDetailViewState.suggestion = newSuggestion;
                dispatch(updatedSuggestion(returnedId));
                window.location.assign(getLinkableUrl(PAGE.HomeCardSuggestions) + '/' + returnedId);
                return returnedId;
            }, 2000);
        } catch (error) {
            dispatch(errorUpdatingSuggestion(new Error(error)));
        }
    } else {
        dispatch(errorUpdatingSuggestion(new Error('Bad input')));
    }
};
