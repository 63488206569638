import { Select } from '@amzn/awsui-components-react';

export const ROW_COUNTRIES: { [countrySymbol: string]: string } = {
    'NL': 'Netherlands',
    'CO': 'Colombia',
    'SE': 'Sweden',
    'PL': 'Poland',
    'CL': 'Chile',
    'AR': 'Argentina',
    'PE': 'Peru',
    'CR': 'Costa Rica',
    'BE': 'Belgium',
    'CH': 'Switzerland',
    'SG': 'Singapore',
    'TR': 'Turkey',
    'DK': 'Denmark',
    'NO': 'Norway',
    'AE': 'UAE',
    'KR': 'South Korea',
    'MY': 'Malaysia',
    'ID': 'Indonesia',
    'PH': 'Philippines',
    'FI': 'Finland',
    'LU': 'Luxembourg',
    'PT': 'Portugal',
    'SA': 'Saudi Arabia',
    'EG': 'Egypt'
};

export const generateRowCountryOptions = (): Select.Option[] => {
    const options = Object.keys(ROW_COUNTRIES).map(key => {
        return {
            id: key,
            label: ROW_COUNTRIES[key]
        };
    });
    options.sort((a,b) => a.label.localeCompare(b.label));
    return options;
};
