import { ExperienceStatus } from '../models/Experience';
import { Icon } from '@amzn/awsui-components-react';
import { SuggestionApprovalStatus } from '../models/homeCardSuggestions/SuggestionApproval';

export const StatusBadgeMap: {
    [key in ExperienceStatus]: { iconName: Icon.Name; iconVariant: Icon.Variant; color: string; beautifiedName: string }
} = {
    'DRAFT': { iconName: 'status-in-progress', iconVariant: 'link', color: '#4285f4', beautifiedName: 'DRAFT' },
    'TESTABLE': { iconName: 'status-positive', iconVariant: 'link', color: '#4285f4', beautifiedName: 'TESTABLE'  },
    'DISABLED': { iconName: 'status-stopped', iconVariant: 'disabled', color: '#adadad', beautifiedName: 'DISABLED'  },
    'SCHEDULING': { iconName: 'status-pending', iconVariant: 'link', color: '#4285f4', beautifiedName: 'SCHEDULING'  },
    'SCHEDULED': { iconName: 'status-positive', iconVariant: 'success', color: '#0f9d58', beautifiedName: 'SCHEDULED'  },
    'UNDER_REVIEW': { iconName: 'status-pending', iconVariant: 'subtle', color: '#f4b400', beautifiedName: 'UNDER_REVIEW'  },
    'PARTIALLY_LIVE': { iconName: 'status-positive', iconVariant: 'success', color: '#0f9d58', beautifiedName: 'PARTIALLY_LIVE'  },
    'LIVE': { iconName: 'status-positive', iconVariant: 'success', color: '#0f9d58', beautifiedName: 'LIVE'  },
    'LIVE_TEN_PERCENT': { iconName: 'status-positive', iconVariant: 'success', color: '#0f9d58', beautifiedName: 'LIVE (10%)'  },
    'LIVE_FIFTY_PERCENT': { iconName: 'status-positive', iconVariant: 'success', color: '#0f9d58', beautifiedName: 'LIVE (50%)'  },
    'LIVE_HUNDRED_PERCENT': { iconName: 'status-positive', iconVariant: 'success', color: '#0f9d58', beautifiedName: 'LIVE (100%)'  },
    'LAUNCHED': { iconName: 'status-positive', iconVariant: 'success', color: '#0f9d58', beautifiedName: 'LAUNCHED'  },
    'PAUSED': { iconName: 'status-stopped', iconVariant: 'warning', color: '#db4437', beautifiedName: 'PAUSED'  },
    'EXPIRED': { iconName: 'status-stopped', iconVariant: 'warning', color: '#db4437', beautifiedName: 'EXPIRED'  },
    'CANCELED': { iconName: 'status-stopped', iconVariant: 'subtle', color: '#7d7d7d', beautifiedName: 'CANCELLED'  },
};

export const SuggestionStatusBadgeMap: {
    [key in SuggestionApprovalStatus]: { iconName: Icon.Name; iconVariant: Icon.Variant; color: string; beautifiedName: string }
} = {
    'PENDING': { iconName: 'status-pending', iconVariant: 'subtle', color: '#f4b400', beautifiedName: 'PENDING' },
    'APPROVED': { iconName: 'status-positive', iconVariant: 'success', color: '#0f9d58', beautifiedName: 'APPROVED'  },
    'REJECTED': { iconName: 'status-stopped', iconVariant: 'warning', color: '#db4437', beautifiedName: 'REJECTED'  },
};
