import { interruptionRateThreshold, negativeFeedbackThreshold, pValueThreshold, percentLiftThreshold } from '../constants/dialUpCriteriaConstant';
import { AlexaLabMetricName, IAlexaLabMetrics } from '../models/AlexaLabMetrics';
import { IRawCifMetricsData } from '../models/CifMetrics';
import { isListEmpty } from './stringAndMappingHelper';

export const extractActiveDaysValue = (alexaLabMetrics: IAlexaLabMetrics, intents?: string[]) => {
    const activeDaysMetrics = alexaLabMetrics.metrics.filter(m => m.name === AlexaLabMetricName.ACTIVE_DAYS && (m.dimension.value) && intents && (intents.includes(m.dimension.value)));
    if (isListEmpty(activeDaysMetrics)) {
        return undefined;
    }
    return activeDaysMetrics[0].data;
};

export const extractDialogValue = (alexaLabMetrics: IAlexaLabMetrics, intents?: string[]) => {
    const dialogMetrics = alexaLabMetrics.metrics.filter(m => m.name === AlexaLabMetricName.DIALOGS_DAY_ONE_EXCLUDED && (m.dimension.value) && intents && (intents.includes(m.dimension.value)));
    if (isListEmpty(dialogMetrics)) {
        return undefined;
    }
    return dialogMetrics[0].data;
};

export const isPercentLiftPositive = (percentLift: number) => {
    return percentLift > percentLiftThreshold;
};

export const isStatisticallySignificant = (pValue: number) => {
    return pValue <= pValueThreshold;
};

export const isInterruptionRateValid = (interruptionRate?: number) => {
    return ((interruptionRate) && (interruptionRate < interruptionRateThreshold)) || (interruptionRate === 0);
};

export const isNegativeFeedbackValid = (negativeFeedback?: number) => {
    return ((negativeFeedback) && (negativeFeedback < negativeFeedbackThreshold)) || (negativeFeedback === 0);
};

export const extractMaxRateCifMetrics = (cxDailyMetrics: IRawCifMetricsData[], interruptionRate: boolean) => {
    // extract the largest interruption rate or negative feedback
    const evaluationData = cxDailyMetrics[0]?.metrics;
    if (interruptionRate) {
        return evaluationData.reduce((p, c) =>
                                    (p.data.interruption_rate) && (c.data.interruption_rate) && p.data.interruption_rate > c.data.interruption_rate ? p : c).data.interruption_rate;
    }
    return evaluationData.reduce((p, c) => (p.data.negative_feedback) && (c.data.negative_feedback) && p.data.negative_feedback > c.data.negative_feedback ? p : c).data.negative_feedback;
};

export const extractWorstTriggerIntentMetrics = (alexaLabMetrics: IAlexaLabMetrics, triggerIntents: string[]) => {
    const activeDaysMetrics = alexaLabMetrics?.metrics.filter(m => m.name === AlexaLabMetricName.ACTIVE_DAYS && (m.dimension.value) && (triggerIntents.includes(m.dimension.value)));
    if (isListEmpty(activeDaysMetrics)) {
        return undefined;
    }
    // extract the worst trigger intent percentLift value that is statistically significant
    const worstTriggerIntentStatSig = activeDaysMetrics.reduce((p, c) => !isStatisticallySignificant(p.data.pvalue) &&
                                                p.data.percentLift && c.data.percentLift && p.data.percentLift < c.data.percentLift ? p : c).data;
    if (worstTriggerIntentStatSig) {
        return worstTriggerIntentStatSig;
    }

    // if no trigger intent is statistically significant, then extract the worst trigger intent percentLift value
    const worstTriggerIntent = activeDaysMetrics.reduce((p, c) => p.data.percentLift && c.data.percentLift && p.data.percentLift < c.data.percentLift ? p : c).data;
    return worstTriggerIntent;
};

