import React from 'react';
import { Table, TableSorting, TablePreferences, TableWrapLines,
    TableContentSelector, TablePropertyFiltering, Button, Modal, Flashbar } from '@amzn/awsui-components-react';
import { AppState } from '../reducers/index';
import { connect } from 'react-redux';
import { Stage } from '../util/context';
import { IEventTrail, EventTrailResponse, toEventTrail } from '../models/events/EventTrail';
import { defaultStringComparator, defaultNumberComparator } from '../constants/tableColumnConfiguration';
import { countTextFunction, fromUnixTimetoFormattedDateTime, bestEffortJsonFormat  } from '../util/stringAndMappingHelper';

interface IColumnConfig {
    id: string;
    header: string;
    renderingFunction: (item: IEventTrail) => React.ReactNode;
    filteringOptions: string[];
    visible: boolean;
    sortingComparator: (a: IEventTrail, b: IEventTrail) => number;
}
interface EventTrailProps {
    isLoading: boolean;
    events?: EventTrailResponse;
    error?: Error;
    stage?: Stage;
}
interface ComponentState {
    isModalOpen: boolean;
    modalText: string | undefined;
}


export class EventTrailLoadingView extends React.Component<EventTrailProps, ComponentState>{
    constructor(props: Readonly<EventTrailProps>) {
        super(props);
        this.state = {
            isModalOpen: false,
            modalText: undefined
        };
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal(text: string | undefined) {
        this.setState( (prevState) => ({
            modalText: text === undefined ? prevState.modalText : bestEffortJsonFormat(text),
            isModalOpen: !prevState.isModalOpen
        }));
    }

    timestampColumnConfig: IColumnConfig = {
        id: 'timestamp',
        header: 'TimeStamp',
        renderingFunction: item => fromUnixTimetoFormattedDateTime(item.timeEpochSeconds),
        filteringOptions: [],
        visible: true,
        sortingComparator: (a, b) => defaultNumberComparator(a.timeEpochSeconds, b.timeEpochSeconds)
    };

    typeColumnConfig: IColumnConfig = {
        id: 'type',
        header: 'Action Type',
        renderingFunction: item => item.type,
        filteringOptions: [],
        visible: true,
        sortingComparator: (a, b) => defaultStringComparator(a.type, b.type)
    };

    usernameColumnConfig: IColumnConfig = {
        id: 'username',
        header: 'Username',
        renderingFunction: item => item.username,
        filteringOptions: [],
        visible: true,
        sortingComparator: (a, b) => defaultStringComparator(a.username, b.username)
    };

    valueColumnConfig: IColumnConfig = {
        id: 'value',
        header: 'Value',
        renderingFunction: item => (<Button onClick={() => {this.toggleModal(item.value);}}>View Details</Button>),
        filteringOptions: [],
        visible: true,
        sortingComparator: (a, b) => defaultStringComparator(a.value, b.value)
    };

    detailsModal = (details: string) => {
        return <Modal visible={this.state.isModalOpen} header='Details' onDismiss={() => {this.toggleModal(undefined);}}
        footer={<span className='awsui-util-f-r'>
                    <Button onClick={() => {this.toggleModal(undefined);}} variant='normal'>Close</Button>
                </span>}>
            <div><pre>{details}</pre></div>
        </Modal>;
    }

    columnConfigs: IColumnConfig[] = [
        this.timestampColumnConfig,
        this.typeColumnConfig,
        this.usernameColumnConfig,
        this.valueColumnConfig
    ];

    COLUMN_DEFINITIONS: Table.ColumnDefinition<IEventTrail>[] = this.columnConfigs.map(
        (config: IColumnConfig) => {
            return {
                id: config.id,
                header: config.header,
                cell: config.renderingFunction
            };
        });

    SORTABLE_COLUMNS: TableSorting.SortableColumn<IEventTrail>[] = this.columnConfigs.map(
        (config: IColumnConfig) => {
            return {
                id: config.id,
                field: config.id,
                comparator: config.sortingComparator
            };
        });

    SELECTABLE_COLUMNS: TableContentSelector.ContentDescription[] = this.columnConfigs.map(
        (config: IColumnConfig) => {
            return {
                id: config.id,
                label: config.header,
                visible: config.visible
            };
        });

    FILTERING_OPTIONS: TablePropertyFiltering.Option[] = this.columnConfigs.map((config: IColumnConfig) => {
        return {
            groupValuesLabel: config.header + ' Values',
            propertyKey: config.id,
            propertyLabel: config.header,
            values: config.filteringOptions
        };
    });

    NoMatchDisplay = () => {
        return <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-xs awsui-util-mb-xs'>
                <b>
                    No matches
                </b>
            </div>
            <p className='awsui-util-mb-s'>
                We can’t find a match.
            </p>
        </div>;
    }

    EmptyDisplay = () => {
        return <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-xs awsui-util-mb-xs'>
                <b>
                    No events
                </b>
            </div>
            <p className='awsui-util-mb-s'>
                No events to display
            </p>
        </div>;
    }

    render() {
        const { isLoading, events, error } = this.props;
        const { modalText } = this.state;
        if (error) {
            return <Flashbar items={[{
                header: 'Loading failed',
                content: 'Failed to load Event Trail events',
                type: 'error'
            }]} />;
        }
        return <div>
                <div><Flashbar items={[{
                    content: 'This feature is currently in beta testing phase, only updates made after July 31, 2020 are tracked',
                    dismissible: true,
                    type: 'info'
                }]} /> </div>
                <Table
                    id='table.events'
                    header={
                        <div className='awsui-util-action-stripe'>
                            <div className='awsui-util-action-stripe-title'>
                                <h2 className='awsui-util-d-ib'>
                                    Event Trail&nbsp;
                                    <span className='awsui-util-header-counter'>
                                        ({(events?.results || []).length})
                                    </span>
                                </h2>
                            </div>
                        </div>
                    }
                    columnDefinitions={this.COLUMN_DEFINITIONS}
                    items={ toEventTrail(events!) || []}
                    loadingText='Loading Events'
                    loading={isLoading}
                    empty={<this.EmptyDisplay />}
                    noMatch={<this.NoMatchDisplay />}
                    resizableColumns={true}
                    stickyHeader={true}
                    wrapLines={false}
                >
                    <TablePropertyFiltering
                        filteringOptions={this.FILTERING_OPTIONS}
                        groupValuesText='Values'
                        groupPropertiesText='Properties'
                        operationAndText='and'
                        operationNotAndText='and not'
                        operationOrText='or'
                        operationNotOrText='or not'
                        clearFiltersText='Clear filter'
                        placeholder='Filter experiences by properties'
                        allowFreeTextFiltering={true}
                        filteringCountTextFunction={countTextFunction}
                    />

                    <TablePreferences
                        title='Preferences'
                        confirmLabel='Confirm'
                        cancelLabel='Cancel'
                    >
                        <TableWrapLines
                            label='Wrap lines'
                            description='Enable to wrap table cell content, disable to truncate text.'
                        />
                        <TableContentSelector
                            title='Select visible columns'
                            options={[
                                {
                                    label: 'Properties',
                                    options: this.SELECTABLE_COLUMNS
                                }
                            ]}
                        />
                    </TablePreferences>
                    <TableSorting
                        sortableColumns={this.SORTABLE_COLUMNS}
                    />
                </Table>
                {this.detailsModal(modalText!)}
                </div>;
    }
}

const mapStateToPropsForEventTrail = ({ eventTrailState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isLoading has default value set in reducer, so no default
        // value needed
        isLoading: eventTrailState.isLoading,
        events: eventTrailState.events || undefined,
        error: eventTrailState.error || undefined
    };
};

export default connect(mapStateToPropsForEventTrail)(EventTrailLoadingView);
