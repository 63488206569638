import { Action, Dispatch } from 'redux';

import * as SuggestionsGateway from '../api/suggestionsGateway';
import { ERROR_LOADING_SUGGESTION_DETAIL, LOADED_SUGGESTION_DETAIL, LOADING_SUGGESTION_DETAIL } from '../constants/homeCardSuggestionsDetailViewActionTypes';
import { IHomeCardSuggestion } from '../models/HomeCardSuggestion';

export interface ILoadSuggestionAction extends Action {
    suggestion?: IHomeCardSuggestion;
    error?: Error;
}

export const loadingSuggestionDetail = (): ILoadSuggestionAction => {
    return {
        type: LOADING_SUGGESTION_DETAIL
    };
};

export const loadedSuggestionDetail = (suggestion: IHomeCardSuggestion): ILoadSuggestionAction => {
    return {
        type: LOADED_SUGGESTION_DETAIL,
        suggestion
    };
};

export const errorLoadingSuggestionDetail = (error: Error): ILoadSuggestionAction => {
    return {
        type: ERROR_LOADING_SUGGESTION_DETAIL,
        error
    };
};


export const getSuggestionAction = (id: string) => async (dispatch: Dispatch) => {
    dispatch(loadingSuggestionDetail());

    try {
        const data: IHomeCardSuggestion = await SuggestionsGateway.getSuggestion(id);
        dispatch(loadedSuggestionDetail(data));
    } catch (error) {
        dispatch(errorLoadingSuggestionDetail(error));
    }
};
