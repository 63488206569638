import { ILoadRcpCXWeeklyMetricsAction } from '../../actions/experienceMetricsViewActions';
import {
    LOADING_RCP_CX_WEEKLY_METRICS,
    LOADED_RCP_CX_WEEKLY_METRICS,
    ERROR_LOADING_RCP_CX_WEEKLY_METRICS
} from '../../constants/experienceMetricsViewActionTypes';
import { IRawRcpMetricsData } from '../../models/v2/metric/RCPMetrics';

export interface IOdysseyExperienceRcpCXWeeklyMetricsViewState {
    isLoading: boolean;
    cxWeeklyMetrics?: IRawRcpMetricsData[];
    error?: Error;
}

export const odysseyExperienceRcpCXWeeklyMetricsViewReducer = (
    state: IOdysseyExperienceRcpCXWeeklyMetricsViewState = { isLoading: false },
    action: ILoadRcpCXWeeklyMetricsAction
): IOdysseyExperienceRcpCXWeeklyMetricsViewState => {
    switch (action.type) {
        case LOADING_RCP_CX_WEEKLY_METRICS:
            return {
                ...state,
                isLoading: true
            };
        case LOADED_RCP_CX_WEEKLY_METRICS:
            const { cxWeeklyMetrics } = action;
            return {
                ...state,
                isLoading: false,
                cxWeeklyMetrics
            };
        case ERROR_LOADING_RCP_CX_WEEKLY_METRICS:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};
