import { ExperienceType } from '../models/Experience';

export const PAGE = {
    Home: '/',
    Experiences: '/experiences',
    NewExperience: '/experiences/new',
    CloneExperience: '/experiences/new/:id',
    ExperienceDetail: '/experiences/:id',
    Dashboard: '/admin/scheduleDashboard',
    Investigator: '/admin/investigator',
    AdminDashboard: '/admin',
    OdysseyAnnouncementUpdateWidget: '/admin/announcement',
    BypassPauseListUpdateWidget: '/admin/bypassPauseList',
    PromotedExperimentsUpdateWidget: '/admin/promotedExperiments',
    MonitoredExperimentsUpdateWidget: '/admin/monitoredExperiments',
    GenerateVcifUsingAcc: '/admin/generateVcifUsingAcc',
    HomeCardSuggestions: '/suggestions',
    HomeCardSuggestionDetails: '/suggestions/:id',

    MhcExperiences: '/mhcExperiences',
    MhcExperienceDetail: '/mhcExperiences/:id', // detail page

    RcpExperiences: '/RcpExperiences',
    RcpExperienceDetail: '/RcpExperiences/:id',

    SpiProviderOnboarding: '/admin/spiProviderOnboarding',
    SpiProviderOnboardingNewProvider: '/admin/spiProviderOnboarding/createNewProvider',
    SpiProviderOnboardingConfig: '/admin/spiProviderOnboarding/:providerId',
    SpiProviderOnboardingConfigVersion: '/admin/spiProviderOnboarding/:providerId/:versionId'
};

export const getLinkableUrl = (url: string) => {
    return `/#${url}`;
    // return `${url}`;
};

export const getLinkableUrlByExperienceType = (experienceType: ExperienceType, experienceId?: string) => {
    switch (experienceType) {
        case 'MobileHomeCard':
            return `${getLinkableUrl(PAGE.MhcExperiences)}/${experienceId || ''}`;
        case 'RotatingContentPanel':
            return `${getLinkableUrl(PAGE.RcpExperiences)}/${experienceId || ''}`;
        default:
            return `${getLinkableUrl(PAGE.Experiences)}/${experienceId || ''}`;
    }
};

export const getLinkableUrlByProviderId = (providerId: string) => {
    return `${getLinkableUrl(PAGE.SpiProviderOnboarding)}/${providerId || ''}`;
};

export const getLinkableUrlByProviderIdAndVersion = (providerId:string, versionId: string) => {
    return `${getLinkableUrl(PAGE.SpiProviderOnboarding)}/${providerId || ''}/${versionId || ''}`;
};