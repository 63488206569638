import { IWeblabAllocation, WeblabAllocationType } from '../models/WeblabAllocation';
import { SUPPORTED_EXPOSURE_TREATMENTS, LAUNCH_EXPOSURE_ALLOCATION, LAUNCH_EXPOSURE_MESSAGE, WEBLAB_ALLOC_C_50_T_50 } from '../constants/exposureControlConstants';
import { IApprovalMetadata } from '../models/ApprovalInfo';
import { Select } from '@amzn/awsui-components-react';

export const treatmentToDialupStageMapping: { [key: number]: REQUESTED_DIALUP_STAGE_OPTIONS } = {
    0.2: 'LIVE_TEN_PERCENT',
    1: 'LIVE_FIFTY_PERCENT',
    100: 'LIVE_HUNDRED_PERCENT'
};

export const dialupStageToTreatmentMapping: { [key in LAST_DIALUP_STAGE_OPTIONS]: number } = {
    'PAUSED': 0,
    'LIVE_TEN_PERCENT': 0.2,
    'LIVE_FIFTY_PERCENT': 1,
    'LIVE_HUNDRED_PERCENT': 100,
    'LAUNCHED': 100
};

export type REQUESTED_DIALUP_STAGE_OPTIONS = 'LIVE_TEN_PERCENT' | 'LIVE_FIFTY_PERCENT' | 'LIVE_HUNDRED_PERCENT' | 'PAUSED';
export type LAST_DIALUP_STAGE_OPTIONS = REQUESTED_DIALUP_STAGE_OPTIONS | 'LAUNCHED';

export const DIRECT_EXCEPTION_APPROVAL_ALLOCATION_DROPDOWN_OPTIONS: Select.Option[] = [
    {
        id: 'NO_EXCEPTION',
        label: 'No exception',
        description: 'Normal process, it will go to "C: 10%, T1: 10%, No exposure 80%".'
    },
    {
        id: 'LIVE_FIFTY_PERCENT',
        label: 'C: 50%, T1: 50%, No exposure: 0%',
        description: 'After approval, it will go directly to 50%'
    },
    {
        id: 'LIVE_HUNDRED_PERCENT',
        label: 'C: 0%, T1: 100%, No exposure: 0%',
        description: 'After approval, it will go to full exposure'
    }
];

export const EXCEPTIONAL_APPROVAL_OPTIONS: { [key: string]: string } = {
    '1': 'C: 50%, T1: 50%, No exposure: 0%',
    '100': 'C: 0%, T1: 100%, No exposure: 0%'
};

export const EXCEPTIONAL_APPROVAL_LAUNCH_OPTION: { [key: string]: string } = {
    '100': 'C: 0%, T1: 100%, No exposure: 0%'
};

export const normalizeWeblabAllocation = (allocation: IWeblabAllocation) : IWeblabAllocation => {
    if (!allocation) {
        return {};
    }
    const totalAllocation = Object.values(allocation).reduce((total: number, value ) => total + (value || 0), 0);
    if (!totalAllocation){
        return {};
    }
    return Object.keys(allocation).reduce((prev: IWeblabAllocation, k: string) => {
        const key: WeblabAllocationType = k as WeblabAllocationType;
        if(allocation[key]){
            prev[key] = allocation[key]! / totalAllocation;
        }
        return prev;
    }, {} as IWeblabAllocation);
};

export const getAllocationMessage = (allocation: IWeblabAllocation | undefined, percentage: number) => {
    if (!allocation) {
        return '';
    }
    const normalizedAlloc = normalizeWeblabAllocation(allocation);
    const messagePrefix = ('C' in normalizedAlloc) ? '' : 'C: 0%, ';
    let totalAllocation = 0;
    const messages = Object.entries(normalizedAlloc).map(entry =>{
        const [key, value] = entry;
        if(value){
            const actualAllocation = value * percentage * 100;
            totalAllocation += actualAllocation;
            return `${key}: ${actualAllocation}%`;
        }
        return undefined;
    });
    const trimmedNoExposureAllocation = (LAUNCH_EXPOSURE_ALLOCATION - totalAllocation).toFixed(0);
    return `${messagePrefix}${messages.join(', ')}, No exposure: ${trimmedNoExposureAllocation}%`;
};

export const isWeblabAllocationValid = (allocation?: IWeblabAllocation) => {

    if (!allocation || Object.keys(allocation).length === 0) {
        return false;
    }
    if (Object.keys(allocation).length === 1 && 'C' in allocation) {
        return false;
    }

    const firstAllocationValue = Object.values(allocation)[0];
    return Object.values(allocation).reduce((final, value) => final && firstAllocationValue === value, true);
};

// ToDo: dive deep into how we should generate allocation message dynamically when supporting multi treatment
export const populateAllocationMessages = (allocation?: IWeblabAllocation ): { [treatmentStage: string]: string } => {

    if (!allocation) {
        return {};
    }
    const allocationMessages: { [key: string]: string } = {};
    const normalizedAlloc = normalizeWeblabAllocation(allocation);
    SUPPORTED_EXPOSURE_TREATMENTS.forEach(treatmentStage => {
        allocationMessages[String(treatmentStage)] = getAllocationMessage(normalizedAlloc, treatmentStage);
    });

    allocationMessages[String(LAUNCH_EXPOSURE_ALLOCATION)] = LAUNCH_EXPOSURE_MESSAGE;
    return allocationMessages;
};

// Temporary solution to make allocation message static before we supporting multi treatment
export const populateStaticAllocationMessages = (): { [treatmentStage: string]: string } => {

    const allocationMessages: { [key: string]: string } = {};
    const normalizedAlloc = normalizeWeblabAllocation(WEBLAB_ALLOC_C_50_T_50);
    SUPPORTED_EXPOSURE_TREATMENTS.forEach(treatmentStage => {
        allocationMessages[String(treatmentStage)] = getAllocationMessage(normalizedAlloc, treatmentStage);
    });

    allocationMessages[String(LAUNCH_EXPOSURE_ALLOCATION)] = LAUNCH_EXPOSURE_MESSAGE;
    return allocationMessages;
};

export const isWeblabLaunched = (allocation?: IWeblabAllocation) : boolean => {
    if(!allocation) {
        return false;
    }
    return isWeblabAllocationValid(allocation) && Object.keys(allocation).length === 1;
};

export const checkIfExperienceApprovedForFurtherDialup = (approvalInfo?: IApprovalMetadata) => {
    let approvedForDialup = false;
    let furtherDialupPercentage = 0; // tracks whether experience can be dialed up to 50% or 100% LIVE and enables dropdown accordingly
    if (approvalInfo) {
        switch (approvalInfo.approvedStage) {
            case 'LIVE_FIFTY_PERCENT':
            case 'LIVE_HUNDRED_PERCENT':
                furtherDialupPercentage = (approvalInfo.approvedStage === 'LIVE_FIFTY_PERCENT') ? 50 : 100;
                if (approvalInfo.lastDialupStage) {
                    approvedForDialup = dialupStageToTreatmentMapping[approvalInfo.approvedStage] > dialupStageToTreatmentMapping[approvalInfo.lastDialupStage];
                }
                break;
            default:
                break;
        }
    }
    return {approvedForDialup, furtherDialupPercentage};
};

export const generateExceptionalLaunchSelectOption = () : Select.IOption[] => {
    const options = Object.keys(EXCEPTIONAL_APPROVAL_LAUNCH_OPTION).map(key => {
        return {
            id: key,
            label: EXCEPTIONAL_APPROVAL_LAUNCH_OPTION[key]
        };
    });
    return options;
};

export const generateExceptionalSelectOption = () : Select.IOption[] => {
    const options = Object.keys(EXCEPTIONAL_APPROVAL_OPTIONS).map(key => {
        return {
            id: key,
            label: EXCEPTIONAL_APPROVAL_OPTIONS[key]
        };
    });
    return options;
};
