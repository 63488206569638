import * as React from 'react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { ColumnLayout, FormField, Spinner, Select, Alert, Button, Tooltip, Tiles, Checkbox, Icon } from '@amzn/awsui-components-react';
import { INotificationExperienceContent } from '../../models/appDeviceExperience/NotificationExperienceContent';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { connect } from 'react-redux';
import { AppState } from '../../reducers/index';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { pushNotificationContentSectionAction } from '../../actions/experienceEditViewActions';
import { OptionalField, RequiredField } from '../../components/common/DescriptionAnnotations';
import { notificationHeaderLimit, notificationHeaderLimitPNAPI, notificationContentLimitPNAPI, notificationContentLimit, ctaButtonTextLimit, spokenContentLimit } from '../../constants/componentConstants';
import { InputWrapper } from '../../components/common/InputWrapper';
import { NotificationValidator } from '../../util/NotificationValidator';
import { TextAreaWrapper } from '../../components/common/TextAreaWrapper';
import { UrlType } from '../../models/UrlType';
import { isRightToLeftLocale, LOCALE_TO_LANG } from '../../constants/locales';
import { TextRenderDirection } from '../../models/htmlElementProps';
import { LinkedA4pcWiki } from '../../components/common/LinksRenderHelper';
import { ExternalLink } from '../../components/common/LinkComponents';
import { isNullOrUndefined } from 'util';
import {
    MOBILE_PUSH_URL_USER_GROUP,
    PROACTIVE_NOTIFICATIONS_USER_GROUP
} from '../../constants/userGroups';
import { ImageValidator } from '../../util/ImageValidator';
import { AsyncInputWrapper } from '../../components/common/AsyncInputWrapper';
import {isStringEmpty} from '../../util/stringAndMappingHelper';

interface INotificationSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    permissions?: string[];
    updateCandidate?: ExperienceUpdateCandidate;
    notificationType?: 'App' | 'Device';
    onMobileAppGuidelinesCheckedUpdate?: (checked?: boolean) => void;
}

interface INotificationSectionViewState {
    isCategorySelected: boolean;
    selectedUrltype?: string;
    forcedLtrRendering: boolean;
    mobileNotificationsCXGuidelinesCheckbox: boolean;
}

export class NotificationSectionView extends React.Component<INotificationSectionViewProps, INotificationSectionViewState> {
    constructor(props: INotificationSectionViewProps) {
        super(props);

        this.state = {
            isCategorySelected: false,
            selectedUrltype: undefined,
            forcedLtrRendering: false,
            mobileNotificationsCXGuidelinesCheckbox: false
        };
    }

    updateNotificationContent(title?: string, displayContent?: string, spokenContent?: string, url?: string,
        deepLinkUrl?: string, webUrl?: string, amazonUrl?: string, googleUrl?: string, appleUrl?: string,
        appNotificationType?: string, ctaButtonTitle?: string, ctaButtonAction?: string, checkAppMobileNotificationsGuidelines?: boolean,
        interactionPayload?: string, notificationThumbnail?: string, banyanCampaign?: boolean, imageUrl?: string) {
        const { dispatch } = this.props;
        const storeUrls = {amazonUrl, googleUrl, appleUrl};
        dispatch(pushNotificationContentSectionAction({
            title,
            displayContent,
            spokenContent,
            url,
            deepLinkUrl,
            webUrl,
            storeUrls,
            appNotificationType,
            ctaButtonTitle,
            ctaButtonAction,
            checkAppMobileNotificationsGuidelines,
            interactionPayload,
            notificationThumbnail,
            banyanCampaign,
            imageUrl
        }));
    }

    shouldRenderTextRightToLeft(locale: string): boolean {
        const { forcedLtrRendering } = this.state;

        return isRightToLeftLocale(locale) && !forcedLtrRendering;
    }

    onTextDirectionSwitchButtonClicked(): void {
        this.setState((prevState) => {
            return {
                ...prevState,
                forcedLtrRendering: !prevState.forcedLtrRendering
            };
        });
    }

    onMobileAppGuidelinesConfirmation(checked: boolean, onMobileAppGuidelinesCheckedUpdate?: (checked?: boolean) => void) {
        this.setState({ mobileNotificationsCXGuidelinesCheckbox: checked });
            if (onMobileAppGuidelinesCheckedUpdate) {
                onMobileAppGuidelinesCheckedUpdate(checked);
        }
    }

    private getAppNotificationTypeTileItems(disabled: boolean): Tiles.TileDefinition[] {
        return [
            {
                controlId: 'MobileAppNotification',
                label: 'Mobile Push Notification from Alexa App',
                value: 'Mobile',
                disabled
            },
            {
                controlId: 'DesktopAppNotification',
                label: 'Desktop notification from Alexa for PC',
                value: 'Desktop',
                disabled
            },
        ];
    }

    private getNotificationBanyanTypeTileItems(disabled: boolean): Tiles.TileDefinition[] {
        return [
            {
                controlId: 'BanyanCampaignExperience',
                label: 'Banyan Campaign Experience',
                value: 'Banyan',
                disabled
            },
            {
                controlId: 'NonBanyanCampaignExperience',
                label: 'NonBanyan Campaign Experience',
                value: 'NonBanyan',
                disabled
            }
        ];
    }

    render () {
        const { notificationType, isUpdating, isEditing, experience,
            updateCandidate, onMobileAppGuidelinesCheckedUpdate, permissions } = this.props;

        const { forcedLtrRendering } = this.state;

        if (!experience) {
            return <FormSectionView title='Basic Info'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const type = notificationType || experience.shortenedType;

        const shouldDisableInput = !isEditing || isUpdating;

        const contentVariables: string[] = ((isEditing && updateCandidate)
            ? updateCandidate.getTemplateVariables()
            : experience.templateVariables || []).map(v => v.variableName);

        const renderData: INotificationExperienceContent = (isEditing && updateCandidate)
            ? updateCandidate.getNotificationContent()
            : ExperienceUpdateCandidate.extractNotificationContent(experience);

        const appNotificationType: string = renderData.appNotificationType ? renderData.appNotificationType : 'Mobile';
        const isDesktopNotification: boolean = appNotificationType === 'Desktop';

        const targetLocale = ((isEditing && updateCandidate)
            ? updateCandidate.getRegion().locale
            : experience.locale) || 'en_US';

        const textDirection: TextRenderDirection = isRightToLeftLocale(targetLocale) ? 'rtl' : 'ltr';

        const localeEnabled = ((isEditing && updateCandidate)
            ? updateCandidate.getRegion().enabled
            : experience.enabledFilter?.enabled) || false;

        const operatorGroup = ((isEditing && updateCandidate)
            ? updateCandidate.getBasicInfo().groupImpressions
            : experience.operatorGroup) || '';

        const ProactiveNotificationsGroup = PROACTIVE_NOTIFICATIONS_USER_GROUP;

        // Determine defaultValue for URT type in first loading.
        const urlsType = this.state.selectedUrltype === undefined
            ? isStringEmpty(renderData.deepLinkUrl)
                ? UrlType.ALEXA_APP
                : isStringEmpty(renderData.webUrl)
                    ? UrlType.DEEP_LINK_URL_WITH_STORE_URLS
                    : UrlType.DEEP_LINK_WITH_WEB_URL
            : undefined;
        // experience type cannot be changed through edit view

        // You could use multiline string, but it only works with ES5 or newer
        // Desktop notification doesn't need Call to Action part because it already has a specific field for it
        const contentDescriptionString = `Body should include 1) Action 2) Benefit statement${isDesktopNotification
            ? '.'
            : ' 3) Call to Action - it can either be a dialog or a click/tap action.'
        } If your notification content includes a dialog, please write it within quotes, e.g. "Alexa, play the debate"`;

        const interactionDescription = `Interaction payload should be a json string. Please see the example below and visit wiki for more details:
        https://developer.amazon.com/en-US/docs/alexa/smapi/interactive-notifications.html`;
        const thumbnailDescription = 'Please make sure that the supplied thumbnail url begins with "https://m.media-amazon.com/images".';

        return <div lang={LOCALE_TO_LANG[targetLocale]} spellCheck={true} >
            <FormSectionView
                title={ isDesktopNotification ? 'A4PC Notification' : `${type} Notification`}
                description={(type === 'App')
                    ? 'Select the app notification type and configure the content'
                    : 'Configure your device notification content here'}>
                <ColumnLayout>
                    <div className='awsui-util-mb-m'>
                        {type === 'App' &&
                            <Tiles
                                id='experience.app-notification-type-tiles'
                                items={this.getAppNotificationTypeTileItems(shouldDisableInput)}
                                value={appNotificationType}
                                columns={2}
                                onChange={e => {
                                    this.updateNotificationContent(undefined, undefined, undefined, '', '', '', '', '',  undefined, e.detail.value);
                                }}
                            />
                        }
                    </div>
                    <div className='awsui-util-mb-m'>
                        <FormField
                            label={<RequiredField fieldName='Notification title' />}
                            description='
                                Try to include the feature name in Title. Title should be
                                short and should clearly articulate purpose of the notification.'
                        >
                            <InputWrapper
                                id='experience.notification-title'
                                readonly={shouldDisableInput}
                                value={renderData.title}
                                placeholder='ex. Did you know Alexa can do this?'
                                characterLimit={operatorGroup === ProactiveNotificationsGroup ? notificationHeaderLimitPNAPI : notificationHeaderLimit}
                                textRenderDirection={textDirection}
                                onInput={(input: string) => {
                                    this.updateNotificationContent(input);
                                }}
                                validate={(title) => NotificationValidator.isTitleValid(title, contentVariables, targetLocale, localeEnabled)}
                            />
                        </FormField>
                    </div>
                    <div className='awsui-util-mb-m'>
                        <FormField
                            label={<RequiredField fieldName='Display content' />}
                            description={contentDescriptionString} >
                            <TextAreaWrapper
                                id='experience.notification-content'
                                experienceType={type}
                                readonly={shouldDisableInput}
                                textRenderDirection={textDirection}
                                placeholder='Notification content goes here'
                                value={renderData.displayContent}
                                characterLimit={operatorGroup === ProactiveNotificationsGroup ? notificationContentLimitPNAPI : notificationContentLimit}
                                onInput={(input: string) => {
                                    this.updateNotificationContent(undefined, input);
                                }}
                                validate={(displayContent) => NotificationValidator.isDisplayContentValid(displayContent, contentVariables, targetLocale, localeEnabled)}
                            />
                        </FormField>
                    </div>
                    {type !== 'Device' &&
                    <div>
                       {isNullOrUndefined(experience.id)
                            ? <>
                                <Alert type='info' className='awsui-util-mb-l'>
                                    Please read the following prior to proceeding further:
                                    <ul>
                                        <li> <ExternalLink href={'https://designguide.corp.amazon.com/en/alexa-app/guidelines/notifications/'}
                                        >Alexa App Mobile Notifications Guidelines</ExternalLink></li>
                                        <li><ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+Push+Notifications+Launch'}
                                        >Odyssey Push Notifications Launch Wiki</ExternalLink></li>
                                        <li><ExternalLink href={'https://legal.amazon.com/WorkingWithLegal/MarketingGuidelines/Pages/Push_Notification_Guidance_US_EU.aspx'}
                                        >Push Notifications Legal Guidance for Marketing Messages</ExternalLink></li>
                                    </ul>

                                    <Checkbox
                                        id={'experience.confirm-read-mobile-notifications-guidelines'}
                                        controlId={'experience.confirm-read-mobile-notifications-guidelines'}
                                        checked={renderData.checkAppMobileNotificationsGuidelines || false}
                                        onChange={(e) => {
                                            this.onMobileAppGuidelinesConfirmation(e.detail.checked, onMobileAppGuidelinesCheckedUpdate);
                                            this.updateNotificationContent(undefined, undefined, undefined, undefined, '', '', '', '', '', '', '', '', e.detail.checked);
                                        }}
                                    ><b>I confirm that I have read the above and will follow the guidelines mentioned in the Alexa App Mobile Notifications Guidelines,
                                        Odyssey Push Notifications Launch Wiki, and Push Notifications Legal Guidance for Marketing Messages.</b></Checkbox>
                                    <div><div><span className='awsui-util-status-negative'><Icon name='status-warning' />  This box must be checked</span></div><span /></div>
                                </Alert>
                            </>
                            : <>
                                <Alert type='info' visible={isEditing} className='awsui-util-mb-l'>
                                    Please read the following prior to proceeding further:
                                    <ul>
                                        <li> <ExternalLink href={'https://designguide.corp.amazon.com/en/alexa-app/guidelines/notifications/'}
                                        >Alexa App Mobile Notifications Guidelines</ExternalLink></li>
                                        <li><ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+Push+Notifications+Launch'}
                                        >Odyssey Push Notifications Launch Wiki</ExternalLink></li>
                                        <li><ExternalLink href={'https://legal.amazon.com/WorkingWithLegal/MarketingGuidelines/Pages/Push_Notification_Guidance_US_EU.aspx'}
                                        >Push Notifications Legal Guidance for Marketing Messages</ExternalLink></li>
                                    </ul>
                               </Alert>
                            </>}
                    </div>
                    }
                    {type === 'App' && isDesktopNotification &&
                        <div className='awsui-util-mb-m'>
                            <FormField
                                label={<RequiredField fieldName='Notification button text' />}
                                description='The call to action of the button.'>
                                <InputWrapper
                                    id='experience.cta-button-title'
                                    readonly={shouldDisableInput}
                                    value={renderData.ctaButtonTitle}
                                    characterLimit={ctaButtonTextLimit}
                                    textRenderDirection={textDirection}
                                    placeholder='ex. Open Alexa'
                                    onInput={(input: string) => {
                                        this.updateNotificationContent(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 'Desktop', input);
                                    }}
                                    validate={(ctaButtonTitle) => NotificationValidator.isCtaButtonTitleValid(ctaButtonTitle, contentVariables, targetLocale, localeEnabled)}
                                />
                            </FormField>
                            <FormField
                                label={<RequiredField fieldName='Notification button URL' />}
                                description={<span>The destination that the user will navigate to within the Alexa for PC app. Please reference the&nbsp;
                                    <LinkedA4pcWiki /> for guidelines.</span>}>
                                <InputWrapper
                                    id='experience.cta-button-action'
                                    readonly={shouldDisableInput}
                                    value={renderData.ctaButtonAction}
                                    textRenderDirection={textDirection}
                                    onInput={(input: string) => {
                                        this.updateNotificationContent(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 'Desktop', undefined, input);
                                    }}
                                    validate={(ctaButtonAction) => NotificationValidator.isCtaButtonActionValid(ctaButtonAction, contentVariables, targetLocale, localeEnabled)}
                                />
                            </FormField>
                        </div>
                    }
                    {type === 'App' && !isDesktopNotification &&
                        <div className='awsui-util-mb-m'>
                            <ColumnLayout columns={3}>
                                <div data-awsui-column-layout-root='true' className='awsui-util-mb-m' spellCheck={false} >
                                    <div>
                                        <FormField
                                            label='URL type'
                                            description='Type of URL direction of customer'>
                                            <Select
                                                id='experience.notification-ut-categories-url'
                                                controlId='experience.notification-ut-categories-url'
                                                disabled={shouldDisableInput}
                                                options={(operatorGroup === MOBILE_PUSH_URL_USER_GROUP && permissions?.includes('OPERATOR')) ? fullUrlsTypeOptions : urlsTypeOptions}
                                                selectedId= {this.state.selectedUrltype ? this.state.selectedUrltype : urlsType}
                                                onChange={e => {
                                                    this.setState({
                                                        selectedUrltype: e.detail.selectedId? e.detail.selectedId : undefined
                                                    });
                                                }}
                                            />
                                        </FormField>
                                    </div>
                                    {(this.state.selectedUrltype === UrlType.ALEXA_APP || urlsType === UrlType.ALEXA_APP) &&
                                        <FormField
                                            label={<RequiredField fieldName='URL' />}
                                            description='When user taps on the notification, they will be directed to a destination within the Alexa application'>
                                            <InputWrapper
                                                id='experience.notification-url'
                                                readonly={shouldDisableInput}
                                                value={renderData.url}
                                                onInput={(input: string) => {
                                                    this.setState({
                                                        isCategorySelected: false,
                                                        selectedUrltype: this.state.selectedUrltype !== undefined ? this.state.selectedUrltype : urlsType
                                                    });
                                                    this.updateNotificationContent(undefined, undefined, undefined, input, '', '', '', '', '');
                                                }}
                                                validate={(url) => NotificationValidator.isUrlValid(url, contentVariables, targetLocale, localeEnabled)}
                                            />
                                        </FormField>
                                    }
                                    {(operatorGroup === MOBILE_PUSH_URL_USER_GROUP && permissions?.includes('OPERATOR')) &&
                                        (this.state.selectedUrltype === UrlType.DEEP_LINK_URL_WITH_STORE_URLS || urlsType === UrlType.DEEP_LINK_URL_WITH_STORE_URLS) &&
                                        <div>
                                            <FormField
                                                label={<RequiredField fieldName='Deep Link URL' />}
                                                description='When user taps on the notification, this is where the customer will be directed to.'>
                                                <InputWrapper
                                                    id='experience.notification-deep-link-url'
                                                    readonly={shouldDisableInput}
                                                    value={renderData.deepLinkUrl}
                                                    onInput={(input: string) => {
                                                        this.setState({
                                                            isCategorySelected: false,
                                                            selectedUrltype: this.state.selectedUrltype !== undefined ? this.state.selectedUrltype : urlsType
                                                        });
                                                        this.updateNotificationContent(undefined, undefined, undefined, '', input, '');
                                                    }}
                                                    validate={(deepLinkUrl) => NotificationValidator.isUrlValid(deepLinkUrl, contentVariables, targetLocale, localeEnabled)}
                                                />
                                            </FormField>
                                            <FormField
                                                label={<h3>Fallback URLs</h3>}
                                                description={<span>
                                                    Fallback URLs are used when the customer's mobile device does not have the necessary App installed to execute the provided Deep Link URL.
                                                    <br /><br /></span>}
                                                >
                                            </FormField>
                                            <FormField
                                                label={<RequiredField fieldName='Amazon App Store URL' />}
                                                description='Fallback URL that directs user to the Amazon App Store.'>
                                                <InputWrapper
                                                    id='experience.notification-amazon-url'
                                                    readonly={shouldDisableInput}
                                                    value={renderData.storeUrls?.amazonUrl}
                                                    placeholder='amzn://apps/android?asin=B004FRX0MY'
                                                    onInput={(input: string) => {
                                                        this.setState({
                                                            isCategorySelected: false,
                                                            selectedUrltype: this.state.selectedUrltype !== undefined ? this.state.selectedUrltype : urlsType
                                                        });
                                                        this.updateNotificationContent(undefined, undefined, undefined, '', undefined, '', input);
                                                    }}
                                                    validate={(amazonUrl) => NotificationValidator.isUrlValid(amazonUrl, contentVariables, targetLocale, localeEnabled)}
                                                />
                                            </FormField>
                                            <FormField
                                                label={<RequiredField fieldName='Google Play Store URL' />}
                                                description='Fallback URL that directs user to the Google Play Store.'>
                                                <InputWrapper
                                                    id='experience.notification-google-url'
                                                    readonly={shouldDisableInput}
                                                    value={renderData.storeUrls?.googleUrl}
                                                    placeholder='https://play.google.com/store/apps/details?id=com.amazon.mp3'
                                                    onInput={(input: string) => {
                                                        this.setState({
                                                            isCategorySelected: false,
                                                            selectedUrltype: this.state.selectedUrltype !== undefined ? this.state.selectedUrltype : urlsType
                                                        });
                                                        this.updateNotificationContent(undefined, undefined, undefined, '', undefined, '', undefined, input);
                                                    }}
                                                    validate={(googleUrl) => NotificationValidator.isUrlValid(googleUrl, contentVariables, targetLocale, localeEnabled)}
                                                />
                                            </FormField>
                                            <FormField
                                                label={<RequiredField fieldName='Apple App Store URL' />}
                                                description='Fallback URL that directs user to the Apple App Store.'>
                                                <InputWrapper
                                                    id='experience.notification-apple-url'
                                                    readonly={shouldDisableInput}
                                                    value={renderData.storeUrls?.appleUrl}
                                                    placeholder='https://apps.apple.com/us/app/amazon-music-songs-podcasts/id510855668'
                                                    onInput={(input: string) => {
                                                        this.setState({
                                                            isCategorySelected: false,
                                                            selectedUrltype: this.state.selectedUrltype !== undefined ? this.state.selectedUrltype : urlsType
                                                        });
                                                        this.updateNotificationContent(undefined, undefined, undefined, '', undefined, '', undefined, undefined, input);
                                                    }}
                                                    validate={(appleUrl) => NotificationValidator.isUrlValid(appleUrl, contentVariables, targetLocale, localeEnabled)}
                                                />
                                            </FormField>
                                        </div>
                                    }
                                    {(operatorGroup === MOBILE_PUSH_URL_USER_GROUP && permissions?.includes('OPERATOR')) &&
                                        (this.state.selectedUrltype === UrlType.DEEP_LINK_WITH_WEB_URL || urlsType === UrlType.DEEP_LINK_WITH_WEB_URL) &&
                                        <div>
                                            <FormField
                                                label={<RequiredField fieldName='Deep Link URL' />}
                                                description='When user taps on the notification, this is where the customer will be directed to.'>
                                                <InputWrapper
                                                    id='experience.notification-deep-link-url'
                                                    readonly={shouldDisableInput}
                                                    value={renderData.deepLinkUrl}
                                                    onInput={(input: string) => {
                                                        this.setState({
                                                            isCategorySelected: false,
                                                            selectedUrltype: this.state.selectedUrltype !== undefined ? this.state.selectedUrltype : urlsType
                                                        });
                                                        this.updateNotificationContent(undefined, undefined, undefined, '', input, undefined, '', '', '');
                                                    }}
                                                    validate={(deepLinkUrl) => NotificationValidator.isUrlValid(deepLinkUrl, contentVariables, targetLocale, localeEnabled)}
                                                />
                                            </FormField>
                                            <FormField
                                                label={<h3>Fallback URLs</h3>}
                                                description={<span>
                                                    Fallback URLs are used when the customer's mobile device does not have the necessary App installed to execute the provided Deep Link URL.
                                                    <br /><br /></span>}
                                                >
                                            </FormField>
                                            <FormField
                                                label={<RequiredField fieldName='Web URL' />}
                                                description='Fallback URL that directs user to a Web page.'>
                                                <InputWrapper
                                                    id='experience.notification-web-url'
                                                    readonly={shouldDisableInput}
                                                    value={renderData.webUrl}
                                                    placeholder='https://www.amazon.com'
                                                    onInput={(input: string) => {
                                                        this.setState({
                                                            isCategorySelected: false,
                                                            selectedUrltype: this.state.selectedUrltype !== undefined ? this.state.selectedUrltype : urlsType
                                                        });
                                                        this.updateNotificationContent(undefined, undefined, undefined, '', undefined, input, '', '', '');
                                                    }}
                                                    validate={(webUrl) => NotificationValidator.isUrlValid(webUrl, contentVariables, targetLocale, localeEnabled)}
                                                />
                                            </FormField>
                                        </div>
                                    }
                                </div>
                            </ColumnLayout>
                        </div>
                    }
                    {type === 'App' && !isDesktopNotification &&
                        <div className='awsui-util-mb-m'>
                            <FormField
                                label='Attach an image to this app notification'
                                description='When a user receives an app notification, this image will be shown alongside the notification text'>
                                <AsyncInputWrapper
                                    id='experience.notification-image-url'
                                    readonly={shouldDisableInput}
                                    value={renderData.imageUrl}
                                    textRenderDirection={textDirection}
                                    placeholder='ex. https://m.media-amazon.com/images/<path>'
                                    onInput={(input: string) => {
                                        this.updateNotificationContent(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
                                            undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, input);
                                    }}
                                    validate={async (url) => await ImageValidator.isNotificationImageValid(url)}
                                />
                            </FormField>
                        </div>
                    }
                    {type === 'Device' &&
                        <div className='awsui-util-mb-m'>
                            <FormField
                                label={<RequiredField fieldName='Spoken content' />}
                                description={contentDescriptionString} >
                                {isRightToLeftLocale(targetLocale)
                                    && <Tooltip
                                        position='top'
                                        text='Use RTL for natural Arabic text direction; LTR to validate SSML tags'>
                                        <Button
                                            id='button.rtl-and-ltr'
                                            icon={forcedLtrRendering ? 'caret-right' : 'caret-left'}
                                            onClick={this.onTextDirectionSwitchButtonClicked.bind(this)} >
                                            Switch to {forcedLtrRendering ? 'Right-to-Left (RTL)' : 'Left-to-Right (LTR)'}
                                        </Button>
                                    </Tooltip>
                                }
                                <TextAreaWrapper
                                    id='experience.spoken-content'
                                    readonly={shouldDisableInput}
                                    placeholder='Notification spoken content goes here'
                                    textRenderDirection={this.shouldRenderTextRightToLeft(targetLocale) ? 'rtl' : 'ltr'}
                                    value={renderData.spokenContent}
                                    characterLimit={spokenContentLimit}
                                    onInput={(input: string) => {
                                        this.updateNotificationContent(undefined, undefined, input);
                                    }}
                                    validate={(spokenContent) => NotificationValidator.isSpokenContentValid(spokenContent, contentVariables, targetLocale, localeEnabled)}
                                />
                            </FormField>
                        </div>}
                    {type === 'Device' && operatorGroup === ProactiveNotificationsGroup &&
                        <div className='awsui-util-mb-m'>
                            <FormField
                                label={<OptionalField fieldName='Interaction Payload' />}
                                description={interactionDescription} >
                                <TextAreaWrapper
                                    id='experience.interaction-payload'
                                    readonly={shouldDisableInput}
                                    placeholder='{
                                        "type": "AlexaSkillConnection",
                                        "uri": "connection://AMAZON.RecommendShoppingProducts/1",
                                        "text": "Buy now",
                                        "spokenText": {
                                          "type": "PLAIN_TEXT",
                                          "content": "Would you like to buy this item now ?"
                                        },
                                        "input": {
                                          "products": [{
                                            "asin": "B08J8FFJ8H"
                                          }]
                                        }
                                      }'
                                    textRenderDirection={this.shouldRenderTextRightToLeft(targetLocale) ? 'rtl' : 'ltr'}
                                    value={renderData.interactionPayload}
                                    characterLimit={5000}
                                    onInput={(input: string) => {
                                        this.updateNotificationContent(undefined, undefined, undefined, undefined, undefined,
                                            undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, input);
                                    }}
                                    validate={(interactionPayload) => NotificationValidator.isInteractionPayloadValid(interactionPayload)}
                                    operatorGroup={ProactiveNotificationsGroup}
                                />
                            </FormField>
                            <FormField
                                label={<OptionalField fieldName='Notification Thumbnail' />}
                                description={thumbnailDescription} >
                                <TextAreaWrapper
                                    id='experience.notification-thumbnail'
                                    readonly={shouldDisableInput}
                                    placeholder='https://m.media-amazon.com/images/G/01/Alexa/Settings/amazon_logo.png'
                                    textRenderDirection={this.shouldRenderTextRightToLeft(targetLocale) ? 'rtl' : 'ltr'}
                                    value={renderData.notificationThumbnail}
                                    characterLimit={300}
                                    onInput={(input: string) => {
                                        this.updateNotificationContent(undefined, undefined, undefined, undefined, undefined, undefined, undefined,
                                            undefined, undefined, undefined, undefined, undefined, undefined, undefined, input);
                                    }}
                                    validate={(notificationThumbnail) => NotificationValidator.isThumbnailImageValid(notificationThumbnail)}
                                    operatorGroup={ProactiveNotificationsGroup}
                                />
                            </FormField>
                        </div>}
                        <div className='awsui-util-mb-m'>
                            <FormField
                                label={<RequiredField fieldName='Banyan classification' />}
                                description='Specify Banyan or Non-Banyan customer targeting.' >
                                <Tiles
                                id='experience.banyan-type-tiles'
                                items={this.getNotificationBanyanTypeTileItems(shouldDisableInput)}
                                value={renderData.banyanCampaign === true ? 'Banyan' : 'NonBanyan'}
                                columns={2}
                                onChange={e => {
                                    this.updateNotificationContent(undefined, undefined, undefined, undefined, undefined, undefined,
                                        undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, e.detail.value === 'Banyan' ? true : false);
                                }}
                            />
                            </FormField>
                        </div>
                </ColumnLayout>
            </FormSectionView>
        </div>;
    }
}

// Selection type for URLs
const fullUrlsTypeOptions : Select.Option[] =  [
    {id: UrlType.ALEXA_APP, label: 'Direct within Alexa application', tags: ['Customer goes somewhere in Alexa application']},
    {id: UrlType.DEEP_LINK_URL_WITH_STORE_URLS, label: 'Direct to Deep link and iOS/Android/Amazon store', tags: ['Customer goes outside of Alexa application']},
    {id: UrlType.DEEP_LINK_WITH_WEB_URL ,label: 'Deep link and Web url', tags: ['Customer goes somewhere in Alexa application']}
];
const urlsTypeOptions : Select.Option[] = [
    {id: UrlType.ALEXA_APP, label: 'Direct within Alexa application', tags: ['Customer goes somewhere in Alexa application']}
];

const mapStateToProps = ({experienceDetailViewState, experienceEditViewState, authenticationState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        permissions: authenticationState.permissions || [],
        updateCandidate: experienceEditViewState.updateCandidate,
    };
};

export default connect(mapStateToProps)(NotificationSectionView);
