import { IInvocationParameters, BYOR_INVOCATION_PARAMETERS } from './InvocationParameters';

export enum TemplateVariableType {
    SLOT_VALUE = 'SLOT_VALUE',
    PUBLISHER_DATA = 'PUBLISHER_DATA',
    REMOTE_SERVICE = 'REMOTE_SERVICE'
}

export const ALL_SUPPORTED_VARIABLE_TYPES: TemplateVariableType[] = [
    TemplateVariableType.SLOT_VALUE,
    TemplateVariableType.PUBLISHER_DATA,
    TemplateVariableType.REMOTE_SERVICE
];

export const NOTIFICATION_SUPPORTED_VARIABLE_TYPES: TemplateVariableType[] = [
    TemplateVariableType.REMOTE_SERVICE
];

export interface ITemplateVariable {
    variableName: string;
    variableType: TemplateVariableType;
    dedupeByContent?: boolean;
    description?: string;
    invocationParameters?: IInvocationParameters;
}

export const EMPTY_TEMPLATE_VARIABLE: ITemplateVariable = {
    variableName: '',
    variableType: TemplateVariableType.SLOT_VALUE,
    invocationParameters: undefined
};

export const BYOR_TEMPLATE_VARIABLE: ITemplateVariable = {
    variableName: '',
    variableType: TemplateVariableType.REMOTE_SERVICE,
    invocationParameters: BYOR_INVOCATION_PARAMETERS
};

export const BYOR_DEFAULT_TEMPLATE_VARIABLE: ITemplateVariable = {
    variableName: 'ContentId',
    variableType: TemplateVariableType.REMOTE_SERVICE,
    description: 'This will be your content-level guardrail',
    dedupeByContent: true,
    invocationParameters: BYOR_INVOCATION_PARAMETERS
};

export function isTemplateVariableRemote(templateVariable: ITemplateVariable): boolean {
    return (templateVariable.variableType === TemplateVariableType.REMOTE_SERVICE &&
        templateVariable.invocationParameters?.CTI !== 'Alexa/PAGE/BYOR');
}

export function isVariableTypeBYOR(templateVariable: ITemplateVariable): boolean {
    return (templateVariable !== undefined && templateVariable.variableType === TemplateVariableType.REMOTE_SERVICE &&
         templateVariable.invocationParameters?.CTI === 'Alexa/PAGE/BYOR');
}

export const processTemplateVariables = (templateVariables?: ITemplateVariable[]): ITemplateVariable[] | undefined => {
    if (templateVariables == null || templateVariables === undefined || templateVariables.length === 0) {
        return undefined;
    }

    if (templateVariables.filter(v => !!v.variableName || !!v.description).length === 0) {
        return undefined;
    }

    return templateVariables;
};
