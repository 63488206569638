import * as React from 'react';
import { Icon } from '@amzn/awsui-components-react';
import { ExperienceStatus } from '../../models/Experience';
import { StatusBadgeMap } from '../../constants/statusBadgeMap';


export const StatusBadge = (props: { status: ExperienceStatus, controlId?: string }) => {
    const { status, controlId } = props;

    if (!StatusBadgeMap[status]) {
        return <span id={controlId} >
            <Icon name='status-positive' variant='success' />&nbsp;&nbsp;{status}
        </span>;
    } else {
        const { iconName, iconVariant, beautifiedName } = StatusBadgeMap[status];
        return <span id={controlId}>
            <Icon name={iconName} variant={iconVariant} />&nbsp;&nbsp;{beautifiedName}
        </span>;
    }
};
