import * as React from 'react';
import { connect } from 'react-redux';
import { IPromotedExperiments } from '../../models/promotedExperiments/PromotedExperiments';
import { AppState } from '../../reducers';
import { FormSectionView } from '../../components/common/FormSectionView';
import { ColumnLayout, FormField, Spinner } from '@amzn/awsui-components-react';
import { InputWrapper } from '../../components/common/InputWrapper';
import { CommonValidator } from '../../util/CommonValidator';

interface PromotedExperimentsViewProps {
    dispatch: any;
    isLoading: boolean;
    isPromotedExperimentsUpdated: boolean;
    errorUpdating?: Error;
    errorLoading?: Error;
    promotedExperiments?: IPromotedExperiments;
}

const mapStateToProps = ({ promotedExperimentsState }: AppState) => {
    return {
        isLoading: promotedExperimentsState.isLoading,
        isPromotedExperimentsUpdated: promotedExperimentsState.updatedPromotedExperiments,
        errorUpdating: promotedExperimentsState.errorUpdating || undefined,
        errorLoading: promotedExperimentsState.errorLoading || undefined,
        promotedExperiments: promotedExperimentsState.promotedExperiments || undefined,
    };
};

const promotedExperimentsDescription = 'Promoted experiments are prioritized when considered for opportunity, '
   + 'in that all other competing experiences are removed when promoted experiments are eligible for opportunity';

export const PromotedExperimentsTextOnlySectionView = connect(mapStateToProps)((props: PromotedExperimentsViewProps) => {
    const { isLoading, errorLoading, promotedExperiments } = props;
    if (isLoading) {
        return <Spinner size='large' variant='disabled' />;
    }

    if (errorLoading) {
        return <div id='promotedExperiments.content-render-area.error'>
            <p className='awsui-util-status-negative'>{errorLoading.message}</p>
        </div>;
    }

    if(!promotedExperiments) {
        return <div id='promotedExperiments.content-render-area.no-content'>
	        <p className='awsui-util-status-inactive awsui-util-status-negative' >No promoted experiments at the moment...</p>
	    </div>;
    }

    return <div id='promotedExperiments.content-render-area'>
        {promotedExperiments.promotedExperimentsInfoList.map((promotedExperiment, index) =>
             <div>
                <ColumnLayout columns={2}>
                    <div data-awsui-column-layout-root='true'>
                        <FormField label='Experience Id'>
                            <InputWrapper
                                id={`input.experiment-id-${index}`}
                                readonly={true}
                                value={promotedExperiment.experimentId}
                                validate={(value) => { return CommonValidator.isExperienceIdValid(value); }} />
                        </FormField>
                        <FormField label='MCM Link'>
                            <InputWrapper
                                id={`input.mcm-link-${index}`}
                                readonly={true}
                                value={promotedExperiment.mcm}
                                validate={(value) => { return CommonValidator.isMcmLinkValid(value); }} />
                        </FormField>
                    </div>
                </ColumnLayout>
                <hr />
             </div>
        )}
    </div>;
});


export const PromotedExperimentsSectionView = () => {
    return <FormSectionView title='Promoted Experiments' description= {promotedExperimentsDescription}>
        <PromotedExperimentsTextOnlySectionView/>
    </FormSectionView>;
};
