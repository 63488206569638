import { COMMON_ERROR_MESSAGES, DMPS_IMAGE_URL_HOST_ALLOW_LIST } from '../constants/validatorConstants';
import * as Axios from 'axios';
import axiosRateLimit from 'axios-rate-limit';
import { THROTTLE_LIMIT } from '../constants/throttleLimit';

interface IValidationResult {
    valid: boolean;
    error?: Error;
}

enum ImageType {
    ICON,
    BACKGROUND,
    APP_NOTIFICATION
}

export class ImageValidator {
    private static MAX_SIZE_BYTES = 5000000;
    private static DMPS_MEDIA_BYTES_LIMIT = 2000000;

    private static CACHE_REFRESH_INTERVAL_MS = 150000;

    private static CONTENT_TYPE_PNG = 'image/png';
    private static CONTENT_TYPE_SVG = 'image/svg+xml';
    private static CONTENT_TYPE_JPEG = 'image/jpeg';
    private static CONTENT_TYPE_GIF = 'image/gif';

    private static DEFAULT_CONTENT_TYPES = [ImageValidator.CONTENT_TYPE_PNG, ImageValidator.CONTENT_TYPE_SVG];
    private static DMPS_ALLOWED_CONTENT_TYPES = [ImageValidator.CONTENT_TYPE_PNG, ImageValidator.CONTENT_TYPE_JPEG, ImageValidator.CONTENT_TYPE_GIF];

    private static cache = new Map<string, IValidationResult>();
    public static axios = axiosRateLimit(Axios.default.create(), { maxRequests: THROTTLE_LIMIT });

    constructor() {
        // refresh cache every 15 seconds
        setInterval(ImageValidator.clearCache, ImageValidator.CACHE_REFRESH_INTERVAL_MS);
    }

    /**
     * Clear ImageValidator's cache, try to avoid calling this outside of unit tests
     */
    static clearCache() {
        this.cache.clear();
    }

    private static generateKey(url: string, imageType: ImageType) {
        const salt = 'isIcon?';
        if (imageType === ImageType.ICON) return salt + url;
        return url;
    }

    private static commonImageValidation(url: string,
        type: ImageType, allowDvp: boolean = true, allowedContentTypes: string[] = this.DEFAULT_CONTENT_TYPES,
        maxImageSize: number = this.MAX_SIZE_BYTES, allowListedDomains?: string[]) {
        return new Promise<void>((resolve, reject) => {
            const cachedResult = this.cache.get(this.generateKey(url, type));
            if (cachedResult) {
                if (cachedResult.valid) {
                    resolve();
                } else {
                    reject(cachedResult.error);
                }
                return;
            }
            try {
                const host = new URL(url).host;
                if (allowListedDomains && !allowListedDomains.includes(host)) {
                    // Do not cache result in case this function gets called again without allowListedDomains passed in
                    reject(new Error(COMMON_ERROR_MESSAGES.INVALID_IMAGE_URL_HOST));
                }
            } catch (err) {
                const regex = /\$\{[^}]+\}/;
                if (allowDvp && regex.test(url)) {
                    resolve();
                }
                else {
                    let errorMsg = '';
                    switch (type) {
                        case ImageType.BACKGROUND:
                            errorMsg = COMMON_ERROR_MESSAGES.BACKGROUND_IMAGE_LINK_NOT_VALID_URL;
                            break;
                        case ImageType.ICON:
                        case ImageType.APP_NOTIFICATION:
                        default:
                            errorMsg = COMMON_ERROR_MESSAGES.INPUT_STRING_NOT_VALID_URL;
                            break;
                    }
                    const result = { valid: false, error: new Error(errorMsg) };
                    this.cache.set(this.generateKey(url, type), result);
                    reject(result.error);
                }
                return;
            }

            ImageValidator.axios.head(url)
                .then(response => {
                    // Check response status code
                    if (response.status !== 200) {
                        let errorMsg = '';
                        switch (type) {
                            case ImageType.BACKGROUND:
                                errorMsg = COMMON_ERROR_MESSAGES.BACKGROUND_IMAGE_NOT_OPENABLE_HTTP_NOT_OK;
                                break;
                            case ImageType.ICON:
                            case ImageType.APP_NOTIFICATION:
                            default:
                                errorMsg = COMMON_ERROR_MESSAGES.DOMAIN_ILLUSTRATION_IMAGE_NOT_OPENABLE_HTTP_NOT_OK;
                                break;
                        }
                        const error = new Error(errorMsg);
                        this.cache.set(this.generateKey(url, type), { valid: false, error });
                        reject(error);
                        return;
                    }

                    // Image must be a valid file format
                    if (!allowedContentTypes.includes(response.headers['content-type'])) {
                        let errorMsg = '';
                        switch (type) {
                            case ImageType.BACKGROUND:
                                errorMsg = COMMON_ERROR_MESSAGES.BACKGROUND_IMAGE_LINK_NOT_SUPPORTED_TYPE;
                                break;
                            case ImageType.APP_NOTIFICATION:
                                errorMsg = COMMON_ERROR_MESSAGES.DMPS_NOTIFICATION_IMAGE_LINK_NOT_SUPPORTED_TYPE;
                                break;
                            case ImageType.ICON:
                            default:
                                errorMsg = COMMON_ERROR_MESSAGES.DOMAIN_ILLUSTRATION_IMAGE_LINK_NOT_SUPPORTED_TYPE;
                                break;
                        }
                        const error = new Error(errorMsg);
                        this.cache.set(this.generateKey(url, type), { valid: false, error });
                        reject(error);
                        return;
                    }

                    // Image cannot exceed max size
                    if (response.headers['content-length'] > maxImageSize) {
                        let errorMsg = '';
                        switch (type) {
                            case ImageType.BACKGROUND:
                                errorMsg = COMMON_ERROR_MESSAGES.BACKGROUND_IMAGE_SIZE_TOO_LARGE;
                                break;
                            case ImageType.ICON:
                                errorMsg = COMMON_ERROR_MESSAGES.DOMAIN_ILLUSTRATION_IMAGE_SIZE_TOO_LARGE_TEMPLATE.replace('%s', (maxImageSize / 1e6).toString());
                                break;
                            case ImageType.APP_NOTIFICATION:
                            default:
                                errorMsg = COMMON_ERROR_MESSAGES.DOMAIN_ILLUSTRATION_IMAGE_SIZE_TOO_LARGE_TEMPLATE.replace('%s', (maxImageSize / 1e6).toString());
                                break;
                        }
                        const error = new Error(errorMsg);
                        this.cache.set(this.generateKey(url, type), { valid: false, error });
                        reject(error);
                        return;
                    }

                    this.cache.set(this.generateKey(url, type), { valid: true });
                    resolve();
                    return;
                }).catch(onError => {
                    let errorMsg = '';
                    switch (type) {
                        case ImageType.BACKGROUND:
                            errorMsg = COMMON_ERROR_MESSAGES.BACKGROUND_IMAGE_NOT_OPENABLE_DUE_TO_NETWORK_ERROR;
                            break;
                        case ImageType.ICON:
                        case ImageType.APP_NOTIFICATION:
                        default:
                            errorMsg = COMMON_ERROR_MESSAGES.DOMAIN_ILLUSTRATION_IMAGE_NOT_OPENABLE_DUE_TO_NETWORK_ERROR;
                            break;
                    }
                    const error = new Error(errorMsg);
                    this.cache.set(this.generateKey(url, type), { valid: false, error });
                    reject(error);
                });
        });
    }

    /**
     * Performs basic validation on icon url
     * <nl>
     * To be valid, a url must be:
     * <ol>
     *  <li> Non-Empty and Non-Null
     *  <li> Not malformed (i.e. <code> new URL(url) </code> must not throw an error)
     *  <li> A HEAD request to the url returns HTTP 200
     *  <li> A HEAD request to the url returns the header "content-type" == "image/png"
     *  <li> A HEAD request to the url returns the header "content-length" < MAX_SIZE_BYTES
     * </ol>
     * <nl>
     * <nl>
     * <em> Caching: </em>
     * The cache map is refreshed every CACHE_REFRESH_INTERVAL_MS (15 sec).
     * Blank URLs are not cached because no real validation effort is involved.
     * Only validation results are cached.
     */
    static isIconValid(url?: string, allowDvp?: boolean) {
        if (url) {
            return this.commonImageValidation(url, ImageType.ICON, !!allowDvp);
        }
        else {
            return new Promise<void>((resolve, reject) => {
                reject(new Error(COMMON_ERROR_MESSAGES.DOMAIN_ILLUSTRATION_LINK_CANT_BE_EMPTY));
                return;
            });
        }
    }

    /**
     * Performs basic validation on background image url
     * <nl>
     * To be valid, a url must be:
     * <ol>
     *  <li> Not malformed (i.e. <code> new URL(url) </code> must not throw an error)
     *  <li> A HEAD request to the url returns HTTP 200
     *  <li> A HEAD request to the url returns the header "content-type" == "image/png"
     *  <li> A HEAD request to the url returns the header "content-length" < MAX_SIZE_BYTES
     * </ol>
     * <nl>
     * <nl>
     * <em> Caching: </em>
     * The cache map is refreshed every CACHE_REFRESH_INTERVAL_MS (15 sec).
     * Blank URLs are not cached because no real validation effort is involved.
     * Only validation results are cached.
     */
    static isBackgroundImageValid(url?: string, allowDvp?: boolean) {
        if (url) {
            return this.commonImageValidation(url, ImageType.BACKGROUND, !!allowDvp);
        }
        else {
            return new Promise<void>((resolve, reject) => {
                resolve();
                return;
            });
        }
    }

    static isNotificationImageValid(url?: string) {
        if (url) {
            return this.commonImageValidation(url, ImageType.APP_NOTIFICATION, false, this.DMPS_ALLOWED_CONTENT_TYPES, this.DMPS_MEDIA_BYTES_LIMIT, DMPS_IMAGE_URL_HOST_ALLOW_LIST);
        } else {
            return new Promise<void>((resolve, reject) => {
                resolve();
                return;
            });
        }
    }
}
