import * as React from 'react';
import {
    Input,
    Select,
    Button,
    TokenGroup,
    Checkbox,
    Alert,
    Popover
} from '@amzn/awsui-components-react';
import { Autosuggest } from '@amzn/awsui-components-react';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import {
    IOpportunityTrigger,
    ISkillIdTrigger,
    buildIntentTrigger,
    buildSkillIdTrigger,
    buildSkillCategoryTrigger,
    ISkillCategoryTrigger,
    IIntentTrigger,
    buildKeyValueTarget,
    IIntentTarget,
    IntentTargetType,
    buildSlotTarget,
    buildPublisherDataTarget,
    buildPhaticOrQAIntentTrigger,
    IQAOrPhaticIntentTrigger,
    MatchingMethod,
    buildFrictionTrigger,
    IFrictionTrigger,
    buildPromptDetails
} from '../../models/NluOpportunityTrigger';
import { TriggerIntentOptions } from './NluIntentOptions';
import { IExperienceSlot } from '../../models/ExperienceSlot';
import { ITargetPublisherData } from '../../models/TargetPublisherData';
import { Flashbar } from '@amzn/awsui-components-react';
import _, { isNumber } from 'lodash';
import { CifMetricDDBNames, IRawCifMetricsData } from '../../models/CifMetrics';
import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import { interruptionRateThreshold, negativeFeedbackThreshold } from '../../constants/dialUpCriteriaConstant';
import { InputWrapper } from './InputWrapper';
import { ExternalLink } from './LinkComponents';
import { FrictionTriggerPromptDetailsView } from './FrictionTriggerPromptDetailsView';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { FRICTION_TRIGGERED_CIF_USER_GROUP } from '../../constants/userGroups';
import { PromptDetails } from '../../models/PromptDetails';

/* Select Const(s) */

export const PersonalityIntents = ['GreetingsIntent', 'PleasantryIntent', 'FarewellIntent', 'AboutAlexaQAIntent', 'SubjectiveQAIntent'];

export const PersonalityIntentsForAdditionalApproval = ['GreetingsIntent', 'PleasantryIntent', 'FarewellIntent', 'AboutAlexaQAIntent'];
export const MusicIntentsForAdditionalApproval = ['PlayMusicIntent', 'PlayStationIntent', 'AddToPlaylistIntent', 'SetMusicNotificationIntent', 'ExpressMusicPreferenceIntent',
    'ExpressPreferenceIntent', 'FindMusicIntent', 'CreatePlaylistIntent', 'SetMusicTypeIntent', 'CreateMusicLookoutIntent', 'MusicControlIntent',
    'EditMusicNotificationIntent', 'CreateStationIntent', 'DeleteMusicLookoutIntent', 'GetMusicDetailsIntent', 'BuyMusicIntent', 'SelectMusicIntent'];
export const NewsIntentsForAdditionalApproval = ['DailyBriefingIntent'];
export const CustomIntentsForAdditionalApproval = ['PlaybackFinishedEvent','DailyBriefingIntent.SpeechFinishedEvent','DailyBriefingIntent.TrendingStoryPlaybackFinishedEvent','BrowseNotificationIntent.NotificationsFinishedEvent'];

export const IntentToGroupMap = (intent: string) => {
    if (PersonalityIntentsForAdditionalApproval.includes(intent)) {
        return 'the Personality Group';
    }
    else if (MusicIntentsForAdditionalApproval.includes(intent)) {
        return 'the Music Group';
    }
    else if (NewsIntentsForAdditionalApproval.includes(intent)) {
        return 'the News Group';
    }
    else {
        return '';
    }
};

export const IsCustomIntent = (intent: string) => {
    return (CustomIntentsForAdditionalApproval.includes(intent));
};

export const InclusionTriggerOptions = [
    {
        'label': 'Intent/Custom Event',
        'id': 'Intent',
        'description': 'The customer’s request, ex. PlayMusicIntent or PlaybackFinishedEvent'
    },
    {
        'label': 'Utterance Intent',
        'id': 'QAIntentOrPhaticIntent',
        'description': 'QAIntent, PhaticIntent'
    },
    {
        'label': 'Skill category',
        'id': 'SkillCategory',
        'description': 'A group of skills, ex. Games'
    },
    {
        'label': 'Skill ID',
        'id': 'SkillID',
        'description': 'ex. Jeoporady’s skill ID: asin:hdiohisoh8au8u3e'
    },
    {
        'label': 'Friction',
        'id': 'FrictionTrigger',
        'description': 'Friction Triggered CIF',
        'disabled': true
    }
] as Select.Option[];

export const QuickCreateInclusionTriggerOptions = [
    {
        'label': 'Intent/Custom Event',
        'id': 'Intent',
        'description': 'The customer’s request, ex. PlayMusicIntent or PlaybackFinishedEvent'
    },
    {
        'label': 'Utterance Intent',
        'id': 'QAIntentOrPhaticIntent',
        'description': 'QAIntent, PhaticIntent'
    }
] as Select.Option[];

export const ExclusionTriggerOptions = [
    {
        'label': 'Intent',
        'id': 'Intent',
        'description': 'The customer’s request, ex. PlayMusicIntent'
    }
] as Select.Option[];

export const IntentInclusionTargetOptions = [
    {
        'label': 'Slot name',
        'id': 'SlotName',
    },
    {
        'label': 'Publisher data key',
        'id': 'PublisherDataKey',
    },
    {
        'label': 'Skill category',
        'id': 'SkillCategory',
    },
    {
        'label': 'Specified skill',
        'id': 'SpecifiedSkill',
    }
] as Select.Option[];

export const IntentInclusionTargetOptionsPersonalityIntent = [
    {
        'label': 'Publisher data key',
        'id': 'PublisherDataKey',
    },
    {
        'label': 'Skill category',
        'id': 'SkillCategory',
    },
    {
        'label': 'Specified skill',
        'id': 'SpecifiedSkill',
    }
] as Select.Option[];

export const IntentExclusionTargetOptions = [
    {
        'label': 'Slot name',
        'id': 'SlotName',
    },
    {
        'label': 'Publisher data key',
        'id': 'PublisherDataKey',
    }
] as Select.Option[];


export const UtteranceIntentOptions = [
    {
        'label': 'QAIntent',
        'id': 'QAIntent',
    },
    {
        'label': 'PhaticIntent',
        'id': 'PhaticIntent',
    }
] as Select.Option[];

export const UtteranceTypeOptions = [
    {
        'label': 'Question',
        'id': 'Question',
    },
    {
        'label': 'Interpretation from Evi',
        'id': 'Interpretation',
    },
] as Select.Option[];

export const UtteranceMatchOptions = [
    {
        'label': 'Exact match',
        'description': 'Will trigger on exact utterance',
        'id': 'ExactMatch',
    },
    {
        'label': 'Prefix',
        'description': 'The beginning of an utterance',
        'id': 'Prefix',
    },
    {
        'label': 'Suffix',
        'description': 'The end of an utterance',
        'id': 'Suffix',
    },
    {
        'label': 'Contains',
        'description': 'Utterance contains this phrase',
        'id': 'Contains',
    }
] as Select.Option[];

export const FRICTION_PROMPT_DETAILS_LIMIT = 10;

/* Main Nlu Opportunity TriggeringSingle Intent View */

const NluOpportunityTriggeringSingleIntentView = (props: {
    trigger: IOpportunityTrigger,
    type: 'inclusion' | 'exclusion',
    index: number,
    arrayKey: string,
    updateOpportunityTriggering: (input: IOpportunityTrigger) => void,
    disableInput?: boolean,
    isQuickCreateWorkflow?: boolean,
    isVisualCIF?: boolean,
    cxWeeklyMetrics?: IRawCifMetricsData[]
    permissions?: string[]
    updateCandidate?: ExperienceUpdateCandidate
    isEditing?: boolean
    experience?: IFlattenedExperience
}) => {
    const { trigger, type, index, arrayKey, updateOpportunityTriggering, disableInput, isVisualCIF, isQuickCreateWorkflow,
        cxWeeklyMetrics, permissions, updateCandidate, isEditing, experience } = props;

    const operatorGroup = ((isEditing && updateCandidate)
            ? updateCandidate.getBasicInfo().groupImpressions
            : experience?.operatorGroup) || '';

    const disabled = (disableInput === undefined) ? false : disableInput;
    const triggerCopy: IOpportunityTrigger = ExperienceUpdateCandidate.deepClone(trigger);
    const hasGenericQAIntentAlert = 'Warning: QAIntent is a broad intent and may result in non-relevant suggestions. Please consider targeting sub-intents of QAIntent or targeting specific utterances.';
    const customTriggerIntentWarning = `Experience with this trigger intent will not be automatically dialed up after it reaches Live 50%, for it is triggering a custom CIF event and doesn't have sufficient AlexaLab engagement metrics.
        Please apply for an exception for dialing up to 100% by providing evidence of increased actions or engagement for the capability or domain that is being featured through your own offline analysis as supporting evidence.`;
    const exactSlotNameMatchingFlashBarItems = [
        {
            'type': 'warning',
            'dismissible': false,
            'content': 'Checking this may restrict your trigger criteria and result in reduced target customer size, to learn more: https://tiny.amazon.com/15mn60rl0/wamazbinviewAlexDebuHEx'
        }
    ];

    if (type === 'inclusion' && operatorGroup === FRICTION_TRIGGERED_CIF_USER_GROUP && permissions?.includes('OPERATOR')) {
        const frictionIndex = InclusionTriggerOptions.findIndex(x => x.id === 'FrictionTrigger');
        InclusionTriggerOptions[frictionIndex].disabled = false;
    }

    const calculateCXMetricAverage = (intentName: string, selectType: CifMetricDDBNames): number | string => {
        if (!cxWeeklyMetrics || intentName === '') return '-';

        const regex = new RegExp('^' + intentName + '$', 'i');
        const keys = Object.keys(cxWeeklyMetrics[0].intentMetrics).filter(key => regex.test(key.split('-')[0]));

        if (keys.length === 0) return '-';

        let average = 0;
        let countNonZero = 0;
        keys.forEach(key => {
            let eachData: (number | undefined);
            if (selectType === CifMetricDDBNames.InterruptionRate) {
                eachData = cxWeeklyMetrics[0].intentMetrics[key][0].data.interruption_rate;
            } else if (selectType === CifMetricDDBNames.NegativeFeedback) {
                eachData = cxWeeklyMetrics[0].intentMetrics[key][0].data.negative_feedback;
            } else if (selectType === CifMetricDDBNames.NumberOfCampaigns) {
                eachData = cxWeeklyMetrics[0].intentMetrics[key][0].data.number_of_campaigns;
            } else if (selectType === CifMetricDDBNames.AvgImpressionPerCampaign) {
                eachData = cxWeeklyMetrics[0].intentMetrics[key][0].data.avg_impression_per_campaign;
            }
            average += eachData ? eachData : 0;
            countNonZero += (eachData && eachData !== 0) ? 1 : 0;
        });
        average /= countNonZero;

        return average;
    };
    const avgIR = calculateCXMetricAverage((triggerCopy as IIntentTrigger).bluIntent ? (triggerCopy as IIntentTrigger).bluIntent.trim() : '', CifMetricDDBNames.InterruptionRate);
    const avgUNFR = calculateCXMetricAverage((triggerCopy as IIntentTrigger).bluIntent ? (triggerCopy as IIntentTrigger).bluIntent.trim() : '', CifMetricDDBNames.NegativeFeedback);
    const avgNumOfCampaign = calculateCXMetricAverage((triggerCopy as IIntentTrigger).bluIntent ? (triggerCopy as IIntentTrigger).bluIntent.trim() : '', CifMetricDDBNames.NumberOfCampaigns);
    const avgAvgImpressionPerCampaign = calculateCXMetricAverage((triggerCopy as IIntentTrigger).bluIntent? (triggerCopy as IIntentTrigger).bluIntent.trim(): '',
        CifMetricDDBNames.AvgImpressionPerCampaign);
    const irPassThreshold = avgIR >= interruptionRateThreshold;
    const unfrPassThreshold = avgUNFR >= negativeFeedbackThreshold;

    return <div>
        <div className='awsui-grid'>

            <div className='awsui-row'>
                <div className='awsui-util-font-size-3 awsui-util-p-m awsui-util-mb-m'>Trigger {index + 1}</div>
            </div>

            {/* First Row of Display Content */}
            <div className='awsui-row awsui-util-no-gutters'>
                <div className='col-1 awsui-util-t-r awsui-util-pt-xs awsui-util-pr-xs'>
                    <strong>Trigger</strong>
                </div>
                <div className='col-3'>
                    <Select
                        options={type === 'inclusion' ?
                            (isQuickCreateWorkflow ? QuickCreateInclusionTriggerOptions : InclusionTriggerOptions) : ExclusionTriggerOptions}
                        disabled={disabled}
                        id={`experience.select-trigger-${type}-${arrayKey}`}
                        placeholder={'Select'}
                        selectedId={trigger.opportunityTrigger}
                        className='awsui-util-pl-s'
                        onChange={(e) => {
                            let selectedTrigger;
                            switch (e.detail.selectedId) {
                                case 'QAIntentOrPhaticIntent':
                                    selectedTrigger = buildPhaticOrQAIntentTrigger();
                                    break;
                                case 'SkillCategory':
                                    selectedTrigger = buildSkillCategoryTrigger();
                                    break;
                                case 'SkillID':
                                    selectedTrigger = buildSkillIdTrigger();
                                    break;
                                case 'FrictionTrigger':
                                    selectedTrigger = buildFrictionTrigger();
                                    break;
                                default:
                                    selectedTrigger = buildIntentTrigger();
                            }
                            updateOpportunityTriggering(selectedTrigger);
                        }} />
                </div>

                {/* CIF Intent Flow*/}
                {(triggerCopy.opportunityTrigger === 'Intent') &&
                    <div className='col-8'>
                        <div className='awsui-row awsui-util-no-gutters'>
                            <div className='col-9 awsui-util-pl-s'>
                                {isVisualCIF ?
                                    <InputWrapper
                                        readonly={disabled}
                                        validate={() => true}
                                        id={`experience.select-intent-${type}-${arrayKey}`}
                                        value={(triggerCopy as IIntentTrigger).bluIntent.trim()}
                                        onInput={(inputString: string) => {
                                            const intentTrigger = triggerCopy as IIntentTrigger;
                                            intentTrigger.bluIntent = inputString;
                                            updateOpportunityTriggering(triggerCopy);
                                        }}/>
                                    :
                                    <Autosuggest
                                        options={TriggerIntentOptions()}
                                        disabled={disabled}
                                        id={`experience.select-intent-${type}-${arrayKey}`}
                                        controlId={`experience.intent-${type}-${arrayKey}`}
                                        value={(triggerCopy as IIntentTrigger).bluIntent.trim()}
                                        onChange={({ detail }: any) => {
                                            const intentTrigger = triggerCopy as IIntentTrigger;
                                            intentTrigger.bluIntent = detail.value;
                                            updateOpportunityTriggering(triggerCopy);
                                        }}/>
                                    }
                                {!isVisualCIF &&
                                    <li>Frequently used Trigger Intents listed above. This list is not exhaustive, more info <ExternalLink href='https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+CIF+Experience+Setup#Workflows-1739990606'>here</ExternalLink>.</li>}
                                {IntentToGroupMap((triggerCopy as IIntentTrigger).bluIntent) && type === 'inclusion' &&
                                    <li style={{ color: 'red' }}>This intent requires additional approval by {IntentToGroupMap((triggerCopy as IIntentTrigger).bluIntent)}.</li>}
                                {IsCustomIntent((triggerCopy as IIntentTrigger).bluIntent) &&
                                    <Alert>
                                        {customTriggerIntentWarning}
                                     </Alert>}
                                {(triggerCopy as IIntentTrigger).bluIntent === 'QAIntent' && (triggerCopy as IIntentTrigger).intentTargets.length === 0 && <Alert
                                    visible={true}
                                    type='warning'
                                > {hasGenericQAIntentAlert}
                                </Alert>}
                            </div>
                            <div className='col-3 awsui-util-pl-s'>
                                <Button
                                    id={`experience.add-intent-condition-${type}-${arrayKey}`}
                                    disabled={disabled}
                                    icon='add-plus'
                                    className='col-8'
                                    onClick={() => {
                                        const intentTrigger = triggerCopy as IIntentTrigger;
                                        intentTrigger.intentTargets.push(buildSlotTarget());
                                        updateOpportunityTriggering(triggerCopy);
                                    } }>
                                    Condition
                                </Button>
                            </div>
                        </div>
                    </div>
                }

                {(triggerCopy.opportunityTrigger === 'FrictionTrigger') &&
                    <div className='col-8'>
                        <div className='awsui-row awsui-util-no-gutters'>
                            <div className='col-8 awsui-util-pl-s'>
                                <Autosuggest
                                    options={TriggerIntentOptions()}
                                    disabled={disabled}
                                    id={`experience.select-intent-${type}-${arrayKey}`}
                                    controlId={`experience.intent-${type}-${arrayKey}`}
                                    value={(triggerCopy as IIntentTrigger).bluIntent.trim()}
                                    onChange={({ detail }: any) => {
                                        const intentTrigger = triggerCopy as IIntentTrigger;
                                        intentTrigger.bluIntent = detail.value;
                                        updateOpportunityTriggering(triggerCopy);
                                    }}/>
                            </div>
                            <div className='col-4 awsui-util-pl-s'>
                                <Button
                                    id={`experience.add-prompt-details-${type}-${arrayKey}`}
                                    disabled={disabled || (triggerCopy as IFrictionTrigger).frictionPromptDetails.length === FRICTION_PROMPT_DETAILS_LIMIT}
                                    icon='add-plus'
                                    onClick={() => {
                                        const intentTrigger = triggerCopy as IFrictionTrigger;
                                        intentTrigger.frictionPromptDetails.push(buildPromptDetails());
                                        updateOpportunityTriggering(triggerCopy);
                                    } }>
                                    Prompt Details
                                </Button>
                            </div>
                        </div>
                    </div>
                }

                {/* QA/Phatic(Utterance) Intent Flow */}
                {(triggerCopy.opportunityTrigger === 'QAIntentOrPhaticIntent') &&
                    <div className='col-8'>
                        <div className='awsui-row awsui-util-no-gutters'>
                            <div className='col-9 awsui-util-pl-s'>
                                <Select
                                    options={UtteranceIntentOptions}
                                    id={`experience.qa-phatic-intent-${type}-${arrayKey}`}
                                    disabled={disabled}
                                    controlId={`experience.qa-phatic-intent-${type}-${arrayKey}`}
                                    placeholder={'Select Intent'}
                                    selectedId={(triggerCopy as IQAOrPhaticIntentTrigger).bluIntent}
                                    onChange={(e) => {
                                        const qaOrPhaticIntentTrigger = triggerCopy as IQAOrPhaticIntentTrigger;
                                        qaOrPhaticIntentTrigger.bluIntent = e.detail.selectedId;
                                        updateOpportunityTriggering(triggerCopy);
                                    }} />
                                {(triggerCopy as IQAOrPhaticIntentTrigger).bluIntent === 'QAIntent' && _.isEmpty((triggerCopy as IQAOrPhaticIntentTrigger).matchingTargets[0]) && <Alert
                                    visible={true}
                                    type='warning'
                                > {hasGenericQAIntentAlert}
                                </Alert>}
                            </div>
                            <div className='col-3 awsui-util-pl-s'>
                                <Button
                                    id={`experience.add-utterance-matcher-qa-phatic-${type}-${arrayKey}`}
                                    disabled={disabled}
                                    icon='add-plus'
                                    onClick={() => {
                                        const qaOrPhaticIntentTrigger = triggerCopy as IQAOrPhaticIntentTrigger;
                                        qaOrPhaticIntentTrigger.matchingTargets.push({});
                                        updateOpportunityTriggering(triggerCopy);
                                    }}>
                                    Add target utterance
                                </Button>
                            </div>
                        </div>
                    </div>
                }
            </div>

            {/* Second Row */}
            {/* Friction Prompt Details */}
            {(triggerCopy.opportunityTrigger === 'FrictionTrigger') &&
                (triggerCopy as IFrictionTrigger).frictionPromptDetails.map((promptDetails, detailsIndex) => (
                    <FrictionTriggerPromptDetailsView
                        disabled={disabled}
                        key={`friction_prompt_details_${detailsIndex}`}
                        frictionTrigger={triggerCopy as IFrictionTrigger}
                        promptDetailsIndex={detailsIndex}
                        updatePromptDetails={(d: PromptDetails) => {
                            const frictionTrigger = triggerCopy as IFrictionTrigger;
                            if (frictionTrigger.frictionPromptDetails[detailsIndex]) {
                                frictionTrigger.frictionPromptDetails[detailsIndex] = d;
                            }
                            updateOpportunityTriggering(triggerCopy);
                        }}
                        onPromptDetailsRemove={() => {
                            const frictionTrigger = triggerCopy as IFrictionTrigger;
                            frictionTrigger.frictionPromptDetails.splice(detailsIndex, 1);
                            updateOpportunityTriggering(triggerCopy);
                        }}
                    />
                ))
            }

            {/* Intent Metrics */}
            {(triggerCopy.opportunityTrigger === 'Intent') &&
                <div className='awsui-row awsui-util-no-gutters awsui-util-mt-s'>
                    <div className='col-1 awsui-util-t-c awsui-util-pt-l awsui-util-pl-s'>
                        <strong>Intent Metrics</strong>
                    </div>
                    {(irPassThreshold && unfrPassThreshold)
                        ? <Alert type='error' className='awsui-util-f-l awsui-util-pl-s awsui-util-pr-l'><strong>Warning: </strong>IR/UNFR is at or above threshold (7%/1.9%)</Alert>
                        : irPassThreshold
                            ? <Alert type='error' className='awsui-util-f-l awsui-util-pl-s awsui-util-pr-l'><strong>Warning: </strong>IR is above threshold (7%)</Alert>
                            : unfrPassThreshold
                                ? <Alert type='error' className='awsui-util-f-l awsui-util-pl-s awsui-util-pr-l'><strong>Warning: </strong>UNFR is above threshold (1.9%)</Alert>
                                : (avgIR === '-' && avgUNFR === '-')
                                    ? <></>
                                    : <Alert type='success' className='awsui-util-f-l awsui-util-pl-s awsui-util-pr-l'>IR/UNFR is below threshold (7%/1.9%)</Alert>
                    }
                    <div className='col-6 awsui-util-pl-xl awsui-util-pr-xl awsui-util-pt-s'>
                        <div className='awsui-row'>
                            <div className='col-2'>
                                <Popover
                                    className='awsui-util-label'
                                    position='top'
                                    content='Trailing 4 week average across providers for campaigns in selected marketplace'
                                    header={<h2>Interruption Rate</h2>}
                                    >
                                    <div>IR</div>
                                </Popover>
                                <div className={irPassThreshold ? 'awsui-util-pt-xs awsui-util-status-negative' : 'awsui-util-pt-xs'}>
                                    {isNumber(avgIR) ? ((avgIR * 100).toFixed(1) + '%') : avgIR
                                }</div>
                            </div>
                            <div className='col-2'>
                                <Popover
                                    className='awsui-util-label'
                                    position='top'
                                    content='Trailing 4 week average across providers for campaigns in selected marketplace'
                                    header={<h2>Unprompted Negative Feedback Rate</h2>}
                                    >
                                    <div>UNFR</div>
                                </Popover>
                                <div className={unfrPassThreshold ? 'awsui-util-pt-xs awsui-util-status-negative' : 'awsui-util-pt-xs'}>
                                    {isNumber(avgUNFR) ? ((avgUNFR * 100).toFixed(2) + '%') : avgUNFR}
                                </div>
                            </div>
                            <div className='col-3'>
                                <Popover
                                    className='awsui-util-label'
                                    position='top'
                                    content='Trailing 4 week average of number of campaigns across providers using this intent in selected marketplace'
                                    >
                                    <div>Competition</div>
                                </Popover>
                                <div className='awsui-util-pt-xs'>
                                    {isNumber(avgNumOfCampaign)
                                        ? Math.round(avgNumOfCampaign) + (Math.round(avgNumOfCampaign) > 1 ? ' campaigns' : ' campaign')
                                        : '-'
                                    }
                                </div>
                            </div>
                            <div className='col-4'>
                                <Popover
                                    className='awsui-util-label'
                                    position='top'
                                    content='Trailing 4 week average of impressions across providers using this intent in selected marketplace'
                                    >
                                    <div>Avg. impressions per campaign</div>
                                </Popover>
                                <div className='awsui-util-pt-xs'>
                                    {isNumber(avgAvgImpressionPerCampaign)
                                        ? Math.round(avgAvgImpressionPerCampaign).toLocaleString()
                                        : '-'
                                    }
                                </div>
                            </div>
                            </div>
                    </div>
                </div>
            }

            {/* Skill Category Flow */}
            {(triggerCopy.opportunityTrigger === 'SkillCategory') &&
                <div className='awsui-row awsui-util-no-gutters awsui-util-mt-s'>
                    <div className='col-1 awsui-util-t-r awsui-util-pt-xs awsui-util-pr-xs'>
                        <strong>Skill category</strong>
                    </div>
                    <div className='col-10 awsui-util-pl-s'>
                        <TokenGroupTargetView
                        key={`skill-category-${type}-${arrayKey}`}
                        id={`skill-category-${type}-${arrayKey}`}
                        disabled={disabled}
                        name='Skill Categories'
                        values={(triggerCopy as ISkillCategoryTrigger).skillCategory}
                        sampleText='Enter the skill category, ex. Games'
                        onChangeInput={(d) => {
                            const skillCatTrigger = triggerCopy as ISkillCategoryTrigger;
                            for (const value of d.value.split(/[,|]+/).map(v => v.trim())) {
                                if ((value !== '')
                                    && (!skillCatTrigger.skillCategory.includes(value))) {
                                    skillCatTrigger.skillCategory.push(value);
                                }
                            }
                            updateOpportunityTriggering(triggerCopy);
                        }}
                        onDismiss={(d) => {
                            const skillCatTrigger = triggerCopy as ISkillCategoryTrigger;
                            if (skillCatTrigger.skillCategory)
                                skillCatTrigger.skillCategory.splice(d.itemIndex, 1);
                            updateOpportunityTriggering(triggerCopy);
                        }} />
                    </div>
                </div>
            }
            {(triggerCopy.opportunityTrigger === 'SkillCategory') &&
                <div className='awsui-row awsui-util-no-gutters'>
                    <div className='col-10 offset-m-1 awsui-util-pl-s awsui-util-label'>
                        To specify multiple values, use delimiter '|' in string
                    </div>
                </div>
            }

            {/* Skill ID Flow */}
            {(triggerCopy.opportunityTrigger === 'SkillID') &&
                <div className='awsui-row awsui-util-no-gutters awsui-util-mt-s'>
                    <div className='col-1 awsui-util-t-r awsui-util-pt-xs awsui-util-pr-xs'>
                        <strong>Skill ID</strong>
                    </div>
                    <div className='col-10 awsui-util-pl-s'>
                        <TokenGroupTargetView
                            key={`skill-id-${type}-${arrayKey}`}
                            id={`skill-id-${type}-${arrayKey}`}
                            disabled={disabled}
                            name='Skill Ids'
                            values={(triggerCopy as ISkillIdTrigger).skillId}
                            sampleText='Enter the skill ID, ex. amzn1.ask.skill.e85155fd-beb4-4151-9f1a-b2319038'
                            onChangeInput={(d) => {
                                const skillTrigger = triggerCopy as ISkillIdTrigger;
                                for (const value of d.value.split(/[,|]+/).map(v => v.trim())) {
                                    if ((value !== '')
                                        && (!skillTrigger.skillId.includes(value))) {
                                        skillTrigger.skillId.push(value);
                                    }
                                }
                                updateOpportunityTriggering(triggerCopy);
                            }}
                            onDismiss={(d) => {
                                const skillTrigger = triggerCopy as ISkillIdTrigger;
                                if (skillTrigger.skillId)
                                    skillTrigger.skillId.splice(d.itemIndex, 1);
                                updateOpportunityTriggering(triggerCopy);
                            }} />
                    </div>
                </div>
            }
            {(triggerCopy.opportunityTrigger === 'SkillID') &&
                <div className='awsui-row awsui-util-no-gutters'>
                    <div className='col-10 offset-m-1 awsui-util-pl-s awsui-util-label'>
                        To specify multiple values, use delimiter '|' in string
                    </div>
                </div>
            }

            {/* Third Row of Content */}
            {/* CIF Intent Flow*/}
            {(triggerCopy.opportunityTrigger === 'Intent') && (triggerCopy as IIntentTrigger).intentTargets &&
                (triggerCopy as IIntentTrigger).intentTargets.map(
                    (intentTargetMapped, intentTargetIndex: number) =>
                        <IntentWrapperTriggerGroupView
                            intentTrigger={triggerCopy as IIntentTrigger}
                            type={type}
                            key={`intent-target-${type}-${arrayKey}-${intentTargetIndex}`}
                            intentGroupIndex={intentTargetIndex}
                            keyName={`intent-target-${type}-${arrayKey}-${intentTargetIndex}`}
                            valueName={`intent-target-value-${type}-${arrayKey}-${intentTargetIndex}`}
                            intentTarget={intentTargetMapped}
                            disabled={disabled}
                            updateIntentTarget={(d) => {
                                const intentTrigger = triggerCopy as IIntentTrigger;
                                if (intentTrigger.intentTargets[intentTargetIndex]) {
                                    intentTrigger.intentTargets[intentTargetIndex] = d;
                                }
                                updateOpportunityTriggering(triggerCopy);
                            }}
                            onIntentTargetRemove={() => {
                                const intentTrigger = triggerCopy as IIntentTrigger;
                                intentTrigger.intentTargets.splice(intentTargetIndex, 1);
                                updateOpportunityTriggering(triggerCopy);
                            }}
                        />
                )
            }
            {(type === 'inclusion') && (triggerCopy.opportunityTrigger === 'Intent') && (triggerCopy as IIntentTrigger).intentTargets &&
                <div className='awsui-row awsui-util-no-gutters awsui-util-pt-l'>
                    <div className='col-1'></div>
                    <div className='col-11 awsui-util-pl-s'>
                        {((triggerCopy as IIntentTrigger).exactSlotNameMatching && <Flashbar items={exactSlotNameMatchingFlashBarItems} />)}
                        <Checkbox
                            id={`experience.toggle-exact-matching-${arrayKey}`}
                            controlId={`experience.toggle-exact-matching-${arrayKey}`}
                            checked={(triggerCopy as IIntentTrigger).exactSlotNameMatching || false}
                            disabled={disabled}
                            onChange={(e) => {
                                const intentTrigger = triggerCopy as IIntentTrigger;
                                intentTrigger.exactSlotNameMatching = e.detail.checked;
                                updateOpportunityTriggering(triggerCopy);
                            }}
                        >Trigger only when all the slot conditions are met</Checkbox>
                    </div>
                </div>
            }

            {/* QA/Phatic(Utterance) Intent Flow */}
            {(triggerCopy.opportunityTrigger === 'QAIntentOrPhaticIntent') &&
                <div>
                    {(triggerCopy as IQAOrPhaticIntentTrigger).matchingTargets.map(
                        (matchingTarget, targetIndex: number) =>
                            <div key={`qa-phatic-${type}-${arrayKey}-${targetIndex}`}>
                                <div className='awsui-row awsui-util-no-gutters awsui-util-mt-xl'>
                                    <div className='col-1 awsui-util-t-r awsui-util-pr-xs'>
                                        <strong>Matching method</strong>
                                    </div>
                                    <div className='col-3 awsui-util-pl-s awsui-util-pt-xs'>
                                        <Select
                                            options={UtteranceMatchOptions}
                                            id={`experience.utterance-matcher-qa-phatic-${type}-${arrayKey}-${targetIndex}`}
                                            disabled={disabled}
                                            placeholder={'Select'}
                                            selectedId={matchingTarget.matchingMethod}
                                            onChange={(e) => {
                                                const qaOrPhaticIntentTrigger = triggerCopy as IQAOrPhaticIntentTrigger;
                                                qaOrPhaticIntentTrigger.matchingTargets[targetIndex].matchingMethod = e.detail.selectedId as MatchingMethod;
                                                updateOpportunityTriggering(triggerCopy);
                                            }} />
                                    </div>
                                </div>
                                <div className='awsui-row awsui-util-no-gutters awsui-util-mt-xs'>
                                    <div className='col-1 awsui-util-t-r awsui-util-pt-xs awsui-util-pr-xs'>
                                        <strong>Utterance</strong>
                                    </div>
                                    <div className='col-10 awsui-util-pl-s'>
                                        <Input
                                            id={`experience.qa-phatic-utterance-target-qa-phatic-${type}-${arrayKey}-${targetIndex}`}
                                            readonly={disabled}
                                            controlId={`experience.qa-phatic-utterance-target-qa-phatic-${type}-${arrayKey}-${targetIndex}`}
                                            value={matchingTarget.targetUtterance}
                                            onChange={(e) => {
                                                const qaOrPhaticIntentTrigger = triggerCopy as IQAOrPhaticIntentTrigger;
                                                qaOrPhaticIntentTrigger.matchingTargets[targetIndex].targetUtterance = e.detail.value;
                                                updateOpportunityTriggering(triggerCopy);
                                            }} />
                                    </div>
                                </div>
                                {targetIndex > 0 &&
                                    <div className='awsui-row awsui-util-no-gutters awsui-util-mt-xs'>
                                        <Button
                                            id={`experience.remove-utterance-matcher-qa-phatic-${type}-${arrayKey}-${targetIndex}`}
                                            disabled={disabled}
                                            icon='close'
                                            className='col-3 offset-m-1 awsui-util-pl-s'
                                            onClick={() => {
                                                const qaOrPhaticIntentTrigger = triggerCopy as IQAOrPhaticIntentTrigger;
                                                qaOrPhaticIntentTrigger.matchingTargets.splice(targetIndex, 1);
                                                updateOpportunityTriggering(triggerCopy);
                                            }}>
                                            Remove target utterance
                                        </Button>
                                    </div>
                                }
                            </div>
                    )}
                </div>
            }
        </div>
    </div>;
};

const mapStateToProps = ({ experienceDetailViewState, cxWeeklyMetricsState, authenticationState, experienceEditViewState }: AppState) => {
    return {
        cxWeeklyMetrics: cxWeeklyMetricsState.cxWeeklyMetrics,
        updateCandidate: experienceEditViewState.updateCandidate || undefined,
        permissions: authenticationState.permissions || [],
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined
    };
};

export default connect(mapStateToProps)(NluOpportunityTriggeringSingleIntentView);

/* Intent Specific Target View Wrapper */

interface IIntentWrapperTriggerGroupViewProps {
    intentTrigger: IIntentTrigger | IFrictionTrigger;
    intentGroupIndex?: number;
    intentTarget: IIntentTarget;
    type?: string;

    keyName: string;
    valueName: string;

    disabled: boolean;

    updateIntentTarget: (input: IIntentTarget) => void;
    onIntentTargetRemove: () => void;
}

export const IntentWrapperTriggerGroupView = (props: IIntentWrapperTriggerGroupViewProps) => {
    const { intentTrigger, intentGroupIndex, intentTarget, type, keyName, valueName, disabled, updateIntentTarget, onIntentTargetRemove } = props;

    let name: string;
    let values: string[];

    let onTargetInput: (detail: Input.ChangeDetail) => void;
    let onValueInput: (detail: Input.ChangeDetail) => void;
    let onToggleShowValues: () => void;
    let onValueDismiss: (detail: TokenGroup.DismissDetail) => void;

    switch (intentTarget.intentTargetType) {
        case 'SlotName':
            const slotTarget = intentTarget.intentTargetValue as IExperienceSlot;
            name = slotTarget.name;
            values = slotTarget.values;
            onTargetInput = (d) => {
                slotTarget.name = d.value;
                updateIntentTarget(intentTarget);
            };
            onToggleShowValues = () => {
                intentTarget.showValues = !intentTarget.showValues;

                // Remove all values if toggling OFF
                if (!intentTarget.showValues) {
                    slotTarget.values = [];
                }
                updateIntentTarget(intentTarget);
            };
            onValueInput = (d) => {
                for (const value of d.value.split(/[,|]+/).map(v => v.trim())) {
                    if ((value !== '')
                        && (!slotTarget.values.includes(value))) {
                        slotTarget.values.push(value);
                    }
                }
                updateIntentTarget(intentTarget);
            };
            onValueDismiss = (d) => {
                if (slotTarget.values)
                    slotTarget.values.splice(d.itemIndex, 1);
                updateIntentTarget(intentTarget);
            };
            break;
        case 'PublisherDataKey':
            const publisherDataTarget = intentTarget.intentTargetValue as ITargetPublisherData;
            name = publisherDataTarget.key;
            values = publisherDataTarget.values;
            onTargetInput = (d) => {
                publisherDataTarget.key = d.value;
                updateIntentTarget(intentTarget);
            };
            onToggleShowValues = () => {
                intentTarget.showValues = !intentTarget.showValues;

                // Remove all values if toggling OFF
                if (!intentTarget.showValues) {
                    publisherDataTarget.values = [];
                }
                updateIntentTarget(intentTarget);
            };
            onValueInput = (d) => {
                for (const value of d.value.split(/[,|]+/).map(v => v.trim())) {
                    if ((value !== '')
                        && (!publisherDataTarget.values.includes(value))) {
                        publisherDataTarget.values.push(value);
                    }
                }
                updateIntentTarget(intentTarget);
            };
            onValueDismiss = (d) => {
                if (publisherDataTarget.values)
                    publisherDataTarget.values.splice(d.itemIndex, 1);
                updateIntentTarget(intentTarget);
            };
            break;
        default:
            const valueTarget = intentTarget.intentTargetValue as string[];
            name = intentTarget.intentTargetType;
            values = valueTarget;
            // tslint:disable-next-line: no-empty
            onTargetInput = () => { };
            // tslint:disable-next-line: no-empty
            onToggleShowValues = () => { };
            onValueInput = (d) => {
                for (const value of d.value.split(/[,|]+/).map(v => v.trim())) {
                    if ((value !== '')
                        && (!valueTarget.includes(value))) {
                        valueTarget.push(value);
                    }
                }
                updateIntentTarget(intentTarget);
            };
            onValueDismiss = (d) => {
                if (valueTarget)
                    valueTarget.splice(d.itemIndex, 1);
                updateIntentTarget(intentTarget);
            };
    }

    let targetOptions = IntentExclusionTargetOptions;
    if (type === 'inclusion') {
        targetOptions = PersonalityIntents.includes(intentTrigger.bluIntent) ? IntentInclusionTargetOptionsPersonalityIntent : IntentInclusionTargetOptions;

        if (['QAIntent', 'PhaticIntent'].includes(intentTrigger.bluIntent)) {
            targetOptions = targetOptions.filter((value) => value.id !== 'SlotName');
        }
    }

    return <TriggerGroupView
        selectedTarget={intentTarget.intentTargetType}
        whereTargetOptions={targetOptions}
        groupIndex={intentGroupIndex}
        showValues={intentTarget.showValues}
        name={name}
        disabled={disabled}
        values={values}
        keyName={keyName}
        valueName={valueName}
        onTargetTypeChange={(d) => {
            switch (d.selectedId as IntentTargetType) {
                case 'SlotName':
                    updateIntentTarget(buildSlotTarget());
                    break;
                case 'PublisherDataKey':
                    updateIntentTarget(buildPublisherDataTarget());
                    break;
                default:
                    updateIntentTarget(buildKeyValueTarget(d.selectedId as IntentTargetType));
            }
        }}
        onToggleShowValues={onToggleShowValues}
        onTargetInput={onTargetInput}
        onTargetRemove={onIntentTargetRemove}
        onValueInput={onValueInput}
        onValueDismiss={onValueDismiss}
    />;
};

/* Token group wrapper to support simple string grouped triggers */

interface ITriggerGroupViewProps {
    selectedTarget?: string;
    whereTargetOptions: Select.Option[];

    groupIndex?: number;
    showValues: boolean;

    name?: string;
    values?: string[];
    keyName: string;
    valueName: string;
    disabled: boolean;

    onTargetTypeChange: (detail: Select.ChangeDetail) => void;
    onTargetInput: (detail: Input.ChangeDetail) => void;
    onTargetRemove: () => void;
    onToggleShowValues: () => void;
    onValueInput: (detail: Input.ChangeDetail) => void;
    onValueDismiss: (detail: TokenGroup.DismissDetail) => void;
}

const TriggerGroupView = (props: ITriggerGroupViewProps) => {
    const {
        selectedTarget, whereTargetOptions, showValues, name, values, keyName, valueName, disabled,
        onTargetTypeChange, onTargetInput, onTargetRemove, onToggleShowValues, onValueInput, onValueDismiss
    } = props;

    let label: string;
    let placeholder: string;
    switch (selectedTarget) {
        case 'SlotName':
            label = 'Slot Values';
            placeholder = 'Type in a slot value and hit enter or paste a list with commas';
            break;
        case 'PublisherDataKey':
            label = 'Publisher Values';
            placeholder = 'Type in a publisher value and hit enter or paste a list with commas';
            break;
        default:
            label = '';
            placeholder = '';
    }

    const delimitFalshbarItems = [
        {
            'type': 'info',
            'dismissible': true,
            'content': 'To specify multiple values, use delimiter \'|\' in string'
        }
    ];

    return <div>
        <div className='awsui-row awsui-util-no-gutters awsui-util-pt-l'>
            <div className='col-1 awsui-util-t-r awsui-util-pt-xs awsui-util-pr-xs'>
                <strong>Condition</strong>
            </div>
            <div className='col-3'>
                <Select
                    options={whereTargetOptions}
                    disabled={disabled}
                    id={'experience.customer-event-conditional'}
                    placeholder={'Select'}
                    selectedId={selectedTarget}
                    className='awsui-util-pl-s'
                    onChange={(e) => {
                        onTargetTypeChange(e.detail);
                    }}/>
            </div>

            {/* We only display key changes for Slot and Publisher Target Types */}
            {(!selectedTarget || selectedTarget === 'SlotName' || selectedTarget === 'PublisherDataKey') &&
                <Input
                    id={`experience.target-key-${keyName.replace(/\s/g, '-')}`}
                    readonly={disabled}
                    controlId={`experience.target-key-${keyName.replace(/\s/g, '-')}`}
                    className='col-5 awsui-util-pl-s'
                    value={name}
                    onInput={(e) => onTargetInput(e.detail)} />
            }
            {(!selectedTarget || selectedTarget === 'SlotName' || selectedTarget === 'PublisherDataKey') &&
                <Button
                    id={`experience.remove-target-${keyName.replace(/\s/g, '-')}`}
                    disabled={disabled}
                    icon={'close'}
                    onClick={() => onTargetRemove()} />
            }
            {(!selectedTarget || selectedTarget === 'SlotName' || selectedTarget === 'PublisherDataKey') &&
                <Button
                    id={`experience.show-target-values-${valueName.replace(/\s/g, '-')}`}
                    icon={'add-plus'}
                    disabled={disabled}
                    // className='col-2'
                    onClick={() => onToggleShowValues()} >
                    Value
                </Button>
            }

            {/* Handle skill category targets */}
            {selectedTarget === 'SkillCategory' &&
                <div className='col-5 awsui-util-pl-s'>
                    <Input
                        id={`experience.target-value-${valueName.replace(/\s/g, '-')}`}
                        readonly={disabled}
                        controlId={`experience.target-value-${valueName.replace(/\s/g, '-')}`}
                        value=''
                        placeholder='Enter the skill category, ex. Games'
                        onChange={(e) => onValueInput(e.detail)} />
                    <TokenGroup
                        id={`experience.token-group-${valueName.replace(/\s/g, '-')}`}
                        items={convertToItems(values || [])}
                        onDismiss={(e) => onValueDismiss(e.detail)} />
                    <div className='awsui-util-mt-s'><Flashbar items={delimitFalshbarItems} /></div>
                </div>
            }
            {selectedTarget === 'SkillCategory' &&
                <Button
                    id={`experience.remove-target-${keyName.replace(/\s/g, '-')}`}
                    disabled={disabled}
                    className='col-3'
                    icon={'close'}
                    onClick={() => onTargetRemove()} />
            }

            {/* Handle Skill ID targets */}
            {selectedTarget === 'SpecifiedSkill' &&
                <div className='col-5 awsui-util-pl-s'>
                    <Input
                        id={`experience.target-value-${valueName.replace(/\s/g, '-')}`}
                        controlId={`experience.target-value-${valueName.replace(/\s/g, '-')}`}
                        readonly={disabled}
                        value=''
                        placeholder='Enter the skill ID, ex. asin:skill893u438u493f'
                        onChange={(e) => onValueInput(e.detail)} />
                    <TokenGroup
                        id={`experience.token-group-${valueName.replace(/\s/g, '-')}`}
                        items={convertToItems(values || [])}
                        onDismiss={(e) => onValueDismiss(e.detail)} />
                </div>
            }
            {selectedTarget === 'SpecifiedSkill' &&
                <Button
                    id={`experience.remove-target-${keyName.replace(/\s/g, '-')}`}
                    disabled={disabled}
                    className='col-1'
                    icon={'close'}
                    onClick={() => onTargetRemove()} />
            }
        </div>

        {/* Display values for Slot and Publisher Target Types on a new row */}
        {(showValues && (selectedTarget === 'SlotName' || selectedTarget === 'PublisherDataKey')) &&
            <div className='awsui-row awsui-util-no-gutters awsui-util-pt-s'>
                {selectedTarget === 'SlotName' &&
                    <div className='col-1 awsui-util-t-r awsui-util-pt-xs awsui-util-pr-xs'>
                        <strong>{label}</strong>
                    </div>
                }
                {selectedTarget === 'PublisherDataKey' &&
                    <div className='col-1 awsui-util-pt-xs'>
                        <strong>{label}</strong>
                    </div>
                }
                <div className='col-8 awsui-util-pl-s'>
                    <Input
                        id={`experience.target-value-${valueName.replace(/\s/g, '-')}`}
                        controlId={`experience.target-value-${valueName.replace(/\s/g, '-')}`}
                        readonly={disabled}
                        value=''

                        placeholder={placeholder}
                        onChange={(e) => onValueInput(e.detail)} />
                    <TokenGroup
                        id={`experience.token-group-${valueName.replace(/\s/g, '-')}`}
                        items={convertToItems(values || [])}
                        onDismiss={(e) => onValueDismiss(e.detail)} />
                </div>
                <Button
                    id={`experience.hide-target-values-${valueName.replace(/\s/g, '-')}`}
                    disabled={disabled}
                    icon={'close'}
                    className='col-3'
                    onClick={() => onToggleShowValues()} />
            </div>
        }
    </div>;
};

/* Token group wrapper to support simple string grouped triggers */

interface ITokenGroupTargetViewProps {
    id: string;
    name: string;
    values?: string[];
    sampleText: string;
    disabled: boolean;
    onChangeInput: (detail: Input.ChangeDetail) => void;
    onDismiss: (detail: TokenGroup.DismissDetail) => void;
}

const TokenGroupTargetView = (props: ITokenGroupTargetViewProps) => {
    const { id, values, sampleText, disabled, onChangeInput, onDismiss } = props;
    const items = convertToItems(values || []);

    return <div>
        <Input
            id={`experience.token-group-input-${id}`}
            readonly={disabled}
            controlId={`experience.token-group-input-${id}`}
            value={''}
            placeholder={sampleText}
            onChange={(e) => onChangeInput(e.detail)} />
        <TokenGroup
            id={`experience.token-group-${id}`}
            items={items}
            onDismiss={(e) => onDismiss(e.detail)} />
    </div>;
};

/* Utility Methods */
const convertToItems = (values: string[]): TokenGroup.Item[] => {
    return values.map(value => {
        const item: TokenGroup.Item = {
            label: value
        };

        if (value.trim() !== value) {
            item.tags = ['WARNING: token has leading or trailing whitespace'];
        }
        return item;
    });
};
