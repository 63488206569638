import { Action, Dispatch } from 'redux';
import * as AcopsGateway from '../api/acopsGateway';
import { IExperience, IExperiencePage } from '../models/Experience';
import { UNSET_EXPERIENCES, REQUESTED_EXPERIENCES_PAGINATE, RECEIVED_EXPERIENCES_PAGINATE,
    ERROR_FETCHING_EXPERIENCES_PAGINATE, UPDATED_PROGRESS } from '../constants/experienceListViewActionTypes';

export interface ILoadExperiencesAction extends Action {
    experiences?: IExperience[];
    lastEvaluatedKey?: string;
    error?: Error;
    succeeded?: number;
    failed?: number;
}

export const requestedExperiencesPaginate = (): ILoadExperiencesAction => {
    return {
        type: REQUESTED_EXPERIENCES_PAGINATE
    };
};

// Stage needs to be passed in for generating link to Amazon Approval
export const receivedExperiencesPaginate = (experiences: IExperience[], lastEvaluatedKey?: string): ILoadExperiencesAction => {
    return {
        type: RECEIVED_EXPERIENCES_PAGINATE,
        lastEvaluatedKey,
        experiences
    };
};

export const errorFetchingExperiencesPaginate = (error: Error): ILoadExperiencesAction => {
    return {
        type: ERROR_FETCHING_EXPERIENCES_PAGINATE,
        error
    };
};

export const unsetExperiences = (): ILoadExperiencesAction => {
    return {
        type: UNSET_EXPERIENCES
    };
};

export const updatedProgressBar = (succeeded: number, failed: number): ILoadExperiencesAction => {
    return {
        type: UPDATED_PROGRESS,
        succeeded,
        failed
    };
};

export const getExperiencesPaginateAction = (limit: number, exclusiveStartKey?: string) => async (dispatch: Dispatch) => {
    if (!exclusiveStartKey) {
        dispatch(requestedExperiencesPaginate());
    }

    try {
        const data: IExperiencePage = await AcopsGateway.getExperiencesPaginate(limit, exclusiveStartKey);
        dispatch(receivedExperiencesPaginate(data.experiments, data.lastEvaluatedKey ? encodeURI(data.lastEvaluatedKey) : data.lastEvaluatedKey));
    } catch (error) {
        dispatch(errorFetchingExperiencesPaginate(error));
    }
};
