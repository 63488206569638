import * as React from 'react';
import { AppState } from '../../reducers/index';
import { useDispatch, useSelector } from 'react-redux';
import { FormField } from '@amzn/awsui-components-react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { pushImpressionTrackingSectionAction } from '../../actions/experienceEditViewActions';
import { InputWrapper } from '../../components/common/InputWrapper';
import { CommonValidator } from '../../util/CommonValidator';

interface RCPExperienceTrackingSectionViewProps {
    onRcpExperienceWeblabUpdate: (weblab?: string) => void;
}

export const RCPExperienceTrackingSectionView: React.FC<RCPExperienceTrackingSectionViewProps> = (props: RCPExperienceTrackingSectionViewProps) => {
    const { updateCandidate } = useSelector((state: AppState) => state.experienceEditViewState);
    const { onRcpExperienceWeblabUpdate } = props;
    const dispatch = useDispatch();

    const updateImpressionTracking = (weblab?: string) => {
        dispatch(pushImpressionTrackingSectionAction(weblab, undefined, undefined, undefined));
        if (onRcpExperienceWeblabUpdate) {
            onRcpExperienceWeblabUpdate(weblab);
        }
    };

    return <div>
        <FormSectionView title='Experience Tracking'>
            <div className='awsui-util-mb-m'>
                <FormField
                    label={<RequiredField fieldName='Launch Weblab' />}
                    description={'The weblab name associated with this experience.'}>
                    <InputWrapper
                        id='experience.weblab'
                        readonly={false}
                        placeholder='ex. ALEXA_FA_CIF_PODCAST_COMMUTE_GENERIC_263599'
                        value={updateCandidate?.getImpressionTracking().weblab}
                        onChange={(input: string) => updateImpressionTracking(input.trim())}
                        validate={CommonValidator.isWeblabValid}
                    />
                </FormField>
            </div>
        </FormSectionView>
    </div>;
};
