import * as React from 'react';
import { Wizard } from '@amzn/awsui-components-react';
import MobileHomeCardActivationDatesSectionView from '../sections/MobileHomeCardActivationDatesSectionView';
import MobileHomeCardCustomerTargetingSectionView from '../sections/MobileHomeCardCustomerTargetingSectionView';
import MobileHomeCardExperienceDetailsSectionView from '../sections/MobileHomeCardExperienceDetailsSectionView';
import MobileHomeCardDisplayElementsSectionView from '../sections/MobileHomeCardDisplayElementsSectionView';
import MobileHomeCardActionSectionView from '../sections/MobileHomeCardActionSectionView';
import MobileHomeCardPlatformSectionView from '../sections/MobileHomeCardPlatformSectionView';
import MobileHomeCardAbExperimentSectionView from '../sections/MobileHomeCardAbExperimentSectionView';

export const MobileHomeCardWorkflow = (
    onCardActionAndCharLimitCheckedUpdate: (cardActionChecked?: boolean, charLimitChecked?: boolean) => void,
): Wizard.Step[] => {

    return [
        {
            title: 'Mobile home card placement',
            content: <div>
                <MobileHomeCardActivationDatesSectionView />
            </div>
        },
        {
            title: 'Customer targeting ',
            content: <div>
                <MobileHomeCardCustomerTargetingSectionView />
            </div>
        },
        {
            title: 'Create experience',
            content: <div>
                <MobileHomeCardDisplayElementsSectionView onCardActionAndCharLimitCheckedUpdate={(cardActionChecked, charLimitChecked) =>
                    onCardActionAndCharLimitCheckedUpdate(cardActionChecked, charLimitChecked)} />
                <MobileHomeCardExperienceDetailsSectionView />
                <MobileHomeCardPlatformSectionView />
                <MobileHomeCardActionSectionView onCardActionAndCharLimitCheckedUpdate={(cardActionChecked, charLimitChecked) =>
                    onCardActionAndCharLimitCheckedUpdate(cardActionChecked, charLimitChecked)} />
            </div>
        },
        {
            title: 'Review experience',
            content: <div>
                <MobileHomeCardAbExperimentSectionView />
                <MobileHomeCardActivationDatesSectionView />
                <MobileHomeCardCustomerTargetingSectionView />
                <MobileHomeCardDisplayElementsSectionView onCardActionAndCharLimitCheckedUpdate={(cardActionChecked, charLimitChecked) =>
                    onCardActionAndCharLimitCheckedUpdate(cardActionChecked, charLimitChecked)} />
                <MobileHomeCardExperienceDetailsSectionView />
                <MobileHomeCardPlatformSectionView />
                <MobileHomeCardActionSectionView onCardActionAndCharLimitCheckedUpdate={(cardActionChecked, charLimitChecked) =>
                    onCardActionAndCharLimitCheckedUpdate(cardActionChecked, charLimitChecked)} />
            </div>
        }
    ];
};
