import { IConfigureAlexaLabAction } from '../actions/experienceDetailViewActions';
import {
    CONFIGURING_ALEXA_LAB_METRICS,
    CONFIGURED_ALEXA_LAB_METRICS,
    ERROR_CONFIGURE_ALEXA_LAB_METRICS,
    RESET_ALEXA_LAB_METRICS
} from '../constants/experienceDetailViewActionTypes';

export interface IConfigureAlexaLabMetricsState {
    alexaLabConfigResponse?: string;
    isLoading: boolean;
    error?: Error;
}

export const configureAlexaLabMetricsReducer = (
    state: IConfigureAlexaLabMetricsState = { isLoading: false },
    action: IConfigureAlexaLabAction
): IConfigureAlexaLabMetricsState => {
    switch (action.type) {
        case CONFIGURING_ALEXA_LAB_METRICS:
            return {
                ...state,
                isLoading: true
            };
        case CONFIGURED_ALEXA_LAB_METRICS:
            const { alexaLabConfigResponse } = action;
            return {
                ...state,
                isLoading: false,
                alexaLabConfigResponse
            };
        case RESET_ALEXA_LAB_METRICS:
            return {
                ...state,
                isLoading: false,
                alexaLabConfigResponse: undefined,
                error: undefined
            };
        case ERROR_CONFIGURE_ALEXA_LAB_METRICS:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};
