import * as React from 'react';
import { connect } from 'react-redux';
import { getOdysseyAnnouncementAction } from '../actions/odysseyAnnouncementActions';
import { getPermissionAction } from '../actions/authenticationActions';
import { getBypassPauseListAction } from '../actions/bypassPauseListActions';
import { getLinkableUrl, PAGE } from '../constants/page';
import { CIF_SETUP_WIKI, DEVICE_SETUP_WIKI, EXPERIENCE_EXAMPLE_LINK, ODYSSEY_LINK, PUSH_SETUP_WIKI, SURVEY_LINK, TROUBLESHOOTING_WIKI_LINK } from './Navigation';
import { AnnouncementSectionView } from './sections/OdysseyAnnouncementView';
import { HOME_CARD_APPROVERS_USER_GROUP, MOBILE_HOME_CARD_USER_GROUP, RCP_CREATORS_USER_GROUP } from '../constants/userGroups';

export const HomeCard = (props: { link: string, text: string, description: string, imageLink: string, externalLink?: boolean }) => {
    const { link, text, description, imageLink, externalLink } = props;

    return <div className='col-4'>
        <a rel='noopener noreferrer' href={link} style={{ textDecoration: 'none' }} target={externalLink ? '_blank' : ''}>
            <div className='awsui-util-container awsui-util-no-gutters awsui-util-mp-m awsui-util-p-l'>
                <div className='awsui-util-t-c awsui-util-mb-l'>
                    <img src={imageLink} alt='Odyssey Boat Logo' style={{
                        height: '120px',
                        width: '120px'
                    }} />
                </div>
                <div>
                    <h3 style={{ color: 'black' }}>{text}</h3>
                    <p className='awsui-util-font-size-1' style={{ color: 'gray', minHeight: '50px' }}>{description}</p>
                </div>
            </div>
        </a>
    </div>;
};

export class Home extends React.Component<{ dispatch: any }> {
    public componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getOdysseyAnnouncementAction());
        dispatch(getBypassPauseListAction());
        dispatch(getPermissionAction(HOME_CARD_APPROVERS_USER_GROUP));
        dispatch(getPermissionAction(MOBILE_HOME_CARD_USER_GROUP));
        dispatch(getPermissionAction(RCP_CREATORS_USER_GROUP));
    }

    public render() {
        return <div>
            <div className='awsui-util-t-c'>
                <img src='/landing-page-big-logo.svg' alt='Odyssey Boat Logo' style={{
                    height: '300px',
                    width: '750px'
                }} />
            </div>
            <div className='awsui-grid awsui-util-p-xl'>
                <div className='awsui-row'>
                    <div className='col-12'>
                        <AnnouncementSectionView />
                    </div>
                </div>
                <div className='awsui-row'>
                    <HomeCard
                        link={getLinkableUrl(PAGE.NewExperience)}
                        text='Create experience'
                        description='Create a CIF, push-notification, or device-notification experience'
                        imageLink='/create.svg'
                    />
                    <HomeCard
                        link={getLinkableUrl(PAGE.Experiences)}
                        text='Browse experiences'
                        description='Manage your experiences and take a look at experiences partner teams have created'
                        imageLink='/browse.svg'
                    />
                    <HomeCard
                        link={ODYSSEY_LINK}
                        text='Odyssey wikipedia'
                        description={'All about Odyssey\'s self-service product and FAQs'}
                        imageLink='/info.svg'
                        externalLink={true}
                    />
                </div>
                <div className='awsui-row'>
                    <HomeCard
                        link={CIF_SETUP_WIKI}
                        text='How-to: CIF experience setup'
                        description='Documentation and basics on how to set up a CIF experience'
                        imageLink='/how-to-CIF.svg'
                        externalLink={true}
                    />
                    <HomeCard
                        link={PUSH_SETUP_WIKI}
                        text='How-to: App experience setup'
                        description='Documentation and basics on how to set up a push-notification experience'
                        imageLink='/how-to-notification.svg'
                        externalLink={true}
                    />
                    <HomeCard
                        link={DEVICE_SETUP_WIKI}
                        text='How-to: Device experience setup'
                        description='Documentation and basics on how to set up a device-notification experience'
                        imageLink='/how-to-device.svg'
                        externalLink={true}
                    />
                </div>
                <div className='awsui-row'>
                    <HomeCard
                        link={TROUBLESHOOTING_WIKI_LINK}
                        text='Troubleshooting'
                        description='Having trouble setting up your experience? Check out our guide'
                        imageLink='/troubleshooting.svg'
                        externalLink={true}
                    />
                    <HomeCard
                        link={SURVEY_LINK}
                        text='Take our survey!'
                        description={'We\'d love to hear feedback from you about Odyssey'}
                        imageLink='/survey.svg'
                        externalLink={true}
                    />
                    <HomeCard
                        link={EXPERIENCE_EXAMPLE_LINK}
                        text='Experience examples'
                        description='A breakdown of live experiences'
                        imageLink='/examples.svg'
                        externalLink={true}
                    />
                </div>
            </div>
        </div>;
    }
}

export default connect()(Home);
