import { ICapabilityCatalogSearchAction } from '../actions/experienceDetailViewActions';
import { CLEAR_CAPABILITY_SEARCH, LOADING_CAPABILITY_CATALOGS, LOADED_CAPABILITY_CATALOGS, ERROR_LOADING_CAPABILITY_CATALOGS, SELECTED_PROMOTED_CAPABILITY, TOGGLE_PROMOTED_CAPABILITY_VISIBILITY } from '../constants/capabilitySearchActionTypes';
import { IPromotedCapability } from '../models/ExperienceUpdateCandidate';

export interface ICapabilityCatalogSearchState {
    isLoading: boolean;
    isVisible: boolean;
    saveOnConfirm: boolean;
    capabilities?: IPromotedCapability[];
    selectedCapability?: IPromotedCapability;
    error?: Error;
}

const InitialState: ICapabilityCatalogSearchState = {
    isLoading: false,
    isVisible: false,
    saveOnConfirm: false
};

export const capabilityCatalogSearchReducer = (
    state: ICapabilityCatalogSearchState = InitialState,
    action: ICapabilityCatalogSearchAction
): ICapabilityCatalogSearchState => {
    switch (action.type) {
        case LOADING_CAPABILITY_CATALOGS:
            return {
                isLoading: true,
                isVisible: state.isVisible,
                saveOnConfirm: state.saveOnConfirm
            };
        case LOADED_CAPABILITY_CATALOGS:
            const { capabilities } = action;
            return {
                isLoading: false,
                isVisible: state.isVisible,
                saveOnConfirm: state.saveOnConfirm,
                capabilities
            };
        case SELECTED_PROMOTED_CAPABILITY:
            const { selectedCapability } = action;
            return {
                ...state,
                isLoading: false,
                selectedCapability
            };
        case TOGGLE_PROMOTED_CAPABILITY_VISIBILITY:
            return {
                ...state,
                isLoading: false,
                isVisible: !state.isVisible,
                saveOnConfirm: action.saveOnConfirm || false
            };
        case ERROR_LOADING_CAPABILITY_CATALOGS:
            return {
                isLoading: false,
                isVisible: state.isVisible,
                saveOnConfirm: false,
                error: action.error
            };
        case CLEAR_CAPABILITY_SEARCH:
            return InitialState;
        default:
            return state;
    }
};
