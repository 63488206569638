import * as React from 'react';
import { Component } from 'react';
import { AppState } from '../reducers/index';
import { connect } from 'react-redux';
import { IHomeCardSuggestion } from '../models/HomeCardSuggestion';
import { Button, FormField, Modal } from '@amzn/awsui-components-react';
import { KeyValuePairMultipleColumnView } from '../components/common/GridKeyValuePair';
import { SuggestionStatusBadge } from '../components/common/SuggestionStatusBadge';
import { updateSuggestionStatusAction } from '../actions/homeCardSuggestionsEditViewActions';
import { TextAreaWrapper } from '../components/common/TextAreaWrapper';
import HomeCardSuggestionsDetailGlobalView, { documentToTemplateMapping } from '../components/detail/HomeCardSuggestionDetailGlobalView';

export interface ISuggestionDetailProps {
    dispatch: any;

    suggestion?: IHomeCardSuggestion;
    isUpdating: boolean;
}

export interface ISuggestionDetailState {
    rejectionString?: string;
    rejectionModalVisible: boolean;
}

class HomeCardSuggestionDetail extends Component<ISuggestionDetailProps, ISuggestionDetailState> {

    constructor(props: ISuggestionDetailProps) {
        super(props);

        this.state = {
            rejectionString: undefined,
            rejectionModalVisible: false
        };
    }

    private approveButtonClickHandler() {
        const { dispatch, suggestion } = this.props;

        if (suggestion?.id) {
            dispatch(updateSuggestionStatusAction(suggestion?.id, 'APPROVED'));
        }
    }

    private rejectButtonClickHandler() {
        const { dispatch, suggestion } = this.props;
        const { rejectionString } = this.state;

        if (suggestion?.id) {
            dispatch(updateSuggestionStatusAction(suggestion?.id, 'REJECTED', rejectionString));
        }
    }

    private dismissModal() {
        this.setState(prevState => {
            return {
                ...prevState,
                rejectionModalVisible: false,
            };
        });
    }

    private updateRejectionString(rejection: string) {
        this.setState(prevState => {
            return {
                ...prevState,
                rejectionString: rejection
            };
        });
    }

    private toggleRejectionModalVisibility() {
        const { rejectionModalVisible } = this.state;
        this.setState({ rejectionModalVisible: !rejectionModalVisible });
    }

    public render() {
        const { suggestion } = this.props;
        const { rejectionString, rejectionModalVisible } = this.state;

        if (!suggestion) {
            return null;
        }

        return <div>
                <div className='awsui-grid'>
                    <div className='awsui-row'>
                        <div className='col-8'>
                            <h1 id='suggestion-id'>
                             <div style={{height:'35px',width:'35px',verticalAlign:'bottom'}} className='awsui-util-mr-xs'/>{suggestion.id}
                            </h1>
                        </div>
                        <div className='col-4'>
                            <span className='awsui-util-f-r'>
                                <Button
                                    id='button.approve-suggestion'
                                    icon='status-positive'
                                    disabled={suggestion.suggestionApproval.status === 'APPROVED'}
                                    onClick={this.approveButtonClickHandler.bind(this)}
                                >Approve
                                </Button>
                                <Button
                                    id='button.reject-suggestion'
                                    icon='status-negative'
                                    disabled={suggestion.suggestionApproval.status === 'REJECTED'}
                                    onClick={this.toggleRejectionModalVisibility.bind(this)}
                                >Reject
                                </Button>
                            </span>
                        </div>
                    </div>
                <div className='awsui-util-container'>
                    <div className='awsui-util-container-header'>
                        <div className='awsui-grid'>
                            <div className='awsui-row'>
                                <div className='col-12 awsui-util-t-l'>
                                    <h2>{<SuggestionStatusBadge status={suggestion.suggestionApproval.status} controlId='suggestion.summary-status' />}</h2>
                                    {suggestion.suggestionApproval.status==='REJECTED' ? <h5 style={{ color: 'grey' }}>{suggestion.suggestionApproval.metadata.rejectionString}</h5> : <div></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <KeyValuePairMultipleColumnView
                        data={[[{
                            key: 'Template',
                            value: documentToTemplateMapping[suggestion.suggestion.variants[0].content.values[0].document.src]
                                === undefined ? 'Undefined' : documentToTemplateMapping[suggestion.suggestion.variants[0].content.values[0].document.src]
                        }], [{
                            key: 'Skill ID',
                            value: suggestion.provider.providerId
                        }]]}
                    />
                </div>
                <HomeCardSuggestionsDetailGlobalView suggestion={suggestion}/>
                <Modal
                onDismiss={() => {
                    this.dismissModal();
                }}
                visible={rejectionModalVisible}
                size='medium'
                footer={<span className='awsui-util-f-r'>
                    <Button
                        id='suggestion.cancel-rejection'
                        variant='link'
                        onClick={() => {
                            this.dismissModal();
                        }}>
                        Cancel
                    </Button>
                    <Button
                        id='suggestion.confirm-rejection'
                        variant='primary'
                        icon='envelope'
                        disabled={rejectionString === '' || rejectionString === null}
                        onClick={() => {
                            this.rejectButtonClickHandler();
                            this.dismissModal();
                        }}>
                        Submit rejection
                    </Button>
                </span>
                }
                header={'You are rejecting the suggestion.'}>
                <div className='awsui-util-spacing-v-s'>
                    <FormField
                        label='Suggestion Rejection Reason'
                        description='Please list your reason for rejecting the suggestion.'>
                        <TextAreaWrapper
                            id='suggestion.rejection-reason'
                            readonly={false}
                            validate={item => item !== ''}
                            value={rejectionString}
                            onChange={(input: string) => this.updateRejectionString(input)} />
                    </FormField>
                </div>
            </Modal>
            </div>
        </div>;
    }
}

const mapStateToProps = ({ homeCardSuggestionsDetailViewState, homeCardSuggestionsEditViewState }: AppState) => {
    return {
        suggestion: homeCardSuggestionsDetailViewState.suggestion || undefined,
        isUpdating: homeCardSuggestionsEditViewState.isUpdating,
    };
};

export default connect(mapStateToProps)(HomeCardSuggestionDetail);
