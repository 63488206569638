import * as React from 'react';
import { IFlattenedExperience } from '../../../models/FlattenedExperience';
import RegionSectionView from '../../../containers/sections/RegionSectionView';
import DataSourceTypeSectionView from '../../../containers/sections/DataSourceTypeSectionView';
import BullseyeSectionView from '../../../containers/sections/BullseyeSectionView';
import NotificationDevicesSectionView from '../../../containers/sections/NotificationDevicesSectionView';

export const CustomerTargetingTabView = (props: { experience: IFlattenedExperience }) => {
    const { experience } = props;

    return <div>
        <RegionSectionView />
        {
            (experience.type === 'CIF') &&
                <BullseyeSectionView />
        }
        {
            (experience.type === 'AppNotification' || experience.type === 'DeviceNotification') &&
                <DataSourceTypeSectionView />
        }
        {
            experience.type === 'DeviceNotification' && <div>
                <NotificationDevicesSectionView />
            </div>
        }
    </div>;
};
