import { Action, Dispatch } from 'redux';

import * as odysseyExperienceManagementGateway from '../../api/odysseyExperienceManagementGateway';
import { GetOdysseyExperienceResponse } from '../../api/odysseyExperienceManagementGateway';
import { getLinkableUrl, PAGE } from '../../constants/page';
import {
    LOADING_ODYSSEY_EXPERIENCE_DETAIL,
    LOADED_ODYSSEY_EXPERIENCE_DETAIL,
    ERROR_LOADING_ODYSSEY_EXPERIENCE_DETAIL,
} from '../../constants/v2/odysseyExperienceDetailViewActionTypes';
import { fromSearchIdsToCompressedSearchParams } from '../../models/uri/SearchParams';
import { IOdysseyExperience } from '../../models/v2/IOdysseyExperience';
import { AppState } from '../../reducers';
import { transformFromMetaDataToExperiences } from '../../util/MultiCloneUtils';
import { transformOdysseyExperience } from '../../util/stringAndMappingHelper';
import { creatingMultiCloneExperiences, errorUpdateMultiCloneExperiences, successUpdateMultiCloneExperiences } from '../experienceDetailViewActions';
import { errorUpdatingExperience, updatedExperience, updatingExperience } from '../experienceEditViewActions';

export interface ILoadOdysseyExperienceAction extends Action {
    experience?: IOdysseyExperience;
    error?: Error;
}

export const loadingOdysseyExperienceDetail = (): ILoadOdysseyExperienceAction => {
    return {
        type: LOADING_ODYSSEY_EXPERIENCE_DETAIL
    };
};

export const loadedOdysseyExperienceDetail = (experience: IOdysseyExperience): ILoadOdysseyExperienceAction => {
    return {
        type: LOADED_ODYSSEY_EXPERIENCE_DETAIL,
        experience
    };
};

export const errorLoadingOdysseyExperienceDetail = (error: Error): ILoadOdysseyExperienceAction => {
    return {
        type: ERROR_LOADING_ODYSSEY_EXPERIENCE_DETAIL,
        error
    };
};

export const getOdysseyExperienceAction = (id: string) => async (dispatch: Dispatch) => {
    dispatch(loadingOdysseyExperienceDetail());

    try {
        const data: GetOdysseyExperienceResponse = await odysseyExperienceManagementGateway.getOdysseyExperience(id);
        dispatch(loadedOdysseyExperienceDetail(data.experiment));
    } catch (error) {
        dispatch(errorLoadingOdysseyExperienceDetail(error as Error));
    }
};

// clone odyssey experience
export const createMultiCloneOdysseyExperienceAction = () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    if (state.odysseyExperienceDetailViewState.experience && state.multiCloneState.multiCloneExperiences) {
        const errors: Error[] = [];
        const cloneExperienceIds: string[] = [];
        const userAlias = state.navigationViewState.userAlias;

        const experiences = transformFromMetaDataToExperiences(state.odysseyExperienceDetailViewState.experience, state.multiCloneState.multiCloneExperiences, userAlias);

        dispatch(updatingExperience());
        dispatch(creatingMultiCloneExperiences());

        for (const exp of experiences) {
            try {
                const putOdysseyExperienceResponse = await odysseyExperienceManagementGateway.putOdysseyExperience(exp as IOdysseyExperience);
                cloneExperienceIds.push(putOdysseyExperienceResponse.id);
            } catch (error) {
                errors.push(error as Error);
            }
        }

        const compressedSearchParams = fromSearchIdsToCompressedSearchParams(cloneExperienceIds);
        const searchCriteriaString = JSON.stringify(compressedSearchParams);
        if (errors.length > 0) {
            dispatch(errorUpdateMultiCloneExperiences(errors, state.odysseyExperienceDetailViewState.experience.title || '', experiences.length - errors.length));
            window.location.assign(getLinkableUrl(`${PAGE.MhcExperiences}?search=${searchCriteriaString}`));
        } else {
            dispatch(successUpdateMultiCloneExperiences(state.odysseyExperienceDetailViewState.experience.title || '', experiences.length));

            if (cloneExperienceIds.length === 1) {
                try {
                    const getOdysseyExperienceResponse = await odysseyExperienceManagementGateway.getOdysseyExperience(cloneExperienceIds[0]);
                    setTimeout(() => {
                        state.odysseyExperienceDetailViewState.experience = transformOdysseyExperience(getOdysseyExperienceResponse.experiment);
                        dispatch(updatedExperience(cloneExperienceIds[0]));
                        window.location.assign(getLinkableUrl(PAGE.MhcExperiences) + '/' + cloneExperienceIds[0]);
                    }, 2000);
                } catch (err) {
                    dispatch(errorUpdatingExperience(err as Error));
                }
            } else {
                window.location.assign(getLinkableUrl(`${PAGE.MhcExperiences}?search=${searchCriteriaString}`));
            }
        }
    } else {
        dispatch(errorUpdatingExperience(new Error('Bad input')));
    }
};
