import * as React from 'react';
import { ColumnLayout, Spinner, FormField, Multiselect, Select, Alert } from '@amzn/awsui-components-react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { DEVICES, hasOnTheGoDevices } from '../../constants/devices';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { pushDevicesSectionAction } from '../../actions/experienceEditViewActions';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { CIFValidator } from '../../util/CIFValidator';
import { FlashbarWrapper } from '../../components/common/FlashbarWrapper';
import { generateDeviceGroups } from './CifDevicesSectionView';


interface IBasicDevicesSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

interface IBasicDevicesSectionViewState {
    avsDeviceInput?: string;
    deviceSelections?: Select.OptionsGroup[];
}

export class BasicDevicesSectionView extends React.Component<IBasicDevicesSectionViewProps, IBasicDevicesSectionViewState>  {

    constructor(props: IBasicDevicesSectionViewProps) {
        super(props);
        this.state = {avsDeviceInput: '', deviceSelections: generateDeviceGroups()};
    }

    updateDevices(devices?: string[]) {
        const { dispatch } = this.props;
        dispatch(pushDevicesSectionAction(devices));
    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate } = this.props;

        if (!experience) {
            return <FormSectionView title='Devices'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const supportedDevices = Object.keys(DEVICES);
        const devices: string[] = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
            ? updateCandidate.getDevices()
            : ExperienceUpdateCandidate.extractDevices(experience));

        const nonAvsDevices: string[] = [];
        const avsDevices: string[] = [];

        devices.forEach((deviceId) => {
            supportedDevices.includes(deviceId) ? nonAvsDevices.push(deviceId) : avsDevices.push(deviceId);
        });

        const shouldDisableInput = !isEditing || isUpdating;

        return <FormSectionView title='Device Targeting' description='
            At least one device must be selected.
            Please select device(s) appropriate for your experience'
        >
            {!shouldDisableInput && <FlashbarWrapper validate={() => CIFValidator.isDevicesValid(devices)} />}
            {hasOnTheGoDevices(devices)
                && <Alert visible={true}
                    type='info'>You have added at least one OTG (On-The-Go) device to your experience.
                </Alert>}
            <ColumnLayout columns={2} >
                <div data-awsui-column-layout-root='true'>
                    <FormField label={<RequiredField fieldName='Echo Family and Fire TV Devices' />} description='Target Echo multimodal, headless, on-the-go, Fire TV, and Echo Auto devices'>
                        <Multiselect
                            id='experience.devices-non-avs-multiselect'
                            controlId='experience.devices-non-avs-multiselect'
                            selectedIds={nonAvsDevices}
                            options={this.state.deviceSelections}
                            disabled={shouldDisableInput}
                            onChange={(event) => {this.updateDevices(event.detail.selectedIds.concat(avsDevices));}}
                            filteringType='auto'
                            checkboxes={true}
                        />
                    </FormField>
                </div>
            </ColumnLayout>
        </FormSectionView>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(BasicDevicesSectionView);
