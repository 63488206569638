import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../reducers/index';
import { configureAlexaLabMetricsAction, resetConfiguredAlexaLab } from '../../actions/experienceDetailViewActions';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { FormSectionView } from '../../components/common/FormSectionView';
import { Spinner, Tooltip, Alert } from '@amzn/awsui-components-react';
import { ButtonWrapper } from '../../components/common/ButtonWrapper';
import { CommonValidator } from '../../util/CommonValidator';
import { ExternalLink } from '../../components/common/LinkComponents';
import { getRole, isExperienceApproved } from '../../util/stringAndMappingHelper';

export interface IConfigureAlexaLabMetricsViewProps {
    dispatch: any;

    isInEdit: boolean;
    alexaLabConfigResponse?: string;
    isLoading: boolean;
    error?: Error;

    experience?: IFlattenedExperience;
}

class ConfigureAlexaLabMetricsView extends React.Component<IConfigureAlexaLabMetricsViewProps> {
    configureAlexaLabMetrics () {
        const { dispatch, experience } = this.props;

        if (experience) {
            dispatch(configureAlexaLabMetricsAction(experience.id));
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(resetConfiguredAlexaLab());
    }

    render () {
        const { isInEdit, isLoading, alexaLabConfigResponse, error, experience } = this.props;

        if (!experience) {
            return <FormSectionView title='AlexaLab Metrics'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const inEditModePrompt = 'Metric generation requires you save your experience first';
        const configurePrompt = 'Configure default Alexa Lab metrics for your experience';
        const isUserAdmin: boolean = getRole(experience.permissionRole) === 'ADMIN';
        const isApproved: boolean = isExperienceApproved(experience.status);

        return <FormSectionView title='AlexaLab Metrics'>
            <div className='awsui-util-spacing-v-s'>
                <p>
                Odyssey will automatically set up all the required Alexa Lab metrics needed by Odyssey for this experience.
                This includes common metrics like Dialogs and Active Days for each NLU trigger as well as the suggested intent.
                </p>
                <p>
                If you want to configure additional metrics, click on Metrics Configuration in the summary.
                </p>
                <p>
                <b>Note</b>: If you click “Configure Alexa Lab Metrics” a second time, any additional metrics configured will be reset
                </p>
                <Tooltip text={isInEdit ? inEditModePrompt : configurePrompt} >
                    <ButtonWrapper
                        id='button.configure-alexa-lab-metrics'
                        icon='key'
                        experience={experience}
                        isLoading={isLoading}
                        onClick={this.configureAlexaLabMetrics.bind(this)}
                        isDisabled={isLoading || isInEdit || (isApproved && !isUserAdmin)}
                        displayText='Configure Alexa Lab Metrics'
                        validate={CommonValidator.isExperienceReadyForAlexaLabMetrics}
                    />
                </Tooltip>
                {error && <Alert header='Error configuring Alexa Lab Metrics' type='error'>
                    {error.message}
                    <hr />
                    <p>Cut us a <ExternalLink
                        href='https://t.corp.amazon.com/create/templates/ea88c3d7-af24-47b5-8c13-0781d93876cc'
                    >ticket</ExternalLink> with the error message and experience ID.</p>
                </Alert>}

                {alexaLabConfigResponse && <Alert
                    type='success'
                    header='You have configured your Alexa Lab metrics successfully'
                    dismissible={true}>
                    {alexaLabConfigResponse}
                </Alert>}
            </div>
        </FormSectionView>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState, configuredAlexaLabMetricsState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isInEdit: experienceEditViewState.isEditing || experienceEditViewState.isUpdating,
        alexaLabConfigResponse: configuredAlexaLabMetricsState.alexaLabConfigResponse,
        isLoading: configuredAlexaLabMetricsState.isLoading || experienceDetailViewState.isLoading,
        error: configuredAlexaLabMetricsState.error,

        experience: experienceDetailViewState.experience,
    };
};

export default connect(mapStateToProps)(ConfigureAlexaLabMetricsView);
