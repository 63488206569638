import React from 'react';
import { Icon, Popover } from '@amzn/awsui-components-react';
import { IAlexaLabMetrics } from '../../models/AlexaLabMetrics';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { KeyValuePairMultipleColumnView, RenderableKeyValuePair } from '../common/GridKeyValuePair';
import { StatusBadge } from '../common/StatusBadge';
import { isCifLegacyTestExperience } from '../../util/stringAndMappingHelper';
import { IRawCifMetricsData, filterDataForComputingPerformanceStatus } from '../../models/CifMetrics';
import { ExternalLink } from '../common/LinkComponents';
import { NextDialUpStatus } from '../../constants/dialUpCriteriaMap';

export enum PerformanceStatus {
    CIF_EXPERIENCE_MEETS_LAUNCH_CRITERIA = 'CIF_EXPERIENCE_MEETS_LAUNCH_CRITERIA',
    NON_CIF_EXPERIENCE_MEETS_LAUNCH_CRITERIA = 'NON_CIF_EXPERIENCE_MEETS_LAUNCH_CRITERIA',
    ALEXALAB_METRICS_FAIL_LAUNCH_CRITERIA = 'ALEXALAB_METRICS_FAIL_LAUNCH_CRITERIA',
    NON_CIF_EXPERIENCE_ALEXALAB_METRICS_FAIL_LAUNCH_CRITERIA = 'NON_CIF_EXPERIENCE_ALEXALAB_METRICS_FAIL_LAUNCH_CRITERIA',
    CIF_METRICS_FAIL_LAUNCH_CRITERIA = 'CIF_METRICS_FAIL_LAUNCH_CRITERIA',
    ALEXALAB_AND_CIF_METRICS_FAIL_LAUNCH_CRITERIA = 'ALEXALAB_AND_CIF_METRICS_FAIL_LAUNCH_CRITERIA',
    INSUFFICIENT_ALEXALAB_DATA = 'INSUFFICIENT_ALEXALAB_DATA',
    NON_CIF_EXPERIENCE_INSUFFICIENT_ALEXALAB_DATA = 'NON_CIF_EXPERIENCE_INSUFFICIENT_ALEXALAB_DATA',
    INSUFFICIENT_CIF_DATA = 'INSUFFICIENT_CIF_DATA',
    INSUFFICIENT_ALEXALAB_AND_CIF_DATA = 'INSUFFICIENT_ALEXALAB_AND_CIF_DATA',
    INCONSISTENT_CIF_DATA = 'INCONSISTENT_CIF_DATA',
    INSUFFICIENT_ALEXALAB_AND_INCONSISTENT_CIF_DATA = 'INSUFFICIENT_ALEXALAB_AND_INCONSISTENT_CIF_DATA'
}

export interface PerformanceStatusDisplay {
    iconName: Icon.Name;
    text: string;
    className: string;
    description: React.ReactNode;
}

export const PerformanceStatusBadgeMap: {
    [key in PerformanceStatus]: PerformanceStatusDisplay
} = {
    'CIF_EXPERIENCE_MEETS_LAUNCH_CRITERIA': {
        iconName: 'status-positive',
        text: 'Meets launch criteria',
        className: 'awsui-util-status-positive',
        description: <p>Your experience for AlexaLab and CIF metrics performed up to standard. <br /><br />
            Visit <ExternalLink href ={'https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+CIF+Launch#Workflows--2064241446'} >here</ExternalLink> for more information.</p>
    },
    'NON_CIF_EXPERIENCE_MEETS_LAUNCH_CRITERIA': {
        iconName: 'status-positive',
        text: 'Meets launch criteria',
        className: 'awsui-util-status-positive',
        description: 'Your experience for AlexaLab metrics performed up to standard.'
    },
    'ALEXALAB_METRICS_FAIL_LAUNCH_CRITERIA': {
        iconName: 'status-negative',
        text: 'Does not meet launch criteria',
        className: 'awsui-util-status-negative',
        description: <p>Your AlexaLab metrics performance does not meet standards for launch. <br /><br />
            Visit <ExternalLink href ={'https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+CIF+Launch#Workflows--2064241446'} >here</ExternalLink> for more information.</p>
    },
    'NON_CIF_EXPERIENCE_ALEXALAB_METRICS_FAIL_LAUNCH_CRITERIA': {
        iconName: 'status-negative',
        text: 'Does not meet launch criteria',
        className: 'awsui-util-status-negative',
        description: 'Your AlexaLab metrics performance does not meet standards for launch.'
    },
    'CIF_METRICS_FAIL_LAUNCH_CRITERIA': {
        iconName: 'status-negative',
        text: 'Does not meet launch criteria',
        className: 'awsui-util-status-negative',
        description: <p>Your CIF metrics performance does not meet standards for launch. <br /><br />
            Visit <ExternalLink href ={'https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+CIF+Launch#Workflows--2064241446'} >here</ExternalLink> for more information.</p>
    },
    'ALEXALAB_AND_CIF_METRICS_FAIL_LAUNCH_CRITERIA': {
        iconName: 'status-negative',
        text: 'Does not meet launch criteria',
        className: 'awsui-util-status-negative',
        description: <p>Your AlexaLab and CIF Metrics performance does not meet standards for launch. <br /><br />
            Visit <ExternalLink href ={'https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+CIF+Launch#Workflows--2064241446'} >here</ExternalLink> for more information.</p>
    },
    'INSUFFICIENT_ALEXALAB_DATA': {
        iconName: 'status-warning',
        text: 'Insufficient data',
        className: '',
        description: <p>Your experience's AlexaLab metrics are statistically insignificant to determine the launch status. <br /><br />
            Visit <ExternalLink href ={'https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+CIF+Launch#Workflows--1097051534'} >here</ExternalLink> for more information.</p>
    },
    'NON_CIF_EXPERIENCE_INSUFFICIENT_ALEXALAB_DATA': {
        iconName: 'status-warning',
        text: 'Insufficient data',
        className: '',
        description: 'Your experience\'s AlexaLab metrics are statistically insignificant to determine the launch status.'
    },
    'INSUFFICIENT_CIF_DATA': {
        iconName: 'status-warning',
        text: 'Insufficient data',
        className: '',
        description: <p>Your experience has less than 7 days of data within the last 14 days and cannot determine the launch status. <br /><br />
            Visit <ExternalLink href ={'https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+CIF+Launch#Workflows--2064241446'} >here</ExternalLink> for more information.</p>
    },
    'INCONSISTENT_CIF_DATA': {
        iconName: 'status-warning',
        text: 'Insufficient data',
        className: '',
        description: <p>Your experience requires 7 days of unvarying interruption rate and UNFR data within the last 14 days. <br /><br />
            Visit <ExternalLink href ={'https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+CIF+Launch#Workflows--2064241446'} >here</ExternalLink> for more information.</p>
    },
    'INSUFFICIENT_ALEXALAB_AND_INCONSISTENT_CIF_DATA': {
        iconName: 'status-warning',
        text: 'Insufficient data',
        className: '',
        description: <p>Your experience requires 7 days of unvarying interruption rate and UNFR data within the last 14 days. <br /><br />
                            Additionally, your experience's AlexaLab metrics are statistically insignificant to determine the launch status. <br /><br />
                            Visit <ExternalLink href ={'https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+CIF+Launch#Workflows--1097051534'}>here</ExternalLink> for more information.</p>
    },
    'INSUFFICIENT_ALEXALAB_AND_CIF_DATA': {
        iconName: 'status-warning',
        text: 'Insufficient data',
        className: '',
        description: <p>Your experience has less than 7 days of data within the last 14 days and cannot determine the launch status. <br /><br />
                            Additionally, your experience's AlexaLab metrics are statistically insignificant to determine the launch status. <br /><br />
                            Visit <ExternalLink href ={'https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+CIF+Launch#Workflows--1097051534'}>here</ExternalLink> for more information.</p>
    },
};

const PerformanceStatusBadge = ({ status }: { status: PerformanceStatus }) => {
    const { iconName, text, className, description } = PerformanceStatusBadgeMap[status];

    return <div><Popover size='medium' position='top' content={description}>
        <span className={className} style={{ fontWeight: 'bold' }}><Icon name={iconName} />&nbsp;&nbsp;{text}</span>
    </Popover></div>;
};

const computePerformanceStatus = (alexaLabMetrics?: IAlexaLabMetrics, cxDailyMetrics?: IRawCifMetricsData[], experience?: IFlattenedExperience): PerformanceStatus => {

    if (!alexaLabMetrics) {
        return PerformanceStatus.NON_CIF_EXPERIENCE_INSUFFICIENT_ALEXALAB_DATA;
    }
    const hasInsufficientAlexaLabData = alexaLabMetrics.metrics.some(metric => metric.data.pvalue > 0.05);
    const hasLowPerformingAlexaLabData = alexaLabMetrics.metrics.some(metric => metric.data.pvalue <= 0.05 && metric.data.percentLift < 0);

    if (hasLowPerformingAlexaLabData) {
        return PerformanceStatus.NON_CIF_EXPERIENCE_ALEXALAB_METRICS_FAIL_LAUNCH_CRITERIA;
    } else if (hasInsufficientAlexaLabData) {
        return PerformanceStatus.NON_CIF_EXPERIENCE_INSUFFICIENT_ALEXALAB_DATA;
    } else {
        return PerformanceStatus.NON_CIF_EXPERIENCE_MEETS_LAUNCH_CRITERIA;
    }
};

export const computeNextDialUpStatus = (experience: IFlattenedExperience): NextDialUpStatus => {
    if (experience.status === 'DRAFT' || experience.status === 'TESTABLE' || experience.status === 'UNDER_REVIEW') {
        return NextDialUpStatus.LIVE_TEN_PERCENT;
    } else if ((isCifLegacyTestExperience(experience) && experience.approvalInfo?.lastDialupStage === 'LIVE_TEN_PERCENT') ||
                experience.status === 'LIVE_TEN_PERCENT') {
        return NextDialUpStatus.LIVE_FIFTY_PERCENT;
    } else if (((isCifLegacyTestExperience(experience) && ( experience.approvalInfo?.lastDialupStage === 'LIVE_FIFTY_PERCENT'))  ||
                experience.status === 'LIVE_FIFTY_PERCENT')) {
        return NextDialUpStatus.LIVE_HUNDRED_PERCENT;
    } else if ((((isCifLegacyTestExperience(experience) && (experience.approvalInfo?.lastDialupStage === 'LIVE_HUNDRED_PERCENT' || experience.approvalInfo?.lastDialupStage === 'LAUNCHED'))) ||
                experience.status === 'LIVE_HUNDRED_PERCENT' || experience.status === 'LAUNCHED')) {
        if (experience.status === 'LAUNCHED' || experience.approvalInfo?.lastDialupStage === 'LAUNCHED') {
            return NextDialUpStatus.NONE;
        }
        return NextDialUpStatus.LAUNCH_REVIEW;
    } else if (experience.status === 'PAUSED') {
        return NextDialUpStatus.RESUBMIT;
    } else {
        return NextDialUpStatus.NONE;
    }
};


export const ExperienceMetricsOverview = ({ alexaLabMetrics, cxDailyMetrics, experience }: {
    alexaLabMetrics?: IAlexaLabMetrics, cxDailyMetrics?: IRawCifMetricsData[],
    experience: IFlattenedExperience
}) => {
    const cxDailyMetricsMeetingPerformanceStatusComputationCriteria = filterDataForComputingPerformanceStatus(cxDailyMetrics?.filter(e => e.experienceId === experience.id));

    const experienceStatus = {
        key: 'Experience status',
        value: <StatusBadge status={experience.status} />
    } as RenderableKeyValuePair;

    const pauseReason = (experience.status === 'PAUSED' && experience.pausedReason) ? {
        key: 'Pause reason',
        value: experience.pausedReason
    } as RenderableKeyValuePair : undefined;

    return <div id='metrics.overview' className='awsui-util-container' style={{ height: '100%' }}>
        <div className='awsui-util-container-header'>
            Experience status
            {experience.type === 'CIF' &&
            <div className='awsui-util-container-header-description'>
                <div>
                    Dial-up to 50% takes place 3 days after experience is live. Dial-up to 100% takes place 21 days after last dial-up or at time specified in AlexaLab configuration
                    (whichever is longer). Launch review takes place 14 days after last dial-up.
                </div>
            </div>}
        </div>
        {experience.type === 'CIF' &&
        <KeyValuePairMultipleColumnView
            data={[[experienceStatus].concat(pauseReason ? [pauseReason] : []),[{
                key: 'Next dial-up',
                value: <div>{computeNextDialUpStatus(experience)}</div>
            }]]}
        />}
        {experience.type !== 'CIF' &&
        <KeyValuePairMultipleColumnView
            data={[[experienceStatus].concat(pauseReason ? [pauseReason] : []), [{
                key: 'Launch status',
                value: <PerformanceStatusBadge status={computePerformanceStatus(alexaLabMetrics, cxDailyMetricsMeetingPerformanceStatusComputationCriteria, experience)} />
            }]]}
        />}
    </div>;
};
