import * as React from 'react';
import { Component } from 'react';
import { IFlattenedExperience } from '../models/FlattenedExperience';
import { getExperienceAction, getBullseyeAction } from '../actions/experienceDetailViewActions';
import ExperienceDetailGlobalView from '../components/detail/ExperienceDetailGlobalView';
import { CifExperienceTabsView } from '../components/detail/CifExperienceTabsView';
import { getPermissionAction } from '../actions/authenticationActions';
import { NotificationExperienceTabsView } from '../components/detail/NotificationExperienceTabsView';
import { Flash, Flashbar, Button, BreadcrumbGroup } from '@amzn/awsui-components-react';
import { AppState } from '../reducers/index';
import { connect } from 'react-redux';
import { cancelEditingAction, saveExperienceAction, enableCloningAction } from '../actions/experienceEditViewActions';
import { getLinkableUrl, PAGE } from '../constants/page';
import { ExperienceUpdateCandidate } from '../models/ExperienceUpdateCandidate';

export interface ICloneExperienceProps {
    match: { params: { id: string } };
    dispatch: any;

    experience?: IFlattenedExperience;
    isLoading: boolean;
    error?: Error;
    permissions: string[];

    // from edit
    isEditing: boolean;
    isUpdating: boolean;
    updateError?: Error;
    isCloning: boolean;
    id?: string;
    updateCandidate?: ExperienceUpdateCandidate;
}

export interface ICloneExperienceState {
    activeTabId?: string;
}

export class CloneExperience extends Component<ICloneExperienceProps, ICloneExperienceState> {

    constructor(props: ICloneExperienceProps) {
        super(props);

        this.state = {
            activeTabId: 'experience-details'
        };
    }
    public componentDidMount() {
        const { match, dispatch, experience, isLoading } = this.props;
        dispatch(enableCloningAction(match.params.id));
        dispatch(getPermissionAction());

        if (!experience && !isLoading) {
            dispatch(getExperienceAction(match.params.id));
        }

        if (experience
                && experience.region
                && experience.enabledFilter
                && experience.enabledFilter.bullseye) {
            dispatch(getBullseyeAction(experience.region, experience.enabledFilter.bullseye));
        }
    }

    private cancelButtonClickHandler () {
        const { experience, dispatch } = this.props;
        dispatch(cancelEditingAction());

        experience
            ? window.location.assign(getLinkableUrl(PAGE.Experiences) + '/' + experience.id)
            :  window.location.assign(getLinkableUrl(PAGE.Experiences));
    }

    private createButtonClickHandler() {
        const { dispatch, experience } = this.props;
        if (experience) {
            dispatch(saveExperienceAction());
        }
    }

    public render() {
        const { experience, isLoading, error,
            isEditing, isUpdating, updateError, updateCandidate, permissions} = this.props;

        if (error) {
            return <Flashbar items={[{
                header: 'Loading failed',
                content: 'Failed to find your experience',
                type: 'error'
            }]} />;
        }

        if (isLoading) {
            return <Flash dismissible={false} >Loading...</Flash>;
        }

        if (isUpdating) {
            return <Flash dismissible={false} >Cloning...</Flash>;
        }

        if (!experience) {
            return <Flash dismissible={true} type='error' >
                Something happened while trying to retrieve experience...
            </Flash>;
        }

        const isAdmin: boolean = (permissions.includes('ADMIN'));

        return <div>
            {updateError && <Flashbar items={[{
                header: 'Clone failed',
                // TODO: get rid of the ternary operator once Lambda passes back the real error message
                content: updateError.message !== 'Network Error' ? updateError.message : 'Failed to clone',
                type: 'error'
            }]}/>}
            <div style={{ margin: '30px' }} className='awsui-grid'>
                <div className='awsui-row'>
                    <div style={{ marginBottom: '20px' }} className='col-8'>
                        <BreadcrumbGroup label='Breadcrumbs' items={[{
                            text: 'Odyssey',
                            href: getLinkableUrl(PAGE.Home),
                        }, {
                            text: 'Experiences',
                            href: getLinkableUrl(PAGE.Experiences),
                        }]} />
                    </div>
                    <div className='col-4 awsui-util-t-r'>
                        {isEditing && <div>
                            <Button
                                id={'button.cancel-clone'}
                                onClick={this.cancelButtonClickHandler.bind(this)}
                            >Cancel</Button>
                            <Button
                                id={'button.create-clone'}
                                onClick={this.createButtonClickHandler.bind(this)}
                                variant='primary'
                            >Create</Button>
                        </div>}
                    </div>
                </div>
                {isEditing && (experience.status === 'DRAFT' || experience.status === 'TESTABLE') && <Flashbar items={[{
                    content: <div>
                        <p>You will be required to set a Locale for this experience before making changes to it.</p>
                        <p>Once you have configured all required information and save your draft,
                        your experience will be moved into TESTABLE state.
                        At this point, you can enter a Testing Account (under the Testing tab)
                        and preview your experience on a live device.</p>
                        <p>After you have completed setup and tested your configuration,
                            you may submit this experience for approval.</p>
                    </div>,
                    type: 'info'
                }]}/>}
                <Flashbar items={[{
                    header: 'Clone successful',
                    // TODO: get rid of the ternary operator once Lambda passes back the real error message
                    content: `Experience successfully cloned from ${experience.title}, provide a new title and you may now edit this experience in draft mode`,
                    type: 'success',
                    dismissible: true
                }]}/>
                <ExperienceDetailGlobalView
                    experience={experience}
                    isEditing={isEditing}
                    updateCandidate={updateCandidate} />
                {generateTabsView(experience, isAdmin, this)}
            </div>
        </div>;
    }
}

const generateTabsView = (experience: IFlattenedExperience, isAdmin: boolean, component: Component<ICloneExperienceProps, ICloneExperienceState>) => {

    if (experience.type === 'CIF') {
        return <CifExperienceTabsView experience={experience}
        activeTabId={component.state.activeTabId || 'cif-injection'}
        isAdmin={isAdmin}
        onTabChange={(d) => {
            component.setState(prevState => {
                return {...prevState, activeTabId: d.activeTabId};
            });
        }} />;
    } else if (experience.type === 'AppNotification' || experience.type === 'DeviceNotification') {
        return <NotificationExperienceTabsView experience={experience}
        isAdmin={isAdmin}
        activeTabId={component.state.activeTabId || 'notification-content'}
        onTabChange={(d) => {
            component.setState(prevState => {
                return {...prevState, activeTabId: d.activeTabId};
            });
        }} />;
    }
};

const mapStateToProps = ({ experienceDetailViewState, authenticationState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isLoading has default value set in reducer, so no default
        // value needed
        isLoading: experienceDetailViewState.isLoading,
        experience: experienceDetailViewState.experience || undefined,
        error: experienceDetailViewState.error || undefined,
        isEditing: experienceEditViewState.isEditing,
        isCloning: experienceEditViewState.isCloning,
        permissions: authenticationState.permissions || [],
        isUpdating: experienceEditViewState.isUpdating,
        updateError: experienceEditViewState.error || undefined,
        updateCandidate: experienceEditViewState.updateCandidate || undefined,
        id: experienceEditViewState.id || undefined,
    };
};

export default connect(mapStateToProps)(CloneExperience);
