import * as React from 'react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { ColumnLayout, Alert, Spinner, Checkbox } from '@amzn/awsui-components-react';
import { DAYS, getRole } from '../../util/stringAndMappingHelper';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { connect } from 'react-redux';
import { AppState } from '../../reducers/index';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { pushDayTimeWindowSectionAction } from '../../actions/experienceEditViewActions';
import { ITimeRange, getDefaultTimeRange, getDefaultDayTimeGuardrails } from '../../models/TimeRange';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { TimeInputWrapper } from '../../components/common/TimeInputWrapper';
import { CommonValidator } from '../../util/CommonValidator';
import { COMMON_ERROR_MESSAGES } from '../../constants/validatorConstants';

interface IDayTimeWindowSelectionViewProp {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
    isVisualCIF?: boolean;
}

export class DayTimeWindowSectionView extends React.Component<IDayTimeWindowSelectionViewProp> {

    private pad(input: string | number, desiredLength: number, customPaddingNotation = '0') {
        input = '' + input + '';
        return input.length >= desiredLength
            ? input
            : new Array(desiredLength - input.length + 1).join(customPaddingNotation) + input;
    }

    private updateTimeRanges(timeRanges: { [key: number]: ITimeRange[] }) {
        const { dispatch } = this.props;
        dispatch(pushDayTimeWindowSectionAction(timeRanges));
    }

    private getDayTimeGuardrails(isEditing: boolean, experience: IFlattenedExperience, updateCandidate?: ExperienceUpdateCandidate, isVisualCIF?: boolean) {
        let timeRanges : { [key: number]: ITimeRange[] };
        if (isEditing && updateCandidate) {
            timeRanges = updateCandidate.getTimeRanges();
        } else if (experience.dayTimeRangeGuardrails) {
            timeRanges = experience.dayTimeRangeGuardrails;
        } else {
            timeRanges = getDefaultDayTimeGuardrails(experience.type, isVisualCIF);
        }
        return ExperienceUpdateCandidate.deepClone(timeRanges);
    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate } = this.props;

        if (!experience) {
            return <FormSectionView title={<RequiredField fieldName='Day Time Window' />}>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }
        const isVisualCIF = this.props.isVisualCIF || experience.visualContent? true : false;
        const DEFAULT_TIME_RANGE = getDefaultTimeRange(experience.type, isVisualCIF);
        const defaultStartHour = DEFAULT_TIME_RANGE.startHour;
        const defaultEndHour = DEFAULT_TIME_RANGE.endHour;
        const defaultStartMin = DEFAULT_TIME_RANGE.startMin;
        const defaultEndMin = DEFAULT_TIME_RANGE.endMin;

        const dayTimeRangeGuardrails = this.getDayTimeGuardrails(isEditing, experience, updateCandidate, isVisualCIF);
        const days = Object.keys(dayTimeRangeGuardrails);
        const shouldDisableInput = !isEditing || isUpdating;

        const cifDescriptionString = 'Odyssey prevents injections between the hours of 22:00 and 07:00 (24h format). ' +
            'All times are in the timezone of the customer\'s device where the injection will happen.';

        const notificationDescriptionString = 'Odyssey prevents injections between the hours of 22:00 and 09:00 (24h format). ' +
            'All times are in the timezone of your selected country. ' +
            'All US notification experiences follow Central Standard Time (GMT-6, or GMT-5 during daylight savings).';

        const vcifDescriptionString = 'All times are in the timezone of the customer\'s device where the injection will happen.';

        const descriptionString = experience.type === 'CIF' ? (isVisualCIF? vcifDescriptionString: cifDescriptionString) : notificationDescriptionString;

        return <FormSectionView title={<RequiredField fieldName='Day Time Window' />}>
            <Alert>
                {descriptionString}
            </Alert>
            {isEditing && Object.keys(dayTimeRangeGuardrails).length === 0 &&
                <Alert id='experience.day-time-windows-missing' type='error'>
                    {COMMON_ERROR_MESSAGES.MISSING_DAY_TIME_WINDOW}
                </Alert>}
            <div className='awsui-grid'>
                <div className='awsui-row'>
                    <div className='col-6'>
                        {Object.keys(DAYS)
                            .map(dayId => {
                                const dayInGuardrails = days.includes(dayId);

                                const timeRanges = (dayInGuardrails
                                    && dayTimeRangeGuardrails[Number(dayId)].length !== 0)
                                    && dayTimeRangeGuardrails[Number(dayId)];

                                const timeRange = timeRanges ? timeRanges[0] : DEFAULT_TIME_RANGE;

                                return <div key={dayId} style={{ marginTop: '10px', marginBottom: '15px' }}>
                                    <Checkbox
                                        id={`experience.day-in-guardrails-${dayId}`}
                                        controlId={`experience.day-in-guardrails-${dayId}`}
                                        disabled={shouldDisableInput}
                                        checked={dayInGuardrails}
                                        onChange={(e) => {
                                            if (e.detail.checked) {
                                                dayTimeRangeGuardrails[Number(dayId)] = [DEFAULT_TIME_RANGE];
                                            } else {
                                                delete dayTimeRangeGuardrails[Number(dayId)];
                                            }

                                            this.updateTimeRanges(dayTimeRangeGuardrails);
                                        }}
                                    >
                                        {DAYS[Number(dayId)]}
                                    </Checkbox>
                                    <ColumnLayout columns={2}>
                                        <div data-awsui-column-layout-root='true'>
                                            <TimeInputWrapper
                                                id={`experience.start-time-day-${dayId}`}
                                                dayId={Number(dayId)}
                                                timeRanges={dayTimeRangeGuardrails}
                                                validate={CommonValidator.isWindowEmpty}
                                                value={dayTimeRangeGuardrails[Number(dayId)] ? `${this.pad(timeRange.startHour, 2)}:${this.pad(timeRange.startMin, 2)}` : ''}
                                                disabled={shouldDisableInput || !dayInGuardrails}
                                                onChange={(e) => {
                                                    if (e.detail.value === '') {
                                                        delete timeRange.startHour;
                                                        delete timeRange.startMin;
                                                    } else {
                                                        const [hour, min] = e.detail.value.split(':');
                                                        timeRange.startHour = Number(hour);
                                                        timeRange.startMin = Number(min);
                                                        if (getRole(experience.permissionRole) !== 'ADMIN') {
                                                            if (Number(hour) < defaultStartHour) {
                                                                timeRange.startHour = defaultStartHour;
                                                                timeRange.startMin = defaultStartMin;
                                                            } else if (Number(hour) > defaultEndHour) {
                                                                timeRange.startHour = defaultEndHour;
                                                                timeRange.startMin = defaultEndMin;
                                                            }
                                                        }
                                                    }

                                                    dayTimeRangeGuardrails[Number(dayId)] = [timeRange];
                                                    this.updateTimeRanges(dayTimeRangeGuardrails);
                                                }}
                                            />
                                            <TimeInputWrapper
                                                id={`experience.end-time-day-${dayId}`}
                                                dayId={Number(dayId)}
                                                timeRanges={dayTimeRangeGuardrails}
                                                validate={CommonValidator.isStartTimeAfterEndTime}
                                                value={dayTimeRangeGuardrails[Number(dayId)] ? `${this.pad(timeRange.endHour, 2)}:${this.pad(timeRange.endMin, 2)}` : ''}
                                                disabled={shouldDisableInput || !dayInGuardrails}
                                                onChange={(e) => {
                                                    if (e.detail.value === '') {
                                                        delete timeRange.endHour;
                                                        delete timeRange.endMin;
                                                    } else {
                                                        const [hour, min] = e.detail.value.split(':');
                                                        timeRange.endHour = Number(hour);
                                                        timeRange.endMin = Number(min);
                                                        if (getRole(experience.permissionRole) !== 'ADMIN') {
                                                            if (Number(hour) < defaultStartHour) {
                                                                timeRange.endHour = defaultStartHour;
                                                                timeRange.endMin = defaultStartMin;
                                                            } else if (Number(hour) > defaultEndHour) {
                                                                timeRange.endHour = defaultEndHour;
                                                                timeRange.endMin = defaultEndMin;
                                                            }
                                                        }
                                                    }

                                                    dayTimeRangeGuardrails[Number(dayId)] = [timeRange];
                                                    this.updateTimeRanges(dayTimeRangeGuardrails);
                                                }}
                                            />
                                        </div>
                                    </ColumnLayout>
                                </div>;
                            })
                        }
                    </div>
                    <div className='col-6'></div>
                </div>
            </div>

        </FormSectionView>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(DayTimeWindowSectionView);
