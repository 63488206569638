import { TablePropertyFiltering } from '@amzn/awsui-components-react';
import { FILTERING_OPTIONS } from '../../constants/tableColumnConfiguration';
export interface ISearchParams {
    op: TablePropertyFiltering.Operation;
    toks: ICompressedToken[];
}

export interface ICompressedToken {
    k: string;
    v: string;
    neg: boolean; // is negated
    fr: boolean; // is free text search
}

export const fromPropertyFiltersToCompressedSearchParams = (changeDetail: TablePropertyFiltering.ChangeDetail): ISearchParams => {
    return {
        op: changeDetail.operation as ('and' | 'or'), // https://v2-1.polaris.a2z.com/components/awsui-table-property-filtering/ bad duck typing from AWS Polaris
        toks: changeDetail.tokens.map(t => {
            return {
                k: t.propertyKey,
                v: t.value,
                neg: t.negated,
                fr: t.isFreeText || false
            };
        })
    };
};

export const fromCompressedSearchParamsToPropertyFilters = (searchParams: ISearchParams): TablePropertyFiltering.ChangeDetail => {
    const filteringOptionsKeyToPropertiesMapping: { [key: string]: TablePropertyFiltering.Option } = FILTERING_OPTIONS.reduce(
        (prev: { [key: string]: TablePropertyFiltering.Option }, cur) => {
            prev[cur.propertyKey] = cur;
            return prev;
        }, {}
    );

    return {
        operation: searchParams.op,
        tokens: searchParams.toks.map(t => {
            return {
                propertyKey: t.k || undefined, // AWS Polaris duck typing wrong. This is nullable
                propertyLabel: t.k ? filteringOptionsKeyToPropertiesMapping[t.k].propertyLabel : undefined,
                value: t.v,
                label: t.v,
                negated: t.neg,
                isFreeText: t.fr
            } as TablePropertyFiltering.FilteringToken;
        })
    };
};

export const fromSearchIdsToCompressedSearchParams = (ids: string[]): ISearchParams => {
    return {
        op: 'or',
        toks: ids.map(id => {
            return {
                k: 'id',
                v: id,
                neg: false,
                fr: true
            };
        })
    };
};
