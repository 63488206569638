import * as React from 'react';
import { BreadcrumbGroup, Button, Flash, Modal, Tabs } from '@amzn/awsui-components-react';
import { getLinkableUrl, PAGE } from '../../constants/page';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    cancelConfigEditingAction,
    deleteProviderConfigsAction,
    disableConfigEditingAction,
    disableOnboardingProviderAction,
    enableConfigEditingAction,
    getAllProviderConfigsAction,
    getProviderConfigByIdAction,
    getProviderConfigVersionsAction,
    setCompareVersionsAction,
    upsertProviderConfigsAction
} from '../../actions/spiProviderOnboardingActions';
import { AppState } from '../../reducers';
import { SpiProviderOnboardingVersionHistoryTabView } from '../../components/detail/tabs/SpiProviderOnboardingVersionHistoryTabView';
import SpiProviderOnboardingCreateEndpointSectionView from '../sections/SpiProviderOnboardingFormFieldViews/SpiProviderOnboardingEndpointSectionView';
import SpiProviderOnboardingCreateSmallFieldSectionView from '../sections/SpiProviderOnboardingFormFieldViews/SpiProviderOnboardingSmallFieldSectionView';
import SpiProviderOnboardingCreateSupportedSectionView from '../sections/SpiProviderOnboardingFormFieldViews/SpiProviderOnboardingSupportedSectionView';

const SpiProviderConfig = () => {
    const location = useLocation();
    const { providerId } = useParams<{ providerId: string }>();
    const { isEditing, isLoading, currentProviderConfig, formValidation } = useSelector((state: AppState) => state.spiProviderOnboardingState);
    const dispatch = useDispatch();

    const [deleteModalVisible, setDeleteModalVisible] = React.useState({
        deleteModalVisible: false
    });

    const [tabState, setTabState] = React.useState({
        activeTabId: 'spi-provider-onboarding-configure',
        isConfigureTab: true
    });

    React.useEffect(() => {
        dispatch(getProviderConfigVersionsAction(providerId));
        dispatch(getProviderConfigByIdAction(providerId));
        dispatch(disableOnboardingProviderAction());
        dispatch(getAllProviderConfigsAction());
        dispatch(disableConfigEditingAction());
        dispatch(setCompareVersionsAction([]));
    }, [dispatch, providerId]);

    const isFormValid = Object.values(formValidation).every(isInvalid => !isInvalid);

    const editButtonClickHandler = () => {
        dispatch(enableConfigEditingAction());
    };

    const cancelButtonClickHandler = () => {
        dispatch(cancelConfigEditingAction(providerId));
    };

    const goBackButtonClickHandler = () => {
        window.location.assign(getLinkableUrl('/admin/spiProviderOnboarding'));
    };

    const saveButtonClickHandler = () => {
        if (currentProviderConfig) {
            dispatch(upsertProviderConfigsAction(providerId, currentProviderConfig));
        }
    };

    const deleteButtonClickHandler = () => {
        dispatch(deleteProviderConfigsAction(providerId));
        toggleDeleteModalVisibility();
        window.location.assign(getLinkableUrl('/admin/spiProviderOnboarding'));
    };

    const toggleDeleteModalVisibility = () => {
        setDeleteModalVisible({ deleteModalVisible: !deleteModalVisible.deleteModalVisible });
    };

    const getSpiProviderOnboardingTabs = (): Tabs.Tab[] => {
        return [{
            label: 'Configure',
            id: 'spi-provider-onboarding-configure',
            content:
            <div>
                <SpiProviderOnboardingCreateSmallFieldSectionView/>
                <SpiProviderOnboardingCreateEndpointSectionView/>
                <SpiProviderOnboardingCreateSupportedSectionView/>
            </div>

        }, {
            label: 'Version History',
            id: 'spi-provider-onboarding-version-history',
            content: <SpiProviderOnboardingVersionHistoryTabView />
        }];
    };

    const letLoad = () => {
        if (isLoading) {
            return (<div><Flash dismissible={false} >Loading...</Flash></div>);
        }
        return (<div>
                    <span className='awsui-util-f-r'>
                    {(() => {
                        if (!isEditing && tabState.activeTabId === 'spi-provider-onboarding-configure') {
                            return (
                                <>
                                    <Button
                                        id='button.goBack'
                                        icon='undo'
                                        variant='link'
                                        onClick={goBackButtonClickHandler}
                                        >Go Back</Button>
                                    <Button
                                        id='button.deleteDisabled'
                                        icon='status-warning'
                                        disabled={!isEditing}
                                        >Delete Provider</Button>
                                    <Button
                                        id='button.edit'
                                        icon='edit'
                                        onClick={editButtonClickHandler}
                                        >Edit</Button>
                                </>
                            );
                        } else if (!isEditing && tabState.activeTabId === 'spi-provider-onboarding-version-history') {
                            return (
                                <>
                                    <Button
                                        icon='undo'
                                        variant='link'
                                        onClick={goBackButtonClickHandler}
                                    >Go Back</Button>
                                </>
                            );
                        } else {
                            return (
                                <>
                                    <Button
                                        id='button.cancel'
                                        icon='undo'
                                        onClick={cancelButtonClickHandler}
                                    >Cancel</Button>
                                    <Button
                                        id='button.delete'
                                        icon='status-warning'
                                        variant='primary'
                                        onClick={toggleDeleteModalVisibility}
                                        >Delete Provider</Button>
                                    <Button
                                        id='button.save'
                                        icon='upload'
                                        disabled={!isFormValid}
                                        onClick={saveButtonClickHandler}
                                        variant='primary'
                                    >Save</Button>
                                </>
                            );
                        }
                    })()}
                    </span>
                    <Tabs
                        tabs={getSpiProviderOnboardingTabs()}
                        activeTabId={tabState.activeTabId}
                        onChange={event => {
                            setTabState(prevState => {
                                if (prevState.isConfigureTab === true) {
                                    return {
                                        activeTabId: 'spi-provider-onboarding-version-history',
                                        isConfigureTab: false
                                    };
                                } else {
                                    return {
                                        activeTabId: 'spi-provider-onboarding-configure',
                                        isConfigureTab: true
                                    };
                                }
                            });
                        }}
                        />
                </div>);
    };

    return (
        <div>
            <Modal
                id='modal.deleteProvider'
                visible={deleteModalVisible.deleteModalVisible}
                onDismiss={toggleDeleteModalVisibility}
                header='Delete Provider'
                footer={
                    <span className='awsui-util-f-r'>
                    <Button
                        id='button.modal.cancel'
                        variant='link'
                        onClick={toggleDeleteModalVisibility}>
                        Cancel</Button>
                    <Button
                        id='button.modal.delete'
                        variant='primary'
                        icon='status-warning'
                        onClick={deleteButtonClickHandler}
                        >Delete Provider</Button>
                </span>
                }
            >
            Are you sure you want to delete this provider?
            </Modal>
            <div style={{ margin: '30px' }}>
                <div style={{ marginBottom: '20px' }}>
                    <BreadcrumbGroup label='Breadcrumbs.configure' items={[{
                        text: 'Odyssey',
                        href: getLinkableUrl(PAGE.Home),
                    }, {
                        text: 'Admin Dashboard',
                        href: getLinkableUrl(PAGE.AdminDashboard)
                    }, {
                        text: 'SPI Providers',
                        href: getLinkableUrl(PAGE.SpiProviderOnboarding),
                    }, {
                        text: providerId,
                        href: getLinkableUrl(location.pathname),
                    }
                    ]} />
                </div>
                <div>
                    <h1>{providerId}</h1>
                </div>
                {letLoad()}
            </div>
        </div>
    );
};

export default SpiProviderConfig;