export const LOADING_ALEXALAB_METRICS = 'LOADING_ALEXALAB_METRICS';
export const LOADED_ALEXALAB_METRICS = 'LOADED_ALEXALAB_METRICS';
export const ERROR_LOADING_ALEXALAB_METRICS = 'ERROR_LOADING_ALEXALAB_METRICS';
export const LOADING_CX_WEEKLY_METRICS = 'LOADING_CX_WEEKLY_METRICS';
export const LOADED_CX_WEEKLY_METRICS = 'LOADED_CX_WEEKLY_METRICS';
export const ERROR_LOADING_CX_WEEKLY_METRICS = 'ERROR_LOADING_CX_WEEKLY_METRICS';
export const LOADING_CX_DAILY_METRICS = 'LOADING_CX_DAILY_METRICS';
export const LOADED_CX_DAILY_METRICS = 'LOADED_CX_DAILY_METRICS';
export const ERROR_LOADING_CX_DAILY_METRICS = 'ERROR_LOADING_CX_DAILY_METRICS';
export const LOADING_SIMILAR_EXPERIENCES = 'LOADING_SIMILAR_EXPERIENCES';
export const LOADED_SIMILAR_EXPERIENCES = 'LOADED_SIMILAR_EXPERIENCES';
export const ERROR_LOADING_SIMILAR_EXPERIENCES = 'ERROR_LOADING_SIMILAR_EXPERIENCES';
export const LOADING_FILTER_DAILY_METRICS = 'LOADING_FILTER_METRICS';
export const LOADED_FILTER_DAILY_METRICS = 'LOADED_FILTER_METRICS';
export const ERROR_LOADING_FILTER_DAILY_METRICS = 'ERROR_LOADING_FILTER_METRICS';
export const LOADING_RCP_CX_DAILY_METRICS = 'LOADING_RCP_CX_DAILY_METRICS';
export const LOADED_RCP_CX_DAILY_METRICS = 'LOADED_RCP_CX_DAILY_METRICS';
export const ERROR_LOADING_RCP_CX_DAILY_METRICS = 'ERROR_LOADING_RCP_CX_DAILY_METRICS';
export const LOADING_RCP_CX_WEEKLY_METRICS = 'LOADING_RCP_CX_WEEKLY_METRICS';
export const LOADED_RCP_CX_WEEKLY_METRICS = 'LOADED_RCP_CX_WEEKLY_METRICS';
export const ERROR_LOADING_RCP_CX_WEEKLY_METRICS = 'ERROR_LOADING_RCP_CX_WEEKLY_METRICS';
