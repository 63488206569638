import * as React from 'react';
import { ColumnLayout, Spinner, FormField, Multiselect, Select } from '@amzn/awsui-components-react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { DEVICES, VCIF_DEVICES } from '../../constants/devices';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { pushDevicesSectionAction } from '../../actions/experienceEditViewActions';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { CIFValidator } from '../../util/CIFValidator';
import { FlashbarWrapper } from '../../components/common/FlashbarWrapper';


interface IVCIFDevicesSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

interface IVCIFDevicesSectionViewState {
    avsDeviceInput?: string;
    deviceSelections?: Select.Option[];
}

export class VCIFDevicesSectionView extends React.Component<IVCIFDevicesSectionViewProps, IVCIFDevicesSectionViewState>  {

    constructor(props: IVCIFDevicesSectionViewProps) {
        super(props);
        this.state = {avsDeviceInput: '', deviceSelections: generateVCIFDeviceOptions()};
    }

    updateDevices(devices?: string[]) {
        const { dispatch } = this.props;
        dispatch(pushDevicesSectionAction(devices));
    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate } = this.props;

        if (!experience) {
            return <FormSectionView title='Devices'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const supportedDevices = Object.keys(DEVICES);
        const devices: string[] = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
            ? updateCandidate.getDevices()
            : ExperienceUpdateCandidate.extractDevices(experience));

        const nonAvsDevices: string[] = [];
        const avsDevices: string[] = [];

        devices.forEach((deviceId) => {
            supportedDevices.includes(deviceId) ? nonAvsDevices.push(deviceId) : avsDevices.push(deviceId);
        });

        const shouldDisableInput = !isEditing || isUpdating;

        return <FormSectionView title='Device Targeting' description='
            At least one device must be selected.
            Please select device(s) appropriate for your experience'
        >
            {!shouldDisableInput && <FlashbarWrapper validate={() => CIFValidator.isDevicesValid(devices)} />}
            <ColumnLayout columns={2} >
                <div data-awsui-column-layout-root='true'>
                    <FormField label={<RequiredField fieldName='Echo Family multimodal Devices' />}>
                        <Multiselect
                            id='experience.devices-non-avs-multiselect'
                            controlId='experience.devices-non-avs-multiselect'
                            selectedIds={nonAvsDevices}
                            options={this.state.deviceSelections}
                            disabled={shouldDisableInput}
                            onChange={(event) => {this.updateDevices(event.detail.selectedIds.concat(avsDevices));}}
                            filteringType='auto'
                            checkboxes={true}
                        />
                    </FormField>
                </div>
            </ColumnLayout>
        </FormSectionView>;
    }
}

export const generateVCIFDeviceOptions = (): Select.Option[] => {
    return Object.keys(VCIF_DEVICES)
        .map((deviceId, index) => {
            return {
                label: `${DEVICES[deviceId].name} (${DEVICES[deviceId].code})`,
                id: deviceId,
                tags: ['Multimodal']
            };
        });
};

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(VCIFDevicesSectionView);
