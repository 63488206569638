import * as React from 'react';
import ContentSectionView from '../../../containers/sections/ContentSectionView';
import ContentVariablesSectionView from '../../../containers/sections/ContentVariablesSectionView';
import CifDevicesSectionView from '../../../containers/sections/CifDevicesSectionView';

export const CifInjectionTabView = () => {

    return <div>
        <ContentSectionView/>
        <ContentVariablesSectionView />
        <CifDevicesSectionView />
    </div>;
};
