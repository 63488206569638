import { FormField, Input } from '@amzn/awsui-components-react';
import * as React from 'react';
import { Component } from 'react';
import { showDisplayTextValue } from '../../HomeCardSuggestionDetailGlobalView';
import { IHomeCardSuggestion } from '../../../../models/HomeCardSuggestion';
import { TextAreaWrapper } from '../../../common/TextAreaWrapper';
import { ExternalLink } from '../../../common/LinkComponents';
import { bestEffortJsonFormat } from '../../../../util/stringAndMappingHelper';

export default class HomeCardSuggestionDetailTextView extends Component<{
    suggestion: IHomeCardSuggestion,
}> {
    public render() {
        const { suggestion } = this.props;

        return <div>
            <div className='awsui-util-mb-m'>
                <FormField
                    label='Header text'
                >
                    <Input
                        readonly={true}
                        value={showDisplayTextValue(suggestion.suggestion.variants, 'headerText')}
                        controlId='suggestion.header-text'
                        id='suggestion.header-text' />
                </FormField>
            </div>
            <div className='awsui-util-mb-m'>
                <FormField
                    label='Primary text'
                >
                    <Input
                        readonly={true}
                        value={showDisplayTextValue(suggestion.suggestion.variants, 'primaryText')}
                        controlId='suggestion.primary-text'
                        id='suggestion.primary-text'
                    />
                </FormField>
            </div>
            <div className='awsui-util-mb-m'>
                <FormField
                    label='Secondary text'
                >
                    <Input
                        readonly={true}
                        value={showDisplayTextValue(suggestion.suggestion.variants, 'secondaryText')}
                        controlId='suggestion.secondary-text'
                        id='suggestion.secondary-text'
                    />
                </FormField>
            </div>
            <div className='awsui-util-mb-m'>
                <FormField
                    label='Tertiary text'
                >
                    <Input
                        readonly={true}
                        value={showDisplayTextValue(suggestion.suggestion.variants, 'tertiaryText')}
                        controlId='suggestion.tertiary-text'
                        id='suggestion.tertiary-text'
                    />
                </FormField>
            </div>
            <div className='awsui-util-mb-m'>
                <FormField
                    label='Action'
                >
                    <TextAreaWrapper
                        readonly={true}
                        value={bestEffortJsonFormat(JSON.stringify(showDisplayTextValue(suggestion.suggestion.variants, 'action')))}
                        id='suggestion.action'
                        validate={item => item}
                    />
                </FormField>
            </div>
            <div className='awsui-util-mb-m'>
                <FormField
                    label='Enable Playback'
                >
                    <Input
                        readonly={true}
                        value={showDisplayTextValue(suggestion.suggestion.variants, 'enablePlayback')}
                        controlId='suggestion.enable-playback'
                        id='suggestion.enable-playback'
                    />
                </FormField>
            </div>
            <div className='awsui-util-mb-m'>
                <FormField
                    label='Attribution text'
                >
                    <Input
                        readonly={true}
                        value={showDisplayTextValue(suggestion.suggestion.variants, 'attributionText')}
                        controlId='suggestion.attribution-text'
                        id='suggestion.attribution-text'
                    />
                </FormField>
            </div>
            <div className='awsui-util-mb-m'>
                <FormField
                    label='Hint text'
                >
                    <Input
                        readonly={true}
                        value={showDisplayTextValue(suggestion.suggestion.variants, 'hintText')}
                        controlId='suggestion.hint-text'
                        id='suggestion.hint-text'
                    />
                </FormField>
            </div>
            <div className='awsui-util-mb-m'>
                <FormField
                    label='Background image source'
                >
                    <ExternalLink href={showDisplayTextValue(suggestion.suggestion.variants, 'backgroundImageSource')}>
                        {showDisplayTextValue(suggestion.suggestion.variants, 'backgroundImageSource')}
                    </ExternalLink>
                </FormField>
            </div>
            <div className='awsui-util-mb-m'>
                <FormField
                    label='Activation start time'
                >
                    <Input
                        readonly={true}
                        value={suggestion.constraints[0].startTime}
                        controlId='suggestion.activation-start-time'
                        id='suggestion.activation-start-time'
                    />
                </FormField>
            </div>
            <div className='awsui-util-mb-m'>
                <FormField
                    label='Activation end time'
                >
                    <Input
                        readonly={true}
                        value={suggestion.constraints[0].endTime}
                        controlId='suggestion.activation-end-time'
                        id='suggestion.activation-end-time'
                    />
                </FormField>
            </div>
        </div>;
    }
}

