import * as React from 'react';
import _ from 'lodash';
import { Checkbox, ColumnLayout, FormField, Multiselect, Select } from '@amzn/awsui-components-react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { DEVICES, RCP_DEVICES } from '../../constants/devices';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { pushRotatingContentPanelDevicesConstraint } from '../../actions/experienceEditViewActions';
import { AppState } from '../../reducers/index';
import { useDispatch, useSelector } from 'react-redux';
import { RequiredField } from '../../components/common/DescriptionAnnotations';

const RCPDevicesSectionView: React.FC = () => {

    const dispatch = useDispatch();
    const { isEditing, isUpdating, updateCandidate } = useSelector((appState: AppState) => appState.experienceEditViewState );
    const { experience } = useSelector((appState: AppState) => appState.odysseyExperienceDetailViewState);
    const deviceSelections = generateRCPDeviceOptions();

    const supportedDevices = Object.keys(DEVICES);
    const devices: string[] = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
        ? updateCandidate.getRotatingContentPanelDevices()
        : _.get(experience, 'constraints.DeviceTypesConstraint.deviceTypes', []) ?? []);

    const [currentState, setState] = React.useState(devices.length === 0);

    const nonAvsDevices: string[] = [];
    const avsDevices: string[] = [];

    devices.forEach((deviceId) => {
        supportedDevices.includes(deviceId) ? nonAvsDevices.push(deviceId) : avsDevices.push(deviceId);
    });

    const shouldDisableInput = !isEditing || isUpdating;

    const updateDevices = (deviceList?: string[]) => {
        if (deviceList) {
            setState(deviceList.length === 0);
            dispatch(pushRotatingContentPanelDevicesConstraint(deviceList));
        }
    };

    const selectAllDevices = (event: CustomEvent<Checkbox.ChangeDetail>) => {
        const checkStatus = event.detail.checked;
        if (checkStatus) {
            setState(checkStatus);
            dispatch(pushRotatingContentPanelDevicesConstraint([]));
        }
    };

    return <FormSectionView title='Device Targeting' description='
            At least one device must be selected.
            Please select device(s) appropriate for your experience'
        >
            <ColumnLayout columns={2} >
                <div data-awsui-column-layout-root='true'>
                    <FormField label={<RequiredField fieldName='Echo Family multimodal Devices' />}>
                        <Multiselect
                            id='experience.devices-non-avs-multiselect'
                            controlId='experience.devices-non-avs-multiselect'
                            selectedIds={nonAvsDevices}
                            options={deviceSelections}
                            disabled={shouldDisableInput}
                            onChange={(event) => {updateDevices(event.detail.selectedIds.concat(avsDevices));}}
                            filteringType='auto'
                            checkboxes={true}
                        />
                    </FormField>
                    <FormField label={'Select All'}>
                        <Checkbox onChange={selectAllDevices} checked={currentState} disabled={shouldDisableInput} />
                    </FormField>
                </div>
            </ColumnLayout>
        </FormSectionView>;
};

export const generateRCPDeviceOptions = (): Select.Option[] => {
    return Object.keys(RCP_DEVICES)
        .map((deviceId, index) => {
            return {
                label: `${DEVICES[deviceId].name} (${DEVICES[deviceId].code})`,
                id: deviceId,
                tags: ['Multimodal']
            };
        });
};

export default RCPDevicesSectionView;
