import React from 'react';
import { Table, TableSorting } from '@amzn/awsui-components-react';
import { IAlexaLabMetrics, IAlexaLabMetric, AlexaLabMetricName, AlexaLabTreatment } from '../../models/AlexaLabMetrics';
import ExperienceMetricsReportTimePopover from './ExperienceMetricsReportTimePopover';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { pValueThreshold } from '../../constants/dialUpCriteriaConstant';
import { isPercentLiftPositive, isStatisticallySignificant } from '../../util/metricsCriteriaUtil';

export const IntentPerformanceCell = ({ metric }: { metric?: IAlexaLabMetric }) => {
    if (!metric || isNaN(Number(metric.data.percentLift))) {
        return <div className='awsui-util-status-inactive'>-</div>;
    }

    const { percentLift, pvalue } = metric.data;

    const sign = percentLift > 0 ? '+' : '';

    let className = '';

    if (isStatisticallySignificant(pvalue)) {
        className = isPercentLiftPositive(percentLift) ? 'awsui-util-status-info': 'awsui-util-status-negative';
    }

    return <ExperienceMetricsReportTimePopover alexaLabMetric={metric}>
        <div className={className} >{`${sign}${percentLift.toFixed(2)}%`}</div>
    </ExperienceMetricsReportTimePopover>;
};

export const IntentNameCell = ({ metric }: { metric: IntentMetrics }) => {
    const intentType = metric.suggested? 'Suggested Intent' : 'Trigger Intent';
    return <div>
                <div>{metric.intent}</div>
                <div className='awsui-util-status-inactive'>{intentType}</div>
            </div>;
};

export const PValueCell = ({ pValue }: { pValue: number | undefined }) => {
    const significance = (pValue && pValue <= pValueThreshold) ? 'Statistically Significant' : 'Statistically Insignificant';
    return <div>
                <div>{significance}</div>
            </div>;
};

export interface IntentMetrics {
    intent: string;
    metrics?: {
        [intent in AlexaLabMetricName]?: IAlexaLabMetric
    };
    suggested: boolean;
}

const IntentPerformanceTableColumnDefinitions = [
    {
        id: 'intent',
        header: 'Intent',
        cell: (intentMetrics: IntentMetrics) => <IntentNameCell metric={intentMetrics} />,
        width: 175,
    },
    {
        id: 'activeDays',
        header: 'Active Days',
        cell: (intentMetrics: IntentMetrics) =>
            <IntentPerformanceCell metric={intentMetrics?.metrics?.[AlexaLabMetricName.ACTIVE_DAYS]} />,
        width: 125,
    },
    {
        id: 'activeDaysPValue',
        header: 'Active Days p-value',
        cell: (intentMetrics: IntentMetrics) =>
            <PValueCell pValue={intentMetrics?.metrics?.[AlexaLabMetricName.ACTIVE_DAYS]?.data.pvalue} />,
        width: 200,
    },
    {
        id: 'dialogs',
        header: 'Day-1 Excluded Dialogs',
        cell: (intentMetrics: IntentMetrics) =>
            <IntentPerformanceCell metric={intentMetrics?.metrics?.[AlexaLabMetricName.DIALOGS_DAY_ONE_EXCLUDED]} />,
        width: 200,
    },
    {
        id: 'dialogsPValue',
        header: 'Day-1 Excluded Dialogs p-value',
        cell: (intentMetrics: IntentMetrics) =>
            <PValueCell pValue={intentMetrics?.metrics?.[AlexaLabMetricName.DIALOGS_DAY_ONE_EXCLUDED]?.data.pvalue} />,
        width: 200,
    },
];

const IntentPerformanceTable = ({ intentMetricsList }: { intentMetricsList: IntentMetrics[] }) => {
    return <Table
        id='table.intent-performance'
        columnDefinitions={IntentPerformanceTableColumnDefinitions}
        items={intentMetricsList}
        loadingText='Loading intent metrics'
        loading={false}
        empty={<div>No intent level metrics found</div>}
        resizableColumns={true}
    >
        <TableSorting
            sortingColumn='intent'
            sortableColumns={[{ id: 'intent', field: 'dimension.value' }]}
        />
    </Table>;
};

export const getAllIntents = (experience: IFlattenedExperience): string[] => {
    const configuredIntents = getConfiguredIntents(experience);
    const result = [
        ...configuredIntents,
        ...experience.suggestedFeature?.nluIntent? [experience.suggestedFeature?.nluIntent] : []
    ];
    return result as string[];
};

export const getConfiguredIntents = (experience: IFlattenedExperience): string[] => {
    const configuredIntents = experience.nluOpportunityTargetings
        .map((target) => target.bluIntent)
        .filter((intent) => intent);

    return configuredIntents as string[];
};

export const getSuggestedIntents = (experience: IFlattenedExperience): string[] => {
    const suggestedFeatureIntents = experience.suggestedFeature?.nluIntent? [experience.suggestedFeature?.nluIntent] : [];

    return suggestedFeatureIntents;
};

export const ExperienceMetricsIntentPerformance = ({ alexaLabMetrics, experience }: { alexaLabMetrics?: IAlexaLabMetrics, experience: IFlattenedExperience }) => {
    if (!experience.nluOpportunityTargetings) {
        return <div id='metrics.intent-performance' className='awsui-util-container awsui-util-no-gutters' style={{ marginBottom: '0' }}>
        <div className='awsui-util-container-header'>
            Intent performance
            <div className='awsui-util-container-header-description'>
                <div>
                    For 100% dial-up, the trigger intent(s) cannot show a statistically significant regression, and the suggested intent must show a statistically significant improvement in AlexaLab.
                </div>
            </div>
        </div>
    </div>;
    }

    const configuredIntents = getConfiguredIntents(experience);
    const suggestedFeatureIntents = getSuggestedIntents(experience);

    const intentMetrics = configuredIntents.map(intent => ({
        intent,
        metrics: alexaLabMetrics?.mappedMetrics?.[AlexaLabTreatment.T1]?.[AlexaLabTreatment.C]?.[`INTENT:${intent}`],
        suggested: false
    })).concat(suggestedFeatureIntents.map(intent => ({
        intent,
        metrics: alexaLabMetrics?.mappedMetrics?.[AlexaLabTreatment.T1]?.[AlexaLabTreatment.C]?.[`INTENT:${intent}`],
        suggested: true
    })));

    intentMetrics.sort((m1,m2)=> Number(m2.suggested) - Number(m1.suggested));
    return <div id='metrics.intent-performance' className='awsui-util-container awsui-util-no-gutters' style={{ marginBottom: '0' }}>
        <div className='awsui-util-container-header'>
            Intent performance
            <div className='awsui-util-container-header-description'>
                <div>
                    For 100% dial-up, the trigger intent(s) cannot show a statistically significant regression, and the suggested intent must show a statistically significant improvement in AlexaLab.
                </div>
            </div>
        </div>
        <IntentPerformanceTable intentMetricsList={intentMetrics} />
    </div>;
};
