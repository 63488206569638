import React from 'react';
import { IAlexaLabMetrics, AlexaLabTreatment, IAlexaLabMetric } from '../../models/AlexaLabMetrics';
import { KeyValuePairMultipleColumnView } from '../common/GridKeyValuePair';
import ExperienceMetricsReportTimePopover from './ExperienceMetricsReportTimePopover';

const CustomersReached = ({ metric, value }: { metric?: IAlexaLabMetric, value?: number }) => {
    if (!metric || !value) {
        return <div className='awsui-util-status-inactive awsui-util-font-size-4'>-</div>;
    }

    return <ExperienceMetricsReportTimePopover alexaLabMetric={metric}>
        <div className='awsui-util-font-size-4'>{`~${value.toLocaleString()}`}</div>
    </ExperienceMetricsReportTimePopover>;
};

export const ExperienceMetricsCustomersReached = ({ alexaLabMetrics }: { alexaLabMetrics?: IAlexaLabMetrics }) => {
    const metrics: IAlexaLabMetric[] = Object.values(alexaLabMetrics?.mappedMetrics?.[AlexaLabTreatment.T1]?.[AlexaLabTreatment.C]?.['NO_DIMENSION:'] || {})
        .filter(m => m !== undefined) as IAlexaLabMetric[];
    const metric = metrics.reduce((lastExecutedMetric: IAlexaLabMetric | undefined, m: IAlexaLabMetric) => {
        if (lastExecutedMetric === undefined || lastExecutedMetric.metadata.apiExecutionTime < m.metadata.apiExecutionTime) {
            return m;
        }

        return lastExecutedMetric;
    }, undefined);

    return <div id='metrics.customers' className='awsui-util-container' style={{ height: '100%' }}>
        <div className='awsui-util-container-header'>
            Customers reached
        </div>
        <KeyValuePairMultipleColumnView
            data={[[{
                key: 'Control',
                value: <CustomersReached metric={metric} value={metric?.data?.controlSampleSize} />
            }], [{
                key: 'Treatment',
                value: <CustomersReached metric={metric} value={metric?.data?.treatmentSampleSize} />
            }]]}
        />
    </div>;
};
