import React from 'react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { TESTING_CONFIRMATION_PROMPT } from '../../util/stringAndMappingHelper';
import { generateOdysseyExperienceApprovalAction } from '../../actions/experienceEditViewActions';
import { generateMhcSimApprovalTemplate } from '../../util/simMhcApprovalTemplateHelper';
import { AppState } from '../../reducers';
import { connect } from 'react-redux';
import { Form, FormField, Button, Modal, Alert, Input, Checkbox, ColumnLayout, Textarea } from '@amzn/awsui-components-react';
import { ExternalLink } from '../common/LinkComponents';

interface MhcValidationModalProps {
    dispatch: any;
    experience?: IFlattenedExperience;
    isVisible: boolean;
    hideModal: () => void;
    permissions: string[];
}

interface MhcValidationModalState {
    testingConfirmation?: string;
    cxDescription?: string;
    bullseyeDescription?: string;
    isMonetaryExperience?: 'true' | 'false'; // using string boolean for easier checking

}

class MhcValidationModal extends React.Component<MhcValidationModalProps, MhcValidationModalState> {
    private static INITIAL_QUESTIONNAIRE_STATE: MhcValidationModalState = {
        testingConfirmation: undefined,
        cxDescription: undefined,
        bullseyeDescription: undefined,
        isMonetaryExperience: undefined,
    };

    constructor(props: MhcValidationModalProps) {
        super(props);
        this.state = MhcValidationModal.INITIAL_QUESTIONNAIRE_STATE;
    }

    updateState(object: { [key: string]: any }) {
        this.setState(object);
    }

    componentDidUpdate(prevProps: MhcValidationModalProps) {
        // if modal becomes visible again, need to clear off all previous state to a fresh state
        if (this.props.isVisible === true && prevProps.isVisible === false) {
            this.setState(MhcValidationModal.INITIAL_QUESTIONNAIRE_STATE);
        }
    }

    private validateTemplateCompletion(): boolean {

        const {
            testingConfirmation,
            cxDescription,
            bullseyeDescription,
            isMonetaryExperience,
        } = this.state;

        return testingConfirmation === TESTING_CONFIRMATION_PROMPT
            && !!cxDescription
            && !!bullseyeDescription
            && !!isMonetaryExperience;

    }

    private approveButtonClickHandler() {
        const { dispatch, hideModal, experience } = this.props;
        const {
            testingConfirmation,
            cxDescription,
            bullseyeDescription,
            isMonetaryExperience,
        } = this.state;

        const userHasTestedExperience = testingConfirmation === TESTING_CONFIRMATION_PROMPT;

        if (this.validateTemplateCompletion()) {
            hideModal();
            dispatch(generateOdysseyExperienceApprovalAction(
                generateMhcSimApprovalTemplate(
                    experience!, userHasTestedExperience, cxDescription!, bullseyeDescription!, isMonetaryExperience!)
            )
            );
        }
    }

    render() {
        const { isVisible, experience, hideModal } = this.props;
        const { testingConfirmation, cxDescription, bullseyeDescription, isMonetaryExperience } = this.state;

        if (experience === undefined || experience === null) {
            return <Modal>
                <div className='awsui-util-inactive'>
                    You cannot send an experience for approval right now. Please try again later.
                </div>
            </Modal>;
        }

        const bullseyeConfigured = (experience?.bullseyeSegment != null);

        return <Modal
            id='modal.send-mch-for-approval'
            visible={isVisible}
            header='Send for Approval'
            onDismiss={hideModal.bind(this)}
        >

            {!bullseyeConfigured &&
                <Alert
                    visible={true}
                    type='error'
                    id='alert.bullseye-not-configured'>
                    Warning: Your experience does not have bullseye configured which may result in
                    non-relevant suggestions
                </Alert>}
            <Form
                actions={<div className='awsui-util-mt-l'>
                    <Button
                        id='button.send-mhc-for-approval-cancel'
                        variant='link'
                        onClick={hideModal.bind(this)}>
                        Cancel
                    </Button>
                    <Button variant='primary'
                        id='button.send-mhc-for-approval-submit'
                        disabled={!this.validateTemplateCompletion()}
                        onClick={this.approveButtonClickHandler.bind(this)}>
                        Send for approval
                    </Button>
                </div>}
            >
                <ColumnLayout>
                    <div data-awsui-column-layout-root='true'>
                        <p>
                            Visit&nbsp;
                            <ExternalLink href={'https://w.amazon.com/bin/view/FindingYourCustomerID/'}>here</ExternalLink>
                            to find your Customer ID if logged in to your Amazon shopping account.
                        </p>
                        <FormField
                            label='Testing Confirmation'
                            description='Please type `I have fully tested this experience` to confirm testing.'>
                            <Input
                                id='experience.send-for-approval-user-has-tested'
                                placeholder='I have fully tested this experience'
                                name='testingConfirmation'
                                value={testingConfirmation}
                                onChange={e => this.updateState({ testingConfirmation: e.detail.value.trim() })}
                            />
                        </FormField>

                    </div>
                </ColumnLayout>
                <hr />
                <ColumnLayout>
                    <div data-awsui-column-layout-root='true'>
                        <FormField label='Describe the CX in detail'>
                            <Textarea
                                id='approval.cxDescription'
                                placeholder='Experience CX description'
                                name='cxDescription'
                                value={cxDescription}
                                onChange={e => this.updateState({ cxDescription: e.detail.value })}
                            />
                        </FormField>
                        <FormField label='Describe your Bullseye segment in detail'>
                            <Textarea
                                id='approval.bullseyeDescription'
                                placeholder='What segment of customers is your Bullseye intended to target?'
                                name='bullseyeDescription'
                                value={bullseyeDescription}
                                onChange={e => this.updateState({ bullseyeDescription: e.detail.value })}
                            />
                        </FormField>
                        <FormField label='Does this experience provide any monetary value (cash rewards, discounts, etc.) to Alexa customers?'>
                            <div className='awsui-grid'>
                                <div className='awsui-row awsui-util-t-c'>
                                    <div className='col-3'>
                                        <Checkbox
                                            id='approval.isMonetaryExperience.y'
                                            name='isMonetaryExperience.y'
                                            checked={isMonetaryExperience === 'true'}
                                            onChange={e => this.updateState({ isMonetaryExperience: String(e.detail.checked) })}
                                        >
                                            Yes
                                        </Checkbox>
                                    </div>
                                    <div className='col-3'>
                                        <Checkbox
                                            id='approval.isMonetaryExperience.n'
                                            name='isMonetaryExperience.n'
                                            checked={isMonetaryExperience === 'false'}
                                            onChange={e => this.updateState({ isMonetaryExperience: String(!e.detail.checked) })}
                                        >
                                            No
                                        </Checkbox>
                                    </div>
                                </div>
                            </div>
                        </FormField>
                    </div>
                </ColumnLayout>
            </Form>
        </Modal>;
    }


}

const mapStateToProps = ({ odysseyExperienceDetailViewState, authenticationState }: AppState) => {
    return {
        experience: odysseyExperienceDetailViewState.experience,
        permissions: authenticationState.permissions || []
    };
};

export default connect(mapStateToProps)(MhcValidationModal);
