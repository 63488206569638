import * as React from 'react';
import {Wizard} from '@amzn/awsui-components-react';

import NluOpportunityTriggeringSectionView from '../sections/NluOpportunityTriggeringSectionView';
import ActivationDatesSectionView from '../sections/ActivationDatesSectionView';
import DayTimeWindowSectionView from '../sections/DayTimeWindowSectionView';
import RegionSectionView from '../sections/RegionSectionView';
import BullseyeSectionView from '../sections/BullseyeSectionView';
import VisualInterstitialSectionView from '../sections/VisualInterstitialSectionView';
import SuggestedFeatureSectionView from '../sections/SuggestedFeatureSectionView';
import AudienceTypeSectionView from '../sections/AudienceTypeSectionView';
import FeatureHintSectionView from '../sections/FeatureHintSectionView';
import DomainTagsSectionView from '../sections/DomainTagsSectionView';
import RemoteTagsSectionView from '../sections/RemoteTagsSectionView';
import JourneySectionView from '../sections/JourneySectionView';
import { ExperienceTypeSectionView } from '../../components/detail/creation/ExperienceTypeSectionView';
import { ExperienceType } from '../../models/Experience';
import BasicCustomerTargetingView from '../sections/BasicCustomerTargetingView';
import VCIFDevicesSectionView from '../sections/VCIFDevicesSectionView';
import VoiceContentSectionView from '../sections/VoiceContentSectionView';
import PromotedCapabilitySectionView from '../sections/PromotedCapabilitySectionView';
import ContentVariablesSectionView from '../sections/ContentVariablesSectionView';

export const VisualCIFWorkflow = (
        isAdmin: boolean,
        onCXGuidelinesChecked: (checked?: boolean) => void,
        onEnableVoiceCifChecked: (checked?: boolean) => void,
        onCardConfiguration: (state?: boolean) => void
    ): Wizard.Step[] => {
    return [
        {
            title: 'Customer targeting',
            content: <div>
                <ActivationDatesSectionView />
                <DayTimeWindowSectionView isVisualCIF={true}/>
                <BasicCustomerTargetingView />
                <VCIFDevicesSectionView />
                <NluOpportunityTriggeringSectionView triggerGrouping={'VisualCIFTriggers'} />
            </div>
        },
        {
            title: 'Content',
            content: <div>
                <VisualInterstitialSectionView
                onCardConfigurationUpdate={(configured) => onCardConfiguration(configured)}
                isAdmin={isAdmin} />
                <VoiceContentSectionView
                    onContentCXGuidelinesCheckedUpdate={(checked) => onCXGuidelinesChecked(checked)}
                    onEnableVoiceCifCheckedUpdate={(checked) => onEnableVoiceCifChecked(checked)} />
                <ContentVariablesSectionView />
                <PromotedCapabilitySectionView />
                <SuggestedFeatureSectionView />
            </div>
        },
        {
            title: 'Advanced configuration',
            content: <div>
                <AudienceTypeSectionView />
                <FeatureHintSectionView />
                <DomainTagsSectionView />
                <RemoteTagsSectionView />
                <JourneySectionView />
            </div>
        },
        {
            title: 'Review experience',
            content: <div>
                <ExperienceTypeSectionView
                        selectedExperienceType={'VisualCIF' as ExperienceType}
                        disableTypeSelection={true} />
                <ActivationDatesSectionView />
                <DayTimeWindowSectionView />
                <RegionSectionView />
                <BullseyeSectionView />
                <VCIFDevicesSectionView />
                <NluOpportunityTriggeringSectionView triggerGrouping={'VisualCIFTriggers'} />
                <VisualInterstitialSectionView />
                <VoiceContentSectionView
                    onContentCXGuidelinesCheckedUpdate={(checked) => onCXGuidelinesChecked(checked)}
                    onEnableVoiceCifCheckedUpdate={(checked) => onEnableVoiceCifChecked(checked)} />
                <ContentVariablesSectionView />
                <PromotedCapabilitySectionView />
                <SuggestedFeatureSectionView />
                <AudienceTypeSectionView />
                <FeatureHintSectionView />
                <DomainTagsSectionView />
                <RemoteTagsSectionView />
                <JourneySectionView />
            </div>
        }
    ];
};
