import { AnyAction } from 'redux';
import {
    LOADING_PROVIDER_CONFIGS,
    LOADED_PROVIDER_CONFIGS,
    ERROR_LOADING_PROVIDER_CONFIGS,
    LOADING_PROVIDER_VERSION_HISTORY,
    LOADED_PROVIDER_VERSION_HISTORY,
    ERROR_LOADING_PROVIDER_VERSION_HISTORY,
    UPSERTING_PROVIDER_CONFIG,
    UPSERTED_PROVIDER_CONFIG,
    ERROR_UPSERTING_PROVIDER_CONFIG,
    DELETING_PROVIDER_CONFIG,
    DELETED_PROVIDER_CONFIG,
    ERROR_DELETING_PROVIDER_CONFIG,
    LOADING_PROVIDER_CONFIG_BY_ID,
    LOADED_PROVIDER_CONFIG_BY_ID,
    ERROR_LOADING_PROVIDER_CONFIG_BY_ID,
    ENABLE_CONFIG_EDIT,
    CANCEL_CONFIG_EDIT,
    UPDATE_CURRENT_PROVIDER_CONFIG,
    DISABLE_CONFIG_EDIT,
    SET_CONFIG_TO_VERSION,
    LOADING_PROVIDER_CONFIG_BY_ID_AND_VERSION,
    LOADED_PROVIDER_CONFIG_BY_ID_AND_VERSION,
    ERROR_LOADING_PROVIDER_CONFIG_BY_ID_AND_VERSION,
    ENABLE_ONBOARDING_PROVIDER,
    DISABLE_ONBOARDING_PROVIDER,
    UPDATE_FORM_VALIDATION_SECTION,
    SET_COMPARE_VERSIONS
} from '../constants/spiProviderOnboardingActionTypes';
import { IVersionedObject, IProviderConfig } from '../models/spiProviderConfig/ISpiProviderOnboarding';
import { emptyConfig } from '../data/SpiProviderOnboardingTestData';
import { Table } from '@amzn/awsui-components-react';

export interface SpiProviderOnboardingState {
    providerConfigs: { [key: string]: IProviderConfig};
    versionHistory: { [key: string]: { [key: string]: IVersionedObject<IProviderConfig> }};
    formValidation: { [key: string]: boolean };
    compareVersions: Table.Item[];
    currentProviderConfig?: IProviderConfig;
    isOnboardingProvider: boolean;
    isLoading: boolean;
    isLoadingVersionHistory: boolean;
    isEditing: boolean;
    error?: Error;
}

const initialState = {
    providerConfigs: {},
    versionHistory: {},
    formValidation: {
        invalidSmallFieldSection: false,
        invalidEndpointSection: false,
        invalidSupportedSection: false
    },
    compareVersions: [],
    isOnboardingProvider: false,
    isLoading: false,
    isLoadingVersionHistory: false,
    isEditing: false
};

export const spiProviderOnboardingReducer = (
    state: SpiProviderOnboardingState = initialState,
    action: AnyAction
): SpiProviderOnboardingState => {
    switch (action.type) {
        case SET_COMPARE_VERSIONS:
            return {
                ...state,
                compareVersions: action.payload
            };
        case UPDATE_FORM_VALIDATION_SECTION:
            return {
                ...state,
                formValidation: {
                    ...state.formValidation,
                    [action.payload.section]: action.payload.isInvalid
                }
            };
        case SET_CONFIG_TO_VERSION:
            return {
                ...state,
                currentProviderConfig: action.payload
            };
        case ENABLE_ONBOARDING_PROVIDER:
            return {
                ...state,
                currentProviderConfig: emptyConfig,
                isEditing: true,
                isOnboardingProvider: true
            };
        case DISABLE_ONBOARDING_PROVIDER:
            return {
                ...state,
                isEditing: false,
                isOnboardingProvider: false
            };
        case UPDATE_CURRENT_PROVIDER_CONFIG:
            if(state.currentProviderConfig){
                return {
                    ...state,
                    currentProviderConfig: {
                        ...state.currentProviderConfig,
                        ...action.payload
                    }
                };
            } else {
                return state;
            }
        case ENABLE_CONFIG_EDIT:
            return {
                ...state,
                isEditing: true
            };
        case DISABLE_CONFIG_EDIT:
            return {
                ...state,
                isEditing: false
            };
        case CANCEL_CONFIG_EDIT:
            return {
                ...state,
                isEditing: false,
                currentProviderConfig: state.providerConfigs[action.payload]
            };
        case LOADING_PROVIDER_CONFIG_BY_ID_AND_VERSION:
            return {
                ...state,
                isLoading: true
            };
        case LOADED_PROVIDER_CONFIG_BY_ID_AND_VERSION:
            if (!action.payload) {
                return {
                    ...state,
                    isLoading: false
                };
            }
            return {
                ...state,
                isLoading: false,
                currentProviderConfig: action.payload
            };
        case ERROR_LOADING_PROVIDER_CONFIG_BY_ID_AND_VERSION:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case LOADING_PROVIDER_CONFIG_BY_ID:
            return {
                ...state,
                isLoading: true
            };
        case LOADED_PROVIDER_CONFIG_BY_ID:
            if (!action.payload) {
                return {
                    ...state,
                    isLoading: false
                };
            }
            return {
                ...state,
                isLoading: false,
                currentProviderConfig: action.payload
            };
        case ERROR_LOADING_PROVIDER_CONFIG_BY_ID:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case LOADING_PROVIDER_CONFIGS:
            return {
                ...state,
                isLoading: true
            };
        case LOADED_PROVIDER_CONFIGS:
            if (!action.payload) {
                return {
                    ...state,
                    isLoading: false
                };
            }
            return {
                ...state,
                isLoading: false,
                providerConfigs: action.payload.providerConfigs
            };
        case ERROR_LOADING_PROVIDER_CONFIGS:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case LOADING_PROVIDER_VERSION_HISTORY:
            return {
                ...state,
                isLoadingVersionHistory: true
            };
        case LOADED_PROVIDER_VERSION_HISTORY:
            if (!action.payload) {
                return {
                    ...state,
                    isLoadingVersionHistory: false
                };
            }
            return {
                ...state,
                isLoadingVersionHistory: false,
                versionHistory: {
                    ...state.versionHistory,
                    [action.payload.providerId]: action.payload.versionHistory
                }
            };
        case ERROR_LOADING_PROVIDER_VERSION_HISTORY:
            return {
                ...state,
                isLoadingVersionHistory: false,
                error: action.payload
            };
        case UPSERTING_PROVIDER_CONFIG:
            return {
                ...state,
                isLoading: true
            };
        case UPSERTED_PROVIDER_CONFIG:
            return {
                ...state,
                isLoading: false,
                isEditing: false,
                providerConfigs: {
                    ...state.providerConfigs,
                    [action.payload.providerId]: action.payload.providerConfig
                }
            };
        case ERROR_UPSERTING_PROVIDER_CONFIG:
            return {
                ...state,
                isLoading: false,
                isEditing: false,
                error: action.payload
            };
        case DELETING_PROVIDER_CONFIG:
            return {
                ...state,
                isLoading: true
            };
        case DELETED_PROVIDER_CONFIG:
            const remainingConfigs = { ...state.providerConfigs };
            delete remainingConfigs[action.payload];
            return {
                ...state,
                isLoading: false,
                providerConfigs: remainingConfigs
            };
        case ERROR_DELETING_PROVIDER_CONFIG:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
};