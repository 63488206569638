import * as React from 'react';
import { FormSection } from '@amzn/awsui-components-react';
import { ExternalLink } from '../../common/LinkComponents';

export class BeforeGettingStartedSectionView extends React.Component<{
    userAcceptedAgreement: boolean;
}> {

    render () {
        return <FormSection header='Make sure you have the following set up'>
            <dl>
                <dt><h2>Create an experimental weblab</h2></dt>
                <dd>
                    <p>
                        This is a self-service process. Guidelines can be found on Odyssey's&nbsp;
                        <ExternalLink href='https://w.amazon.com/bin/view/AlexaFeatureDiscovery/CreatingWeblab/'>Weblab Setup Guide</ExternalLink>.
                    </p>
                    <h3>Requirements:</h3>
                    <ul>
                        <li>It must be <b>customer</b> based instead of session based.</li>
                        <li>It must be an experimental weblab.</li>
                        <li>Weblab must be dialed up (by allocating your control and treatment group) to 50% before the experience is submitted for review in Odyssey</li>
                        <li>Include a brief description in the weblab Objective,
                            also include links to any relevant wikis.</li>
                    </ul>
                </dd>
            </dl>
        </FormSection>;
    }
}
