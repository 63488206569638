import * as React from 'react';
import { Spinner, FormField, Checkbox, ColumnLayout } from '@amzn/awsui-components-react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { OptionalField, RequiredField } from '../../components/common/DescriptionAnnotations';
import { IMobileHomeCardConstraint } from '../../models/mobileHomeCard/MobileHomeCardConstraint';
import { pushMobileHomeCardAction, pushMobileHomeCardConstraint, pushMobileHomeCardContext } from '../../actions/experienceEditViewActions';
import { LinkedReleaseScheduleWiki } from '../../components/common/LinksRenderHelper';
import { InputWrapper } from '../../components/common/InputWrapper';
import { CommonValidator } from '../../util/CommonValidator';
import { extractMobileHomeCardAction, extractMobileHomeCardConstraint, extractMobileHomeCardContent, extractMobileHomeCardContext } from '../../util/mobileHomeCardHelper';
import { EMPTY_MOBILE_CARD_ACTION, IMobileHomeCardAction } from '../../models/mobileHomeCard/MobileHomeCardAction';
import { IosDevice, AndroidDevice,FireosDevice } from '../../constants/mobileHomeCardRelatedConstant';
import { TemplateType } from '../../models/mobileHomeCard/MobileHomeCardContent';
import { IMobileHomeCardContext } from '../../models/mobileHomeCard/MobileHomeCardContext';

interface IMobileHomeCardPlatformSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

export class MobileHomeCardPlatformSectionView extends React.Component<IMobileHomeCardPlatformSectionViewProps> {


    updateMobileCardContext(mobileHomeCardContext?: IMobileHomeCardContext) {
        const { dispatch } = this.props;
        dispatch(pushMobileHomeCardContext(mobileHomeCardContext));
    }

    updateMobileCardPlatform(mobileHomeCardConstraint?: IMobileHomeCardConstraint) {
        const { dispatch } = this.props;
        dispatch(pushMobileHomeCardConstraint(mobileHomeCardConstraint));
    }

    updateMobileHomeCardAction(mobileHomeCardActions?: IMobileHomeCardAction[]) {
        const { dispatch } = this.props;
        dispatch(pushMobileHomeCardAction(mobileHomeCardActions));
    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate } = this.props;

        if (!experience) {
            return <FormSectionView title='Platform'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const templateType: TemplateType | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getMobileHomeCardContent()?.templateType
            : extractMobileHomeCardContent(experience).templateType;

        const mobileHomeCardConstraint: IMobileHomeCardConstraint | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getMobileHomeCardConstraint()
            : extractMobileHomeCardConstraint(experience);

        const mobileHomeCardContext: IMobileHomeCardContext | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getMobileHomeCardContext()
            : extractMobileHomeCardContext(experience);

        const mobileHomeCardActions: IMobileHomeCardAction[] = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
            ? updateCandidate.getMobileHomeCardActions() || [EMPTY_MOBILE_CARD_ACTION]
            : extractMobileHomeCardAction(experience) || [EMPTY_MOBILE_CARD_ACTION]);

        const shouldDisableInput = !isEditing || isUpdating;

        const targetDevice: string[] = mobileHomeCardConstraint?.targetDevice || [];

        let isAndroidchecked = false;
        let isFireosChecked = false;
        const uncheckCardAction = isAndroidchecked && isFireosChecked;

        return <FormSectionView
            title='Platform'
            description='Configure for specific device and app release'>

            <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root='true' style={{ marginBottom: '10px' }}>
                    <FormField
                        label={<RequiredField fieldName='Target Device' />}>
                        <Checkbox
                            id='experience.ios'
                            controlId='experience.ios'
                            disabled={shouldDisableInput}
                            checked={targetDevice.includes(IosDevice)}
                            onChange={(e) => {
                                if (e.detail.checked) {
                                    targetDevice.push(IosDevice);
                                } else {
                                    const index = targetDevice.indexOf(IosDevice, 0);
                                    targetDevice.splice(index, 1);
                                    // clean up app store related fields if uncheck ios device
                                    const cardActions: IMobileHomeCardAction[] = mobileHomeCardActions.map((cardAction) => {
                                        cardAction.appStoreName = undefined;
                                        cardAction.appStoreUrl = undefined;
                                        return cardAction;
                                    });
                                    this.updateMobileHomeCardAction(cardActions);
                                }
                                this.updateMobileCardPlatform({ ...mobileHomeCardConstraint, targetDevice });
                            }}>
                            IOS
                        </Checkbox>

                        <Checkbox
                            id='experience.android'
                            controlId='experience.android'
                            disabled={shouldDisableInput}
                            checked={targetDevice.includes(AndroidDevice)}
                            onChange={(e) => {
                                isAndroidchecked = !isAndroidchecked;
                                if (e.detail.checked) {
                                    targetDevice.push(AndroidDevice);
                                } else {
                                    const index = targetDevice.indexOf(AndroidDevice, 0);
                                    targetDevice.splice(index, 1);
                                    // clean up play store related fields if uncheck android device
                                    if (uncheckCardAction) {
                                        const cardActions: IMobileHomeCardAction[] = mobileHomeCardActions.map((cardAction) => {
                                            cardAction.appStoreName = undefined;
                                            cardAction.appStoreUrl = undefined;
                                            return cardAction;
                                        });
                                        this.updateMobileHomeCardAction(cardActions);
                                    }
                                }
                                this.updateMobileCardPlatform({ ...mobileHomeCardConstraint, targetDevice });
                            }}>
                            Android
                        </Checkbox>

                        <Checkbox
                            id='experience.fireos'
                            controlId='experience.fireos'
                            disabled={shouldDisableInput}
                            checked={targetDevice.includes(FireosDevice)}
                            onChange={(e) => {
                                isFireosChecked = !isFireosChecked;
                                if (e.detail.checked) {
                                    targetDevice.push(FireosDevice);
                                } else {
                                    const index = targetDevice.indexOf(FireosDevice, 0);
                                    targetDevice.splice(index, 1);
                                    // clean up play store related fields if uncheck FireOS device
                                    if (uncheckCardAction) {
                                        const cardActions: IMobileHomeCardAction[] = mobileHomeCardActions.map((cardAction) => {
                                            cardAction.appStoreName = undefined;
                                            cardAction.appStoreUrl = undefined;
                                            return cardAction;
                                        });
                                        this.updateMobileHomeCardAction(cardActions);
                                    }
                                }
                                this.updateMobileCardPlatform({ ...mobileHomeCardConstraint, targetDevice });
                            }}>
                            FireOS
                        </Checkbox>
                    </FormField>

                    {templateType === 'DiscoveryTemplate' ?
                        <FormField>
                            <Checkbox
                                id='experience.dismissible'
                                controlId='experience.dismissible'
                                disabled={shouldDisableInput}
                                checked={mobileHomeCardContext?.dismissible || false}
                                onChange={e => this.updateMobileCardContext({ ...mobileHomeCardContext, dismissible: e.detail.checked })}>
                                Make card dismissible
                            </Checkbox>
                        </FormField> : null}
                </div>
            </ColumnLayout>

            <FormField
                label='App Release Date'
                description={<span>See <LinkedReleaseScheduleWiki /></span>}>
            </FormField>

            <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root='true' style={{ marginBottom: '10px' }}>
                    <FormField
                        label={<OptionalField fieldName='Start' />}>
                        <InputWrapper
                            id='experience.appReleaseStart'
                            placeholder='e.g. RELEASE_2022_16'
                            readonly={shouldDisableInput}
                            value={mobileHomeCardConstraint?.appReleaseStart}
                            onChange={(input: string) => {
                                this.updateMobileCardPlatform({ ...mobileHomeCardConstraint, appReleaseStart: input.trim() });
                            }}
                            validate={(value) => { return CommonValidator.isReleaseDateValid(value); }}
                        />
                    </FormField>

                    <FormField
                        label={<OptionalField fieldName='End' />}>
                        <InputWrapper
                            id='experience.appReleaseEnd'
                            placeholder='e.g. RELEASE_2022_17'
                            readonly={shouldDisableInput}
                            value={mobileHomeCardConstraint?.appReleaseEnd}
                            onChange={(input: string) => {
                                this.updateMobileCardPlatform({ ...mobileHomeCardConstraint, appReleaseEnd: input.trim() });
                            }}
                            validate={(value) => { return CommonValidator.isReleaseDateValid(value); }}
                        />
                    </FormField>
                </div>
            </ColumnLayout>
        </FormSectionView>;
    }
}

const mapStateToProps = ({ odysseyExperienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: odysseyExperienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate,
    };
};

export default connect(mapStateToProps)(MobileHomeCardPlatformSectionView);