import * as React from 'react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { FormSectionView } from '../../components/common/FormSectionView';
import { FormField, ColumnLayout, DatePicker, Spinner, TimeInput } from '@amzn/awsui-components-react';
import { connect } from 'react-redux';
import { AppState } from '../../reducers/index';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { pushMobileHomeCardConstraint } from '../../actions/experienceEditViewActions';
import { OptionalField } from '../../components/common/DescriptionAnnotations';
import { isNotPastDate } from './BasicInfoSectionView';
import { ExperienceType } from '../../models/Experience';
import { extractMobileHomeCardConstraint } from '../../util/mobileHomeCardHelper';
import DatePickerWrapper from '../../components/common/DatePickerWrapper';
import moment from 'moment';

interface IMobileHomeCardActivationDatesSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    isCloning: boolean;
    selectedExperienceType?: ExperienceType;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

export class MobileHomeCardActivationDatesSectionView extends React.Component<IMobileHomeCardActivationDatesSectionViewProps> {
    updatePublishAt(publishesAt: number) {
        const { dispatch } = this.props;
        const after14Days = 14 * 24 * 60 * 60 * 1000;
        dispatch(pushMobileHomeCardConstraint({
            startTime: publishesAt
        }));
        // auto update the end time 14 days after start time given by user
        dispatch(pushMobileHomeCardConstraint({
            endTime: publishesAt + after14Days
        }));
    }

    updateExpiresAt(expiresAt: number, publishesAt?: number) {
        const { dispatch } = this.props;

        if (publishesAt && expiresAt >= publishesAt) {
            dispatch(pushMobileHomeCardConstraint({
                endTime: expiresAt
            }));
        }
    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate } = this.props;

        if (!experience) {
            const title: string = 'Mobile Home Card Activation Dates';
            return <FormSectionView title={title}>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const publishesAt: number | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getMobileHomeCardConstraint()?.startTime
            : extractMobileHomeCardConstraint(experience)?.startTime;

        const expiresAt: number | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getMobileHomeCardConstraint()?.endTime
            : extractMobileHomeCardConstraint(experience)?.endTime;

        const shouldDisableInput = !isEditing || isUpdating;

        const activationDateDescription = 'Date range over which experience will be served. All Start / End Time will be saved in UTC for all locales.';

        const startDate = publishesAt ? moment(publishesAt).utc().format('YYYY-MM-DD') : '';

        const startTime = publishesAt ? new Date(publishesAt).toTimeString().split(' ')[0] : '00:00:00';

        const endDate = expiresAt ? moment(expiresAt).utc().format('YYYY-MM-DD') : '';

        const endTime = expiresAt ? new Date(expiresAt).toTimeString().split(' ')[0] : '00:00:00';

        const fieldName: string = 'Mobile Home Card Activation Dates';

        const datePickerSharedConfig = {
            disabled: shouldDisableInput,
            placeholder: 'YYYY/MM/DD',
            todayLabel: 'Today',
            nextMonthLabel: 'Next month',
            previousMonthLabel: 'Previous month',
            isDateEnabled: isNotPastDate
        };

        return <FormSectionView title={<OptionalField fieldName={fieldName} />} description={activationDateDescription}>
            <FormField>
                <ColumnLayout columns={2} borders='horizontal' >
                    <div data-awsui-column-layout-root='true'>
                        <FormField label='Start Date'>
                        <DatePickerWrapper
                                {...(startDate ?
                                    { value: startDate, ...datePickerSharedConfig }
                                    : { value:'', ...datePickerSharedConfig }
                                )}
                                onChange={(e) => {
                                    this.updatePublishAt(new Date(e.detail.value + 'T' + startTime).getTime());
                                }}
                                id='experience.start-date'
                                checkChangeControl={true}
                            />
                        </FormField>
                        <FormField label='Start Time'>
                            <TimeInput
                                value={startTime}
                                disabled={shouldDisableInput}
                                onChange={(e) => this.updatePublishAt(new Date(startDate + 'T' + e.detail.value).getTime())}
                                format='hh:mm:ss'
                                controlId='experience.start-time'
                                id='experience.start-time'
                            />
                        </FormField>
                        <FormField label='End Date'>
                            <DatePicker
                                value={endDate}
                                disabled={shouldDisableInput}
                                onChange={(e) => this.updateExpiresAt(new Date(e.detail.value + 'T' + endTime).getTime(), new Date(startDate + 'T' + startTime).getTime())}
                                isDateEnabled={isNotPastDate}
                                placeholder='YYYY-MM-DD'
                                todayLabel='Today'
                                nextMonthLabel='Next month'
                                previousMonthLabel='Previous month'
                                controlId='experience.end-date'
                                id='experience.end-date'
                            />
                        </FormField>
                        <FormField label='End Time'>
                            <TimeInput
                                value={endTime}
                                disabled={shouldDisableInput}
                                onChange={(e) => this.updateExpiresAt(new Date(endDate + 'T' + e.detail.value).getTime(), new Date(startDate + 'T' + startTime).getTime())}
                                format='hh:mm:ss'
                                controlId='experience.end-time'
                                id='experience.end-time'
                            />
                        </FormField>
                    </div>
                </ColumnLayout>
            </FormField>
        </FormSectionView>;
    }
}

const mapStateToProps = ({ odysseyExperienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        isCloning: experienceEditViewState.isCloning,
        experience: odysseyExperienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(MobileHomeCardActivationDatesSectionView);
