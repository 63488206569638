import _ from 'lodash';
import { IHomeCardSuggestion } from '../models/HomeCardSuggestion';
import { ILoadSuggestionsAction } from '../actions/homeCardSuggestionsListViewActions';
import { ERROR_FETCHING_SUGGESTIONS, RECEIVED_SUGGESTIONS, REQUESTED_SUGGESTIONS, UNSET_SUGGESTIONS } from '../constants/homeCardSuggestionsListViewActionTypes';


export interface ISuggestionsListViewState {
    isLoading: boolean;
    suggestions?: IHomeCardSuggestion[];
    lastEvaluatedKey?: string;
    error?: Error;
}

export const homeCardSuggestionsListViewReducer = (
    state: ISuggestionsListViewState = { isLoading: false },
    action: ILoadSuggestionsAction
): ISuggestionsListViewState => {
    switch (action.type) {
        case REQUESTED_SUGGESTIONS:
            return {
                ...state,
                isLoading: true
            };
        case RECEIVED_SUGGESTIONS:
            // reducer is at top level. it should resolve error
            // and get it ready for rendering.
            if (!action.suggestions) {
                return {
                    ...state,
                    isLoading: false,
                    error: new Error('Loaded `undefined` suggestions, something went wrong')
                };
            }

            const suggestions = (state.suggestions || []).concat(action.suggestions.filter
                (suggestion => suggestion.provider.type === 'SkillProvider' && suggestion.suggestionApproval !== null));

            return {
                ...state,
                isLoading: false,
                lastEvaluatedKey: action.lastEvaluatedKey,
                suggestions: _.uniqBy(suggestions, (suggestion) => { return suggestion.id; }),
                error: undefined
            };
        case ERROR_FETCHING_SUGGESTIONS:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case UNSET_SUGGESTIONS:
            return {
                ...state,
                suggestions: undefined,
                error: undefined,
                isLoading: false
            };
        default:
            return state;
    }
};
