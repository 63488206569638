import { ExperienceUpdateCandidate } from '../models/ExperienceUpdateCandidate';
import { CommonValidator } from './CommonValidator';

/**
 * This helper method attempts to validate the experience and throw validation errors as flash bar right after
 * ExperienceEditViewAction is called after the user clicks 'Save/Create Experience' and right before POEMS calls ACOPS.
 * Feel free to modify or extend if needed.
 * @param updateCandidate experience to be validated.
 */
export function validateExperienceOnSubmit(updateCandidate?: ExperienceUpdateCandidate) {

    switch (updateCandidate?.getType()) {
        case 'CIF':
            validateCifOnSubmit(updateCandidate);
            break;
        case 'AppNotification':
            validateAppNotificationOnSubmit(updateCandidate);
            break;
        case 'DeviceNotification':
            validateDeviceNotificationOnSubmit(updateCandidate);
            break;
        case 'MobileHomeCard':
            validateMhcOnSubmit(updateCandidate);
            break;
        case 'RotatingContentPanel':
            validateRcpOnSubmit(updateCandidate);
            break;
    }
}

function validateCifOnSubmit(updateCandidate: ExperienceUpdateCandidate) {
    CommonValidator.isSubscriptionUpsellFieldValid(updateCandidate.getSubscriptionUpsellType(), ' under \'Content\' section or under \'Experience Details - Suggested Feature\' section');
}

function validateAppNotificationOnSubmit(updateCandidate: ExperienceUpdateCandidate) {
    CommonValidator.isSubscriptionUpsellFieldValid(updateCandidate.getSubscriptionUpsellType(), ' under \'Experience Details - Suggested Feature\' section');
}

function validateDeviceNotificationOnSubmit(updateCandidate: ExperienceUpdateCandidate) {
    CommonValidator.isSubscriptionUpsellFieldValid(updateCandidate.getSubscriptionUpsellType(), ' under \'Experience Details - Suggested Feature\' section');
}

function validateMhcOnSubmit(updateCandidate: ExperienceUpdateCandidate) {
    CommonValidator.isSubscriptionUpsellFieldValid(updateCandidate.getMobileHomeCardContext()?.subscriptionUpsellType, ' under \'Card Detail\' tab and \'Experience Details\' section');
}


function validateRcpOnSubmit(updateCandidate: ExperienceUpdateCandidate) {
    CommonValidator.isSubscriptionUpsellFieldValid(updateCandidate.getRotatingContentPanelContext()?.subscriptionUpsellType, ' under \'Content\' tab and \'Meta Data\' section');
}

/**
 * Executing this function will scroll the page to the top of the component associated with the input id.
 * @param elementId indicates the id of the component to be scrolled to.
 */
export const scrollToTopOf = (elementId: string) => {
    const scrollToElement = document.getElementById(elementId);
    scrollToElement?.scrollIntoView({
        behavior: 'auto',
        block: 'start',
        inline: 'center'
    });
};
