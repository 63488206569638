import { MARKETPLACES_FLIPPED } from '../constants/marketplaces';
import { IApprovalMetadata } from '../models/ApprovalInfo';
import { IBasicInfoViewAttributes, IRegionViewAttributes } from '../models/ExperienceUpdateCandidate';
import { IFlattenedExperience } from '../models/FlattenedExperience';
import { IConstraint } from '../models/v2/constraint/IConstraint';
import { IMetadata } from '../models/Metadata';
import { AbExperimentCategory, AbExperimentGroup, IMobileHomeCardAbExperiment } from '../models/mobileHomeCard/MobileHomeCardAbExperiment';
import { IMobileHomeCardAction } from '../models/mobileHomeCard/MobileHomeCardAction';
import { IMobileHomeCardConstraint } from '../models/mobileHomeCard/MobileHomeCardConstraint';
import { IMobileHomeCardContent, TemplateType } from '../models/mobileHomeCard/MobileHomeCardContent';
import { IMobileHomeCardTargeting } from '../models/mobileHomeCard/MobileHomeCardTargeting';
import { IConstraintType } from '../models/v2/constraint/IConstraintType';
import { IContent } from '../models/v2/content/IContent';
import { IContentType } from '../models/v2/content/IContentType';
import { IMetadataInfo } from '../models/v2/metadata/IMetadata';
import { IMetadataType } from '../models/v2/metadata/IMetadataType';
import { ITargeting } from '../models/v2/targeting/ITargeting';
import { ITargetingType } from '../models/v2/targeting/ITargetingType';
import { IContext } from '../models/v2/context/IContext';
import { IContextType } from '../models/v2/context/IContextType';
import { IBusinessMetadata } from '../models/BusinessInfo';
import { IMobileHomeCardContext } from '../models/mobileHomeCard/MobileHomeCardContext';

export const extractMobileHomeCardContent = (experience: IFlattenedExperience): IMobileHomeCardContent => {
    const mobileHomeCardContent = experience.contents?.[0];
    const mobileHomeCardContext = experience?.contexts?.MobileHomeCardContext;

    return {
        ...mobileHomeCardContent,
        templateType: mobileHomeCardContent?.templateType as TemplateType,
        cardActionConfirmation: false,
        campaignTag: mobileHomeCardContext?.campaignTag,
        businessInfo: extractBusinessInfoList(mobileHomeCardContext?.businessInfo)
    };

};

export const extractMobileHomeCardAction = (experience: IFlattenedExperience): IMobileHomeCardAction[] | undefined => {
    const mobileHomeCardContent = experience.contents?.[0];
    return mobileHomeCardContent?.cardAction;

};

export const extractMobileHomeCardConstraint = (experience: IFlattenedExperience): IMobileHomeCardConstraint | undefined => {
    const mobileHomeCardConstraint = experience.constraints?.MobileHomeCardConstraint;
    return {
        ...mobileHomeCardConstraint
    };
};

export const extractMobileHomeCardTargeting = (experience: IFlattenedExperience): IMobileHomeCardTargeting | undefined => {
    const mobileHomeCardTargeting = experience.targeting?.MobileHomeCardTargeting;
    const customerSegmentConstraint = experience.constraints?.CustomerSegmentConstraint;

    return {
        ...mobileHomeCardTargeting,
        antiTarget: mobileHomeCardTargeting?.antiTarget || false,
        locale: mobileHomeCardTargeting?.locale,
        bullseye: customerSegmentConstraint?.bullseyeSegmentId
    };
};

export const extractMobileHomeCardContext = (experience: IFlattenedExperience): IMobileHomeCardContext | undefined => {
    const mobileHomeCardContext = experience.contexts?.MobileHomeCardContext;

    return {
        ...mobileHomeCardContext
    };
};

export const extractMobileHomeCardAbExperiment = (experience?: IFlattenedExperience): IMobileHomeCardAbExperiment | undefined => {
    const mobileHomeCardContext = experience?.contexts?.MobileHomeCardContext;
    const generalConstraint = experience?.constraints?.GeneralConstraint;

    return {
        abExperimentCategory: mobileHomeCardContext?.abExperimentCategory as AbExperimentCategory,
        abExperimentGroup: mobileHomeCardContext?.abExperimentGroup as AbExperimentGroup,
        weblabName: generalConstraint?.weblabName,
        launched: generalConstraint?.launched,
    };
};

export const constructMobileHomeCardData = (
    basicInfo?: IBasicInfoViewAttributes,
    metadata?: IMetadata,
    approvalInfo?: IApprovalMetadata,
    approvalId?: string,
    cancellationReason?: string,
    region?: IRegionViewAttributes,
    mobileHomeCardContent?: IMobileHomeCardContent,
    mobileHomeCardActions?: IMobileHomeCardAction[],
    mobileHomeCardTargeting?: IMobileHomeCardTargeting,
    mobileHomeCardConstraint?: IMobileHomeCardConstraint,
    mobileHomeCardAbExperiment?: IMobileHomeCardAbExperiment,
    mobileHomeCardContext?: IMobileHomeCardContext
) => {
    return {
        'metadataInfo': constructMetadataInfo(metadata, approvalInfo, approvalId, cancellationReason),
        'contents': constructMobileCardContents(basicInfo, mobileHomeCardContent, mobileHomeCardActions, mobileHomeCardTargeting, mobileHomeCardConstraint, mobileHomeCardContext),
        'constraints': constructMobileHomeCardConstraints(mobileHomeCardAbExperiment, mobileHomeCardTargeting, mobileHomeCardConstraint, region),
        'targeting': constructMobileCardTargeting(mobileHomeCardTargeting, region),
        'contexts': constructMobileCardContext(mobileHomeCardAbExperiment, mobileHomeCardContent, mobileHomeCardContext)
    };
};

export const constructMetadataInfo = (metadata?: IMetadata, approvalInfo?: IApprovalMetadata, approvalId?: string, cancellationReason?: string): { [key: string]: IMetadataInfo } => {
    const metadataMap: { [key: string]: IMetadataInfo } = {};

    metadataMap.ExperienceMetadata = {
        ...metadata,
        type: IMetadataType.ExperienceMetadata
    };

    metadataMap.ApprovalInfoMetadata = {
        ...approvalInfo,
        approvalId,
        cancellationReason,
        type: IMetadataType.ApprovalInfoMetadata
    };

    return metadataMap;
};

export const constructMobileCardContents = (
    basicInfo?: IBasicInfoViewAttributes,
    mobileHomeCardContent?: IMobileHomeCardContent,
    mobileHomeCardActions?: IMobileHomeCardAction[],
    mobileHomeCardTargeting?: IMobileHomeCardTargeting,
    mobileHomeCardConstraint?: IMobileHomeCardConstraint,
    mobileHomeCardContext?: IMobileHomeCardContext): IContent[] => {

    const contentsList: IContent[] = [];
    const mhcContent = {
        type: IContentType.MobileHomeCardContent,
        ...mobileHomeCardContent,
        cardAction: mobileHomeCardActions,
        cardItem: constructCardItem(basicInfo, mobileHomeCardContent, mobileHomeCardActions, mobileHomeCardTargeting, mobileHomeCardConstraint, mobileHomeCardContext)
    };
    contentsList.push(mhcContent);

    return contentsList;
};

export const constructMobileHomeCardConstraints = (
    mobileHomeCardAbExperiment?: IMobileHomeCardAbExperiment,
    mobileHomeCardTargeting?: IMobileHomeCardTargeting,
    mobileHomeCardConstraint?: IMobileHomeCardConstraint,
    region?: IRegionViewAttributes
): { [key: string]: IConstraint } => {

    const constraintsMap: { [key: string]: IConstraint } = {};

    constraintsMap.MobileHomeCardConstraint = {
        type: IConstraintType.MobileHomeCardConstraint,
        ...mobileHomeCardConstraint
    };

    constraintsMap.GeneralConstraint = {
        type: IConstraintType.GeneralConstraint,
        weblabName: mobileHomeCardAbExperiment?.weblabName,
        launched: mobileHomeCardAbExperiment?.launched || false,
        marketplace: MARKETPLACES_FLIPPED[region?.marketplace as string]
    };

    constraintsMap.CustomerSegmentConstraint = {
        type: IConstraintType.CustomerSegmentConstraint,
        bullseyeSegmentId: mobileHomeCardTargeting?.bullseye
    };

    return constraintsMap;

};

export const constructMobileCardTargeting = (mobileHomeCardTargeting?: IMobileHomeCardTargeting, region?: IRegionViewAttributes): { [key: string]: ITargeting } => {

    const targetingMap: { [key: string]: ITargeting } = {};

    targetingMap.MobileHomeCardTargeting = {
        type: ITargetingType.MobileHomeCardTargeting,
        antiTarget: mobileHomeCardTargeting?.antiTarget || false,
        locale: region?.locale,
    };

    return targetingMap;
};

export const constructMobileCardContext = (
    mobileHomeCardAbExperiment?: IMobileHomeCardAbExperiment,
    mobilehomeCardContent?: IMobileHomeCardContent,
    mobileHomeCardContext?: IMobileHomeCardContext
    ): { [key: string]: IContext } => {

    const contextsMap: { [key: string]: IContext } = {};

    contextsMap.MobileHomeCardContext = {
        type: IContextType.MobileHomeCardContext,
        abExperimentCategory: mobileHomeCardAbExperiment?.abExperimentCategory,
        abExperimentGroup: mobileHomeCardAbExperiment?.abExperimentGroup,
        campaignTag: mobilehomeCardContent?.campaignTag,
        businessInfo: constructBusinessInfoList(mobilehomeCardContent?.businessInfo),
        dismissible: mobileHomeCardContext?.dismissible || false,
        subscriptionUpsellType: mobileHomeCardContext?.subscriptionUpsellType
    };

    return contextsMap;
};

export const extractBusinessInfoList = (businessInfoList?: IBusinessMetadata[]): string[] | undefined => {
    return businessInfoList?.map((businessInfo) => {
        return `${businessInfo.vertical}-${businessInfo.domain}`;
    });
};

export const constructBusinessInfoList = (businessInfoList?: string[]): IBusinessMetadata[] | undefined => {
    return businessInfoList?.map((businessInfo) => {
        const vertical = businessInfo.split('-')[0];
        const domain = businessInfo.split('-')[1];
        return {
            vertical,
            domain
        };

    });
};

export const constructCardItem = (
    basicInfo?: IBasicInfoViewAttributes,
    mobileHomeCardContent?: IMobileHomeCardContent,
    mobileHomeCardActions?: IMobileHomeCardAction[],
    mobileHomeCardTargeting?: IMobileHomeCardTargeting,
    mobileHomeCardConstraint?: IMobileHomeCardConstraint,
    mobileHomeCardContext?: IMobileHomeCardContext) => {

    switch (mobileHomeCardContent?.templateType) {
        case 'HeroTemplate':
            return constructCardItemHeroTemplate(basicInfo, mobileHomeCardContent, mobileHomeCardActions, mobileHomeCardTargeting, mobileHomeCardConstraint);
        case 'DiscoveryTemplate':
            return constructCardItemDiscoveryTemplate(basicInfo, mobileHomeCardContent, mobileHomeCardActions, mobileHomeCardTargeting, mobileHomeCardConstraint, mobileHomeCardContext);
        default:
            return '';
    }

};

export const constructCardItemHeroTemplate = (
    basicInfo?: IBasicInfoViewAttributes,
    mobileHomeCardContent?: IMobileHomeCardContent,
    mobileHomeCardActions?: IMobileHomeCardAction[],
    mobileHomeCardTargeting?: IMobileHomeCardTargeting,
    mobileHomeCardConstraint?: IMobileHomeCardConstraint) => {
    const cardItem =
    {
        contentId: mobileHomeCardContent?.contentId,
        templateType: mobileHomeCardContent?.templateType,
        contentProvider: mobileHomeCardContent?.contentProvider,
        contentType: mobileHomeCardContent?.contentType,
        antiTargeting: mobileHomeCardTargeting?.antiTarget,
        items: [
            {
                title: mobileHomeCardContent?.cardTitle,
                subtitle: mobileHomeCardContent?.cardSubtitle,
                imageUrl: mobileHomeCardContent?.cardImageUrl,
                actions: extractCardActions(mobileHomeCardActions, mobileHomeCardContent)
            }
        ],
        segmentMap: constructSegmentMap(basicInfo, mobileHomeCardTargeting),
        domainP13NMetadata: {
            section: mobileHomeCardContent?.section,
            timeRangeOfEvent: {
                startTime: mobileHomeCardConstraint?.startTime,
                endTime: mobileHomeCardConstraint?.endTime,
            },
        },
        contentMetadata: {
            targetDeviceTypes: mobileHomeCardConstraint?.targetDevice,
        }

    };
    return JSON.stringify(cardItem);

};

export const constructCardItemDiscoveryTemplate = (
    basicInfo?: IBasicInfoViewAttributes,
    mobileHomeCardContent?: IMobileHomeCardContent,
    mobileHomeCardActions?: IMobileHomeCardAction[],
    mobileHomeCardTargeting?: IMobileHomeCardTargeting,
    mobileHomeCardConstraint?: IMobileHomeCardConstraint,
    mobileHomeCardContext?: IMobileHomeCardContext) => {
    const cardItem =
    {
        contentId: mobileHomeCardContent?.contentId,
        templateType: mobileHomeCardContent?.templateType,
        contentProvider: mobileHomeCardContent?.contentProvider,
        contentType: mobileHomeCardContent?.contentType,
        antiTargeting: mobileHomeCardTargeting?.antiTarget,
        clickToDismiss: mobileHomeCardContext?.dismissible || false,
        discoveryData: {
            title: mobileHomeCardContent?.cardTitle,
            subtitle: mobileHomeCardContent?.cardSubtitle,
            rightImageUrl: mobileHomeCardContent?.cardImageUrl,
            backgroundColor: mobileHomeCardContent?.cardBgColor,
            leftIcon: {
                iconUrl: mobileHomeCardContent?.leftIconUrl
            },
            label: {
                text: mobileHomeCardContent?.labelText,
                backgroundColor: mobileHomeCardContent?.labelBgColor
            },
            cardAction: extractCardAction(mobileHomeCardActions?.[0], mobileHomeCardContent)
        },
        discoveryMetadata: {
            campaignTag: mobileHomeCardContent?.campaignTag,
            businessInfo: constructBusinessInfoList(mobileHomeCardContent?.businessInfo)
        },
        segmentMap: constructSegmentMap(basicInfo, mobileHomeCardTargeting),
        domainP13NMetadata: {
            section: mobileHomeCardContent?.section,
            timeRangeOfEvent: {
                startTime: mobileHomeCardConstraint?.startTime,
                endTime: mobileHomeCardConstraint?.endTime,
            },
        },
        contentMetadata: {
            targetDeviceTypes: mobileHomeCardConstraint?.targetDevice,
        }

    };
    return JSON.stringify(cardItem);

};

// 'NA' | 'EU' | 'FE'
export const constructSegmentMap = (basicInfo?: IBasicInfoViewAttributes, mobileHomeCardTargeting?: IMobileHomeCardTargeting) => {
    if (basicInfo?.region && mobileHomeCardTargeting?.bullseye) {
        switch (basicInfo.region) {
            case 'NA':
                return {
                    'prod.USAmazon': [mobileHomeCardTargeting.bullseye]
                };
            case 'EU':
                return {
                    'prod.EUAmazon': [mobileHomeCardTargeting.bullseye]
                };
            case 'FE':
                return {
                    'prod.FEAmazon': [mobileHomeCardTargeting.bullseye]
                };
        }
    }

};

export function extractCardAction(mobileHomeCardAction?: IMobileHomeCardAction, mobileHomeCardContent?: IMobileHomeCardContent): {} {
    return {
        '@type': mobileHomeCardAction?.actionType,
        ...mobileHomeCardAction,
        url: mobileHomeCardAction?.url || mobileHomeCardAction?.deepLinkUrl,
        text: mobileHomeCardContent?.callToActionButton
    };
}

export function extractCardActions(mobileHomeCardActions: IMobileHomeCardAction[] | undefined, mobileHomeCardContent?: IMobileHomeCardContent): any[] {
    if (!mobileHomeCardActions) return [];
    return mobileHomeCardActions.map((action) => extractCardAction(action, mobileHomeCardContent));
}


export function toSentenceCase(text: string): string {
    return text.replace(/\S*/g, function (word: string) {
        const firstLetter = word.charAt(0).toLocaleUpperCase();
        return firstLetter + word.slice(1).toLocaleLowerCase();
    });
}
