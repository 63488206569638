import * as React from 'react';
import { ColumnLayout, Spinner, FormField, Multiselect, Select, TokenGroup, Input, Alert } from '@amzn/awsui-components-react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { DEVICES, hasOnTheGoDevices } from '../../constants/devices';
import { DeviceType } from '../../models/device';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { pushDevicesSectionAction } from '../../actions/experienceEditViewActions';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { RequiredField, OptionalField } from '../../components/common/DescriptionAnnotations';
import { CIFValidator } from '../../util/CIFValidator';
import { FlashbarWrapper } from '../../components/common/FlashbarWrapper';


interface ICifDevicesSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

interface ICifDevicesSectionViewState {
    avsDeviceInput?: string;
    deviceSelections?: Select.OptionsGroup[];
}

export class CifDevicesSectionView extends React.Component<ICifDevicesSectionViewProps, ICifDevicesSectionViewState>  {

    constructor(props: ICifDevicesSectionViewProps) {
        super(props);
        this.state = {avsDeviceInput: '', deviceSelections: generateDeviceGroups()};
    }

    updateDevices(devices?: string[]) {
        const { dispatch } = this.props;
        dispatch(pushDevicesSectionAction(devices));
    }

    setAVSDeviceInput(event: CustomEvent<Input.ChangeDetail>) {
        this.setState({avsDeviceInput: event.detail.value});
    }

    onAVSDeviceInputEnter(devices: string[]) {
        this.state.avsDeviceInput?.split(/[,|]+/)
            .map(tag => tag.trim())
            .filter(tag => (tag.length > 0 && !devices.includes(tag)))
            .map((tag) => { devices.push(tag);});

        this.setState({avsDeviceInput: ''});
        this.updateDevices(devices);
    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate } = this.props;

        if (!experience) {
            return <FormSectionView title='Devices'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const supportedDevices = Object.keys(DEVICES);
        const devices: string[] = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
            ? updateCandidate.getDevices()
            : ExperienceUpdateCandidate.extractDevices(experience));

        const nonAvsDevices: string[] = [];
        const avsDevices: string[] = [];

        devices.forEach((deviceId) => {
            supportedDevices.includes(deviceId) ? nonAvsDevices.push(deviceId) : avsDevices.push(deviceId);
        });

        const shouldDisableInput = !isEditing || isUpdating;

        return <FormSectionView title={<RequiredField fieldName='Devices' />} description='
            At least one device must be selected.
            Please select device(s) appropriate for your experience'
        >
            {!shouldDisableInput && <FlashbarWrapper validate={() => CIFValidator.isDevicesValid(devices)} />}
            {hasOnTheGoDevices(devices)
                && <Alert visible={true}
                    type='info'>You have added at least one OTG (On-The-Go) device to your experience.
                </Alert>}
            <ColumnLayout columns={2} borders='vertical' >
                <div data-awsui-column-layout-root='true'>
                    <FormField label={<RequiredField fieldName='Echo Family and Fire TV Devices' />} description='Target Echo multimodal, headless, on-the-go, Fire TV, and Echo Auto devices'>
                        <Multiselect
                            id='experience.devices-non-avs-multiselect'
                            controlId='experience.devices-non-avs-multiselect'
                            selectedIds={nonAvsDevices}
                            options={this.state.deviceSelections}
                            disabled={shouldDisableInput}
                            onChange={(event) => {this.updateDevices(event.detail.selectedIds.concat(avsDevices));}}
                            filteringType='auto'
                            checkboxes={true}
                        />
                    </FormField>
                    <FormField label={<OptionalField fieldName='AVS Devices' />} description='Target AVS devices by using the device type id'>
                        <Input
                            id='experience.devices-avs-input'
                            controlId='experience.devices-avs-input'
                            disabled={shouldDisableInput}
                            onInput={(e) => this.setAVSDeviceInput(e)}
                            value={this.state.avsDeviceInput}
                            onKeyup={(e) => {
                                if (e.detail.keyCode === 13) {
                                    this.onAVSDeviceInputEnter(devices);
                                }
                            }}/>
                        <DevicesTagsView
                            tags={avsDevices}
                            tokenGroupId='experience.devices-avs-token-group'
                            disableTag={shouldDisableInput}
                            onDismiss={(e) => {
                                avsDevices.splice(e.detail.itemIndex, 1);
                                this.updateDevices(nonAvsDevices.concat(avsDevices));
                            }} />
                    </FormField>
                </div>
            </ColumnLayout>
        </FormSectionView>;
    }
}

export const generateDeviceGroups = (): Select.OptionsGroup[] => {

    return [generateDeviceGroup('Headless'), generateDeviceGroup('Multimodal'), generateDeviceGroup('On-The-Go'), generateDeviceGroup('FireTV'), generateDeviceGroup('Echo Auto')];
};

export const generateDeviceGroup = (deviceType: DeviceType ): Select.OptionsGroup => {

    const options: Select.Option[] = Object.keys(DEVICES)
        .filter(deviceId => DEVICES[deviceId].type === deviceType)
        .map((deviceId, index) => {
            return {
                label: `${DEVICES[deviceId].name} (${DEVICES[deviceId].code})`,
                id: deviceId,
                tags: [deviceType]
            };
        });
    return {
        label: deviceType,
        options
    };
};

export const DevicesTagsView = (props: { tags: string[],
    disableTag: boolean,
    tokenGroupId: string,
    onDismiss: (e: CustomEvent<TokenGroup.DismissDetail>) => void }) => {

    const { tokenGroupId, tags, disableTag, onDismiss } = props;

    const items = tags.map(tag => {
        const item: TokenGroup.Item = {
            label: tag,
            disabled: disableTag,
            tags: ['Device']
        };

        if (tag.trim() !== tag) {
            item.tags = ['WARNING: token has leading or trailing whitespace'];
        }

        return item;
    });

    return <TokenGroup id={tokenGroupId} items={items} onDismiss={onDismiss} />;
};

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(CifDevicesSectionView);
