import * as React from 'react';
import { APPROVALS_MAP } from '../../constants/approvals';
import { getCurrRegionStage, Region } from '../../util/context';
import { ODYSSEY_ENDPOINTS_MAP } from '../../constants/odysseyEndpoints';
import { BULLSEYE_ENDPOINTS_MAP } from '../../constants/bullseyeEndpoints';
import { ExternalLink } from './LinkComponents';
import { ODYSSEY_LINK } from '../../containers/Navigation';

export const LinkedPhonetool = ({ userAlias }: { userAlias?: string }) => {
    if (!userAlias) return <span>-</span>;
    return <ExternalLink href={`https://phonetool.amazon.com/users/${userAlias}`}>{userAlias}</ExternalLink>;
};

export const LinkedWeblab = ({ weblab }: { weblab?: string }) => {
    if (!weblab) return <span>-</span>;
    return <ExternalLink href={`https://weblab.amazon.com/wl/${weblab}/allocations/production`}>{weblab}</ExternalLink>;
};

export const LinkedAlexaLab = ({ weblab, realm }: { weblab?: string, realm?: string }) => {
    if (!weblab) return <span>-</span>;

    return <div><ExternalLink href={`https://sensai.a2z.com/alexalab/experiment-reports/${weblab}${realm ? `/${realm}` : ''}`}>Result</ExternalLink> / <ExternalLink href={`https://sensai.a2z.com/alexalab/configure-custom-metrics/${weblab}`}>Metrics Configuration</ExternalLink></div>;
};

export const LinkedMcm = ({ mcm }: { mcm?: string }) => {
    if (!mcm) return <span>-</span>;
    return <ExternalLink href={`https://mcm.amazon.com/cms/${mcm}`}>{mcm}</ExternalLink>;
};

export const LinkedPermissionGroup = ({ permissionGroup }: { permissionGroup?: string }) => {
    if (!permissionGroup) return <span>-</span>;
    return <ExternalLink href={`https://permissions.amazon.com/group.mhtml?group_type=ldap&group=${permissionGroup}`}>
        {permissionGroup}
    </ExternalLink>;
};

export const LinkedHintId = ({ hintId }: { hintId?: string }) => {
    if (!hintId) return <span>-</span>;

    // TODO: service not regionalized. NA only
    // pass in region when needed
    return <ExternalLink href={`${ODYSSEY_ENDPOINTS_MAP[getCurrRegionStage().stage]}/hints/${hintId}`}>
        {hintId}
    </ExternalLink>;
};

export const LinkedCapabilityId = ({ capabilityId, locale }: { capabilityId?: string, locale?: string }) => {
    if (!capabilityId) return <span>-</span>;

    return <ExternalLink href={`https://console.harmony.a2z.com/acc-console/capabilities/${capabilityId}?locale=${locale || 'en_US'}`}>
        {capabilityId}
    </ExternalLink>;
};

export const LinkedApproval = (props: { approvalId?: string }) => {
    const { approvalId } = props;

    if (!approvalId) return <span>-</span>;
    return <ExternalLink href={`${APPROVALS_MAP[getCurrRegionStage().stage]}${approvalId}`}>
        {approvalId}
    </ExternalLink>;
};

export const LinkedSimTicket = (props: { simDocumentId?: string }) => {
    const { simDocumentId } = props;

    if (!simDocumentId) return <span>-</span>;
    return <ExternalLink href={`https://issues.amazon.com/issues/${simDocumentId}`}>
        {simDocumentId}
    </ExternalLink>;
};

export const LinkedBullseye = (props: { bullseye?: number, region?: Region }) => {
    const { bullseye, region } = props;

    if (!bullseye || !region) return <span>-</span>;
    return <ExternalLink href={`${BULLSEYE_ENDPOINTS_MAP[getCurrRegionStage().stage][region]}${bullseye}`}>
        {bullseye}
    </ExternalLink>;
};

export const LinkedOdysseyWiki = () => {
    return <ExternalLink href={ODYSSEY_LINK}>
        Odyssey Wiki
    </ExternalLink>;
};

export const LinkedCIFTestingWiki = () => {
    return <ExternalLink href='https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+CIF+Experience+Setup#Workflows-242207216'>
        Odyssey CIF Campaign Testing Wiki
    </ExternalLink>;
};

export const LinkedHinty = () => {
    return <ExternalLink href='https://wiki.labcollab.net/confluence/pages/viewpage.action?spaceKey=Doppler&title=Hinty+-+Hint+Management+Tool#Hinty-HintManagementTool-LinkingHintIDinCIF/NotificationForm'>
        Hinty
    </ExternalLink>;
};

export const LinkedA4pcWiki = () => {
    return <ExternalLink href='https://wiki.labcollab.net/confluence/display/Doppler/Alexa+for+PC+App+Navigation+Options'>
        wiki
    </ExternalLink>;
};

export const LinkedMobileCardTemplateTypeWiki = () => {
    return <ExternalLink href='https://designguide.corp.amazon.com/en/alexa-app/components/home-discovery-cards/?'>
        design guideline
    </ExternalLink>;
};

export const LinkedMediaCenterWiki = () => {
    return <ExternalLink href='https://console.harmony.a2z.com/media-central/search-browse/browse/1/Alexa/Channels/Home'>
        media center
    </ExternalLink>;
};

export const LinkedSectionlineWiki = () => {
    return <ExternalLink href='https://wiki.labcollab.net/confluence/pages/viewpage.action?pageId=974450821'>
        section guideline
    </ExternalLink>;
};

export const LinkedReleaseScheduleWiki = () => {
    return <ExternalLink href='https://wiki.labcollab.net/confluence/display/ALEXAAPP/2022.16+-+Release'>
        release schedule
    </ExternalLink>;
};

export const LinkedCidLookUp = () => {
    return <ExternalLink href='https://www.amazon.com/gp/gurupa/test/index.html'>
        wiki
    </ExternalLink>;
};

export const LinkedJira = () => {
    return <ExternalLink href='https://issues.labcollab.net/browse/AMAX-20682'>
        here
    </ExternalLink>;
};

export const LinkedMhcWeblabGuideline = () => {
    return <ExternalLink href='https://wiki.labcollab.net/confluence/display/ALEXAAPP/Odyssey+Home+Cards+Onboarding+Guide'>
        guideline
    </ExternalLink>;
};
