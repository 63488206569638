import { IWeblab } from './Weblab';
import { IMetadata } from './Metadata';
import { IFilter } from './Filter';
import { ICifExperienceContent } from './cifExperience/CifExperienceContent';
import { ICifExperienceReferralContent } from './cifExperience/CifExperienceReferralContent';
import { INotificationExperienceContent } from './appDeviceExperience/NotificationExperienceContent';
import { IGuardrails } from './Guardrails';
import { ITimeRange } from './TimeRange';
import { ITemplateVariable } from './TemplateVariable';
import { IRemoteContextualSegments } from './RemoteContextualSegments';
import { DataSource } from './DataSource';
import { INluOpportunityTargeting } from './NluOpportunityTargeting';
import { PermissionRole } from './PermissionRole';
import { IOpportunityTrigger } from './NluOpportunityTrigger';
import { ISpeakerData } from './SpeakerData';
import { INluInfo } from './NluInfo';
import { IManagementVisualContent } from './cifExperience/visualExperience/IManagementVisualContent';
import { IApprovalMetadata } from './ApprovalInfo';
import { IRowCountryInfo } from './RowCountryInfo';
import { IJourneyMetadata } from './JourneyMetadata';
import { INotificationGuardrails } from './NotificationGuardrails';
import { IBusinessMetadata } from './BusinessInfo';
import { SubscriptionUpsellType } from './SubscriptionUpsellType';

export type ExperienceStatus =
    | 'DRAFT'
    | 'TESTABLE'
    | 'DISABLED'
    | 'SCHEDULING'
    | 'SCHEDULED'
    | 'UNDER_REVIEW'
    | 'PARTIALLY_LIVE'
    | 'LIVE'
    | 'LIVE_TEN_PERCENT'
    | 'LIVE_FIFTY_PERCENT'
    | 'LIVE_HUNDRED_PERCENT'
    | 'LAUNCHED'
    | 'PAUSED'
    | 'EXPIRED'
    | 'CANCELED';

export const EXPERIENCE_STATUS: ExperienceStatus[] = [
    'DRAFT',
    'TESTABLE',
    'DISABLED',
    'SCHEDULING',
    'SCHEDULED',
    'UNDER_REVIEW',
    'PARTIALLY_LIVE',
    'LIVE',
    'EXPIRED',
    'CANCELED'
];

export type ExperienceType = 'CIF' | 'AppNotification' | 'DeviceNotification' | 'MobileHomeCard' | 'RotatingContentPanel';

export const EXPERIENCE_TYPES: ExperienceType[] = [
    'CIF',
    'AppNotification',
    'DeviceNotification',
    'MobileHomeCard',
    'RotatingContentPanel'
];

export interface IExperience {
    id: string;
    // even title is required to create a new experience, we still have
    // old experiences that didn't have titles
    title?: string;
    type: ExperienceType;
    hintId?: string;
    capabilityId?: string;
    bypassCapabilityFilter?: boolean;
    contentId?: string;
    suggestedFeature?: INluInfo;
    filters:  { [key: string]: IFilter };
    weblab: IWeblab;
    metadata: IMetadata;
    guardrails?: IGuardrails;
    dayTimeRangeGuardrails?: {[key: number]: ITimeRange[]};
    status: ExperienceStatus;
    approvalId?: string;
    approvalInfo?: IApprovalMetadata;
    businessInfo?: IBusinessMetadata;
    subscriptionUpsellType?: SubscriptionUpsellType;
    operatorGroup?: string;
    cancellationReason?: string;
    pausedReason?: string;
    speakerData?: ISpeakerData;
    rowCountryInfo?: IRowCountryInfo;
    snsTopicBYORDataSource?: string;
    journeyMetadata?: IJourneyMetadata;

    // fields specific to CIF experience
    opportunityTriggers: IOpportunityTrigger[];
    opportunityTriggerExclusions: IOpportunityTrigger[];
    nluOpportunityTargetings: INluOpportunityTargeting[];
    nluOpportunityTargetingExclusions: INluOpportunityTargeting[];
    content: { [key: string]: ICifExperienceContent };
    visualContent?: { [key: string]: IManagementVisualContent };
    referralContent?: ICifExperienceReferralContent;
    featureHints?: string[];
    templateVariables?: ITemplateVariable[];
    remoteContextualSegments?: IRemoteContextualSegments;
    permissionRole?: PermissionRole;

    // fields specific to notification experience
    notificationContent?: { [key: string]: INotificationExperienceContent };
    dataSource?: DataSource;
    expiryTimeInHours?: number;
    notificationGuardrails?: INotificationGuardrails;
    publisherRoleForAutoCreatedSnsTopic?: string;
}

export interface IExperiencePage {
    experiments: IExperience[];
    lastEvaluatedKey?: string;
}
