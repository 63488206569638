import { ILoadExperienceAction } from '../actions/experienceDetailViewActions';
import {
    LOADING_EXPERIENCE_DETAIL,
    LOADED_EXPERIENCE_DETAIL,
    ERROR_LOADING_EXPERIENCE_DETAIL
} from '../constants/experienceDetailViewActionTypes';
import { transformExperience } from '../util/stringAndMappingHelper';
import { IFlattenedExperience } from '../models/FlattenedExperience';

export interface IExperienceDetailViewState {
    isLoading: boolean;
    experience?: IFlattenedExperience;
    error?: Error;
}

export const experienceDetailViewReducer = (
    state: IExperienceDetailViewState = { isLoading: false },
    action: ILoadExperienceAction
): IExperienceDetailViewState => {
    switch (action.type) {
        case LOADING_EXPERIENCE_DETAIL:
            return {
                ...state,
                isLoading: true
            };
        case LOADED_EXPERIENCE_DETAIL:
            const { experience } = action;
            // reducer is at top level. it should resolve error
            // and get it ready for rendering.
            if (!experience) {
                return {
                    ...state,
                    isLoading: false,
                    error: new Error('Loaded `undefined` experiences, something went wrong')
                };
            }
            return {
                ...state,
                isLoading: false,
                experience: transformExperience(experience),
                error: undefined
            };
        case ERROR_LOADING_EXPERIENCE_DETAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};
