import * as React from 'react';
import VCIFBasicInfoSectionView from '../../../containers/sections/VCIFBasicInfoSectionView';
import ImpressionTrackingSectionView from '../../../containers/sections/ImpressionTrackingSectionView';
import PromotedCapabilitySectionView from '../../../containers/sections/PromotedCapabilitySectionView';
import SuggestedFeatureSectionView from '../../../containers/sections/SuggestedFeatureSectionView';
import ConfigureAlexaLabMetricsView from '../../../containers/sections/ConfigureAlexaLabMetricsView';

export const VisualCIFExperienceDetailsTabView = () => {
    return <div>
        <VCIFBasicInfoSectionView />
        <ImpressionTrackingSectionView />
        <PromotedCapabilitySectionView />
        <SuggestedFeatureSectionView />
        <ConfigureAlexaLabMetricsView />
    </div>;
};
