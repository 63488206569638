import { Select } from '@amzn/awsui-components-react';

// mobile home card
export const HeroCardTitleCharLimit: number = 50;
export const HeroCardSubtitleCharLimit: number = 100;
export const HeroCardCallToActionButtonCharLimit: number = 35;
export const DiscoveryTitleCharLimit: number = 30;
export const DiscoverySubtitleCharLimit: number = 30;
export const DiscoveryLabelTextCharLimit: number = 20;
export const IosDevice = 'A2IVLV5VM2W81';
export const AndroidDevice = 'A2TF17PFR55MTB';
export const FireosDevice = 'FIREOS';

export const BackgroundColorOption = [
    {
        'label': 'None',
        'id': '',
    },
    {
        'label': 'Cyan',
        'id': 'cardBackgroundCyan',
    },
    {
        'label': 'Blue',
        'id': 'cardBackgroundBlue',
    },
    {
        'label': 'Green',
        'id': 'cardBackgroundGreen',
    },
    {
        'label': 'Yellow',
        'id': 'cardBackgroundYellow',
    },
    {
        'label': 'Blue-White',
        'id': 'gradientCardMonetizationBlueStartDefault',
    },
    {
        'label': 'Blue-Orange',
        'id': 'gradientCardMonetizationBlueOrangeStartDefault',
    },
    {
        'label': 'Green-Cyan',
        'id': 'gradientCardMonetizationGreenCyanStartDefault',
    },
    {
        'label': 'Orange-Blue',
        'id': 'gradientCardMonetizationOrangeBlueStartDefault',
    },
    {
        'label': 'Red-Violet',
        'id': 'gradientCardMonetizationRedVioletStartDefault',
    },
    {
        'label': 'Violet-Orange',
        'id': 'gradientCardMonetizationVioletOrangeStartDefault',
    },
    {
        'label': 'Violet-Teal',
        'id': 'gradientCardMonetizationVioletTealStartDefault',
    },
    {
        'label': 'Yellow-Orange',
        'id': 'gradientCardMonetizationYellowOrangeStartDefault',
    },
] as Select.Option[];