import { IPushNotificationAction } from '../actions/pushNotificationActions';
import { SENDING_NOTIFICATION, FINISHED_SENDING_NOTIFICATION, ERROR_SENDING_NOTIFICATION } from '../constants/pushNotificationActionTypes';

export interface IPushNotificationState {
    isLoading: boolean;
    result?: string;
    error?: Error;
}

export const pushNotificationReducer = (
    state: IPushNotificationState = { isLoading: false },
    action: IPushNotificationAction
): IPushNotificationState => {
    switch (action.type) {
        case SENDING_NOTIFICATION:
            return {
                ...state,
                isLoading: true
            };
        case FINISHED_SENDING_NOTIFICATION:
            const { result } = action;
            // reducer is at top level. it should resolve error
            // and get it ready for rendering.
            if (!result) {
                return {
                    ...state,
                    isLoading: false,
                    error: new Error('Unknown problem sending notification')
                };
            }
            return {
                ...state,
                isLoading: false,
                result
            };
        case ERROR_SENDING_NOTIFICATION:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};
