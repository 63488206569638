export const businessInfoMap: { [vertical: string]: string[] } = {
    'Alexa for Business': ['ALL', 'Conferencing', 'Room Booking'],
    'Audio': ['ALL', 'Audible', 'Books', 'Music', 'Music & Audio Skills'],
    'Car Control and Skills': ['ALL', 'Car Control', 'Connected Car Skills'],
    'Communications': ['ALL', 'Announcements', 'Calling', 'Connection Resolution', 'Contacts', 'Dropin', 'Messaging', 'Other'],
    'Games': ['ALL', 'Games, Trivia & Accessories Skills'],
    'Health': ['ALL', 'Health', 'Health & Fitness Skills'],
    'Home Productivity': ['Alarms', 'Alexa Email', 'ALL', 'Calendar', 'Notes', 'Productivity Skills', 'Reminders', 'Timers', 'Utilities Skills', 'WhatTime_WhatDay'],
    'Information': ['ALL', 'Information - Business', 'Information - Entertainment', 'Information - General Knowledge', 'Information - Help', 'Information - Sports', 'Information - Utilities', 'Local Information', 'Weather', 'Weather Skills'],
    'Kids': ['ALL', 'Kids Skills'],
    'Kitchen': ['ALL', 'Food & Drink Skills', 'Kitchen', 'Lifestyle Skills'],
    'Language Services': ['ALL', 'Translations'],
    'News': ['ALL', 'Flash Briefing', 'GoodNews', 'News Skills', "What's Up"],
    'Other': ['Alexa Trust', 'ALL', 'Bluetooth', 'Business & Finance Skills', 'Discovery', 'Education & Reference Skills', 'FBPhotos', 'Feedback', 'Global', 'IFTTT', 'Local Skills', 'Mobile App Launch', 'Notification Service', 'Other', 'Other Skills', 'Photos', 'Routines', 'Skills Management', 'Sports Skills'],
    'Personal Mobility': ['ALL', 'Navigation', 'Travel & Transportation Skills'],
    'Personality': ['AboutAlexa', 'ALL', 'Jokes', 'Novelty & Humor Skills', 'Originals', 'Phatic'],
    'Shopping': ['ALL', 'Shopping', 'Shopping Skills', 'To-Do'],
    'Smart Home': ['ALL', 'Smart Home', 'Smart Home Skills'],
    'Social': ['ALL', 'Social Skills'],
    'Video': ['ALL', 'Movies & TV Skills', 'Video']
};