import * as React from 'react';
import _ from 'lodash';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { Button, Checkbox, FormSection } from '@amzn/awsui-components-react';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { connect } from 'react-redux';
import { AppState } from '../../reducers/index';
import PromotedCapability from '../../components/common/PromotedCapability';
import { toggleCapabilitySearchModalVisibility, pushBypassCapabilityFilterAction, pushPromotedCapabilityAction } from '../../actions/experienceEditViewActions';
import CapabilitySearchComponent from '../../components/common/CapabilitySearchComponent';
import SkillExceptionComponent from '../../components/common/SkillExceptionComponent';
import AdminOnly from '../../components/common/AdminOnly';
import { ExternalLink } from '../../components/common/LinkComponents';
import { RequiredField } from '../../components/common/DescriptionAnnotations';

interface IPromotedCapabilitySectionViewProps {
    dispatch: any;
    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

interface IPromotedCapabilitySectionViewState {
    isExpIdAsCapId: boolean;
    isBypassCapabilityFilter: boolean;
    isSkillId: boolean;
}

export class PromotedCapabilitySectionView extends React.Component<IPromotedCapabilitySectionViewProps, IPromotedCapabilitySectionViewState> {

    constructor(props: IPromotedCapabilitySectionViewProps) {
        super(props);
        const { experience } = props;
        this.state = {
            isExpIdAsCapId: experience?.id === experience?.capabilityId,
            isBypassCapabilityFilter: experience?.bypassCapabilityFilter || false,
            isSkillId: experience?.featureType === 'Skill ID' || false
        };
    }

    toggleCapabilitySearchModal = () => {
        const {dispatch} = this.props;
        dispatch(toggleCapabilitySearchModalVisibility());
    }

    onChangeBypassCapabilityFilter = (checked: boolean) => {
        const {dispatch} = this.props;
        this.setState((prevState) => ({...prevState, isBypassCapabilityFilter: checked}));
        dispatch(pushBypassCapabilityFilterAction(checked));
    }

    onChangeBypassCapabilityId = (checked: boolean) => {
        const {dispatch, experience} = this.props;

        this.setState((prevState) => ({
            ...prevState,
            isExpIdAsCapId: checked
        }));

        if (checked) {
            dispatch(pushPromotedCapabilityAction({
                capabilityId: experience?.id
            }));
            this.onChangeBypassCapabilityFilter(checked);
        } else {
            if (experience?.capabilityId !== experience?.id) {
                dispatch(pushPromotedCapabilityAction({capabilityId: experience?.capabilityId}));
                this.onChangeBypassCapabilityFilter(experience?.bypassCapabilityFilter || false);
            } else {
                dispatch(pushPromotedCapabilityAction({}));
                this.onChangeBypassCapabilityFilter(checked);
            }
        }
    }

    onChangeSwitchToSkill = (checked: boolean) => {
        const { dispatch } = this.props;
        this.setState((prevState) => ({
            ...prevState,
            isSkillId: checked
        }));
        if (!checked) {
            dispatch(pushBypassCapabilityFilterAction(checked));
            dispatch(pushPromotedCapabilityAction({
                capabilityId: ''
            }));
            this.setState((prevState) => ({
                ...prevState,
                isExpIdAsCapId: checked,
                isBypassCapabilityFilter: checked
            }));
        }
    }

    render() {
        const { isEditing, isUpdating, experience, updateCandidate } = this.props;
        const { isExpIdAsCapId, isBypassCapabilityFilter, isSkillId } = this.state;

        const rcpCapability = experience?.contents && experience.type === 'RotatingContentPanel' ?
            experience.contents[0].contentId :
            updateCandidate?.getRotatingContentPanelContent()?.contentId;

        const capabilityIdExists = !_.isEmpty(experience?.capabilityId) || !_.isEmpty(updateCandidate?.getPromotedCapability()) || !_.isEmpty(rcpCapability);
        const hintIdEmpty = _.isEmpty(experience?.hintId);

        if (isSkillId) {
            return (
                <FormSection header='Promoted Skill' id='promoted-capability-section'>
                    <Checkbox
                        id={'skill-switch-checkbox'}
                        controlId={'skill-switch-checkbox'}
                        checked={isSkillId}
                        disabled = {!isEditing || isUpdating}
                        onChange={(e) => {
                            this.onChangeSwitchToSkill(e.detail.checked);
                        }}
                        ><b><i>Exception: </i>Use Skill ID instead of Capability ID</b> - if so,
                        <i> do not</i> create a SIM to request a Capability ID Exception.
                        <ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/Alexa+Odyssey#AlexaOdyssey-TitleandCapabilityID'}
                                    > See wiki for more info</ExternalLink>
                    </Checkbox>
                    <hr/>
                    <SkillExceptionComponent />
                </FormSection>
            );
        } else if (_.isEmpty(experience?.id)) {
            return (
                <FormSection header='Promoted Capability' id='promoted-capability-section'>
                    <Checkbox
                        id={'skill-switch-checkbox'}
                        controlId={'skill-switch-checkbox'}
                        checked={isSkillId}
                        disabled = {!isEditing || isUpdating}
                        onChange={(e) => {
                            this.onChangeSwitchToSkill(e.detail.checked);
                        }}
                        ><b><i>Exception: </i>Use Skill ID instead of Capability ID</b> - if so,
                        <i> do not</i> create a SIM to request a Capability ID Exception.
                        <ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/Alexa+Odyssey#AlexaOdyssey-TitleandCapabilityID'}
                                    > See wiki for more info</ExternalLink>
                    </Checkbox>
                    <hr/>
                    <CapabilitySearchComponent />
                </FormSection>
            );
        } else if (capabilityIdExists || hintIdEmpty) {
            return (
                <FormSection header={<RequiredField fieldName='Promoted Capability'/>} id='promoted-capability-section'>
                    <Checkbox
                        id={'skill-switch-checkbox'}
                        controlId={'skill-switch-checkbox'}
                        checked={isSkillId}
                        disabled = {!isEditing || isUpdating}
                        onChange={(e) => {
                            this.onChangeSwitchToSkill(e.detail.checked);
                        }}
                        ><b><i>Exception: </i>Use Skill ID instead of Capability ID</b> - if so,
                        <i> do not</i> create a SIM to request a Capability ID Exception.
                        <ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/Alexa+Odyssey#AlexaOdyssey-TitleandCapabilityID'}
                                    > See wiki for more info</ExternalLink>
                    </Checkbox>
                    <hr/>
                    <PromotedCapability />
                    { isEditing && <Button id='edit-capability-id' onClick={this.toggleCapabilitySearchModal} disabled={isExpIdAsCapId}>Edit Capability</Button>}

                    <AdminOnly>
                        <Checkbox
                            id='bypass-capability-filter-checkbox'
                            controlId='bypass-capability-filter-checkbox'
                            className='awsui-util-mt-m awsui-util-mb-xl'
                            disabled = {!isEditing || isUpdating}
                            checked={isBypassCapabilityFilter}
                            onChange={(e) => {
                                this.onChangeBypassCapabilityFilter(e.detail.checked);
                            }}
                        >
                            <div>Bypass Capability Engagement Filter</div>
                        </Checkbox>

                        <Checkbox
                            id='bypass-capability-id-checkbox'
                            controlId='bypass-capability-id-checkbox'
                            className='awsui-util-mt-m awsui-util-mb-xl'
                            disabled = {!isEditing || isUpdating}
                            checked={isExpIdAsCapId}
                            onChange={(e) => {
                                this.onChangeBypassCapabilityId(e.detail.checked);
                            }}
                        >
                            <div>Use ExpID as CapID</div>
                        </Checkbox>
                    </AdminOnly>
                </FormSection>
            );
        }
        return <></>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState, odysseyExperienceDetailViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || odysseyExperienceDetailViewState.experience,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(PromotedCapabilitySectionView);
