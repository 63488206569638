
export enum NextDialUpStatus {
    LIVE_TEN_PERCENT = 'Live (10%)',
    LIVE_FIFTY_PERCENT = 'Live (50%)',
    LIVE_HUNDRED_PERCENT = 'Live (100%)',
    LAUNCH_REVIEW = 'Launched',
    NONE = 'None',
    DIAL_DOWN_REVIEW = 'Dial-down review',
    RESUBMIT = 'Resubmit for approval'
}
