import * as React from 'react';
import { Button, Modal, Input } from '@amzn/awsui-components-react';

import { AppState } from '../../reducers';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { connect } from 'react-redux';
import { cancelExperienceAction, cancelOdysseyExperienceAction } from '../../actions/experienceEditViewActions';

interface CancelExperienceModalProps {
    dispatch: any;
    experience?: IFlattenedExperience;
    isVisible: boolean;
    hideModal: () => void;
}

interface CancelExperienceModalState {
    cancellationReason?: string;
    [key: string]: any;
}

class CancelExperienceModal extends React.Component<CancelExperienceModalProps, CancelExperienceModalState> {
    private static INITIAL_MODAL_STATE: CancelExperienceModalState = {
        cancellationReason: undefined
    };

    constructor(props: CancelExperienceModalProps) {
        super(props);
        this.state = CancelExperienceModal.INITIAL_MODAL_STATE;
    }

    updateState(object: { [key: string]: any }) {
        this.setState(object);
    }

    componentDidUpdate(prevProps: CancelExperienceModalState) {
        // if modal becomes visible again, need to clear off all previous state to a fresh state
        if(this.props.isVisible === true && prevProps.isVisible === false) {
            this.setState(CancelExperienceModal.INITIAL_MODAL_STATE);
        }
    }

    private validateModalCompletion(): boolean {
        const { cancellationReason } = this.state;

        return !!cancellationReason;
    }

    private cancelExperienceButtonClickHandler() {
        const { dispatch, hideModal, experience } = this.props;
        const { cancellationReason } = this.state;

        // cancel action on odyssey Experience
        if (this.validateModalCompletion() && experience && (experience.type === 'MobileHomeCard' || experience.type === 'RotatingContentPanel')) {
            hideModal();
            dispatch(cancelOdysseyExperienceAction(cancellationReason!));
            return;
        }

        if (this.validateModalCompletion()) {
            hideModal();
            dispatch(cancelExperienceAction(cancellationReason!));
        }
    }

    render() {
        const {
            isVisible,
            experience,
            hideModal
        } = this.props;
        const { cancellationReason } = this.state;

        if (experience === undefined || experience === null) {
            return <Modal>
                <div className='awsui-util-inactive'>
                    You cannot cancel an experience right now. Please try again later.
                </div>
            </Modal>;
        }

        return <Modal
            id='modal.cancel-experience'
            visible={isVisible}
            header='Cancel Experience'
            footer={<span className='awsui-util-f-r'>
                <Button
                    id='button.cancel-cancel-modal'
                    variant='link'
                    onClick={hideModal.bind(this)}>
                    Cancel
                </Button>
                    <Button
                        id='button.cancel-experience-in-modal'
                        variant='primary'
                        disabled={!this.validateModalCompletion()}
                        onClick={this.cancelExperienceButtonClickHandler.bind(this)}>
                        Cancel Experience
                    </Button>
                </span>}
            onDismiss={hideModal.bind(this)}>

            <p>
                Canceled experiences will not be served to customers.
                Canceling an experience is a permanent action.
                The experience <b>cannot</b> be un-canceled in the future.
            </p>

            <Input
                id='experience.cancel-reason'
                value={cancellationReason}
                placeholder='Put reason here'
                onInput={e => this.updateState({ cancellationReason: e.detail.value })}/>
        </Modal>;
    }
}

const mapStateToProps = ({ odysseyExperienceDetailViewState, experienceDetailViewState }: AppState) => {
    return {
        experience: experienceDetailViewState.experience || odysseyExperienceDetailViewState.experience
    };
};

export default connect(mapStateToProps)(CancelExperienceModal);
