import * as React from 'react';
import { Route, Switch } from 'react-router';

import { PAGE } from './constants/page';
import Experience from './containers/Experience';
import { Experiences } from './containers/Experiences';
import Dashboard from './containers/Dashboard';
import { AdminDashboard } from './components/AdminDashboard';
import Home from './containers/Home';
import { HashRouter } from 'react-router-dom';
import CloneExperience from './containers/CloneExperience';
import ExperienceCreationWorkflow from './containers/ExperienceCreationWorkflow';
import OdysseyAnnouncementUpdateWidget from './containers/OdysseyAnnouncementUpdateWidget';
import BypassPauseListUpdateWidget from './containers/BypassPauseListUpdateWidget';
import PromotedExperimentsUpdateWidget from './containers/PromotedExperimentsUpdateWidget';
import { GenerateVcifUsingAcc } from './containers/GenerateVcifUsingAcc';
import MonitoredExperimentsUpdateWidget from './containers/MonitoredExperimentsUpdateWidget';
import Investigator from './containers/Investigator';
import { HomeCardSuggestions } from './containers/HomeCardSuggestions';
import HomeCardSuggestion from './containers/HomeCardSuggestion';
import { connect } from 'react-redux';
import { INavigationViewState } from './reducers/navigationViewReducer';
import { IAuthenticationState } from './reducers/authenticationReducer';
import OdysseyExperience  from './containers/v2/OdysseyExperience';
import { OdysseyExperiences } from './containers/v2/OdysseyExperiences';
import { SpiProviderOnboardingWidget } from './containers/v2/SpiProviderOnboardingWidget';
import SpiProviderConfig from './containers/v2/SpiProviderConfig';
import SpiProviderOnboardingNewProvider from './containers/v2/SpiProviderOnboardingNewProvider';
import SpiProviderConfigVersion from './containers/v2/SpiProviderConfigVersion';
// TODO: implement lazy loading here once we think it is too slow to load the page

interface IRoutesProps {
    userAlias: string;
    permissions: string[];
    dispatch?: any;
}
export class Routes extends React.Component<IRoutesProps> {
    public render() {
        const { permissions } = this.props;
        return <HashRouter>
            <Switch>
                <Route exact path={PAGE.NewExperience} component={ExperienceCreationWorkflow} />
                <Route path={PAGE.CloneExperience} component={CloneExperience} />
                <Route path={PAGE.ExperienceDetail} component={Experience} />
                <Route path={PAGE.Experiences} component={Experiences} />

                <Route path={PAGE.MhcExperienceDetail} render={(routerProps) => <OdysseyExperience {...routerProps} experiencesLinkableUrl={PAGE.MhcExperiences} />} />
                <Route path={PAGE.MhcExperiences} render={() => <OdysseyExperiences />} />

                <Route path={PAGE.RcpExperienceDetail} render={(routerProps) => <OdysseyExperience {...routerProps} experiencesLinkableUrl={PAGE.RcpExperiences} />} />
                <Route path={PAGE.RcpExperiences} render={() => <OdysseyExperiences />} />

                <Route path={PAGE.SpiProviderOnboardingConfigVersion} render={() => <SpiProviderConfigVersion/>} />
                <Route path={PAGE.SpiProviderOnboardingNewProvider} render={() => <SpiProviderOnboardingNewProvider/>} />
                <Route path={PAGE.SpiProviderOnboardingConfig} render={() => <SpiProviderConfig/>} />
                <Route path={PAGE.SpiProviderOnboarding} render={() => <SpiProviderOnboardingWidget />} />

                <Route exact path={PAGE.Dashboard} component={Dashboard} />
                <Route exact path={PAGE.Investigator} component={Investigator} />
                <Route exact path={PAGE.AdminDashboard} component={AdminDashboard} />
                <Route exact path={PAGE.OdysseyAnnouncementUpdateWidget} component={OdysseyAnnouncementUpdateWidget} />
                <Route exact path={PAGE.BypassPauseListUpdateWidget} component={BypassPauseListUpdateWidget} />
                <Route exact path={PAGE.MonitoredExperimentsUpdateWidget} component={MonitoredExperimentsUpdateWidget} />
                <Route exact path={PAGE.PromotedExperimentsUpdateWidget} component={PromotedExperimentsUpdateWidget} />
                <Route exact path={PAGE.GenerateVcifUsingAcc} component={GenerateVcifUsingAcc} />
                {(permissions.includes('ADMIN') || permissions.includes('OPERATOR')) ?
                    <Route exact path={PAGE.HomeCardSuggestions} component={HomeCardSuggestions}/> : <Route path={PAGE.Home} component={Home} />}
                <Route exact path={PAGE.HomeCardSuggestionDetails} component={HomeCardSuggestion}/>

                <Route path={PAGE.Home} component={Home} />
            </Switch>
        </HashRouter>;
    }
}


const mapStateToProps = ({ navigationViewState, authenticationState }: { navigationViewState: INavigationViewState, authenticationState: IAuthenticationState }) => {
    return {
        userAlias: navigationViewState.userAlias || 'nobody',
        permissions: authenticationState.permissions || []
    };
};

export default connect(mapStateToProps)(Routes);
