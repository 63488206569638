import * as React from 'react';
import _ from 'lodash';
import { Component } from 'react';
import { ExperienceStatus } from '../../models/Experience';
import { IMetadata } from '../../models/Metadata';
import { LinkedPhonetool, LinkedPermissionGroup, LinkedHintId, LinkedSimTicket, LinkedCapabilityId } from '../common/LinksRenderHelper';
import { fromTimestampToFormattedDateTime } from '../../util/stringAndMappingHelper';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { KeyValuePairMultipleColumnView } from '../common/GridKeyValuePair';
import { StatusBadge } from '../common/StatusBadge';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { MARKETPLACES } from '../../constants/marketplaces';


const ExperienceDetailGlobalHeader = (props: { status: ExperienceStatus }) => {
    const { status } = props;
    return <div className='awsui-util-container-header'>
        <div className='awsui-grid'>
            <div className='awsui-row'>
                <div className='col-12 awsui-util-t-r'>
                    <StatusBadge status={status} controlId='experience.summary-status' />
                </div>
            </div>
        </div>
    </div>;
};

const ExperienceDetailGlobalFooter = (props: { metadata: IMetadata }) => {
    const { createdAt, createdBy, modifiedAt, modifiedBy } = props.metadata;
    const createdTime = fromTimestampToFormattedDateTime(createdAt);
    const modifiedTime = fromTimestampToFormattedDateTime(modifiedAt);

    return <div className='awsui-grid'>
        <div className='awsui-row'>
            <div className='col-6'>
                Created by <LinkedPhonetool userAlias={createdBy} /> @ {createdTime}
            </div>
            <div className='col-6 awsui-util-t-r'>
                Modified by <LinkedPhonetool userAlias={modifiedBy} /> @ {modifiedTime}
            </div>
        </div>
    </div>;
};

export default class ExperienceDetailGlobalView extends Component<{
    experience: IFlattenedExperience,
    isEditing: boolean,
    updateCandidate?: ExperienceUpdateCandidate
}> {
    public render() {
        const { experience, isEditing, updateCandidate } = this.props;

        const status: ExperienceStatus = (isEditing && updateCandidate)
            ? updateCandidate.getStatus()
            : experience.status;

        const isRcp = experience.type === 'RotatingContentPanel';
        const metadata = updateCandidate ? updateCandidate.getMetadata() : experience.metadata;
        const marketplace = isRcp ? MARKETPLACES[_.get(experience, 'contents[0].marketPlace', undefined)] : experience.marketplace;
        const locale = isRcp ? _.get(experience, 'contents[0].locale', undefined) : experience.locale;
        const capabilityId = isRcp ? _.get(experience, 'contents[0].contentId', undefined) : experience.capabilityId;

        return <div className='awsui-util-container'>
            <ExperienceDetailGlobalHeader status={status} />
            <KeyValuePairMultipleColumnView
                data={[[{
                    key: 'Owner',
                    value: <LinkedPhonetool userAlias={experience.metadata
                        ? experience.metadata.createdBy
                        : undefined} />
                }, {
                    key: 'Group Permissions',
                    value: <LinkedPermissionGroup permissionGroup={experience.operatorGroup} />
                }, {
                    key: 'SIM tracking',
                    value: <LinkedSimTicket simDocumentId={experience.approvalInfo?.simDocumentId} />
                }], [{
                    key: 'Marketplace',
                    value: marketplace || '-'
                }, {
                    key: 'Locale',
                    value: locale || '-'
                }], (experience.featureType === 'Hint ID') ? [{
                    key: 'Suggested Feature',
                    value: <LinkedHintId hintId={experience.hintId || experience.contentId} />
                }] : (experience.featureType === 'Skill ID') ? [{
                    key: 'ID of Promoted Skill',
                    value: capabilityId || '-'
                }] : [{
                    key: 'ID of Promoted Capability',
                    value: <LinkedCapabilityId capabilityId={capabilityId} locale={locale} />
                }]
            ]}
            />
            {metadata && <ExperienceDetailGlobalFooter metadata={experience.metadata} />}
        </div>;
    }
}
