import * as React from 'react';
import { Component } from 'react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { Calendar, momentLocalizer, Event } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ExperienceType, ExperienceStatus } from '../../models/Experience';
import { StatusBadgeMap } from '../../constants/statusBadgeMap';
import { defaultStringComparator } from '../../constants/tableColumnConfiguration';
import { getLinkableUrl, PAGE } from '../../constants/page';
import { RRule, Weekday } from 'rrule';
import { ITimeRange, getDefaultTimeRange, getDefaultDayTimeGuardrails } from '../../models/TimeRange';

const DAYS_TO_RRULE: { [key: number]: Weekday } = {
    1: RRule.MO,
    2: RRule.TU,
    3: RRule.WE,
    4: RRule.TH,
    5: RRule.FR,
    6: RRule.SA,
    7: RRule.SU
};

const formatDate = (date: Date): Date => {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
                    date.getUTCHours(),    date.getUTCMinutes());
};

const isDayTimeGuardrailsEmpty = (dayTimeGuardrails: {[key: number]: ITimeRange[]}): boolean => {
    return (Object.keys(dayTimeGuardrails || {}).length === 0);
};


const extractEvents = (experience: IFlattenedExperience): Event[] => {
    const events: any[] = [];

    const isVisualCIF = experience.visualContent? true: false;
    const DEFAULT_TIME_RANGE = getDefaultTimeRange(experience.type, isVisualCIF);
    const DEFAULT_DAY_TIME_GUARDRAILS = getDefaultDayTimeGuardrails(experience.type, isVisualCIF);

    const year = moment().toDate().getUTCFullYear();

    const startDate: Date = new Date(experience.enabledFilter?.activationConfiguration?.startDate
        || year + '-01-01');
    const endDate:   Date = new Date(experience.enabledFilter?.activationConfiguration?.endDate
        || year + '-12-31');

    const dayTimeGuardrails : {[key: number]: ITimeRange[]}
        = isDayTimeGuardrailsEmpty(experience.dayTimeRangeGuardrails || {}) ?
    DEFAULT_DAY_TIME_GUARDRAILS : (experience.dayTimeRangeGuardrails || DEFAULT_DAY_TIME_GUARDRAILS);


    Object.keys(dayTimeGuardrails).forEach((dayId, value) => {

        const timeRange: ITimeRange =
                (dayTimeGuardrails &&
                 dayTimeGuardrails[Number(dayId)] &&
                 dayTimeGuardrails[Number(dayId)][0]) || DEFAULT_TIME_RANGE;

        const starts: Date[] = new RRule({
            freq: RRule.WEEKLY,
            byweekday: [DAYS_TO_RRULE[Number(dayId)]],
            dtstart: new Date(Date.UTC(
                moment(startDate).add(1, 'day').toDate().getFullYear(),
                moment(startDate).add(1, 'day').toDate().getMonth(),
                moment(startDate).add(1, 'day').toDate().getDate(),
                timeRange.startHour,
                timeRange.startMin)),
            until: new Date(Date.UTC(
                year,
                moment(endDate).add(1, 'day').toDate().getMonth(),
                moment(endDate).add(1, 'day').toDate().getDate(),
                23,
                59)),
        }).all();


        starts.forEach(((date) => {
            events.push({
                start: formatDate(date),
                end: moment(formatDate(date)).hour(timeRange.endHour).minutes(timeRange.endMin).toDate(),
                title: experience.title,
                id: experience.id,
                status: experience.status
            });
        }));
    });

    return events;
};

export class ExperienceGraph extends Component<{flattenedExperiences: IFlattenedExperience[],
    type: ExperienceType, status: ExperienceStatus }> {

    eventPropGetter = (event: any) => {
        return {
            style: {
                background: StatusBadgeMap[event.status as ExperienceStatus].color
            }
        };
    }

    eventSelectHandler = (event: any) => {
        window.location.assign(getLinkableUrl(PAGE.Experiences) + '/' + event.id);
    }

    render() {

        const { flattenedExperiences, type, status } = this.props;

        const localizer = momentLocalizer(moment);

        const events: Event[] = [];

        flattenedExperiences
            .filter(experience => experience.type === type)
            .filter(experience => experience.status === status)
            .forEach(experience => {events.push(...extractEvents(experience));});

        events.sort((a: any, b: any) => {
            return defaultStringComparator(a.status, b.status);
        });

        return <div style={{ height: 600, width: '100%' }}>
            {<Calendar
                localizer={localizer}
                events={events}
                startAccessor='start'
                endAccessor='end'
                eventPropGetter={this.eventPropGetter}
                onSelectEvent={this.eventSelectHandler}
                showMultiDayTimes={true}
                min={moment().hours(6).minutes(0).toDate()}
                formats={{
                    timeGutterFormat: (date, culture) =>
                      localizer.format(date, 'HH:mm', culture || 'en-gb'),
                    eventTimeRangeFormat: ({ start, end }, culture) => {
                      const s = localizer.format(start, 'HH:mm', culture || 'en-gb');
                      const e = localizer.format(end, 'HH:mm', culture || 'en-gb');
                      return `${s} - ${e}`;
                    },
                    agendaTimeRangeFormat: ({ start, end }, culture) => {
                      const s = localizer.format(start, 'HH:mm', culture || 'en-gb');
                      const e = localizer.format(end, 'HH:mm', culture || 'en-gb');
                      return `${s} - ${e}`;
                    }
                  }}
            />}
        </div>;
    }
}
