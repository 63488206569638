import { ILoadExperiencesAction } from '../actions/experienceListViewActions';
import { IFlattenedExperience } from '../models/FlattenedExperience';
import { transformExperience } from '../util/stringAndMappingHelper';
import { UNSET_EXPERIENCES, REQUESTED_EXPERIENCES_PAGINATE, RECEIVED_EXPERIENCES_PAGINATE, ERROR_FETCHING_EXPERIENCES_PAGINATE, UPDATED_PROGRESS } from '../constants/experienceListViewActionTypes';
import _ from 'lodash';


export interface IExperienceListViewState {
    isLoading: boolean;
    flattenedExperiences?: IFlattenedExperience[];
    lastEvaluatedKey?: string;
    error?: Error;
    succeeded?: number;
    failed?: number;
    redirected: boolean;
}

export const experienceListViewReducer = (
    state: IExperienceListViewState = { isLoading: false, redirected: false },
    action: ILoadExperiencesAction
): IExperienceListViewState => {
    switch (action.type) {
        case REQUESTED_EXPERIENCES_PAGINATE:
            return {
                ...state,
                redirected: false,
                isLoading: true
            };
        case RECEIVED_EXPERIENCES_PAGINATE:
            // reducer is at top level. it should resolve error
            // and get it ready for rendering.
            if (state.redirected) {
                return state;
            }
            if (!action.experiences) {
                return {
                    ...state,
                    isLoading: false,
                    error: new Error('Loaded `undefined` experiences, something went wrong')
                };
            }

            const flattenedExperiences = (state.flattenedExperiences || []).concat(action.experiences.map(experience => transformExperience(experience)));

            return {
                ...state,
                isLoading: false,
                lastEvaluatedKey: action.lastEvaluatedKey,
                flattenedExperiences: _.uniqBy(flattenedExperiences, (experience) => { return experience.id; }),
                error: undefined
            };
        case ERROR_FETCHING_EXPERIENCES_PAGINATE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case UNSET_EXPERIENCES:
            return {
                ...state,
                flattenedExperiences: undefined,
                error: undefined,
                isLoading: false,
                redirected: true
            };
        case UPDATED_PROGRESS:
            return {
                ...state,
                succeeded: action.succeeded,
                failed: action.failed
            };
        default:
            return state;
    }
};
