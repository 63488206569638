import * as React from 'react';
import { Table, TablePropertyFiltering, TableSorting, TableContentSelector } from '@amzn/awsui-components-react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { LinkedPermissionGroup, LinkedPhonetool, LinkedWeblab, LinkedApproval } from '../../components/common/LinksRenderHelper';
import { StatusBadgeMap } from '../statusBadgeMap';
import { StatusBadge } from '../../components/common/StatusBadge';
import { getLinkableUrlByExperienceType } from '../page';
import { MARKETPLACE_TO_REGION } from '../marketplaces';
import { LOCALE_TO_MARKETPLACE } from '../locales';

// lower case comparison with null value tolerance
export const defaultStringComparator = (a?: string, b?: string) => {
    if (a && b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
    }

    return !a && !b ? 0 : (!a ? -1 : 1);
};

// number comparison
export const defaultNumberComparator = (a?: number, b?: number) => {
    if (a && b) {
        return a > b ? 1 : (a < b ? -1 : 0);
    }

    return !a && !b ? 0 : (!a ? -1 : 1);
};

interface IColumnConfig {
    id: string;
    header: string;
    width: number;
    minWidth: string;
    renderingFunction: (item: IFlattenedExperience) => React.ReactNode;
    filteringOptions: string[];
    visible: boolean;
    sortingComparator: (a: IFlattenedExperience, b: IFlattenedExperience) => number;
}

// experience ID
const idColumnConfig: IColumnConfig = {
    id: 'id',
    header: 'ID',
    width: 320,
    minWidth: '250px',
    renderingFunction: item => {
        return <a href={getLinkableUrlByExperienceType(item.type, item.id)}>{item.id || '-'}</a>;
    },
    filteringOptions: [],
    visible: false,
    sortingComparator: (a, b) => defaultStringComparator(a.id, b.id)
};

// experience title
const titleColumnConfig: IColumnConfig = {
    id: 'title',
    header: 'Title',
    width: 350,
    minWidth: '300px',
    renderingFunction: item => {
        return <a href={getLinkableUrlByExperienceType(item.type, item.id)}>{item.title || '-'}</a>;
    },
    filteringOptions: [],
    visible: true,
    sortingComparator: (a, b) => defaultStringComparator(a.title, b.title)
};

// experience content
const contentColumnConfig: IColumnConfig = {
    id: 'contentString',
    header: 'Content',
    width: 150,
    minWidth: '150px',
    renderingFunction: item => item.contentString,
    filteringOptions: [],
    visible: false,
    sortingComparator: (a, b) => defaultStringComparator(a.contentString, b.contentString)
};

// experience type
const experienceTypeColumnConfig: IColumnConfig = {
    id: 'shortenedType',
    header: 'Type',
    width: 100,
    minWidth: '100px',
    renderingFunction: item => item.shortenedType,
    filteringOptions: ['CIF', 'App', 'Device', 'MHC', 'RCP'],
    visible: true,
    sortingComparator: (a, b) => defaultStringComparator(a.type, b.type)
};


// experience content ID
const contentIdColumnConfig: IColumnConfig = {
    id: 'contentId',
    header: 'Content ID',
    width: 150,
    minWidth: '150px',
    renderingFunction: item => item.contentId,
    filteringOptions: [],
    visible: false,
    sortingComparator: (a, b) => defaultStringComparator(a.contentId, b.contentId)
};

// experience bullseye segment ID
const bullseyeSegmentColumnConfig: IColumnConfig = {
    id: 'bullseyeSegment',
    header: 'Bullseye Segment',
    width: 100,
    minWidth: '100px',
    renderingFunction: item => item.bullseyeSegment,
    filteringOptions: [],
    visible: true,
    sortingComparator: (a, b) => defaultStringComparator(a.bullseyeSegment, b.bullseyeSegment)
};

// experience currentStateDuration
const currentStateDurationColumnConfig: IColumnConfig = {
    id: 'currentStateDuration',
    header: 'Current State Duration (Days)',
    width: 250,
    minWidth: '250px',
    renderingFunction: item => item.currentStateDuration,
    filteringOptions: [],
    visible: true,
    sortingComparator: (a, b) => defaultNumberComparator(a.currentStateDuration, b.currentStateDuration)
};

// experience operator group
const operatorGroupColumnConfig: IColumnConfig = {
    id: 'operatorGroup',
    header: 'Operator Group',
    width: 150,
    minWidth: '150px',
    renderingFunction: item => <LinkedPermissionGroup permissionGroup={item.operatorGroup} />,
    filteringOptions: [],
    visible: false,
    sortingComparator: (a, b) => defaultStringComparator(a.operatorGroup, b.operatorGroup)
};

// experience marketplace
const marketplaceColumnConfig: IColumnConfig = {
    id: 'marketplace',
    header: 'Marketplace',
    width: 50,
    minWidth: '50px',
    renderingFunction: item => item.marketplace,
    filteringOptions: Object.keys(MARKETPLACE_TO_REGION),
    visible: true,
    sortingComparator: (a, b) => defaultStringComparator(a.marketplace, b.marketplace)
};

// experience locale
const localeColumnConfig: IColumnConfig = {
    id: 'locale',
    header: 'Locale',
    width: 120,
    minWidth: '120px',
    renderingFunction: item => item.locale,
    filteringOptions: Object.keys(LOCALE_TO_MARKETPLACE),
    visible: true,
    sortingComparator: (a, b) => defaultStringComparator(a.locale, b.locale)
};


// experience weblab
const weblabColumnConfig: IColumnConfig = {
    id: 'weblabName',
    header: 'Weblab',
    width: 250,
    minWidth: '250px',
    renderingFunction: item => <LinkedWeblab weblab={item.weblabName} />,
    filteringOptions: [],
    visible: true,
    sortingComparator: (a, b) => defaultStringComparator(a.weblabName, b.weblabName)
};

// experience status
const statusColumnConfig: IColumnConfig = {
    id: 'status',
    header: 'Status',
    width: 150,
    minWidth: '150px',
    renderingFunction: item => <StatusBadge status={item.status} />,
    filteringOptions: Object.keys(StatusBadgeMap),
    visible: true,
    sortingComparator: (a, b) => defaultStringComparator(a.status, b.status)
};

const approvalIdColumnConfig: IColumnConfig = {
    id: 'approvalId',
    header: 'Approval ID',
    width: 150,
    minWidth: '150px',
    renderingFunction: item => <LinkedApproval approvalId={item.approvalId} />,
    filteringOptions: [],
    visible: false,
    sortingComparator: (a, b) => defaultStringComparator(a.approvalId, b.approvalId)
};

// experience owner / creator
const ownerColumnConfig: IColumnConfig = {
    id: 'createdBy',
    header: 'Owner',
    width: 150,
    minWidth: '150px',
    renderingFunction: item => <LinkedPhonetool userAlias={item.createdBy} />,
    filteringOptions: [],
    visible: true,
    sortingComparator: (a, b) => defaultStringComparator(a.createdBy, b.createdBy)
};

// experience creation date
const createdDateColumnConfig: IColumnConfig = {
    id: 'createdDate',
    header: 'Create Date',
    width: 150,
    minWidth: '150px',
    renderingFunction: item => item.createdDate,
    filteringOptions: [],
    visible: false,

    sortingComparator: (a, b) => defaultNumberComparator(a.metadata.createdAt, b.metadata.createdAt)
};

// experience modifiedBy
const modifiedByColumnConfig: IColumnConfig = {
    id: 'modifiedBy',
    header: 'Modified By',
    width: 150,
    minWidth: '150px',
    renderingFunction: item => <LinkedPhonetool userAlias={item.modifiedBy} />,
    filteringOptions: [],
    visible: false,
    sortingComparator: (a, b) => defaultStringComparator(a.modifiedBy, b.modifiedBy)
};

// experience modified date
const modifiedDateColumnConfig: IColumnConfig = {
    id: 'modifiedDate',
    header: 'Modified Date',
    width: 250,
    minWidth: '250px',
    renderingFunction: item => item.modifiedDate,
    filteringOptions: [],
    visible: true,
    sortingComparator: (a, b) => defaultNumberComparator(a.metadata.modifiedAt, b.metadata.modifiedAt)
};

// experience start date
const startDateColumnConfig: IColumnConfig = {
    id: 'startDate',
    header: 'Start Date',
    width: 250,
    minWidth: '150px',
    renderingFunction: item => item.startDate,
    filteringOptions: [],
    visible: true,
    sortingComparator: (a, b) => defaultStringComparator(a.startDate, b.startDate)
};

// experience end date
const endDateColumnConfig: IColumnConfig = {
    id: 'endDate',
    header: 'End Date',
    width: 250,
    minWidth: '150px',
    renderingFunction: item => item.endDate,
    filteringOptions: [],
    visible: true,
    sortingComparator: (a, b) => defaultStringComparator(a.endDate, b.endDate)
};



const columnConfigs: IColumnConfig[] = [
    idColumnConfig,
    titleColumnConfig,
    experienceTypeColumnConfig,
    contentIdColumnConfig,
    bullseyeSegmentColumnConfig,
    operatorGroupColumnConfig,
    marketplaceColumnConfig,
    localeColumnConfig,
    weblabColumnConfig,
    approvalIdColumnConfig,
    ownerColumnConfig,
    startDateColumnConfig,
    endDateColumnConfig,
    createdDateColumnConfig,
    modifiedByColumnConfig,
    modifiedDateColumnConfig,
    contentColumnConfig,
    currentStateDurationColumnConfig,
    statusColumnConfig,
];

export const COLUMN_DEFINITIONS: Table.ColumnDefinition<IFlattenedExperience>[] = columnConfigs.map(
    (config: IColumnConfig) => {
        return {
            id: config.id,
            header: config.header,
            minWidth: config.minWidth,
            cell: config.renderingFunction
        };
    });

export const FILTERING_OPTIONS: TablePropertyFiltering.Option[] = columnConfigs.map((config: IColumnConfig) => {
    return {
        groupValuesLabel: config.header + ' Values',
        propertyKey: config.id,
        propertyLabel: config.header,
        values: config.filteringOptions
    };
}).sort((a, b) => defaultStringComparator(a.propertyLabel, b.propertyLabel));

export const SORTABLE_COLUMNS: TableSorting.SortableColumn<IFlattenedExperience>[] = columnConfigs.map(
    (config: IColumnConfig) => {
        return {
            id: config.id,
            field: config.id,
            comparator: config.sortingComparator
        };
    });

export const SELECTABLE_COLUMNS: TableContentSelector.ContentDescription[] = columnConfigs.map(
    (config: IColumnConfig) => {
        return {
            id: config.id,
            label: config.header,
            visible: config.visible
        };
    }
);
