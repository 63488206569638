import * as React from 'react';
import { Component } from 'react';
import { Parser } from 'json2csv';

import {
    ButtonDropdown, TableSelection, Modal, Button, ProgressBar, Flashbar,
    Table, TablePropertyFiltering, TablePagination, TableSorting, Input,
    TablePreferences, TableContentSelector, TablePageSizeSelector, TableWrapLines
} from '@amzn/awsui-components-react';

import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { EmptyDisplay } from '../EmptyDisplay';
import { NoMatchDisplay } from '../NoMatchDisplay';
import { COLUMN_DEFINITIONS, FILTERING_OPTIONS, SORTABLE_COLUMNS, SELECTABLE_COLUMNS } from '../../constants/tableColumnConfiguration';
import { countTextFunction, fromTimestampToFormattedDateTime } from '../../util/stringAndMappingHelper';
import { unsetExperiences, getExperiencesPaginateAction } from '../../actions/experienceListViewActions';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { fromCompressedSearchParamsToPropertyFilters, fromPropertyFiltersToCompressedSearchParams } from '../../models/uri/SearchParams';
import { ExportType } from '../../models/ExportType';
import { BulkUpdateActionType } from '../../models/BulkUpdateActionType';
import { experiencesPageLimit } from '../../constants/componentConstants';
import { getPermissionAction } from '../../actions/authenticationActions';
import { bulkUpdateDevices, bulkUpdateExperienceStatus, bulkUpdateAction, bulkTransferOwner } from '../../actions/bulkUpdateActions';
import { updatedProgressBar } from '../../actions/experienceListViewActions';
import { DeviceComponent } from '../../components/common/DeviceComponent';
import { generateDeviceGroups } from '../../containers/sections/CifDevicesSectionView';
import { DEVICES } from '../../constants/devices';
import { InputWrapper } from '../common/InputWrapper';

interface IExperiencesProps {
    // from Redux
    isLoading: boolean;
    flattenedExperiences: IFlattenedExperience[];
    lastEvaluatedKey?: string;
    error?: Error;
    permissions: string[];
    succeeded: number;
    failed: number;

    // for multi clone landing
    isCreatingMultiCloneExperiences?: boolean;
    multiCloneErrors?: Error[];
    cloneExperienceTitle?: string;
    newExperiencesCount?: number;

    // Redux inherited
    dispatch: any;

    // initialized
    updateFilteringCriteria: (searchCriteriaString: string) => void;
    searchCriteriaString: string | null;
}

interface IExperienceBulkUpdateState {
    selectedItems: IFlattenedExperience[];
    bulkUpdateActionType?: BulkUpdateActionType;

    avsDeviceInput?: string;
    devices: string[];
    newOwner: string;
    pauseReason?: string;

    pauseBtnDisabled: boolean;
    bulkUpdateDeviceModalVisible: boolean;
    bulkUpdateExperienceStatusModalVisible: boolean;
    bulkTransferOwnerModalVisible: boolean;
}

export class ExperienceTable extends Component<IExperiencesProps, IExperienceBulkUpdateState> {

    constructor(props: IExperiencesProps) {
        super(props);
        this.state = {
            selectedItems: [],
            avsDeviceInput: '',
            devices: [],
            newOwner: '',
            pauseBtnDisabled: true,
            bulkUpdateActionType: undefined,
            bulkUpdateDeviceModalVisible: false,
            bulkUpdateExperienceStatusModalVisible: false,
            bulkTransferOwnerModalVisible: false
        };
    }

    public componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getExperiencesPaginateAction(experiencesPageLimit));
        dispatch(getPermissionAction());
    }

    public componentDidUpdate() {
        const { dispatch, lastEvaluatedKey, isLoading } = this.props;

        if (!isLoading && lastEvaluatedKey) {
            dispatch(getExperiencesPaginateAction(experiencesPageLimit, lastEvaluatedKey));
        }

    }

    public componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(unsetExperiences());
    }

    private toggleUpdateDeviceModalVisibility() {
        const { bulkUpdateDeviceModalVisible } = this.state;
        this.setState(prevState => {
            return {
                ...prevState,
                bulkUpdateDeviceModalVisible: !bulkUpdateDeviceModalVisible
            };
        });
    }

    private toggleUpdateExperienceStatusModalVisibility() {
        const { bulkUpdateExperienceStatusModalVisible } = this.state;
        this.setState(prevState => {
            return {
                ...prevState,
                bulkUpdateExperienceStatusModalVisible: !bulkUpdateExperienceStatusModalVisible
            };
        });
    }

    private toggleTransferOwnerModalVisibility() {
        const { bulkTransferOwnerModalVisible } = this.state;
        this.setState(prevState => {
            return {
                ...prevState,
                bulkTransferOwnerModalVisible: !bulkTransferOwnerModalVisible
            };
        });
    }

    private setBulkUpdateAction(action: BulkUpdateActionType) {
        this.setState({bulkUpdateActionType: action});
    }

    private setAVSDeviceInput(event: CustomEvent<Input.ChangeDetail>) {
        this.setState({avsDeviceInput: event.detail.value});
    }

    private setDeviceSelection(newDevices: string[]) {
        this.setState({devices: newDevices});
    }

    private setNewOwner(data: string) {
        this.setState({newOwner: data});
    }

    private exportToJsonOrCSV(type?: string) {
        const { flattenedExperiences } = this.props;
        const json2csvParser = new Parser();
        if (flattenedExperiences) {
            const fileName = `Experiences Dump ${fromTimestampToFormattedDateTime(Date.now())}.${ExportType.JSON_FILE === type ? 'json' : 'csv'}`;
            const blob = type === ExportType.JSON_FILE
                ? new Blob([JSON.stringify(flattenedExperiences)], {type: 'text/plain;charset=utf-8'})
                : new Blob([json2csvParser.parse(flattenedExperiences)], {type: 'text/plain;charset=utf-8'});
            saveAs(blob, fileName);
        }
    }

    private clearProgressBar() {
        const { dispatch } = this.props;
        dispatch(updatedProgressBar(0,0));
    }

    private onAVSDeviceInputEnter(devices: string[]) {
        this.state.avsDeviceInput?.split(/[,|]+/)
            .map(tag => tag.trim())
            .filter(tag => (tag.length > 0 && !devices.includes(tag)))
            .map((tag) => { devices.push(tag);});

        this.setState({avsDeviceInput: ''});
        this.setDeviceSelection(devices);
    }

    private updateMetadataHandler() {
        const { dispatch } = this.props;
        const { bulkUpdateActionType, newOwner, selectedItems } = this.state;

        const updatedItems = selectedItems.map(
            experience => bulkTransferOwner(experience, newOwner, bulkUpdateActionType)
        );

        dispatch(bulkUpdateAction(updatedItems));

        this.toggleTransferOwnerModalVisibility();
    }

    private updateExperienceStatusHandler() {
        const adminPauseReasonPrefix = 'Odyssey Admin:';
        const { dispatch } = this.props;
        const { bulkUpdateActionType, selectedItems } = this.state;

        const updatedItems = selectedItems.map(
            experience => {
                if (this.state.pauseReason) {
                    experience.pausedReason = `${adminPauseReasonPrefix} ${this.state.pauseReason}`;
                }
                return bulkUpdateExperienceStatus(experience, bulkUpdateActionType);
            }
        );

        this.setState({pauseReason: undefined});

        dispatch(bulkUpdateAction(updatedItems));

        this.toggleUpdateExperienceStatusModalVisibility();
    }

    private saveDeviceHandler() {
        const { dispatch } = this.props;
        const { bulkUpdateActionType, devices, selectedItems } = this.state;

        const updatedItems = selectedItems.map(
            experience => bulkUpdateDevices(experience, devices, bulkUpdateActionType)
        );

        dispatch(bulkUpdateAction(updatedItems));

        this.toggleUpdateDeviceModalVisibility();
        this.setState({devices: []});
    }

    private updatePauseReason(pauseReason: string) {
        let pauseBtnDisabled = true;
        if (pauseReason !== null && pauseReason !== '') pauseBtnDisabled = false;
        this.setState({pauseReason, pauseBtnDisabled});
    }

    private updatePauseReasonInput() {
        if (this.state.bulkUpdateActionType === BulkUpdateActionType.Pause) {
            return (<span>
                <InputWrapper
                    id='input.bulk-update-experience'
                    value={this.state.pauseReason}
                    readonly={false}
                    validate={item => item !== ''}
                    placeholder='Pause reason'
                    onInput={(input: string) => {
                        this.updatePauseReason(input);
                    }}
                />
            </span>);
        }
    }

    public render() {
        const { isLoading, lastEvaluatedKey, permissions, flattenedExperiences, updateFilteringCriteria, searchCriteriaString,
            succeeded, failed, multiCloneErrors, cloneExperienceTitle, newExperiencesCount } = this.props;
        const { selectedItems } = this.state;

        const nonAvsDevices: string[] = [];
        const avsDevices: string[] = [];
        this.state.devices.forEach((deviceId) => {
            Object.keys(DEVICES).includes(deviceId) ? nonAvsDevices.push(deviceId) : avsDevices.push(deviceId);
        });

        const isPagesLoading: boolean = (lastEvaluatedKey !== undefined);
        const isAdmin: boolean = (permissions.includes('ADMIN'));

        let searchParams: TablePropertyFiltering.ChangeDetail | undefined;
        let flashType = 'info';
        let newOwner = '';

        const deDuplicateErrorMessages = Array.from(new Set(multiCloneErrors?.map(e => e.message)));

        const percentFinished: number = (succeeded + failed) / (selectedItems.length || 1) * 100 || 0;
        if (percentFinished === 100) {
            flashType = (percentFinished === 100 && failed === 0) ? 'success' : 'error';
        }
        try {
            if (searchCriteriaString) {
                const tokens = JSON.parse(searchCriteriaString);
                searchParams = fromCompressedSearchParamsToPropertyFilters(tokens);
            }

        } catch (error) {
            // if it fails, we keep tokens as undefined, then will resolve to [], which is still safe
        }

        return (
            <div>
            <div>
                {(percentFinished !== 0) && isAdmin &&
                <Flashbar
                    items = {[
                    {
                        'type': flashType,
                        'dismissible': true,
                        'content': (
                            <div>
                            <ProgressBar
                                label={flashTypeToProgress(true, flashType, succeeded, failed, this.state.selectedItems.length)}
                                description={flashTypeToProgress(false, flashType, succeeded, failed, this.state.selectedItems.length)}
                                value={percentFinished}
                                variant='flash'
                                />
                            </div>
                        ),
                        'dismiss': this.clearProgressBar.bind(this)
                    }]}
                    ></Flashbar>}
                </div>
            <div>
                {newExperiencesCount && cloneExperienceTitle && <Flashbar items={[{
                    header: 'Success',
                    content: `Experience ${cloneExperienceTitle} has been cloned to ${newExperiencesCount} ${newExperiencesCount > 1 ? 'experiences' : 'experience'}`,
                    type: 'success',
                    dismissible: true
                }]}/>}
                {multiCloneErrors && <Flashbar items={[{
                    header: 'Cloning failed',
                    content: `${multiCloneErrors.length} out of ${(newExperiencesCount || 0) + multiCloneErrors.length} clone(s) could not be created. ${deDuplicateErrorMessages}`,
                    type: 'error',
                    dismissible: true
                }]}/>}
            </div>
            {
            <Table
                id='table.experiences'
                header={<div className='awsui-util-t-r'>
                    {isAdmin &&
                    <ButtonDropdown
                        id='dropdown.bulk-edit-experiences'
                        loading={isLoading || isPagesLoading}
                        disabled={isLoading || isPagesLoading || selectedItems.length === 0 }
                        items={bulkUpdateOptions}
                        onItemClick={e =>{
                            switch (e.detail.id) {
                                case BulkUpdateActionType.AddDevice:
                                case BulkUpdateActionType.RemoveDevice:
                                case BulkUpdateActionType.ConsolidateDevice:
                                    this.toggleUpdateDeviceModalVisibility();
                                    break;
                                case BulkUpdateActionType.Pause:
                                case BulkUpdateActionType.Unpause:
                                case BulkUpdateActionType.SendToTestable:
                                    this.toggleUpdateExperienceStatusModalVisibility();
                                    break;
                                case BulkUpdateActionType.TransferOwner:
                                    this.toggleTransferOwnerModalVisibility();
                                    break;
                                default:
                            }
                            this.setBulkUpdateAction(e.detail.id as BulkUpdateActionType);
                        }}
                    > Edit </ButtonDropdown>}
                    <ButtonDropdown
                        id='dropdown.export-experiences'
                        loading={isLoading || isPagesLoading}
                        disabled={isLoading || isPagesLoading}
                        items={exportTypeOptions}
                        onItemClick={e =>{
                            this.exportToJsonOrCSV(e.detail.id);
                        }}
                    > Export </ButtonDropdown>
                </div>}
                columnDefinitions={COLUMN_DEFINITIONS}
                items={flattenedExperiences}
                loadingText='Loading Experiences'
                loading={isLoading}
                empty={<EmptyDisplay />}
                noMatch={<NoMatchDisplay />}
                resizableColumns={true}
                stickyHeader={true}
                wrapLines={false}
            >
                <TablePropertyFiltering
                    filteringOptions={FILTERING_OPTIONS}
                    groupValuesText='Values'
                    groupPropertiesText='Properties'
                    operationAndText='and'
                    operationNotAndText='and not'
                    operationOrText='or'
                    operationNotOrText='or not'
                    clearFiltersText='Clear filter'
                    placeholder='Filter experiences by properties'
                    allowFreeTextFiltering={true}
                    filteringCountTextFunction={countTextFunction}
                    operation={searchParams ? searchParams.operation as TablePropertyFiltering.Operation : 'and'}
                    tokens={(searchParams ? searchParams.tokens : [])}
                    disabled={isLoading}
                    onPropertyFilteringChange={(event) => {
                        const compressedSearchParams = fromPropertyFiltersToCompressedSearchParams(event.detail);
                        const updatedSearchCriteriaString = JSON.stringify(compressedSearchParams);
                        updateFilteringCriteria(updatedSearchCriteriaString);
                    }}
                />
                <TableSorting
                    sortableColumns={SORTABLE_COLUMNS}
                />
                {isAdmin &&
                <TableSelection
                    selectedItems={selectedItems}
                    onSelectionChange={e => {this.setState({ selectedItems: e.detail.selectedItems });
                        if (flashType !== 'info') {this.clearProgressBar();}
                    }}
                    trackBy='id'
                    keepSelection={true}
                ></TableSelection>}
                <TablePagination pageSize={40} openEnd={isPagesLoading} />
                <TablePreferences
                    title='Preferences'
                    confirmLabel='Confirm'
                    cancelLabel='Cancel'
                >
                    <TablePageSizeSelector
                        title='Page size'
                        options={[
                            { value: 40, label: '40 items' },
                            { value: 60, label: '60 items' },
                            { value: 80, label: '80 items' },
                            { value: 100, label: '100 items' }
                        ]}
                    />
                    <TableWrapLines
                        label='Wrap lines'
                        description='Enable to wrap table cell content, disable to truncate text.'
                    />
                    <TableContentSelector
                        title='Select visible columns'
                        options={[
                            {
                                label: 'Properties',
                                options: SELECTABLE_COLUMNS
                            }
                        ]}
                    />
                </TablePreferences>
            </Table>}
                <Modal
                    id='modal.bulk-update-device'
                    visible={this.state.bulkUpdateDeviceModalVisible}
                    header={this.state.bulkUpdateActionType}
                    expandToFit={true}
                    onDismiss={this.toggleUpdateDeviceModalVisibility.bind(this)}
                    footer={
                        <span className='awsui-util-f-r'>
                            <Button
                                id='button.bulk-update-device'
                                variant='primary'
                                disabled={false}
                                onClick={this.saveDeviceHandler.bind(this)}>
                                {this.state.bulkUpdateActionType}
                            </Button>
                        </span>}
                    >
                    <p>
                        Add devices to {this.state.selectedItems.length} experiences you selected
                    </p>
                    <DeviceComponent
                        shouldDisableInput={false}
                        nonAvsDevices={nonAvsDevices}
                        avsDevices={avsDevices}
                        deviceSelections={generateDeviceGroups()}
                        avsDeviceInput={this.state.avsDeviceInput}
                        devices={this.state.devices}
                        updateDevices={(devicesCopy: string[]) => {
                            this.setDeviceSelection(devicesCopy);
                        }}
                        setAVSDeviceInput={(eventCopy: CustomEvent<Input.ChangeDetail>) => {
                            this.setAVSDeviceInput(eventCopy);
                        }}
                        onAVSDeviceInputEnter={(devicesCopy: string[]) => {
                            this.onAVSDeviceInputEnter(devicesCopy);
                        }}
                    ></DeviceComponent>
                    </Modal>
                <Modal
                    id='modal.bulk-update-experience'
                    visible={this.state.bulkUpdateExperienceStatusModalVisible}
                    header={this.state.bulkUpdateActionType}
                    expandToFit={true}
                    onDismiss={this.toggleUpdateExperienceStatusModalVisibility.bind(this)}
                    footer={
                        <span className='awsui-util-f-r'>
                            <Button
                                id='button.bulk-update-experience'
                                variant='primary'
                                disabled={this.state.bulkUpdateActionType === BulkUpdateActionType.Pause ? this.state.pauseBtnDisabled : false}
                                onClick={this.updateExperienceStatusHandler.bind(this)}>
                                {this.state.bulkUpdateActionType}
                            </Button>
                        </span>}
                >
                    <p>
                        {actionTypeToDescription(this.state.bulkUpdateActionType)}
                    </p>
                    {this.updatePauseReasonInput()}
                </Modal>
                <Modal
                    id='modal.bulk-transfer-owner'
                    visible={this.state.bulkTransferOwnerModalVisible}
                    header={this.state.bulkUpdateActionType}
                    expandToFit={true}
                    onDismiss={this.toggleTransferOwnerModalVisibility.bind(this)}
                    footer={
                        <span className='awsui-util-f-r'>
                            <Button
                                id='button.bulk-transfer-owner'
                                variant='primary'
                                disabled={false}
                                onClick={this.updateMetadataHandler.bind(this)}>
                                {this.state.bulkUpdateActionType}
                            </Button>
                        </span>}
                    >
                    <p>
                        Transfer {this.state.selectedItems.length} experiences you selected under new owner
                    </p>
                    <InputWrapper
                        id='input.bulk-transfer-owner'
                        readonly={false}
                        validate={item => item !== ''}
                        value={newOwner}
                        placeholder='user alias'
                        onChange={(input: string) => {
                            newOwner = input.trim();
                            this.setNewOwner(newOwner);
                        }}/>
                    </Modal>
            </div>
        );
    }
}

const exportTypeOptions = [
    {id: ExportType.JSON_FILE, text: '.JSON'},
    {id: ExportType.CSV_FILE, text: '.CSV'}
];

const bulkUpdateOptions = [
    {id: BulkUpdateActionType.AddDevice, text: 'Add Device'},
    {id: BulkUpdateActionType.RemoveDevice, text: 'Remove Device'},
    {id: BulkUpdateActionType.ConsolidateDevice, text: 'Consolidate to Same Devices'},
    {id: BulkUpdateActionType.Pause, text: 'Pause'},
    {id: BulkUpdateActionType.Unpause, text: 'Unpause'},
    {id: BulkUpdateActionType.SendToTestable, text: 'Send Back to Testable'},
    {id: BulkUpdateActionType.TransferOwner, text: 'Transfer Ownership'}
];

export const actionTypeToDescription = (actionType: BulkUpdateActionType | undefined) => {
    switch (actionType) {
        case BulkUpdateActionType.Pause:
            return 'Paused experiences will not be served to customers until you unpause them. Unpausing will resume the experience at the previous dial-up stage. Pausing and unpausing only applies to CIF experiences.';
        case BulkUpdateActionType.Unpause:
            return 'Unpausing will resume the experience at the previous dial-up stage. Pausing and unpausing only applies to CIF experiences.';
        case BulkUpdateActionType.SendToTestable:
            return 'Sending experiences back to testable state will require them to go through the approval process again.';
        case BulkUpdateActionType.AddDevice:
            return 'Appending selected device types onto existing device types.';
        case BulkUpdateActionType.RemoveDevice:
            return 'Removing selected device types if they existed in selected experiences.';
        case BulkUpdateActionType.ConsolidateDevice:
            return 'Consolidating device types to selected devices.';
        case BulkUpdateActionType.TransferOwner:
            return 'Transferring selected experiences to the new owner.';
        default:
            return 'Invalid action selected, please try again.';
    }
};

export const flashTypeToProgress = (isLabel: boolean, flashType: string, successCount: number, failedCount: number, totalCount: number) => {
    switch (flashType) {
        case 'success':
            return isLabel ? 'Success' : `Updated ${successCount} experiences`;
        case 'error':
            return isLabel ? 'Failed' : `${failedCount} experiences were failed to update`;
        default:
            return isLabel ? `Updating ${totalCount} experiences` : 'Please wait while experiences are being edited. Exiting this page will interrupt the editing for remaining experiences.';
    }
};

const mapStateToProps = ({ experienceListViewState, authenticationState, multiCloneState }: AppState) => {
    return {
        isLoading: experienceListViewState.isLoading || false,
        flattenedExperiences: experienceListViewState.flattenedExperiences || [],
        lastEvaluatedKey: experienceListViewState.lastEvaluatedKey || undefined,
        permissions: authenticationState.permissions || [],
        error: experienceListViewState.error || undefined,
        succeeded: experienceListViewState.succeeded || 0,
        failed: experienceListViewState.failed || 0,
        multiCloneErrors: multiCloneState.errors || undefined,
        cloneExperienceTitle: multiCloneState.cloneExperienceTitle || undefined,
        isCreatingMultiCloneExperiences: multiCloneState.isCreating || undefined,
        newExperiencesCount: multiCloneState.newExperiencesCount || undefined
    };
};

export default connect(mapStateToProps)(ExperienceTable);
