import * as React from 'react';
import { ColumnLayout, FormField, Input, Spinner, Alert } from '@amzn/awsui-components-react';
import { debounce } from 'lodash';
import { FormSectionView } from '../../components/common/FormSectionView';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { pushBullseyeSectionAction } from '../../actions/experienceEditViewActions';
import { getBullseyeAction, resetBullseyeMetadata } from '../../actions/experienceDetailViewActions';
import { Region } from '../../util/context';
import BullseyeSegmentLoadingViewStoreConnected from '../BullseyeSegmentLoading';
import { MARKETPLACE_TO_REGION } from '../../constants/marketplaces';
import { ExternalLink } from '../../components/common/LinkComponents';


interface IBullseyeSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

export class BullseyeSectionView extends React.Component<IBullseyeSectionViewProps> {

    constructor(props: IBullseyeSectionViewProps) {
        super(props);
        this.updateBullseye = debounce(this.updateBullseye, 500);
    }

    updateBullseye(bullseyeId?: string) {
        const { updateCandidate, dispatch } = this.props;
        dispatch(pushBullseyeSectionAction(bullseyeId === undefined ? undefined : Number(bullseyeId)));

        if (bullseyeId && !Number.isNaN(Number(bullseyeId)) && updateCandidate) {
            const region: Region = MARKETPLACE_TO_REGION[updateCandidate.getRegion().marketplace || 'US'];
            dispatch(getBullseyeAction(region, Number(bullseyeId)));
        } else {
            dispatch(resetBullseyeMetadata());
        }
    }

    render() {
        const { experience, isEditing, updateCandidate, isUpdating } = this.props;

        if (!experience) {
            return <FormSectionView title='Bullseye'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const isRcp = experience.type === 'RotatingContentPanel';
        const bullseyeId: number | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getBullseye()
            : (isRcp) ? experience.constraints?.CustomerSegmentConstraint?.bullseyeSegmentId : experience.enabledFilter?.bullseye;

        const shouldDisableInput = !isEditing || isUpdating;

        const bullseyeQueryEditorDescription = <p>Visit <ExternalLink href={'https://tiny.amazon.com/jpsex8c9/wamazindeBullUser'}>
        here</ExternalLink> to learn how to create a Bullseye segment via a Bullseye Query.</p>;

        return <FormSectionView title='Bullseye' description='
            Enter segment id for your bullseye segment, where only customers
            that are present in specified bullseye will be targeted for your use case.'
        >
            <ColumnLayout columns={2} borders='vertical'>
                <div data-awsui-column-layout-root='true'>
                    <div>
                        <div className='awsui-util-mb-m'>
                            <FormField label='Bullseye' description='Enter the bullseye segment ID'>
                                <Alert>
                                    {bullseyeQueryEditorDescription}
                                </Alert>
                                <Input
                                    id='experience.bullseye'
                                    controlId='experience.bullseye'
                                    value={(bullseyeId || '').toString()}
                                    readonly={shouldDisableInput}
                                    onInput={(e) => {
                                        this.updateBullseye(e.detail.value.trim());
                                    }} />
                            </FormField>
                        </div>
                    </div>
                    <BullseyeSegmentLoadingViewStoreConnected />
                </div>
            </ColumnLayout>
        </FormSectionView>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState, bullseyeMetadataState, odysseyExperienceDetailViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || odysseyExperienceDetailViewState.experience,
        updateCandidate: experienceEditViewState.updateCandidate,
        isBullseyeLoading: bullseyeMetadataState.isLoading,
        bullseye: bullseyeMetadataState.bullseye || undefined,
        error: bullseyeMetadataState.error || undefined
    };
};

export default connect(mapStateToProps)(BullseyeSectionView);
