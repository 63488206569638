export enum AlexaLabMetricName {
    DIALOGS = 'DIALOGS',
    ACTIVE_DAYS = 'ACTIVE_DAYS',
    NEXT_DAYS_RETENTION = 'NEXT_DAYS_RETENTION',
    DIALOGS_DAY_ONE_EXCLUDED = 'DIALOGS_DAY_ONE_EXCLUDED',
    PUSH_NOTIFICATION_CTR = 'PUSH_NOTIFICATION_CTR',
    AVERAGE_PUSH_NOTIFICATION_OPT_OUT_PER_CUSTOMER = 'AVERAGE_PUSH_NOTIFICATION_OPT_OUT_PER_CUSTOMER'
}

export enum AlexaLabTreatment {
    C = 'C',
    T1 = 'T1',
    T2 = 'T2',
}

export enum AlexaLabDimensionType {
    INTENT = 'INTENT',
    DOMAIN = 'DOMAIN',
    NO_DIMENSION = 'NO_DIMENSION',
}

export interface AlexaLabMetricDimension {
    type: AlexaLabDimensionType;
    value?: string;
}

export interface IAlexaLabMetricData {
    controlSampleSize: number;
    treatmentSampleSize: number;
    controlMean: number;
    treatmentMean: number;
    controlVariance: number;
    treatmentVariance: number;
    standardError?: number;
    percentConfidenceIntervalLower: number;
    percentConfidenceIntervalUpper: number;
    rawConfidenceIntervalLower: number;
    rawConfidenceIntervalUpper: number;
    rawLift: number;
    percentLift: number;
    pvalue: number;
}

export interface IAlexaLabMetricMetadata {
    reportStartTime: string;
    reportEndTime: string;
    excludedDates: string[];
    apiExecutionTime: number;
}

export interface IAlexaLabMetric {
    baseline: AlexaLabTreatment;
    treatment: AlexaLabTreatment;
    name: AlexaLabMetricName;
    dimension: AlexaLabMetricDimension;
    metadata: IAlexaLabMetricMetadata;
    data: IAlexaLabMetricData;
}

export interface IRawAlexaLabMetrics {
    weblabId: string;
    realm: string;
    metrics: IAlexaLabMetric[];
}

export interface IAlexaLabMetrics extends IRawAlexaLabMetrics {
    mappedMetrics: {
        [treatment in AlexaLabTreatment]? : {
            [baseline in AlexaLabTreatment]?: {
                [dimension: string]: {
                    [metric in AlexaLabMetricName]?: IAlexaLabMetric
                }
            }
        }
    };
}

export const getKeyFromDimension = (dimension: AlexaLabMetricDimension): string =>
    `${dimension.type}:${dimension.value || ''}`;

export const createMappedAlexaLabMetrics = (alexaLabMetrics: IRawAlexaLabMetrics): IAlexaLabMetrics => {
    return {
        ...alexaLabMetrics,
        mappedMetrics: alexaLabMetrics.metrics.reduce((mappedMetrics: IAlexaLabMetrics['mappedMetrics'], alexaLabMetric: IAlexaLabMetric) => {
            const { treatment, baseline, name, dimension } = alexaLabMetric;
            const dimensionKey = getKeyFromDimension(dimension);

            if (!mappedMetrics[treatment]) {
                mappedMetrics[treatment] = { [baseline]: { [dimensionKey]: {} } };
            }

            if (!mappedMetrics[treatment]![baseline]) {
                mappedMetrics[treatment]![baseline] = { [dimensionKey]: {} };
            }

            if (!mappedMetrics[treatment]![baseline]![dimensionKey]) {
                mappedMetrics[treatment]![baseline]![dimensionKey] = {};
            }

            mappedMetrics[treatment]![baseline]![dimensionKey][name] = alexaLabMetric;
            return mappedMetrics;
        }, {})
    };
};
