import * as React from 'react';
import { ColumnLayout, FormField, Button, Select} from '@amzn/awsui-components-react';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { ITemplateVariable, TemplateVariableType, isVariableTypeBYOR, BYOR_DEFAULT_TEMPLATE_VARIABLE } from '../../models/TemplateVariable';
import { RequiredField } from './DescriptionAnnotations';
import { InputWrapper } from './InputWrapper';
import { TextAreaWrapper } from './TextAreaWrapper';
import { CommonValidator } from '../../util/CommonValidator';
import { EMPTY_INVOCATION_PARAMETERS, BYOR_INVOCATION_PARAMETERS } from '../../models/InvocationParameters';
import { IRemoteContextualSegments } from '../../models/RemoteContextualSegments';

interface ContentVariableComponentProps {
    index: number;
    templateVariables: ITemplateVariable[];
    shouldDisableInput: boolean;
    isNonDefaultBYOR: boolean;
    supportedTemplateVariableTypes: (Select.IOption | Select.Option | Select.OptionsGroup)[];
    remoteTags: IRemoteContextualSegments;
    updateTemplateVariables: (input: ITemplateVariable[]) => void;
    updateSnsTopicArn: (input: string) => void;
}

export const byorVariableCount = (variables: ITemplateVariable[]) => {
    return variables.filter(variable => (variable.variableType === 'REMOTE_SERVICE' &&
        variable.invocationParameters?.CTI === 'Alexa/PAGE/BYOR')).length;
};

export const containDefaultBYORVariable = (variables: ITemplateVariable[]) => {
    return variables.filter(variable => (variable.variableType === 'REMOTE_SERVICE' &&
        variable.variableName === 'ContentId' &&
        variable.invocationParameters?.CTI === 'Alexa/PAGE/BYOR')).length > 0;
};

export const findDefaultBYORVariableIndex = (variables: ITemplateVariable[]) => {
    return variables.findIndex(variable => (variable.variableType === 'REMOTE_SERVICE' &&
        variable.variableName === 'ContentId' &&
        variable.invocationParameters?.CTI === 'Alexa/PAGE/BYOR'));
};

export const ContentVariableComponent = (props: ContentVariableComponentProps) => {
    const { templateVariables, shouldDisableInput, index, supportedTemplateVariableTypes,
        updateTemplateVariables, remoteTags, isNonDefaultBYOR, updateSnsTopicArn} = props;
    const templateVariablesCopy: ITemplateVariable[] = ExperienceUpdateCandidate.deepClone(templateVariables);

    return <div>
        <div className='awsui-row'>
            <div className='col-10'>
        <ColumnLayout columns={3}>
            <div data-awsui-column-layout-root='true'>
            <FormField label='Variable type'>
                    <Select
                        id={`experience.template-variable-type-${index}`}
                        controlId={`experience.template-variable-type-${index}`}
                        disabled={shouldDisableInput || (isVariableTypeBYOR(templateVariables[index]) && !isNonDefaultBYOR)}
                        selectedId={isVariableTypeBYOR(templateVariables[index]) ? 'BYOR': templateVariables[index].variableType}
                        placeholder = {isNonDefaultBYOR ? 'BYOR' : ''}
                        options={supportedTemplateVariableTypes}
                        onChange={(e) => {
                            if (e.detail.selectedOption.label !== 'BYOR') {
                                templateVariablesCopy[index].variableType
                                    = e.detail.selectedId as TemplateVariableType;
                                templateVariablesCopy[index].invocationParameters
                                    = e.detail.selectedOption.label === 'REMOTE_SERVICE' ? EMPTY_INVOCATION_PARAMETERS : undefined;
                                // In the case where we changed one of the BYOR content variables to other content variable types:
                                //  remove the BYOR variable and push the new content variable to the bottom of the list
                                if (isVariableTypeBYOR(templateVariables[index-1]) && isVariableTypeBYOR(templateVariables[index+1])) {
                                    templateVariablesCopy.push(templateVariablesCopy[index]);
                                    templateVariablesCopy.splice(index, 1);
                                }
                                // In the case where we changed the only BYOR content variables to other content variable types:
                                //  remove the default BYOR content variable
                                else if (byorVariableCount(templateVariablesCopy) === 1 && containDefaultBYORVariable(templateVariablesCopy)) {
                                    templateVariablesCopy.splice(findDefaultBYORVariableIndex(templateVariablesCopy), 1);
                                }
                            }
                            else {
                                templateVariablesCopy[index].variableType
                                = 'REMOTE_SERVICE' as TemplateVariableType;
                                templateVariablesCopy[index]
                                    .invocationParameters = BYOR_INVOCATION_PARAMETERS;
                                // Add default BYOR content variable
                                if (!containDefaultBYORVariable(templateVariablesCopy)) {
                                    templateVariablesCopy.splice(index, 0, BYOR_DEFAULT_TEMPLATE_VARIABLE);
                                }
                                // In the case where we changed one of the other content variable types to BYOR:
                                //  find the index of the default BYOR content variable and add the new content variable after it
                                else if (byorVariableCount(templateVariablesCopy) > 1 && containDefaultBYORVariable(templateVariablesCopy)) {
                                    templateVariablesCopy.splice(findDefaultBYORVariableIndex(templateVariablesCopy)+1, 0, templateVariablesCopy[index]);
                                    // differentiate the case where the removed content variable is above or below BYOR content variable section.
                                    templateVariablesCopy.splice(index < findDefaultBYORVariableIndex(templateVariablesCopy) ? index: index+1, 1);
                                }
                            }
                            updateTemplateVariables(templateVariablesCopy);
                        }} />
                </FormField>
                <FormField label={<RequiredField fieldName='Variable name' />}>
                    <InputWrapper
                        id={`experience.template-variable-name-${index}`}
                        readonly={shouldDisableInput || (isVariableTypeBYOR(templateVariables[index]) && !isNonDefaultBYOR)}
                        value={templateVariables[index].variableName}
                        onInput={(input: string) => {
                            templateVariablesCopy[index].variableName = input;
                            updateTemplateVariables(templateVariablesCopy);
                        }}
                        validate={(value) => CommonValidator.isTemplateVariableNameValid(value,
                            templateVariables.map(x => x.variableName),
                            templateVariables[index].variableType,
                            remoteTags)} />
                </FormField>
                <FormField label={<RequiredField fieldName='Description' />}>
                    <TextAreaWrapper
                        id={`experience.template-variable-description-${index}`}
                        readonly={shouldDisableInput || (isVariableTypeBYOR(templateVariables[index]) && !isNonDefaultBYOR)}
                        value={templateVariables[index].description}
                        onInput={(input: string) => {
                            templateVariablesCopy[index].description = input;
                            updateTemplateVariables(templateVariablesCopy);
                        }}
                        validate={CommonValidator.isTemplateVariableDescriptionValid} />
                </FormField>
            </div>
        </ColumnLayout>
        </div>
        {(!isVariableTypeBYOR(templateVariables[index]) || templateVariables[index].variableName !== 'ContentId') &&
        <div className='col-2' style={{ paddingTop: '35px' }}>
            <Button
                id={`button.remove-template-variable-${index}`}
                disabled={shouldDisableInput}
                onClick={() => {
                    if (isVariableTypeBYOR(templateVariables[index]) &&
                        byorVariableCount(templateVariables) === 2) {
                            templateVariablesCopy.splice(index-1, 2);
                            updateSnsTopicArn('');
                        }
                    else {
                        templateVariablesCopy.splice(index, 1);
                    }
                    updateTemplateVariables(templateVariablesCopy);
                }}>Remove</Button>
        </div>}
        </div>
    </div>;
};
