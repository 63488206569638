export type DeviceType = 'Headless' | 'Multimodal' | 'FireTV' | 'On-The-Go' | 'Echo Auto';

export class Device {
    code: string;
    name: string;
    type: DeviceType;

    public constructor(code: string, name: string, type: DeviceType) {
        this.code = code;
        this.name = name;
        this.type = type;
    }
}
