import _ from 'lodash';
import { IExperienceUpdateCandidate } from '../models/ExperienceUpdateCandidate';
import { IContent } from '../models/v2/content/IContent';
import { IContentType } from '../models/v2/content/IContentType';
import { IMetadataInfo } from '../models/v2/metadata/IMetadata';
import { IMetadataType } from '../models/v2/metadata/IMetadataType';
import { IContext } from '../models/v2/context/IContext';
import { IContextType } from '../models/v2/context/IContextType';
import { IConstraint } from '../models/v2/constraint/IConstraint';
import { IConstraintType } from '../models/v2/constraint/IConstraintType';
import {
    IRotatingContentPanelTestingConstraint,
    IRotatingContentPanelContent,
    IRotatingContentPanelDevicesConstraint,
    RCPMetadata,
    IRotatingContentPanelSchedulingConstraint,
    IRCPDataSourceTypes
} from '../models/rotatingContentPanel/RotatingContentPanelStructure';
import { IFlattenedExperience } from '../models/FlattenedExperience';
import { IRCPOverrideAccount } from '../models/v2/constraint/IRCPOverrideAccount';
import { MARKETPLACES_FLIPPED } from '../constants/marketplaces';

export enum WakeWordKey {
    default = 'default',
    disney = 'disney'
}

export const wakeWords: { [key in WakeWordKey]: string } = {
    'default': 'Try "{WakeWord},',
    'disney': 'Try "Hey Disney!'
};

export const constructRotatingContentPanelData = (updateCandidate: IExperienceUpdateCandidate) => {
    const RcpContentData = {
        'metadataInfo': constructMetadataInfo(updateCandidate),
        'contents': constructRotatingContentPanelContents(updateCandidate),
        'constraints': constructRotatingContentPanelConstraints(updateCandidate),
        'contexts': constructRotatingContentPanelContext(updateCandidate)
    };
    return RcpContentData;
};

const constructMetadataInfo = (updateCandidate: IExperienceUpdateCandidate): { [key: string]: IMetadataInfo } => {
    return {
        'ExperienceMetadata': {
            ...updateCandidate.metadata,
            type: IMetadataType.ExperienceMetadata
        }
    };
};

const constructRotatingContentPanelContents = (updateCandidate: IExperienceUpdateCandidate): IContent[] => {
    const touchAction = _.get(updateCandidate.rotatingContentPanelContent, 'touchActions[0]', null) ?? {};
    const selectedWakeWord = _.get(touchAction, 'wakeWord', null) ?? WakeWordKey.default;
    const wakeWordValue = _.get(wakeWords, selectedWakeWord);
    const hintText = _.get(updateCandidate.rotatingContentPanelContent, 'touchActions[0].hintText');

    const rcpContent: IRotatingContentPanelContent = {
        ...updateCandidate.rotatingContentPanelContent,
        touchActions: [_.omit({
            ...touchAction
        }, ['wakeWord'])],
        textWrappingDataSource: {
            ...updateCandidate.rotatingContentPanelContent?.textWrappingDataSource,
            hintText: `${wakeWordValue} ${hintText}"`,
            type: IRCPDataSourceTypes.TextWrappingDataSource
        },
        marketPlace: MARKETPLACES_FLIPPED[updateCandidate.region?.marketplace as string],
        locale: updateCandidate.region.locale,
        aplTemplate: {
            aplTemplateId: 'DEFAULT',
            aplTemplateName: 'textWrapping'
        }
    };

    return [{...rcpContent, type: IContentType.RotatingContentPanelContent}];
};

const constructRotatingContentPanelConstraints = (updateCandidate: IExperienceUpdateCandidate): { [key: string]: IConstraint } => {
    return {
        'GeneralConstraint': {
            type: IConstraintType.GeneralConstraint,
            weblabName: updateCandidate.impressionTracking.weblab,
            marketplace: MARKETPLACES_FLIPPED[updateCandidate.region?.marketplace as string],
            launched: false
        },
        'CustomerSegmentConstraint': {
            type: IConstraintType.CustomerSegmentConstraint,
            bullseyeSegmentId: updateCandidate.bullseye
        },
        'DeviceTypesConstraint': {
            type: IConstraintType.DeviceTypesConstraint,
            deviceTypes: updateCandidate.rotatingContentPanelDevicesConstraint?.deviceTypes
        },
        'SchedulingConstraint': {
            type: IConstraintType.SchedulingConstraint,
            timeWindows: updateCandidate.rotatingContentPanelSchedulingConstraint?.timeWindows,
            startDate: updateCandidate.rotatingContentPanelSchedulingConstraint?.startDate,
            endDate: updateCandidate.rotatingContentPanelSchedulingConstraint?.endDate
        },
        'RCPTestingConstraint': {
            type: IConstraintType.RCPTestingConstraint,
            rcpOverrideAccounts: updateCandidate.rotatingContentPanelTestingConstraint?.rcpOverrideAccounts
        }
    };
};

const constructRotatingContentPanelContext = (updateCandidate: IExperienceUpdateCandidate): { [key: string]: IContext } => {

    return {
        'BusinessInfo': {
            type: IContextType.BusinessInfo,
            vertical: updateCandidate.rotatingContentPanelBusinessContext?.vertical,
            domain: updateCandidate.rotatingContentPanelBusinessContext?.domain
        },
        'RotatingContentPanelContext': {
            type: IContextType.RotatingContentPanelContext,
            businessInfo: updateCandidate.rotatingContentPanelContext?.businessInfo ? updateCandidate.rotatingContentPanelContext?.businessInfo : [{
                vertical: updateCandidate.rotatingContentPanelBusinessContext?.vertical,
                domain: updateCandidate.rotatingContentPanelBusinessContext?.domain
            }],
            subscriptionUpsellType: updateCandidate.rotatingContentPanelContext?.subscriptionUpsellType
        }
    };
};

export const extractRotatingContentPanelTestingConstraint = (experience: IFlattenedExperience): IRotatingContentPanelTestingConstraint => {
    return {
        rcpOverrideAccounts: _.get(experience, 'constraints.RCPTestingConstraint.rcpOverrideAccounts', []) ?? []
    };
};

export const extractRotatingContentPanelSchedulingConstraint = (experience: IFlattenedExperience): IRotatingContentPanelSchedulingConstraint => {
    return {
        startDate: _.get(experience, 'constraints.SchedulingConstraint.startDate', ''),
        endDate: _.get(experience, 'constraints.SchedulingConstraint.endDate', ''),
        timeWindows: _.get(experience, 'constraints.SchedulingConstraint.timeWindows', [])
    };
};

export const extractRotatingContentPanelDevicesConstraint = (experience: IFlattenedExperience): IRotatingContentPanelDevicesConstraint => {
    return _.get(experience, 'constraints.DeviceTypesConstraint', {});
};

export const extractRotatingContentPanelContent = (experience: IFlattenedExperience | undefined): IRotatingContentPanelContent => {
    return _.get(experience, 'contents[0]', {}) ?? {};
};

export const extractRotatingContentPanelOverrideAccounts = (experience: IFlattenedExperience): IRCPOverrideAccount[] => {
    return _.get(experience, 'constraints.RCPTestingConstraint.rcpOverrideAccounts', []) ?? [];
};

export const extractRotatingContentPanelBusinessContext = (experience: IFlattenedExperience | undefined): IContext => {
    return {
        ..._.get(experience, 'contexts.BusinessInfo', {}),
        type: IContextType.BusinessInfo
    };
};

export const extractRotatingContentPanelContext = (experience: IFlattenedExperience | undefined): IContext => {
    return {
        ..._.get(experience, 'contexts.RotatingContentPanelContext', {}),
        type: IContextType.RotatingContentPanelContext
    };
};

export const extractRotatingContentPanelMetaData = (experience: IFlattenedExperience): RCPMetadata => {
    return _.get(extractRotatingContentPanelContent(experience), 'rcpMetaData', {});
};

export const extractBullseyeSegmentId = (experience: IFlattenedExperience): number => {
    return _.get(experience, 'constraints.CustomerSegmentConstraint.bullseyeSegmentId', undefined);
};
