import { fromTimestampToFormattedDateTime } from '../util/stringAndMappingHelper';
import moment from 'moment';
import { ExperienceType } from './Experience';


export enum CifMetricNames {
    TotalImpressions = 'Total Impressions',
    DistinctCustomers = 'Distinct Customers',
    ConversionRate = 'Conversion Rate',
    InterruptionRate = 'Interruption Rate',
    NegativeFeedback = 'Negative Feedback'
}

export enum CifMetricDDBNames {
    TotalImpressions = 'total_impression',
    DistinctCustomers = 'distinct_customer',
    ConversionRate = 'conversion_rate',
    InterruptionRate = 'interruption_rate',
    NegativeFeedback = 'negative_feedback',
    NumberOfCampaigns = 'number_of_campaigns',
    AvgImpressionPerCampaign = 'avg_impression_per_campaign'
}

export enum CifMetricsTimeRange {
    days = 'days',
    weeks = 'weeks',
}

export enum ExperienceMetricFrequency {
    Day = 'DAY',
    Week = 'WEEK',
}

export interface ICifMetricsTypes {
    total_impression?: number;
    distinct_customer?: number;
    conversion_rate?: number;
    interruption_rate?: number;
    negative_feedback?: number;
    number_of_campaigns?: number;
    avg_impression_per_campaign?: number;
}

export interface ICifData {
    period: string;
    reportedAt: number;
    weekStart?: string;
    weekEnd?: string;
    data: ICifMetricsTypes;
}

export interface IIntentData {
    [key: string] : ICifData[];
}

export interface IRawCifMetricsData {
    experienceId: string;
    experienceType: ExperienceType;
    periodType: ExperienceMetricFrequency;
    metrics: ICifData[];
    intentMetrics: IIntentData;
}

export interface ICifMetric {
    metricName: CifMetricNames;
    values: (number | undefined)[];
}

export interface IIntentMetric {
    intentName: string;
    values: (number | undefined)[];
    totalImpressions: (number | undefined)[];
}

// helper function to create headers for days with missing data - starting with the most recent available day, it constructs a header if the previous day is unavailable
export const constructDailyMetricsForTableView = (cifMetrics: IRawCifMetricsData[]) : IRawCifMetricsData[] => {

    const CIF_METRICS_PERIODS_INDEX_RANGE = 6;

    if (cifMetrics[0]) {
        const { metrics } = cifMetrics[0];
        if (metrics) {
            const constructedDailyMetrics = JSON.parse(JSON.stringify(cifMetrics));
            for (let dailyMetricsDataIterator = 0; dailyMetricsDataIterator < CIF_METRICS_PERIODS_INDEX_RANGE; dailyMetricsDataIterator++){
                const currentDailyMetricsPeriod = moment(constructedDailyMetrics[0].metrics[dailyMetricsDataIterator].period);
                const indexOfNextPeriod = cifMetrics[0].metrics.findIndex(metric => ((metric.period === (moment(currentDailyMetricsPeriod).subtract(1, 'days').format('YYYY-MM-DD')))));
                if (indexOfNextPeriod === -1) {
                    const constructedDailyMetricsForMissingPeriod = {
                        period: moment(currentDailyMetricsPeriod).subtract(1, 'days').format('YYYY-MM-DD'),
                        reportedAt: moment().format('MM/DD/YYYY hh:mm A z'),
                        data: {}
                    };
                    constructedDailyMetrics[0].metrics[dailyMetricsDataIterator+1] = constructedDailyMetricsForMissingPeriod;
                } else {
                    constructedDailyMetrics[0].metrics[dailyMetricsDataIterator+1] = cifMetrics[0].metrics[indexOfNextPeriod];
                }
            }
            return constructedDailyMetrics;
        }
    }
    return [];
};

// helper function to create headers for weeks with missing data - starting with the most recent available week, it constructs a header if the previous week is unavailable
// Finds whether the current week's previous week number has associated data
export const constructWeeklyMetricsForTableView = (cifMetrics: IRawCifMetricsData[]) : IRawCifMetricsData[] => {

    const CIF_METRICS_PERIODS_INDEX_RANGE = 6;

    if (cifMetrics[0]) {
        const { metrics } = cifMetrics[0];
        if (metrics) {
            const constructedWeeklyMetrics = JSON.parse(JSON.stringify(cifMetrics));
            for (let weeklyMetricsDataIterator = 0;  weeklyMetricsDataIterator < CIF_METRICS_PERIODS_INDEX_RANGE; weeklyMetricsDataIterator++) {
                const { weekStart, weekEnd } = constructedWeeklyMetrics[0].metrics[weeklyMetricsDataIterator];
                const indexOfNextPeriod = cifMetrics[0].metrics.findIndex(metric => ((moment(metric.weekEnd).isoWeek() === (moment(weekStart).isoWeek()))));
                if (indexOfNextPeriod === -1) {
                    const weekPrefixString = (moment(weekStart).add(1, 'days').isoWeek() >= 2 && moment(weekStart).add(1, 'days').isoWeek() <= 10) ? '-W0' : '-W';
                    const constructedWeeklyMetricsForMissingPeriod = {
                        period: moment(weekStart).subtract(1, 'days').year() + weekPrefixString + moment(weekStart).isoWeek(),
                        reportedAt: moment().format('MM/DD/YYYY hh:mm A z'),
                        weekStart: moment(weekStart).subtract(7, 'days').format('YYYY-MM-DD'),
                        weekEnd: moment(weekEnd).subtract(7, 'days').format('YYYY-MM-DD'),
                        data: {}
                    };
                    constructedWeeklyMetrics[0].metrics[weeklyMetricsDataIterator+1] = constructedWeeklyMetricsForMissingPeriod;
                } else {
                    constructedWeeklyMetrics[0].metrics[weeklyMetricsDataIterator+1] = cifMetrics[0].metrics[indexOfNextPeriod];
                }
            }
            return constructedWeeklyMetrics;
        }
    }
    return [];
};

export const filterDataForComputingPerformanceStatus = (cifMetrics?: IRawCifMetricsData[]) : IRawCifMetricsData[] => {

    if (cifMetrics && cifMetrics[0]) {
        const { metrics } = cifMetrics[0];
        if (metrics) {
            const filteredDataToComputePerformanceStatus = JSON.parse(JSON.stringify(cifMetrics));
            const cutoffDate = moment(cifMetrics[0].metrics[0].period).subtract(14, 'days').format('YYYY-MM-DD');
            filteredDataToComputePerformanceStatus[0].metrics = cifMetrics[0].metrics.filter(metric => moment(metric.period).diff(cutoffDate) > 0);
            if (filteredDataToComputePerformanceStatus[0].metrics.length > 7) {
                filteredDataToComputePerformanceStatus[0].metrics.splice(7);
            }
            return filteredDataToComputePerformanceStatus;
        }
    }
    return [];
};

export const createMappedCifMetrics = (isExperienceReferralBased: boolean, cifMetrics: IRawCifMetricsData[]): ICifMetric[] => {
    const metricNames = [CifMetricNames.TotalImpressions, CifMetricNames.DistinctCustomers, CifMetricNames.ConversionRate, CifMetricNames.InterruptionRate, CifMetricNames.NegativeFeedback];
    const totalImpressions:(number|undefined)[] = [];
    const distinctCustomers:(number|undefined)[] = [];
    const conversionRate:(number|undefined)[] = [];
    const interruptionRate:(number|undefined)[] = [];
    const feedbackRate:(number|undefined)[] = [];

    if (cifMetrics[0]) {
        const { metrics } = (cifMetrics[0]);
        if (metrics) {
            metrics.forEach((entry: ICifData) =>
                totalImpressions.push(entry.data.total_impression) && distinctCustomers.push(entry.data.distinct_customer) && conversionRate.push(entry.data.conversion_rate)
                && interruptionRate.push(entry.data.interruption_rate) && feedbackRate.push(entry.data.negative_feedback)
            );
        }
    }
    const metricValues = [totalImpressions, distinctCustomers, conversionRate, interruptionRate, feedbackRate];

    if(!isExperienceReferralBased){
        metricNames.splice(2, 1);
        metricValues.splice(2, 1);
    }

    const mappedCifMetrics = metricNames.map((metricName, index) => ({
        metricName,
        values: metricValues[index]
        }));

    return mappedCifMetrics;
};

export const areMetricsDelayed = (cifMetrics: IRawCifMetricsData[]): boolean => {

    if (cifMetrics.length !== 0) {
        if (cifMetrics[0].metrics.length !== 0) {
            if (cifMetrics[0].periodType === ExperienceMetricFrequency.Week) {
                if (moment().diff(moment(cifMetrics[0].metrics[0].weekEnd), 'days') > 11) {
                    return true;
                }
            } else {
                if (moment().diff(moment(cifMetrics[0].metrics[0].period), 'days') > 4) {
                    return true;
                }
            }
        }
    }
    return false;
};

export const findMostRecentlyUpdatedTime = (cifMetrics: IRawCifMetricsData[]) => {
    const lastUpdatedTimes:number[] = [];
    let mostRecentlyUpdatedTime:number = 0;
    const emptyTime = '';

    if (cifMetrics[0]) {
        const { metrics } = (cifMetrics[0]);
        if (metrics) {
            metrics.forEach((entry: ICifData) =>
                lastUpdatedTimes.push(entry.reportedAt)
            );
        }
        mostRecentlyUpdatedTime = lastUpdatedTimes.reduce(function (accumulator,currentValue){
            return Math.max(accumulator,currentValue);
        });
    }

    if (mostRecentlyUpdatedTime !== 0){
        return fromTimestampToFormattedDateTime(mostRecentlyUpdatedTime);
    } else {
        return emptyTime;
    }
};
