import * as React from 'react';
import { connect } from 'react-redux';
import { MonitoredExperimentsSectionView } from './sections/MonitoredExperimentsView';
import { getMonitoredExperimentsAction, updateMonitoredExperimentsAction } from '../actions/monitoredExperimentsActions';
import { IMonitoredExperiments } from '../models/monitoredExperiments/MonitoredExperiments';
import { AppState } from '../reducers';
import { Button, Flash, FormField, Icon, Modal, Textarea } from '@amzn/awsui-components-react';
import { trimAndDedupeStringList } from '../util/stringAndMappingHelper';


interface MonitoredExperimentsUpdateWidgetProps {
    dispatch: any;
    isLoading: boolean;
    isMonitoredExperimentsUpdated: boolean;
    errorUpdating?: Error;
    errorLoading?: Error;
    monitoredExperiments?: IMonitoredExperiments;
}

interface MonitoredExperimentsUpdateWidgetState {
    updateMonitoredExperimentsIds?: string[];
    isShowingConfirmationModal: boolean;
}

class MonitoredExperimentsUpdateWidget extends React.Component<MonitoredExperimentsUpdateWidgetProps, MonitoredExperimentsUpdateWidgetState> {
    constructor(props: MonitoredExperimentsUpdateWidgetProps) {
        super(props);

        this.state = {
            updateMonitoredExperimentsIds: undefined,
            isShowingConfirmationModal: false
        };
    }

    dismissConfirmationModal() {
        this.setState({
            isShowingConfirmationModal: false
        });
    }

    showConfirmationModal() {
        this.setState({
            isShowingConfirmationModal: true
        });
    }

    updateMonitoredExperimentsExperimentIds(value: string[]) {
        this.setState({
            updateMonitoredExperimentsIds: value
        });
    }

    copyMonitoredExperimentsToEditPane() {
        const { monitoredExperiments } = this.props;
        if (monitoredExperiments) {
            this.setState({
                updateMonitoredExperimentsIds: monitoredExperiments.experimentIds
            });
        }
    }

    newMonitoredExperimentsToEditPane() {
        this.setState({
            updateMonitoredExperimentsIds: []
        });
    }

    saveMonitoredExperiments() {
        const { dispatch } = this.props;
        const { updateMonitoredExperimentsIds } = this.state;

        if (updateMonitoredExperimentsIds !== undefined) {
            dispatch(updateMonitoredExperimentsAction({
                experimentIds: trimAndDedupeStringList(updateMonitoredExperimentsIds),
                type: 'MonitoringAllowlist'
            }));
            this.resetMonitoredExperiments();
        }
        this.dismissConfirmationModal();
    }

    resetMonitoredExperiments() {
        this.setState({
            updateMonitoredExperimentsIds: undefined
        });
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(getMonitoredExperimentsAction());
    }

    componentDidUpdate() {
        const { isMonitoredExperimentsUpdated, dispatch, isLoading } = this.props;
        if (isMonitoredExperimentsUpdated && !isLoading) {
            dispatch(getMonitoredExperimentsAction());
        }
    }

    render() {
        const { isLoading, errorUpdating, monitoredExperiments } = this.props;

        const { updateMonitoredExperimentsIds } = this.state;
        return <div className='awsui-util-no-gutter'>
            {errorUpdating && <Flash type='error' dismissible={true} >{errorUpdating.message}</Flash>}
            <div className='awsui-util-m-xl'>
                <MonitoredExperimentsSectionView />
                <div className='awsui-util-m-l'>
                    <Button
                        id='button.add-monitored-experiments'
                        onClick={this.newMonitoredExperimentsToEditPane.bind(this)}
                        disabled={ monitoredExperiments !== undefined || isLoading}
                        icon='add-plus'>Create monitored experiments list</Button>
                    <Button
                        id='button.edit-monitored-experiments'
                        onClick={this.copyMonitoredExperimentsToEditPane.bind(this)}
                        icon='copy' disabled={!monitoredExperiments || isLoading}
                    >Edit existing monitored experiments</Button>
                </div>
                {(updateMonitoredExperimentsIds !== undefined) && <div className='awsui-grid'>
                    <div className='awsui-row'>
                        <div className='col-6 awsui-util-spacing-v-s'>
                            <FormField label='Experiment Ids:' description='Following Experiments will be monitored, starting with new line'>
                                <Textarea id='monitoredExperiments.experimentIds' placeholder='Monitored experiments, starting with new line'
                                value={updateMonitoredExperimentsIds.toString().replace(/,/g, '\n')}onInput={(event: CustomEvent<Textarea.ChangeDetail>) => {
                                    this.updateMonitoredExperimentsExperimentIds(event.detail.value.split('\n'));
                                }} />
                            </FormField>
                        </div>
                    </div>
                    <div className='awsui-row'>
                        <div className='col-12'>
                            <Button id='button.pre-save-monitored-experiments' onClick={this.showConfirmationModal.bind(this)} variant='primary' icon='upload' disabled={isLoading} >Save</Button>
                            <Button id='button.pre-cancel-monitored-experiments' onClick={this.resetMonitoredExperiments.bind(this)} variant='normal' icon='close' disabled={isLoading} >Cancel</Button>

                        </div>
                    </div>
                </div>}
            </div>
            <Modal
                id='modal.update-monitored-experiments-confirmation'
                visible={this.state.isShowingConfirmationModal}
                header='Publish monitored experiments'
                footer={<span className='awsui-util-f-r'>
                    <Button id='button.save-monitored-experiments' onClick={this.saveMonitoredExperiments.bind(this)} variant='primary' icon='upload' disabled={isLoading} >Save</Button>
                    <Button id='button.cancel-monitored-experiments' onClick={this.dismissConfirmationModal.bind(this)} variant='normal' icon='close' disabled={isLoading} >Cancel</Button>
                </span>}
                onDismiss={this.dismissConfirmationModal.bind(this)}>

                <p>
                    <Icon size='large' name='status-warning' variant='warning' /> By clicking save, this experiments list will take effect <em>immediately</em>. Are you sure?
                </p>
            </Modal>
        </div>;
    }
}

const mapStateToProps = ({ monitoredExperimentsState }: AppState) => {
    return {
        isLoading: monitoredExperimentsState.isLoading,
        isMonitoredExperimentsUpdated: monitoredExperimentsState.updatedMonitoredExperiments,
        errorUpdating: monitoredExperimentsState.errorUpdating || undefined,
        errorLoading: monitoredExperimentsState.errorLoading || undefined,
        monitoredExperiments: monitoredExperimentsState.monitoredExperiments || undefined,
    };
};

export default connect(mapStateToProps)(MonitoredExperimentsUpdateWidget);
