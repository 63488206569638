import * as React from 'react';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { Alert, FormField } from '@amzn/awsui-components-react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { LinkedOdysseyWiki } from '../../components/common/LinksRenderHelper';
import { pushImpressionTrackingSectionAction } from '../../actions/experienceEditViewActions';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { ExperienceType } from '../../models/Experience';
import { InputWrapper } from '../../components/common/InputWrapper';
import { CommonValidator } from '../../util/CommonValidator';


interface IExperienceTrackingSectionViewProps {
    dispatch: any;

    selectedExperienceType?: ExperienceType;
    updateCandidate?: ExperienceUpdateCandidate;

}

export class ExperienceTrackingSectionView extends React.Component<IExperienceTrackingSectionViewProps> {

    updateImpressionTracking(weblab?: string, hintId?: string, contentId?: string) {
        const { dispatch } = this.props;

        dispatch(pushImpressionTrackingSectionAction(weblab, undefined, hintId, contentId));
    }

    render () {
        const { selectedExperienceType, updateCandidate } = this.props;

        const weblabDialupRequirementCif = 'CIF experience dialups ' +
        'should not exceed 10% during experimentation phase.';

        const weblabDialupRequirementNotification = 'Notification experience dialups ' +
            'should not exceed 50% during experimentation phase.';

        const weblabDialupRequirement = selectedExperienceType === 'CIF'
            ? weblabDialupRequirementCif
            : weblabDialupRequirementNotification;

        const weblabFormFieldDescription = selectedExperienceType === 'CIF'
            ? 'This weblab will be checked before injection is served'
            : 'This weblab will be checked before notification is sent';

        return <div>

            <FormSectionView title='Experience Tracking'>
                <Alert>
                    All Odyssey experiences require an experimental phase using a weblab gated
                    by the Odyssey system. Please ensure your weblab is randomized by Customer
                    (not Session), and has defined one Treatment group. {weblabDialupRequirement}&nbsp;
                    See <LinkedOdysseyWiki /> to request an exception.
                </Alert>
                <div className='awsui-util-mb-m'>
                    <FormField
                        label={<RequiredField fieldName='Weblab' />}
                        description={`The weblab name associated with this experience. ${weblabFormFieldDescription}`}>
                        <InputWrapper
                            id='experience.weblab'
                            readonly={false}
                            placeholder='ex. ALEXA_FA_CIF_PODCAST_COMMUTE_GENERIC_263599'
                            value={updateCandidate?.getImpressionTracking().weblab}
                            onChange={(input: string) => this.updateImpressionTracking(input.trim())}
                            validate={CommonValidator.isWeblabValid}
                        />
                    </FormField>
                </div>
            </FormSectionView>
        </div>;
    }
}

const mapStateToProps = ({ experienceEditViewState }: AppState) => {
    return {
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(ExperienceTrackingSectionView);
