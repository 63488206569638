import { IFilterType } from '../models/FilterMetrics';

export const ProgressBarConst = 48;
export const ProgressBarLength = 760;
export interface OpportunityFilterDisplay {
    label: React.ReactNode;
    description: React.ReactNode;
}

export const OpportunityFilterMap: {
    [key in IFilterType]: OpportunityFilterDisplay
} = {
    'StatusFilter': {
        label: 'Status Filter',
        description: 'filters out all non-LIVE experiences'
    },
    'ActivationFilter': {
        label: 'Activation Filter',
        description: 'Filters out experiences that do not match user marketplace or local date'
    },
    'VisualFilter': {
        label: 'Visual Filter',
        description: 'Filters out experiences that have apl template version compatible with opportunity device'
    },
    'SpeakerUserRoleFilter': {
        label: 'Speaker User Role Filter',
        description: 'Filters out experience not approved to target opportunity user role'
    },
    'ContentFilter': {
        label: 'Content Filter',
        description: 'Filters out experiences that do not have content for opportunity locale'
    },
    'NluTargetingFilter': {
        label: 'NLU Targeting Filter',
        description: 'Filters out experiences that do not match opportunity trigger intent and slots'
    },
    'PhaticIntentUtteranceFilter': {
        label: 'Phatic Intent Utterance Filter',
        description: 'Filters out experiences with target utterance that do not match opportunity phatic intent utterance slot value'
    },
    'EviUtteranceFilter': {
        label: 'EVI Utterance Filter',
        description: 'Filters out experiences with target utterance that do not match opportunity question-answer intent utterance slot value'
    },
    'ContextualSegmentsFilter': {
        label: 'Contextual Segments Filter',
        description: 'Filters out experiences with inclusion tags not in / exclusion tags in opportunity segments'
    },
    'DayTimeRangeGuardrailsFilter': {
        label: 'Day Time Range Guardrails Filter',
        description: 'Filters out experiences with day time windows outside user local time'
    },
    'TimerFilter': {
        label: 'Timer Filter',
        description: 'Filters out all experiences if opportunity contains timer slot with value less than 60 seconds'
    },
    'TemplateVariableFilter': {
        label: 'Local Template Variable Filter',
        description: 'Filters out experiences with invalid local template variable values'
    },
    'ExposureControlFilter': {
        label: 'Exposure Control Filter',
        description: 'FIlters out experiences if customerId + experienceId has exposure percentage greater than configured launch treatment percentage'
    },
    'BullseyeFilter': {
        label: 'Bullseye Filter',
        description: 'Filters out experience if bullseye does not contain customerId'
    },
    'FeatureHintsFilter': {
        label: 'Feature Hints Filter',
        description: 'Filters out experience if customer has tried any feature linked in experience'
    },
    'MonthOneJourneyFilter': {
        label: 'Month One Journey Filter',
        description: 'Filters out experiences that are blocklisted as suppressed'
    },
    'EverestJourneyFilter': {
        label: 'Everest Journey Filter',
        description: 'Filters out everest journey experiences if customer not in everest journey, else filters out all non-everest experiences'
    },
    'RowCountryFilter': {
        label: 'Row Country Filter',
        description: 'Filters out experiences that do not support customer row country'
    },
    'PromotedExperienceFilter': {
        label: 'Promoted Experience Filter',
        description: 'Filters out non-promoted experiences during promotion period (e.g. Prime Day)'
    },
    'DomainInformationFilter': {
        label: 'DVP Filter',
        description: 'Filters out experiences that have missing DVP fields'
    },
    'VisualCifFilter': {
        label: 'Visual CIF Filter',
        description: 'Filters out experiences that are not Visual CIF when the opportunity is targeting Visual CIF only'
    },
    'CapabilityFilter': {
        label: 'Capability Filter',
        description: 'Filters out experiences configured with a capabilityId that the user has engaged with in the past 30 day'
    },
    'ALL_FILTERS': {
        label: '',
        description: ''
    }
};
