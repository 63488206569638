import * as React from 'react';
import { IFlattenedExperience } from '../../../models/FlattenedExperience';
import OverrideAccountSectionView from '../../../containers/sections/OverrideAccountSectionView';
import OverrideFiltersSectionView from '../../../containers/sections/OverrideFiltersSectionView';
import { TestingExperienceSectionView, TestingMobileHomeCardSectionView, TestingRotatingContentPanelSectionView } from '../sections/TestingExperienceSectionView';

export const TestingTabView = (props: { experience: IFlattenedExperience }) => {
    const { experience } = props;

    return <div>
        {(experience.shortenedType === 'CIF' || experience.shortenedType === 'VCIF') &&
            <div>
                <OverrideAccountSectionView />
                <OverrideFiltersSectionView />
            </div>
        }

        {(experience.shortenedType === 'App' || experience.shortenedType === 'Device') && <TestingExperienceSectionView />}

        {(experience.shortenedType === 'MHC') && <TestingMobileHomeCardSectionView />}

        {(experience.shortenedType === 'RCP') && <TestingRotatingContentPanelSectionView />}
    </div>;
};
