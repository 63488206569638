import * as React from 'react';
import NotificationSectionView from '../../../containers/sections/NotificationSectionView';
import ContentVariablesSectionView from '../../../containers/sections/ContentVariablesSectionView';

export const NotificationTabView = () => {
    return <div>
        <NotificationSectionView />
        <ContentVariablesSectionView />
    </div>;
};
