import { AnyAction } from 'redux';
import {
    LOADED_PROMOTED_EXPERIMENTS,
    LOADING_PROMOTED_EXPERIMENTS,
    ERROR_LOADING_PROMOTED_EXPERIMENTS,
    UPDATED_PROMOTED_EXPERIMENTS,
    UPDATING_PROMOTED_EXPERIMENTS,
    ERROR_UPDATING_PROMOTED_EXPERIMENTS
} from '../constants/promotedExperimentsActionTypes';
import { IPromotedExperiments } from '../models/promotedExperiments/PromotedExperiments';

export interface IPromotedExperimentsState {
    isLoading: boolean;
    updatedPromotedExperiments: boolean;
    promotedExperiments?: IPromotedExperiments;
    errorLoading?: Error;
    errorUpdating?: Error;
}

export const promotedExperimentsReducer = (
    state: IPromotedExperimentsState = { isLoading: false, updatedPromotedExperiments: false },
    action: AnyAction
): IPromotedExperimentsState => {
    switch (action.type) {
        case LOADING_PROMOTED_EXPERIMENTS:
            return {
                ...state,
                isLoading: true,
                updatedPromotedExperiments: false
            };
        case UPDATING_PROMOTED_EXPERIMENTS:
            return {
                ...state,
                updatedPromotedExperiments: false,
                isLoading: true
            };
        case LOADED_PROMOTED_EXPERIMENTS:
            return {
                ...state,
                isLoading: false,
                promotedExperiments: action.promotedExperiments,
                errorLoading: undefined,
                errorUpdating: undefined
            };
        case UPDATED_PROMOTED_EXPERIMENTS:
            return {
                ...state,
                isLoading: false,
                updatedPromotedExperiments: true,
                errorLoading: undefined,
                errorUpdating: undefined
            };
        case ERROR_LOADING_PROMOTED_EXPERIMENTS:
            return {
                ...state,
                isLoading: false,
                updatedPromotedExperiments: false,
                errorLoading: action.error,
                errorUpdating: undefined
            };
        case ERROR_UPDATING_PROMOTED_EXPERIMENTS:
            return {
                ...state,
                isLoading: false,
                updatedPromotedExperiments: false,
                errorUpdating: action.error,
                errorLoading: undefined
            };
        default:
            return state;
    }
};
