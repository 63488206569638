export enum IConstraintType {
    SchedulingConstraint = 'SchedulingConstraint',
    CustomerSegmentConstraint = 'CustomerSegmentConstraint',
    TestingConstraint = 'TestingConstraint',
    FrequencyConstraint = 'FrequencyConstraint',
    GeneralConstraint = 'GeneralConstraint',
    DeviceTypesConstraint = 'DeviceTypesConstraint',
    UsageHistoryConstraint = 'UsageHistoryConstraint',
    MobileHomeCardConstraint = 'MobileHomeCardConstraint',
    RCPTestingConstraint = 'RCPTestingConstraint'
}
