import * as React from 'react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { FormSectionView } from '../../components/common/FormSectionView';
import { ColumnLayout, FormField, Input, Spinner } from '@amzn/awsui-components-react';
import { connect } from 'react-redux';
import { AppState } from '../../reducers/index';
import { ExperienceUpdateCandidate, IBasicInfoViewAttributes } from '../../models/ExperienceUpdateCandidate';
import { pushBasicInfoSectionAction } from '../../actions/experienceEditViewActions';
import { getRole } from '../../util/stringAndMappingHelper';
import { RequiredField } from '../../components/common/DescriptionAnnotations';

interface IVCIFBasicInfoSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    isCloning: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

export class VCIFBasicInfoSectionView extends React.Component<IVCIFBasicInfoSectionViewProps> {
    updateBasicInfo(title?: string, groupPermissions?: string) {
        const { dispatch } = this.props;
        dispatch(pushBasicInfoSectionAction(title, groupPermissions));
    }

    render () {
        const { experience, isEditing, isUpdating, updateCandidate, isCloning } = this.props;

        if (!experience) {
            return <FormSectionView title='Basic Information'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const renderData: IBasicInfoViewAttributes = (isEditing && updateCandidate)
            ? updateCandidate.getBasicInfo()
            : ExperienceUpdateCandidate.extractBasicInfo(experience);

        const shouldDisableInput = !isEditing || isUpdating;

        return <FormSectionView
            title='Basic Information'
            description='Every experience needs a title at a minimum to be saved'
            >
            <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root='true'>
                    <FormField
                        label={<RequiredField fieldName='Experience title' />}
                        description='This title was set when you first created the experience and cannot be changed. It is used for tracking and searching.'
                    >
                        {/* title is only changed on cloning or legacy experience that doesn't have title */}
                        <Input
                            readonly={!isEditing || (!isCloning && !!renderData.title)}
                            placeholder='ex. ALEXA_FA_CIF_PODCAST_COMMUTE_GENERIC_263599'
                            value={renderData.title}
                            onChange={(e) => this.updateBasicInfo(e.detail.value.trim())}
                            controlId='experience.title'
                            id='experience.title' />
                    </FormField>
                    <FormField
                    label='Operator Group (recommended)'
                    description='LDAP group that allows members of your team to edit the experiment'
                >
                    <Input
                        readonly={(shouldDisableInput
                            || ['VIEWER', 'OPERATOR'].includes(getRole(experience.permissionRole))) && !isCloning}
                        placeholder='ex. alexa-fa-dev'
                        value={renderData.groupImpressions}
                        onChange={(e) => this.updateBasicInfo(undefined, e.detail.value)}
                        controlId='experience.groupImpressions'
                        id='experience.groupImpressions'
                    />
                </FormField>
                </div>
            </ColumnLayout>
        </FormSectionView>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        isCloning: experienceEditViewState.isCloning,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(VCIFBasicInfoSectionView);
