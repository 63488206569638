import * as React from 'react';
import { useState } from 'react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { FormSectionView } from '../../components/common/FormSectionView';
import { Form, FormSection, FormField, Spinner, Button, ColumnLayout, Icon, Alert, Modal, Tiles, Checkbox, Toggle, Tabs } from '@amzn/awsui-components-react';
import { connect } from 'react-redux';
import { AppState } from '../../reducers/index';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { pushVisualInterstitialCards, pushUseTouchActionTemplate, pushUseNonCTATemplate, isCardConfigurationIncomplete,
         pushOverrideBackgroundImage, pushAcknowledgeBackgroundImage, pushCustomDomainIllustrationSelection,
         pushIncludeOdysseyFields
       } from '../../actions/experienceEditViewActions';
import { IManagementCard, isCardConfigured } from '../../models/cifExperience/visualExperience/IManagementCard';
import { DomainIllustrationDefaultItems } from '../../constants/domainIllustrationDefaultItems';
import { InputWrapper } from '../../components/common/InputWrapper';
import { AsyncInputWrapper } from '../../components/common/AsyncInputWrapper';
import { preambleTitleCharacterLimit, hintHeadlineCharacterLimit, buttonTextCharacterLimit} from '../../constants/componentConstants';
import { CommonValidator } from '../../util/CommonValidator';
import { ExternalLink } from '../../components/common/LinkComponents';
import { TextAreaWrapper } from '../../components/common/TextAreaWrapper';
import { ITouchTarget } from '../../models/cifExperience/visualExperience/ITouchTarget';
import { isTouchActionTemplate, isNonCTATemplateExperience } from '../../util/cifExperienceHelper';
import { AsyncFlashbarWrapper } from '../../components/common/AsyncFlashbarWrapper';
import { CIFValidator } from '../../util/CIFValidator';
import { ImageValidator } from '../../util/ImageValidator';
import { OptionalField, RequiredField } from '../../components/common/DescriptionAnnotations';
import { COMMON_ERROR_MESSAGES, COMMON_SUCCESS_MESSAGES } from '../../constants/validatorConstants';
import { getRole, isStringEmpty } from '../../util/stringAndMappingHelper';
import { PermissionRole } from '../../models/PermissionRole';
import { IIntentTrigger } from '../../models/NluOpportunityTrigger';

interface IVisualInterstitialSectionViewProps {
    dispatch: any;
    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
    onCardConfigurationUpdate?: (state?: boolean) => void;
    isAdmin?: boolean;
}

interface IVisualInterstitialSectionViewState {
    chooseDomainIllustrationVisible: boolean;
    domainIllustrationModalIndex: number;
}

export class VisualInterstitialSectionView extends React.Component<IVisualInterstitialSectionViewProps, IVisualInterstitialSectionViewState> {
    constructor(props: IVisualInterstitialSectionViewProps) {
        super(props);

        this.state = {
            chooseDomainIllustrationVisible: false,
            domainIllustrationModalIndex: 0
        };
    }

    private toggleDomainIllustrationModalVisibility(modalIndex: number) {
        const { chooseDomainIllustrationVisible } = this.state;
        this.setState(prevState => {
            return {
                ...prevState,
                domainIllustrationModalIndex: modalIndex,
                chooseDomainIllustrationVisible: !chooseDomainIllustrationVisible
            };
        });
    }

    updateCustomDomainIllustrationSource(domainIllustrationCustomSource: boolean[]) {
        const { dispatch } = this.props;
        dispatch(pushCustomDomainIllustrationSelection(domainIllustrationCustomSource));
        this.forceUpdate();
    }

    updateBackgroundImageOverrideState(override: boolean[]) {
        const { dispatch } = this.props;
        dispatch(pushOverrideBackgroundImage(override));
        this.forceUpdate();
    }

    updateAcknowledgeBackgroundImageState(acknowledge: boolean[]) {
        const { dispatch } = this.props;
        dispatch(pushAcknowledgeBackgroundImage(acknowledge));
        this.forceUpdate();
    }

    updateVisualInterstitialCard(visualInterstitialCards: IManagementCard[]) {
        const { dispatch } = this.props;
        dispatch(pushVisualInterstitialCards(visualInterstitialCards));
        this.forceUpdate();
    }

    updateIncludingOdysseyFields(checked: boolean) {
        const { dispatch } = this.props;
        dispatch(pushIncludeOdysseyFields(checked));
        this.forceUpdate();
    }

    useTouchActionTemplateCheckedUpdate(checked: boolean) {
        const { dispatch } = this.props;
        dispatch(pushUseTouchActionTemplate(checked));
        if (!checked) {
            dispatch(pushIncludeOdysseyFields(false));
        }
        this.forceUpdate();
    }

    isBlockedIntent() {
        const triggers = this.props.updateCandidate?.getOpportunityTriggerInclusions();
        if (triggers) {
            for (const eachTrigger of triggers) {
                if((eachTrigger as IIntentTrigger).bluIntent?.toLowerCase() === 'playmusicintent') {
                    return true;
                }
            }
        }
        return false;
    }

    useNonCTATemplateCheckedUpdate(checked: boolean) {
        const { dispatch } = this.props;
        dispatch(pushUseNonCTATemplate(checked));
        this.forceUpdate();
    }

    async areCardsValid(visualInterstitialCards: IManagementCard[], useTouchActionTemplate: boolean,
                        overrideBackgroundImage: boolean[], acknowledgeBackgroundImage: boolean[],
                        domainIllustrationCustomSource: boolean[], allowDvpSourceForImages: boolean) {
        const { dispatch, onCardConfigurationUpdate } = this.props;
        let shouldDisableSave = false;
        for (const each of visualInterstitialCards.map((card, index) => ({card, index}))) {
            if (!(await isCardConfigured(each.card, useTouchActionTemplate, overrideBackgroundImage[each.index],
                acknowledgeBackgroundImage[each.index], domainIllustrationCustomSource[each.index], allowDvpSourceForImages))) {
                    shouldDisableSave = true;
                    break;
            }
        }
        if (onCardConfigurationUpdate) {
            onCardConfigurationUpdate(shouldDisableSave);
        }
        dispatch(isCardConfigurationIncomplete(shouldDisableSave));
    }

    render () {
        const { dispatch, onCardConfigurationUpdate, experience, isEditing, isUpdating, updateCandidate, isAdmin } = this.props;
        const { chooseDomainIllustrationVisible, domainIllustrationModalIndex } = this.state;

        if (!experience) {
            return <FormSectionView title='Suggested Feature'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const localeNotSet = ((isEditing && updateCandidate)
            ? updateCandidate.getRegion().locale
            : experience.locale) === undefined;

        const visualInterstitialCards = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
            ? updateCandidate.getVisualInterstitialCards() || []
            : ExperienceUpdateCandidate.extractVisualInterstitialCards(experience) || []);

        // This is only needed for the static template solution and can be removed
        // when ever we convert to a more general 'CARDS' solution.
        if (visualInterstitialCards.length === 0) {
            visualInterstitialCards.push(
                {} as IManagementCard, // Left
                {} as IManagementCard, // Center
                {} as IManagementCard  // Right
            );
        }

        const domainIllustrationCustomSource = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
        ? updateCandidate.getDomainIllustrationCustomSource() || []
        : ExperienceUpdateCandidate.extractCustomDomainIllustration(experience) || []);

        if (domainIllustrationCustomSource.length === 0) {
            domainIllustrationCustomSource.push(false, false, false);
        }

        const overrideDefaultBackgroundImage = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
        ? updateCandidate.getOverrideBackgroundImage() || []
        : ExperienceUpdateCandidate.extractOverrideDefaultBackgroundImages(experience) || []);

        if (overrideDefaultBackgroundImage.length === 0) {
            overrideDefaultBackgroundImage.push(false, false, false);
        }

        const acknowledgeBackgroundImage = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
        ? updateCandidate.getAcknowledgeBackgroundImage() || []
        : ExperienceUpdateCandidate.extractOverrideDefaultBackgroundImages(experience) || []);

        if (acknowledgeBackgroundImage.length === 0) {
            acknowledgeBackgroundImage.push(false, false, false);
        }

        const shouldDisableInput = (!isEditing || isUpdating) || localeNotSet;

        const isBlockedIntent = this.isBlockedIntent();

        const useTouchActionTemplate =  (isEditing && updateCandidate)
            ? (updateCandidate.getUseTouchActionTemplate()) || false
            : isTouchActionTemplate(experience);

        const updateCardsFn = this.updateVisualInterstitialCard.bind(this);

        const operatorGroup = ((isEditing && updateCandidate)
        ? updateCandidate.getBasicInfo().groupImpressions
        : experience.operatorGroup) || '';

        const allowDvpSourceForImages = operatorGroup === 'ask-organic-traffic-retention';

        const includingOdysseyFields = (isEditing && updateCandidate)
            ? updateCandidate.getIncludingOdysseyFields() || false
            : ExperienceUpdateCandidate.extractIncludingOdysseyFields(experience);

        const useNonCTATemplate = (isEditing && updateCandidate)
            ? updateCandidate.getUseNonCTATemplate() || false
            : isNonCTATemplateExperience(experience);

        const isAdminRole = isAdmin === undefined ? getRole(experience.permissionRole) === PermissionRole.ADMIN : isAdmin;

        return <FormSection
                    header={
                        <div className='awsui-util-action-stripe'>
                            <div className='awsui-util-action-stripe-title'>
                                <h2 className='awsui-util-d-ib'>
                                    Visual Content
                                </h2>
                            </div>
                        </div>
                    }
                >
            <Alert type='error' visible={localeNotSet} className='awsui-util-mb-l'>
                You must select/set a locale under <i>Customer Targeting -&gt; Region</i> to enable these fields.
            </Alert>
            <Alert id={'alert.vcifBlockedIntent'} type='error' visible={isBlockedIntent} className='awsui-util-mb-l'>
                For enabling touch actions, please remove PlayMusicIntent from trigger intents
            </Alert>
            <Modal
                id='modal.domain-illustration'
                visible={chooseDomainIllustrationVisible}
                header='Domain illustration'
                footer={<span className='awsui-util-f-r'>
                            <Button
                                id='button.cancel-domain-illustration-in-modal'
                                variant='link'
                                onClick={() => { this.toggleDomainIllustrationModalVisibility(domainIllustrationModalIndex); }} >
                                Cancel
                            </Button>
                            <Button
                                id='button.confirm-domain-illustration-in-modal'
                                variant='primary'
                                onClick={() => {
                                    this.updateVisualInterstitialCard(visualInterstitialCards);
                                    this.toggleDomainIllustrationModalVisibility(domainIllustrationModalIndex);
                                }} >
                                Confirm
                            </Button>
                        </span>}
                onDismiss={() => { this.toggleDomainIllustrationModalVisibility(domainIllustrationModalIndex); }} >
                {chooseDomainIllustrationVisible && <Tiles
                    id='tiles.domain-illustration-selection'
                    items={DomainIllustrationDefaultItems}
                    columns={2}
                    value={visualInterstitialCards[domainIllustrationModalIndex].imageLink}
                    onChange={async (e) => {
                        visualInterstitialCards[domainIllustrationModalIndex].imageLink = e.detail.value;
                        this.updateVisualInterstitialCard(visualInterstitialCards);
                        await this.areCardsValid(visualInterstitialCards, useTouchActionTemplate,
                                                 overrideDefaultBackgroundImage, acknowledgeBackgroundImage,
                                                 domainIllustrationCustomSource, allowDvpSourceForImages);
                    }}/>}
            </Modal>
            {isAdminRole && !useTouchActionTemplate && <Checkbox
                id={'checkbox.use-non-cta-template'}
                controlId={'checkbox.use-non-cta-template'}
                checked={useNonCTATemplate || false}
                disabled={shouldDisableInput}
                onChange={async (e) => {
                    this.useNonCTATemplateCheckedUpdate(e.detail.checked);
                }}
            >
                Do Not Auto-Convert to CTA VCIF (ADMIN ONLY)
            </Checkbox>
            }
            <Checkbox
                id={'checkbox.use-touch-action-template'}
                controlId={'checkbox.use-touch-action-template'}
                checked={useTouchActionTemplate || false}
                disabled={shouldDisableInput || useNonCTATemplate}
                onChange={async (e) => {
                    this.useTouchActionTemplateCheckedUpdate(e.detail.checked);
                    await this.areCardsValid(visualInterstitialCards, useTouchActionTemplate,
                        overrideDefaultBackgroundImage, acknowledgeBackgroundImage,
                        domainIllustrationCustomSource, allowDvpSourceForImages);
                }}
            >
                Use touch action template
            </Checkbox>
            {useTouchActionTemplate && <Checkbox
                    id={'checkbox.toggle-include-odyssey-fields'}
                    controlId={'checkbox.toggle-include-odyssey-fields'}
                    checked={includingOdysseyFields || false}
                    disabled={shouldDisableInput}
                    onChange={(e) => {
                        this.updateIncludingOdysseyFields(e.detail.checked);
                    }}>
                Include OdysseyFields in Payload
                </Checkbox>
            }
            <div className='awsui-util-mt-l awsui-util-mb-xs' />
            {useTouchActionTemplate
                ? <img src='/images/Touch Action Template Diagram.png' alt='Conversation Icon' />
                : <img src='/images/VCIF diagram.png' alt='Conversation Icon' />
            }
            <div className='awsui-util-mt-l awsui-util-mb-l' />
            {(visualInterstitialCards && visualInterstitialCards.length > 0)
                ? visualInterstitialCards.map((card, index) =>
                    <FormSectionView title={`Card ${index + 1}`}>
                        <Form>
                            <VisualCardView
                                dispatch={dispatch}
                                onCardConfigurationUpdate={onCardConfigurationUpdate}
                                index={index}
                                card={card}
                                shouldDisableInput={shouldDisableInput}
                                onUpdateCardInfo={async (c) => {
                                    visualInterstitialCards[index] = c;
                                    await this.areCardsValid(visualInterstitialCards, useTouchActionTemplate,
                                        overrideDefaultBackgroundImage, acknowledgeBackgroundImage,
                                        domainIllustrationCustomSource, allowDvpSourceForImages);
                                        updateCardsFn(visualInterstitialCards);
                                } }
                                toggleDomainIllustrationModal={this.toggleDomainIllustrationModalVisibility.bind(this)}
                                useTouchActionTemplate={useTouchActionTemplate}
                                domainIllustrationCustomSource={domainIllustrationCustomSource}
                                updateCustomDomainIllustrationSource={this.updateCustomDomainIllustrationSource.bind(this)}
                                overrideDefaultBackgroundImage={overrideDefaultBackgroundImage}
                                updateBackgroundImageOverrideState={this.updateBackgroundImageOverrideState.bind(this)}
                                acknowledgeBackgroundImage={acknowledgeBackgroundImage}
                                updateAcknowledgeBackgroundImageState={this.updateAcknowledgeBackgroundImageState.bind(this)}
                                allowDvpSourceForImages={allowDvpSourceForImages}
                            />
                        </Form>
                        <div style={{ paddingTop: '35px' }}>
                            <Button
                                id={`button.remove-card-${index}`}
                                disabled={shouldDisableInput || visualInterstitialCards.length === 1}
                                onClick={async () => {
                                    visualInterstitialCards.splice(index, 1);
                                    domainIllustrationCustomSource.splice(index, 1);
                                    overrideDefaultBackgroundImage.splice(index, 1);
                                    acknowledgeBackgroundImage.splice(index, 1);
                                    this.updateVisualInterstitialCard(visualInterstitialCards);
                                    this.updateCustomDomainIllustrationSource(domainIllustrationCustomSource);
                                    this.updateBackgroundImageOverrideState(overrideDefaultBackgroundImage);
                                    this.updateAcknowledgeBackgroundImageState(acknowledgeBackgroundImage);
                                    await this.areCardsValid(visualInterstitialCards, useTouchActionTemplate,
                                        overrideDefaultBackgroundImage, acknowledgeBackgroundImage,
                                        domainIllustrationCustomSource, allowDvpSourceForImages);
                            }}>Remove Card</Button>
                        </div>
                        <div className='awsui-util-mt-l awsui-util-mb-l' />
                    </FormSectionView>)
                : <p className='awsui-util-status-inactive'>
                                No card configured for this experience</p>
            }
            {(!visualInterstitialCards || (visualInterstitialCards && visualInterstitialCards.length < 3)) &&
                <div className='awsui-util-mt-l awsui-util-mb-l'>
                    <Button
                        id='button.add-card'
                        disabled={shouldDisableInput}
                        onClick={async () => {
                            domainIllustrationCustomSource.push(false);
                            overrideDefaultBackgroundImage.push(false);
                            acknowledgeBackgroundImage.push(false);
                            visualInterstitialCards.push({} as IManagementCard);
                            this.updateCustomDomainIllustrationSource(domainIllustrationCustomSource);
                            this.updateBackgroundImageOverrideState(overrideDefaultBackgroundImage);
                            this.updateAcknowledgeBackgroundImageState(acknowledgeBackgroundImage);
                            this.updateVisualInterstitialCard(visualInterstitialCards);
                            await this.areCardsValid(visualInterstitialCards, useTouchActionTemplate,
                                overrideDefaultBackgroundImage, acknowledgeBackgroundImage,
                                domainIllustrationCustomSource, allowDvpSourceForImages);
                        }} >Add Card</Button>
                </div>
            }
        </FormSection>;
    }
}

interface IVisualCardViewProps {
    dispatch: any;
    onCardConfigurationUpdate?: (state?: boolean) => void;
    index: number;
    card: IManagementCard;
    shouldDisableInput: boolean;
    onUpdateCardInfo: (card: IManagementCard) => Promise<void>;
    toggleDomainIllustrationModal: (modalIndex: number) => void;
    useTouchActionTemplate: boolean;
    domainIllustrationCustomSource: boolean[];
    updateCustomDomainIllustrationSource: (domainIllustrationCustomSource: boolean[]) => void;
    overrideDefaultBackgroundImage: boolean[];
    updateBackgroundImageOverrideState: (overrideDefaultBackgroundImage: boolean[]) => void;
    acknowledgeBackgroundImage: boolean[];
    updateAcknowledgeBackgroundImageState: (acknowledgeBackgroundImage: boolean[]) => void;
    allowDvpSourceForImages: boolean;
}

const VisualCardView = (props: IVisualCardViewProps) => {
    const { index, card, shouldDisableInput, onUpdateCardInfo, overrideDefaultBackgroundImage, acknowledgeBackgroundImage,
        toggleDomainIllustrationModal, useTouchActionTemplate, domainIllustrationCustomSource, allowDvpSourceForImages,
        updateCustomDomainIllustrationSource, updateBackgroundImageOverrideState, updateAcknowledgeBackgroundImageState } = props;

    if (card.isSyntheticUtterance === undefined) {
        card.isSyntheticUtterance = !isStringEmpty(card.syntheticUtteranceOverrideText);
    }

    const [activeTabId, setActiveTabId] = useState(card.isSyntheticUtterance ? 'Synthetic Utterance' : 'Skill Connection');

    return <div>
        {!shouldDisableInput && <AsyncFlashbarWrapper id={`flashbar.configure-card-error-${index}`}
            validate={() => CIFValidator.isCardValid(card, useTouchActionTemplate, overrideDefaultBackgroundImage[index],
            acknowledgeBackgroundImage[index], domainIllustrationCustomSource[index], allowDvpSourceForImages)}/>}
        <span style={{ color: 'red'}}>Disclaimer: How text displays on a device can vary greatly based on the specific characters used.
         Please test your experience on device types of all screen sizes to choose the appropriate configurations for your campaign.</span>
        <ColumnLayout columns={2}>
            <div data-awsui-column-layout-root='true'>
                <FormField label={<RequiredField fieldName='Preamble title' />} description='Ex. Brighten your day by saying, Alexa...'>
                    <InputWrapper
                        id={`input.preamble-title-${index}`}
                        placeholder='Preamble + by saying, Alexa...'
                        readonly={shouldDisableInput}
                        value={card.titleText}
                        characterLimit={preambleTitleCharacterLimit}
                        onInput={async (input: string) => {
                            // Capitalize first character of text
                            input = input.trimStart();
                            if(input.length > 0){
                                card.titleText = input.charAt(0).toUpperCase() + input.substring(1);
                            }
                            else {
                                card.titleText = input;
                            }
                            await onUpdateCardInfo(card);
                        }}
                        onChange={async (input: string) => {
                            card.titleText = input.trim();
                            await onUpdateCardInfo(card);
                        }}
                        validate={(value) => { return CommonValidator.isStringLengthExceeded(preambleTitleCharacterLimit, value); }} />
                </FormField>
                <FormField label={<RequiredField fieldName='Hint headline' />} description='Ex. Tell me some good news'>
                    <InputWrapper
                        id={`input.hint-headline-${index}`}
                        placeholder='Preamble + by saying, Alexa...'
                        readonly={shouldDisableInput}
                        value={card.hintText}
                        characterLimit={hintHeadlineCharacterLimit}
                        onInput={async (input: string) => {
                            // Capitalize first character of text
                            input = input.trimStart();
                            if(input.length > 0){
                                card.hintText = input.charAt(0).toUpperCase() + input.substring(1);
                            }
                            else {
                                card.hintText = input;
                            }
                            await onUpdateCardInfo(card);
                        }}
                        onChange={async (input: string) => {
                            card.hintText = input.trim();
                            await onUpdateCardInfo(card);
                        }}
                        validate={(value) => { return CommonValidator.isStringLengthExceeded(hintHeadlineCharacterLimit, value); }} />
                </FormField>
            </div>
        </ColumnLayout>
        {useTouchActionTemplate &&
            <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root='true'>
                    <FormField label={<RequiredField fieldName='CTA Button Text' />} description='Text for the Call to Action Button'>
                        <InputWrapper
                            id={`input.try-now-button-${index}`}
                            placeholder='Try Now, Play Now, Buy Now, etc.'
                            readonly={shouldDisableInput}
                            value={card.buttonText}
                            characterLimit={buttonTextCharacterLimit}
                            onInput={async (input: string) => {
                                // Capitalize first character of text
                                input = input.trimStart();
                                if(input.length > 0){
                                    card.buttonText = input.charAt(0).toUpperCase() + input.substring(1);
                                }
                                else {
                                    card.buttonText = input;
                                }
                                await onUpdateCardInfo(card);
                            }}
                            onChange={async (input: string) => {
                                card.buttonText = input.trim();
                                await onUpdateCardInfo(card);
                            }}
                            validate={(value) => { return CommonValidator.isStringLengthExceeded(buttonTextCharacterLimit, value); }} />
                    </FormField>
                </div>
            </ColumnLayout>
        }
        <DomainIllustrationDyanmicForm
                card={card}
                onUpdateCardInfo={onUpdateCardInfo}
                index={index}
                shouldDisableInput={shouldDisableInput}
                toggleDomainIllustrationModal={toggleDomainIllustrationModal}
                domainIllustrationCustomSource={domainIllustrationCustomSource}
                updateCustomDomainIllustrationSource={updateCustomDomainIllustrationSource}
                allowDvpSourceForImages={allowDvpSourceForImages}
        />
        {useTouchActionTemplate && <>
            <div className='awsui-util-mt-l awsui-util-mb-l' />
            <Tabs
                activeTabId={activeTabId}
                tabs={getIsSyntheticUtteranceTabs(props)}
                variant='container'
                onChange={async (e) => {
                    const option = e.detail.activeTabId;
                    setActiveTabId(option);
                    card.isSyntheticUtterance = option === 'Synthetic Utterance';
                    await onUpdateCardInfo(card);
                } }/>
        </>
        }
        <div className='awsui-util-mt-l awsui-util-mb-l' />
        <BackgroundImageSelection shouldDisableInput={shouldDisableInput} card={card} index={index}
                                  override={overrideDefaultBackgroundImage}
                                  acknowledge={acknowledgeBackgroundImage}
                                  onOverride={updateBackgroundImageOverrideState}
                                  onAcknowledge={updateAcknowledgeBackgroundImageState}
                                  onUpdateCardInfo={onUpdateCardInfo}
                                  allowDvpSourceForImages={allowDvpSourceForImages} />
    </div>;
};

const getIsSyntheticUtteranceTabs = (props: IVisualCardViewProps): Tabs.Tab[] => {
    const { index, card, shouldDisableInput, onUpdateCardInfo } = props;

    return [{
        label: 'Skill Connection',
        id: 'Skill Connection',
        content: <>
            <FormField label={<RequiredField fieldName='Target skill ID' />} description={
            <span>Skill ID of the Skill that will handle the touch action when the user taps the call to action button.
                Visit the <ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/VCIF+Touch+Actions+Guide'}>VCIF Touch Actions Guide</ExternalLink> to learn more.</span>}>
            <span style={{ color: 'red'}}>Reminder: When testing VCIFs with Touch Actions, please test End-to-End to ensure the Touch Action executes the intended CX.</span>
                <InputWrapper
                    id={`input.target-address-${index}`}
                    readonly={shouldDisableInput}
                    value={card.touchTargets ? card.touchTargets[0].targetAddress : ''}
                    placeholder='connection://amzn1.ask.skill.48995203-7918-4eab-8b39-6a20c7215386.RenderVisualFeedback/1?provider=amzn1.ask.skill.48995203-7918-4eab-8b39-6a20c7215386'
                    onChange={async (input: string) => {
                        if (card.touchTargets && card.touchTargets.length > 0) {
                            card.touchTargets[0].targetAddress = input;
                        } else {
                            const touchTarget: ITouchTarget = {
                                targetAddress: input,
                                payload: ''
                            };
                            card.touchTargets = [touchTarget];
                        }
                        await onUpdateCardInfo(card);
                    }}
                    validate={() => true} />
            </FormField>
            <FormField label={<RequiredField fieldName='Payload' />} description={
            <span>Payload of the Skill that will handle the touch action when the user taps the call to action button.
                Visit the <ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/VCIF+Touch+Actions+Guide'}>VCIF Touch Actions Guide</ExternalLink> to learn more.</span>}>
                <TextAreaWrapper
                    id={`textarea.payload-${index}`}
                    readonly={shouldDisableInput}
                    value={card.touchTargets ? card.touchTargets[0].payload : ''}
                    placeholder={`{'@type': 'IMPRESSION', '@version': '1', 'feedbackOpportunity': {'feedbackReferrer': \\"CifTestTraffic\\", 'utteranceId': \\"TextClient:1.0/2022/05/11/19/DEVICE_UNSPECIFIED/39:33::TNIH_2V.230f038f-da51-4d3e-a980-ee578efa83a1ZXV\\"}, 'inquiry': {'id': 'Optimus_Option'} }`}
                    onInput={async (input: string) => {
                        if (card.touchTargets && card.touchTargets.length > 0) {
                            card.touchTargets[0].payload = input;
                        } else {
                            const touchTarget: ITouchTarget = {
                                targetAddress: '',
                                payload: input
                            };
                            card.touchTargets = [touchTarget];
                        }
                        await onUpdateCardInfo(card);
                    }}
                    // tslint:disable-next-line
                    validate={() => true} />
            </FormField>
        </>
    }, {
        label: 'Synthetic Utterance',
        id: 'Synthetic Utterance',
        content: <>
            <FormField label={<RequiredField fieldName='Synthetic Utterance' />} description='Ex. Tell me some good news'>
                <InputWrapper
                    id={`input.synthetic-utterance-override-text-${index}`}
                    placeholder='Preamble + by saying, Alexa...'
                    readonly={shouldDisableInput}
                    value={card.syntheticUtteranceOverrideText}
                    characterLimit={hintHeadlineCharacterLimit}
                    onChange={async (input: string) => {
                        card.syntheticUtteranceOverrideText = input;
                        await onUpdateCardInfo(card);
                    }}
                    validate={(value) => { return CommonValidator.isStringLengthExceeded(hintHeadlineCharacterLimit, value); }} />
            </FormField>
        </>
    }];
};

export enum DomainIllustrationSource {
    PRE_CONFIGURED = 'Pre-Configured',
    CUSTOM = 'Custom'
}

interface IDomainIllustrationDyanmicFormProps {
    card: IManagementCard;
    onUpdateCardInfo: (card: IManagementCard) => Promise<void>;
    index: number;
    domainIllustrationCustomSource: boolean[];
    shouldDisableInput: boolean;
    toggleDomainIllustrationModal: (modalIndex: number) => void;
    updateCustomDomainIllustrationSource: (domainIllustrationCustomSource: boolean[]) => void;
    allowDvpSourceForImages: boolean;
}

const DomainIllustrationDyanmicForm = (props: IDomainIllustrationDyanmicFormProps) => {
    const { card, index, domainIllustrationCustomSource, onUpdateCardInfo, allowDvpSourceForImages,
        shouldDisableInput, toggleDomainIllustrationModal, updateCustomDomainIllustrationSource } = props;
    return (
        // tslint:disable-next-line
        <FormField label={<RequiredField fieldName='Domain illustration' />} description='Illustration that will appear on top of the preamble title.'>
        <div>
            <FormField label='Illustration source'>
            <Tiles
                id={`tiles.domain-illustration-source-${index}`}
                value={domainIllustrationCustomSource[index] ? DomainIllustrationSource.CUSTOM : DomainIllustrationSource.PRE_CONFIGURED}
                items={[
                    {
                      'label': DomainIllustrationSource.PRE_CONFIGURED,
                      'value': DomainIllustrationSource.PRE_CONFIGURED,
                      'disabled': shouldDisableInput
                    },
                    {
                      'label': DomainIllustrationSource.CUSTOM,
                      'value': DomainIllustrationSource.CUSTOM,
                      'disabled': shouldDisableInput
                    }
                  ]}
                columns={2}
                onChange={async (event) => {
                    delete card.imageLink;
                    await onUpdateCardInfo(card);
                    domainIllustrationCustomSource[index] = !domainIllustrationCustomSource[index];
                    updateCustomDomainIllustrationSource(domainIllustrationCustomSource);
                }}
            />
            </FormField>
        </div>
        { !domainIllustrationCustomSource[index] && <>
            <div className='awsui-util-mt-l awsui-util-mb-l' />
            <div>
                <Button
                    id={`button.choose-file-domain-illustration-${index}`}
                    icon='upload'
                    disabled={shouldDisableInput}
                    onClick={() => {
                        toggleDomainIllustrationModal(index);
                    }}
                >
                    Choose file
                </Button>
            </div>
            {card.imageLink
                ? <span className='awsui-util-status-positive' id={`experience.selected-card-image-${index}`}>
                    <Icon
                        id={`icon.upload-status-${index}`}
                        name='status-positive'
                    />
                    &nbsp;
                    <ExternalLink href={card.imageLink}>
                        {card.imageLink}
                    </ExternalLink>
                </span>
                : <div />}
        </>}
        {
            domainIllustrationCustomSource[index] && <>
                <FormField label={<RequiredField fieldName='Domain illustration URL' />} description={
                <span>A URL of a custom domain illustration/skill icon.
                    Visit the <ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/VCIF+Custom+Image+Guide'}>VCIF Custom Image Guide</ExternalLink> to learn more.</span>}>
                    <AsyncInputWrapper
                            id={`input.custom-domain-illustration-source-${index}`}
                            readonly={shouldDisableInput}
                            value={card.imageLink}
                            placeholder='https://m.media-amazon.com/images/G/01/afss/suggested-for-you/hub-landscape-large/160/img_suggested_action_skills.png'
                            onChange={async (input: string) => {
                                card.imageLink = input;
                                await onUpdateCardInfo(card);
                            } }
                            validate={(value) => ImageValidator.isIconValid(value, allowDvpSourceForImages)}
                            onSuccessText={COMMON_SUCCESS_MESSAGES.VALID_IMAGE}
                    />
                </FormField>
            </>
        }
    </FormField>
    );
};

export interface IBackgroundImageSelectionProps {
    card: IManagementCard;
    shouldDisableInput: boolean;
    index: number;
    override : boolean[];
    acknowledge : boolean[];
    onOverride: (overrideDefaultBackgroundImage: boolean[]) => void;
    onAcknowledge:(acknowledgeBackgroundImage: boolean[]) => void;
    onUpdateCardInfo: (card: IManagementCard) => Promise<void>;
    allowDvpSourceForImages: boolean;
}

export const finalizeBackgroundImageSelection = async (card : IManagementCard, allowDvpSourceForImages: boolean) => {
    if (card.hubLandscapeSmallBackgroundImage !== undefined && card.hubLandscapeSmallBackgroundImage !== null) {
        if (card.hubLandscapeSmallBackgroundImage.length !== 0) {
            ImageValidator.isBackgroundImageValid(card.hubLandscapeSmallBackgroundImage, allowDvpSourceForImages)
                .catch(() => {
                    // invalid : delete image selection to enforce default background image
                    delete card.hubLandscapeSmallBackgroundImage;
                });
        }
        else {
            // empty string : delete image selection to enforce default background image
            delete card.hubLandscapeSmallBackgroundImage;
        }
    }
    if (card.hubLandscapeMediumBackgroundImage !== undefined && card.hubLandscapeMediumBackgroundImage !== null) {
        if (card.hubLandscapeMediumBackgroundImage.length !== 0) {
            ImageValidator.isBackgroundImageValid(card.hubLandscapeMediumBackgroundImage, allowDvpSourceForImages)
                .catch(() => {
                    // invalid : delete image selection to enforce default background image
                    delete card.hubLandscapeMediumBackgroundImage;
                });
        }
        else {
            // empty string : delete image selection to enforce default background image
            delete card.hubLandscapeMediumBackgroundImage;
        }
    }
    if (card.hubLandscapeLargeBackgroundImage !== undefined && card.hubLandscapeLargeBackgroundImage !== null) {
        if (card.hubLandscapeLargeBackgroundImage.length !== 0) {
            ImageValidator.isBackgroundImageValid(card.hubLandscapeLargeBackgroundImage, allowDvpSourceForImages)
                .catch(() => {
                    // invalid : delete image selection to enforce default background image
                    delete card.hubLandscapeLargeBackgroundImage;
                });
        }
        else {
            // empty string : delete image selection to enforce default background image
            delete card.hubLandscapeLargeBackgroundImage;
        }
    }
    if (card.hubLandscapeXLargeBackgroundImage !== undefined && card.hubLandscapeXLargeBackgroundImage !== null) {
        if (card.hubLandscapeXLargeBackgroundImage.length !== 0) {
            ImageValidator.isBackgroundImageValid(card.hubLandscapeXLargeBackgroundImage, allowDvpSourceForImages)
                .catch(() => {
                    // invalid : delete image selection to enforce default background image
                    delete card.hubLandscapeXLargeBackgroundImage;
                });
        }
        else {
            // empty string : delete image selection to enforce default background image
            delete card.hubLandscapeXLargeBackgroundImage;
        }
    }
};

const BackgroundImageSelection = (props : IBackgroundImageSelectionProps) => {
    const { card, shouldDisableInput, index, onAcknowledge, onOverride, onUpdateCardInfo,
        acknowledge, override, allowDvpSourceForImages } = props;
    return (
            <div data-awsui-column-layout-root='true'>
                <FormField label={<OptionalField fieldName='Background Image Targeting'/>} description={
                <span>Set a custom background image for your experience.
                     Visit the <ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/VCIF+Custom+Image+Guide'}>VCIF Custom Image Guide</ExternalLink> to learn more.</span>}>
                    <Toggle id={`toggle.override-default-background-image-card-${index}`}
                            label='Override Defaults'
                            disabled={shouldDisableInput || acknowledge[index]}
                            checked={override[index]}
                            onChange={async (event) => {
                                if (!event.detail.checked) {
                                    delete card.hubLandscapeSmallBackgroundImage;
                                    delete card.hubLandscapeMediumBackgroundImage;
                                    delete card.hubLandscapeLargeBackgroundImage;
                                    delete card.hubLandscapeXLargeBackgroundImage;
                                }
                                override[index] = event.detail.checked;
                                onOverride(override);
                                await onUpdateCardInfo(card);
                            }}
                    />
                </FormField>
                { override[index] && <FormField label='Small Hub (Landscape) Background Image URL'>
                    <AsyncInputWrapper
                        id={`input.background-image-small-hub-landscape-card-${index}`}
                        value={card.hubLandscapeSmallBackgroundImage}
                        readonly={shouldDisableInput || acknowledge[index]}
                        placeholder={'https://m.media-amazon.com/images/G/01/feature_discovery/vie/hub-landscape-small/160/blue_bg.svg'}
                        onChange={async (input: string) => {
                            card.hubLandscapeSmallBackgroundImage = input;
                            await onUpdateCardInfo(card);
                        }}
                        onSuccessText={COMMON_SUCCESS_MESSAGES.VALID_IMAGE}
                        validate={async (value) => await ImageValidator.isBackgroundImageValid(value, allowDvpSourceForImages)}
                        skipValidationOnEmpty={true}
                        validateOnReadonly={true}
                    />
                </FormField> }
                { override[index] && <FormField label='Medium Hub (Landscape) Background Image URL'>
                    <AsyncInputWrapper
                        id={`input.background-image-medium-hub-landscape-card-${index}`}
                        value={card.hubLandscapeMediumBackgroundImage}
                        readonly={shouldDisableInput || acknowledge[index]}
                        placeholder={'https://m.media-amazon.com/images/G/01/feature_discovery/vie/hub-landscape-small/160/blue_bg.svg'}
                        onChange={async (input: string) => {
                            card.hubLandscapeMediumBackgroundImage = input;
                            await onUpdateCardInfo(card);
                        }}
                        onSuccessText={COMMON_SUCCESS_MESSAGES.VALID_IMAGE}
                        validate={async (value) => await ImageValidator.isBackgroundImageValid(value, allowDvpSourceForImages)}
                        skipValidationOnEmpty={true}
                        validateOnReadonly={true}
                    />
                </FormField> }
                { override[index] && <FormField label='Large Hub (Landscape) Background Image URL'>
                     <AsyncInputWrapper
                        id={`input.background-image-large-hub-landscape-card-${index}`}
                        value={card.hubLandscapeLargeBackgroundImage}
                        readonly={shouldDisableInput || acknowledge[index]}
                        placeholder={'https://m.media-amazon.com/images/G/01/feature_discovery/vie/hub-landscape-small/160/blue_bg.svg'}
                        onChange={async (input: string) => {
                            card.hubLandscapeLargeBackgroundImage = input;
                            await onUpdateCardInfo(card);
                        }}
                        onSuccessText={COMMON_SUCCESS_MESSAGES.VALID_IMAGE}
                        validate={async (value) => await ImageValidator.isBackgroundImageValid(value, allowDvpSourceForImages)}
                        skipValidationOnEmpty={true}
                        validateOnReadonly={true}
                    />
                </FormField> }
                { override[index] && <FormField label='X Large Hub (Landscape) Background Image URL'>
                    <AsyncInputWrapper
                        id={`input.background-image-xlarge-hub-landscape-card-${index}`}
                        value={card.hubLandscapeXLargeBackgroundImage}
                        readonly={shouldDisableInput || acknowledge[index]}
                        placeholder={'https://m.media-amazon.com/images/G/01/feature_discovery/vie/hub-landscape-small/160/blue_bg.svg'}
                        onChange={async (input: string) => {
                            card.hubLandscapeXLargeBackgroundImage = input;
                            await onUpdateCardInfo(card);
                        }}
                        onSuccessText={COMMON_SUCCESS_MESSAGES.VALID_IMAGE}
                        validate={async (value) => await ImageValidator.isBackgroundImageValid(value, allowDvpSourceForImages)}
                        skipValidationOnEmpty={true}
                        validateOnReadonly={true}
                    />
                </FormField> }
                <div className='awsui-util-mt-l awsui-util-mb-l' />
                {override[index] && <Checkbox
                    id={`checkbox.acknowledge-background-image-selection-card-${index}`}
                    controlId={`checkbox.acknowledge-background-image-selection-card-${index}`}
                    checked={acknowledge[index]}
                    disabled={shouldDisableInput}
                    onChange={async (event) => {
                        if (event.detail.checked) {
                            await finalizeBackgroundImageSelection(card, allowDvpSourceForImages);
                        }
                        acknowledge[index] = event.detail.checked;
                        onAcknowledge(acknowledge);
                        await onUpdateCardInfo(card);
                    }}
                >
                    <em>I acknowledge the selected background images are appropriate to render on the targeted viewport profiles and I received legal approval for their use.</em>
                </Checkbox>}
                {(override[index] && !acknowledge[index]) && <p className='awsui-util-status-negative'>{COMMON_ERROR_MESSAGES.BACKGROUND_IMAGE_ACKNOWLEDGED_NOT_RECEIVED}</p>}
            </div>
        );
};

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(VisualInterstitialSectionView);
