import React from 'react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { Tabs } from '@amzn/awsui-components-react';
import { RotatingContentPanelContentTabView } from './tabs/RotatingContentPanelContentTabView';
import { RotatingContentPanelCustomerTargetingTabView } from './tabs/RotatingContentPanelCustomerTargetingTabView';
import { TestingTabView } from './tabs/TestingTabView';

interface RotatingContentPanelTabsViewProps {
    experience: IFlattenedExperience;
    activeTabId: string | undefined;
    onTabChange?: (detail: Tabs.ChangeDetail) => void;
}

const RotatingContentPanelTabsView: React.FC<RotatingContentPanelTabsViewProps> = (props: RotatingContentPanelTabsViewProps) => {
    const { experience, activeTabId, onTabChange } = props;
    return <Tabs
        tabs={getRotatingContentPanelTabs(experience)}
        activeTabId={activeTabId}
        onChange={(e) => onTabChange ? onTabChange(e.detail) : null} />;
};

const getRotatingContentPanelTabs = (experience: IFlattenedExperience): Tabs.Tab[] => {
    return [{
        label: 'Content',
        id: 'rotating-content-panel-content',
        content: <RotatingContentPanelContentTabView />
    }, {
        label: 'Customer Targeting',
        id: 'rotating-content-panel-customer-targeting',
        content: <RotatingContentPanelCustomerTargetingTabView />
    }, {
        label: 'Testing',
        id: 'rotating-content-panel-testing',
        content: <TestingTabView experience={experience} />
    }
];
};

export default RotatingContentPanelTabsView;
