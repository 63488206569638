import React from 'react';
import _ from 'lodash';
import { Icon, Popover, Table, TableSorting } from '@amzn/awsui-components-react';
import { ExternalLink } from '../common/LinkComponents';
import { AlexaLabMetricName, IAlexaLabMetrics } from '../../models/AlexaLabMetrics';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { getExperienceMetricsReportTimestamp } from './ExperienceMetricsReportTimestamp';
import { isStatisticallySignificant } from '../../util/metricsCriteriaUtil';

interface PnMetric {
    name: string;
    value: number;
    pvalue: number;
    threshold?: number;
    startDate: string;
    endDate: string;
    refreshTime: string;
}

const PUSH_NOTIFICATIONS_THRESHOLDS = {
    optOutRate: 0.5,
    clickThroughRate: 3
};

interface IExperienceMetricsProps {
    experience: IFlattenedExperience;
    alexaLabMetrics?: IAlexaLabMetrics;
}

const valueCell = (metric: PnMetric) => {
    if (metric.name === 'Opt out rate') {
        const dateRangeContent = <div>Date range: {metric.startDate} to {metric.endDate}</div>;
        const refreshTimeContent = <div>Refreshed: {metric.refreshTime}</div>;
        const popoverContent = <>{dateRangeContent}{refreshTimeContent}</>;

        if (metric.threshold && metric.value > metric.threshold) {
            const popoverError = <div className='awsui-util-status-negative'><Icon variant='warning' name='status-warning' /> {metric.name} must be below {metric.threshold}%</div>;
            return (
            <Popover id={'popover.opt_out_rate'} position={'top'} content={<div>{popoverError}{popoverContent}</div>}>
                <div className='awsui-util-status-negative' id={'popover.opt_out_rate_value'}><Icon variant='warning' name='status-warning' /> {metric.value}%</div>
            </Popover>
            );
        }
        return <div id={'popover.opt_out_rate_value'}>{metric.value}%</div>;
    } else if (metric.name === 'Click through rate') {
        const dateRangeContent = <div>Date range: {metric.startDate} to {metric.endDate}</div>;
        const refreshTimeContent = <div>Refreshed: {metric.refreshTime}</div>;
        const popoverContent = <>{dateRangeContent}{refreshTimeContent}</>;

        if (metric.threshold && metric.value < metric.threshold) {
            const popoverError = <div className='awsui-util-status-negative'><Icon variant='warning' name='status-warning' /> {metric.name} must be above {metric.threshold}%</div>;
            return (
            <Popover id={'popover.click_through_rate'} position={'top'} content={<div>{popoverError}{popoverContent}</div>}>
                <div className='awsui-util-status-negative' id={'popover.click_through_rate_value'}><Icon variant='warning' name='status-warning' /> {metric.value}%</div>
            </Popover>
            );
        }
        return (
        <Popover id={'popover.click_through_rate'} position={'top'} content={popoverContent}>
            <div id={'popover.click_through_rate_value'} className='awsui-util-status-info'>{metric.value}%</div>
        </Popover>
        );
    }
};

const PushNotificationMetricsTableColumnDefinitions = () => {
    return(
    [
        {
            id: 'metric',
            header: 'Metric',
            cell: (metric: PnMetric) => <div>{metric.name}</div>,
            width: 175
        },
        {
            id: 'value',
            header: 'Value',
            cell: (metric: PnMetric) => valueCell(metric)
        },
        {
            id: 'pvalue',
            header: 'P-Value',
            cell: (metric: PnMetric) => isStatisticallySignificant(metric.pvalue) ? <div>Statistically Significant</div> : <div>Statistically Insignificant</div>
        }
    ]);
};

const ExperienceApplicationNotificationMetrics = (props: IExperienceMetricsProps) => {
    const { experience, alexaLabMetrics } = props;

    const items: PnMetric[] = [];

    if (alexaLabMetrics && experience.nluOpportunityTargetings) {
        const optOutMetric = alexaLabMetrics.metrics.filter((x) => _.isEqual(x.name, AlexaLabMetricName.AVERAGE_PUSH_NOTIFICATION_OPT_OUT_PER_CUSTOMER))[0];
        const ctrMetric = alexaLabMetrics.metrics.filter((x) => _.isEqual(x.name, AlexaLabMetricName.PUSH_NOTIFICATION_CTR))[0];

        if (optOutMetric && ctrMetric) {
            const optOutT1 = Number(optOutMetric.data.treatmentMean);
            const optOutC = Number(optOutMetric.data.controlMean);
            const clickThroughT1 = Number(ctrMetric.data.treatmentMean);

            const optOutRate = Math.round((((optOutT1 - optOutC) * 100) + Number.EPSILON) * 100) / 100;
            const clickThroughRate = Math.round(((clickThroughT1 * 100) + Number.EPSILON) * 100) / 100;

            const { startDate, endDate, refreshTime } = getExperienceMetricsReportTimestamp({experience, alexaLabMetrics});

            items.push({
                startDate, endDate, refreshTime,
                name: 'Opt out rate',
                value: optOutRate,
                threshold: PUSH_NOTIFICATIONS_THRESHOLDS.optOutRate,
                pvalue: optOutMetric.data.pvalue
            });

            items.push({
                startDate, endDate, refreshTime,
                name: 'Click through rate',
                value: clickThroughRate,
                threshold: PUSH_NOTIFICATIONS_THRESHOLDS.clickThroughRate,
                pvalue: ctrMetric.data.pvalue
            });
        }
    }

    return (
        <div id='metrics.ApplicationNotification' className='awsui-util-container awsui-util-no-gutters' style={{ marginBottom: '0' }}>
            <div className='awsui-util-container-header' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    Push notification metrics
                    <div className='awsui-util-container-header-description'>
                        <div>
                            For more information, see the <ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/Alexa+app+Push+Notifications+Intakes+Wiki'}>
                                Push notifications wiki</ExternalLink>
                        </div>
                    </div>
                </div>
            </div>
            <Table
                id='table.app-metrics'
                columnDefinitions={PushNotificationMetricsTableColumnDefinitions()}
                items={items}
                loadingText='Loading push notifications metrics'
                loading={false}
                empty={<div>No push notification metrics found</div>}
                resizableColumns={true}
            >
                <TableSorting
                    sortingColumn='metric'
                    sortableColumns={[{ id: 'metric', field: 'dimension.value' }]}
                />
            </Table>
        </div>
    );
};

export default ExperienceApplicationNotificationMetrics;
