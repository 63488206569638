import * as React from 'react';
import { FormSectionView } from '../common/FormSectionView';
import { Spinner } from '@amzn/awsui-components-react';

import { AppState } from '../../reducers/index';
import { useSelector } from 'react-redux';
import { KeyValuePairMultipleColumnView } from '../common/GridKeyValuePair';
import { LinkedWeblab, LinkedBullseye, LinkedAlexaLab } from '../common/LinksRenderHelper';


const OdysseyExperienceExposureControlSectionView: React.FC = () => {
    const experience = useSelector((state: AppState) => state.odysseyExperienceDetailViewState.experience);

    if (!experience) {
        return <FormSectionView title='ExposureControl' description='Something went wrong with experience.' >
            <Spinner size='large' variant='disabled' />
        </FormSectionView>;
    }

    if (experience.type === 'RotatingContentPanel') {
        return <FormSectionView title='Exposure Control'>
            <KeyValuePairMultipleColumnView
                data={[[{
                    key: 'Weblab',
                    value: <LinkedWeblab weblab={experience && experience.weblabName} />
                }], [{
                    key: 'Bullseye',
                    value: <LinkedBullseye
                        bullseye={experience && Number(experience.bullseyeSegment)} region={experience.region} />
                }]]}
            />
        </FormSectionView>;
    }

    return <FormSectionView title='Exposure Control'>
        <KeyValuePairMultipleColumnView
            data={[[{
                key: 'Weblab',
                value: <LinkedWeblab weblab={experience && experience.weblabName} />
            }], [{
                key: 'Bullseye',
                value: <LinkedBullseye
                    bullseye={experience && Number(experience.bullseyeSegment)} region={experience.region} />
            }], [{
                key: 'AlexaLab',
                value: <LinkedAlexaLab weblab={experience && experience.weblabName} />
            }]]}
        />
    </FormSectionView>;

};

export default OdysseyExperienceExposureControlSectionView;
