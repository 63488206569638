import { AnyAction } from 'redux';
import {
    ERROR_LOADING_ANNOUNCEMENT,
    ERROR_UPDATING_ANNOUNCEMENT,
    LOADED_ODYSSEY_ANNOUNCEMENT,
    LOADING_ODYSSEY_ANNOUNCEMENT,
    RESET_ODYSSEY_ANNOUNCEMENT,
    UPDATED_ODYSSEY_ANNOUNCEMENT,
    UPDATING_ODYSSEY_ANNOUNCEMENT
} from '../constants/odysseyAnnouncementActionTypes';
import { IOdysseyAnnouncement } from '../models/odysseyAnnouncement/Announcement';


export interface IOdysseyAnnouncementState {
    isLoading: boolean;
    updatedAnnouncement: boolean;
    announcement?: IOdysseyAnnouncement;
    errorLoading?: Error;
    errorUpdating?: Error;
}

export const odysseyAnnouncementReducer = (
    state: IOdysseyAnnouncementState = { isLoading: false, updatedAnnouncement: false },
    action: AnyAction
): IOdysseyAnnouncementState => {
    switch (action.type) {
        case RESET_ODYSSEY_ANNOUNCEMENT:
            return {
                ...state,
                isLoading: false,
                updatedAnnouncement: false,
                announcement: undefined,
                errorLoading: undefined,
                errorUpdating: undefined
            };
        case LOADING_ODYSSEY_ANNOUNCEMENT:
            return {
                ...state,
                isLoading: true,
                updatedAnnouncement: false
            };
        case UPDATING_ODYSSEY_ANNOUNCEMENT:
            return {
                ...state,
                updatedAnnouncement: false,
                isLoading: true
            };
        case LOADED_ODYSSEY_ANNOUNCEMENT:
            return {
                ...state,
                isLoading: false,
                announcement: action.announcement,
                errorLoading: undefined,
                errorUpdating: undefined
            };
        case UPDATED_ODYSSEY_ANNOUNCEMENT:
            return {
                ...state,
                isLoading: false,
                updatedAnnouncement: true,
                errorLoading: undefined,
                errorUpdating: undefined
            };
        case ERROR_LOADING_ANNOUNCEMENT:
            return {
                ...state,
                isLoading: false,
                updatedAnnouncement: false,
                errorLoading: action.error,
                errorUpdating: undefined
            };
        case ERROR_UPDATING_ANNOUNCEMENT:
            return {
                ...state,
                isLoading: false,
                updatedAnnouncement: false,
                errorUpdating: action.error,
                errorLoading: undefined
            };
        default:
            return state;
    }
};
