import React from 'react';
import { Flashbar } from '@amzn/awsui-components-react';

export interface IAsyncFlashbarWrapperProps {
    id: string;
    validate: () => Promise<void>;

}

export interface IAsyncFlashbarWrapperState{
    errorMessage: string;
}

export class AsyncFlashbarWrapper extends React.Component<IAsyncFlashbarWrapperProps, IAsyncFlashbarWrapperState> {
    private mounted: boolean;

    constructor(props : IAsyncFlashbarWrapperProps, state : IAsyncFlashbarWrapperState) {
        super(props);
        this.mounted = false;
        this.state = {errorMessage:''};
    }

    componentDidMount(): void {
        this.mounted = true;
        this.props.validate()
            .then( response => {
                if (this.mounted) {
                    this.setState(prevState => {
                        return {...prevState,
                            errorMessage: ''
                        };
                    });
                }
            }).catch( err => {
                if (this.mounted) {
                    this.setState(prevState => {
                        return {...prevState,
                            errorMessage: err.message
                        };
                    });
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<IAsyncFlashbarWrapperProps>, prevState: Readonly<IAsyncFlashbarWrapperState>, snapshot?: any): void {
        this.props.validate()
            .then( response => {
                if (this.mounted && prevState.errorMessage) {
                    this.setState(prev => {
                        return {...prev,
                            errorMessage: ''
                        };
                    });
                }
            }).catch( err => {
                if (this.mounted && !prevState.errorMessage) {
                    this.setState(prev => {
                        return {...prev,
                            errorMessage: err.message
                        };
                    });
                }
            });
    }
    componentWillUnmount(): void {
        this.mounted = false;
        this.setState(prevState => {
            return {
                ...prevState,
                errorMessage: ''
            };
        });
    }

    render () {
        const { id } = this.props;
        const { errorMessage } = this.state;
        return <div style={{ marginBottom: '10px' }}>
            {errorMessage && <Flashbar id={id}
                items={[{
                    content: errorMessage,
                    type: 'error'
                }]} />}
        </div>;
    }
}
