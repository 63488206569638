import * as React from 'react';
import { Component } from 'react';
import { Table, Popover, ButtonDropdown, Alert, Tabs } from '@amzn/awsui-components-react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { IRawCifMetricsData, ICifMetric, ICifData, CifMetricNames, CifMetricsTimeRange, createMappedCifMetrics,
    constructWeeklyMetricsForTableView, constructDailyMetricsForTableView, areMetricsDelayed, IIntentMetric, CifMetricDDBNames } from '../../models/CifMetrics';
import { AppState } from '../../reducers';
import { connect } from 'react-redux';
import { interruptionRateThreshold, negativeFeedbackThreshold } from '../../constants/dialUpCriteriaConstant';
import { ExternalLink } from '../common/LinkComponents';
import { constructDailyIntentMetrics, constructDailyIntentMetricsForTableView, constructWeeklyIntentMetrics, constructWeeklyIntentMetricsForTableView, createMappedItems, } from '../../models/IntentMetrics';

export interface ICifMetricsProps {
    dispatch: any;
    experience?: IFlattenedExperience;
    cxWeeklyMetrics?: IRawCifMetricsData[];
    cxDailyMetrics?: IRawCifMetricsData[];
}

export interface ICifMetricsState {
    isRangeDaysOrWeeks: CifMetricsTimeRange;
    isExperienceReferralBased: boolean;
    cifMetricDisplayData: IRawCifMetricsData[];
    intentMetricDisplayData: IRawCifMetricsData[];
    activeTabId: string;
}

export const OutputMetricData = ({ metricValue, metricName }: { metricValue?: number, metricName: string }) => {
    let className = '';
    const negativeMetricClassName = 'awsui-util-status-negative';

    if(!metricValue && metricValue !== 0){
        return<div className='awsui-util-status-inactive'>-</div>;
    }

    switch(metricName){
        case CifMetricNames.ConversionRate:
            return <div className={className}>{`${(metricValue*100).toFixed(1)}%`}</div>;
        case CifMetricNames.InterruptionRate:
            className = metricValue < interruptionRateThreshold ? className : negativeMetricClassName;
            return <div className={className}>{`${(metricValue*100).toFixed(1)}%`}</div>;
        case CifMetricNames.NegativeFeedback:
            className = metricValue < negativeFeedbackThreshold ? className : negativeMetricClassName;
            return <div className={className}>{`${(metricValue*100).toFixed(2)}%`}</div>;
        default:
            return <div className={className}>{`${metricValue.toLocaleString()}`}</div>;
    }
};

export const CifDateCell = ({ metrics }: { metrics: ICifData }) => {
    if (metrics) {
        const { weekStart, weekEnd, reportedAt } = metrics;

        if (!weekStart) {
            return (
                <Popover
                    size='medium'
                    position='top'
                    content={
                        <div>
                            <p>{`Refreshed: ${new Date(reportedAt).toLocaleString()}`}</p>
                        </div>
                            }
                ><div>{metrics.period}</div></Popover>
            );
        } else {
            return(
                <Popover
                    size='medium'
                    position='top'
                    content={
                        <div>
                            <p>{`Date range: ${weekStart} to ${weekEnd}`}</p>
                            <p>{`Refreshed: ${new Date(reportedAt).toLocaleString()}`}</p>
                        </div>
                            }
                ><div>{metrics.period}</div></Popover>
            );
        }
    } else {
        return <div></div>;
    }
};

const getIntentMetricsTabs = (state: ICifMetricsState): Tabs.Tab[] => {
    if (!state.isExperienceReferralBased) {
        return [{
            label: CifMetricNames.TotalImpressions,
            id: CifMetricDDBNames.TotalImpressions,
            content: <IntentTable intentMetricDisplayData={state.intentMetricDisplayData} metricName={CifMetricNames.TotalImpressions} tableName={CifMetricDDBNames.TotalImpressions} />
        }, {
            label: CifMetricNames.DistinctCustomers,
            id: CifMetricDDBNames.DistinctCustomers,
            content: <IntentTable intentMetricDisplayData={state.intentMetricDisplayData} metricName={CifMetricNames.DistinctCustomers} tableName={CifMetricDDBNames.DistinctCustomers} />
        }, {
            label: CifMetricNames.InterruptionRate,
            id: CifMetricDDBNames.InterruptionRate,
            content: <IntentTable intentMetricDisplayData={state.intentMetricDisplayData} metricName={CifMetricNames.InterruptionRate} tableName={CifMetricDDBNames.InterruptionRate} />
        }, {
            label: CifMetricNames.NegativeFeedback,
            id: CifMetricDDBNames.NegativeFeedback,
            content: <IntentTable intentMetricDisplayData={state.intentMetricDisplayData} metricName={CifMetricNames.NegativeFeedback} tableName={CifMetricDDBNames.NegativeFeedback} />
        }];
    }

    return [{
        label: CifMetricNames.TotalImpressions,
        id: CifMetricDDBNames.TotalImpressions,
        content: <IntentTable intentMetricDisplayData={state.intentMetricDisplayData} metricName={CifMetricNames.TotalImpressions} tableName={CifMetricDDBNames.TotalImpressions} />
    }, {
        label: CifMetricNames.DistinctCustomers,
        id: CifMetricDDBNames.DistinctCustomers,
        content: <IntentTable intentMetricDisplayData={state.intentMetricDisplayData} metricName={CifMetricNames.DistinctCustomers} tableName={CifMetricDDBNames.DistinctCustomers} />
    }, {
        label: CifMetricNames.ConversionRate,
        id: CifMetricDDBNames.ConversionRate,
        content: <IntentTable intentMetricDisplayData={state.intentMetricDisplayData} metricName={CifMetricNames.ConversionRate} tableName={CifMetricDDBNames.ConversionRate} />
    }, {
        label: CifMetricNames.InterruptionRate,
        id: CifMetricDDBNames.InterruptionRate,
        content: <IntentTable intentMetricDisplayData={state.intentMetricDisplayData} metricName={CifMetricNames.InterruptionRate} tableName={CifMetricDDBNames.InterruptionRate} />
    }, {
        label: CifMetricNames.NegativeFeedback,
        id: CifMetricDDBNames.NegativeFeedback,
        content: <IntentTable intentMetricDisplayData={state.intentMetricDisplayData} metricName={CifMetricNames.NegativeFeedback} tableName={CifMetricDDBNames.NegativeFeedback} />
    }];
};

const IntentTable = (props: { intentMetricDisplayData: IRawCifMetricsData[], metricName: CifMetricNames, tableName: CifMetricDDBNames }) => {
    return props.intentMetricDisplayData &&
    <Table
        id={'table.' + props.tableName}
        columnDefinitions={intentTableColumnDefinitions(props.intentMetricDisplayData, props.metricName)}
        items={createMappedItems(props.intentMetricDisplayData, props.tableName)}
        loadingText={'Loading' + props.metricName}
        loading={false}
        empty={<div>No {props.metricName} found</div>}
        resizableColumns={true}
    >
    </Table>;
};

const cifMetricsTableColumnDefinitions = (cifMetricDisplayData: IRawCifMetricsData[]) => {
    return(
    [
        {
            id: 'metric',
            header: ('Metric'),
            cell: (cifMetric: ICifMetric) => cifMetric.metricName,
            width: 175,
        },
        {
            id: 'time_range1',
            header: () => <CifDateCell metrics={cifMetricDisplayData[0]?.metrics[6]}/>,
            cell: (cifMetric: ICifMetric) => <OutputMetricData metricValue={cifMetric.values[6]} metricName={cifMetric.metricName}/>,
            width: 125,
        },
        {
            id: 'time_range2',
            header: () => <CifDateCell metrics={cifMetricDisplayData[0]?.metrics[5]}/>,
            cell: (cifMetric: ICifMetric) => <OutputMetricData metricValue={cifMetric.values[5]} metricName={cifMetric.metricName}/>,
            width: 125,
        },
        {
            id: 'time_range3',
            header: () => <CifDateCell metrics={cifMetricDisplayData[0]?.metrics[4]}/>,
            cell: (cifMetric: ICifMetric) => <OutputMetricData metricValue={cifMetric.values[4]} metricName={cifMetric.metricName}/>,
            width: 125,
        },
        {
            id: 'time_range4',
            header: () => <CifDateCell metrics={cifMetricDisplayData[0]?.metrics[3]}/>,
            cell: (cifMetric: ICifMetric) => <OutputMetricData metricValue={cifMetric.values[3]} metricName={cifMetric.metricName}/>,
            width: 125,
        },
        {
            id: 'time_range5',
            header: () => <CifDateCell metrics={cifMetricDisplayData[0]?.metrics[2]}/>,
            cell: (cifMetric: ICifMetric) => <OutputMetricData metricValue={cifMetric.values[2]} metricName={cifMetric.metricName}/>,
            width: 125,
        },
        {
            id: 'time_range6',
            header: () => <CifDateCell metrics={cifMetricDisplayData[0]?.metrics[1]}/>,
            cell: (cifMetric: ICifMetric) => <OutputMetricData metricValue={cifMetric.values[1]} metricName={cifMetric.metricName}/>,
            width: 125,
        },
        {
            id: 'time_range7',
            header: () => <CifDateCell metrics={cifMetricDisplayData[0]?.metrics[0]}/>,
            cell: (cifMetric: ICifMetric) => <OutputMetricData metricValue={cifMetric.values[0]} metricName={cifMetric.metricName}/>,
            width: 125,
        },
    ]);
};

const intentTableColumnDefinitions = (intentMetricDisplayData: IRawCifMetricsData[], metricName: string) => {
    if (!intentMetricDisplayData[0] || !intentMetricDisplayData[0].intentMetrics) {
        return [];
    }

    const firstIntentName = Object.keys(intentMetricDisplayData[0].intentMetrics)[0];

    if (!firstIntentName) {
        return [];
    }

    return (
    [
        {
            id: 'intent',
            header: ('Intent'),
            cell: (intentMetric: IIntentMetric) => intentMetric.intentName,
            width: 225,
        },
        {
            id: 'time_range1_intent',
            header: () => <CifDateCell metrics={intentMetricDisplayData[0].intentMetrics[firstIntentName][6]}/>,
            cell: (intentMetric: IIntentMetric) => <OutputMetricData metricValue={intentMetric.values[6]} metricName={metricName}/>,
            width: 125,
        },
        {
            id: 'time_range2_intent',
            header: () => <CifDateCell metrics={intentMetricDisplayData[0].intentMetrics[firstIntentName][5]}/>,
            cell: (intentMetric: IIntentMetric) => <OutputMetricData metricValue={intentMetric.values[5]} metricName={metricName}/>,
            width: 125,
        },
        {
            id: 'time_range3_intent',
            header: () => <CifDateCell metrics={intentMetricDisplayData[0].intentMetrics[firstIntentName][4]}/>,
            cell: (intentMetric: IIntentMetric) => <OutputMetricData metricValue={intentMetric.values[4]} metricName={metricName}/>,
            width: 125,
        },
        {
            id: 'time_range4_intent',
            header: () => <CifDateCell metrics={intentMetricDisplayData[0].intentMetrics[firstIntentName][3]}/>,
            cell: (intentMetric: IIntentMetric) => <OutputMetricData metricValue={intentMetric.values[3]} metricName={metricName}/>,
            width: 125,
        },
        {
            id: 'time_range5_intent',
            header: () => <CifDateCell metrics={intentMetricDisplayData[0].intentMetrics[firstIntentName][2]}/>,
            cell: (intentMetric: IIntentMetric) => <OutputMetricData metricValue={intentMetric.values[2]} metricName={metricName}/>,
            width: 125,
        },
        {
            id: 'time_range6_intent',
            header: () => <CifDateCell metrics={intentMetricDisplayData[0].intentMetrics[firstIntentName][1]}/>,
            cell: (intentMetric: IIntentMetric) => <OutputMetricData metricValue={intentMetric.values[1]} metricName={metricName}/>,
            width: 125,
        },
        {
            id: 'time_range7_intent',
            header: () => <CifDateCell metrics={intentMetricDisplayData[0].intentMetrics[firstIntentName][0]}/>,
            cell: (intentMetric: IIntentMetric) => <OutputMetricData metricValue={intentMetric.values[0]} metricName={metricName}/>,
            width: 125,
        },
    ]);
};

export class ExperienceCifMetrics extends Component<ICifMetricsProps, ICifMetricsState> {
    constructor(props: ICifMetricsProps) {
        super(props);
        const { experience, cxWeeklyMetrics } = this.props;
        const cifWeeklyMetricsDisplayData = (cxWeeklyMetrics) ? cxWeeklyMetrics : [];
        const intentWeeklyMetricsDisplayData = constructWeeklyIntentMetrics(cifWeeklyMetricsDisplayData);

        this.state = {
            isRangeDaysOrWeeks: CifMetricsTimeRange.weeks,
            cifMetricDisplayData: constructWeeklyMetricsForTableView(cifWeeklyMetricsDisplayData),
            intentMetricDisplayData: constructWeeklyIntentMetricsForTableView(intentWeeklyMetricsDisplayData),
            isExperienceReferralBased: (experience?.referralContent) ? true : false,
            activeTabId: CifMetricDDBNames.TotalImpressions
        };
    }

   private changeDropdownTitle(dropdownDaysOrWeeks: string) {
        const { cxDailyMetrics, cxWeeklyMetrics } = this.props;
        const cifDailyMetricsDisplayData = (cxDailyMetrics) ? cxDailyMetrics : [];
        const cifWeeklyMetricsDisplayData = (cxWeeklyMetrics) ? cxWeeklyMetrics : [];

        const intentDailyMetricsDisplayData = constructDailyIntentMetrics(cifDailyMetricsDisplayData);
        const intentWeeklyMetricsDisplayData = constructWeeklyIntentMetrics(cifWeeklyMetricsDisplayData);

        this.setState(prevState => {
            return {
                ...prevState,
                isRangeDaysOrWeeks: (dropdownDaysOrWeeks === CifMetricsTimeRange.weeks) ? CifMetricsTimeRange.weeks : CifMetricsTimeRange.days,
                cifMetricDisplayData: (dropdownDaysOrWeeks === CifMetricsTimeRange.weeks) ?
                    constructWeeklyMetricsForTableView(cifWeeklyMetricsDisplayData) : constructDailyMetricsForTableView(cifDailyMetricsDisplayData),
                intentMetricDisplayData: (dropdownDaysOrWeeks === CifMetricsTimeRange.weeks) ?
                    constructWeeklyIntentMetricsForTableView(intentWeeklyMetricsDisplayData) : constructDailyIntentMetricsForTableView(intentDailyMetricsDisplayData)
            };
        });
    }

    public render() {
        const { isRangeDaysOrWeeks, cifMetricDisplayData, intentMetricDisplayData, isExperienceReferralBased, activeTabId } = this.state;
        const { experience } = this.props;
        const cifMetricDisplayDataFilteredToCurrentExperience = cifMetricDisplayData.filter(e => e.experienceId === experience?.id);
        const isDelayed: boolean = areMetricsDelayed(cifMetricDisplayDataFilteredToCurrentExperience);

        const mappedCifMetricDisplayData = createMappedCifMetrics(isExperienceReferralBased, cifMetricDisplayDataFilteredToCurrentExperience);

        return <div id='metrics.cif' className='awsui-util-container awsui-util-no-gutters' style={{ marginBottom: '0' }}>
            <div className='awsui-util-container-header' style={{ display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    CIF Metrics
                    <div className='awsui-util-container-header-description'>
                       <div>
                            For all dial-ups and to avoid dial-down, interruption rate must be less than 7% and negative feedback must be less than 1.9%. Details regarding
                             dial-up and dial-down criteria can be found on the <ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+Content+Injection+Framework#Workflows--829673895'}>Odyssey wiki.</ExternalLink>
                        </div>
                        <div>
                            CIF Metrics can also be found on the <ExternalLink href={'https://skynet.db.amazon.com/#/views/CIFInsights360/3_1_ProviderbyContentType?:iid=1'}
                            >Provider by Content Type dashboard</ExternalLink> in CIF Insights
                        </div>
                        <div>
                            Information about the Conversion Rate Type (Direct or Attributed) can be found on
                            the <ExternalLink href={'https://skynet.db.amazon.com/#/views/CIFConversionDashboard/CIFConversion-CampaignLevel-SortableforAnalysis'}
                            >CIF Insights dashboard.</ExternalLink>
                        </div>
                        <br/>
                        <div>
                        {(experience?.status.includes('LIVE') || experience?.status === 'LAUNCHED') && isDelayed && <Alert
                            visible={true}
                            header={'Alert: Delay in metrics refresh'}
                            type='warning'>
                                <div>
                                The metrics pipeline is blocked, causing a delay in acquiring data after {cifMetricDisplayDataFilteredToCurrentExperience[0].metrics[0].period}
                                {isRangeDaysOrWeeks === CifMetricsTimeRange.weeks ? ` (${(cifMetricDisplayDataFilteredToCurrentExperience[0].metrics[0].weekStart)} - ${cifMetricDisplayDataFilteredToCurrentExperience[0].metrics[0].weekEnd})` :
                                ''}.  You can check out CIF metrics at <ExternalLink href={'https://skynet.db.amazon.com/#/views/CIFInsights360/3_1_ProviderbyContentType?:iid=1'}
                                >CIF Insights</ExternalLink>, while we are actively working to restore your Odyssey metrics in the meantime.
                                </div>
                            </Alert>}
                        </div>
                    </div>
                </div>
                <div className='col-3 awsui-util-t-r'>
                    <ButtonDropdown
                        items={[
                            {
                            'text':'Last 7 days',
                            'id':CifMetricsTimeRange.days
                            },
                            {
                            'text':'Last 7 weeks',
                            'id':CifMetricsTimeRange.weeks
                            }
                        ]}
                        id='dropdown.cif-metrics'
                        onItemClick={(e) => {this.changeDropdownTitle(e.detail.id);}}
                    >Last 7 {isRangeDaysOrWeeks}</ButtonDropdown>
                </div>
            </div>
            <Table
                id='table.cif-metrics'
                columnDefinitions={cifMetricsTableColumnDefinitions(cifMetricDisplayData)}
                items={mappedCifMetricDisplayData}
                loadingText='Loading cif metrics'
                loading={false}
                empty={<div>No CIF metrics found</div>}
                resizableColumns={true}
            >
            </Table>
            {intentMetricDisplayData[0] && Object.keys(intentMetricDisplayData[0].intentMetrics).length > 1 &&
                <div>
                    <div className='awsui-util-container-header' style={{ display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            View CIF Metrics By Intent
                            <div className='awsui-util-container-header-description'>
                                <div>
                                    For Conversion Rate, Interruption Rate, and Negative Feedback Rate, the Total is a calculation using Impression-based weighted average.
                                </div>
                            </div>
                        </div>
                    </div>
                    <Tabs
                        activeTabId={activeTabId}
                        tabs={getIntentMetricsTabs(this.state)}
                        onChange={(e) => this.setState({ ...this.state, activeTabId: e.detail.activeTabId }) }/>
                </div>
            }
        </div>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, cxDailyMetricsState, cxWeeklyMetricsState }: AppState) => {
    return {
        experience: experienceDetailViewState.experience || undefined,
        cxWeeklyMetrics: cxWeeklyMetricsState.cxWeeklyMetrics,
        cxDailyMetrics: cxDailyMetricsState.cxDailyMetrics,
    };
};

export default connect(mapStateToProps)(ExperienceCifMetrics);
