import { AnyAction, Dispatch } from 'redux';
import { IOdysseyAnnouncement } from '../models/odysseyAnnouncement/Announcement';
import { getOdysseyAnnouncement, putOdysseyAnnouncement } from '../api/odysseyAnnouncementApi';
import {
    ERROR_LOADING_ANNOUNCEMENT,
    ERROR_UPDATING_ANNOUNCEMENT,
    LOADED_ODYSSEY_ANNOUNCEMENT,
    LOADING_ODYSSEY_ANNOUNCEMENT,
    RESET_ODYSSEY_ANNOUNCEMENT,
    UPDATED_ODYSSEY_ANNOUNCEMENT,
    UPDATING_ODYSSEY_ANNOUNCEMENT
} from '../constants/odysseyAnnouncementActionTypes';


export const resetOdysseyAnnouncement: AnyAction = {
    type: RESET_ODYSSEY_ANNOUNCEMENT
};


export const updatingOdysseyAnnouncement: AnyAction = {
    type: UPDATING_ODYSSEY_ANNOUNCEMENT
};

export const updatedOdysseyAnnouncement: AnyAction = {
    type: UPDATED_ODYSSEY_ANNOUNCEMENT
};

export const loadingOdysseyAnnouncement: AnyAction = {
    type: LOADING_ODYSSEY_ANNOUNCEMENT
};

export const loadedOdysseyAnnouncement = (announcement?: IOdysseyAnnouncement): AnyAction => {
    return {
        type: LOADED_ODYSSEY_ANNOUNCEMENT,
        announcement
    };
};

export const errorUpdatingAnnouncement = (error: Error): AnyAction => {
    return {
        type: ERROR_UPDATING_ANNOUNCEMENT,
        error
    };
};

export const errorLoadingAnnouncement = (error: Error): AnyAction => {
    return {
        type: ERROR_LOADING_ANNOUNCEMENT,
        error
    };
};

export const updateOdysseyAnnouncementAction = (announcement: IOdysseyAnnouncement) => async (dispatch: Dispatch) => {
    dispatch(updatingOdysseyAnnouncement);

    try {
        await putOdysseyAnnouncement(announcement);
        dispatch(updatedOdysseyAnnouncement);
    } catch (error) {
        dispatch(errorUpdatingAnnouncement(error));
    }
};

export const getOdysseyAnnouncementAction = () => async (dispatch: Dispatch) => {
    dispatch(loadingOdysseyAnnouncement);

    try {
        const announcement = await getOdysseyAnnouncement();
        dispatch(loadedOdysseyAnnouncement(announcement));
    } catch (error) {
        dispatch(errorLoadingAnnouncement(error));
    }
};
