import { IExperience } from '../models/Experience';
import { UserRole } from '../models/SpeakerData';

// title, type, metadata need to be filled out on create
export const EMPTY_EXPERIENCE: IExperience = {
    'type': 'CIF',
    'id': '',
    'title': undefined,
    'hintId': undefined,
    'contentId': undefined,
    'filters': {},
    'weblab': {},
    'metadata': {
      'createdAt': 0,
      'createdBy': '',
      'modifiedAt': 0,
      'modifiedBy': '',
      'changedStateAt': 0
    },
    'guardrails': {
      'overrideAccounts': [],
      'overrideSelectiveFilters': []
    },
    'dayTimeRangeGuardrails': undefined,
    'businessInfo': {},
    'status': 'DRAFT',
    'approvalId': undefined,
    'operatorGroup': undefined,
    'cancellationReason': undefined,
    'opportunityTriggers': [],
    'opportunityTriggerExclusions': [],
    'nluOpportunityTargetings': [],
    'nluOpportunityTargetingExclusions': [],
    'content': {},
    'visualContent': {},
    'referralContent': undefined,
    'featureHints': [],
    'templateVariables': undefined,
    'remoteContextualSegments': {
      'includeRemoteSegments': [],
      'excludeRemoteSegments': []
    },
    'notificationContent': {},
    'dataSource': 'SINGLE_EXECUTION_BULLSEYE',
    'speakerData': {
        'userRole': UserRole.ADULT
    },
    'expiryTimeInHours': undefined
  };
