import * as React from 'react';
import _ from 'lodash';
import { Button, Modal, Select, FormField, Input, Checkbox, Alert } from '@amzn/awsui-components-react';
import { useDispatch } from 'react-redux';
import { sendForReviewOdysseyExperienceAction } from '../../actions/experienceEditViewActions';
import { ExternalLink } from '../common/LinkComponents';

interface RcpValidationModalProps {
    isVisible: boolean;
    hideModal: () => void;
}

interface RcpValidationModalState {
    experienceDescription: string;
    bullseyeDescription: string;
    spellingErrors: boolean;
    backgroundImageSize: boolean;
    backgroundImageCorrectlyDisplayed: boolean;
    triggerCorrectly: boolean;
    hintInvokeCorrectly: boolean;
    uploadedTestingVideo: boolean;
    linkTestingVideo: string;
    testerUserAlias: string;
    isUserInputValid: boolean;
}

const initialState: RcpValidationModalState = {
    experienceDescription: '',
    bullseyeDescription: '',
    spellingErrors: false,
    backgroundImageSize: false,
    backgroundImageCorrectlyDisplayed: false,
    triggerCorrectly: false,
    hintInvokeCorrectly: false,
    uploadedTestingVideo: false,
    linkTestingVideo: '',
    testerUserAlias: '',
    isUserInputValid: false
};

const alexaOdysseyRCPCardTestingHref = 'https://drive.corp.amazon.com/folders/Alexa%20Odyssey%20RCP%20Card%20Testing';

export const RcpValidationModal: React.FC<RcpValidationModalProps> = (props: RcpValidationModalProps) => {
    const [currentState, setState] = React.useState(initialState);
    const dispatch = useDispatch();

    const sendForApproval = () => {
        dispatch(sendForReviewOdysseyExperienceAction());
    };

    const validateUserInput = (userInput: RcpValidationModalState): boolean => {
        if (_.isEmpty(userInput.experienceDescription)) return false;
        if (_.isEmpty(userInput.bullseyeDescription)) return false;
        if (!userInput.spellingErrors) return false;
        if (!userInput.backgroundImageSize) return false;
        if (!userInput.backgroundImageCorrectlyDisplayed) return false;
        if (!userInput.triggerCorrectly) return false;
        if (!userInput.hintInvokeCorrectly) return false;
        if (!userInput.uploadedTestingVideo) return false;
        if (_.isEmpty(userInput.linkTestingVideo)) return false;
        if (_.isEmpty(userInput.testerUserAlias)) return false;
        return true;
    };

    const onInputChangeHandler = (event: CustomEvent<Input.ChangeDetail | Select.ChangeDetail>) => {
        const id = (event.target as HTMLElement).getAttribute('id')?.split('.').pop();
        if (id) {
            const value = _.get(event.detail, 'value') || _.get(event.detail, 'selectedId') === '1';
            if (value) {
                const newState = { ...currentState, [id]: value };
                const isUserInputValid = validateUserInput(newState);
                newState.isUserInputValid = isUserInputValid;
                setState(newState);
            }
        }
    };

    const onCheckboxChangeHandler = (event: CustomEvent<Checkbox.ChangeDetail>) => {
        const split = (event.target as HTMLElement).getAttribute('id')?.split('.');
        const checkState = split?.pop();
        const id = split?.pop();

        if (id && checkState) {
            const newState = { ...currentState, [id]: checkState === 'y'};
            const isUserInputValid = validateUserInput(newState);
            newState.isUserInputValid = isUserInputValid;
            setState(newState);
        }
    };

    return <Modal
        id='modal.send-rcp-experience-for-approval'
        visible={props.isVisible}
        header='Send for Approval'
        size='medium'
        onDismiss={props.hideModal}
        footer={<div className='awsui-row'>
        <div className='awsui-util-t-r'>
            <Alert>Please ensure the Activation End Date/Time is at least one hour after the current date/time in UTC.</Alert>
            <br></br>
            <span className='awsui-util-f-r'>
                <Button
                    id='modal.send-rcp-experience-for-approval.cancel'
                    variant='link'
                    onClick={props.hideModal}
                    >
                    Cancel
                </Button>
                <Button
                    id='modal.send-rcp-experience-for-approval.send-for-approval'
                    variant='primary'
                    disabled={!currentState.isUserInputValid}
                    onClick={sendForApproval}
                    >
                    Send for Approval
                </Button>
            </span>
        </div>
    </div>}
    >
        <div className='awsui-row'>
            <FormField label='Describe the end to end Customer Experience for this card'>
                <Input id='modal.send-rcp-experience-for-approval.experienceDescription' onChange={onInputChangeHandler}  />
            </FormField><br />
            <FormField label='Bullseye Description'>
                <Input id='modal.send-rcp-experience-for-approval.bullseyeDescription' onChange={onInputChangeHandler}  />
            </FormField><br />

            <FormField label={<span>Are the <strong>Header Text, Primary Text, Secondary Text, Hint Text</strong> and <strong>CTA Button Text</strong> (if used) free
                from spelling, grammar errors and do they display correctly on your test device?</span>}>
                <div className='awsui-grid'>
                    <div className='awsui-row awsui-util-t-c'>
                        <div className='col-3'>
                            <Checkbox
                                id='modal.send-rcp-experience-for-approval.spellingErrors.y'
                                checked={currentState.spellingErrors === true}
                                onChange={onCheckboxChangeHandler}
                            > Yes </Checkbox>
                        </div>
                        <div className='col-3'>
                            <Checkbox
                                id='modal.send-rcp-experience-for-approval.spellingErrors.n'
                                checked={currentState.spellingErrors === false}
                                onChange={onCheckboxChangeHandler}
                            > No </Checkbox>
                        </div>
                    </div>
                </div>
            </FormField><br />

            <FormField label={<span>Is the background image size equal to or below <strong>375 KB</strong> and is the resolution below <strong>1920x1080 px</strong>?</span>}>
                <div className='awsui-grid'>
                    <div className='awsui-row awsui-util-t-c'>
                        <div className='col-3'>
                            <Checkbox
                                id='modal.send-rcp-experience-for-approval.backgroundImageSize.y'
                                checked={currentState.backgroundImageSize === true}
                                onChange={onCheckboxChangeHandler}
                            > Yes </Checkbox>
                        </div>
                        <div className='col-3'>
                            <Checkbox
                                id='modal.send-rcp-experience-for-approval.backgroundImageSize.n'
                                checked={currentState.backgroundImageSize === false}
                                onChange={onCheckboxChangeHandler}
                            > No </Checkbox>
                        </div>
                    </div>
                </div>
            </FormField><br />

            <FormField label='Does the background image display correctly on your test device in both portrait and landscape mode?'>
                <div className='awsui-grid'>
                    <div className='awsui-row awsui-util-t-c'>
                        <div className='col-3'>
                            <Checkbox
                                id='modal.send-rcp-experience-for-approval.backgroundImageCorrectlyDisplayed.y'
                                checked={currentState.backgroundImageCorrectlyDisplayed === true}
                                onChange={onCheckboxChangeHandler}
                            > Yes </Checkbox>
                        </div>
                        <div className='col-3'>
                            <Checkbox
                                id='modal.send-rcp-experience-for-approval.backgroundImageCorrectlyDisplayed.n'
                                checked={currentState.backgroundImageCorrectlyDisplayed === false}
                                onChange={onCheckboxChangeHandler}
                            > No </Checkbox>
                        </div>
                    </div>
                </div>
            </FormField><br />

            <FormField label={<span>Have you clicked the <strong>Primary Text, Secondary Text, Hint Text</strong> and <strong>CTA Button</strong>&nbsp;
                (if used) and ensured that the Alexa feature is triggered correctly and that Alexa does not throw an error?</span>}>
                <div className='awsui-grid'>
                    <div className='awsui-row awsui-util-t-c'>
                        <div className='col-3'>
                            <Checkbox
                                id='modal.send-rcp-experience-for-approval.triggerCorrectly.y'
                                checked={currentState.triggerCorrectly === true}
                                onChange={onCheckboxChangeHandler}
                            > Yes </Checkbox>
                        </div>
                        <div className='col-3'>
                            <Checkbox
                                id='modal.send-rcp-experience-for-approval.triggerCorrectly.n'
                                checked={currentState.triggerCorrectly === false}
                                onChange={onCheckboxChangeHandler}
                            > No </Checkbox>
                        </div>
                    </div>
                </div>
            </FormField><br />

            <FormField label={<span>Does saying the <strong>Hint Text</strong> on your test device exactly how it shows up on the card cause NLU to invoke the Alexa&nbsp;
                feature/experience you are promoting in this campaign?</span>}>
                <div className='awsui-grid'>
                    <div className='awsui-row awsui-util-t-c'>
                        <div className='col-3'>
                            <Checkbox
                                id='modal.send-rcp-experience-for-approval.hintInvokeCorrectly.y'
                                checked={currentState.hintInvokeCorrectly === true}
                                onChange={onCheckboxChangeHandler}
                            > Yes </Checkbox>
                        </div>
                        <div className='col-3'>
                            <Checkbox
                                id='modal.send-rcp-experience-for-approval.hintInvokeCorrectly.n'
                                checked={currentState.hintInvokeCorrectly === false}
                                onChange={onCheckboxChangeHandler}
                            > No </Checkbox>
                        </div>
                    </div>
                </div>
            </FormField><br />

            <FormField label={
                <span>
                    Have you uploaded the testing video that includes the above testing steps were successfully executed? Video should be uploaded in&nbsp;
                    <ExternalLink href={alexaOdysseyRCPCardTestingHref}>Alexa Odyssey RCP Card Testing</ExternalLink> team drive.
                </span>
            }>
                <div className='awsui-grid'>
                    <div className='awsui-row awsui-util-t-c'>
                        <div className='col-3'>
                            <Checkbox
                                id='modal.send-rcp-experience-for-approval.uploadedTestingVideo.y'
                                checked={currentState.uploadedTestingVideo === true}
                                onChange={onCheckboxChangeHandler}
                            > Yes </Checkbox>
                        </div>
                        <div className='col-3'>
                            <Checkbox
                                id='modal.send-rcp-experience-for-approval.uploadedTestingVideo.n'
                                checked={currentState.uploadedTestingVideo === false}
                                onChange={onCheckboxChangeHandler}
                            > No </Checkbox>
                        </div>
                    </div>
                </div>
            </FormField><br />

            <FormField label='Please add the link to the testing video that has been uploaded to the “Alexa Odyssey RCP Card Testing” team drive.'>
                <Input id='modal.send-rcp-experience-for-approval.linkTestingVideo' onChange={onInputChangeHandler}  />
            </FormField><br />
            <FormField label='Please add the alias of the tester that tested this campaign (do not include @amazon.com)'>
                <Input id='modal.send-rcp-experience-for-approval.testerUserAlias' onChange={onInputChangeHandler}  />
            </FormField>
        </div>
    </Modal>;
};
