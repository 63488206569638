import { ExperienceType } from './Experience';

export enum FilterMetricFrequency {
    Week = 'WEEK',
    Day = 'DAY',
    Hour = 'HOUR',
}

export enum IFilterType {
    ActivationFilter = 'ActivationFilter',
    VisualFilter = 'VisualFilter',
    PhaticIntentUtteranceFilter = 'PhaticIntentUtteranceFilter',
    EviUtteranceFilter = 'EviUtteranceFilter',
    NluTargetingFilter = 'NluTargetingFilter',
    TimerFilter = 'TimerFilter',
    ContextualSegmentsFilter = 'ContextualSegmentsFilter',
    TemplateVariableFilter = 'TemplateVariableFilter',
    DayTimeRangeGuardrailsFilter = 'DayTimeRangeGuardrailsFilter',
    StatusFilter = 'StatusFilter',
    BullseyeFilter = 'BullseyeFilter',
    FeatureHintsFilter = 'FeatureHintsFilter',
    MonthOneJourneyFilter = 'MonthOneJourneyFilter',
    EverestJourneyFilter = 'EverestJourneyFilter',
    RowCountryFilter = 'RowCountryFilter',
    ContentFilter = 'ContentFilter',
    SpeakerUserRoleFilter = 'SpeakerUserRoleFilter',
    ExposureControlFilter = 'ExposureControlFilter',
    PromotedExperienceFilter = 'PromotedExperienceFilter',
    DomainInformationFilter = 'DomainInformationFilter',
    VisualCifFilter = 'VisualCifFilter',
    CapabilityFilter = 'CapabilityFilter',
    ALL_FILTERS = 'ALL_FILTERS'
}

export interface IFilterMetricData {
    passed: number;
    failed: number;
}

export interface IFilterMetric {
    period: string;
    reportedAt: number;
    data: {[key in IFilterType]?: IFilterMetricData};
}

export interface IRawFilterMetricsData {
    experienceId: string;
    experienceType: ExperienceType;
    periodType: FilterMetricFrequency;
    metrics: IFilterMetric[];
}
