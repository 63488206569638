import { CIF_ERROR_MESSAGES, TEMPLATE_PREFIX, TEMPLATE_SUFFIX, EMPTY_STRING, WILDCARD_REGEX, SSML_PREFIX, SSML_SUFFIX,
    REFERRAL_DATA_FOLLOW_LINK_TYPE, REFERRAL_DATA_FOLLOW_LINK_TARGET_ADDRESS, REFERRAL_DATA_FOLLOW_LINK_TARGET_PATH,
    REFERRAL_DATA_FOLLOW_LINK_PAYLOAD_RESOURCE_MAP, EXACT_MATCH_REGEX, REFERRAL_TYPE_KEY, TEMPLATE_PREFIX_WITHOUT_SLASH, TEMPLATE_SUFFIX_WITHOUT_SLASH } from '../constants/validatorConstants';
import { isStringEmpty, isListEmpty, isLiveExperience } from './stringAndMappingHelper';
import { INluOpportunityTargeting } from '../models/NluOpportunityTargeting';
import { ExperienceStatus, IExperience } from '../models/Experience';
import { CommonValidator } from './CommonValidator';
import { IManagementCard, isCardConfigured } from '../models/cifExperience/visualExperience/IManagementCard';
import _ from 'lodash';
import { IIntentTrigger, IOpportunityTrigger } from '../models/NluOpportunityTrigger';


export class CIFValidator {


    // Content Validation
    private static isDataValid(referralData?: string, message?: string) {
        if (referralData) {
            try {
                const data = JSON.parse(referralData);

                if (data[REFERRAL_TYPE_KEY] !== REFERRAL_DATA_FOLLOW_LINK_TYPE) {
                    throw Error(`Received unknown referral data type ${data[REFERRAL_TYPE_KEY]}`);
                } else if (data[REFERRAL_DATA_FOLLOW_LINK_TARGET_ADDRESS] === undefined) {
                    throw Error(`${REFERRAL_DATA_FOLLOW_LINK_TARGET_ADDRESS} key is not present`);
                } else if (data[REFERRAL_DATA_FOLLOW_LINK_TARGET_PATH] === undefined) {
                    throw Error(`${REFERRAL_DATA_FOLLOW_LINK_TARGET_PATH} key is not present`);
                } else if (data[REFERRAL_DATA_FOLLOW_LINK_PAYLOAD_RESOURCE_MAP] === undefined) {
                    throw Error(`${REFERRAL_DATA_FOLLOW_LINK_PAYLOAD_RESOURCE_MAP} key is not present`);
                }

                if (!new RegExp(EXACT_MATCH_REGEX).test(data[REFERRAL_DATA_FOLLOW_LINK_PAYLOAD_RESOURCE_MAP])) {
                    JSON.parse(data[REFERRAL_DATA_FOLLOW_LINK_PAYLOAD_RESOURCE_MAP]);
                }
            } catch (error) {
                throw new Error(`${message}: ${error}`);
            }
        }
    }

    static isReferralDataValid(referralData?: string) {
        CIFValidator.isDataValid(referralData, CIF_ERROR_MESSAGES.INVALID_REFERRAL_DATA);
    }

    static isRejectionReferralDataValid(rejectionReferralData?: string) {
        CIFValidator.isDataValid(rejectionReferralData, CIF_ERROR_MESSAGES.INVALID_REJECTION_REFERRAL_DATA);
    }

    static isCampaignIdValid(campaignId?: string) {
        if (!campaignId || campaignId?.length === 0) {
            throw new Error(CIF_ERROR_MESSAGES.INVALID_SONAR_CAMPAIGN);
        }
    }

    static isSonarTemplateJsonDataValid(sonarTemplateJson?: string){
        if(sonarTemplateJson){
            try{
                JSON.parse(sonarTemplateJson);
            }
            catch(error){
                throw new Error(`${CIF_ERROR_MESSAGES.INVALID_SONAR_TEMPLATE_JSON_PAYLOAD}: ${error}`);
            }
        }
    }

    static isUtteranceValid(utterance?: string, variables?: string[], state?: ExperienceStatus) {
        if (utterance) {
            if (utterance === ',') {
                return;
            }

            if (!utterance.startsWith(SSML_PREFIX) || !utterance.endsWith(SSML_SUFFIX)) {
                throw new Error(isLiveExperience(state) ? CIF_ERROR_MESSAGES.INVALID_SSML_LIVE : CIF_ERROR_MESSAGES.INVALID_SSML);
            }

            let resolvedUtterance = utterance;
            for (const variable of variables || []) {
                resolvedUtterance = resolvedUtterance.replace(new RegExp(getFormalizedVariableName(variable), 'g'), EMPTY_STRING);
            }

            if (new RegExp(WILDCARD_REGEX).test(resolvedUtterance)) {
                throw new Error(CIF_ERROR_MESSAGES.INVALID_UTTERANCE_TEMPLATE.replace('%s', resolvedUtterance));
            }

            CommonValidator.isValidSsml(utterance);
        }
    }

    static isEntireContentATemplateVariable(contentText?: string): boolean {
        if (contentText === undefined) {
            return false;
        }

        const indexOfSsmlPrefix = contentText.indexOf(SSML_PREFIX);
        const indexOfSsmlSuffix = contentText.indexOf(SSML_SUFFIX);
        const contentTextCopy = (indexOfSsmlPrefix !== -1 && indexOfSsmlSuffix !== -1)
            ? contentText.slice(indexOfSsmlPrefix + SSML_PREFIX.length, indexOfSsmlSuffix).trim()
            : contentText.trim();

        if (contentTextCopy.startsWith(TEMPLATE_PREFIX_WITHOUT_SLASH)
            && contentTextCopy.endsWith(TEMPLATE_SUFFIX_WITHOUT_SLASH)
            && contentTextCopy.indexOf(TEMPLATE_PREFIX_WITHOUT_SLASH, TEMPLATE_PREFIX_WITHOUT_SLASH.length) === -1) {
                return true;
        }

        return false;
    }

    // Devices Validation
    static isDevicesValid(devices?: string[]) {
        if (devices && devices.length === 0) {
            throw new Error(CIF_ERROR_MESSAGES.NO_DEVICE_ENABLED);
        }
    }
    // Card Validation
    static async isCardValid(card?: IManagementCard, useTouchActionTemplate?: boolean, overrideDefaultBackgroundImage?: boolean,
        acknowledgedBackgroundImage?: boolean, isCustomDomainIllustration?: boolean, allowDvpSourceForImages?: boolean) {
        if (!card) {
            throw new Error(CIF_ERROR_MESSAGES.INCOMPLETE_CARD_CONFIGURATION);
        }
        else {
            const configured = await isCardConfigured(card, useTouchActionTemplate || false, overrideDefaultBackgroundImage || false,
                acknowledgedBackgroundImage || false, isCustomDomainIllustration || false, allowDvpSourceForImages || false);
            if (!configured) {
                throw new Error(CIF_ERROR_MESSAGES.INCOMPLETE_CARD_CONFIGURATION);
            }
        }
    }

    // Checks if intent is blocked
    static isIntentBlocked(triggers: IOpportunityTrigger[], isEditing: boolean, experience?: IExperience) {
        const exceptionStatus = ['UNDER_REVIEW', 'LIVE', 'LIVE_TEN_PERCENT', 'LIVE_FIFTY_PERCENT', 'LIVE_HUNDRED_PERCENT', 'LAUNCHED'];
        const blockedIntents = ['SetNotificationIntent', 'VolumeUpIntent', 'VolumeDownIntent', 'SetVolumeIntent'];
        const showError = experience && !exceptionStatus.includes(experience.status);
        for (const eachTrigger of triggers) {
            if(blockedIntents.map(intent => intent.toLowerCase()).includes((eachTrigger as IIntentTrigger).bluIntent ?
                (eachTrigger as IIntentTrigger).bluIntent.toLowerCase() : '') && (isEditing || showError)) {
                return true;
            }
        }
        return false;
    }

    // Checks if intent is blocked
    static isVCIFIntentBlocked(trigger: IOpportunityTrigger) {
        if ((trigger as IIntentTrigger).bluIntent?.toLocaleLowerCase() === 'playmusicintent') {
            return true;
        }
        return false;
    }

    static compareReferralAndRejectionReferral(referralData?: string, rejectionReferralData?: string) {
        if (!referralData || !rejectionReferralData) {
            return false;
        }

        try {
            const referralDataJSON = JSON.parse(referralData);
            const rejectionReferralDataJSON = JSON.parse(rejectionReferralData);
            if (_.isEqual(referralDataJSON, rejectionReferralDataJSON)) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }

    }
    // Opportunity Triggers Validation
    static isNluOpportunityTargetingInclusionValid(nluOpportunityTargeting?: INluOpportunityTargeting, isVisualCIF: boolean = false) {
        if (nluOpportunityTargeting
            && isStringEmpty(nluOpportunityTargeting.bluIntent)
            && isListEmpty(nluOpportunityTargeting.publisherIdTargeting)
            && isListEmpty(nluOpportunityTargeting.categoryTargeting)) {

            throw new Error(CIF_ERROR_MESSAGES.MISSING_TRIGGER);
        }

        if (!isVisualCIF
            && nluOpportunityTargeting
            && nluOpportunityTargeting.bluIntent
            && nluOpportunityTargeting.bluIntent === 'WhatTimeIntent') {
                throw new Error('WhatTimeIntent is blocked at opportunity fulfillment level for ALL regions for Audio CIFs. Due to recent escalations, '
                    + 'CIF has blocked WhatTimeIntent from being used as an Opportunity Trigger by Odyssey CIF experiences. Any Odyssey '
                    + 'experience targeting this intent will not be served. No end date has been determined for this block.');
        }
    }

    static isNluOpportunityTargetingExclusionValid(nluOpportunityTargeting?: INluOpportunityTargeting) {
        if (nluOpportunityTargeting && !isListEmpty(nluOpportunityTargeting.targetUtterances)) {
            throw new Error(CIF_ERROR_MESSAGES.INVALID_EXCLUSION_UTTERANCE);
        }

        if (nluOpportunityTargeting && !isListEmpty(nluOpportunityTargeting.categoryTargeting)) {
            throw new Error(CIF_ERROR_MESSAGES.INVALID_EXCLUSION_CATEGORY);
        }

        if (nluOpportunityTargeting && !isListEmpty(nluOpportunityTargeting.publisherIdTargeting)) {
            throw new Error(CIF_ERROR_MESSAGES.INVALID_EXCLUSION_PUBLISHER_ID);
        }

        if (nluOpportunityTargeting && isListEmpty(nluOpportunityTargeting.categoryTargeting)) {
            throw new Error(CIF_ERROR_MESSAGES.MISSING_EXCLUSION_SLOT);
        }

    }


    // Remote Tags Validation
    static isRemoteSegmentNameValid(remoteSegmentName?: string) {
        if (isStringEmpty(remoteSegmentName)) {
            throw new Error(CIF_ERROR_MESSAGES.MISSING_TAG_NAME);
        }
    }

    static isRemoteSegmentDescriptionValid(remoteSegmentDescription?: string) {
        if (isStringEmpty(remoteSegmentDescription)) {
            throw new Error(CIF_ERROR_MESSAGES.MISSING_TAG_DESCRIPTION);
        }
    }
}

export const getFormalizedVariableName = (variableName: string) => {
    return `${TEMPLATE_PREFIX}${variableName}${TEMPLATE_SUFFIX}`;
};
