import React from 'react';
import { Select } from '@amzn/awsui-components-react';

export interface ISelectWrapperProps {
    id: string;
    disabled: boolean;
    selectedId?: string;
    options?: (Select.IOption | Select.Option | Select.OptionsGroup)[];
    validate: (input?: string) => void;
    onChange: (event: CustomEvent<Select.ChangeDetail>) => void;
}

export class SelectWrapper extends React.Component<ISelectWrapperProps> {
    render () {
        const { id, selectedId, options, disabled, validate, onChange } = this.props;
        let errorMessage= '';
        let isValid: boolean = true;

        if (!disabled) {
            try {
                validate(selectedId);
                isValid = true;
            } catch (error) {
                isValid = false;
                errorMessage = error.message;
            }
        }

        return <div>
            <Select
                controlId={id}
                id={id}
                disabled={disabled}
                selectedId={selectedId}
                options={options}
                invalid={!isValid}
                onChange={onChange} />
            <div className='awsui-row'>
                {errorMessage && <div className='col-12'>
                    <p className='awsui-util-status-negative'>{errorMessage}</p>
                </div>}
            </div>
        </div>;
    }
}
