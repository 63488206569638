import * as React from 'react';
import AudienceTypeSectionView from '../../../containers/sections/AudienceTypeSectionView';
import FeatureHintSectionView from '../../../containers/sections/FeatureHintSectionView';
import DomainTagsSectionView from '../../../containers/sections/DomainTagsSectionView';
import RemoteTagsSectionView from '../../../containers/sections/RemoteTagsSectionView';
import JourneySectionView from '../../../containers/sections/JourneySectionView';

export const VisualCIFAdvancedConfigurationTabView = () => {
    return <div>
        <AudienceTypeSectionView />
        <FeatureHintSectionView />
        <DomainTagsSectionView />
        <RemoteTagsSectionView />
        <JourneySectionView />
    </div>;
};
