import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../reducers/index';

interface AdminOnlyProps {
    isAdmin: boolean;
    children: JSX.Element | JSX.Element[];
}

const AdminOnly: React.FC<AdminOnlyProps> = (props: AdminOnlyProps) => {
    const {children, isAdmin} = props;
    if (isAdmin) {
        return <>{children}</>;
    }
    return <></>;
};

const mapStateToProps = ({ authenticationState }: AppState) => {
    const permissions = (authenticationState.permissions || []).concat(authenticationState.rcpPermissions || []);
    return {
        isAdmin: permissions.includes('ADMIN')
    };
};

export default connect(mapStateToProps)(AdminOnly);
