import * as React from 'react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { ColumnLayout, FormField, Spinner, Button, Select, Tooltip, Alert, Checkbox, Icon, Input } from '@amzn/awsui-components-react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate, IContentViewAttributes } from '../../models/ExperienceUpdateCandidate';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import {
    pushBusinessInfo,
    pushContentSectionAction,
    pushSubscriptionUpsellType,
    pushSuggestedFeature
} from '../../actions/experienceEditViewActions';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { LOCALE_TO_LANG, isRightToLeftLocale } from '../../constants/locales';
import { contentCharLimit } from '../../constants/componentConstants';
import { TextAreaWrapper } from '../../components/common/TextAreaWrapper';
import { CIFValidator } from '../../util/CIFValidator';
import { TextRenderDirection } from '../../models/htmlElementProps';
import { ExternalLink } from '../../components/common/LinkComponents';
import { isNullOrUndefined } from 'util';
import { BUSINESS_DOMAIN_DROPDOWN_OPTIONS, BUSINESS_VERTICAL_DROPDOWN_OPTIONS } from '../../constants/businessVerticalDomains';
import { IBusinessMetadata } from '../../models/BusinessInfo';
import { ActionType } from '../../models/ActionType';
import { INluInfo } from '../../models/NluInfo';
import { SelectWrapper } from '../../components/common/SelectWrapper';
import { SUBSCRIPTION_UPSELL_TYPE_OPTIONS } from '../../constants/subscriptionUpsellTypeOptions';
import { CommonValidator } from '../../util/CommonValidator';
import { SubscriptionUpsellType } from '../../models/SubscriptionUpsellType';


interface IBasicContentSectionViewState {
    forcedLtrRendering: boolean;
    cxGuidelinesCheckbox: boolean;
}

interface IBasicContentSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;

    onContentCXGuidelinesCheckedUpdate?: (checked?: boolean) => void;
}

export class BasicContentSectionView extends React.Component<IBasicContentSectionViewProps, IBasicContentSectionViewState> {
    constructor(props: IBasicContentSectionViewProps) {
        super(props);

        this.state = {
            forcedLtrRendering: false,
            cxGuidelinesCheckbox: false
        };
    }

    updateContent(contentType: 'content' | 'referral' | 'none', contentText?: string, referralQuestionContentText?: string,
        referralData?: string, rejectionReferralData?: string, actionType?: ActionType, sonarTemplateURI?: string, sonarTemplateJson?: string, campaignId?: string, checkCXGuidelines?: boolean) {
        const { dispatch } = this.props;

        const isEntireContentATemplateVariable = CIFValidator.isEntireContentATemplateVariable(contentText);

        dispatch(pushContentSectionAction(contentType, contentText, referralQuestionContentText,
            referralData, rejectionReferralData, actionType, sonarTemplateURI, sonarTemplateJson, campaignId, checkCXGuidelines, isEntireContentATemplateVariable));
    }

    updateSuggestedFeature(nluDomain?: string, nluIntent?: string, nluSlotName?: string[], featureUtterance?: string) {
        const { dispatch } = this.props;
        dispatch(pushSuggestedFeature(nluDomain, nluIntent, nluSlotName, featureUtterance));

        this.forceUpdate();
    }

    updateBusinessInfo(vertical?: string, domain?: string) {
        const { dispatch } = this.props;
        dispatch(pushBusinessInfo(vertical, domain));
    }

    updateSubscriptionUpsellType(subscriptionUpsellType?: SubscriptionUpsellType) {
        const { dispatch } = this.props;
        dispatch(pushSubscriptionUpsellType(subscriptionUpsellType));
    }

    returnDomainOptions = (businessInfo?: IBusinessMetadata): Select.Option[] => {
        if (businessInfo && businessInfo.vertical && BUSINESS_DOMAIN_DROPDOWN_OPTIONS[businessInfo.vertical]) {
           return BUSINESS_DOMAIN_DROPDOWN_OPTIONS[businessInfo.vertical];
        } else {
            return [];
        }
    }

    shouldRenderTextRightToLeft(locale: string): boolean {
        const { forcedLtrRendering } = this.state;

        return isRightToLeftLocale(locale) && !forcedLtrRendering;
    }

    onTextDirectionSwitchButtonClicked() {
        this.setState((prevState) => {
            return {
                forcedLtrRendering: !prevState.forcedLtrRendering
            };
        });
    }

    onCXGuidelinesConfirmation(checked: boolean, onContentCXGuidelinesCheckedUpdate?: (checked?: boolean) => void) {
        this.setState({ cxGuidelinesCheckbox: checked });
        if (onContentCXGuidelinesCheckedUpdate) {
            onContentCXGuidelinesCheckedUpdate(checked);
        }
    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate, onContentCXGuidelinesCheckedUpdate } = this.props;
        const { forcedLtrRendering } = this.state;

        const forceRenderLtrButton = <Tooltip
            position='top'
            text='Use RTL for natural Arabic text direction; LTR to validate SSML tags'>
            <Button
                id='button.rtl-and-ltr'
                icon={forcedLtrRendering ? 'caret-right' : 'caret-left'}
                onClick={this.onTextDirectionSwitchButtonClicked.bind(this)} >
                Switch to {forcedLtrRendering ? 'Right-to-Left (RTL)' : 'Left-to-Right (LTR)'}
            </Button>
        </Tooltip>;

        if (!experience) {
            return <FormSectionView title={'Content'}>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const contentVariables: string[] = ((isEditing && updateCandidate)
            ? updateCandidate.getTemplateVariables()
            : experience.templateVariables || []).map(v => v.variableName);

        const contentData: IContentViewAttributes = (isEditing && updateCandidate)
            ? updateCandidate.getContent()
            : ExperienceUpdateCandidate.extractContent(experience);

        const enableVoiceCif = (isEditing && updateCandidate)
            ? updateCandidate.getEnableVoiceCif() || false
            : ExperienceUpdateCandidate.extractEnableVoiceCif(experience) || false;

        const shouldDisableInput = !isEditing || isUpdating || !enableVoiceCif;

        const targetLocale = ((isEditing && updateCandidate)
            ? updateCandidate.getRegion().locale
            : experience.locale) || 'en_US';

        const textDirection: TextRenderDirection = this.shouldRenderTextRightToLeft(targetLocale) ? 'rtl' : 'ltr';

        const businessInfo: IBusinessMetadata | undefined = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
            ? updateCandidate.getBusinessInfo()
            : experience.businessInfo);

        const subscriptionUpsellType: SubscriptionUpsellType | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getSubscriptionUpsellType()
            : experience.subscriptionUpsellType;

        const nluData: INluInfo | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getSuggestedFeature()
            : experience.suggestedFeature;


        return <div lang={LOCALE_TO_LANG[targetLocale]} spellCheck={true} >
            <FormSectionView
                title={
                    <div className='awsui-util-action-stripe'>
                        <div className='awsui-util-action-stripe-title'>
                            <h2 className='awsui-util-d-ib'>
                                Content
                            </h2>
                        </div>
                    </div>}
                description='Content specified below is what Alexa will say to the
                    customer when your use case is triggered.'>
                <div>
                    {isNullOrUndefined(experience.id)
                        ? <>
                            <Alert type='info' className='awsui-util-mb-l'>
                                Please read the <ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/CIF+CX+Guidelines+and+Recommendations'}
                                    >CIF CX Guidelines and Recommendations</ExternalLink> documentation.
                                <Checkbox
                                    id={'experience.confirm-read-cx-guidelines'}
                                    controlId={'experience.confirm-read-cx-guidelines'}
                                    checked={contentData.checkCXGuidelines || false}
                                    onChange={(e) => {
                                        this.onCXGuidelinesConfirmation(e.detail.checked, onContentCXGuidelinesCheckedUpdate);
                                        this.updateContent('content', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, e.detail.checked);
                                    }}
                                ><b>I confirm that I have read and will follow the CX Guidelines</b></Checkbox>
                                <div><div><span className='awsui-util-status-negative'><Icon name='status-warning' />  This box must be checked</span></div><span /></div>
                            </Alert>
                        </>
                        : <>
                            <Alert type='info' visible={isEditing} className='awsui-util-mb-l'>
                                Please read the <ExternalLink href={'https://wiki.labcollab.net/confluence/display/Doppler/CIF+CX+Guidelines+and+Recommendations'}
                                    >CIF CX Guidelines and Recommendations</ExternalLink> documentation.
                            </Alert>
                        </>}
                </div>

                <Alert type='error' visible={!enableVoiceCif} className='awsui-util-mb-l'>
                    You must disable visual promptless toggle under <i>Advanced Configuration -&gt; VIMO</i> to enable content fields.
                </Alert>


                {isRightToLeftLocale(targetLocale) && forceRenderLtrButton}

                <FormField label={<RequiredField fieldName='Content' />} description='Spoken content in SSML' >
                    <TextAreaWrapper
                        id='experience.content'
                        textRenderDirection={textDirection}
                        readonly={shouldDisableInput}
                        value={!enableVoiceCif ? '' : contentData.contentText}
                        characterLimit={contentCharLimit}
                        onChange={(input: string) => {
                            this.updateContent('content', input.trim());
                        }}
                        validate={(utterance) => CIFValidator.isUtteranceValid(utterance, contentVariables, experience.status)} />
                </FormField>
                <ColumnLayout columns={4}>
                    <div data-awsui-column-layout-root='true'>
                        <FormField
                            label={<RequiredField fieldName='Intent Name' />}
                            description='NLU Intent of the feature being suggested'>
                            <Input
                                readonly={shouldDisableInput}
                                placeholder='ex. GetWeatherForcastIntent'
                                value={nluData ? nluData.nluIntent : ''}
                                onChange={(e) => this.updateSuggestedFeature(undefined, e.detail.value.trim())}
                                controlId='experience.suggestedFeature.nluIntent'
                                id='experience.suggestedFeature.nluIntent' />
                        </FormField>
                        <FormField
                            label={<RequiredField fieldName='Vertical' />}
                            description='L1 Vertical pertaining to experience'>
                            <Select
                                id='experience.suggestedFeature.vertical'
                                controlId='experience.suggestedFeature.vertical'
                                placeholder='Select vertical'
                                options={BUSINESS_VERTICAL_DROPDOWN_OPTIONS}
                                disabled={shouldDisableInput}
                                selectedOption={{
                                    id: businessInfo ? (businessInfo.vertical ? businessInfo.vertical : '') : '',
                                    label: businessInfo ? (businessInfo.vertical ? businessInfo.vertical : '') : ''
                                }}
                                onChange={(e) => this.updateBusinessInfo(e.detail.selectedId, undefined)} />
                        </FormField>
                        <FormField
                            label={<RequiredField fieldName='Domain' />}
                            description='L2 Domain pertaining to experience'>
                            <Select
                                id='experience.suggestedFeature.domain'
                                controlId='experience.suggestedFeature.domain'
                                placeholder='Select domain'
                                options={this.returnDomainOptions(businessInfo)}
                                disabled={shouldDisableInput || isNullOrUndefined(businessInfo) || isNullOrUndefined(businessInfo.vertical)}
                                selectedOption={{
                                    id: businessInfo ? (businessInfo.domain ? businessInfo.domain : '') : '',
                                    label: businessInfo ? (businessInfo.domain ? businessInfo.domain : '') : ''
                                }}
                                onChange={(e) => this.updateBusinessInfo(undefined, e.detail.selectedId)} />
                        </FormField>
                        <FormField
                            label={<RequiredField fieldName='Subscription Upsell Type' />}
                            description='Upsell metadata tag'>
                            <SelectWrapper
                                id='experience.suggestedFeature.subscriptionUpsellType'
                                options={SUBSCRIPTION_UPSELL_TYPE_OPTIONS}
                                disabled={shouldDisableInput}
                                selectedId={subscriptionUpsellType}
                                validate={(data) => {
                                    CommonValidator.isSubscriptionUpsellFieldValid(data);
                                }}
                                onChange={(e) => this.updateSubscriptionUpsellType(e.detail.selectedId as SubscriptionUpsellType)} />
                        </FormField>
                    </div>
                </ColumnLayout>
            </FormSectionView>
        </div>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(BasicContentSectionView);
