import * as React from 'react';
import { Component } from 'react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { Tabs } from '@amzn/awsui-components-react';
import { CifInjectionTabView } from './tabs/CifInjectionTabView';
import { PlacementsTabView } from './tabs/PlacementsTabView';
import { CustomerTargetingTabView } from './tabs/CustomerTargetingTabView';
import { ExperienceDetailsTabView } from './tabs/ExperienceDetailsTabView';
import { TestingTabView } from './tabs/TestingTabView';
import { AdvancedConfigurationTabView } from './tabs/AdvancedConfigurationTabView';
import { EventTrailTabView } from './tabs/EventTrailTabView';

export class CifExperienceTabsView extends Component<{
    experience: IFlattenedExperience,
    activeTabId: string | undefined,
    isAdmin: boolean,
    onTabChange?: (detail: Tabs.ChangeDetail) => void}
> {

    render() {
        const { experience, activeTabId, isAdmin, onTabChange } = this.props;
        return <Tabs
                tabs={getCifExperienceTabs(experience, isAdmin)}
                activeTabId={activeTabId}
                onChange={(e) => onTabChange ? onTabChange(e.detail) : null} />;
    }
}

const getCifExperienceTabs = (experience: IFlattenedExperience, isAdmin: boolean): Tabs.Tab[] => {
    return [{
        label: 'CIF Injection',
        id: 'cif-injection',
        content: <CifInjectionTabView />
    }, {
        label: 'Placements',
        id: 'placements',
        content: <PlacementsTabView experience={experience} />
    }, {
        label: 'Customer Targeting',
        id: 'customer-targeting',
        content: <CustomerTargetingTabView experience={experience} />
    }, {
        label: 'Experience Details',
        id: 'experience-details',
        content: <ExperienceDetailsTabView isAdmin={isAdmin} experience={experience}/>
    }, {
        label: 'Testing',
        id: 'testing',
        content: <TestingTabView experience={experience} />
    }, {
        label: 'Advanced Configuration',
        id: 'advanced-configuration',
        content: <AdvancedConfigurationTabView experience={experience} />
    }, {
        label: 'Event Trail',
        id: 'eventTrail',
        content: <EventTrailTabView />
    }];
};
