import React from 'react';
import moment from 'moment';
import { formatDate as formatDateUtil, fromTimestampToFormattedDateTime } from '../../util/stringAndMappingHelper';
import { IAlexaLabMetrics } from '../../models/AlexaLabMetrics';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { LinkedAlexaLab } from '../common/LinksRenderHelper';
import { getAllIntents } from '../../components/metrics/ExperienceMetricsIntentPerformance';
import { extractMarketplaceFromFlattenedExperience } from '../../util/stringAndMappingHelper';
import { MARKETPLACE_TO_REALM } from '../../constants/marketplaces';

export interface IReportTimestamp {
    startDate: string;
    endDate: string;
    refreshTime: string;
}

const formatDate = (dateString?: string): string => {
    return dateString ? formatDateUtil(dateString) : '-';
};

export const formatDateAndTime = (timestamp?: number): string => {
    return timestamp ? fromTimestampToFormattedDateTime(timestamp) :  '-';
};

export const getExperienceMetricsReportTimestamp = ({ id, experience, alexaLabMetrics }: { id?: string, experience: IFlattenedExperience, alexaLabMetrics: IAlexaLabMetrics }): IReportTimestamp => {
    const configuredIntents = getAllIntents(experience);
    const filteredMetrics = alexaLabMetrics.metrics.filter(metric =>
        metric.dimension.type === 'NO_DIMENSION'
        || configuredIntents.includes(metric.dimension.value!)
    );

    const latestReportMetric = filteredMetrics.sort((metricA, metricB) => {
        const metricAStartTime = moment(metricA.metadata.reportStartTime);
        const metricAEndTime = moment(metricA.metadata.reportEndTime);
  	    const metricBStartTime = moment(metricB.metadata.reportStartTime);
        const metricBEndTime = moment(metricB.metadata.reportEndTime);

        if(metricAEndTime.isBefore(metricBEndTime)) {
            return -1;
        } else if(metricAEndTime.isAfter(metricBEndTime)) {
            return 1;
        } else {
            if (metricAStartTime.isBefore(metricBStartTime)) {
                return 1;
            } else if (metricAStartTime.isAfter(metricBStartTime)) {
                return -1;
            }
        }

        return 0;
    })[filteredMetrics.length - 1];

    const latestRefreshTimeMetric = filteredMetrics.sort((metricA, metricB) => {
        if (metricA.metadata.apiExecutionTime < metricB.metadata.apiExecutionTime) {
            return -1;
        } else if (metricA.metadata.apiExecutionTime > metricB.metadata.apiExecutionTime) {
            return 1;
        }

        return 0;
    })[filteredMetrics.length - 1];

    const startDate = formatDate(latestReportMetric?.metadata.reportStartTime);
    const endDate = formatDate(latestReportMetric?.metadata.reportEndTime);
    const refreshTime = formatDateAndTime(latestRefreshTimeMetric?.metadata.apiExecutionTime);

    return {startDate, endDate, refreshTime};
};

export const ExperienceMetricsReportTimestamp = ({ id, experience, alexaLabMetrics }: { id?: string, experience: IFlattenedExperience, alexaLabMetrics?: IAlexaLabMetrics }) => {
    if (!experience.nluOpportunityTargetings) {
        return <div></div>;
    }

    const marketplace = extractMarketplaceFromFlattenedExperience(experience);
    const realm = MARKETPLACE_TO_REALM[marketplace];
    if (!alexaLabMetrics) {
        return <LinkedAlexaLab
                    weblab={experience.weblab ? experience.weblab.name : undefined}
                    realm={realm}
                />;
    }

    const { startDate, endDate, refreshTime } = getExperienceMetricsReportTimestamp({id, experience, alexaLabMetrics});

    return <div id={id}>
        <LinkedAlexaLab
            weblab={experience.weblab ? experience.weblab.name : undefined}
            realm={realm}
        />
        <div className='awsui-util-status-inactive'>{`Latest report date range: ${startDate} to ${endDate} - Refreshed: ${refreshTime}`}</div>
    </div>;
};
