import * as React from 'react';
import { ExperienceFilterType, EXPERIENCE_FILTERS } from '../../models/Guardrails';
import { FormSectionView } from '../../components/common/FormSectionView';
import { Checkbox, ColumnLayout, Spinner } from '@amzn/awsui-components-react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { pushOverrideFiltersSectionAction } from '../../actions/experienceEditViewActions';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';

interface IOverrideFiltersSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

interface IOverrideFiltersSectionViewState {
    overrideFilters: ExperienceFilterType[];
}

export class OverrideFiltersSectionView extends
    React.Component<IOverrideFiltersSectionViewProps, IOverrideFiltersSectionViewState> {

    constructor(props: IOverrideFiltersSectionViewProps) {
        super(props);
        this.state = {overrideFilters: props.experience?.guardrails?.overrideSelectiveFilters || []};
    }

    updateOverrideFilters(overrideFilters: ExperienceFilterType[]) {
        const { dispatch } = this.props;
        dispatch(pushOverrideFiltersSectionAction(overrideFilters));
        this.setState({overrideFilters});
    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate } = this.props;

        if (!experience) {
            return <FormSectionView title={'Override Filters'}>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const overrideFilters: ExperienceFilterType[] = ExperienceUpdateCandidate.deepClone(
            (isEditing && updateCandidate)
                ? updateCandidate.getOverrideFilters() || []
                : experience.guardrails?.overrideSelectiveFilters || []);

        const shouldDisableInput = !isEditing || isUpdating;

        const isAllFiltersOverridden: boolean = overrideFilters.includes(ExperienceFilterType.ALL_FILTERS);

        return <FormSectionView
            title='Overriding Filters'
            description='Choose which filters will be overridden for the testing accounts specified above.'
        >
            <div className='awsui-util-mb-xxl'>
                <Checkbox
                    id='experience.override-all-filters'
                    controlId='experience.override-all-filters'
                    disabled={shouldDisableInput}
                    checked={isAllFiltersOverridden}
                    onChange={(e) => {
                        this.updateOverrideFilters(e.detail.checked
                            ? overrideFilters.concat([ExperienceFilterType.ALL_FILTERS])
                            : overrideFilters.filter(filter => filter !== ExperienceFilterType.ALL_FILTERS));
                    }}
                    description='Bypass all filters'
                >Override ALL filters</Checkbox>
            </div>
            <hr />
            <ColumnLayout columns={3}>
                <div data-awsui-column-layout-root='true'>
                    {(Object.keys(EXPERIENCE_FILTERS) as ExperienceFilterType[])
                        .filter(type => type !== ExperienceFilterType.ALL_FILTERS)
                        .map((type, index) =>
                            <Checkbox
                                id={`experience.override-filter-${type}`}
                                controlId={`experience.override-filter-${type}`}
                                disabled={shouldDisableInput || isAllFiltersOverridden}
                                checked={!isAllFiltersOverridden && overrideFilters.includes(type)}
                                key={index}
                                description={EXPERIENCE_FILTERS[type]}
                                onChange={(e) => {
                                    this.updateOverrideFilters(e.detail.checked
                                        ? overrideFilters.concat([type])
                                        : overrideFilters.filter(filter => filter !== type));
                                }}
                            >{type}</Checkbox>
                        )}
                </div>
            </ColumnLayout>
        </FormSectionView>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(OverrideFiltersSectionView);
