import * as React from 'react';
import { ColumnLayout, FormSection, Table, TableFiltering, TablePageSizeSelector, TablePagination, TablePreferences, TableSelection, TableSorting } from '@amzn/awsui-components-react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from '../../../reducers';
import { NoMatchDisplay } from '../../NoMatchDisplay';
import { COLUMN_DEFINITIONS, SORTABLE_COLUMNS } from '../../../constants/v2/providerVersionsTableColumnConfigurations';
import { setCompareVersionsAction } from '../../../actions/spiProviderOnboardingActions';
import { IProviderConfig, IVersionedObject } from '../../../models/spiProviderConfig/ISpiProviderOnboarding';
import { getDiffLines } from '../../../util/spiProviderOnboardingHelper';
import DiffLine from './DiffLine';

const ProviderVersionsTable: React.FC = () => {
    const { providerId } = useParams<{ providerId: string }>();
    const { isLoading, versionHistory, currentProviderConfig, compareVersions} = useSelector((state: AppState) => state.spiProviderOnboardingState);
    const dispatch = useDispatch();

    let oldConfigJSON;
    let newConfigJSON;
    let diffLines;
    let versionId1;
    let versionId2;

    if (compareVersions[0] && compareVersions[1]) {

        const version1 = compareVersions[1] as IVersionedObject<IProviderConfig>;
        const version2 = compareVersions[0] as IVersionedObject<IProviderConfig>;

        const config1 = version1.object as IProviderConfig;
        const config2 = version2.object as IProviderConfig;

        versionId1 = version1.versionId;
        versionId2 = version2.versionId;

        oldConfigJSON = JSON.stringify(config1,null,2);
        newConfigJSON = JSON.stringify(config2,null,2);


        diffLines = getDiffLines(oldConfigJSON, newConfigJSON);
    }

    const sortedVersionHistory = Object.values(versionHistory[currentProviderConfig?.providerId ?? providerId])
        .sort((a, b) => {
            const aDate = new Date(a.lastModified);
            const bDate = new Date(b.lastModified);
            return bDate.getTime() - aDate.getTime();
        });

    return (
        <div>
            {diffLines &&
                <ColumnLayout columns={2}>
                    <div data-awsui-column-layout-root='true'>
                        <FormSection
                            id='version1'
                            header={diffLines &&
                                <div>
                                    <i className='awsui-text-secondary'>Version Id: </i>
                                    {versionId1}
                                </div>
                            }
                        >
                            <pre style = {{ flex: 1, padding: '10px', whiteSpace: 'pre-wrap' }}>
                                {diffLines.map((line, index) => (
                                    <DiffLine key={index} line={line} type='old' />
                                    ))}
                            </pre>
                        </FormSection>
                        <FormSection
                            id='version2'
                            header={diffLines &&
                                <div>
                                    <i className='awsui-text-secondary'>Version Id: </i>
                                    {versionId2}
                                </div>
                            }
                        >
                            <pre style = {{ flex: 1, padding: '10px', whiteSpace: 'pre-wrap' }}>
                                {diffLines.map((line, index) => (
                                    <DiffLine key={index} line={line} type='new' />
                                    ))}
                            </pre>
                        </FormSection>
                    </div>
                </ColumnLayout>
            }
            <Table
                id='table.providerVersions'
                columnDefinitions={COLUMN_DEFINITIONS}
                header={
                <div>
                    <h2>Version History</h2>
                    <i className='awsui-text-secondary'>Select Two Versions to Compare</i>
                </div>
                }
                items={sortedVersionHistory}
                loadingText='Loading Version History'
                loading={isLoading}
                empty={
                    <div className='awsui-util-pt-xs awsui-util-mb-xs'>
                        <b>
                            No Versions
                        </b>
                    </div>
                }
                noMatch={<NoMatchDisplay />}
                resizableColumns={true}
                stickyHeader={false}
                >
                <TableFiltering
                    filteringPlaceholder='Find Version by ID'
                    filteringCountTextFunction={count => `${count} ${count === 1 ? 'match' : 'matches'}`}
                />
                <TableSorting
                    sortableColumns={SORTABLE_COLUMNS}
                />
                <TableSelection
                        selectedItems={compareVersions}
                        trackBy='versionId'
                        keepSelection={true}
                        onSelectionChange={e => {
                            const newSelection = e.detail.selectedItems;
                            if (newSelection.length <= 2 ) {
                                dispatch(setCompareVersionsAction(newSelection));
                            } else {
                                dispatch(setCompareVersionsAction(newSelection.slice(0,2)));
                            }
                        }}
                    ></TableSelection>
                <TablePagination pageSize={40} />
                <TablePreferences
                    title='Preferences'
                    confirmLabel='Confirm'
                    cancelLabel='Cancel'
                >
                    <TablePageSizeSelector
                        title='Page size'
                        options={[
                            { value: 40, label: '40 items' },
                            { value: 60, label: '60 items' },
                            { value: 80, label: '80 items' },
                            { value: 100, label: '100 items' }
                        ]}
                    />
                </TablePreferences>
            </Table>
        </div>
    );
};
export default ProviderVersionsTable;