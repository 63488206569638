import { Action, Dispatch } from 'redux';

import * as SuggestionsGateway from '../api/suggestionsGateway';
import { UNSET_SUGGESTIONS, REQUESTED_SUGGESTIONS, RECEIVED_SUGGESTIONS, ERROR_FETCHING_SUGGESTIONS } from '../constants/homeCardSuggestionsListViewActionTypes';
import { IHomeCardSuggestion, ISuggestionPage } from '../models/HomeCardSuggestion';


export interface ILoadSuggestionsAction extends Action {
    suggestions?: IHomeCardSuggestion[];
    lastEvaluatedKey?: string;
    error?: Error;
}

export const requestedSuggestions = (): ILoadSuggestionsAction => {
    return {
        type: REQUESTED_SUGGESTIONS
    };
};

export const receivedSuggestions = (suggestions: IHomeCardSuggestion[], lastEvaluatedKey?: string): ILoadSuggestionsAction => {
    return {
        type: RECEIVED_SUGGESTIONS,
        lastEvaluatedKey,
        suggestions
    };
};

export const errorFetchingSuggestions = (error: Error): ILoadSuggestionsAction => {
    return {
        type: ERROR_FETCHING_SUGGESTIONS,
        error
    };
};

export const unsetSuggestions = (): ILoadSuggestionsAction => {
    return {
        type: UNSET_SUGGESTIONS
    };
};

export const getSuggestionsAction = (limit: number, exclusiveStartKey?: string) => async (dispatch: Dispatch) => {
    if (!exclusiveStartKey) {
        dispatch(requestedSuggestions());
    }
    try {
        const data: ISuggestionPage = await SuggestionsGateway.getSuggestionsPaginate(limit, exclusiveStartKey);
        dispatch(receivedSuggestions(data.suggestions, data.lastEvaluatedKey ? encodeURI(data.lastEvaluatedKey) : data.lastEvaluatedKey));
    } catch (error) {
        dispatch(errorFetchingSuggestions(error));
    }
};
