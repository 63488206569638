import { AnyAction, Dispatch } from 'redux';
import { IMonitoredExperiments } from '../models/monitoredExperiments/MonitoredExperiments';
import { getMonitoredExperiments, putMonitoredExperiments } from '../api/monitoredExperimentsApi';
import {
    LOADED_MONITORED_EXPERIMENTS,
    LOADING_MONITORED_EXPERIMENTS,
    ERROR_LOADING_MONITORED_EXPERIMENTS,
    UPDATED_MONITORED_EXPERIMENTS,
    UPDATING_MONITORED_EXPERIMENTS,
    ERROR_UPDATING_MONITORED_EXPERIMENTS
} from '../constants/monitoredExperimentsActionTypes';

export const updatingMonitoredExperiments: AnyAction = {
    type: UPDATING_MONITORED_EXPERIMENTS
};

export const updatedMonitoredExperiments: AnyAction = {
    type: UPDATED_MONITORED_EXPERIMENTS
};

export const loadingMonitoredExperiments: AnyAction = {
    type: LOADING_MONITORED_EXPERIMENTS
};

export const loadedMonitoredExperiments = (monitoredExperiments?: IMonitoredExperiments): AnyAction => {
    return {
        type: LOADED_MONITORED_EXPERIMENTS,
        monitoredExperiments
    };
};

export const errorLoadingMonitoredExperiments = (error: Error): AnyAction => {
    return {
        type: ERROR_LOADING_MONITORED_EXPERIMENTS,
        error
    };
};

export const errorUpdatingMonitoredExperiments = (error: Error): AnyAction => {
    return {
        type: ERROR_UPDATING_MONITORED_EXPERIMENTS,
        error
    };
};

export const updateMonitoredExperimentsAction = (monitoredExperiments: IMonitoredExperiments) => async (dispatch: Dispatch) => {
    dispatch(updatingMonitoredExperiments);

    try {
        await putMonitoredExperiments(monitoredExperiments);
        dispatch(updatedMonitoredExperiments);
    } catch (error) {
        dispatch(errorUpdatingMonitoredExperiments(error as Error));
    }
};

export const getMonitoredExperimentsAction = () => async (dispatch: Dispatch) => {
    dispatch(loadingMonitoredExperiments);

    try {
        const monitoredExperiments = await getMonitoredExperiments();
        dispatch(loadedMonitoredExperiments(monitoredExperiments));
    } catch (error) {
        dispatch(errorLoadingMonitoredExperiments(error as Error));
    }
};
