import * as React from 'react';
import { Component } from 'react';

import { Tiles } from '@amzn/awsui-components-react';
import { FormSectionView } from '../../common/FormSectionView';
import { ExperienceType } from '../../../models/Experience';

export class ExperienceTypeSectionView extends Component<{
    selectedExperienceType?: ExperienceType
    disableTypeSelection: boolean
    onExperienceChange?: (type: string) => void
    permissions?: string[]
    userAlias?: string
    mhcPermissions?: string[]
    rcpPermissions?: string[]
    isQuickCreate?: boolean
    isVisualCIF?: boolean
}> {

    private getExperienceTypeTileItems(): Tiles.TileDefinition[] {
        const { selectedExperienceType, disableTypeSelection, isQuickCreate, mhcPermissions, rcpPermissions, userAlias } = this.props;
        const types: Tiles.TileDefinition[] = [
            {
                controlId: 'BasicCIF',
                label:
                <div>
                    <div style={{marginLeft:'35px'}}>
                        <img src='/audio-cif.svg' alt='Conversation Icon' height='64' />
                    </div>
                    <br/>
                    <b> Audio CIF </b>
                </div>,
                value: 'BasicCIF',
                disabled: (selectedExperienceType !== 'CIF' || !isQuickCreate) && disableTypeSelection,
                description:
                <ul>
                    <li> For headless and multi-modal devices </li>
                    <li> Suggestion after Alexa completes a conversation with the customer </li>
                    <li> Text-to-speech content only </li>
                </ul>
            },
            {
                controlId: 'CIF',
                label:
                <div>
                    <div style={{marginLeft:'35px'}}>
                        <img src='/advanced-audio-cif.svg' alt='Conversation Icon' height='64' />
                    </div>
                    <br/>
                    <b> Advanced Audio CIF </b>
                </div>,
                value: 'CIF',
                disabled: (selectedExperienceType !== 'CIF' || isQuickCreate) && disableTypeSelection,
                description:
                <ul>
                    <li> Text-to-speech content </li>
                    <li> Yes or No content </li>
                    <li> Content variables and more </li>
                </ul>
            },
            {
                controlId: 'AppNotification',
                label:
                <div>
                    <div style={{marginLeft:'30px'}}>
                        <img src='/app-push-notification.svg' alt='Cellphone Icon' height='64' />
                    </div>
                    <br/>
                    <b> App Push Notification </b>
                </div>,
                value: 'AppNotification',
                disabled: selectedExperienceType !== 'AppNotification' && disableTypeSelection,
                description:
                <ul>
                    <li> For Alexa mobile app or Alexa for PC </li>
                    <li> One time or recurring every week </li>
                    <li> Pre-defined Bullseye targeting or SNS topic </li>
                </ul>
            },
            {
                controlId: 'DeviceNotification',
                label:
                <div>
                    <div style={{marginLeft:'15px'}}>
                        <img src='/device-notification.svg' alt='Alexa Echo Dot Icon' height='64' />
                    </div>
                    <br/>
                    <b> Device Notification </b>
                </div>,
                value: 'DeviceNotification',
                disabled: selectedExperienceType !== 'DeviceNotification' && disableTypeSelection,
                description:
                <ul>
                    <li> Yellow light ring </li>
                    <li> One time or recurring every week </li>
                    <li> Pre-defined Bullseye targeting or SNS topic </li>
                </ul>
            }
        ];

        if ((mhcPermissions && (mhcPermissions.includes('ADMIN') || mhcPermissions.includes('OPERATOR'))) || (userAlias && userAlias === 'svc-poems-test-user')) {
            types.push(
                {
                    controlId: 'MobileHomeCard',
                    label:
                    <div>
                        <div style={{marginLeft:'25px'}}>
                            <img src='/mobile-home-card.svg' alt='Cellphone Icon' height='64' />
                        </div>
                        <br/>
                        <b> Mobile Home Card </b>
                    </div>,
                    value: 'MobileHomeCard',
                    disabled: selectedExperienceType !== 'MobileHomeCard' && disableTypeSelection,
                    description:
                    <ul>
                        <li> For Alexa mobile app </li>
                        <li> Informational cards on the home channel </li>
                    </ul>
                }
            );
        }
        types.push(
            {
                controlId: 'VisualCIF',
                label:
                <div>
                    <div style={{marginLeft:'20px'}}>
                        <img src='/visual-cif.svg' alt='Alexa Multimodal Icon' height='64' />
                    </div>
                    <br/>
                    <b> Visual CIF </b>
                </div>,
                value: 'VisualCIF',
                disabled: selectedExperienceType !== 'CIF' && disableTypeSelection,
                description:
                <ul>
                    <li> For multi-modal devices </li>
                    <li> Suggestion after Alexa completes a conversation with the customer </li>
                    <li> Voice or touch interaction </li>
                </ul>
            }
        );

        if ((rcpPermissions && (rcpPermissions.includes('ADMIN') || rcpPermissions.includes('OPERATOR')))) {
            types.push(
                {
                    controlId: 'RotatingContentPanel',
                    label:
                    <div>
                        <div style={{ marginLeft:'20px' }}>
                            <img src='/rcp-card.svg' alt='Alexa Multimodal Icon' height='64' />
                        </div>
                        <br/>
                        <b> Rotating Content Panel </b>
                    </div>,
                    value: 'RotatingContentPanel',
                    disabled: selectedExperienceType !== 'RotatingContentPanel' && disableTypeSelection,
                    description:
                    <ul>
                        <li>For multi-modal devices</li>
                        <li>Voice or touch interaction</li>
                        <li>Displays when device is idle</li>
                    </ul>
                }
            );
        }

        return types;
    }

    private fromSelectedTypeToWorkflow(type?: ExperienceType, isQuickCreate?: boolean, isVisualCIF?: boolean) {
        if (isQuickCreate) {
            return 'BasicCIF';
        } else if (isVisualCIF) {
            return 'VisualCIF';
        }

        return type?.toString();
    }

    render() {
        const { selectedExperienceType, onExperienceChange, isQuickCreate, isVisualCIF } = this.props;

        return <FormSectionView title='Experience Type'>
            <Tiles
                id='experience.type-tiles'
                items={this.getExperienceTypeTileItems()}
                value={this.fromSelectedTypeToWorkflow(selectedExperienceType, isQuickCreate, isVisualCIF)}
                columns={3}
                onChange={(e) => onExperienceChange ? onExperienceChange(e.detail.value) : null} />
        </FormSectionView>;
    }
}
