import * as React from 'react';
import {Wizard} from '@amzn/awsui-components-react';

import ActivationDatesSectionView from '../sections/ActivationDatesSectionView';
import ContentVariablesSectionView from '../sections/ContentVariablesSectionView';
import DataSourceTypeSectionView from '../sections/DataSourceTypeSectionView';
import DayTimeWindowSectionView from '../sections/DayTimeWindowSectionView';
import NotificationSectionView from '../sections/NotificationSectionView';
import RegionSectionView from '../sections/RegionSectionView';
import SuggestedFeatureSectionView from '../sections/SuggestedFeatureSectionView';

import { ExperienceTypeSectionView } from '../../components/detail/creation/ExperienceTypeSectionView';
import { ExperienceType } from '../../models/Experience';
import PromotedCapabilitySectionView from '../sections/PromotedCapabilitySectionView';

export const AppNotificationWorkflow = (
        onMobileGuidelinesChecked: (checked?: boolean) => void
    ): Wizard.Step[] => {
    return [
        {
            title: 'Notification placement',
            content: <div>
                <ActivationDatesSectionView />
                <DayTimeWindowSectionView />
            </div>
        },
        {
            title: 'Customer targeting',
            content: <div>
                <RegionSectionView />
                <DataSourceTypeSectionView />
            </div>
        },
        {
            title: 'Create experience',
            content: <div>
                <NotificationSectionView
                    onMobileAppGuidelinesCheckedUpdate={(checked) => onMobileGuidelinesChecked(checked)}/>
                <ContentVariablesSectionView />
                <PromotedCapabilitySectionView />
                <SuggestedFeatureSectionView />
            </div>
        },
        {
            title: 'Review experience',
            content: <div>
                <ExperienceTypeSectionView
                    selectedExperienceType={'AppNotification' as ExperienceType}
                    disableTypeSelection={true} />

                <ActivationDatesSectionView />
                <DayTimeWindowSectionView />

                <RegionSectionView />
                <DataSourceTypeSectionView />

                <NotificationSectionView
                    onMobileAppGuidelinesCheckedUpdate={(checked) => onMobileGuidelinesChecked(checked)}/>
                <ContentVariablesSectionView />
                <PromotedCapabilitySectionView />
                <SuggestedFeatureSectionView />
            </div>
       }
   ];
};
