export interface IExperienceSlot {
    name: string;
    values: string[];
}

export const EMPTY_SLOT: IExperienceSlot = {
    name: '',
    values: []
};

export const processSlots = (slots?: IExperienceSlot[]): IExperienceSlot[] | undefined => {
    if (slots == null || slots === undefined || slots.length === 0) { return undefined; }

    const slotMap: {[key: string]: string[]} = {};

    slots.filter(slot => slot.name !== '')
        .forEach((slot) => {
        if (Object.keys(slotMap).includes(slot.name)) {
            slotMap[slot.name] = slotMap[slot.name].concat(slot.values);
        } else {
            slotMap[slot.name] = slot.values;
        }

        slotMap[slot.name] = Array.from(new Set(slotMap[slot.name]));
    });

    return Object.keys(slotMap).map((name) => {
        return {name, values: slotMap[name]} as IExperienceSlot;
    });
};
