import { AnyAction } from 'redux';
import {
    LOADED_MONITORED_EXPERIMENTS,
    LOADING_MONITORED_EXPERIMENTS,
    ERROR_LOADING_MONITORED_EXPERIMENTS,
    UPDATED_MONITORED_EXPERIMENTS,
    UPDATING_MONITORED_EXPERIMENTS,
    ERROR_UPDATING_MONITORED_EXPERIMENTS
} from '../constants/monitoredExperimentsActionTypes';
import { IMonitoredExperiments } from '../models/monitoredExperiments/MonitoredExperiments';

export interface IMonitoredExperimentsState {
    isLoading: boolean;
    updatedMonitoredExperiments: boolean;
    monitoredExperiments?: IMonitoredExperiments;
    errorLoading?: Error;
    errorUpdating?: Error;
}

export const monitoredExperimentsReducer = (
    state: IMonitoredExperimentsState = { isLoading: false, updatedMonitoredExperiments: false },
    action: AnyAction
): IMonitoredExperimentsState => {
    switch (action.type) {
        case LOADING_MONITORED_EXPERIMENTS:
            return {
                ...state,
                isLoading: true,
                updatedMonitoredExperiments: false
            };
        case UPDATING_MONITORED_EXPERIMENTS:
            return {
                ...state,
                updatedMonitoredExperiments: false,
                isLoading: true
            };
        case LOADED_MONITORED_EXPERIMENTS:
            return {
                ...state,
                isLoading: false,
                monitoredExperiments: action.monitoredExperiments,
                errorLoading: undefined,
                errorUpdating: undefined
            };
        case UPDATED_MONITORED_EXPERIMENTS:
            return {
                ...state,
                isLoading: false,
                updatedMonitoredExperiments: true,
                errorLoading: undefined,
                errorUpdating: undefined
            };
        case ERROR_LOADING_MONITORED_EXPERIMENTS:
            return {
                ...state,
                isLoading: false,
                updatedMonitoredExperiments: false,
                errorLoading: action.error,
                errorUpdating: undefined
            };
        case ERROR_UPDATING_MONITORED_EXPERIMENTS:
            return {
                ...state,
                isLoading: false,
                updatedMonitoredExperiments: false,
                errorUpdating: action.error,
                errorLoading: undefined
            };
        default:
            return state;
    }
};
