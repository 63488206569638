import React from 'react';
import { Textarea } from '@amzn/awsui-components-react';
import { CharacterCount } from './CharacterCount';
import { TextRenderDirection } from '../../models/htmlElementProps';
import { WAKEWORDS } from '../../constants/componentConstants';
import { PROACTIVE_NOTIFICATIONS_USER_GROUP } from '../../constants/userGroups';

export interface ITextAreaWrapperProps {
    id: string;
    experienceType?: string;
    textRenderDirection?: TextRenderDirection;
    value?: string;
    readonly: boolean;
    characterLimit?: number;
    placeholder?: string;
    operatorGroup?: string;
    validate: (input?: string) => void;
    onInput?: (inputString: string) => void;
    onChange?: (inputString: string) => void;
}

export class TextAreaWrapper extends React.Component<ITextAreaWrapperProps> {
    render () {
        const {
            id, experienceType, operatorGroup, textRenderDirection, value, readonly, placeholder,
            characterLimit, validate, onInput, onChange
        } = this.props;

        let errorMessage = '';
        let content = '';
        let isValid: boolean = true;

        if (!readonly) {
            try {
                validate(value);
                isValid = true;
            } catch (error) {
                isValid = false;
                errorMessage = 'Warning: ' + error.message;
            }
        }

        if (value && characterLimit) {
            content = value.replace(/<[^>]*>/g,'');
            if (content.length > characterLimit) {
                if (errorMessage === '') {
                    errorMessage = 'Warning: ';
                }
                errorMessage += '\nYour content exceeds the recommended length of ' + characterLimit + ' characters.\n';
            }
            const wakewordResult = WAKEWORDS.filter(word => content.includes(word));
            if (wakewordResult && wakewordResult.length !== 0 && experienceType !== 'App' && experienceType !== 'MHC'
                    && operatorGroup !== PROACTIVE_NOTIFICATIONS_USER_GROUP) {
                if (errorMessage === '') {
                    errorMessage = 'Warning: ';
                }
                errorMessage += '\nYour content contains the following Wakeword: '+ wakewordResult.join(', ') + '.\n';
            }
            isValid = isValid && (content.length <=  characterLimit);
        }

        const RightToLeftRenderedTextArea = <textarea
            id={id}
            dir='rtl'
            style={{ direction:'rtl' }}
            readOnly={readonly}
            value={value}
            className={`awsui-textarea ${readonly ? 'awsui-textarea-readonly' : ''} awsui-textarea-${isValid ? '' : 'in'}valid`}
            placeholder={placeholder}
            onInput={(event: React.FormEvent<HTMLTextAreaElement>) => {
                if (onInput) {
                    onInput((event.target as HTMLTextAreaElement).value);
                }
            }}
            onChange={(event: React.FormEvent<HTMLTextAreaElement>) => {
                if (onChange) {
                    onChange((event.target as HTMLTextAreaElement).value);
                }
            }}
        />;

        const LeftToRightRenderedTextArea = <Textarea
            controlId={id}
            id={id}
            readonly={readonly}
            value={value}
            invalid={!isValid}
            placeholder={placeholder}
            onInput={(event: CustomEvent<Textarea.ChangeDetail>) => {
                if (onInput) {
                    onInput(event.detail.value);
                }
            }}
            onChange={(event: CustomEvent<Textarea.ChangeDetail>) => {
                if (onChange) {
                    onChange(event.detail.value);
                }
            }} />;

        return <div>
            {textRenderDirection === 'rtl' ? RightToLeftRenderedTextArea : LeftToRightRenderedTextArea}

            <div className='awsui-grid'>
                <div className='awsui-row'>
                    <div className={characterLimit ? 'col-6' : 'col-12'}>
                        {errorMessage && <div className='awsui-util-status-negative'>{errorMessage}</div>}
                    </div>
                    <div className={errorMessage ? 'col-6' : 'col-12'}>
                        {characterLimit && <div className='awsui-util-t-r'>
                            <CharacterCount characters={content? content.length : 0} limit={characterLimit} />
                        </div>}
                    </div>
                </div>
            </div>
        </div>;
    }
}
