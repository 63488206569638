import * as React from 'react';
import { useState } from 'react';

import { BreadcrumbGroup } from '@amzn/awsui-components-react';
import ExperienceTable from '../components/list/ExperienceTable';
import { getLinkableUrl, PAGE } from '../constants/page';
import { useLocation } from 'react-router-dom';

/**
 * HLC (higher level components) for hooks
 */
export function Experiences(props: {}) {
    const search = useLocation().search;
    const searchCriteriaParams = new URLSearchParams(search).get('search');
    const [value, setState] = useState(searchCriteriaParams);

    return (
        <div>
            <div style={{ margin: '30px' }}>
                <div style={{ marginBottom: '20px' }}>
                    <BreadcrumbGroup label='Breadcrumbs' items={[{
                        text: 'Odyssey',
                        href: getLinkableUrl(PAGE.Home),
                    }, {
                        text: 'Experiences',
                        href: getLinkableUrl(PAGE.Experiences),
                    }]} />
                </div>
                <ExperienceTable updateFilteringCriteria={(searchCriteriaString: string) => {
                    setState(searchCriteriaString);
                    window.location.assign(getLinkableUrl(`${PAGE.Experiences}?search=${searchCriteriaString}`));
                }} searchCriteriaString={value} />
            </div>
        </div>
    );
}
