import * as React from 'react';
import BasicInfoSectionView from '../../../containers/sections/BasicInfoSectionView';
import { IFlattenedExperience } from '../../../models/FlattenedExperience';
import NotificationGuardrailsSectionView from '../../../containers/sections/NotificationGuardrailsSectionView';
import ImpressionTrackingSectionView from '../../../containers/sections/ImpressionTrackingSectionView';
import ConfigureAlexaLabMetricsView from '../../../containers/sections/ConfigureAlexaLabMetricsView';
import SuggestedFeatureSectionView from '../../../containers/sections/SuggestedFeatureSectionView';
import PromotedCapabilitySectionView from '../../../containers/sections/PromotedCapabilitySectionView';
import MobileHomeCardActivationDatesSectionView from '../../../containers/sections/MobileHomeCardActivationDatesSectionView';

export const ExperienceDetailsTabView = (props: { isAdmin: boolean, experience: IFlattenedExperience }) => {
    const { isAdmin, experience } = props;
    if (isAdmin && (experience.shortenedType === 'App' || experience.shortenedType === 'Device')) {
        return <div>
            <BasicInfoSectionView />
            <NotificationGuardrailsSectionView />
            <ImpressionTrackingSectionView />
            <PromotedCapabilitySectionView />
            <SuggestedFeatureSectionView />
            <ConfigureAlexaLabMetricsView />
        </div>;
    } else {
        return <div>
            <BasicInfoSectionView />
            <ImpressionTrackingSectionView />
            <PromotedCapabilitySectionView />
            <SuggestedFeatureSectionView />
            <ConfigureAlexaLabMetricsView />
        </div>;
    }
};

export const MobileHomeCardExperienceDetailsTabView = (props: { isAdmin: boolean, experience: IFlattenedExperience }) => {
    return <div>
        <BasicInfoSectionView />
        <MobileHomeCardActivationDatesSectionView />
    </div>;
};
