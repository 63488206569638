import { IProviderConfig, IProviderSkill } from '../models/spiProviderConfig/ISpiProviderOnboarding';

// validate individual endpoint fields
export const validateEndpointFields = (field: string, validationState: any, newFieldsState: any | undefined, setValidationState: React.SetStateAction<any>) => {
    const newValidationState = { ...validationState };
    if (!newFieldsState) return;
    const skillIdRegex = /^(amzn1\.ask\.skill\.|amzn1\.echo-sdk-ams\.app\.)/;
    switch (field) {
        case 'defaultEndpointskillId':
            // newValidationState.invalidDefaultEndpointSkillId = newFieldsState.defaultEndpoint.skillId.trim().length === 0;
            newValidationState.invalidDefaultEndpointSkillId = !skillIdRegex.test(newFieldsState.defaultEndpoint.skillId.trim());
            break;
        case 'defaultEndpointskillStage':
            newValidationState.invalidDefaultEndpointSkillStage = newFieldsState.defaultEndpoint.skillStage.trim().length === 0;
            break;
        case 'naEndpointskillId':
        case 'naEndpointskillStage':
            if (!newFieldsState.naEndpoint?.skillId && newFieldsState.naEndpoint?.skillStage) {
                newValidationState.invalidNaEndpointSkillStage = false;
                newValidationState.invalidNaEndpointSkillId = true;
            }
            else if (!newFieldsState.naEndpoint?.skillStage && skillIdRegex.test(newFieldsState.naEndpoint.skillId.trim())) {
                newValidationState.invalidNaEndpointSkillStage = true;
                newValidationState.invalidNaEndpointSkillId = false;
            }
            else if (!newFieldsState.naEndpoint?.skillStage && (!skillIdRegex.test(newFieldsState.naEndpoint.skillId.trim()) && newFieldsState.naEndpoint?.skillId)) {
                newValidationState.invalidNaEndpointSkillStage = true;
                newValidationState.invalidNaEndpointSkillId = true;
            }
            else if (newFieldsState.naEndpoint?.skillStage && (!skillIdRegex.test(newFieldsState.naEndpoint.skillId.trim()) && newFieldsState.naEndpoint?.skillId)) {
                newValidationState.invalidNaEndpointSkillStage = false;
                newValidationState.invalidNaEndpointSkillId = true;
            }
            else {
                newValidationState.invalidNaEndpointSkillStage = false;
                newValidationState.invalidNaEndpointSkillId = false;
            }
            break;
        case 'euEndpointskillId':
        case 'euEndpointskillStage':
            if (!newFieldsState.euEndpoint?.skillId && newFieldsState.euEndpoint?.skillStage) {
                newValidationState.invalidEuEndpointSkillStage = false;
                newValidationState.invalidEuEndpointSkillId = true;
            }
            else if (!newFieldsState.euEndpoint?.skillStage && skillIdRegex.test(newFieldsState.euEndpoint.skillId.trim())) {
                newValidationState.invalidEuEndpointSkillStage = true;
                newValidationState.invalidEuEndpointSkillId = false;
            }
            else if (!newFieldsState.euEndpoint?.skillStage && (!skillIdRegex.test(newFieldsState.euEndpoint.skillId.trim()) && newFieldsState.euEndpoint?.skillId)) {
                newValidationState.invalidEuEndpointSkillStage = true;
                newValidationState.invalidEuEndpointSkillId = true;
            }
            else if (newFieldsState.euEndpoint?.skillStage && (!skillIdRegex.test(newFieldsState.euEndpoint.skillId.trim()) && newFieldsState.euEndpoint?.skillId)) {
                newValidationState.invalidEuEndpointSkillStage = false;
                newValidationState.invalidEuEndpointSkillId = true;
            }
            else {
                newValidationState.invalidEuEndpointSkillStage = false;
                newValidationState.invalidEuEndpointSkillId = false;
            }
            break;
        case 'feEndpointskillId':
        case 'feEndpointskillStage':
            if (!newFieldsState.feEndpoint?.skillId && newFieldsState.feEndpoint?.skillStage) {
                newValidationState.invalidFeEndpointSkillStage = false;
                newValidationState.invalidFeEndpointSkillId = true;
            }
            else if (!newFieldsState.feEndpoint?.skillStage && skillIdRegex.test(newFieldsState.feEndpoint.skillId.trim())) {
                newValidationState.invalidFeEndpointSkillStage = true;
                newValidationState.invalidFeEndpointSkillId = false;
            }
            else if (!newFieldsState.feEndpoint?.skillStage && (!skillIdRegex.test(newFieldsState.feEndpoint.skillId.trim()) && newFieldsState.feEndpoint?.skillId)) {
                newValidationState.invalidFeEndpointSkillStage = true;
                newValidationState.invalidFeEndpointSkillId = true;
            }
            else if (newFieldsState.feEndpoint?.skillStage && (!skillIdRegex.test(newFieldsState.feEndpoint.skillId.trim()) && newFieldsState.feEndpoint?.skillId)) {
                newValidationState.invalidFeEndpointSkillStage = false;
                newValidationState.invalidFeEndpointSkillId = true;
            }
            else {
                newValidationState.invalidFeEndpointSkillStage = false;
                newValidationState.invalidFeEndpointSkillId = false;
            }
            break;
        default:
            break;
    }
    setValidationState(newValidationState);
};

// validate individual supported fields
export const validateSupportedFields = (field: string, validationState: any, newFieldsState: any, setValidationState: React.SetStateAction<any>) => {
    const newValidationState = { ...validationState };
    if (!newFieldsState) return;
    switch (field) {
        case 'supportedLocales':
            newValidationState.invalidSupportedLocales = JSON.stringify(newFieldsState.supportedLocales) === JSON.stringify([]);
            break;
        case 'supportedExperienceTypes':
            newValidationState.invalidSupportedExperienceTypes = JSON.stringify(newFieldsState.supportedExperienceTypes) === JSON.stringify([]);
            break;
        case 'supportedEndpointTypes':
            newValidationState.invalidSupportedEndpointTypes = JSON.stringify(newFieldsState.supportedEndpointTypes) === JSON.stringify([]);
            break;
        default:
            break;
    }
    setValidationState(newValidationState);
};

export const validateCtiField = (validationState: any, newFieldsState: any, setValidationState: React.SetStateAction<any>) => {
    const newValidationState = { ...validationState };
    newValidationState.invalidCti = (newFieldsState.cti.length === 0);
    setValidationState(newValidationState);
};

export const validateProviderIdAndCti = (field: string, validationState: any, newFieldsState: any, setValidationState: React.SetStateAction<any>) => {
    const newValidationState = { ...validationState };
    switch (field) {
        case 'providerId':
            newValidationState.invalidProviderId = (newFieldsState.providerId.length === 0);
            break;
        case 'cti':
            newValidationState.invalidCti = (newFieldsState.cti.length === 0);
            break;
        default:
            break;
    }
    setValidationState(newValidationState);
};

export function filterProviderConfigEndpoints(providerConfig: IProviderConfig): IProviderConfig {
    const { naEndpoint, euEndpoint, feEndpoint, ...rest } = providerConfig;

    const shouldRemoveEndpoint = (endpoint?: IProviderSkill) => {
        return (
            (endpoint?.skillId === '' || endpoint?.skillStage === '') ||
            (endpoint?.skillId === undefined && endpoint?.skillStage !== undefined) ||
            (endpoint?.skillId !== undefined && endpoint?.skillStage === undefined)
        );
    };
    return {
        ...rest,
        ...(naEndpoint && !shouldRemoveEndpoint(naEndpoint) ? { naEndpoint } : {}),
        ...(euEndpoint && !shouldRemoveEndpoint(euEndpoint) ? { euEndpoint } : {}),
        ...(feEndpoint && !shouldRemoveEndpoint(feEndpoint) ? { feEndpoint } : {})
    };

}

export function getDiffLines(oldText: string, newText: string) {
    const oldLines = oldText.split('\n');
    const newLines = newText.split('\n');
    const diffLines: { old: string; new: string; type: 'same' | 'added' | 'removed' }[] = [];

    const lcsMatrix = Array(oldLines.length + 1).fill(null).map(() => Array(newLines.length + 1).fill(0));
    // fill the lcs matrix
    for (let i = 1; i <= oldLines.length; i++) {
        for (let j = 1; j <= newLines.length; j++) {
            if (oldLines[i - 1] === newLines[j - 1]) {
                lcsMatrix[i][j] = lcsMatrix[i - 1][j - 1] + 1;
            } else {
                lcsMatrix[i][j] = Math.max(lcsMatrix[i - 1][j], lcsMatrix[i][j - 1]);
            }
        }
    }

    let k = oldLines.length;
    let l = newLines.length;
    // backtrack to find lcs
    while (k > 0 || l > 0) {
        if (k > 0 && l > 0 && oldLines[k - 1] === newLines[l - 1]) {
            diffLines.unshift({ old: oldLines[k - 1], new: newLines[l- 1], type: 'same' });
        k--;
        l--;
        } else if (l > 0 && (k === 0 || lcsMatrix[k][l - 1] >= lcsMatrix[k - 1][l])) {
            diffLines.unshift({ old: '', new: newLines[l - 1], type: 'added' });
            l--;
        } else if (k > 0 && (l === 0 || lcsMatrix[k][l - 1] < lcsMatrix[k - 1][l])) {
            diffLines.unshift({ old: oldLines[k - 1], new: '', type: 'removed' });
            k--;
        }
    }
    return diffLines;
}