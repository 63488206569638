export enum UtteranceMatcher {
    Exact_Match = 'Exact_Match',
    Prefix_Match = 'Prefix_Match',
    Suffix_Match = 'Suffix_Match',
    Contains_Match = 'Contains_Match'
}

export interface IExperienceTargetUtterance {
    utterance: string;
    utteranceMatcher: UtteranceMatcher;
}

export const EMPTY_TARGET_UTTERANCE: IExperienceTargetUtterance = {
    utterance: '',
    utteranceMatcher: UtteranceMatcher.Exact_Match
};

export const processTargetUtterances = (targetUtterances?: IExperienceTargetUtterance[])
    : IExperienceTargetUtterance[] | undefined => {

    if (targetUtterances == null || targetUtterances === undefined || targetUtterances.length === 0) {
        return undefined;
    }

    return targetUtterances.filter(target => (target.utterance !== ''));
};
