import * as React from 'react';
import { IFlattenedExperience } from '../../../models/FlattenedExperience';
import FeatureHintSectionView from '../../../containers/sections/FeatureHintSectionView';
import RemoteTagsSectionView from '../../../containers/sections/RemoteTagsSectionView';
import DomainTagsSectionView from '../../../containers/sections/DomainTagsSectionView';
import AudienceTypeSectionView from '../../../containers/sections/AudienceTypeSectionView';
import JourneySectionView from '../../../containers/sections/JourneySectionView';
import MobileHomeCardPlatformSectionView from '../../../containers/sections/MobileHomeCardPlatformSectionView';
import MobileHomeCardAbExperimentSectionView from '../../../containers/sections/MobileHomeCardAbExperimentSectionView';

export const AdvancedConfigurationTabView = (props: { experience: IFlattenedExperience }) => {
    const { experience } = props;
    return <div>
        {experience.type === 'CIF' && (<div>
            <AudienceTypeSectionView />
            <DomainTagsSectionView />
            <RemoteTagsSectionView />
            <FeatureHintSectionView />
        </div>)}

        {experience.type !== 'MobileHomeCard' && (<div>
            <JourneySectionView />
        </div>)}

        {experience.type === 'MobileHomeCard' && (<div>
            <MobileHomeCardPlatformSectionView />
            <MobileHomeCardAbExperimentSectionView />
        </div>)}
    </div>;
};
