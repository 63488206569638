import * as React from 'react';
import { Alert, ColumnLayout, FormField, FormSection, Icon, Popover, ProgressBar } from '@amzn/awsui-components-react';
import { IFilterType, IRawFilterMetricsData, IFilterMetric, IFilterMetricData } from '../../models/FilterMetrics';
import { OpportunityFilterMap, ProgressBarConst, ProgressBarLength } from '../../constants/opportunityFilterMap';
import DatePickerWrapper from '../common/DatePickerWrapper';
import moment from 'moment';

export const OpportunityFilters = ({ filterMetrics }: {
    filterMetrics: IFilterMetric[]
}) => {
    let progress = -1;
    let maxOpportunities = -1;
    let index = 0;
    return <div style={{ width: '800px'}}>
                {Object.keys(OpportunityFilterMap)
                    .map(filter => {
                        if (filter === 'StatusFilter' || filter === 'ActivationFilter' || filter === 'ALL_FILTERS' || filter === 'VisualCifFilter') {
                            return <div></div>;
                        }
                        const { label, description } = OpportunityFilterMap[filter as IFilterType];
                        const filterData = filterMetrics[0].data[filter as IFilterType];
                        if (!filterData){
                            return <div></div>;
                        }
                        if (progress < 0) {
                            progress = filterData.passed+filterData.failed;
                            maxOpportunities = filterData.passed+filterData.failed;
                        }
                        index += 1;
                        const width = (progress) / (maxOpportunities) * ProgressBarLength + ProgressBarConst;
                        progress = Math.max(filterData.passed, progress-filterData.failed);
                        const progressBarValue = progress/(progress+filterData.failed)*100;
                        return <div>
                            <div>{index}. {label} {<Popover content={description}>
                            <Icon className='awsui-util-status-info' name='status-info'></Icon>
                                </Popover>}</div>
                            <div style={{ width: width < 1 ? 50 : width }}>
                            <ProgressBar
                                value={progressBarValue < 1 ? 1 : progressBarValue}
                                variant='standalone'
                            />
                        </div>
                        <div className='awsui-util-status-inactive'><strong className='awsui-util-status-info'>{progress.toLocaleString()} </strong>
                            passed out of the {(progress+filterData.failed).toLocaleString()} {index === 1 ? 'initial': 'remaining'} opportunities</div>
                        <br />
                        </div>;
                    })
                }
            </div>;
};

export const OpportunityFunnelAnalysis = ({ filterMetrics, fromDate, toDate, updateFromDate, updateToDate, isFromDateValid, isToDateValid }: {
    filterMetrics?: IRawFilterMetricsData[]
    fromDate: string
    toDate: string
    updateFromDate: (input: string, isValid: boolean) => void;
    updateToDate: (input: string, isValid: boolean) => void;
    isFromDateValid: boolean;
    isToDateValid: boolean;
}) => {

    if (!filterMetrics) {
        return <div id='metrics.overview' className='awsui-util-container' style={{ height: '100%' }}>
        <div className='awsui-util-container-header'>
            Opportunity Funnel Analysis
            <div className='awsui-util-container-header-description'>
                <div>
                    The following analysis shows the filters applied within Odyssey and their impact on customer reach.
                </div>
            </div>
            </div>
            <Alert header='Funnel Metrics Not Available'
                id='metrics.alert'
                type='info'
                content='Funnel Analysis Metrics for this experience is not available right now.
                Please make sure this experience is in a LIVE stage for at least one day.'
            />
        </div>;
    }
    const filterMetric = filterMetrics[0].metrics;
    const findValidFromDate = (date: Date) => {
        for (const metric of filterMetric) {
            if (moment(metric.period).isSame(date) && moment(metric.period).isSameOrBefore(toDate)) {
                return true;
            }
        }
        return false;
    };

    const findValidToDate = (date: Date) => {
        for (const metric of filterMetric) {
            if (moment(metric.period).isSame(date) && moment(metric.period).isSameOrAfter(fromDate)) {
                return true;
            }
        }
        return false;
    };

    // Combines the passed and failed number when date range gets updated
    const metrics: IFilterMetric[] = [];
    filterMetric.forEach(
        metric => {
            if (!moment(metric.period).isAfter(moment(toDate)) && !moment(metric.period).isBefore(moment(fromDate))) {
                if (fromDate && toDate) {
                    if (metrics.length === 0){
                        metrics.push(JSON.parse(JSON.stringify(metric)) as IFilterMetric);
                    } else {
                        // Update passed and failed number based on Filter name
                        Object.keys(OpportunityFilterMap)
                            .forEach(filter => {
                                const filterData = metric.data[filter as IFilterType];
                                const newMetrics = metrics[0].data[filter as IFilterType];
                                if (newMetrics) {
                                    newMetrics.passed += metric.data[filter as IFilterType]?.passed || 0;
                                    newMetrics.failed += metric.data[filter as IFilterType]?.failed || 0;
                                } else if (!newMetrics && filterData) {
                                    metrics[0].data[filter as IFilterType] = JSON.parse(JSON.stringify(filterData)) as IFilterMetricData;
                                }
                            });
                    }
                } else {
                    metrics.push(JSON.parse(JSON.stringify(metric)) as IFilterMetric);
                }
            }
        }
    );

    let totalReach = 0;
    let totalOpportunity = 1;
    if (metrics[0].data.ALL_FILTERS) {
        totalReach = metrics[0].data.ALL_FILTERS.passed;
    }
    Object.keys(OpportunityFilterMap)
        .forEach(filter => {
            const filterData = metrics[0].data[filter as IFilterType];
            totalOpportunity = Math.max(totalOpportunity, (filterData?.failed || 0) + (filterData?.passed || 0));
        });
    return <div id='metrics.overview' className='awsui-util-container' style={{ height: '100%' }}>
        <div className='awsui-util-container-header' style={{ display: 'flex', justifyContent: 'space-between'}}>
            <div>
            Opportunity Funnel Analysis
                <div className='awsui-util-container-header-description'>
                    <div>
                        The following analysis shows the filters applied within Odyssey and their impact on customer reach.
                    </div>
                </div>
            </div>
            <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root='true'>
                    <FormField label='Start Date'>
                        <DatePickerWrapper
                            id='metrics.from-date'
                            value={moment(fromDate).format('YYYY-MM-DD')}
                            disabled={false}
                            isDateEnabled={findValidFromDate}
                            onChange={(e) => {
                                if (moment(e.detail.value).isSameOrAfter(moment(filterMetric[filterMetric.length-1].period)) &&
                                moment(e.detail.value).isSameOrBefore(moment(filterMetric[0].period))){
                                    updateFromDate(e.detail.value, true);
                                } else {
                                    updateFromDate(filterMetric[filterMetric.length-1].period, false);
                                }
                            }}
                            ></DatePickerWrapper>
                            { !isFromDateValid &&
                                <p className='awsui-util-status-negative'>Please select a valid Start Date.</p>
                            }
                    </FormField>
                    <FormField label='End Date'>
                        <DatePickerWrapper
                            id='metrics.to-date'
                            value={moment(toDate).format('YYYY-MM-DD')}
                            disabled={false}
                            isDateEnabled={findValidToDate}
                            onChange={(e) => {
                                if (moment(e.detail.value).isSameOrAfter(moment(filterMetric[filterMetric.length-1].period)) &&
                                moment(e.detail.value).isSameOrBefore(moment(filterMetric[0].period))){
                                    updateToDate(e.detail.value, true);
                                } else {
                                    updateToDate(filterMetric[0].period, false);
                                }
                            }}
                            ></DatePickerWrapper>
                            { !isToDateValid &&
                                <p className='awsui-util-status-negative'>Please select a valid End Date.</p>
                            }
                    </FormField>
                </div>
            </ColumnLayout>
        </div>
        <div>
            <ProgressBar
            label='Total Reach'
            description='Number of opportunities passing all filters out of all opportunities while experience is active.'
            value={Math.ceil(totalReach/totalOpportunity*100)}
            variant='standalone'
            />
            <div className='awsui-util-font-size-0 awsui-util-status-inactive'><strong className='awsui-util-status-info'>
                {totalReach.toLocaleString()}</strong> out of the {totalOpportunity.toLocaleString()} total opportunities</div>
        </div>
        <div>
            <FormSection
                header='Opportunity Filters'
                description='The list below only shows the filters applied to the experience in Odyssey, other filters are hidden.'
                expandable={true}>
                    <ColumnLayout columns={1} borders='vertical'>
                        <OpportunityFilters filterMetrics={metrics}/>
                    </ColumnLayout>
            </FormSection>
        </div>
    </div>;
};
