import { Select } from '@amzn/awsui-components-react';
import { SubscriptionUpsellType } from '../models/SubscriptionUpsellType';

export const SUBSCRIPTION_UPSELL_TYPE_OPTIONS: Select.Option[] = Object.entries(SubscriptionUpsellType)
    .map(([key, value]) => (
        {
            'id': key,
            'label': value
        }
    ));
