import * as React from 'react';
import { ColumnLayout } from '@amzn/awsui-components-react';

export type RenderableKeyValuePair = {
    key: string,
    value: JSX.Element | string
};

type ExperienceDetailGlobalContentProps = {
    data:
    | [RenderableKeyValuePair[]]
    | [RenderableKeyValuePair[], RenderableKeyValuePair[]]
    | [RenderableKeyValuePair[], RenderableKeyValuePair[], RenderableKeyValuePair[]]
    | [RenderableKeyValuePair[], RenderableKeyValuePair[], RenderableKeyValuePair[], RenderableKeyValuePair[]]
};
type ExperienceDetailGlobalContentColumnProps = {
    data: RenderableKeyValuePair[]
};

// single key value pair
export const KeyValuePairView = (props: { k: string, v: JSX.Element | string }) => {
    const { k, v } = props;
    return <div className='awsui-util-mb-l'>
        <div className='awsui-util-label' id={`key-${k.replace(/\s/g, '-')}`}>{k}</div>
        <div id={`value-${k.replace(/\s/g, '-')}`}>{v}</div>
    </div>;
};

// single column
export const KeyValuePairSingleColumnView = (props: ExperienceDetailGlobalContentColumnProps) => {
    return <div>
        {props.data.map((data, index) => <KeyValuePairView
            k={data.key} v={data.value} key={index}
        />)}
    </div>;
};

// entire container
export const KeyValuePairMultipleColumnView = (props: ExperienceDetailGlobalContentProps) => {
    return <ColumnLayout columns={props.data.length} borders='vertical' >
        <div data-awsui-column-layout-root='true' >
            {props.data.map((datum, index: number) => {
                return <KeyValuePairSingleColumnView data={datum} key={`col-number-${index + 1}`} />;
            })}
        </div>
    </ColumnLayout>;
};
