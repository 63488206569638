import { applyMiddleware, combineReducers, createStore, Middleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { authenticationReducer } from './authenticationReducer';
import { experienceListViewReducer } from './experienceListViewReducer';
import { navigationViewReducer } from './navigationViewReducer';
import { experienceDetailViewReducer } from './experienceDetailViewReducer';
import { experienceMetricsViewReducer } from './experienceMetricsViewReducer';
import { bullseyeMetadataReducer } from './bullseyeMetadataReducer';
import { pushNotificationReducer } from './pushNotificationReducer';
import { experienceEditViewReducer } from './experienceEditViewReducer';
import { eventsMetadataReducer } from './eventsMetadataReducer';
import { eventTrailReducer } from './eventTrailReducer';
import { configureAlexaLabMetricsReducer } from './configureAlexaLabMetricsReducer';
import { experienceCXWeeklyMetricsViewReducer } from './experienceCXWeeklyMetricsViewReducer';
import { experienceCXDailyMetricsViewReducer } from './experienceCXDailyMetricsViewReducer';
import { experienceFilterDailyMetricsViewReducer } from './experienceFilterDailyMetricsViewReducer';
import { odysseyAnnouncementReducer } from './odysseyAnnouncementReducer';
import { bypassPauseListReducer } from './bypassPauseListReducer';
import { promotedExperimentsReducer } from './promotedExperimentsReducer';
import { monitoredExperimentsReducer } from './monitoredExperimentsReducer';
import { experienceSimilarViewReducer } from './experienceSimilarViewReducer';
import { homeCardSuggestionsListViewReducer } from './homeCardSuggestionsListViewReducer';
import { homeCardSuggestionsDetailViewReducer } from './homeCardSuggestionsDetailViewReducer';
import { homeCardSuggestionsEditViewReducer } from './homeCardSuggestionsEditViewReducer';
import { changeControlDecisionReducer } from './changeControlDecisionReducer';
import { multiCloneReducer } from './multiCloneReducer';
import { capabilityCatalogSearchReducer } from './capabilityCatalogSearchReducer';
import { odysseyExperienceDetailViewReducer } from './v2/odysseyExperienceDetailViewReducer';
import { testMobileHomeCardReducer } from './testMobileHomeCardReducer';
import { odysseyExperienceListViewReducer } from './v2/odysseyExperienceListViewReducer';
import { odysseyExperienceRcpCXDailyMetricsViewReducer } from './v2/odysseyExperienceRcpCXDailyMetricsViewReducer';
import { odysseyExperienceRcpCXWeeklyMetricsViewReducer } from './v2/odysseyExperienceRcpCXWeeklyMetricsViewReducer';
import { spiProviderOnboardingReducer } from './spiProviderOnboardingReducer';

const middlewareList: Middleware[] = [thunk];

// redux logger has to be last middleware, otherwise, we end up logging more stuff than we need.
if (process.env.NODE_ENV !== 'production') {
    middlewareList.push(createLogger());
}

export const rootReducers = combineReducers({
    experienceListViewState: experienceListViewReducer,
    experienceEditViewState: experienceEditViewReducer,
    experienceDetailViewState: experienceDetailViewReducer,
    experienceMetricsViewState: experienceMetricsViewReducer,
    experienceSimilarExperincesState: experienceSimilarViewReducer,
    cxWeeklyMetricsState: experienceCXWeeklyMetricsViewReducer,
    cxDailyMetricsState: experienceCXDailyMetricsViewReducer,
    filterDailyMetricsState: experienceFilterDailyMetricsViewReducer,
    bullseyeMetadataState: bullseyeMetadataReducer,
    changeControlDecisionState: changeControlDecisionReducer,
    eventsMetadataState: eventsMetadataReducer,
    navigationViewState: navigationViewReducer,
    authenticationState: authenticationReducer,
    pushNotificationState: pushNotificationReducer,
    eventTrailState: eventTrailReducer,
    configuredAlexaLabMetricsState: configureAlexaLabMetricsReducer,
    odysseyAnnouncementState: odysseyAnnouncementReducer,
    bypassPauseListState: bypassPauseListReducer,
    promotedExperimentsState: promotedExperimentsReducer,
    monitoredExperimentsState: monitoredExperimentsReducer,
    homeCardSuggestionsListViewState: homeCardSuggestionsListViewReducer,
    homeCardSuggestionsDetailViewState: homeCardSuggestionsDetailViewReducer,
    homeCardSuggestionsEditViewState: homeCardSuggestionsEditViewReducer,
    multiCloneState: multiCloneReducer,
    capabilityCatalogSearchState: capabilityCatalogSearchReducer,
    odysseyExperienceDetailViewState: odysseyExperienceDetailViewReducer,
    testMobileHomeCardState: testMobileHomeCardReducer,
    odysseyExperienceListViewState: odysseyExperienceListViewReducer,
    rcpCXDailyMetricsState: odysseyExperienceRcpCXDailyMetricsViewReducer,
    rcpCXWeeklyMetricsState: odysseyExperienceRcpCXWeeklyMetricsViewReducer,
    spiProviderOnboardingState: spiProviderOnboardingReducer
});

export type AppState = ReturnType<typeof rootReducers>;

export const store = createStore(rootReducers, applyMiddleware(...middlewareList));
