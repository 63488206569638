import * as React from 'react';
import { Input } from '@amzn/awsui-components-react';

export interface IInputWithCharLimitProps extends Input.Props {
    characterLimit: number;
}

const InputWithCharLimit: React.FC<IInputWithCharLimitProps> = (props: IInputWithCharLimitProps) => {
    const { characterLimit } = props;
    const [currentValue, setValue] = React.useState(props.value || '');

    const onInputEvent = (e: CustomEvent<Input.ChangeDetail>) => {
        setValue(e.detail.value);
        if (props.onInput) {
            props.onInput(e);
        }
    };

    return <div>
        <Input {...props}
            onInput={onInputEvent}
            value={currentValue}
            invalid={(currentValue.length > characterLimit) || (props.invalid || false)}>
        </Input>
        <small>{currentValue.length} of {characterLimit} characters max</small>
    </div>;
};

export default InputWithCharLimit;
