import { ColumnLayout, Icon } from '@amzn/awsui-components-react';
import React from 'react';
import { IAlexaLabMetrics } from '../../models/AlexaLabMetrics';
import { IRawCifMetricsData } from '../../models/CifMetrics';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { extractMaxRateCifMetrics, extractWorstTriggerIntentMetrics,
    extractActiveDaysValue, extractDialogValue,
    isInterruptionRateValid, isNegativeFeedbackValid, isPercentLiftPositive, isStatisticallySignificant } from '../../util/metricsCriteriaUtil';
import { getConfiguredIntents, getSuggestedIntents } from './ExperienceMetricsIntentPerformance';
import { experienceWithAlexaLabDialUpMetric, isListEmpty } from '../../util/stringAndMappingHelper';

const EmptyMetric = ({ text }:{ text: string}) => {
    return <div><div className='awsui-util-label'>{text}</div>
            <div><span className={'awsui-util-status-negative'}><Icon name={'status-warning'} /></span>&nbsp;&nbsp;-</div>
        <span /></div>;
};

const CifDialUpMetric = ({ valid, headerText, value }:{ valid: boolean, headerText: string, value?: number }) => {
    if (!valid || value === undefined) {
        return <div>
            <EmptyMetric text={headerText}/>
        </div>;
    }
    const iconName = valid ? 'status-positive' : 'status-warning';
    const className = valid ? 'awsui-util-status-positive': 'awsui-util-status-negative';
    return <div><div className='awsui-util-label'>{headerText}</div>
            <div><span className={className}><Icon name={iconName} /></span>&nbsp;&nbsp;{`${(value*100).toFixed(2)}%`}</div>
            <span /></div>;
};

const CifDialUpMetrics = ({ cxDailyMetrics }:{ cxDailyMetrics?: IRawCifMetricsData[] }) => {
    if (!cxDailyMetrics) {
        return <div>
            <EmptyMetric text={'Interruption rate'}/>&nbsp;
            <EmptyMetric text={'Negative feedback'}/>
        </div>;
    }

    const maxInterruptionRate = extractMaxRateCifMetrics(cxDailyMetrics, true);
    const maxNegativeFeedback = extractMaxRateCifMetrics(cxDailyMetrics, false);

return <div>
        <CifDialUpMetric valid={isInterruptionRateValid(maxInterruptionRate)} headerText={'Interruption rate'} value={maxInterruptionRate}/>
            <span />&nbsp;
        <CifDialUpMetric valid={isNegativeFeedbackValid(maxNegativeFeedback)} headerText={'Negative feedback'} value={maxNegativeFeedback}/>
        </div>;
};

export const AlexaLabTriggerIntentDialUpMetric = ({ triggerIntents, alexaLabMetrics }:{ triggerIntents: string[], alexaLabMetrics?: IAlexaLabMetrics }) => {
    if (!alexaLabMetrics) {
        return <EmptyMetric text={'Trigger intent active days'}/>;
    }

    const worstTriggerIntentMetrics = extractWorstTriggerIntentMetrics(alexaLabMetrics, triggerIntents);
    const percentLift = worstTriggerIntentMetrics?.percentLift;
    const pValue = worstTriggerIntentMetrics?.pvalue;

    if (percentLift === undefined || pValue === undefined || isNaN(Number(percentLift))) {
        return <EmptyMetric text={'Trigger intent active days'}/>;
    }

    const sign = percentLift > 0 ? '+' : '';
    const className = isStatisticallySignificant(pValue) && !isPercentLiftPositive(percentLift)? 'awsui-util-status-negative': 'awsui-util-status-positive';
    const iconName = isStatisticallySignificant(pValue) && !isPercentLiftPositive(percentLift) ? 'status-warning' : 'status-positive';
    const pValueStat = isStatisticallySignificant(pValue)? 'Statistically Significant': 'Statistically Insignificant';

    return <div>
        <div className='awsui-util-label'>Trigger intent active days</div>
            <div>
                <span className={className}><Icon name={iconName} /></span>&nbsp;&nbsp;{`${sign}${percentLift.toFixed(2)}%, ${pValueStat}`}&nbsp;&nbsp;
            </div>
        </div>;
};

const SuggestedIntentDialUpMetric = ({ percentLift, pValue, headerText }:{ percentLift?: number, pValue?: number, headerText: string }) => {
    if (percentLift === undefined || pValue === undefined || isNaN(Number(percentLift))) {
        return <EmptyMetric text={headerText}/>;
    }
    const iconName = isStatisticallySignificant(pValue) && isPercentLiftPositive(percentLift) ? 'status-positive' : 'status-warning';
    const pValueStat = isStatisticallySignificant(pValue)? 'Statistically Significant': 'Statistically Insignificant';

    let className = '';
    if (isStatisticallySignificant(pValue) && isPercentLiftPositive(percentLift)) {
        className = 'awsui-util-status-positive';
    } else if (isStatisticallySignificant(pValue) && !isPercentLiftPositive(percentLift)) {
        className = 'awsui-util-status-negative';
    } else {
        className = 'awsui-util-status-inactive';
    }

    const sign = percentLift > 0 ? '+' : '';
    return <div><div className='awsui-util-label'>{headerText}</div>
            <div>
                <span className={className}><Icon name={iconName} /></span>&nbsp;&nbsp;{`${sign}${percentLift.toFixed(2)}%, ${pValueStat}`}&nbsp;&nbsp;</div>
            </div>;
};

export const AlexaLabSuggestedIntentDialUpMetric = ({ suggestedIntents, alexaLabMetrics }:{ suggestedIntents: string[], alexaLabMetrics?: IAlexaLabMetrics }) => {
    if (!alexaLabMetrics){
        return <div>
            <EmptyMetric text={'Suggested intent active days'}/>&nbsp;
            <EmptyMetric text={'Suggested intent day-1 excluded dialogs'}/>
        </div>;
    }
    const activeDaysMeetingThresholds = extractActiveDaysValue(alexaLabMetrics, suggestedIntents);
    const dialogsMeetingThresholds = extractDialogValue(alexaLabMetrics, suggestedIntents);
    return <div>
        <SuggestedIntentDialUpMetric percentLift={activeDaysMeetingThresholds?.percentLift} pValue={activeDaysMeetingThresholds?.pvalue} headerText={'Suggested intent active days'}/>
            <span />&nbsp;
            <SuggestedIntentDialUpMetric percentLift={dialogsMeetingThresholds?.percentLift} pValue={dialogsMeetingThresholds?.pvalue} headerText={'Suggested intent day-1 excluded dialogs'}/>
        </div>;
};

export const ExperienceDialUpMetrics = ({ alexaLabMetrics, cxDailyMetrics, experience }: {
    alexaLabMetrics?: IAlexaLabMetrics, cxDailyMetrics?: IRawCifMetricsData[],
    experience: IFlattenedExperience
}) => {
    const triggerIntents = getConfiguredIntents(experience);
    const suggestedIntents = getSuggestedIntents(experience);
    return <div id='metrics.dialup' className='awsui-util-container' style={{ height: '100%' }}>
        <div className='awsui-util-container-header'>
            Dial-up metrics
            <div className='awsui-util-container-header-description'>
                Metrics used to make dial-up and dial-down decisions. The metrics used vary per dial-up stage.
            </div>
        </div>
        <ColumnLayout columns={3} borders='vertical' >
            <div data-awsui-column-layout-root='true'>
                <div><CifDialUpMetrics cxDailyMetrics={cxDailyMetrics}/></div>
                { experienceWithAlexaLabDialUpMetric(experience) &&
                    <div><AlexaLabTriggerIntentDialUpMetric triggerIntents={triggerIntents} alexaLabMetrics={alexaLabMetrics}/>
                    </div>}
                { experienceWithAlexaLabDialUpMetric(experience) && !isListEmpty(suggestedIntents) &&
                    <div><AlexaLabSuggestedIntentDialUpMetric suggestedIntents={suggestedIntents} alexaLabMetrics={alexaLabMetrics}/>
                    </div>}
            </div>
        </ColumnLayout>
    </div>;
};
