import { ILoadRcpCXDailyMetricsAction } from '../../actions/experienceMetricsViewActions';
import {
    LOADING_RCP_CX_DAILY_METRICS,
    LOADED_RCP_CX_DAILY_METRICS,
    ERROR_LOADING_RCP_CX_DAILY_METRICS
} from '../../constants/experienceMetricsViewActionTypes';
import { IRawRcpMetricsData } from '../../models/v2/metric/RCPMetrics';

export interface IOdysseyExperienceRcpCXDailyMetricsViewState {
    isLoading: boolean;
    cxDailyMetrics?: IRawRcpMetricsData[];
    error?: Error;
}

export const odysseyExperienceRcpCXDailyMetricsViewReducer = (
    state: IOdysseyExperienceRcpCXDailyMetricsViewState = { isLoading: false },
    action: ILoadRcpCXDailyMetricsAction
): IOdysseyExperienceRcpCXDailyMetricsViewState => {
    switch (action.type) {
        case LOADING_RCP_CX_DAILY_METRICS:
            return {
                ...state,
                isLoading: true
            };
        case LOADED_RCP_CX_DAILY_METRICS:
            const { cxDailyMetrics } = action;
            return {
                ...state,
                isLoading: false,
                cxDailyMetrics
            };
        case ERROR_LOADING_RCP_CX_DAILY_METRICS:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};
