import * as React from 'react';

export const RequiredField = (props: { fieldName: string, fieldDescription?: string }) => {
    const { fieldName, fieldDescription } = props;
    const description = fieldDescription || '(required)';
    return <span>{fieldName} <i className='awsui-text-secondary' >{description}</i></span>;
};

export const OptionalField = (props: { fieldName: string, fieldDescription?: string }) => {
    const { fieldName, fieldDescription } = props;
    const description = fieldDescription || '(optional)';
    return <span>{fieldName} <i className='awsui-text-secondary' >{description}</i></span>;
};

export const RecommendedField = (props: { fieldName: string }) => {
    const { fieldName } = props;

    return <span>{fieldName} <i className='awsui-text-secondary' >(recommended)</i></span>;
};

export const AutoFill = (props: { fieldName: string }) => {
    const { fieldName } = props;

    return <span>{fieldName} <i className='awsui-text-secondary' >(auto-filled)</i></span>;
};
