import * as React from 'react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { FormSectionView } from '../../components/common/FormSectionView';
import { ColumnLayout, TokenGroup, FormField, Input, Spinner, Alert, Select, Autosuggest } from '@amzn/awsui-components-react';
import { connect } from 'react-redux';
import { AppState } from '../../reducers/index';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import {
    pushBusinessInfo,
    pushSubscriptionUpsellType,
    pushSuggestedFeature
} from '../../actions/experienceEditViewActions';
import { OptionalField, RequiredField } from '../../components/common/DescriptionAnnotations';
import { INluInfo } from '../../models/NluInfo';
import { isNullOrUndefined } from 'util';
import { IBusinessMetadata } from '../../models/BusinessInfo';
import { BUSINESS_DOMAIN_DROPDOWN_OPTIONS, BUSINESS_VERTICAL_DROPDOWN_OPTIONS } from '../../constants/businessVerticalDomains';
import { TriggerIntentOptions } from '../../components/common/NluIntentOptions';
import { IsCustomIntent } from '../../components/common/NluOpportunityTriggeringSingleIntentView';
import { SelectWrapper } from '../../components/common/SelectWrapper';
import { CommonValidator } from '../../util/CommonValidator';
import { SUBSCRIPTION_UPSELL_TYPE_OPTIONS } from '../../constants/subscriptionUpsellTypeOptions';
import { SubscriptionUpsellType } from '../../models/SubscriptionUpsellType';

interface ISuggestedFeatureSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

interface ISlotNameState {
    slotName?: string;
}

export class SuggestedFeatureSectionView extends React.Component<ISuggestedFeatureSectionViewProps, ISlotNameState> {

    constructor(props: ISuggestedFeatureSectionViewProps) {
        super(props);
        this.state = {slotName : ''};
    }

    setSlotName(event: CustomEvent<Input.ChangeDetail>) {
        this.setState({slotName: event.detail.value});
    }

    onSegmentsEnter(nluSlotName: string[]) {
        const { slotName }  = this.state;

        const segments = nluSlotName ? nluSlotName : [];
        const inputString = slotName;

        inputString?.split(',')
            .filter(tag => (tag.length > 0 && !segments.includes(tag)))
            .map((tag) => { segments.push(tag);});

        this.setState({slotName : ''});
        this.updateSuggestedFeature(undefined, undefined, nluSlotName);
    }

    updateSuggestedFeature(nluDomain?: string, nluIntent?: string, nluSlotName?: string[], featureUtterance?: string) {
        const { dispatch } = this.props;
        dispatch(pushSuggestedFeature(nluDomain, nluIntent, nluSlotName, featureUtterance));

        this.forceUpdate();
    }

    updateBusinessInfo(vertical?: string, domain?: string) {
        const { dispatch } = this.props;
        dispatch(pushBusinessInfo(vertical, domain));
    }

    updateSubscriptionUpsellType(subscriptionUpsellType?: SubscriptionUpsellType) {
        const { dispatch } = this.props;
        dispatch(pushSubscriptionUpsellType(subscriptionUpsellType));
    }

    returnDomainOptions = (businessInfo?: IBusinessMetadata): Select.Option[] => {
        if (businessInfo && businessInfo.vertical && BUSINESS_DOMAIN_DROPDOWN_OPTIONS[businessInfo.vertical]) {
           return BUSINESS_DOMAIN_DROPDOWN_OPTIONS[businessInfo.vertical];
        } else {
            return [];
        }
    }

    render () {
        const { experience, isEditing, isUpdating, updateCandidate } = this.props;

        if (!experience) {
            return <FormSectionView title='Suggested Feature'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const renderData: INluInfo | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getSuggestedFeature()
            : experience.suggestedFeature;

        const businessInfo: IBusinessMetadata | undefined = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
            ? updateCandidate.getBusinessInfo()
            : experience.businessInfo);

        const subscriptionUpsellType: SubscriptionUpsellType | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getSubscriptionUpsellType()
            : experience.subscriptionUpsellType;

        const shouldDisableInput = !isEditing || isUpdating;

        const descriptionString = 'If you change suggested Intent after configuring AlexaLab metrics, ' +
        'please make sure you also update AlexaLab metric configuration before sending for approval';

        const customSuggestedIntentWarning = `Experience with this suggested intent will not be automatically dialed up after it reaches Live 50%, for it is suggesting a custom CIF event and doesn't have sufficient AlexaLab engagement metrics.
        Please apply for an exception for dialing up to 100% by providing evidence of increased actions or engagement for the capability or domain that is being featured through your own offline analysis as supporting evidence.`;

        const isCifExperience = experience.type === 'CIF';

        return <FormSectionView title={isCifExperience ? <RequiredField fieldName='Suggested Feature' /> : <OptionalField fieldName='Suggested Feature' />} description='The feature that will be suggested to the customer'>
            {<Alert>
                {descriptionString}
            </Alert>}
            <br></br>
            <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root='true'>
                    <div className='awsui-util-mb-m'>
                        <FormField
                            label={isCifExperience ? <RequiredField fieldName='Intent Name' /> : <OptionalField fieldName='Intent Name' />}
                            description='NLU Intent of the feature being suggested. Input or select suggested intent.'
                        >
                            <Autosuggest
                                options = {TriggerIntentOptions()}
                                disabled = {shouldDisableInput}
                                id='experience.suggestedFeature.nluIntent'
                                controlId='experience.suggestedFeature.nluIntent'
                                value={renderData ? renderData.nluIntent : ''}
                                onChange={({ detail }: any) => this.updateSuggestedFeature(undefined, detail.value.trim())}
                        />
                        </FormField>
                        {renderData && IsCustomIntent(renderData.nluIntent ?? '') &&
                        <Alert>
                            {customSuggestedIntentWarning}
                        </Alert> }
                    </div>
                </div>
            </ColumnLayout>
            {<div className='awsui-util-mb-m'>
                <FormField
                    label={<OptionalField fieldName='Slot Name' />}
                    description='(Optional) One or more Slot Names for the golden utterance of the feature being suggested'
                >
                    <Input
                    id='experience.suggestedFeature.nluSlotName'
                    controlId='experience.suggestedFeature.nluSlotName'
                    disabled={shouldDisableInput}
                    onInput={(e) => this.setSlotName(e)}
                    value={this.state.slotName}
                    onKeypress={(e) => {
                        if (e.detail.keyCode === 13) {
                            this.onSegmentsEnter(renderData?.nluSlotName ? renderData.nluSlotName : []);
                        }
                    }}/>
                    {(renderData?.nluSlotName !== undefined && renderData?.nluSlotName !== null && renderData?.nluSlotName.length > 0)
                        ? <ContextualSegmentsTagsView
                              tags={renderData.nluSlotName}
                              disableTag={shouldDisableInput}
                              onDismiss={(e) => {
                                  const nluSlotNames = renderData.nluSlotName ? renderData.nluSlotName : [];
                                  nluSlotNames.splice(e.detail.itemIndex, 1);
                                  this.updateSuggestedFeature(undefined, undefined, nluSlotNames);
                              }} />
                        : <p className='awsui-util-status-inactive'>No slot name configured for this experience</p>}
                </FormField>
            </div>}
            {<div className='awsui-util-mb-m'>
                <FormField
                    label={<OptionalField fieldName='Golden Utterance' />}
                    description='Golden Utterance of the feature being suggested'
                >
                    <Input
                        readonly={shouldDisableInput}
                        placeholder='ex. Alexa, Help me get weather'
                        value={renderData ? renderData.featureUtterance : ''}
                        onChange={(e) => this.updateSuggestedFeature(undefined, undefined, undefined, e.detail.value.trim())}
                        id='experience.suggestedFeature.featureUtterance'
                        controlId='experience.suggestedFeature.featureUtterance' />
                </FormField>
            </div>}
            <ColumnLayout columns={3}>
                    <div data-awsui-column-layout-root='true'>
                        <FormField
                            label={<RequiredField fieldName='Vertical' />}
                            description='L1 Vertical pertaining to experience'>
                            <Select
                                id='experience.suggestedFeature.vertical'
                                controlId='experience.suggestedFeature.vertical'
                                placeholder='Select vertical'
                                options={BUSINESS_VERTICAL_DROPDOWN_OPTIONS}
                                disabled={shouldDisableInput}
                                selectedOption={{
                                    id: businessInfo ? (businessInfo.vertical ? businessInfo.vertical : '') : '',
                                    label: businessInfo ? (businessInfo.vertical ? businessInfo.vertical : '') : ''
                                }}
                                onChange={(e) => this.updateBusinessInfo(e.detail.selectedId, undefined)} />
                        </FormField>
                        <FormField
                            label={<RequiredField fieldName='Domain' />}
                            description='L2 Domain pertaining to experience'>
                            <Select
                                    id='experience.suggestedFeature.domain'
                                    controlId='experience.suggestedFeature.domain'
                                    placeholder='Select domain'
                                    options={this.returnDomainOptions(businessInfo)}
                                    disabled={shouldDisableInput || isNullOrUndefined(businessInfo) || isNullOrUndefined(businessInfo.vertical)}
                                    selectedOption={{
                                        id: businessInfo ? (businessInfo.domain ? businessInfo.domain : '') : '',
                                        label: businessInfo ? (businessInfo.domain ? businessInfo.domain : '') : ''
                                    }}
                                    onChange={(e) => this.updateBusinessInfo(undefined, e.detail.selectedId)} />
                        </FormField>
                        <FormField
                            label={<RequiredField fieldName='Subscription Upsell Type' />}
                            description='Upsell metadata tag'>
                            <SelectWrapper
                                id='experience.suggestedFeature.subscriptionUpsellType'
                                options={SUBSCRIPTION_UPSELL_TYPE_OPTIONS}
                                disabled={shouldDisableInput}
                                selectedId={subscriptionUpsellType}
                                validate={(data) => {
                                    CommonValidator.isSubscriptionUpsellFieldValid(data);
                                }}
                                onChange={(e) => this.updateSubscriptionUpsellType(e.detail.selectedId as SubscriptionUpsellType)} />
                        </FormField>
                    </div>
                </ColumnLayout>
        </FormSectionView>;
    }
}

const ContextualSegmentsTagsView = (props: { tags: string[],
    disableTag: boolean,
    onDismiss: (e: CustomEvent<TokenGroup.DismissDetail>) => void }) => {

    const { tags, disableTag, onDismiss } = props;

    const items = tags.map(tag => {
        return {
            label: tag,
            disabled: disableTag
        };
    });

    return <TokenGroup id={'experience.suggestedFeature.slotGroup'} items={items} onDismiss={onDismiss} />;
};

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(SuggestedFeatureSectionView);
