import { axios } from './axios';
import { API_ENDPOINT, GET_PERMISSIONS } from '../constants/lambdaEndpoints';

export async function getPermission(userAlias: string, operatorGroup?: string): Promise<string[]> {
    const searchParams = new URLSearchParams();
    appendParamIfPresent(searchParams, 'userAlias', userAlias);
    appendParamIfPresent(searchParams, 'operatorGroup', operatorGroup);
    const response = await axios.get(`${API_ENDPOINT}${GET_PERMISSIONS}?${searchParams.toString()}`);

    return response.data;
}

const appendParamIfPresent = (params: URLSearchParams, key: string, value?: string) => {
    if (value) {
        params.append(key, value);
    }
};
