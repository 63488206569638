import { AppLayout } from '@amzn/awsui-components-react';
import * as React from 'react';
import { Component } from 'react';

import Navigation from '../containers/Navigation';
import Routes from '../routes';


export class App extends Component {
    public render() {
        return (
            <div className='awsui'>
                <AppLayout
                    className='awsui-util-no-gutters'
                    content={<Routes />}
                    navigation={<Navigation />}
                    toolsHide={true}
                />
            </div>
        );
    }
}
