const Intents = [
    'AboutAlexaQAIntent',
    'AddToListIntent',
    'AnnounceIntent.SpeechFinishedEvent',
    'BrowseGalleryIntent',
    'BrowseMemoryIntent',
    'BrowseNotificationIntent',
    'BrowseRecipeListIntent',
    'BrowseReminderIntent',
    'BrowseShoppingListIntent',
    'CreateEventIntent',
    'DailyBriefingIntent.SpeechFinishedEvent',
    'EndCallIntent',
    'ExpressMusicPreferenceIntent',
    'GameListIntent',
    'GetContentIntent',
    'GetDirectionsIntent',
    'GetRecipeDetailsIntent',
    'GetTrafficIntent',
    'GetWeatherForecastIntent',
    'GreetingsIntent',
    'LaunchNativeAppIntent',
    'NavigateIntent',
    'PhaticIntent',
    'PlaybackFinishedEvent',
    'PlayMusicIntent',
    'PlayStationIntent',
    'QAIntent',
    'SearchPlaceIntent',
    'SearchRecipeIntent',
    'SetReminderIntent',
    'SetSleepTimerIntent',
    'SetValueIntent',
    'SportsBriefingIntent',
    'TranslateUtteranceIntent',
    'TurnOffApplianceIntent',
    'TurnOnApplianceIntent',
    'WhatDayIntent'
];

export const TriggerIntentOptions = () => {
    const results = Intents.map(intent => {return {'value':intent};});
    return results;
};
