import * as React from 'react';
import { Component } from 'react';
import { Table, Popover, ButtonDropdown } from '@amzn/awsui-components-react';
import { IFlattenedExperience } from '../../../models/FlattenedExperience';
import { RCPMetricsTimeRange, constructWeeklyMetricsForTableView, constructDailyMetricsForTableView, IRawRcpMetricsData, IRcpMetric, IRcpData, createMappedRcpMetrics, RcpMetricNames } from '../../../models/v2/metric/RCPMetrics';
import { AppState } from '../../../reducers';
import { connect } from 'react-redux';

export interface IRcpMetricsProps {
    dispatch: any;
    experience?: IFlattenedExperience;
    cxWeeklyMetrics?: IRawRcpMetricsData[];
    cxDailyMetrics?: IRawRcpMetricsData[];
}

export interface IRcpMetricsState {
    isRangeDaysOrWeeks: RCPMetricsTimeRange;
    rcpMetricDisplayData: IRawRcpMetricsData[];
}

export const OutputMetricData = ({ metricValue, metricName } : { metricValue?: number, metricName: string }) => {
    if (!metricValue && metricValue !== 0) {
        return <div className='awsui-util-status-inactive'>-</div>;
    }

    if (metricName === RcpMetricNames.VoiceRate || metricName === RcpMetricNames.TouchRate || metricName === RcpMetricNames.TotalRate) {
        return <div>{`${(metricValue * 100).toFixed(3)}%`}</div>;
    } else {
        return <div>{`${metricValue.toLocaleString()}`}</div>;
    }
};

export const RcpDateCell = ({ metrics }: { metrics: IRcpData }) => {
    if (metrics) {
        const { weekStart, weekEnd, reportedAt } = metrics;

        if (weekStart) {
            return(
                <Popover
                    size='medium'
                    position='top'
                    content={
                        <div>
                            <p>{`Date range: ${weekStart} to ${weekEnd}`}</p>
                            <p>{`Refreshed: ${new Date(reportedAt).toISOString().split('T')[0]}`}</p>
                        </div>
                            }
                ><div>{metrics.period}</div></Popover>
            );
        } else {
            return (
                <Popover
                    size='medium'
                    position='top'
                    content={
                        <div>
                            <p>{`Refreshed: ${new Date(reportedAt).toISOString().split('T')[0]}`}</p>
                        </div>
                            }
                ><div>{metrics.period}</div></Popover>
            );
        }
    } else {
        return <div></div>;
    }
};

const rcpMetricsTableColumnDefinitions = (rcpMetricDisplayData: IRawRcpMetricsData[]) => {
    return(
    [
        {
            id: 'metric',
            header: ('Metric'),
            cell: (rcpMetric: IRcpMetric) => rcpMetric.metricName,
            width: 160,
        },
        {
            id: 'time_range1',
            header: () => <RcpDateCell metrics={rcpMetricDisplayData[0]?.metrics[6]}/>,
            cell: (rcpMetric: IRcpMetric) => <OutputMetricData metricValue={rcpMetric.values[6]} metricName={rcpMetric.metricName}/>,
            width: 180,
        },
        {
            id: 'time_range2',
            header: () => <RcpDateCell metrics={rcpMetricDisplayData[0]?.metrics[5]}/>,
            cell: (rcpMetric: IRcpMetric) => <OutputMetricData metricValue={rcpMetric.values[5]} metricName={rcpMetric.metricName}/>,
            width: 180,
        },
        {
            id: 'time_range3',
            header: () => <RcpDateCell metrics={rcpMetricDisplayData[0]?.metrics[4]}/>,
            cell: (rcpMetric: IRcpMetric) => <OutputMetricData metricValue={rcpMetric.values[4]} metricName={rcpMetric.metricName}/>,
            width: 180,
        },
        {
            id: 'time_range4',
            header: () => <RcpDateCell metrics={rcpMetricDisplayData[0]?.metrics[3]}/>,
            cell: (rcpMetric: IRcpMetric) => <OutputMetricData metricValue={rcpMetric.values[3]} metricName={rcpMetric.metricName}/>,
            width: 180,
        },
        {
            id: 'time_range5',
            header: () => <RcpDateCell metrics={rcpMetricDisplayData[0]?.metrics[2]}/>,
            cell: (rcpMetric: IRcpMetric) => <OutputMetricData metricValue={rcpMetric.values[2]} metricName={rcpMetric.metricName}/>,
            width: 180,
        },
        {
            id: 'time_range6',
            header: () => <RcpDateCell metrics={rcpMetricDisplayData[0]?.metrics[1]}/>,
            cell: (rcpMetric: IRcpMetric) => <OutputMetricData metricValue={rcpMetric.values[1]} metricName={rcpMetric.metricName}/>,
            width: 180,
        },
        {
            id: 'time_range7',
            header: () => <RcpDateCell metrics={rcpMetricDisplayData[0]?.metrics[0]}/>,
            cell: (rcpMetric: IRcpMetric) => <OutputMetricData metricValue={rcpMetric.values[0]} metricName={rcpMetric.metricName}/>,
            width: 180,
        }
    ]);
};

export class OdysseyExperienceRCPMetrics extends Component<IRcpMetricsProps, IRcpMetricsState> {
    constructor(props: IRcpMetricsProps) {
        super(props);
        const { cxWeeklyMetrics } = this.props;
        const weeklyMetricsDisplayData = (cxWeeklyMetrics) ? cxWeeklyMetrics : [];

        this.state = {
            isRangeDaysOrWeeks: RCPMetricsTimeRange.weeks,
            rcpMetricDisplayData: constructWeeklyMetricsForTableView(weeklyMetricsDisplayData)
        };
    }

   private changeDropdownTitle(dropdownDaysOrWeeks: string) {
        const { cxDailyMetrics, cxWeeklyMetrics } = this.props;
        const dailyMetricsDisplayData = (cxDailyMetrics) ? cxDailyMetrics : [];
        const weeklyMetricsDisplayData = (cxWeeklyMetrics) ? cxWeeklyMetrics : [];

        this.setState(prevState => {
            return {
                ...prevState,
                isRangeDaysOrWeeks: (dropdownDaysOrWeeks === RCPMetricsTimeRange.weeks) ? RCPMetricsTimeRange.weeks : RCPMetricsTimeRange.days,
                rcpMetricDisplayData: (dropdownDaysOrWeeks === RCPMetricsTimeRange.weeks)
                    ? constructWeeklyMetricsForTableView(weeklyMetricsDisplayData)
                    : constructDailyMetricsForTableView(dailyMetricsDisplayData)
            };
        });
    }

    public render() {
        const { isRangeDaysOrWeeks, rcpMetricDisplayData } = this.state;
        const { experience } = this.props;
        const rcpMetricDisplayDataFilteredToCurrentExperience = rcpMetricDisplayData.filter(e => e.experienceId === experience?.id);
        const mappedRcpMetricDisplayData = createMappedRcpMetrics(rcpMetricDisplayDataFilteredToCurrentExperience);

        return <div id='metrics.rcp' className='awsui-util-container awsui-util-no-gutters' style={{ marginBottom: '0' }}>
            <div className='awsui-util-container-header' style={{ display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    RCP Metrics
                </div>
                <div className='col-3 awsui-util-t-r'>
                    <ButtonDropdown
                        items={[
                            {
                            'text':'Last 7 days',
                            'id':RCPMetricsTimeRange.days
                            },
                            {
                            'text':'Last 7 weeks',
                            'id':RCPMetricsTimeRange.weeks
                            }
                        ]}
                        id='dropdown.rcp-metrics'
                        onItemClick={(e) => {this.changeDropdownTitle(e.detail.id);}}
                    >Last 7 {isRangeDaysOrWeeks}</ButtonDropdown>
                </div>
            </div>
            <Table
                id='table.rcp-metrics'
                columnDefinitions={rcpMetricsTableColumnDefinitions(rcpMetricDisplayData)}
                items={mappedRcpMetricDisplayData}
                loadingText='Loading RCP metrics'
                loading={false}
                empty={<div>No RCP metrics found</div>}
                resizableColumns={true}
            >
            </Table>
        </div>;
    }
}

const mapStateToProps = ({ odysseyExperienceDetailViewState, rcpCXDailyMetricsState, rcpCXWeeklyMetricsState }: AppState) => {
    return {
        experience: odysseyExperienceDetailViewState.experience || undefined,
        cxDailyMetrics: rcpCXDailyMetricsState.cxDailyMetrics,
        cxWeeklyMetrics: rcpCXWeeklyMetricsState.cxWeeklyMetrics
    };
};

export default connect(mapStateToProps)(OdysseyExperienceRCPMetrics);
