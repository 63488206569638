import * as React from 'react';
import {Wizard} from '@amzn/awsui-components-react';

import NluOpportunityTriggeringSectionView from '../sections/NluOpportunityTriggeringSectionView';
import DayTimeWindowSectionView from '../sections/DayTimeWindowSectionView';
import AudienceTypeSectionView from '../sections/AudienceTypeSectionView';
import RegionSectionView from '../sections/RegionSectionView';
import BullseyeSectionView from '../sections/BullseyeSectionView';
import ContentSectionView from '../sections/ContentSectionView';
import ContentVariablesSectionView from '../sections/ContentVariablesSectionView';
import CifDevicesSectionView from '../sections/CifDevicesSectionView';
import FeatureHintSectionView from '../sections/FeatureHintSectionView';
import DomainTagsSectionView from '../sections/DomainTagsSectionView';
import RemoteTagsSectionView from '../sections/RemoteTagsSectionView';
import ActivationDatesSectionView from '../sections/ActivationDatesSectionView';

import { ExperienceTypeSectionView } from '../../components/detail/creation/ExperienceTypeSectionView';
import { ExperienceType } from '../../models/Experience';
import SuggestedFeatureSectionView from '../sections/SuggestedFeatureSectionView';
import JourneySectionView from '../sections/JourneySectionView';
import PromotedCapabilitySectionView from '../sections/PromotedCapabilitySectionView';
import BasicCustomerTargetingView from '../sections/BasicCustomerTargetingView';
import BasicDevicesSectionView from '../sections/BasicDevicesSectionView';
import BasicContentSectionView from '../sections/BasicContentSectionView';

export const ExperienceWorkflow = (
        permissions: (string[] | undefined),
        onCXGuidelinesChecked: (checked?: boolean) => void
    ): Wizard.Step[] => {

    return [
        {
            title: 'CIF placement',
            description: 'This is the description.',
            content: <div>
                <NluOpportunityTriggeringSectionView triggerGrouping={'InclusionTriggers'} />
                <NluOpportunityTriggeringSectionView triggerGrouping={'ExclusionTriggers'} />
                <ActivationDatesSectionView />
                <DayTimeWindowSectionView />
            </div>
        },
        {
            title: 'Customer targeting',
            content: <div>
                <RegionSectionView />
                <BullseyeSectionView />
            </div>
        },
        {
            title: 'Create experience',
            content: <div>
                <ContentSectionView
                    onContentCXGuidelinesCheckedUpdate={(checked) => onCXGuidelinesChecked(checked)}
                />
                <ContentVariablesSectionView />
                <PromotedCapabilitySectionView />
  			    <SuggestedFeatureSectionView />
                <CifDevicesSectionView />
            </div>
        },
        {
            title: 'Advanced configuration',
            content: <div>
                <AudienceTypeSectionView />
                <FeatureHintSectionView />
                <DomainTagsSectionView />
                <RemoteTagsSectionView />
                <JourneySectionView />
            </div>
        },
        {
            title: 'Review experience',
            content: <div>
                <ExperienceTypeSectionView
                        selectedExperienceType={'CIF' as ExperienceType}
                        disableTypeSelection={true}
                        permissions={permissions}
                        isQuickCreate={false}/>

                <NluOpportunityTriggeringSectionView triggerGrouping={'InclusionTriggers'} />
                <NluOpportunityTriggeringSectionView triggerGrouping={'ExclusionTriggers'} />
                <ActivationDatesSectionView />
                <DayTimeWindowSectionView />

                <RegionSectionView />
                <BullseyeSectionView />

                <ContentSectionView
                    onContentCXGuidelinesCheckedUpdate={(checked) => onCXGuidelinesChecked(checked)}
                />
                <ContentVariablesSectionView />
                <PromotedCapabilitySectionView />
  			    <SuggestedFeatureSectionView />
                <CifDevicesSectionView />

                <AudienceTypeSectionView />
                <FeatureHintSectionView />
                <DomainTagsSectionView />
                <RemoteTagsSectionView />
            </div>
        }
    ];
};

export const BasicCIFWorkflow = (
        isAdmin: boolean,
        onCXGuidelinesChecked: (checked?: boolean) => void
    ): Wizard.Step[] => {
    return [
        {
            title: 'Basic CIF setup',
            content: <div>
                <ActivationDatesSectionView />
                <BasicCustomerTargetingView />
                <PromotedCapabilitySectionView />
                <BasicDevicesSectionView />
                <NluOpportunityTriggeringSectionView triggerGrouping={'InclusionTriggers'} />
                <BasicContentSectionView onContentCXGuidelinesCheckedUpdate={(checked) => onCXGuidelinesChecked(checked)}/>
            </div>
        }
    ];
};

