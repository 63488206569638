import * as React from 'react';
import { ColumnLayout, RadioGroup, FormField, Input, Spinner, Alert } from '@amzn/awsui-components-react';
import { connect } from 'react-redux';

import { FormSectionView } from '../../components/common/FormSectionView';
import BullseyeSegmentLoadingViewStoreConnected from '../BullseyeSegmentLoading';
import { DataSource } from '../../models/DataSource';
import { AppState } from '../../reducers/index';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate, IDataSourceViewAttributes, IRegionViewAttributes } from '../../models/ExperienceUpdateCandidate';
import { pushDataSourceSectionAction } from '../../actions/experienceEditViewActions';
import { getBullseyeAction, resetBullseyeMetadata } from '../../actions/experienceDetailViewActions';
import { Region } from '../../util/context';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { InputWrapper } from '../../components/common/InputWrapper';
import { NotificationValidator } from '../../util/NotificationValidator';
import { MARKETPLACE_TO_REGION } from '../../constants/marketplaces';
import { ExternalLink } from '../../components/common/LinkComponents';

interface IDataSourceTypeSectionProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

export class DataSourceTypeSectionView extends React.Component<IDataSourceTypeSectionProps> {
    updateDataSourceHandler(dataSource?: DataSource, bullseyeId?: string, snsTopic?: string, ttl?: string, publisherRoleForAutoCreatedSnsTopic?: string) {
        const { dispatch, updateCandidate } = this.props;

        if (bullseyeId && !Number.isNaN(Number(bullseyeId)) && updateCandidate) {
            const region: Region = MARKETPLACE_TO_REGION[updateCandidate.getRegion().marketplace || 'US'];
            dispatch(getBullseyeAction(region, Number(bullseyeId)));
        } else {
            bullseyeId = undefined;
            dispatch(resetBullseyeMetadata());
        }

        let ttlNumber: number | undefined;
        //  if ttlNumber is NaN, that means the input is bad. Since JavaScript parses
        //  an empty string as 0, but it is not valid in our case, we are expanding our
        //  definition of invalid input by including empty string
        if (ttl !== undefined && (Number.isNaN(Number(ttl)) || ttl === '')) {
            ttlNumber = NaN;

        } else if (ttl === undefined) {
            // undefined is still going to mean no update
            ttlNumber = undefined;
        } else {
            // then parse the number
            ttlNumber = Number(ttl);
        }

        dispatch(pushDataSourceSectionAction({
            dataSource,
            bullseyeId: bullseyeId === undefined ? undefined : Number(bullseyeId),
            snsTopic,
            publisherRoleForAutoCreatedSnsTopic,
            ttl: ttlNumber
        }));
    }

    render () {
        const { experience, isEditing, isUpdating, updateCandidate } = this.props;

        if (!experience) {
            return <FormSectionView title='Data source type'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const status = experience.status;
        const renderData: IDataSourceViewAttributes = (isEditing && updateCandidate)
            ? updateCandidate.getDataSourceInfo()
            : ExperienceUpdateCandidate.extractDataSource(experience);

        const region: IRegionViewAttributes = (isEditing && updateCandidate)
            ? updateCandidate.getRegion()
            : ExperienceUpdateCandidate.extractRegion(experience);

        const shouldDisableInput = !isEditing || isUpdating || !(status === 'DRAFT' || status === 'TESTABLE');
        return <FormSectionView
            title={<RequiredField fieldName='Data source type' />}
            description='Where customers are sourced. This section cannot be updated once sent for review' >
            <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root='true' className='awsui-util-spacing-v-s' >
                    <div>
                        <RadioGroup
                            id='experience.data-source'
                            className='awsui-util-mb-l'
                            value={renderData.dataSource}
                            items={getDataSourceOptions(shouldDisableInput, experience.operatorGroup)}
                            onChange={e => this.updateDataSourceHandler(e.detail.value as DataSource)} />
                    </div>
                    {DataSourceDetailsView(renderData, shouldDisableInput, region, this.updateDataSourceHandler.bind(this),  experience.operatorGroup)}
                </div>
            </ColumnLayout>
        </FormSectionView>;
    }
}

const getDataSourceOptions = (shouldDisableInput: boolean, operatorGroup?: string) => {
    const dataSourceOptions = [{
        controlId: 'experience.data-source.single-execution-bullseye',
        value: 'SINGLE_EXECUTION_BULLSEYE',
        label: 'Single execution bullseye',
        disabled: shouldDisableInput,
        description: 'A one-time snapshot of all customers in the bullseye will be taken at the time of approval, scheduling notifications for each customer.'
    }, {
        controlId: 'experience.data-source.repeatable-bullseye',
        value: 'REPEATABLE_BULLSEYE',
        label: 'Repeatable bullseye',
        disabled: shouldDisableInput,
        description: 'The bullseye will be queried on a regular basis, scheduling notifications for each NEW customer.'
    }, {
        controlId: 'experience.data-source.sns-topic',
        value: 'SNS_TOPIC',
        label: 'SNS Topic',
        disabled: shouldDisableInput,
        description: 'Whenever a customer is published to the SNS topic a notification will be scheduled for them.'
    },{
        controlId: 'experience.data-source.auto-created-sns-topic',
        value: 'AUTO_CREATED_SNS_TOPIC',
        label: 'Auto SNS Topic Creation',
        disabled: shouldDisableInput,
        description: 'Odyssey is creating an SNS topic for you, you can publish to the topic using your nominated role.'
    }];
    return dataSourceOptions;
};

interface IBullseyeRelatedDataSourceView {
    bullseyeId?: number;
    ttl?: number;
    dataSource?: DataSource;
    bullseyeVersion?: number;
    shouldDisableInput: boolean;
    region: IRegionViewAttributes;
    updateDataSourceHandler: (dataSource?: DataSource, bullseyeId?: string, snsTopic?: string, ttl?: string) => void;
}

const BullseyeRelatedDataSourceView = (props: IBullseyeRelatedDataSourceView) => {
    const { bullseyeId, ttl, dataSource, bullseyeVersion, shouldDisableInput, region, updateDataSourceHandler } = props;

    const bullseyeQueryEditorDescription = <p>Visit <ExternalLink href={'https://tiny.amazon.com/jpsex8c9/wamazindeBullUser'}>
    here</ExternalLink> to learn how to create a Bullseye segment via a Bullseye Query.</p>;

    return <div>
        <div className='awsui-util-mb-m'>
            <FormField
                label={<RequiredField fieldName='Bullseye' />}
                description='Enter the bullseye ID number'>
                <Alert>
                    {bullseyeQueryEditorDescription}
                </Alert>
                <InputWrapper
                    id='experience.bullseye'
                    value={(bullseyeId === undefined || bullseyeId === null ? '' : bullseyeId).toString()}
                    readonly={shouldDisableInput}
                    onInput={(input: string) => {
                        updateDataSourceHandler(undefined, input);
                    }}
                    validate={(bullseye) => {NotificationValidator.isBullseyeValid(bullseye, region.locale, region.enabled);}}/>
            </FormField>
        </div>
        {dataSource === 'REPEATABLE_BULLSEYE' && <div className='awsui-util-mb-m'>
            <FormField
                label='Time to live'
                description='By setting a TTL, customers that are removed from a bullseye will not be sent
                    a notification after TTL hours have passed from Odyssey processing their removal.'>
                <div className='awsui-row'>
                    <div className='col-3'>
                        <Input
                            id='experience.ttl'
                            controlId='experience.ttl'
                            type='number'
                            value={(ttl === undefined || ttl === null ? '' : ttl).toString()}
                            readonly={shouldDisableInput}
                            onInput={(e) => {
                                updateDataSourceHandler(undefined, undefined, undefined, e.detail.value);
                            }} />
                    </div>
                    <div className='col-9' >
                        <p>hours</p>
                    </div>
                </div>
            </FormField>
        </div>}
        <BullseyeSegmentLoadingViewStoreConnected />
        {bullseyeVersion && <p className='awsui-util-status-positive'>ver. {bullseyeVersion} in use</p>}
    </div>;
};

interface ISnsTopicRelatedDataSourceViewProps {
    snsTopic?: string;
    shouldDisableInput: boolean;
    region: IRegionViewAttributes;
    dataSource?: DataSource;
    showPublisherPrincipleInput: boolean;
    publisherRoleForAutoCreatedSnsTopic?: string;
    updateDataSourceHandler: (dataSource?: DataSource, bullseyeId?: string, snsTopic?: string, ttl?: string, publisherRoleForAutoCreatedSnsTopic?: string) => void;
}

const SnsTopicRelatedDataSourceView = (props: ISnsTopicRelatedDataSourceViewProps) => {
    const { snsTopic, publisherRoleForAutoCreatedSnsTopic, dataSource, showPublisherPrincipleInput, shouldDisableInput, region, updateDataSourceHandler } = props;

    return <div className='awsui-util-mb-m'>
        <FormField label='SNS Topic' description='Customer Event SNS Topic ARN' >
            <InputWrapper
                id='experience.sns-topic'
                readonly={shouldDisableInput || dataSource === 'AUTO_CREATED_SNS_TOPIC'}
                value={snsTopic}
                onInput={(input: string) => updateDataSourceHandler(undefined, undefined, input)}
                validate={(topic) => NotificationValidator.isSNSTopicValid(topic, region.locale, region.enabled)} />
        </FormField>
        {showPublisherPrincipleInput && <div className='awsui-util-mb-m'>
            <FormField label='Publisher Principle' description='IAM User/Role ARN to get permission to publish to SNS topic.'>
                <InputWrapper
                    id='experience.publisher-role-for-auto-created-sns-topic'
                    readonly={shouldDisableInput}
                    value={publisherRoleForAutoCreatedSnsTopic}
                    placeholder={'arn:aws:iam::000010000122:role/sendExperienceXyzMessage'}
                    onInput={(input: string) => updateDataSourceHandler(undefined, undefined, undefined, undefined, input)}
                    validate={(principle) => NotificationValidator.isPublisherRoleForAutoCreatedSnsTopicValid(principle, region.locale, region.enabled)} />
            </FormField>
        </div>}
    </div>;
};

const DataSourceDetailsView = (renderData: IDataSourceViewAttributes,
                                        shouldDisableInput: boolean,
                                        region: IRegionViewAttributes,
                                        updateDataSourceHandler: any,
                                        operatorGroup?: string) => {
    switch(renderData.dataSource) {
        case 'AUTO_CREATED_SNS_TOPIC':
        case 'SNS_TOPIC':
            return <SnsTopicRelatedDataSourceView
                snsTopic={renderData.snsTopic}
                shouldDisableInput={shouldDisableInput}
                updateDataSourceHandler={updateDataSourceHandler}
                dataSource={renderData.dataSource}
                publisherRoleForAutoCreatedSnsTopic={renderData.publisherRoleForAutoCreatedSnsTopic}
                showPublisherPrincipleInput={renderData.dataSource === 'AUTO_CREATED_SNS_TOPIC'}
                region={region} />;
        default:
            return <BullseyeRelatedDataSourceView
                bullseyeId={renderData.bullseyeId}
                ttl={renderData.ttl}
                dataSource={renderData.dataSource}
                bullseyeVersion={renderData.bullseyeVersion}
                shouldDisableInput={shouldDisableInput}
                updateDataSourceHandler={updateDataSourceHandler}
                region={region} />;
    }
};

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(DataSourceTypeSectionView);
