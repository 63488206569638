import { INavigationViewActions } from '../actions/navigationViewActions';
import {
    LOADED_USER_ALIAS
} from '../constants/navigationViewActionTypes';


const defaultUserAlias = 'nobody';

export interface INavigationViewState {
    userAlias: string;
}

export const navigationViewReducer = (
    state: INavigationViewState = { userAlias: defaultUserAlias },
    action: INavigationViewActions
): INavigationViewState => {
    switch (action.type) {
        case LOADED_USER_ALIAS:
            return {
                ...state,
                userAlias: action.userAlias || defaultUserAlias
            };
        default:
            return state;
    }
};
