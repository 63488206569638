import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../reducers/index';
import { FormField, Input } from '@amzn/awsui-components-react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { pushPromotedCapabilityAction, pushBypassCapabilityFilterAction } from '../../actions/experienceEditViewActions';
import { skillIDRegex } from '../../util/stringAndMappingHelper';

interface ISkillExceptionComponentProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

interface ISkillExceptionComponentState {
    inputValue: string;
}

export class SkillExceptionComponent extends React.Component<ISkillExceptionComponentProps, ISkillExceptionComponentState> {

    constructor(props: ISkillExceptionComponentProps) {
        super(props);
        const { experience } = this.props;
        this.state = {
            inputValue: experience?.capabilityId || ''
        };
    }

    onChangeInputHandler = (event: CustomEvent) => {
        const { dispatch } = this.props;
        this.setState((prevState) => ({
            ...prevState,
            inputValue: event.detail.value.trim()
        }));

        // update candidate cap id to skill id if the input value is a valid skill id or empty string
        // bypass capability engagement filter only if the input is a valid skill id
        if (skillIDRegex.test(event.detail.value.trim())) {
            dispatch(pushPromotedCapabilityAction({
                capabilityId: event.detail.value.trim()
            }));
            dispatch(pushBypassCapabilityFilterAction(true));
        } else if (event.detail.value.trim() === '') {
            dispatch(pushPromotedCapabilityAction({
                capabilityId: event.detail.value.trim()
            }));
            dispatch(pushBypassCapabilityFilterAction(false));
        } else {
            dispatch(pushBypassCapabilityFilterAction(false));
        }
    }

    render() {
        const { isEditing, isUpdating } = this.props;
        const shouldDisableInput = !isEditing || isUpdating;
        return (
            <FormField label='Skill ID'>
                <Input
                    id={'skill-exception-input'}
                    controlId={'skill-exception-input'}
                    readonly={shouldDisableInput}
                    value={this.state.inputValue}
                    onChange={this.onChangeInputHandler}
                />
                { this.state.inputValue !== '' && !skillIDRegex.test(this.state.inputValue) &&
                    <p className='awsui-util-status-negative'>Your current input is not a valid skill id,
                    and it won't be saved.</p>
                }
            </FormField>
        );
    }
}


const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(SkillExceptionComponent);
