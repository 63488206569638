import * as React from 'react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { ColumnLayout, FormField, Select, Spinner, Input, Alert } from '@amzn/awsui-components-react';
import { toSelectOptions } from '../../util/stringAndMappingHelper';
import { AppState } from '../../reducers/index';
import { pushBullseyeSectionAction, pushRegionSectionAction } from '../../actions/experienceEditViewActions';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { connect } from 'react-redux';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { LOCALE_TO_MARKETPLACE } from '../../constants/locales';
import { MARKETPLACE_TO_REGION } from '../../constants/marketplaces';
import { getBullseyeAction, resetBullseyeMetadata } from '../../actions/experienceDetailViewActions';
import BullseyeSegmentLoadingViewStoreConnected from '../BullseyeSegmentLoading';
import { Region } from '../../util/context';
import { ExternalLink } from '../../components/common/LinkComponents';

interface IBasicCustomerTargetingViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

export class BasicCustomerTargetingView extends React.Component<IBasicCustomerTargetingViewProps> {

    private updateRegionSection(marketplace?: string, locale?: string, enabled?: boolean) {
        const { dispatch } = this.props;
        dispatch(pushRegionSectionAction(marketplace, locale, enabled));
    }

    private updateBullseye(bullseyeId?: string) {
        const { updateCandidate, dispatch } = this.props;
        dispatch(pushBullseyeSectionAction(bullseyeId === undefined ? undefined : Number(bullseyeId)));

        if (bullseyeId && !Number.isNaN(Number(bullseyeId)) && updateCandidate) {
            const region: Region = MARKETPLACE_TO_REGION[updateCandidate.getRegion().marketplace || 'US'];
            dispatch(getBullseyeAction(region, Number(bullseyeId)));
        } else {
            dispatch(resetBullseyeMetadata());
        }
    }

    render () {
        const { isEditing, isUpdating, experience, updateCandidate } = this.props;
        if (!experience) {
            return <FormSectionView title='Region' description='You are required to select exactly one locale for your experience.' >
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const shouldDisableInput = experience.status === 'UNDER_REVIEW' || !isEditing || isUpdating;
        const type = experience.type;

        const marketplace = (isEditing && updateCandidate)
            ? updateCandidate.getRegion().marketplace
            : experience.marketplace;

        const locale = (isEditing && updateCandidate)
            ? updateCandidate.getRegion().locale
            : experience.locale;

        const bullseyeId: number | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getBullseye()
            : experience.enabledFilter?.bullseye;

        const shouldDisableMarketplaceInput = !locale || shouldDisableInput;

        const bullseyeQueryEditorDescription = <p>Visit <ExternalLink href={'https://tiny.amazon.com/jpsex8c9/wamazindeBullUser'}>
        here</ExternalLink> to learn how to create a Bullseye segment via a Bullseye Query.</p>;

        return <FormSectionView title='Customer Targeting' description='You are required to select a locale for your experience.
            Multi-locale marketplaces not yet supported. To host your experience in multiple locales,
            use the ”clone“ button after it has been tested and approved in one locale.' >
            <ColumnLayout columns={type === 'CIF' ? 2 : 1}>
                <div data-awsui-column-layout-root='true' style={{ marginBottom: '10px' }}>
                    <div>
                        <FormField
                            label={<RequiredField fieldName='Locale' />}
                            description='The language in which the content will be produced' >
                            <Select
                                id='experience.locale'
                                controlId='experience.locale'
                                disabled={shouldDisableInput}
                                options={toSelectOptions(Object.keys(LOCALE_TO_MARKETPLACE))}
                                selectedId={locale}
                                onChange={(event) => {
                                    const selectedLocale = event.detail.selectedId;
                                    this.updateRegionSection(LOCALE_TO_MARKETPLACE[selectedLocale], selectedLocale, true);
                                }}
                            />
                        </FormField>
                    </div>
                    {type === 'CIF' && <div>
                        <FormField
                            label={<div>Marketplace <i className='awsui-text-secondary' >(required)</i></div>}
                            description='The geographic area in which customers will be reached'>
                            <Select
                                id='experience.marketplace'
                                controlId='experience.marketplace'
                                disabled={shouldDisableMarketplaceInput}
                                options={toSelectOptions(Object.keys(MARKETPLACE_TO_REGION))}
                                selectedId={marketplace}
                                onChange={(event) => {
                                    const selectedMarketplace = event.detail.selectedId;
                                    this.updateRegionSection(selectedMarketplace);
                                }}
                            />
                        </FormField>
                    </div>}
                </div>
            </ColumnLayout>
            <br></br>
            <ColumnLayout columns={2} borders='vertical'>
                <div data-awsui-column-layout-root='true'>
                    <div>
                        <div className='awsui-util-mb-m'>
                            <FormField label='Bullseye' description='Enter the bullseye segment ID'>
                                <Alert>
                                    {bullseyeQueryEditorDescription}
                                </Alert>
                                <Input
                                    id='experience.bullseye'
                                    controlId='experience.bullseye'
                                    value={(bullseyeId || '').toString()}
                                    readonly={shouldDisableInput}
                                    onInput={(e) => {
                                        this.updateBullseye(e.detail.value.trim());
                                    }} />
                            </FormField>
                        </div>
                    </div>
                    <BullseyeSegmentLoadingViewStoreConnected />
                </div>
            </ColumnLayout>
        </FormSectionView>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(BasicCustomerTargetingView);
