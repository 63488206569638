import * as React from 'react';
import { useState } from 'react';

import { BreadcrumbGroup } from '@amzn/awsui-components-react';
import { getLinkableUrl, PAGE } from '../constants/page';
import { useLocation } from 'react-router-dom';
import HomeCardSuggestionsTable from '../components/list/HomeCardSuggestionsTable';

/**
 * HLC (higher level components) for hooks
 */
export function HomeCardSuggestions(props: {}) {
    const search = useLocation().search;
    const searchCriteriaParams = new URLSearchParams(search).get('search');
    const [value, setState] = useState(searchCriteriaParams);

    return (
        <div>
            <div style={{ margin: '30px' }}>
                <div style={{ marginBottom: '20px' }}>
                    <BreadcrumbGroup label='Breadcrumbs' items={[{
                        text: 'Odyssey',
                        href: getLinkableUrl(PAGE.Home),
                    }, {
                        text: 'Home Card Suggestions',
                        href: getLinkableUrl(PAGE.HomeCardSuggestions),
                    }]} />
                </div>
                <HomeCardSuggestionsTable updateFilteringCriteria={(searchCriteriaString: string) => {
                    setState(searchCriteriaString);
                    window.location.assign(getLinkableUrl(`${PAGE.HomeCardSuggestions}?search=${searchCriteriaString}`));
                }} searchCriteriaString={value} />
            </div>
        </div>
    );
}
