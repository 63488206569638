import * as React from 'react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { FormField, TokenGroup, Spinner, Input, Alert, Badge, Select, ColumnLayout } from '@amzn/awsui-components-react';
import { IContextualSegments, ManagementLogicalOperator } from '../../models/Filter';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { pushDomainTagsSectionAction } from '../../actions/experienceEditViewActions';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { toSelectOptions } from '../../util/stringAndMappingHelper';

interface IDomainTagsSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

interface IDomainTagsSectionViewState {
    includeSegment?: string;
    excludeSegment?: string;
    includeSegmentsLogicalOperator?: ManagementLogicalOperator;
    excludeSegmentsLogicalOperator?: ManagementLogicalOperator;
}


export class DomainTagsSectionView extends React.Component<IDomainTagsSectionViewProps, IDomainTagsSectionViewState> {

    constructor(props: IDomainTagsSectionViewProps) {
        super(props);
        this.state = {includeSegment: '', excludeSegment: ''};
    }

    setIncludeTag(event: CustomEvent<Input.ChangeDetail>) {
        this.setState({includeSegment: event.detail.value, excludeSegment: this.state.excludeSegment});
    }

    setExcludeTag(event: CustomEvent<Input.ChangeDetail>) {
        this.setState({includeSegment: this.state.includeSegment, excludeSegment: event.detail.value});
    }

    updateDomainTags(contextualSegments: IContextualSegments) {
        const { dispatch } = this.props;
        dispatch(pushDomainTagsSectionAction(contextualSegments));
    }

    onSegmentsEnter(contextualSegments: IContextualSegments, segmentType: 'include' | 'exclude') {
        const { includeSegment, excludeSegment }  = this.state;
        const { includeSegments, excludeSegments } = contextualSegments;
        const { includeSegmentsLogicalOperator, excludeSegmentsLogicalOperator } = contextualSegments;

        const segments = (segmentType === 'include') ? includeSegments : excludeSegments;
        const inputString = (segmentType === 'include') ? includeSegment : excludeSegment;

        inputString?.split(/[,|]+/)
            .map(tag => tag.trim())
            .filter(tag => (tag.length > 0 && !segments.includes(tag)))
            .map((tag) => { segments.push(tag);});

        this.setState({includeSegment: (segmentType === 'include') ? '' : includeSegment,
            excludeSegment: (segmentType === 'exclude') ? '' : excludeSegment});
        this.updateDomainTags({includeSegments, excludeSegments, includeSegmentsLogicalOperator, excludeSegmentsLogicalOperator});
    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate } = this.props;

        if (!experience) {
            return <FormSectionView title={'Domain Tags'}>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const contextualSegments = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
            ? updateCandidate.getDomainTags()
            : ExperienceUpdateCandidate.extractContextualSegments(experience));

        const shouldDisableInput = !isEditing || isUpdating;
        const inclusionDescription = `List of contextual segment tags where ${logicalOperatorToDescriptor(contextualSegments.includeSegmentsLogicalOperator)} MUST be present for this experience to match the incoming opportunity.`;
        const exclusionDescription = `List of contextual segment tags where ${logicalOperatorToDescriptor(contextualSegments.excludeSegmentsLogicalOperator)} MUST NOT be present for this experience to match the incoming opportunity.`;

        return <FormSectionView
            title='Domain Tags'
            description='Tags specified by the host domain’s speechlet or
                skill during a customer dialog, and provided to Odyssey
                as part of the CIF opportunity. Odyssey does not provide
                officially supported tags; please work with domain teams directly.'>

            {!shouldDisableInput && <div className='awsui-util-mb-m' >
                <Alert type='info' header='Important Note'>
                    Type one of more tag names separated by <Badge color='grey'>COMMA ,</Badge> and hit <Badge color='blue' >ENTER ⏎</Badge> to generate tag(s).
                </Alert>
            </div>}
            <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root='true'>
                    <FormSectionView title={
                        <div className='awsui-util-action-stripe'>
                            <div className='awsui-util-action-stripe-title'>
                                <h2 className='awsui-util-d-ib'>
                                    Inclusion tags
                                </h2>
                            </div>
                            <div className='awsui-util-action-stripe-group'>
                                <Select
                                    id='experience.domain-tags-inclusion-logical-operator'
                                    controlId='experience.domain-tags-inclusion-logical-operator'
                                    disabled={shouldDisableInput}
                                    options={toSelectOptions(Object.values(ManagementLogicalOperator)).map(option => {
                                        return {...option, description: `${logicalOperatorToDescriptor(option.id as ManagementLogicalOperator)} should be included`};
                                    })}
                                    selectedId={contextualSegments.includeSegmentsLogicalOperator}
                                    onChange={(e) => {
                                        const includeSegmentsLogicalOperator = e.detail.selectedId as ManagementLogicalOperator;
                                        this.updateDomainTags({
                                            includeSegments: contextualSegments.includeSegments,
                                            excludeSegments: contextualSegments.excludeSegments,
                                            includeSegmentsLogicalOperator,
                                            excludeSegmentsLogicalOperator: contextualSegments.excludeSegmentsLogicalOperator
                                        });
                                    }}/>
                            </div>
                        </div>} description={inclusionDescription}>
                        <FormField className='awsui-util-mb-xl' >
                            <Input
                                id='experience.domain-tags-inclusion'
                                controlId='experience.domain-tags-inclusion'
                                disabled={shouldDisableInput}
                                onInput={(e) => this.setIncludeTag(e)}
                                value={this.state.includeSegment}
                                onKeyup={(e) => {
                                    if (e.detail.keyCode === 13) {
                                        this.onSegmentsEnter(contextualSegments, 'include');
                                    }
                                }}/>
                            {(contextualSegments.includeSegments.length > 0)
                                ? <ContextualSegmentsTagsView
                                    tags={contextualSegments.includeSegments}
                                    type='inclusion'
                                    disableTag={shouldDisableInput}
                                    onDismiss={(e) => {
                                        const includeSegments = contextualSegments.includeSegments;
                                        includeSegments.splice(e.detail.itemIndex, 1);
                                        this.updateDomainTags({
                                            includeSegments,
                                            excludeSegments: contextualSegments.excludeSegments,
                                            includeSegmentsLogicalOperator: contextualSegments.includeSegmentsLogicalOperator,
                                            excludeSegmentsLogicalOperator: contextualSegments.excludeSegmentsLogicalOperator
                                            });
                                    }} />
                                : <p className='awsui-util-status-inactive'>No inclusion tags configured for this experience</p>}
                            </FormField>
                    </FormSectionView>
                    <FormSectionView title={
                        <div className='awsui-util-action-stripe'>
                            <div className='awsui-util-action-stripe-title'>
                                <h2 className='awsui-util-d-ib'>
                                    Exclusion tags
                                </h2>
                            </div>
                            <div className='awsui-util-action-stripe-group'>
                                <Select
                                    id='experience.domain-tags-exclusion-logical-operator'
                                    controlId='experience.domain-tags-exclusion-logical-operator'
                                    disabled={shouldDisableInput}
                                    options={toSelectOptions(Object.values(ManagementLogicalOperator)).map(option => {
                                        return {...option, description: `${logicalOperatorToDescriptor(option.id as ManagementLogicalOperator)} tags should be excluded`};
                                    })}
                                    selectedId={contextualSegments.excludeSegmentsLogicalOperator}
                                    onChange={(e) => {
                                        const excludeSegmentsLogicalOperator = e.detail.selectedId as ManagementLogicalOperator;
                                        this.updateDomainTags({
                                            includeSegments: contextualSegments.includeSegments,
                                            excludeSegments: contextualSegments.excludeSegments,
                                            includeSegmentsLogicalOperator: contextualSegments.includeSegmentsLogicalOperator,
                                            excludeSegmentsLogicalOperator
                                        });
                                    }}/>
                            </div>
                        </div>} description={exclusionDescription}>
                        <FormField className='awsui-util-mb-xl' >
                            <Input
                                id='experience.domain-tags-exclusion'
                                controlId='experience.domain-tags-exclusion'
                                disabled={shouldDisableInput}
                                onInput={(e) => this.setExcludeTag(e)}
                                value={this.state.excludeSegment}
                                onKeyup={(e) => {
                                    if (e.detail.keyCode === 13) {
                                        this.onSegmentsEnter(contextualSegments, 'exclude');
                                    }
                                }}/>
                            {(contextualSegments.excludeSegments.length > 0)
                                ? <ContextualSegmentsTagsView
                                    tags={contextualSegments.excludeSegments}
                                    type='exclusion'
                                    disableTag={shouldDisableInput}
                                    onDismiss={(e) => {
                                        const excludeSegments = contextualSegments.excludeSegments;
                                        excludeSegments.splice(e.detail.itemIndex, 1);
                                        this.updateDomainTags({
                                            includeSegments: contextualSegments.includeSegments,
                                            excludeSegments,
                                            includeSegmentsLogicalOperator: contextualSegments.includeSegmentsLogicalOperator,
                                            excludeSegmentsLogicalOperator: contextualSegments.excludeSegmentsLogicalOperator
                                            });
                                    }} />
                                : <p className='awsui-util-status-inactive'>No exclusion tags configured for this experience</p>}
                        </FormField>
                    </FormSectionView>
                </div>
            </ColumnLayout>
        </FormSectionView>;
    }
}

const logicalOperatorToDescriptor = (logicalOperator: ManagementLogicalOperator): string => {
    if (ManagementLogicalOperator.OR === logicalOperator) {
        return 'AT LEAST ONE tag';
    } else {
        return 'ALL tags';
    }
};

const ContextualSegmentsTagsView = (props: { tags: string[],
    type: 'inclusion' | 'exclusion'
    disableTag: boolean,
    onDismiss: (e: CustomEvent<TokenGroup.DismissDetail>) => void }) => {

    const { type, tags, disableTag, onDismiss } = props;

    const items = tags.map(tag => {
        const item: TokenGroup.Item = {
            label: tag,
            disabled: disableTag
        };

        if (tag.trim() !== tag) {
            item.tags = ['WARNING: token has leading or trailing whitespace'];
        }

        return item;
    });

    return <TokenGroup id={`experience.token-group-${type}`} items={items} onDismiss={onDismiss} />;
};

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(DomainTagsSectionView);
