import * as React from 'react';
import { BreadcrumbGroup, Button, ColumnLayout, FormSection, Spinner } from '@amzn/awsui-components-react';
import { getLinkableUrl, PAGE } from '../../constants/page';
import { useLocation } from 'react-router-dom';
import ProviderConfigsTable from '../../components/list/v2/ProviderConfigsTable';
import { useSelector } from 'react-redux';
import { AppState } from '../../reducers';
import moment from 'moment';
import { ISpiProviderConfigs } from '../../models/spiProviderConfig/ISpiProviderOnboarding';

export const SpiProviderOnboardingWidget = () => {
    const { isLoading, providerConfigs } = useSelector((state: AppState) => state.spiProviderOnboardingState);
    const location = useLocation();

    const formattedProviderConfigs: ISpiProviderConfigs = { providerConfigs };

    const fromTimestampToFormattedDateTime = (timestamp: number | string) => {
        return moment(timestamp).format('MM/DD/YYYY HH:mm UTCZ');
    };

    const exportToJsonOrCSV = () =>  {
        const fileName = `Spi Provider Configurations ${fromTimestampToFormattedDateTime(Date.now())}.json`;
        const blob = new Blob([JSON.stringify(formattedProviderConfigs)], {type: 'text/plain;charset=utf-8'});
        saveAs(blob, fileName);

    };

    return (
        <div>
            <div style={{ margin: '30px' }}>
                <div style={{ marginBottom: '20px' }}>
                    <BreadcrumbGroup label='Breadcrumbs.spiProviders' items={[{
                        text: 'Odyssey',
                        href: getLinkableUrl(PAGE.Home),
                    }, {
                        text: 'Admin Dashboard',
                        href: getLinkableUrl(PAGE.AdminDashboard)
                    }, {
                        text: 'SPI Providers',
                        href: getLinkableUrl(location.pathname),
                    }]} />
                </div>
                <ColumnLayout columns={2}>
                    <div data-awsui-column-layout-root='true'>
                            <FormSection header={
                                <div>
                                    <div className='awsui-util-t-l'>
                                    SPI Provider Configurations JSON
                                    </div>
                                    <div className='awsui-util-t-r'>
                                        <Button
                                            id='button.export-providers'
                                            icon='external'
                                            iconAlign='right'
                                            loading={isLoading}
                                            disabled={isLoading}
                                            onClick={e =>{
                                                exportToJsonOrCSV();
                                            }}
                                            > Export </Button>
                                    </div>
                                </div>
                            }>
                                {isLoading
                                ?
                                <Spinner size='large'/>
                                :
                                <pre style={{ flex: 1, padding: '10px', whiteSpace: 'pre-wrap' }}>
                                    {JSON.stringify(formattedProviderConfigs, null, 2)}
                                </pre>
                                }
                            </FormSection>
                        <ProviderConfigsTable/>
                    </div>
                </ColumnLayout>
            </div>
        </div>
    );
};

