import * as React from 'react';
import { connect } from 'react-redux';
import { BypassPauseListSectionView } from './sections/BypassPauseListView';
import { getBypassPauseListAction, updateBypassPauseListAction } from '../actions/bypassPauseListActions';
import { IBypassPauseList } from '../models/bypassPauseList/BypassPauseList';
import { AppState } from '../reducers';
import { Button, Flash, FormField, Icon, Modal, Textarea } from '@amzn/awsui-components-react';
import { trimAndDedupeStringList } from '../util/stringAndMappingHelper';


interface BypassPauseListUpdateWidgetProps {
    dispatch: any;
    isLoading: boolean;
    isBypassPauseListUpdated: boolean;
    errorUpdating?: Error;
    errorLoading?: Error;
    bypassPauseList?: IBypassPauseList;
}

interface BypassPauseListUpdateWidgetState {
    updateBypassPauseListGroupIds?: string[];
    updateBypassPauseListExperimentIds?: string[];
    isShowingConfirmationModal: boolean;
}

class BypassPauseListUpdateWidget extends React.Component<BypassPauseListUpdateWidgetProps, BypassPauseListUpdateWidgetState> {
    constructor(props: BypassPauseListUpdateWidgetProps) {
        super(props);

        this.state = {
            updateBypassPauseListGroupIds: undefined,
            updateBypassPauseListExperimentIds: undefined,
            isShowingConfirmationModal: false
        };
    }

    dismissConfirmationModal() {
        this.setState({
            isShowingConfirmationModal: false
        });
    }

    showConfirmationModal() {
        this.setState({
            isShowingConfirmationModal: true
        });
    }

    updateBypassPauseListGroupIds(value: string[]) {
        this.setState({
            updateBypassPauseListGroupIds: value
        });
    }

    updateBypassPauseListExperimentIds(value: string[]) {
        this.setState({
            updateBypassPauseListExperimentIds: value
        });
    }

    copyBypassPauseListToEditPane() {
        const { bypassPauseList } = this.props;
        if (bypassPauseList) {
            this.setState({
                updateBypassPauseListExperimentIds: bypassPauseList.experimentIds,
                updateBypassPauseListGroupIds: bypassPauseList.groupIds
            });
        }
    }

    newBypassPauseListToEditPane() {
        this.setState({
            updateBypassPauseListExperimentIds: [],
            updateBypassPauseListGroupIds: []
        });
    }

    saveBypassPauseList() {
        const { dispatch } = this.props;
        const { updateBypassPauseListExperimentIds, updateBypassPauseListGroupIds } = this.state;

        if (updateBypassPauseListExperimentIds !== undefined && updateBypassPauseListGroupIds !== undefined) {
            dispatch(updateBypassPauseListAction({
                experimentIds: trimAndDedupeStringList(updateBypassPauseListExperimentIds),
                groupIds: trimAndDedupeStringList(updateBypassPauseListGroupIds),
                type: 'BypassPauseList'
            }));
            this.resetBypassPauseList();
        }
        this.dismissConfirmationModal();
    }

    resetBypassPauseList() {
        this.setState({
            updateBypassPauseListExperimentIds: undefined,
            updateBypassPauseListGroupIds: undefined
        });
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(getBypassPauseListAction());
    }

    componentDidUpdate() {
        const { isBypassPauseListUpdated, dispatch, isLoading } = this.props;
        if (isBypassPauseListUpdated && !isLoading) {
            dispatch(getBypassPauseListAction());
        }
    }

    render() {
        const { isLoading, errorUpdating, bypassPauseList } = this.props;

        const { updateBypassPauseListExperimentIds, updateBypassPauseListGroupIds } = this.state;
        return <div className='awsui-util-no-gutter'>
            {errorUpdating && <Flash type='error' dismissible={true} >{errorUpdating.message}</Flash>}
            <div className='awsui-util-m-xl'>
                <BypassPauseListSectionView />
                <div className='awsui-util-m-l'>
                    <Button
                        id='button.add-bypass-pause-list'
                        onClick={this.newBypassPauseListToEditPane.bind(this)}
                        disabled={ bypassPauseList !== undefined || isLoading}
                        icon='add-plus'>Create new bypass list</Button>
                    <Button
                        id='button.edit-bypass-pause-list'
                        onClick={this.copyBypassPauseListToEditPane.bind(this)}
                        icon='copy' disabled={!bypassPauseList || isLoading}
                    >Edit existing bypass pause list</Button>
                </div>
                {(updateBypassPauseListExperimentIds !== undefined && updateBypassPauseListGroupIds !== undefined) && <div className='awsui-grid'>
                    <div className='awsui-row'>
                        <div className='col-6 awsui-util-spacing-v-s'>
                            <FormField label='Experiment Ids:' description='Bypass Automated Approval Pause logic for all experiences with the following Experiment IDs, starting with new line'>
                                <Textarea id='bypassPauseList.experimentIds' placeholder='Bypass pause list experimentIds, starting with new line"'
                                value={updateBypassPauseListExperimentIds.toString().replace(/,/g, '\n')}onInput={(event: CustomEvent<Textarea.ChangeDetail>) => {
                                    this.updateBypassPauseListExperimentIds(event.detail.value.split('\n'));
                                }} />
                            </FormField>
                            <FormField label='Operator Groups:' description='Bypass Automated Approval Pause logic for all experiences with the following Operator Groups, starting with new line'>
                                <Textarea
                                    id='bypassPauseList.groupIds'
                                    placeholder='Bypass pause list operator group, starting with new line'
                                    value={updateBypassPauseListGroupIds.toString().replace(/,/g, '\n')}
                                    onInput={(event: CustomEvent<Textarea.ChangeDetail>) => {
                                        this.updateBypassPauseListGroupIds(event.detail.value.split('\n'));
                                }} />
                            </FormField>
                        </div>
                    </div>
                    <div className='awsui-row'>
                        <div className='col-12'>
                            <Button id='button.pre-save-bypass-pause-list' onClick={this.showConfirmationModal.bind(this)} variant='primary' icon='upload' disabled={isLoading} >Save</Button>
                            <Button id='button.pre-cancel-bypass-pause-list' onClick={this.resetBypassPauseList.bind(this)} variant='normal' icon='close' disabled={isLoading} >Cancel</Button>

                        </div>
                    </div>
                </div>}
            </div>
            <Modal
                id='modal.update-bypass-pause-list-confirmation'
                visible={this.state.isShowingConfirmationModal}
                header='Publish bypass pause list'
                footer={<span className='awsui-util-f-r'>
                    <Button id='button.save-bypass-pause-list' onClick={this.saveBypassPauseList.bind(this)} variant='primary' icon='upload' disabled={isLoading} >Save</Button>
                    <Button id='button.cancel-bypass-pause-list' onClick={this.dismissConfirmationModal.bind(this)} variant='normal' icon='close' disabled={isLoading} >Cancel</Button>
                </span>}
                onDismiss={this.dismissConfirmationModal.bind(this)}>

                <p>
                    <Icon size='large' name='status-warning' variant='warning' /> By clicking save, this bypass pause list will take effect <em>immediately</em>. Are you sure?
                </p>
            </Modal>
        </div>;
    }
}

const mapStateToProps = ({ bypassPauseListState }: AppState) => {
    return {
        isLoading: bypassPauseListState.isLoading,
        isBypassPauseListUpdated: bypassPauseListState.updatedBypassPause,
        errorUpdating: bypassPauseListState.errorUpdating || undefined,
        errorLoading: bypassPauseListState.errorLoading || undefined,
        bypassPauseList: bypassPauseListState.bypassPauseList || undefined,
    };
};

export default connect(mapStateToProps)(BypassPauseListUpdateWidget);
