import { axios } from './axios';
import { API_ENDPOINT, GET_ODYSSEY_CONFIGURATION_PATH, PUT_ODYSSEY_CONFIGURATION_PATH } from '../constants/lambdaEndpoints';
import { IMonitoredExperiments } from '../models/monitoredExperiments/MonitoredExperiments';

export const MONITORING_ALLOWLIST_CONFIGURATION_FILE = 'monitoring-allowlist';

export async function getMonitoredExperiments(): Promise<IMonitoredExperiments | undefined> {
    const response = await axios.get(`${API_ENDPOINT}${GET_ODYSSEY_CONFIGURATION_PATH}/${MONITORING_ALLOWLIST_CONFIGURATION_FILE}`);

    if (response.status === 204) {
        return undefined;
    }

    return response.data;
}

export async function putMonitoredExperiments(monitoredExperiments: IMonitoredExperiments): Promise<void> {
    const response = await axios.put(`${API_ENDPOINT}${PUT_ODYSSEY_CONFIGURATION_PATH}/${MONITORING_ALLOWLIST_CONFIGURATION_FILE}`, monitoredExperiments);

    return response.data;
}
