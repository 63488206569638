import React from 'react';
import { Tooltip } from '@amzn/awsui-components-react';

export const CharacterCount = (props:{characters:number, limit: number}) => {
    const { characters, limit } = props;

    return <span>
        <Tooltip className={characters > limit ? 'awsui-util-status-negative' : ''} position='left' text='character limit'>
            {props.characters} /{props.limit}
        </Tooltip>
    </span>;
};
