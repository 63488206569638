import * as React from 'react';

import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { FormSectionView } from '../../components/common/FormSectionView';
import {connect} from 'react-redux';
import {AppState} from '../../reducers/index';
import { ColumnLayout, FormField, Input, Multiselect, Select, Spinner } from '@amzn/awsui-components-react';
import { LinkedJira } from '../../components/common/LinksRenderHelper';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { AutoFill, RequiredField } from '../../components/common/DescriptionAnnotations';
import { IMobileHomeCardContent } from '../../models/mobileHomeCard/MobileHomeCardContent';
import { pushMobileHomeCardContent, pushMobileHomeCardContext } from '../../actions/experienceEditViewActions';
import { extractMobileHomeCardContent, extractMobileHomeCardContext } from '../../util/mobileHomeCardHelper';
import { businessInfoMap } from '../../constants/businessInfo';
import { SubscriptionUpsellType } from '../../models/SubscriptionUpsellType';
import { IMobileHomeCardContext } from '../../models/mobileHomeCard/MobileHomeCardContext';
import { SUBSCRIPTION_UPSELL_TYPE_OPTIONS } from '../../constants/subscriptionUpsellTypeOptions';
import { SelectWrapper} from '../../components/common/SelectWrapper';
import { CommonValidator } from '../../util/CommonValidator';

interface IMobileCardExperienceDetailsSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

export const CampaignTypeOption = [
    {
        'label': 'Subscription',
        'id': 'Subscription',
        'description': 'Subscription'
    },
    {
        'label': 'Upsell',
        'id': 'Upsell',
        'description': 'Upsell'
    },
    {
        'label': 'Other Promotion',
        'id': 'OtherPromotion',
        'description': 'Exception required'
    }
] as Select.Option[];

interface IMobileCardExperienceDetailsSectionViewState {

    businessInfoSelectOptions?: Select.OptionsGroup[];
}

export class MobileCardExperienceDetailsSectionView extends React.Component<IMobileCardExperienceDetailsSectionViewProps, IMobileCardExperienceDetailsSectionViewState> {

    constructor(props: IMobileCardExperienceDetailsSectionViewProps) {
        super(props);
        this.state = {
            businessInfoSelectOptions: generateBusinessInfoGroups(),
        };

    }

    updateMobileHomeCardContent(mobileHomeCardContent?: IMobileHomeCardContent) {
        const { dispatch } = this.props;
        const templateType = mobileHomeCardContent?.templateType;
        const index = mobileHomeCardContent?.jiraLink?.lastIndexOf('/') || 0;
        const contentId = templateType === 'HeroTemplate' ? [
            mobileHomeCardContent?.contentProvider || '',
            mobileHomeCardContent?.cardName || '',
            mobileHomeCardContent?.jiraLink?.substring(index + 1) || ''
        ].join('-') : [
            mobileHomeCardContent?.contentProvider || '',
            mobileHomeCardContent?.campaignTag || '',
            mobileHomeCardContent?.jiraLink?.substring(index + 1) || ''
        ].join('-');
        const contentType = contentId;

        dispatch(pushMobileHomeCardContent({ ...mobileHomeCardContent, contentId, contentType }));
    }

    updateMobileCardContext(mobileHomeCardContext?: IMobileHomeCardContext) {
        const { dispatch } = this.props;
        dispatch(pushMobileHomeCardContext(mobileHomeCardContext));
    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate } = this.props;

        if (!experience) {
            return <FormSectionView title='Experience Details'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const mobileHomeCardContent: IMobileHomeCardContent | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getMobileHomeCardContent()
            : extractMobileHomeCardContent(experience);

        const mobileHomeCardContext: IMobileHomeCardContext | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getMobileHomeCardContext()
            : extractMobileHomeCardContext(experience);

        const shouldDisableInput = !isEditing || isUpdating;

        return <FormSectionView title='Experience Details'>

            <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root='true' style={{ marginBottom: '10px' }}>
                    <FormField
                        label={<RequiredField fieldName='Domain Team/Content Provider' />}
                        description='Your org unique identifier (publisher name)'>
                        <Input
                            readonly={shouldDisableInput}
                            placeholder='ex. AlexaEngagementMarketing'
                            value={mobileHomeCardContent?.contentProvider}
                            onChange={(e) => this.updateMobileHomeCardContent({ ...mobileHomeCardContent, contentProvider: e.detail.value.trim() })}
                            controlId='experience.contentProvider'
                            id='experience.contentProvider'
                        />
                    </FormField>

                    <FormField
                        label={<RequiredField fieldName='Alexa Mobile intake JIRA link' />}
                        description={<span>Create Jira <LinkedJira /> and share the link below</span>}>
                        <Input
                            readonly={shouldDisableInput}
                            id='experience.jira'
                            controlId='experience.jira'
                            placeholder='ex. https://issues.labcollab.net/browse/AMAX-19037'
                            value={mobileHomeCardContent?.jiraLink}
                            onChange={(e) => this.updateMobileHomeCardContent({ ...mobileHomeCardContent, jiraLink: e.detail.value.trim() })}
                        />
                    </FormField>
                    {mobileHomeCardContent?.templateType === 'HeroTemplate' ?
                        <>
                            <FormField
                                label={<RequiredField fieldName='cardName' />}
                                description={<span>Description of your card’s use case (e.g; createRoutine)</span>}>
                                <Input
                                    readonly={shouldDisableInput}
                                    placeholder='ex. createRoutine'
                                    value={mobileHomeCardContent?.cardName}
                                    controlId='experience.cardName'
                                    id='experience.cardName'
                                    onChange={e => {
                                        this.updateMobileHomeCardContent({ ...mobileHomeCardContent, cardName: e.detail.value.trim() });
                                    }}
                                />
                            </FormField>
                        </> :
                        <>
                            <FormField
                                label={<RequiredField fieldName='Campaign Type' />}
                                description={<span>Select campaign type</span>}>
                                <Select
                                    id='experience.campaignType'
                                    controlId='experience.campaignType'
                                    options={CampaignTypeOption}
                                    disabled={shouldDisableInput}
                                    selectedId={mobileHomeCardContent?.campaignTag}
                                    onChange={e => {
                                        this.updateMobileHomeCardContent({ ...mobileHomeCardContent, campaignTag: e.detail.selectedId });
                                    }}
                                />
                            </FormField>
                        </>
                    }

                    <FormField
                        label={<RequiredField fieldName='Subscription Upsell Type' />}
                        description={<span>Select subscription upsell type</span>}>
                        <SelectWrapper
                            id='experience.subscriptionUpsellMHC'
                            validate={(subscriptionUpsellType) => {
                                CommonValidator.isSubscriptionUpsellFieldValid(subscriptionUpsellType);
                            }}
                            options={SUBSCRIPTION_UPSELL_TYPE_OPTIONS}
                            disabled={shouldDisableInput}
                            selectedId={mobileHomeCardContext?.subscriptionUpsellType}
                            onChange={e => {
                                this.updateMobileCardContext({ ...mobileHomeCardContext, subscriptionUpsellType: e.detail.selectedId as SubscriptionUpsellType});
                            }}
                        />
                    </FormField>

                    <FormField
                        label={<AutoFill fieldName='Content Type' />}
                        description={<span>Content Type is used for metric tracking on tableau</span>}>
                        <Input
                            controlId='experience.contentType'
                            id='experience.contentType'
                            readonly={true}
                            placeholder='ex. AlexaEngagementMarketing-createRoutine-AMAX-19037'
                            value={mobileHomeCardContent?.contentType}
                        />
                    </FormField>

                    {mobileHomeCardContent?.templateType === 'DiscoveryTemplate' ?
                        <>
                            <FormField label={<RequiredField fieldName='Business Info' />} description='Select all relevant business info'>
                                <Multiselect
                                    id='experience.businessInfo-multiselect'
                                    controlId='experience.businessInfo-multiselect'
                                    selectedIds={mobileHomeCardContent.businessInfo}
                                    options={this.state.businessInfoSelectOptions}
                                    disabled={shouldDisableInput}
                                    onChange={(event) => {
                                        this.updateMobileHomeCardContent({ ...mobileHomeCardContent, businessInfo: event.detail.selectedIds });
                                    }}
                                    filteringType='auto'
                                    checkboxes={true}
                                />
                            </FormField>
                        </> : null
                    }



                </div>
            </ColumnLayout>

        </FormSectionView>;
    }
}


export const generateBusinessInfoGroups = (): Select.OptionsGroup[] => {

    const businessInfoGroups = Object.keys(businessInfoMap).map((vertical, index) => {
        return generateBusinessInfoGroup(vertical);
    });

    return businessInfoGroups;

};

export const generateBusinessInfoGroup = (vertical: string): Select.OptionsGroup => {

    const options: Select.Option[] = businessInfoMap[vertical]
        .map((domain, index) => {
            return {
                label: domain,
                id: `${vertical}-${domain}`,
                tags: [vertical]
            };
        });
    return {
        label: vertical,
        options
    };
};

const mapStateToProps = ({ odysseyExperienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: odysseyExperienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(MobileCardExperienceDetailsSectionView);
