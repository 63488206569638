import { IExperienceSlot, processSlots } from './ExperienceSlot';
import { IExperienceTargetUtterance, processTargetUtterances } from './ExperienceTargetUtterance';
import { ITargetPublisherData, processTargetPublisherData } from './TargetPublisherData';
import { filterEmptyStrings } from '../util/stringAndMappingHelper';
import { PromptDetails } from './PromptDetails';

export interface INluOpportunityTargeting {
    /**
     * Blueshift intent to trigger this opportunity.
     */
    bluIntent?: string;

    /**
     * List of slots to trigger the opportunity.
     */
    slots?: IExperienceSlot[];

    /**
     * The target utterance slot.
     */
    targetUtteranceSlot?: string;

    /**
     * Target Utterances (if any) for the experience.
     */
    targetUtterances?: IExperienceTargetUtterance[];

    /**
     * PublisherId's to target
     */
    publisherIdTargeting?: string[];

    /**
     * Application Categories to target
     */
    categoryTargeting?: string[];

    /**
     * Publisher custom data to target, multiple CifTargetPublisherData defined here indicates AND condition
     */
    targetPublisherData?: ITargetPublisherData[];

    /**
     * Exact slot name matching flag
     */
    exactSlotNameMatching?: boolean;

    /**
     * Friction Trigger prompt details
     */
    frictionPromptDetails?: PromptDetails[];
}

export const EMPTY_NLU_OPPORTUNITY_TARGETING: INluOpportunityTargeting = {};

export function buildNLUTrigger(): INluOpportunityTargeting {
    return { bluIntent: 'testIntent', exactSlotNameMatching: true, slots: [{ name: '', values: [''] }] };
}

export enum TargetUtteranceSlot {
    Question = 'Question',
    InterpretationFromEvi = 'InterpretationFromEvi'
}

export const isNotEmptyTargeting = (targeting: INluOpportunityTargeting): boolean => {
    return !!targeting.bluIntent ||
        (!!targeting.publisherIdTargeting && !!targeting.publisherIdTargeting.length) ||
        (!!targeting.categoryTargeting && !!targeting.categoryTargeting.length);
};

export const processNluOpportunityTargeting = (targeting: INluOpportunityTargeting): INluOpportunityTargeting => {
    return {
        ...targeting,
        slots: processSlots(targeting.slots),
        targetUtterances: processTargetUtterances(targeting.targetUtterances),
        publisherIdTargeting: filterEmptyStrings(targeting.publisherIdTargeting),
        categoryTargeting: filterEmptyStrings(targeting.categoryTargeting),
        targetPublisherData: processTargetPublisherData(targeting.targetPublisherData),
        frictionPromptDetails: targeting.frictionPromptDetails
    };
};
