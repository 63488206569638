import { Action, Dispatch } from 'redux';
import * as odysseyExperienceManagementGateway from '../../api/odysseyExperienceManagementGateway';
import {
    ERROR_FETCHING_ODYSSEY_EXPERIENCES_PAGINATE, RECEIVED_ODYSSEY_EXPERIENCES_PAGINATE,
    REQUESTED_ODYSSEY_EXPERIENCES_PAGINATE, UNSET_ODYSSEY_EXPERIENCES, UPDATED_PROGRESS
} from '../../constants/v2/odysseyExperienceListViewActionTypes';
import { IOdysseyExperience, IOdysseyExperiencePage } from '../../models/v2/IOdysseyExperience';
import { ExperienceType } from '../../models/Experience';


export interface ILoadOdysseyExperiencesAction extends Action {
    odysseyExperiences?: IOdysseyExperience[];
    lastEvaluatedKey?: string;
    error?: Error;
    succeeded?: number;
    failed?: number;
}

export const requestedOdysseyExperiencesPaginate = (): ILoadOdysseyExperiencesAction => {
    return {
        type: REQUESTED_ODYSSEY_EXPERIENCES_PAGINATE
    };
};

// Stage needs to be passed in for generating link to Amazon Approval
export const receivedOdysseyExperiencesPaginate = (odysseyExperiences: IOdysseyExperience[], lastEvaluatedKey?: string): ILoadOdysseyExperiencesAction => {
    return {
        type: RECEIVED_ODYSSEY_EXPERIENCES_PAGINATE,
        lastEvaluatedKey,
        odysseyExperiences
    };
};

export const errorFetchingOdysseyExperiencesPaginate = (error: Error): ILoadOdysseyExperiencesAction => {
    return {
        type: ERROR_FETCHING_ODYSSEY_EXPERIENCES_PAGINATE,
        error
    };
};

export const unsetOdysseyExperiences = (): ILoadOdysseyExperiencesAction => {
    return {
        type: UNSET_ODYSSEY_EXPERIENCES
    };
};

export const updatedProgressBar = (succeeded: number, failed: number): ILoadOdysseyExperiencesAction => {
    return {
        type: UPDATED_PROGRESS,
        succeeded,
        failed
    };
};

export const getOdysseyExperiencesPaginateAction = (limit: number, exclusiveStartKey?: string) => async (dispatch: Dispatch) => {
    if (!exclusiveStartKey) {
        dispatch(requestedOdysseyExperiencesPaginate());
    }

    try {
        const data: IOdysseyExperiencePage = await odysseyExperienceManagementGateway.getOdysseyExperiencesPaginate(limit, exclusiveStartKey);
        dispatch(receivedOdysseyExperiencesPaginate(data.experiments, data.lastEvaluatedKey ? encodeURI(data.lastEvaluatedKey) : data.lastEvaluatedKey));
    } catch (error) {
        dispatch(errorFetchingOdysseyExperiencesPaginate(error as Error));
    }
};

export const getOdysseyExperiencesWithTypePaginateAction = (experienceType: ExperienceType, limit: number, exclusiveStartKey?: string) => async (dispatch: Dispatch) => {
    if (!exclusiveStartKey) {
        dispatch(requestedOdysseyExperiencesPaginate());
    }

    try {
        const data: IOdysseyExperiencePage = await odysseyExperienceManagementGateway.getOdysseyExperiencesPaginate(limit, exclusiveStartKey);
        dispatch(receivedOdysseyExperiencesPaginate(data.experiments.filter(experience => experience.type === experienceType),
            data.lastEvaluatedKey ? encodeURI(data.lastEvaluatedKey) : data.lastEvaluatedKey));
    } catch (error) {
        dispatch(errorFetchingOdysseyExperiencesPaginate(error as Error));
    }
};
