import { ILoadBullseyeAction } from '../actions/experienceDetailViewActions';
import {
    LOADING_BULLSEYE_METADATA,
    LOADED_BULLSEYE_METADATA,
    ERROR_LOADING_BULLSEYE_METADATA
} from '../constants/experienceDetailViewActionTypes';
import { IBullseyeSegment } from '../models/BullseyeSegment';
import { RESET_BULLSEYE_METADATA } from '../constants/experienceDetailViewActionTypes';
import { Region } from '../util/context';

export interface IBullseyeMetadataState {
    isLoading: boolean;
    bullseye?: IBullseyeSegment;
    error?: Error;
    region?: Region;
    segmentId?: number;
}

export const bullseyeMetadataReducer = (
    state: IBullseyeMetadataState = { isLoading: false },
    action: ILoadBullseyeAction
): IBullseyeMetadataState => {
    switch (action.type) {
        case LOADING_BULLSEYE_METADATA:
            return {
                isLoading: true
            };
        case LOADED_BULLSEYE_METADATA:
            const { bullseye } = action;
            // reducer is at top level. it should resolve error
            // and get it ready for rendering.
            if (!bullseye) {
                return {
                    isLoading: false,
                    error: new Error('Loaded `undefined` bullseye, try refreshing the page')
                };
            }
            return {
                isLoading: false,
                bullseye,
                region: action.region,
                segmentId: action.segmentId
            };
        case ERROR_LOADING_BULLSEYE_METADATA:
            return {
                isLoading: false,
                error: action.error
            };
        case RESET_BULLSEYE_METADATA:
            return {
                isLoading: false

                // reset everything else to be undefined
            };
        default:
            return state;
    }
};
