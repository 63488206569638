import { IWeblabAllocation } from '../models/WeblabAllocation';

export const SUPPORTED_EXPOSURE_TREATMENTS = [
    0.2,
    1.0
];

export const LAUNCH_EXPOSURE_ALLOCATION = 100;

export const LAUNCH_EXPOSURE_MESSAGE = 'C: 0%, T1: 100%, No exposure: 0%';

export const CIF_SETUP_WORKFLOW_LINK = 'https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+CIF+Launch#Workflows-2135720411';

export const WEBLAB_ALLOC_C_50_T_50 : IWeblabAllocation = {
    'C' : 1,
    'T1' : 1
};
