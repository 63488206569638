import * as React from 'react';
import _ from 'lodash';
import { FormSectionView } from '../../components/common/FormSectionView';
import { ColumnLayout, FormField, Input, Textarea } from '@amzn/awsui-components-react';
import { AppState } from '../../reducers/index';
import { useDispatch, useSelector } from 'react-redux';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { ExternalLink } from '../../components/common/LinkComponents';
import TextareaWithCharLimit from '../../components/common/TextareaWithCharLimit';
import { IRCPDataSourceTypes, IRotatingContentPanelContent } from '../../models/rotatingContentPanel/RotatingContentPanelStructure';
import { pushRotatingContentPanelDisplayElements } from '../../actions/experienceEditViewActions';


const ambientHomeDesignDocUrl = 'https://wiki.labcollab.net/confluence/display/Design/Home+UX';
const writingGuidelinesUrl = 'https://wiki.labcollab.net/confluence/display/Design/Home+UX#ANCHORTEMPLATES';
const backgroundGuidelinesUrl = 'https://wiki.labcollab.net/confluence/display/Design/Home+UX#VISUALSTYLEGUIDE';
const mediaCentralUrl = 'https://console.harmony.a2z.com/media-central/search-browse/browse/1/AlexaSkills/2021/Display/Multimodal_Discovery_background';

const RCPDisplayElementsSectionView: React.FC = () => {
    const { isEditing, isUpdating, updateCandidate } = useSelector((state: AppState) => ( state.experienceEditViewState ));
    const { contents } = useSelector((state: AppState) => ({ contents: _.get(state, 'odysseyExperienceDetailViewState.experience.contents[0]', {}) }));
    const dispatch = useDispatch();

    const shouldDisableInput = !isEditing || isUpdating;
    const rotatingContentPanelContent = (!shouldDisableInput) ? updateCandidate?.getRotatingContentPanelContent()
        : (contents as IRotatingContentPanelContent);

    const [currentState, setState] = React.useState({
        headerText: rotatingContentPanelContent?.textWrappingDataSource?.headerText ?? 'Alexa Suggests',
        primaryText: rotatingContentPanelContent?.textWrappingDataSource?.primaryText,
        secondaryText: rotatingContentPanelContent?.textWrappingDataSource?.secondaryText,
        backgroundImageSource: rotatingContentPanelContent?.textWrappingDataSource?.backgroundImageSource
    });

    const onChangeHandler = (event: CustomEvent<Textarea.ChangeDetail | Input.ChangeDetail>) => {
        const id = (event.target as HTMLElement).getAttribute('id')?.split('.').pop();
        if (id) {
            const newState = {
                ...currentState,
                [id]: event.detail.value
            };
            setState(newState);

            dispatch(pushRotatingContentPanelDisplayElements({
                ...newState,
                type: IRCPDataSourceTypes.TextWrappingDataSource
            }));
        }
    };

    return <div>
        <FormSectionView title='Display Elements'>
            <div>
                <FormField label='Text Wrapping Sample Displays' description={
                    <small>Images are for reference and only a partial list. For all screen versions, please refer to this&nbsp;
                        <ExternalLink href={ambientHomeDesignDocUrl}>Ambient Home design document</ExternalLink>
                    </small>} />
                <br />
                <div className='awsui-grid'>
                    <div className='awsui-row awsui-util-no-gutters'>
                        <div className='awsui-util-mr-l'>
                            <figcaption>Hub Portrait Medium</figcaption>
                            <img src='/images/Hub_Portrait_Medium.svg' alt='Conversation Icon' />
                        </div>
                        <div className='awsui-util-mr-l'>
                            <figcaption>Hub Landscape Medium</figcaption>
                            <img src='/images/Hub_Landscape_Medium.svg' alt='Conversation Icon' />
                        </div>
                        <div className='awsui-util-mr-l'>
                            <figcaption>Hub Round</figcaption>
                            <img src='/images/Hub_Round.svg' alt='Conversation Icon' />
                        </div>
                    </div>
                </div>
                <br/>
            </div>
            <ColumnLayout columns={3}>
                <div data-awsui-column-layout-root='true'>
                    <FormField label={'Header Text'}
                        description={<small>Predefined by template</small>} >
                        <Textarea id='experience.displayElements.headerText' readonly={true} placeholder={currentState.headerText} onChange={onChangeHandler} />
                    </FormField>
                    <FormField label={<RequiredField fieldName='Primary Text' />}
                        description={<small>For writing guidelines, <ExternalLink href={writingGuidelinesUrl}>go here</ExternalLink> then "UX Writing Guidelines" in the left nav</small>} >
                        <TextareaWithCharLimit id='experience.displayElements.primaryText' characterLimit={60}
                        readonly={shouldDisableInput} value={currentState.primaryText} onChange={onChangeHandler}/>
                    </FormField>
                    <FormField
                        label={'Secondary Text'}
                        description={<small>For writing guidelines, <ExternalLink href={writingGuidelinesUrl}>go here</ExternalLink> then "UX Writing Guidelines" in the left nav</small>} >
                        <TextareaWithCharLimit id='experience.displayElements.secondaryText' characterLimit={25}
                        readonly={shouldDisableInput} value={currentState.secondaryText} onChange={onChangeHandler}/>
                    </FormField>
                </div>
            </ColumnLayout>
            <br/>
            <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root='true'>
                    <FormField label={<RequiredField fieldName='Background Image URL' />}
                        description={<small>For image guidelines, <ExternalLink href={backgroundGuidelinesUrl}>go here</ExternalLink> then "Backgrounds" in the left nav.<br/>
                        Images must be uploaded to <ExternalLink href={mediaCentralUrl}>media central</ExternalLink></small>} >
                        <Input id='experience.displayElements.backgroundImageSource' readonly={shouldDisableInput}
                        value={currentState.backgroundImageSource} onChange={onChangeHandler}/>
                    </FormField>
                </div>
            </ColumnLayout>
        </FormSectionView>
    </div>;
};

export default RCPDisplayElementsSectionView;
