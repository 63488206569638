import * as React from 'react';
import { IFrictionTrigger } from '../../models/NluOpportunityTrigger';
import { Button, Input } from '@amzn/awsui-components-react';
import { PromptDetails } from '../../models/PromptDetails';

interface FrictionTriggerPromptDetailsViewProps {
    frictionTrigger: IFrictionTrigger;
    promptDetailsIndex: number;
    disabled: boolean;
    updatePromptDetails: (input: PromptDetails) => void;
    onPromptDetailsRemove: () => void;
}

export const FrictionTriggerPromptDetailsView: React.FC<FrictionTriggerPromptDetailsViewProps> = (props: FrictionTriggerPromptDetailsViewProps) => {
    const { frictionTrigger, promptDetailsIndex, updatePromptDetails, onPromptDetailsRemove, disabled } = props;

    return (
        <div className='awsui-row awsui-util-no-gutters awsui-util-pt-l'>
            <div className='col-1 awsui-util-t-r awsui-util-pt-xs awsui-util-pr-xs'>
                <label><strong>Prompt ID</strong></label>
            </div>
            <div className='col-4 awsui-util-pl-s'>
            <Input
                id={`experience.friction-trigger-prompt-id-${promptDetailsIndex}`}
                value={frictionTrigger.frictionPromptDetails[promptDetailsIndex]?.promptId || ''}
                disabled={disabled}
                onChange={(e) => updatePromptDetails({
                    ...frictionTrigger.frictionPromptDetails[promptDetailsIndex],
                    promptId: e.detail.value
                }) } />
            </div>
            <div className='col-5 awsui-row awsui-util-no-gutters'>
                <div className='col-4 awsui-util-t-r awsui-util-pt-xs'>
                    <label><strong>Prompt Namespace</strong></label>
                </div>
                <div className='col-8 awsui-util-pl-s'>
                <Input
                    disabled={disabled}
                    id={`experience.friction-trigger-prompt-namespace-${promptDetailsIndex}`}
                    value={frictionTrigger.frictionPromptDetails[promptDetailsIndex]?.promptNamespace || ''}
                    onChange={(e) => updatePromptDetails({
                        ...frictionTrigger.frictionPromptDetails[promptDetailsIndex],
                        promptNamespace: e.detail.value
                    }) } />
                </div>
            </div>
            <div className='col-2 awsui-util-pl-s'>
                <Button
                    id={`experience.remove-prompt-details-${promptDetailsIndex}`}
                    icon={'close'}
                    onClick={onPromptDetailsRemove}
                    disabled={disabled || frictionTrigger.frictionPromptDetails.length === 1}
                />
            </div>
        </div>
    );
};
