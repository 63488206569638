import * as React from 'react';
import { Component } from 'react';


export class NotAuthorizedPage extends Component {
    public render() {
        return (
            <div className='awsui'>
                <div className='awsui-util-t-c' >
                    <img src='/odyssey-loch-nessie-big.svg' alt='Loch Nessie' style={{
                        height: '350px',
                        width: '350px',
                        marginTop: '150px'
                    }} />
                    <h1>You are not authorized to access Alexa Odyssey page</h1>
                    <div className='awsui-util-status-inactive awsui-util-mt-xl'>
                        <h3>Want to get access?</h3>
                        <p>Request access to <a href='https://permissions.amazon.com/group.mhtml?group=alexa-polaris-experiment-view-prod&group_type=ldap'>experiment-view</a> group</p>
                    </div>
                </div>
            </div>
        );
    }
}
