import { Table } from '@amzn/awsui-components-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { PAGE } from '../constants/page';


interface IMenuItem {
    link: string;
    name: string;
    description: string;
}


export const AdminDashboard = (props: {}) => {

    const items: IMenuItem[] = [
        {
            name: 'Experience launch calendar',
            link: PAGE.Dashboard,
            description: 'A calendar view of all LIVE experiences, based on time window in local timezone.'
        },
        {
            name: 'All experience life-cycle investigator',
            link: PAGE.Investigator,
            description: 'Investigator that is based on audit trail where you can look up even deleted experiences based on experienceID.'
        },
        {
            name: 'Odyssey announcements update widget',
            link: PAGE.OdysseyAnnouncementUpdateWidget,
            description: 'Widget that lets admins update what is being displayed to users. Announcements will disappear after 6 months (180 days).'
        },
        {
            name: 'Bypass pause list update widget',
            link: PAGE.BypassPauseListUpdateWidget,
            description: 'Widget that lets admins update the bypass pause list. ExperimentIDs and OperatorGroups added here will bypass the Automated Approval pause logic.'
        },
        {
            name: 'Promoted experiments update widget',
            link: PAGE.PromotedExperimentsUpdateWidget,
            description: 'Widget that lets admins update the promoted experiments. Experiments added here suppress competing non-promoted experiments when considered for opportunity.'
        },
        {
            name: 'Monitored experiments update widget',
            link: PAGE.MonitoredExperimentsUpdateWidget,
            description: 'Widget that lets admins update the monitored experiments. ExperimentIDs added here will be monitored.'
        },
        {
            name: 'Generate VCIF Experiences using ACC',
            link: PAGE.GenerateVcifUsingAcc,
            description: 'Generate VCIF Experiences using a list of Capability IDs and a list of intents.'
        },
        {
            name: 'SPI Provider Onboarding Service',
            link: PAGE.SpiProviderOnboarding,
            description: 'Service that lets admins onboard internal teams to PCS as SPI providers.'
        }
    ];

    return <div className='awsui-grid'>
        <div className='awsui-row awsui-util-t-c'>
            <div className='col-3'></div>
            <div className='col-6 awsui-util-t-c'>
                <h1>Admin Dashboard</h1>
            </div>
            <div className='col-3'></div>
        </div>
        <div className='awsui-row'>
            <div className='col-3'></div>
            <div className='col-6'>
                <Table
                    loadingText='Loading resources'
                    columnDefinitions={[{
                        id: 'name',
                        header: 'Link to the item',
                        cell: (item: IMenuItem) => <Link to={item.link}>{item.name}</Link>
                    }, {
                        id: 'desc',
                        header: 'Description',
                        cell: (item: IMenuItem) => item.description
                    }]}
                    items={items}
                    header={<h2>
                        Special tools for admins
                    </h2>}
                    empty={<div className='awsui-util-t-c'>
                        <div className='awsui-util-pt-s awsui-util-mb-xs'>
                            <b>
                            No resources
                            </b>
                        </div>
                        <p className='awsui-util-mb-s'>
                            No resources to display.
                        </p>
                        </div>
                    }
                ></Table>
            </div>
            <div className='col-3'></div>
        </div>
    </div>;
};
