import * as React from 'react';
import { Spinner, FormField, TokenGroup, Input, Alert } from '@amzn/awsui-components-react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { DEVICES } from '../../constants/devices';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { pushDevicesSectionAction } from '../../actions/experienceEditViewActions';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { OptionalField } from '../../components/common/DescriptionAnnotations';


interface INotificationDevicesSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

interface INotificationDevicesSectionViewState {
    avsDeviceInput?: string;
}

export class NotificationDevicesSectionView extends React.Component<INotificationDevicesSectionViewProps, INotificationDevicesSectionViewState> {

    constructor(props: INotificationDevicesSectionViewProps) {
        super(props);
        this.state = {
            avsDeviceInput: ''
        };
    }

    updateDevices(devices?: string[]) {
        const { dispatch } = this.props;
        dispatch(pushDevicesSectionAction(devices));
    }

    setAVSDeviceInput(event: CustomEvent<Input.ChangeDetail>) {
        this.setState({avsDeviceInput: event.detail.value});
    }

    onAVSDeviceInputEnter(devices: string[]) {
        this.state.avsDeviceInput?.split(/[,|]+/)
            .map(tag => tag.trim())
            .filter(tag => (tag.length > 0 && !devices.includes(tag)))
            .map((tag) => { devices.push(tag);});

        this.setState({avsDeviceInput: ''});
        this.updateDevices(devices);
    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate } = this.props;

        if (!experience) {
            return <FormSectionView title='Devices'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const devices: string[] = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
            ? updateCandidate.getDevices()
            : ExperienceUpdateCandidate.extractDevices(experience));

        const shouldDisableInput = !isEditing || isUpdating;

        return <FormSectionView
            title={<OptionalField fieldName='AVS Devices' />}
            description='Notifications will only be sent to selected AVS devices if added'>

            <Alert type='info' header='Important Note'>
                Adding AVS devices will make your experience target <i>ONLY</i> selected AVS devices.
                This is an optional field and it should be left blank for most people.
            </Alert>

            <FormField
                label={<OptionalField fieldName='AVS Device IDs' />}
                description='Type in IDs, press enter to add'
            >
                <Input
                    id='experience.devices-avs-input'
                    controlId='experience.devices-avs-input'
                    disabled={shouldDisableInput}
                    onInput={(e) => this.setAVSDeviceInput(e)}
                    value={this.state.avsDeviceInput}
                    onKeyup={(e) => {
                        if (e.detail.keyCode === 13) {
                            this.onAVSDeviceInputEnter(devices);
                        }
                    }}/>
                <DevicesTagsView
                    tags={devices}
                    tokenGroupId='experience.devices-avs-token-group'
                    disableTag={shouldDisableInput}
                    onDismiss={(e) => {
                        devices.splice(e.detail.itemIndex, 1);
                        this.updateDevices(devices);
                    }} />
            </FormField>
        </FormSectionView>;
    }
}

const DevicesTagsView = (props: {
    tags: string[],
    disableTag: boolean,
    tokenGroupId: string,
    onDismiss: (e: CustomEvent<TokenGroup.DismissDetail>) => void
}) => {

    const { tokenGroupId, tags, disableTag, onDismiss } = props;

    const supportedDevices = Object.keys(DEVICES);

    const deviceTags = tags.map(deviceId => {
        if (supportedDevices.includes(deviceId)) {
            const item: TokenGroup.Item = {
                label: `${DEVICES[deviceId].name} (${DEVICES[deviceId].code})`,
                disabled: disableTag,
                tags: ['You have entered a non-AVS device. Click "x" to remove']
            };

            return item;
        } else {
            const item: TokenGroup.Item = {
                label: deviceId,
                disabled: disableTag,
                tags: ['Device']
            };

            if (deviceId.trim() !== deviceId) {
                item.tags = ['WARNING: token has leading or trailing whitespace'];
            }

            return item;
        }
    });

    return <TokenGroup id={tokenGroupId} items={deviceTags} onDismiss={onDismiss} />;
};

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(NotificationDevicesSectionView);
