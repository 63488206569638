import * as React from 'react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ColumnLayout, Spinner, Input, Select } from '@amzn/awsui-components-react';
import { Checkbox} from '@amzn/awsui-components-react';
import { getRole } from '../../util/stringAndMappingHelper';
import { FormSectionView } from '../../components/common/FormSectionView';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { pushNotificationGuardrailsSectionAction } from '../../actions/experienceEditViewActions';
import { PROACTIVE_NOTIFICATIONS_USER_GROUP } from '../../constants/userGroups';

interface INotificationGuardrailsSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

export class NotificationGuardrailsSectionView extends React.Component<INotificationGuardrailsSectionViewProps> {
    updateNotificationGuardrails(overrideFrequencyGuardrail?: boolean, overrideContentGuardrail?: boolean,
        frequencyGuardrailOverrideDays?: number, contentGuardrailOverrideDays?: number, notificationExpirationOverrideDays?: number) {
        const { dispatch } =this.props;
        dispatch(pushNotificationGuardrailsSectionAction(overrideFrequencyGuardrail, overrideContentGuardrail,
            frequencyGuardrailOverrideDays, contentGuardrailOverrideDays, notificationExpirationOverrideDays));
    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate } = this.props;
        if (!experience) {
            return <FormSectionView title='Guardrail Override'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }
        const notificationGuardrails = (isEditing && updateCandidate)
            ? updateCandidate.getNotificationGuardrails() || {}
            : ExperienceUpdateCandidate.extractNotificationGuardrails(experience) || {};

        const shouldDisableInput = !isEditing || isUpdating;

        const overrideFrequencyGuardrail = (notificationGuardrails !== null && notificationGuardrails.overrideFrequencyGuardrail !== null)
            ? notificationGuardrails.overrideFrequencyGuardrail : false;
        const overrideContentGuardrail = (notificationGuardrails !== null && notificationGuardrails.overrideContentGuardrail !== null)
            ? notificationGuardrails.overrideContentGuardrail : false;

        const operatorGroup = ((isEditing && updateCandidate)
            ? updateCandidate.getBasicInfo().groupImpressions
            : experience.operatorGroup) || '';

        return <FormSectionView title='Guardrail Override' description='Configure the guardrails override for this experience(admin-only feature).'>
            { (experience.type === 'AppNotification' || experience.type === 'DeviceNotification')
            && getRole(experience.permissionRole) === 'ADMIN' &&
            <ColumnLayout columns={1}>
                <div className='awsui-row' style={{ marginBottom: '20px' }}>
                    <div className='col-0'>
                        <Checkbox
                            id='control.override-frequency-guardrail'
                            controlId='control.override-frequency-guardrail'
                            disabled={shouldDisableInput}
                            checked= {notificationGuardrails.overrideFrequencyGuardrail || false}
                            onChange={e => this.updateNotificationGuardrails(e.detail.checked)}
                            >
                            1 push notification every
                        </Checkbox>
                    </div>
                    <div className='col-0'>
                        <Input
                            placeholder='default value: 3 days'
                            id= 'experience.notificationGuardrails-frequencyGuardrailOverrideDays'
                            value= {notificationGuardrails.frequencyGuardrailOverrideDays?.toString()}
                            onChange={(e) => this.updateNotificationGuardrails(undefined, undefined, parseInt(e.detail.value, 10))}
                            readonly={shouldDisableInput || !overrideFrequencyGuardrail}>
                        </Input>
                    </div>
                    <div className='col-0'>
                        days per customer
                    </div>
                </div>
                <div className='awsui-row' style={{ marginBottom: '20px' }} >
                    <div className='col-0'>
                        <Checkbox
                            id='control.override-content-guardrail'
                            controlId='control.override-frequency-guardrail'
                            disabled={shouldDisableInput}
                            checked= {notificationGuardrails.overrideContentGuardrail || false}
                            onChange={e => this.updateNotificationGuardrails(undefined, e.detail.checked)}
                            >
                            1 push notification of same content ID every
                        </Checkbox>
                    </div>
                    <div className='col-0'>
                        <Input
                            placeholder='default value: 30 days'
                            id= 'experience.notificationGuardrails-contentGuardrailOverrideDays'
                            value= {notificationGuardrails.contentGuardrailOverrideDays?.toString()}
                            onChange={(e) => this.updateNotificationGuardrails(undefined, undefined, undefined, parseInt(e.detail.value, 10))}
                            readonly={shouldDisableInput || !overrideContentGuardrail}>
                        </Input>
                    </div>
                    <div className='col-0'>
                        days per customer
                    </div>
                </div>
                {operatorGroup === PROACTIVE_NOTIFICATIONS_USER_GROUP && <div className='awsui-row' style={{ marginBottom: '20px' }}>
                    <div className='col-0'>
                        The notification will persist on customer's device for
                    </div>
                    <div className='col-0'>
                        <Select
                            id= 'experience.notificationGuardrails-notificationExpirationOverrideDays'
                            options= {[{id:'1', label:'1'},
                                    {id:'2', label:'2'}]}
                            selectedId={notificationGuardrails.notificationExpirationOverrideDays?.toString() || '1'}
                            onChange={(e) => this.updateNotificationGuardrails(undefined, undefined, undefined, undefined,
                                parseInt(e.detail.selectedId, 10))}
                            disabled={shouldDisableInput}
                        />
                    </div>
                    <div className='col-0'>
                        day(s).
                    </div>
                </div>}
            </ColumnLayout>
            }
        </FormSectionView>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState}: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate,
    };
};

export default connect(mapStateToProps)(NotificationGuardrailsSectionView);