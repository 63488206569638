import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers';
import { FormField, Input, TokenGroup } from '@amzn/awsui-components-react';
import _ from 'lodash';
import { updateCurrentProviderConfigAction, updateFormValidationSectionAction } from '../../../actions/spiProviderOnboardingActions';
import { IProviderConfig } from '../../../models/spiProviderConfig/ISpiProviderOnboarding';
import { validateSupportedFields } from '../../../util/spiProviderOnboardingHelper';
import { FormSectionView } from '../../../components/common/FormSectionView';

const SpiProviderOnboardingSupportedSectionView: React.FC = () => {
    const { currentProviderConfig, isEditing, isOnboardingProvider } = useSelector((state: AppState) => state.spiProviderOnboardingState);
    const dispatch = useDispatch();

    const [currentFieldsState, setFieldsState] = React.useState({
        supportedLocales: currentProviderConfig?.supportedLocales,
        supportedExperienceTypes: currentProviderConfig?.supportedExperienceTypes,
        supportedContentTypes: currentProviderConfig?.supportedContentTypes,
        supportedEndpointTypes: currentProviderConfig?.supportedEndpointTypes,
    });


    React.useEffect(() => {
        setFieldsState({
            supportedLocales: currentProviderConfig?.supportedLocales,
            supportedExperienceTypes: currentProviderConfig?.supportedExperienceTypes,
            supportedContentTypes: currentProviderConfig?.supportedContentTypes,
            supportedEndpointTypes: currentProviderConfig?.supportedEndpointTypes,
        });
    }, [currentProviderConfig]);

    const initializeValidationState = React.useCallback(() => {
        if (!isOnboardingProvider) { // updating config case
          return {
            invalidSupportedLocales: false,
            invalidSupportedExperienceTypes: false,
            invalidSupportedEndpointTypes: false
          };
        } else { // creating config case
          return {
            invalidSupportedLocales: true,
            invalidSupportedExperienceTypes: true,
            invalidSupportedEndpointTypes: true
          };
        }
      }, [isOnboardingProvider]);

    const [validationState, setValidationState] = React.useState(initializeValidationState());

    React.useEffect(() => {
        setValidationState(initializeValidationState());
    }, [isEditing, initializeValidationState]);

    React.useEffect(() => {
        const isSectionValid = Object.values(validationState).every(value => !value);
        dispatch(updateFormValidationSectionAction('invalidSupportedSection', !isSectionValid));
    }, [validationState, dispatch]);

    const convertToItems = (values: string[]): TokenGroup.Item[] => {
        return values.map(value => {
            const item: TokenGroup.Item = {
                label: value,
                disabled: !isEditing
            };
            if (value.trim() !== value) {
                item.tags = ['WARNING: token has leading or trailing whitespace'];
            }
            return item;
        });
    };

    const onChangeHandler = (event: CustomEvent<Input.ChangeDetail>) => {
        const field = (event.target as HTMLElement).getAttribute('id')?.split('.').pop();
        if (field) {
            const newFieldsState = _.cloneDeep(currentFieldsState);
            for (const value of event.detail.value.split(/[,|]+/).map(v => v.trim())) {
                if ((value !== '')) {
                    switch (field) {
                        case 'supportedLocales':
                            if (!newFieldsState.supportedLocales?.includes(value)){
                                newFieldsState.supportedLocales?.push(value);
                            }
                            break;
                        case 'supportedExperienceTypes':
                            if (!newFieldsState.supportedExperienceTypes?.includes(value)){
                                newFieldsState.supportedExperienceTypes?.push(value);
                            }
                            break;
                        case 'supportedContentTypes':
                            if (!newFieldsState.supportedContentTypes?.includes(value)){
                                newFieldsState.supportedContentTypes?.push(value);
                            }
                            break;
                        case 'supportedEndpointTypes':
                            if (!newFieldsState.supportedEndpointTypes?.includes(value)){
                                newFieldsState.supportedEndpointTypes?.push(value);
                            }
                            break;
                    }
                }
            }
            setFieldsState(newFieldsState);
            dispatch(updateCurrentProviderConfigAction(newFieldsState as IProviderConfig));
            validateSupportedFields(field, validationState, newFieldsState, setValidationState);
        }
    };

    const onValueDismiss = (event: CustomEvent<TokenGroup.DismissDetail>) => {
        const field = (event.target as HTMLElement).getAttribute('id')?.split('.').pop();
        if (field) {
            const newFieldsState = _.cloneDeep(currentFieldsState);
            switch (field) {
                case 'supportedLocales':
                    if (newFieldsState.supportedLocales) {
                        newFieldsState.supportedLocales.splice(event.detail.itemIndex, 1);
                    }
                    break;
                case 'supportedExperienceTypes':
                    if (newFieldsState.supportedExperienceTypes) {
                        newFieldsState.supportedExperienceTypes.splice(event.detail.itemIndex, 1);
                    }
                    break;
                case 'supportedContentTypes':
                    if (newFieldsState.supportedContentTypes) {
                        newFieldsState.supportedContentTypes.splice(event.detail.itemIndex, 1);
                    }
                    break;
                case 'supportedEndpointTypes':
                    if (newFieldsState.supportedEndpointTypes) {
                        newFieldsState.supportedEndpointTypes.splice(event.detail.itemIndex, 1);
                    }
                    break;
            }
            setFieldsState(newFieldsState);
            dispatch(updateCurrentProviderConfigAction(newFieldsState as IProviderConfig));
            validateSupportedFields(field, validationState, newFieldsState, setValidationState);
        }
    };

    return (
        <div>
            <FormSectionView title='Supported'>
                <div>
                    <div>
                        <FormField
                            label={<div><strong>Locales</strong> <i className='awsui-text-secondary'>(required)</i></div>}
                            description={<i className='awsui-text-secondary'>Type in a locale and hit enter or paste a list with commas</i>}>
                            <Input
                                id='providerConfig.supportedLocales'
                                placeholder={'en_US, en_UK'}
                                value={''}
                                readonly={!isEditing}
                                onChange={onChangeHandler}
                                invalid={validationState.invalidSupportedLocales}
                            />
                            <TokenGroup
                                id='providerConfig.tokenGroup.supportedLocales'
                                items={convertToItems(currentProviderConfig?.supportedLocales || [])}
                                onDismiss={onValueDismiss} />
                        </FormField>
                    </div>
                    <hr/>
                    <div>
                        <FormField
                            label={<div><strong>Experience Types</strong> <i className='awsui-text-secondary'>(required)</i></div>}
                            description={<i className='awsui-text-secondary'>Type in a experience type and hit enter or paste a list with commas</i>}>
                            <Input
                                    id='providerConfig.supportedExperienceTypes'
                                    placeholder={'EXAMPLE_EXPERIENCE_TYPE1, EXAMPLE_EXPERIENCE_TYPE2'}
                                    value={''}
                                    readonly={!isEditing}
                                    onChange={onChangeHandler}
                                    invalid={validationState.invalidSupportedExperienceTypes}
                                />
                            <TokenGroup
                                id='providerConfig.tokenGroup.supportedExperienceTypes'
                                items={convertToItems(currentProviderConfig?.supportedExperienceTypes || [])}
                                onDismiss={onValueDismiss} />
                        </FormField>
                    </div>
                    <hr/>
                    <div>
                        <FormField
                            label={<div><strong>Content Types</strong> <i className='awsui-text-secondary'>(optional)</i></div>}
                            description={<i className='awsui-text-secondary'>Type in a content type and hit enter or paste a list with commas</i>}>
                            <Input
                                    id='providerConfig.supportedContentTypes'
                                    placeholder={'EXAMPLE_CONTENT_TYPE1, EXAMPLE_CONTENT_TYPE2'}
                                    value={''}
                                    readonly={!isEditing}
                                    onChange={onChangeHandler}
                                    // invalid={validationState.invalidSupportedContentTypes}
                                />
                            <TokenGroup
                                id='providerConfig.tokenGroup.supportedContentTypes'
                                items={convertToItems(currentProviderConfig?.supportedContentTypes || [])}
                                onDismiss={onValueDismiss} />
                        </FormField>
                    </div>
                    <hr/>
                    <div>
                        <FormField
                            label={<div><strong>Endpoint Types</strong> <i className='awsui-text-secondary'>(required)</i></div>}
                            description={<i className='awsui-text-secondary'>Type in a endpoint type and hit enter or paste a list with commas</i>}>
                            <Input
                                    id='providerConfig.supportedEndpointTypes'
                                    placeholder={'EXAMPLE_ENDPOINT_TYPE1, EXAMPLE_ENDPOINT_TYPE2'}
                                    value={''}
                                    readonly={!isEditing}
                                    onChange={onChangeHandler}
                                    invalid={validationState.invalidSupportedEndpointTypes}
                                />
                            <TokenGroup
                                id='providerConfig.tokenGroup.supportedEndpointTypes'
                                items={convertToItems(currentProviderConfig?.supportedEndpointTypes || [])}
                                onDismiss={onValueDismiss} />
                        </FormField>
                    </div>
                </div>
            </FormSectionView>
        </div>
    );
};

export default SpiProviderOnboardingSupportedSectionView;