import * as React from 'react';
import { Table, TablePropertyFiltering, TableSorting, TableContentSelector } from '@amzn/awsui-components-react';
import { getLinkableUrl, PAGE } from './page';
import { IHomeCardSuggestion } from '../models/HomeCardSuggestion';
import { SuggestionStatusBadge } from '../components/common/SuggestionStatusBadge';
import { fromTimestampToFormattedDateTime } from '../util/stringAndMappingHelper';
import { SuggestionStatusBadgeMap } from './statusBadgeMap';
import moment from 'moment';
import { ISuggestionApproval } from '../models/homeCardSuggestions/SuggestionApproval';

// lower case comparison with null value tolerance
export const defaultStringComparator = (a?: string, b?: string) => {
    if (a && b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
    }

    return !a && !b ? 0 : (!a ? -1 : 1);
};

// number comparison
export const defaultNumberComparator = (a: number, b: number) => a > b ? 1 : (a < b ? -1 : 0);

export const evaluate24HourSLA = (suggestionApproval: ISuggestionApproval) => {
    const currentTime = moment();
    const creationTime = (fromTimestampToFormattedDateTime(suggestionApproval.metadata.createdAt));
    if (!suggestionApproval.metadata.approvedAt && suggestionApproval.status !== 'REJECTED' &&
        currentTime.diff(creationTime, 'h') > 24) {
        return <b style={{ color: 'red' }}>{creationTime}</b>;
    }
    return creationTime;
};

interface IColumnConfig {
    id: string;
    header: string;
    width: number;
    minWidth: string;
    renderingFunction: (item: IHomeCardSuggestion) => React.ReactNode;
    filteringOptions: string[];
    visible: boolean;
    sortingComparator: (a: IHomeCardSuggestion, b: IHomeCardSuggestion) => number;
}

// suggestionId
const idColumnConfig: IColumnConfig = {
    id: 'id',
    header: 'Suggestion ID',
    width: 320,
    minWidth: '250px',
    renderingFunction: item => {
        return <a href={`${getLinkableUrl(PAGE.HomeCardSuggestions)}/${item.id}`}>{item.id || '-'}</a>;
    },
    filteringOptions: [],
    visible: true,
    sortingComparator: (a, b) => defaultStringComparator(a.id, b.id)
};

// suggestion approval status
const statusColumnConfig: IColumnConfig = {
    id: 'status',
    header: 'Status',
    width: 200,
    minWidth: '200px',
    renderingFunction: item => (<SuggestionStatusBadge status={item.suggestionApproval.status} />),
    filteringOptions: Object.keys(SuggestionStatusBadgeMap),
    visible: true,
    sortingComparator: (a, b) => defaultStringComparator(a.suggestionApproval.status, b.suggestionApproval.status)
};

// suggestion skill Id
const skillIDColumnConfig: IColumnConfig = {
    id: 'skillId',
    header: 'Skill ID',
    width: 100,
    minWidth: '350px',
    renderingFunction: item => item.provider.providerId,
    filteringOptions: [],
    visible: true,
    sortingComparator: (a, b) => defaultStringComparator(a.provider.providerId, b.provider.providerId)
};

// suggestion submission time
const submissionTimeColumnConfig: IColumnConfig = {
    id: 'submissionTime',
    header: 'Submission Time',
    width: 150,
    minWidth: '150px',
    renderingFunction: item => (evaluate24HourSLA(item.suggestionApproval)),
    filteringOptions: [],
    visible: true,
    sortingComparator: (a, b) => (defaultNumberComparator(a.suggestionApproval.metadata.createdAt, b.suggestionApproval.metadata.createdAt))
};

// suggestion approval time
const approvalTimeColumnConfig: IColumnConfig = {
    id: 'approvalTime',
    header: 'Approval Time',
    width: 150,
    minWidth: '150px',
    renderingFunction: item => (item.suggestionApproval.metadata.approvedAt!==0 ? fromTimestampToFormattedDateTime(item.suggestionApproval.metadata.approvedAt) : ''),
    filteringOptions: [],
    visible: true,
    sortingComparator: (a, b) => (defaultNumberComparator(a.suggestionApproval.metadata.approvedAt, b.suggestionApproval.metadata.approvedAt))
};

// suggestion approver
const approverColumnConfig: IColumnConfig = {
    id: 'approverAlias',
    header: 'Approver',
    width: 100,
    minWidth: '100px',
    renderingFunction: item => (item.suggestionApproval.metadata.approverAlias),
    filteringOptions: [],
    visible: true,
    sortingComparator: (a, b) => (defaultStringComparator(a.suggestionApproval.metadata.approverAlias, b.suggestionApproval.metadata.approverAlias))
};

const columnConfigs: IColumnConfig[] = [
    idColumnConfig,
    statusColumnConfig,
    skillIDColumnConfig,
    submissionTimeColumnConfig,
    approvalTimeColumnConfig,
    approverColumnConfig
];

export const COLUMN_DEFINITIONS_SUGGESTIONS: Table.ColumnDefinition<IHomeCardSuggestion>[] = columnConfigs.map(
    (config: IColumnConfig) => {
        return {
            id: config.id,
            header: config.header,
            minWidth: config.minWidth,
            cell: config.renderingFunction
        };
    });

export const FILTERING_OPTIONS: TablePropertyFiltering.Option[] = columnConfigs.map((config: IColumnConfig) => {
    return {
        groupValuesLabel: config.header + ' Values',
        propertyKey: config.id,
        propertyLabel: config.header,
        values: config.filteringOptions
    };
}).sort((a, b) => defaultStringComparator(a.propertyLabel, b.propertyLabel));

export const SORTABLE_COLUMNS_SUGGESTIONS: TableSorting.SortableColumn<IHomeCardSuggestion>[] = columnConfigs.map(
    (config: IColumnConfig) => {
        return {
            id: config.id,
            field: config.id,
            comparator: config.sortingComparator
        };
    });

export const SELECTABLE_COLUMNS_SUGGESTIONS: TableContentSelector.ContentDescription[] = columnConfigs.map(
    (config: IColumnConfig) => {
        return {
            id: config.id,
            label: config.header,
            visible: config.visible
        };
    }
);
