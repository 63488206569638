import { ILoadOdysseyExperienceAction } from '../../actions/v2/odysseyExperienceDetailViewActions';
import {
    LOADING_ODYSSEY_EXPERIENCE_DETAIL,
    LOADED_ODYSSEY_EXPERIENCE_DETAIL,
    ERROR_LOADING_ODYSSEY_EXPERIENCE_DETAIL
} from '../../constants/v2/odysseyExperienceDetailViewActionTypes';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { transformOdysseyExperience } from '../../util/stringAndMappingHelper';

export interface IExperienceDetailViewState {
    isLoading: boolean;
    experience?: IFlattenedExperience;
    error?: Error;
}

export const odysseyExperienceDetailViewReducer = (
    state: IExperienceDetailViewState = { isLoading: false },
    action: ILoadOdysseyExperienceAction
): IExperienceDetailViewState => {
    switch (action.type) {
        case LOADING_ODYSSEY_EXPERIENCE_DETAIL:
            return {
                ...state,
                isLoading: true
            };
        case LOADED_ODYSSEY_EXPERIENCE_DETAIL:
            const { experience } = action;
            // reducer is at top level. it should resolve error
            // and get it ready for rendering.
            if (!experience) {
                return {
                    ...state,
                    isLoading: false,
                    error: new Error('Loaded `undefined` experiences, something went wrong')
                };
            }
            return {
                ...state,
                isLoading: false,
                experience: transformOdysseyExperience(experience),
                error: undefined
            };
        case ERROR_LOADING_ODYSSEY_EXPERIENCE_DETAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};
