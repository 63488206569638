import * as React from 'react';
import { Component } from 'react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { Tabs } from '@amzn/awsui-components-react';
import { TestingTabView } from './tabs/TestingTabView';
import { EventTrailTabView } from './tabs/EventTrailTabView';

import { VisualCIFExperienceDetailsTabView } from './tabs/VisualCIFExperienceDetailsTabView';
import { VisualCIFCustomerTargetingTabView } from './tabs/VisualCIFCustomerTargetingTabView';
import { VisualCIFContentTabView } from './tabs/VisualCIFContentTabView';
import { VisualCIFAdvancedConfigurationTabView } from './tabs/VisualCIFAdvancedConfigurationTabView';

export class VisualCifExperienceTabsView extends Component<{
    experience: IFlattenedExperience,
    activeTabId: string | undefined,
    onTabChange?: (detail: Tabs.ChangeDetail) => void}
> {

    render() {
        const { experience, activeTabId, onTabChange } = this.props;
        return <Tabs
                tabs={getVisualCifExperienceTabs(experience)}
                activeTabId={activeTabId}
                onChange={(e) => onTabChange ? onTabChange(e.detail) : null} />;
    }
}

const getVisualCifExperienceTabs = (experience: IFlattenedExperience): Tabs.Tab[] => {
    return [
        {
            label: 'Content',
            id: 'visual-cif-content',
            content: <VisualCIFContentTabView />
        },
        {
            label: 'Customer Targeting',
            id: 'visual-cif-customer-targeting',
            content: <VisualCIFCustomerTargetingTabView />
        },
        {
            label: 'Experience Details',
            id: 'visual-cif-experience-details',
            content: <VisualCIFExperienceDetailsTabView />
        },
        {
            label: 'Testing',
            id: 'testing',
            content: <TestingTabView experience={experience} />
        },
        {
            label: 'Advanced Configuration',
            id: 'visual-cif-advanced-configuration',
            content: <VisualCIFAdvancedConfigurationTabView />
        },
        {
            label: 'Event Trail',
            id: 'eventTrail',
            content: <EventTrailTabView />
        }
    ];
};
