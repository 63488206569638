import { Stage, Region } from '../util/context';

export const PAGE_ENDPOINTS_MAP: { [S in Stage]: {[R in Region]: string} } = {
    Alpha: {
        NA: 'https://apps-3io-pdx-pd-tcp.integ.amazon.com:443/v1/page-domain-information',
        EU: 'https://apps-3io-pdx-pd-tcp.integ.amazon.com:443/v1/page-domain-information',
        FE: 'https://apps-3io-pdx-pd-tcp.integ.amazon.com:443/v1/page-domain-information',
        CN: 'https://apps-3io-pdx-pd-tcp.integ.amazon.com:443/v1/page-domain-information'
    },
    Beta: {
        NA: 'https://apps-3io-pdx-d-tcp.integ.amazon.com:443/v1/page-domain-information',
        EU: 'https://apps-3io-pdx-d-tcp.integ.amazon.com:443/v1/page-domain-information',
        FE: 'https://apps-3io-pdx-d-tcp.integ.amazon.com:443/v1/page-domain-information',
        CN: 'https://apps-3io-pdx-pd-tcp.integ.amazon.com:443/v1/page-domain-information'
    },
    Gamma: {
        NA: 'https://apps-3io-main-na-pp-tcp.iad.amazon.com:443/v1/page-domain-information',
        EU: 'https://apps-3io-main-eu-pp-tcp.dub.amazon.com:443/v1/page-domain-information',
        FE: 'https://apps-3io-main-jp-pp-tcp.pdx.amazon.com:443/v1/page-domain-information',
        CN: 'https://apps-3io-pdx-pd-tcp.integ.amazon.com:443/v1/page-domain-information'
    },
    Prod: {
        NA: 'https://apps-3io-main-na-p-tcp.iad.amazon.com:443/v1/page-domain-information',
        EU: 'https://apps-3io-main-eu-p-tcp.dub.amazon.com:443/v1/page-domain-information',
        FE: 'https://apps-3io-main-jp-p-tcp.pdx.amazon.com:443/v1/page-domain-information',
        CN: 'https://apps-3io-pdx-pd-tcp.integ.amazon.com:443/v1/page-domain-information'
    }
};
