import { axios } from './axios';
import { API_ENDPOINT, GET_ODYSSEY_CONFIGURATION_PATH, PUT_ODYSSEY_CONFIGURATION_PATH } from '../constants/lambdaEndpoints';
import { IOdysseyAnnouncement } from '../models/odysseyAnnouncement/Announcement';

export const ANNOUNCEMENT_CONFIGURATION_FILE = 'announcement';

export async function getOdysseyAnnouncement(): Promise<IOdysseyAnnouncement | undefined> {
    const response = await axios.get(`${API_ENDPOINT}${GET_ODYSSEY_CONFIGURATION_PATH}/${ANNOUNCEMENT_CONFIGURATION_FILE}`);

    if (response.status === 204) {
        return undefined;
    }

    return response.data;
}

export async function putOdysseyAnnouncement(announcement: IOdysseyAnnouncement): Promise<void> {
    const response = await axios.put(`${API_ENDPOINT}${PUT_ODYSSEY_CONFIGURATION_PATH}/${ANNOUNCEMENT_CONFIGURATION_FILE}`, announcement);

    return response.data;
}
