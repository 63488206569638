export type CardActionType = 'OpenUrlAction' | 'NavigateAction' | 'SettingsAction' | 'DeepLinkAction';

export interface IMobileHomeCardAction {
    actionType?: CardActionType;
    route?: string;
    url?: string;
    appStoreUrl?: string;
    playStoreUrl?: string;
    appStoreName?: string;
    playStoreName?: string;
    deepLinkUrl?: string;
    androidSettingsUrl?: string;
}

export const EMPTY_MOBILE_CARD_ACTION: IMobileHomeCardAction = {
    actionType: 'OpenUrlAction',
    route: undefined,
    url: undefined,
    appStoreUrl: undefined,
    playStoreUrl: undefined,
    appStoreName: undefined,
    playStoreName: undefined,
    deepLinkUrl: undefined,
    androidSettingsUrl: undefined
};
