import { CognitoAuth, CognitoAuthSession } from 'amazon-cognito-auth-js';
import * as React from 'react';
import { Provider } from 'react-redux';

import { setSharedAuthenticationInfo } from '../actions/authenticationActions';
import { IAuthMap, getOdysseyAuthMap } from '../authentication/cognito';
import { store } from '../reducers';
import { getCurrRegionStage, Region, Stage } from '../util/context';
import { App } from './App';
import { ILoginInfo } from '../models/LoginInfo';
import { loadUserAlias } from '../actions/navigationViewActions';
import { NotAuthorizedPage } from './NotAuthorizedPage';
import { ExpiredTokenPage } from './ExpiredTokenPage';


enum AppStage {
    UNAUTHORIZED,
    LOGGING_IN,
    LOGGED_IN,
    EXPIRED
}

interface IRootState {
    appStage: AppStage;
    region: Region;
    stage: Stage;
}

export default class Root extends React.Component<{}, IRootState> {
    private auths: IAuthMap = getOdysseyAuthMap();

    public constructor(props: {}) {
        super(props);
        const { region, stage } = getCurrRegionStage();

        const cognitoAuth: CognitoAuth= this.auths[stage];
        this.state = {
            ...this.state,
            appStage: cognitoAuth ? AppStage.LOGGING_IN : AppStage.UNAUTHORIZED,
            region,
            stage
        };
        cognitoAuth.useCodeGrantFlow();
        cognitoAuth.userhandler = {
            onFailure: (error: any) => {
                this.onLogoutHandler();
            },
            onSuccess: (session: CognitoAuthSession) => {
                this.onLoggedInHandler(session);
            }
        };
    }

    public componentDidMount() {
        this.login();
    }

    public render() {
        const { appStage } = this.state;
        return <Provider store={store} >
            { appStage === AppStage.LOGGED_IN && <App />}
            { appStage === AppStage.LOGGING_IN && <p>Please wait while logging you in...</p> }
            { appStage === AppStage.UNAUTHORIZED && <NotAuthorizedPage />}
            { appStage === AppStage.EXPIRED && <ExpiredTokenPage />}
        </Provider>;
    }

    private onLoggedInHandler(session: CognitoAuthSession) {
        const payload = session.getIdToken().decodePayload() as ILoginInfo;
        store.dispatch(loadUserAlias(payload.identities[0].userId));

        store.dispatch(setSharedAuthenticationInfo({
            authMap: this.auths,
            stage: this.state.stage,
            region: this.state.region
        }));
        this.setState({ appStage: AppStage.LOGGED_IN });
    }

    /**
     * Not really for logout, but for exiting current session and getting a new session
     */
    private onLogoutHandler() {
        const { stage } = this.state;
        const cognitoAuth = this.auths[stage];
        this.setState({ appStage: AppStage.EXPIRED });

        // Cognito SDK will handle session refresh / authentication
        cognitoAuth.clearCachedTokensScopes();
        cognitoAuth.setState(encodeURIComponent(window.location.href));
        cognitoAuth.getSession();
    }

    private login() {
        const { stage } = this.state;
        const href = window.location.href;
        const cognitoAuth = this.auths[stage];
        const session = cognitoAuth.getSignInUserSession();

        if (session.isValid()) {
            this.onLoggedInHandler(session);
            return true;
        } else if (this.isCognitoStatesIncluded(this.parseQueryParams())) {
            // This is required because Cognito needs to get the authentication result from the query string
            // The parsing is done asynchronously, and the result will be passed to the userHandler.
            cognitoAuth.parseCognitoWebResponse(href);
            this.cleanseQueryParams();
            return false;
        } else {
            // try to refresh session, if fails, onFailure userhandler will redirect accordingly
            cognitoAuth.refreshSession(session.getRefreshToken().getToken());
            return false;
        }
    }

    private parseQueryParams(): URLSearchParams | undefined {
        if (window.location.search) {
            return new URLSearchParams(window.location.search);
        }

        return undefined;
    }

    private isCognitoStatesIncluded(searchParams?: URLSearchParams): boolean {
        if (searchParams) {
            return searchParams.has('state') && searchParams.has('code');
        } else {
            return false;
        }
    }

    private cleanseQueryParams() {
        // Replace the href because the Cognito passes the OAuth2 grant code in the query string
        // And the grant code is not reusable
        const url = window.location.href.split('?')[0];

        const queryParams = this.parseQueryParams();

        if (queryParams && this.isCognitoStatesIncluded(queryParams)) {
            const uriFromState = queryParams.get('state');
            window.history.replaceState(undefined, 'Alexa Odyssey', uriFromState);
        } else if (queryParams) {
            window.history.replaceState(undefined, 'Alexa Odyssey', url + '?' + queryParams.toString());
        } else {
            window.history.replaceState(undefined, 'Alexa Odyssey', url);
        }
    }
}
