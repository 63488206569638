import { COMMON_ERROR_MESSAGES, EXPERIENCE_ID_REGEX, HINT_ID_REGEX, MCM_LINK_REGEX } from '../constants/validatorConstants';
import { ITimeRange } from '../models/TimeRange';
import { TemplateVariableType } from '../models/TemplateVariable';
import { IRemoteContextualSegments } from '../models/RemoteContextualSegments';
import { isStringEmpty } from './stringAndMappingHelper';
import { Region } from './context';
import { SerializationMethod, EncryptionMethod, AuthenticationMethod } from '../models/InvocationParameters';
import { IFlattenedExperience } from '../models/FlattenedExperience';
import { isNotEmptyTargeting } from '../models/NluOpportunityTargeting';
import { ValidationError } from '../exceptions/ValidationErrors';
import { getRole, isExperienceApproved } from './stringAndMappingHelper';

export class CommonValidator {
    static isValidHintId(id?: string) {
        if (id && !new RegExp(HINT_ID_REGEX).test(id)) {
            throw new Error(COMMON_ERROR_MESSAGES.INVALID_HINT_ID);
        }
    }

    static isActivationEndDateAfterStartDate(startDate?: string, endDate?: string) {
        if (startDate && endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            if (end < start) {
                throw new Error(COMMON_ERROR_MESSAGES.INVALID_DATE_RANGE_TEMPLATE);
            }
        }
    }

    static isActivationEndDateEmpty(startDate?: string, endDate?: string) {
        if (!endDate) {
            throw new Error(COMMON_ERROR_MESSAGES.MISSING_END_DATE);
        }
    }

    static isWindowEmpty(timeRanges: {[key: number]: ITimeRange[]}, dayId: number, disabled: boolean) {
        if (Object.keys(timeRanges).length > 0 && !disabled) {
            const startHour = timeRanges[dayId][0].startHour;
            const startMin = timeRanges[dayId][0].startMin;
            const endHour = timeRanges[dayId][0].endHour;
            const endMin = timeRanges[dayId][0].endMin;
            if (startHour === undefined || startMin === undefined || endHour === undefined || endMin === undefined) {
                throw new Error(COMMON_ERROR_MESSAGES.BOTH_START_END_TIME_WINDOW_MISSING);
            }
        }
    }

    static isStartTimeAfterEndTime(timeRanges: {[key: number]: ITimeRange[]}, dayId: number, disabled: boolean) {
        if (Object.keys(timeRanges).length > 0 && !disabled) {
            const startHour = timeRanges[dayId][0].startHour;
            const startMin = timeRanges[dayId][0].startMin;
            const endHour = timeRanges[dayId][0].endHour;
            const endMin = timeRanges[dayId][0].endMin;
            if (startHour > endHour) {
                throw new Error(COMMON_ERROR_MESSAGES.INVALID_TIME_RANGE);
            }
            else if (startHour === endHour && startMin > endMin) {
                throw new Error(COMMON_ERROR_MESSAGES.INVALID_TIME_RANGE);
            }
        }
    }

    static isDayTimeWindowMissing(timeRanges: {[key: number]: ITimeRange[]}, dayId: number, disabled: boolean) {
        if (dayId === 1 && Object.keys(timeRanges).length === 0) {
            throw new Error(COMMON_ERROR_MESSAGES.MISSING_DAY_TIME_WINDOW);
        }
    }

    // Content Variables Validation
    static isTemplateVariableNameValid(templateVariableName?: string, templateVariableNames?: string[],
            templateVariableType?: TemplateVariableType, remoteTags?: IRemoteContextualSegments) {

        if (!templateVariableName || templateVariableName.length === 0) {
            throw new Error(COMMON_ERROR_MESSAGES.MISSING_VARIABLE_NAME);
        }

        if (templateVariableNames && templateVariableNames.filter(x => x === templateVariableName).length > 1) {
            throw new Error(COMMON_ERROR_MESSAGES.UNIQUE_VARIABLE_NAME);
        }

        if (templateVariableType === TemplateVariableType.REMOTE_SERVICE
            && (remoteTags?.includeRemoteSegments.map(s => s.name).includes(templateVariableName) ||
                remoteTags?.excludeRemoteSegments.map(s => s.name).includes(templateVariableName))) {
            throw new Error(COMMON_ERROR_MESSAGES.DUPLICATE_REMOTE_VARIABLE);
        }
    }

    static isTemplateVariableDescriptionValid(templateVariableDescription?: string) {
        if (isStringEmpty(templateVariableDescription)) {
            throw new Error(COMMON_ERROR_MESSAGES.MISSING_VARIABLE_DES);
        }
    }

    static isTemplateVariableDedupeByContentValid(templateVariableDedupe?: boolean, templateVariableDedupes?: boolean[]) {
        if (templateVariableDedupe && templateVariableDedupes && templateVariableDedupes.filter(x => x).length > 1) {
            throw new Error(COMMON_ERROR_MESSAGES.SINGLE_DEDUPE_BY_CONTENT);
        }
    }

    static isSnsTopicArnValid(snsTopicArn?: string) {
        if (!snsTopicArn || snsTopicArn.length === 0) {
            throw new Error(COMMON_ERROR_MESSAGES.MISSING_SNS_TOPIC_ARN);
        }
        if (snsTopicArn.split(':').length !== 6) {
            throw new Error(COMMON_ERROR_MESSAGES.INVALID_ARN_BYOR_SNS_TOPIC.replace('%s', snsTopicArn));
        }
    }

    static isJourneyFieldPresent(field?: string, fieldName?: string) {
        if (isStringEmpty(field)) {
            if (fieldName === 'journeyId') {
                throw new Error(COMMON_ERROR_MESSAGES.MISSING_JOURNEY_IDENTIFIER);
            } else if (fieldName === 'journeyName'){
                throw new Error(COMMON_ERROR_MESSAGES.MISSING_JOURNEY_NAME);
            }
        }
    }

    // Invocation Parameters Validation
    static atLeastOneEndpointConfigured(endpoints: {[key in Region]?: string}) {
        if (Object.values(endpoints).every(value => isStringEmpty(value))) {
            throw new Error(COMMON_ERROR_MESSAGES.MISSING_IP_ENDPOINT);
        }
    }

    static isEndpointValid(endpoint?: string) {
        if (endpoint) {
            try {
                // tslint:disable-next-line: no-unused-expression
                new URL(endpoint);
            } catch (error) {
                throw new Error(COMMON_ERROR_MESSAGES.INVALID_IP_URL_TEMPLATE.replace('%s', endpoint));
            }
        }
    }

    static isCTIValid(cti?: string) {
        if (!cti || cti.length === 0) {
            throw new Error(COMMON_ERROR_MESSAGES.MISSING_IP_CTI);
        }

        if (cti.split('/').length !== 3) {
            throw new Error(COMMON_ERROR_MESSAGES.INVALID_IP_CTI);
        }
    }

    static isAAAServiceNameValid(aaaServiceName?: string) {
        if (isStringEmpty(aaaServiceName)) {
            throw new Error(COMMON_ERROR_MESSAGES.MISSING_IP_SERVICE_NAME);
        }
    }

    static isAAAOperationNameValid(aaaOperationName?: string, serializationMethod?: SerializationMethod) {
        if (isStringEmpty(aaaOperationName)) {
            throw new Error(COMMON_ERROR_MESSAGES.MISSING_IP_OPERATION_NAME);
        }

        if ((serializationMethod === SerializationMethod.CORAL) && (aaaOperationName !== 'GetDomainInformation')) {
            throw new Error(COMMON_ERROR_MESSAGES.INVALID_IP_OPERATION_NAME_CORAL_SERIALIZATION);
        }
    }

    static isAssumeRoleArnValid(assumeRoleArn?: string) {
        if (isStringEmpty(assumeRoleArn)) {
            throw new Error(COMMON_ERROR_MESSAGES.MISSING_IP_ROLE_ARN);
        }
    }

    static isEncryptionMethodValid(encryptionMethod?: EncryptionMethod, authenticationMethod?: AuthenticationMethod) {
        if ((encryptionMethod === EncryptionMethod.KeyMaster) && (authenticationMethod === AuthenticationMethod.IAM_AUTH)) {
            throw new Error(COMMON_ERROR_MESSAGES.INVALID_KEY_MASTER_ENTRY_WITH_IAM_AUTH);
        }
    }

    static isSerializationMethodValid(serializationMethod?: SerializationMethod, authenticationMethod?: AuthenticationMethod) {
        if ((serializationMethod === SerializationMethod.CORAL) && (authenticationMethod === AuthenticationMethod.IAM_AUTH)) {
            throw new Error(COMMON_ERROR_MESSAGES.INVALID_CORAL_SERIALIZATION_WITH_IAM_AUTH);
        }
    }

    static isStringLengthExceeded(length: number, validateString?: string) {
        if (validateString && validateString.length > length) {
            throw new Error(COMMON_ERROR_MESSAGES.INPUT_STRING_TO_LONG);
        }
    }

    static isValidSsml(ssmlString: string) {
        const domParser = new DOMParser();
        const parsedXml = domParser.parseFromString(ssmlString, 'application/xml');

        if (parsedXml.getElementsByTagName('parsererror').length > 0) {
            throw new Error('SSML content has open tags or mismatching tags');
        }
    }

    static isWeblabValid(weblab?: string) {
        if (weblab && (weblab.startsWith('http') || weblab.startsWith('www') || weblab.includes('amazon.com'))) {
            throw new Error(COMMON_ERROR_MESSAGES.INVALID_WEBLAB_URL);
        }

        if (weblab && (weblab.includes(' '))) {
            throw new Error(COMMON_ERROR_MESSAGES.INVALID_WEBLAB_WHITESPACE);
        }
    }

    static isExperienceIdValid(experienceId?: string) {

        if (experienceId && !new RegExp(EXPERIENCE_ID_REGEX).test(experienceId)) {
            throw new Error(COMMON_ERROR_MESSAGES.INVALID_EXPERIENCE_ID);
        }

    }

    static isMcmLinkValid(mcmLink?: string) {

        if (mcmLink && !new RegExp(MCM_LINK_REGEX).test(mcmLink)) {
            throw new Error(COMMON_ERROR_MESSAGES.INVALID_MCM_LINK);
        }
    }

    static isExperienceReadyForAlexaLabMetrics(experience: IFlattenedExperience) {

        const isUserAdmin: boolean = getRole(experience.permissionRole) === 'ADMIN';
        const isApproved: boolean = isExperienceApproved(experience.status);

        if (isApproved && !isUserAdmin) {
            return;
        }


        const errorMessages: string[] = [];
        if (experience.type === 'CIF') {
            const validTargetings = experience.nluOpportunityTargetings.reduce((prev, cur) => {
                // OR logic ensures as long as one targeting is not empty, it returns true
                return prev || isNotEmptyTargeting(cur);
            }, false);

            if (!validTargetings) {
                errorMessages.push(COMMON_ERROR_MESSAGES.INVALID_TARGETING_CONFIGURATION);
            }
        }

        if (!experience.suggestedFeature || !experience.suggestedFeature.nluIntent) {
            errorMessages.push(COMMON_ERROR_MESSAGES.MISSING_SUGGESTED_FEATURE_INTENT);
        }

        if (!experience.weblab || !experience.weblab.name) {
            errorMessages.push(COMMON_ERROR_MESSAGES.MISSING_WEBLAB);
        }

        if (errorMessages.length > 0) {
            throw new ValidationError(errorMessages);
        }
    }

    static isImageUrlValid(imageUrl?: string) {

        if (imageUrl && imageUrl.includes(' ')) {
            throw new Error(COMMON_ERROR_MESSAGES.INVALID_IMAGE_URL_WHITESPACE);
        }
    }

    static isReleaseDateValid(releaseDate?: string) {
        if (releaseDate && !new RegExp('RELEASE_[0-9]+_[0-9]').test(releaseDate)) {
            throw new Error(COMMON_ERROR_MESSAGES.INVALID_RELEASE_SCHEDULE_INPUT);
        }
    }

    static isSubscriptionUpsellFieldValid(data?: string, errorLocationDescription?: string) {
        if (isStringEmpty(data)) {
            throw new Error(COMMON_ERROR_MESSAGES.SUBSCRIPTION_UPSELL_TYPE_NOT_FOUND.replace('%s', errorLocationDescription || ''));
        }
    }
}
