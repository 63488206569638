import * as React from 'react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { Alert, FormField, Input, Button, Spinner } from '@amzn/awsui-components-react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { pushFeatureHintsSectionAction } from '../../actions/experienceEditViewActions';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { ExternalLink } from '../../components/common/LinkComponents';

interface IFeatureHintSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

interface IFeatureHintSectionViewState {
    featureHints?: string[];
}

export class FeatureHintSectionView extends React.Component<
    IFeatureHintSectionViewProps,
    IFeatureHintSectionViewState> {

    constructor(props: IFeatureHintSectionViewProps) {
        super(props);
        const { updateCandidate } = this.props;
        this.state = {featureHints: updateCandidate?.getFeatureHints()};
    }

    updateFeatureHints(featureHints: string[]) {
        const { dispatch } = this.props;
        dispatch(pushFeatureHintsSectionAction(featureHints));
        this.setState({featureHints});
    }

    render() {
        const { experience, isEditing, updateCandidate } = this.props;

        if (!experience) {
            return <FormSectionView title={'Feature Hints'}>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const featureHints = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
            ? updateCandidate.getFeatureHints() || []
            : experience.featureHints || []);


        const featureHintsWiki = 'https://wiki.labcollab.net/confluence/display/Doppler/Odyssey+CIF+Experience+Setup#Workflows-1695156600';

        return <FormSectionView title='Feature hints'>
            <Alert>
            The <b>Feature Hints</b> field (not to be confused with the Suggested Feature field) allows
            you to prevent customers from receiving this experience if they have already used the
            feature promoted in the Feature Hint in the past 30 days. For example, if you'd like to
            make sure that nobody who has already used the Weather feature receives your use case
            about how to use that feature, then enter in a Feature Hint ID for a Weather hint into this field.
            More information about feature hints can be found&nbsp;
                <ExternalLink href={featureHintsWiki}>here</ExternalLink>.
            </Alert>
            <FormField label='Feature hint'>
                {featureHints && featureHints.length > 0
                    ? featureHints.map((featureHint, index) =>
                        <div className='awsui-grid'>
                            <div className='awsui-row'>
                                <div className='col-8'>
                                    <Input
                                        readonly={true}
                                        id={`experience.feature-hint-${featureHint}`}
                                        controlId={`experience.feature-hint-${featureHint}`}
                                        value={featureHint}
                                        onInput={(e) => {
                                            featureHints[index] = e.detail.value.trim();
                                            this.updateFeatureHints(featureHints);
                                        }} />
                                </div>
                                <div className='col-4'>
                                    <Button
                                        id={`button.remove-feature-hint-${featureHint}`}
                                        disabled={true}
                                        onClick={() => {
                                            featureHints.splice(index, 1);
                                            this.updateFeatureHints(featureHints);
                                        }}>Remove</Button>
                                </div>

                            </div>
                        </div>)
                    : <p className='awsui-util-status-inactive'>No feature hints configured for this experience</p>
                }
                <div className='awsui-util-mt-l awsui-util-mb-l'>
                    <Button
                        disabled={true}
                        icon='add-plus'
                        id='button.add-feature-hint'
                        onClick={() => {
                            featureHints.push('');
                            this.updateFeatureHints(featureHints);
                        }}>Add feature hint</Button>
                </div>
            </FormField>
        </FormSectionView>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(FeatureHintSectionView);
