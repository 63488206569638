
export const LOCALE_TO_LANG: { [locale: string]: string } = {
    'ar_SA': 'ar',
    'de_DE': 'de',
    'en_CA': 'en',
    'en_GB': 'en',
    'en_IN': 'en',
    'en_US': 'en',
    'es_ES': 'es',
    'es_MX': 'es',
    'es_US': 'es',
    'fr_CA': 'fr',
    'fr_FR': 'fr',
    'hi_IN': 'hi',
    'it_IT': 'it',
    'nl_NL': 'nl',
    'pt_BR': 'pt',
    'ja_JP': 'ja',
    'en_AU': 'en'
};

export const LOCALE_TO_MARKETPLACE: { [locale: string]: string } = {
    'ar_SA': 'US',
    'de_DE': 'DE',
    'en_CA': 'CA',
    'en_GB': 'UK',
    'en_IN': 'IN',
    'en_US': 'US',
    'es_ES': 'ES',
    'es_MX': 'MX',
    'es_US': 'US',
    'fr_CA': 'CA',
    'fr_FR': 'FR',
    'hi_IN': 'IN',
    'it_IT': 'IT',
    'pt_BR': 'BR',
    'nl_NL': 'NL',
    'ja_JP': 'JP',
    'en_AU': 'AU'
};


export const isRightToLeftLocale = (locale: string) => {
    const rightToLeftLocale = ['ar_SA'];

    return rightToLeftLocale.includes(locale);
};

export const DEFAULT_LOCALE: string = 'en_US';
