import * as AcopsGateway from '../api/acopsGateway';
import PromisePool from 'es6-promise-pool';
import { isLiveExperience, bulkAddDeviceExperience, getRightExposureFromStatus } from '../../src/util/stringAndMappingHelper';
import { IFlattenedExperience } from '../models/FlattenedExperience';
import { updatedProgressBar } from './experienceListViewActions';
import { BulkUpdateActionType } from '../models/BulkUpdateActionType';
import { Dispatch } from 'redux';
import { THROTTLE_LIMIT } from '../constants/throttleLimit';
import { ExperienceUpdateCandidate } from '../models/ExperienceUpdateCandidate';
import { MARKETPLACES_FLIPPED } from '../constants/marketplaces';

const CONCURRENT_REQUEST_LIMIT = THROTTLE_LIMIT; // matches gizmo throttling limit

export const bulkUpdateAction = (experiences: IFlattenedExperience[]) => async (dispatch: Dispatch): Promise<{ succeededItems: string[], failedItems: string[] }> => {
    const succeededItems: string[] = [];
    const failedItems: string[] = [];

    const updateExperience = async (experience: IFlattenedExperience): Promise<void> => {
        try {
            const id = await AcopsGateway.putExperience(experience);
            succeededItems.push(id);
            dispatch(updatedProgressBar(succeededItems.length, failedItems.length));
        } catch (error) {
            console.error('Caught error: ', error);
            failedItems.push(experience.id);
            dispatch(updatedProgressBar(succeededItems.length, failedItems.length));
        }
    };

    let counter = 0;

    const bulkUpdateProducer = (experiencesToUpdate: IFlattenedExperience[]) => (): Promise<void> | undefined => {
        if (counter < experiencesToUpdate.length && experiencesToUpdate[counter]) {
            const promise = updateExperience(experiencesToUpdate[counter]);
            counter++;
            return promise;
        } else {
            return undefined;
        }
    };

    // Create a pool.
    const pool = new PromisePool(bulkUpdateProducer(experiences), CONCURRENT_REQUEST_LIMIT);

    // Start the pool.
    await pool.start();

    console.log('Succeeded experience IDs: ', succeededItems);
    console.log('Failed experience IDs: ', failedItems);

    return {
        succeededItems,
        failedItems
    };
};

export const bulkTransferOwner = (experience: IFlattenedExperience, newOwner: string, updateAction: BulkUpdateActionType | undefined): IFlattenedExperience => {
    const experienceCopy = ExperienceUpdateCandidate.deepClone(experience);
    switch (updateAction) {
        case BulkUpdateActionType.TransferOwner:
            experienceCopy.metadata.createdBy = newOwner;
            break;
        default:
    }
    return experienceCopy;
};

export const bulkUpdateDevices = (experience: IFlattenedExperience, devices: string[], updateAction: BulkUpdateActionType | undefined): IFlattenedExperience => {
    if (experience.type === 'AppNotification' || !bulkAddDeviceExperience(experience.status)) {
        return experience;
    }
    const experienceCopy = ExperienceUpdateCandidate.deepClone(experience);
    let updateDevices: string[] = [];
    const existingDevices = experienceCopy.enabledFilter?.deviceTypes || [];
    switch (updateAction) {
        case BulkUpdateActionType.AddDevice:
            updateDevices = devices.filter(c => !existingDevices.includes(c)).concat(existingDevices);
            break;
        case BulkUpdateActionType.RemoveDevice:
            updateDevices = existingDevices.filter(c => !devices.includes(c));
            break;
        case BulkUpdateActionType.ConsolidateDevice:
            updateDevices = devices;
            break;
        default:
    }
    if (experience.type === 'CIF' && experienceCopy.filters && experienceCopy.marketplace){
        experienceCopy.filters[MARKETPLACES_FLIPPED[experienceCopy.marketplace]].deviceTypes = updateDevices;
    }
    if (experience.type === 'DeviceNotification' && experienceCopy.filters && experienceCopy.locale){
        experienceCopy.filters[experienceCopy.locale].deviceTypes = updateDevices;
    }
    return experienceCopy;
};

export const bulkUpdateExperienceStatus = (experience: IFlattenedExperience, updateAction: BulkUpdateActionType | undefined): IFlattenedExperience => {
    const experienceCopy = ExperienceUpdateCandidate.deepClone(experience);
    switch (updateAction) {
        case BulkUpdateActionType.Pause:
            if (isLiveExperience(experienceCopy.status) && experienceCopy.type === 'CIF' && experienceCopy.approvalInfo) {
                experienceCopy.weblab.launchTreatmentPercentage = 0;
                experienceCopy.approvalInfo.pauseDate = Date.now();
                experienceCopy.approvalInfo.pausedCount = (experienceCopy.approvalInfo.pausedCount || 0) + 1;
            }
            break;
        case BulkUpdateActionType.Unpause:
            if (experienceCopy.status === 'PAUSED' && experienceCopy.approvalInfo?.lastDialupStage && experienceCopy.type === 'CIF') {
                experienceCopy.weblab.launchTreatmentPercentage = getRightExposureFromStatus(experienceCopy.approvalInfo?.lastDialupStage);
                experienceCopy.approvalInfo.lastDialupDate = Date.now();
            }
            break;
        case BulkUpdateActionType.SendToTestable:
            if (isLiveExperience(experienceCopy.status)) {
                experienceCopy.status = 'DRAFT';
            }
            break;
        default:
    }
    return experienceCopy;
};
