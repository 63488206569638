import * as React from 'react';
import { Table, TableSorting } from '@amzn/awsui-components-react';
import { getLinkableUrlByProviderId } from '../page';
import { IProviderConfig } from '../../models/spiProviderConfig/ISpiProviderOnboarding';
import { defaultStringComparator } from '../tableColumnConfiguration';

interface IColumnConfig {
    id: string;
    header: string;
    width: number;
    minWidth: string;
    renderingFunction: (item: IProviderConfig ) => React.ReactNode;
    sortingComparator: (a: IProviderConfig, b: IProviderConfig) => number;
}

const providerIdColumnConfig: IColumnConfig = {
    id: 'providerId',
    header: 'Provider Id',
    width: 350,
    minWidth: '350px',
    renderingFunction: item => {
        return <a href={getLinkableUrlByProviderId(item.providerId)}>{item.providerId || '-'}</a>;
    },
    sortingComparator: (a, b) => defaultStringComparator(a.providerId, b.providerId)
};

const columnConfigs: IColumnConfig[] = [
    providerIdColumnConfig
];

export const COLUMN_DEFINITIONS: Table.ColumnDefinition<IProviderConfig>[] = columnConfigs.map(
    (config: IColumnConfig) => {
        return {
            id: config.id,
            header: config.header,
            minWidth: config.minWidth,
            cell: config.renderingFunction
        };
    });


export const SORTABLE_COLUMNS: TableSorting.SortableColumn<IProviderConfig>[] = columnConfigs.map(
    (config: IColumnConfig) => {
        return {
            id: config.id,
            field: config.id,
            comparator: config.sortingComparator
        };
    });
