import * as React from 'react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { FormField, Button, Spinner, Alert, Select } from '@amzn/awsui-components-react';
import { ITemplateVariable, isTemplateVariableRemote, isVariableTypeBYOR, EMPTY_TEMPLATE_VARIABLE, BYOR_TEMPLATE_VARIABLE } from '../../models/TemplateVariable';
import { InvocationParameterView } from '../../components/common/InvocationParametersView';
import { ContentVariableComponent } from '../../components/common/ContentVariableComponent';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { pushTemplateVariablesSectionAction, pushByorSnsTopicArn } from '../../actions/experienceEditViewActions';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { IInvocationParameters} from '../../models/InvocationParameters';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { InputWrapper } from '../../components/common/InputWrapper';
import { CommonValidator } from '../../util/CommonValidator';
import { CheckboxWrapper } from '../../components/common/CheckboxWrapper';
import { ExternalLink } from '../../components/common/LinkComponents';
import { IRemoteContextualSegments, EMPTY_REMOTE_CONTEXTUAL_SEGMENTS } from '../../models/RemoteContextualSegments';

interface IContentVariablesSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

interface IContentVariablesSectionViewState {
    templateVariables?: ITemplateVariable[];
    snsTopicBYORDataSource?: string;
}

export const anyRemoteVariable = (variables: ITemplateVariable[]) => {
    return variables.filter(variable => (variable.variableType === 'REMOTE_SERVICE' &&
        variable.invocationParameters?.CTI !== 'Alexa/PAGE/BYOR')).length > 0;
};

export const anyBYORVariable = (variables: ITemplateVariable[]) => {
    return variables.filter(variable => (variable.variableType === 'REMOTE_SERVICE' &&
        variable.invocationParameters?.CTI === 'Alexa/PAGE/BYOR')).length > 0;
};

export const allSupportType = [
    {
        'label': 'SLOT_VALUE',
        'id': 'SLOT_VALUE',
        'description': 'From the original utterance'
    },
    {
        'label': 'PUBLISHER_DATA',
        'id': 'PUBLISHER_DATA',
        'description': 'Custom data published by the Speechlet'
    },
    {
        'label': 'REMOTE_SERVICE',
        'id': 'REMOTE_SERVICE',
        'description': 'Values pulled form a DVP remote service'
    },
    {
        'label': 'BYOR',
        'id': 'BYOR',
        'description': 'Bring Your Own Recommendation (accessible in Edit View)'
    }
] as Select.Option[];

export const notificationSupportType = [
    {
        'label': 'REMOTE_SERVICE',
        'id': 'REMOTE_SERVICE',
        'description': 'Values pulled from a Dynamic Variable Provider (DVP) remote service'
    },
    {
        'label': 'BYOR',
        'id': 'BYOR',
        'description': 'Bring your own recommendations (Accessible in Edit View)'
    }
] as Select.Option[];

export class ContentVariablesSectionView extends React.Component<
    IContentVariablesSectionViewProps,
    IContentVariablesSectionViewState> {

    constructor(props: IContentVariablesSectionViewProps) {
        super(props);
        const { updateCandidate } = this.props;
        this.state = {templateVariables: updateCandidate?.getTemplateVariables()};
    }

    componentDidMount() {
        const { experience } = this.props;
        if (experience) {
            this.setState({templateVariables: experience?.templateVariables});
        }
    }

    updateTemplateVariables(templateVariables?: ITemplateVariable[]) {
        const { dispatch } = this.props;
        dispatch(pushTemplateVariablesSectionAction(templateVariables));
        this.setState({templateVariables});
    }

    updateSnsTopicArn(snsTopicBYORDataSource: string) {
        const { dispatch } = this.props;
        dispatch(pushByorSnsTopicArn(snsTopicBYORDataSource));
        this.setState({snsTopicBYORDataSource});
    }

    render() {
        const { experience, isEditing, updateCandidate, isUpdating } = this.props;

        if (!experience) {
            return <FormSectionView title={'Content Variables'}>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const isNotificationExperience = (experience && (experience.type === 'AppNotification' || experience.type === 'DeviceNotification'));

        // We only expose a subset of TemplateVariableTypes for AppNotification types.
        const supportedTemplateVariableTypes = isNotificationExperience ? notificationSupportType : allSupportType;
        const byorOption = supportedTemplateVariableTypes.find(v => v.id === 'BYOR');
        if (!experience.id && byorOption) {
            byorOption.disabled = true;
        } else if (experience.id && byorOption){
            byorOption.disabled = false;
        }

        const templateVariables: ITemplateVariable[] = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
            ? updateCandidate.getTemplateVariables() || []
            : experience.templateVariables || []);

        const remoteTags: IRemoteContextualSegments = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
            ? updateCandidate.getRemoteTags()
            : experience.remoteContextualSegments || EMPTY_REMOTE_CONTEXTUAL_SEGMENTS);

        let snsTopicArn: string = ExperienceUpdateCandidate.deepClone((isEditing && updateCandidate)
            ? updateCandidate.getSnsTopicBYORDataSource() || ''
            : experience.snsTopicBYORDataSource || '');

        const shouldDisableInput = !isEditing || isUpdating;

        return <FormSectionView title='Content Variables' description="
            Content variables allow you to create dynamic content at
            runtime based on a slot value from the customer's
            original utterance, custom data published by
            the originating Speechlet, or values pulled from
            a Dynamic Variable Provider (DVP) remote service."
        >
            {anyRemoteVariable(templateVariables) &&
            <Alert>
                The launch Weblab for an invocation parameter is checked before the configured DVP is called, if T1 is returned the call is made.
                If not T1 the experience is filtered out.
                The intention is to use this when initially integrating a DVP to allow service to control how ACOPS is calling them.
                If a DVP is configured across multiple experiences this is a more convenient way to control traffic to that DVP
            </Alert>}
            {anyBYORVariable(templateVariables) &&
            <Alert>
                Please refer
                    <ExternalLink
                        href='https://w.amazon.com/bin/view/AlexaAutoDisco/Knowledge_Base/BYOR'
                        > here</ExternalLink> for Bring Your Own Recommendations (BYOR) user manual.
            </Alert>}
            {updateCandidate?.getContent().isEntireContentATemplateVariable &&  <Alert id='alert.entire-content-is-a-template-variable'>
                Since the entire content is a single variable, please add more detailed description for the variable.
                Description should include a minimum of 30 characters.
            </Alert>}
            <br></br>
            {templateVariables && templateVariables.length > 0 ?
                templateVariables.map((templateVariable, index) =>
                    <div className='awsui-grid' key={index}>
                            {(!isVariableTypeBYOR(templateVariables[index])) &&
                            <div>
                            <ContentVariableComponent
                                index={index}
                                templateVariables={templateVariables}
                                shouldDisableInput={shouldDisableInput}
                                supportedTemplateVariableTypes={supportedTemplateVariableTypes}
                                remoteTags={remoteTags}
                                updateTemplateVariables={(templateVariableCopy: ITemplateVariable[]) => {
                                    this.updateTemplateVariables(templateVariableCopy);
                                }}
                                updateSnsTopicArn={(snsTopicArnCopy: string) => {
                                    this.updateSnsTopicArn(snsTopicArnCopy);
                                }}
                                isNonDefaultBYOR={false}
                            ></ContentVariableComponent>
                            </div>}
                                {(isVariableTypeBYOR(templateVariables[index])) &&
                                    <div>
                                        <ContentVariableComponent
                                            index={index}
                                            templateVariables={templateVariables}
                                            shouldDisableInput={shouldDisableInput}
                                            supportedTemplateVariableTypes={supportedTemplateVariableTypes}
                                            remoteTags={remoteTags}
                                            updateTemplateVariables={(templateVariableCopy: ITemplateVariable[]) => {
                                                this.updateTemplateVariables(templateVariableCopy);
                                            }}
                                            updateSnsTopicArn={(snsTopicArnCopy: string) => {
                                                this.updateSnsTopicArn(snsTopicArnCopy);
                                            }}
                                            isNonDefaultBYOR={isVariableTypeBYOR(templateVariables[index-1])}
                                    ></ContentVariableComponent>
                                    </div>}
                                {// Only add the following section after the last BYOR variable.
                                isVariableTypeBYOR(templateVariables[index]) && (index+1 === templateVariables.length || !isVariableTypeBYOR(templateVariables[index+1])) &&
                                <div>
                                    <Button
                                        disabled={shouldDisableInput}
                                        id='button.add-byor-variable'
                                        onClick={() => {
                                            templateVariables.splice(index+1, 0, BYOR_TEMPLATE_VARIABLE);
                                            this.updateTemplateVariables(templateVariables);
                                        }} >Add another BYOR variable</Button>
                                    <p>
                                    <br></br>
                                    <b>BYOR Notification SNS Topic Data Source</b>
                                    </p>
                                    <p>
                                    Specify data source information of your recommendations. We wll copy those to our datastore and serve them at runtime.
                                    </p>
                                    <FormField label={<RequiredField fieldName='SNS Topic ARN' />}
                                        description='Whenever new recommendations notification event
                                        is published to this SNS Topic, we will schedule copying of
                                        those recommendations to our datastore.'>
                                        <InputWrapper
                                            id={'experience.sns-topic-arn'}
                                            readonly={shouldDisableInput}
                                            value={snsTopicArn}
                                            onInput={(input: string) => {
                                                snsTopicArn = input;
                                                this.updateSnsTopicArn(snsTopicArn);

                                            }}
                                            validate={CommonValidator.isSnsTopicArnValid} />
                                    </FormField>
                                    <hr />
                                    </div>
                                }
                                {(isTemplateVariableRemote(templateVariable)
                                    && templateVariable.invocationParameters) &&
                                    <InvocationParameterView
                                        arrayKey={'' + index}
                                        invocationParameters={templateVariable.invocationParameters}
                                        isNotificationExperience={isNotificationExperience}
                                        disableInput={shouldDisableInput}
                                        updateInvocationParameters={(invocationParameters: IInvocationParameters) => {
                                            templateVariables[index].invocationParameters = invocationParameters;
                                            this.updateTemplateVariables(templateVariables);
                                        }}
                                        />}
                        {(!isVariableTypeBYOR(templateVariables[index])) &&
                            <div className='awsui-util-mt-xxl awsui-util-mb-xxl'>
                            <CheckboxWrapper
                                id={`experience.dedupe-by-content-${index}`}
                                disabled={shouldDisableInput}
                                checked={templateVariable.dedupeByContent || false}
                                onChange={(e) => {
                                    templateVariables[index].dedupeByContent = e.detail.checked;
                                    this.updateTemplateVariables(templateVariables);
                                }}
                                validate={(c) => CommonValidator.isTemplateVariableDedupeByContentValid(c,
                                    templateVariables.map(x => x.dedupeByContent || false))}>
                                Enable individual content-level guardrails.
                                We will treat each possible template value as a separate entity for guardrail purposes.
                                Customers will receive this experience more often as a result.
                            </CheckboxWrapper>
                        <hr />
                        </div>
                        }
                    </div>)
                : <p className='awsui-util-status-inactive'>No content variables configured for this experience</p>}
            <Button
                disabled={shouldDisableInput}
                id='button.add-variable'
                onClick={() => {
                    templateVariables.push(EMPTY_TEMPLATE_VARIABLE);
                    this.updateTemplateVariables(templateVariables);
                }} >Add variable</Button>
        </FormSectionView>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(ContentVariablesSectionView);
