import * as React from 'react';
import { ColumnLayout, Spinner, FormField, FormSection } from '@amzn/awsui-components-react';
import { FormSectionView } from '../../components/common/FormSectionView';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { pushJourneyMetadata } from '../../actions/experienceEditViewActions';
import { LinkedPermissionGroup } from '../../components/common/LinksRenderHelper';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { IJourneyMetadata } from '../../models/JourneyMetadata';
import { InputWrapper } from '../../components/common/InputWrapper';
import { CommonValidator } from '../../util/CommonValidator';


interface IJourneySectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

export class JourneySectionView extends React.Component<IJourneySectionViewProps> {

    updateJourneyInfo(journeyId?: string, journeyName?: string) {
        const { dispatch } = this.props;
        dispatch(pushJourneyMetadata({ journeyId, journeyName }));
    }

    render() {
        const { experience, isEditing, updateCandidate, isUpdating } = this.props;
        if (!experience) {
            return <FormSectionView title='Journey Configuration'>
                <Spinner size='large' variant='disabled' />
            </FormSectionView>;
        }

        const journeyData: IJourneyMetadata = (isEditing && updateCandidate)
            ? updateCandidate.getJourneyMetadata() || {}
            : ExperienceUpdateCandidate.extractJourneyMetadata(experience) || {};

        const shouldDisableInput = !isEditing || isUpdating;

        return <FormSection
            header='Journey Configuration'
            expandable={true}>
            <div>This feature will only work if experience creator is under the <LinkedPermissionGroup permissionGroup='journey-experience-owners' /> LDAP group.</div><br />
            <ColumnLayout columns={1} borders='vertical'>
                <div data-awsui-column-layout-root='true'>
                    <div>
                        <div className='awsui-util-mb-m'>
                            <FormField label={<RequiredField fieldName='Journey identifier' />} description='This is the identifier of the journey as configured in the Everest tool.'>
                                <InputWrapper
                                    id='experience.journey.metadata.journeyId'
                                    placeholder='Journey identifier here'
                                    value={journeyData ? journeyData.journeyId : ''}
                                    readonly={shouldDisableInput}
                                    validate={(journeyId) => CommonValidator.isJourneyFieldPresent(journeyId, 'journeyId')}
                                    onInput={(input: string) => {
                                        this.updateJourneyInfo(input.trim());
                                    }}
                                />
                            </FormField>
                            <FormField label={<RequiredField fieldName='Journey name' />} description='This is the friendly name of the journey as configured in the Everest tool.'>
                                <InputWrapper
                                    id='experience.journey.metadata.journeyName'
                                    placeholder='Journey name here'
                                    value={journeyData ? journeyData.journeyName: ''}
                                    readonly={shouldDisableInput}
                                    validate={(journeyName) => CommonValidator.isJourneyFieldPresent(journeyName, 'journeyName')}
                                    onInput={(input: string) => {
                                        this.updateJourneyInfo(undefined, input.trim());
                                    }}
                                />
                            </FormField>
                        </div>
                    </div>
                </div>
            </ColumnLayout>
        </FormSection>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState, bullseyeMetadataState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate,
        error: bullseyeMetadataState.error || undefined
    };
};

export default connect(mapStateToProps)(JourneySectionView);
