import { CognitoAuth } from 'amazon-cognito-auth-js';
import { Stage } from '../util/context';

// Mapping of domain, region to their respective Cognito authentication configuration
export type IAuthMap = {
    [D in Stage]: CognitoAuth
};

// Constructs the Cognito authentication configuration to use in production
export const getOdysseyAuthMap = function (): IAuthMap {
    const callbackUrl = window.location.origin + '/';
    return {
        Alpha: new CognitoAuth({
                AppWebDomain: 'poems-alpha.auth.us-west-2.amazoncognito.com',
                ClientId: 'ckla83g4gndbrv5lmd2h16nbp',
                RedirectUriSignIn: callbackUrl,
                RedirectUriSignOut: callbackUrl,
                TokenScopesArray: ['openid'],
                UserPoolId: 'us-west-2_voDna0JtK'
        }),
        Beta: new CognitoAuth({
                AppWebDomain: 'poems-beta.auth.us-west-2.amazoncognito.com',
                ClientId: '12dcpti79pt4p81nncslo6umtt',
                RedirectUriSignIn: callbackUrl,
                RedirectUriSignOut: callbackUrl,
                TokenScopesArray: ['openid'],
                UserPoolId: 'us-west-2_Qp3QiZqS1'
        }),
        Gamma: new CognitoAuth({
                AppWebDomain: 'poems-gamma.auth.us-east-1.amazoncognito.com',
                ClientId: '1v0d3jhb4n4lgssgv6bhs9hm76',
                RedirectUriSignIn: callbackUrl,
                RedirectUriSignOut: callbackUrl,
                TokenScopesArray: ['openid'],
                UserPoolId: 'us-east-1_BHzCKurzI'
        }),
        Prod: new CognitoAuth({
                AppWebDomain: 'poems-prod.auth.us-east-1.amazoncognito.com',
                ClientId: '3l979cvhn2gge1pce6hh1hsn7d',
                RedirectUriSignIn: callbackUrl,
                RedirectUriSignOut: callbackUrl,
                TokenScopesArray: ['openid'],
                UserPoolId: 'us-east-1_KrSCXmim5'
        })
    };
};
