import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Spinner } from '@amzn/awsui-components-react';
import { AppState } from '../reducers/index';
import { IFlattenedExperience } from '../models/FlattenedExperience';
import { IAlexaLabMetrics, AlexaLabTreatment } from '../models/AlexaLabMetrics';
import { ExperienceMetricsReportTimestamp } from '../components/metrics/ExperienceMetricsReportTimestamp';
import { getAlexaLabMetricsAction, getCXDailyMetricsAction, getCXWeeklyMetricsAction, getSimilarExperiencesAction} from '../actions/experienceMetricsViewActions';
import { ExperienceMetricsOverview } from '../components/metrics/ExperienceMetricsOverview';
import { ExperienceDialUpMetrics } from '../components/metrics/ExperienceDialUpMetrics';
import { ExperienceMetricsCustomersReached } from '../components/metrics/ExperienceMetricsCustomersReached';
import { ExperienceMetricsIntentPerformance } from '../components/metrics/ExperienceMetricsIntentPerformance';
import { ExperienceMetricsAlert } from '../components/metrics/ExperienceMetricsAlert';
import { extractMarketplaceFromFlattenedExperience, getTypeIconPath, isLiveExperience } from '../util/stringAndMappingHelper';
import { CifMetricDDBNames, ExperienceMetricFrequency, IRawCifMetricsData } from '../models/CifMetrics';
import ExperienceApplicationNotificationMetrics from '../components/metrics/ExperienceApplicationNotificationMetrics';
import ExperienceCifMetrics from '../components/metrics/ExperienceCifMetrics';
import { MARKETPLACE_TO_REALM } from '../constants/marketplaces';
import { ISimilarExperiencesViewableData } from '../models/SimilarExperiencesData';
import { ExperienceSimilarDisplay } from '../components/metrics/ExperienceSimilarDisplay';

export interface IExperienceMetricsProps {
    dispatch: any;

    experience?: IFlattenedExperience;

    alexaLabMetrics?: IAlexaLabMetrics;
    cxWeeklyMetrics?: IRawCifMetricsData[];
    cxDailyMetrics?: IRawCifMetricsData[];
    similarExperiencesViewableData?: ISimilarExperiencesViewableData[];
    isAlexaLabMetricsLoading: boolean;
    isCXDailyMetricsLoading?: boolean;
    isCXWeeklyMetricsLoading?: boolean;
    isSimilarExperiencesLoading?: boolean;
    alexaLabMetricsError?: Error;
    cxWeeklyMetricsError?: Error;
    cxDailyMetricsError?: Error;
    similarExperiencesError?: Error;
}

class ExperienceMetrics extends Component<IExperienceMetricsProps> {
    private getAlexaLabMetrics() {
        const { dispatch, experience, alexaLabMetrics, isAlexaLabMetricsLoading, alexaLabMetricsError } = this.props;

        if (experience && experience.weblab.name && !alexaLabMetrics && !isAlexaLabMetricsLoading && !alexaLabMetricsError) {
            const marketplace = extractMarketplaceFromFlattenedExperience(experience);
            const realm = MARKETPLACE_TO_REALM[marketplace];

            dispatch(getAlexaLabMetricsAction(experience.weblab.name, realm, AlexaLabTreatment.T1, AlexaLabTreatment.C));
        }
    }

    private getCifMetrics() {
        const { dispatch, experience, isCXWeeklyMetricsLoading, isCXDailyMetricsLoading, cxWeeklyMetricsError, cxDailyMetricsError } = this.props;

        if (experience && !isCXDailyMetricsLoading && !isCXWeeklyMetricsLoading && !cxDailyMetricsError && !cxWeeklyMetricsError) {
            const experienceIds = [experience.id];
            const metricFilter = ([CifMetricDDBNames.TotalImpressions, CifMetricDDBNames.DistinctCustomers, CifMetricDDBNames.ConversionRate,
                CifMetricDDBNames.InterruptionRate, CifMetricDDBNames.NegativeFeedback]);

                dispatch(getCXDailyMetricsAction(experienceIds, 'CIF', ExperienceMetricFrequency.Day, 7, metricFilter));
                dispatch(getCXWeeklyMetricsAction(experienceIds, 'CIF', ExperienceMetricFrequency.Week, 7, metricFilter));
        }
    }

    private getSimilarExperiences(targetLevels: string[]) {
        const { dispatch, experience, isSimilarExperiencesLoading, similarExperiencesViewableData, similarExperiencesError } = this.props;

        if (experience && !isSimilarExperiencesLoading && !similarExperiencesViewableData && !similarExperiencesError) {
            const experienceId = experience.id;
            dispatch(getSimilarExperiencesAction(experienceId, targetLevels));
        }
    }

    public componentDidMount() {
        const { experience } = this.props;
        this.getAlexaLabMetrics();
        if (experience?.type === 'CIF') {
            this.getCifMetrics();
            this.getSimilarExperiences([]);
        }
    }

    public render() {
        const { experience, alexaLabMetrics, cxDailyMetrics, isAlexaLabMetricsLoading, alexaLabMetricsError, cxDailyMetricsError,
            cxWeeklyMetricsError, isCXDailyMetricsLoading, isCXWeeklyMetricsLoading, isSimilarExperiencesLoading, similarExperiencesViewableData } = this.props;


        if (isAlexaLabMetricsLoading || isCXDailyMetricsLoading || isCXWeeklyMetricsLoading) {
            return <div
                id='metrics.spinner'
                style={{ display: 'flex', justifyContent: 'center' }}
            >
                <span className='awsui-util-status-inactive'><Spinner />Loading metrics...</span>
            </div>;
        } else if (!experience) {
            return <Alert
                id='metrics.alert'
                type='error'
                content='Something happened while trying to load your metrics...'
            />;
        }

        const typeIconPath = getTypeIconPath(experience.type);
        let alert = null;

        if (alexaLabMetricsError) {
            alert = <Alert
                id='metrics.alert'
                type='error'
                content='Alexa Lab Metrics for this experience did not load properly.  Please try refreshing the page.'
            />;
        } else if (cxDailyMetricsError) {
            alert = <Alert
                id='metrics.alert'
                type='error'
                content='CIF Daily Metrics for this experience did not load properly.  Please try refreshing the page.'
            />;
        } else if (cxWeeklyMetricsError) {
            alert = <Alert
                id='metrics.alert'
                type='error'
                content='CIF Weekly Metrics for this experience did not load properly.  Please try refreshing the page.'
            />;
        } else if (experience) {
            alert = <ExperienceMetricsAlert experienceStatus={experience.status} alexaLabMetrics={alexaLabMetrics} />;
        }

        return <div className='awsui-grid'>
            {
                alert && <div className='awsui-row'><div className='col-12'>{alert}</div></div>
            }
            <div className='awsui-row'>
                <div className='col-8'>
                    <h1 id='experience-title'>
                        <img src={typeIconPath} alt='' style={{height:'35px',width:'35px',verticalAlign:'bottom'}} className='awsui-util-mr-xs' />{experience.title}
                    </h1>
                    <div className='awsui-util-container-header-description'>
                        <ExperienceMetricsReportTimestamp
                            id='metrics.overview.timestamp'
                            experience={experience}
                            alexaLabMetrics={alexaLabMetrics}
                        />
                    </div>
                </div>
            </div>
            <div className='awsui-row'>
                <div className='col-12'>
                    <ExperienceMetricsOverview alexaLabMetrics={alexaLabMetrics} cxDailyMetrics={cxDailyMetrics} experience={experience} />
                </div>
            </div>

            {experience.type === 'CIF' && isLiveExperience(experience.status) &&
            <div className='awsui-row'>
                <div className='col-12'>
                    <ExperienceDialUpMetrics alexaLabMetrics={alexaLabMetrics} cxDailyMetrics={cxDailyMetrics} experience={experience} />
                </div>
            </div>}

            <div className='awsui-row'>
                <div className='col-12'>
                    <ExperienceMetricsCustomersReached alexaLabMetrics={alexaLabMetrics} />
                </div>
            </div>
            <div className='awsui-row'>
                <div className='col-12'>
                    <ExperienceMetricsIntentPerformance alexaLabMetrics={alexaLabMetrics} experience={experience} />
                </div>
            </div>
            {
                experience.type === 'CIF' && (<div className='awsui-row'>
                    <div className='col-12'>
                        <ExperienceCifMetrics />
                    </div>
                </div>)
            }
            {
                experience.type === 'AppNotification' && (<div className='awsui-row'>
                    <div className='col-12'>
                        <ExperienceApplicationNotificationMetrics experience={experience} alexaLabMetrics={alexaLabMetrics} />
                    </div>
                </div>)
            }
            {
                experience.type === 'CIF' && (<div className='awsui-row'>
                    <div className='col-12'>
                        <ExperienceSimilarDisplay isLoading={isSimilarExperiencesLoading} similarExperiencesViewableData={similarExperiencesViewableData} />
                    </div>
                </div>)
            }
        </div>;
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceMetricsViewState, cxDailyMetricsState, cxWeeklyMetricsState, experienceSimilarExperincesState }: AppState) => {
    return {
        experience: experienceDetailViewState.experience || undefined,
        alexaLabMetrics: experienceMetricsViewState.alexaLabMetrics,
        cxDailyMetrics: cxDailyMetricsState.cxDailyMetrics,
        cxWeeklyMetrics: cxWeeklyMetricsState.cxWeeklyMetrics,
        isAlexaLabMetricsLoading: experienceMetricsViewState.isLoading,
        isCXDailyMetricsLoading: cxDailyMetricsState.isLoading,
        isCXWeeklyMetricsLoading: cxWeeklyMetricsState.isLoading,
        alexaLabMetricsError: experienceMetricsViewState.error,
        cxDailyMetricsError: cxDailyMetricsState.error,
        cxWeeklyMetricsError: cxWeeklyMetricsState.error,
        similarExperiencesViewableData: experienceSimilarExperincesState.similarExperiencesViewableData,
        isSimilarExperiencesLoading: experienceSimilarExperincesState.isLoading,
        similarExperiencesError: experienceSimilarExperincesState.error
    };
};

export default connect(mapStateToProps)(ExperienceMetrics);
