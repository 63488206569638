import * as React from 'react';
import { FormSection } from '@amzn/awsui-components-react';

interface IFormSectionProps {
    title: React.ReactNode;
    className?: string;
    description?: string;
    children: React.ReactNode;
    expandable?: boolean;
}

export const FormSectionView = (props: IFormSectionProps) => {
    const { title, description, children, expandable, className } = props;

    return <FormSection
        header={title}
        className={className}
        description={description}
        expandable={expandable ? expandable : false}
        expanded={true} >
            {children}
    </FormSection>;
};
