import { hasOnTheGoDevices } from '../constants/devices';
import { getExperienceLink } from '../constants/odysseyEndpoints';
import { IFlattenedExperience } from '../models/FlattenedExperience';
import { REQUESTED_DIALUP_STAGE_OPTIONS } from './exposureControlAllocationHelper';

export const generateSimApprovalTemplate = (
    experience: IFlattenedExperience,
    userHasTestedExperience: boolean,
    requiredForEmail: boolean,
    userReceivedLegalApproval: boolean,
    userCompletedOutboundBarRaising: boolean,
    userAcknowledgedOtgDevices: boolean,
    cxDescription: string,
    bullseyeDescription: string,
    isMonetaryExperience: string,
    requestedDialupStage?: REQUESTED_DIALUP_STAGE_OPTIONS,
    exceptionReason?: string,
    experienceConfigurationDifference?: string,
) => {
    const hasOtgDevices = hasOnTheGoDevices((experience?.enabledFilter?.deviceTypes) || []);
    return `### General information
| Name | Value |
|--|--|
| Experience link | [${experience.id}](${getExperienceLink(experience.id)}) |
| Experience Type | ${experience.type} |
| Experience locale | ${experience.locale || 'Un-specified'} |
| Does this experience give any monetary value (discounts, coupons, etc.) to customers? | ${isMonetaryExperience === 'true' ? 'Yes' : 'No'} |

---

### Tell us more about your experience

#### CX description of the content provided to the customer

${cxDescription}

#### What customer segment are you targeting specifically with your Bullseye?

${bullseyeDescription}
${experienceConfigurationDifference !== undefined ? generateExperienceConfigurationDifferenceTemplate(experienceConfigurationDifference) : ''}
---
### Testing questions
| Name | Value |
|--|--|
| Has this user tested the experience fully? | ${userHasTestedExperience ? 'Yes' : 'No'} |${requiredForEmail ? `
| Has this experience's Sonar Template received legal approval? | ${userReceivedLegalApproval ? 'Yes' : 'No'} |
| Has this experience's Sonar Template been Outbound Bar-Raised? | ${userCompletedOutboundBarRaising ? 'Yes' : 'No'} |` : ''}${hasOtgDevices ? `
| Has the requester acknowledged the usage of OTG devices? | ${userAcknowledgedOtgDevices ? 'Yes' : 'No'} |`: ''}

---
${requestedDialupStage !== undefined ? generateSimExceptionApprovalTemplate(exceptionReason!, requestedDialupStage) : ''}`;
};

export const generateExperienceConfigurationDifferenceTemplate = (experienceConfigurationDifference: string) => {
    return `#### Describe changes made to experience post pause

${experienceConfigurationDifference}
`;
};

export const generateSimExceptionApprovalTemplate = (exceptionReason: string, requestedDialupStage: REQUESTED_DIALUP_STAGE_OPTIONS) => {
    return `### Experience requested exceptional approval
#### Reason for obtaining exceptional approval

${exceptionReason}

---

#### After approval, what is the desired stage?

${requestedDialupStage}
`;
};
