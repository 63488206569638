import * as React from 'react';
import { Component } from 'react';
import { AppState } from '../reducers/index';
import { connect } from 'react-redux';
import { getBullseyeAction, getChangeControlDecisionAction } from '../actions/experienceDetailViewActions';
import { Button, Alert, Flashbar, Modal, Checkbox, Icon } from '@amzn/awsui-components-react';
import { getLinkableUrl, PAGE } from '../constants/page';
import ExperienceDetailGlobalView from '../components/detail/ExperienceDetailGlobalView';
import ExposureControlView from '../components/detail/ExposureControlSectionView';
import { IFlattenedExperience } from '../models/FlattenedExperience';
import { CifExperienceTabsView } from '../components/detail/CifExperienceTabsView';
import { NotificationExperienceTabsView } from '../components/detail/NotificationExperienceTabsView';
import { VisualCifExperienceTabsView } from '../components/detail/VisualCifExperienceTabsView';
import { LinkedApproval, LinkedSimTicket } from '../components/common/LinksRenderHelper';
import { saveExperienceAction, toggleCapabilitySearchModalVisibility } from '../actions/experienceEditViewActions';
import { getRole, getTypeIconPath, isLiveExperience } from '../util/stringAndMappingHelper';
import { ExperienceUpdateCandidate } from '../models/ExperienceUpdateCandidate';
import { StatusBadge } from '../components/common/StatusBadge';
import { enableEditingAction, cancelEditingAction } from '../actions/experienceEditViewActions';
import { PermissionRole } from '../models/PermissionRole';
import { getEventTrailAction } from '../actions/getEventTrailActions';
import { getPermissionAction } from '../actions/authenticationActions';
import { ExternalLink } from '../components/common/LinkComponents';
import { ActionType } from '../models/ActionType';
import { APPROVAL_SIM_TEMPLATE_IDS } from '../constants/approvals';
import { enforceStatusTransition } from '../util/cifExperienceHelper';
import { isNullOrUndefined } from 'util';
import SendForApprovalModal from '../components/modals/SendForApprovalModal';
import CancelExperienceModal from '../components/modals/CancelExperienceModal';
import DeleteExperienceModal from '../components/modals/DeleteExperienceModal';
import SkipApprovalModal from '../components/modals/SkipApprovalModal';
import MultiCloneModal from '../components/modals/MultiCloneModal';
import { CifMetricDDBNames, ExperienceMetricFrequency } from '../models/CifMetrics';
import { getCXWeeklyMetricsAction } from '../actions/experienceMetricsViewActions';
import _ from 'lodash';
import CapabilitySearchModal from '../components/modals/CapabilitySearchModal';
import { StatusBadgeMap } from '../constants/statusBadgeMap';
import {MOBILE_PUSH_URL_USER_GROUP, PROACTIVE_NOTIFICATIONS_USER_GROUP} from '../constants/userGroups';

export interface IExperienceDetailProps {
    dispatch: any;

    experience?: IFlattenedExperience;
    permissions: string[];
    changeControlDecision?: string;

    // from edit
    isEditing: boolean;
    isUpdating: boolean;
    updateError?: Error;
    id?: string;
    updateCandidate?: ExperienceUpdateCandidate;
    shouldDisableSave: boolean;
}

export interface IExperienceDetailState {
    cancelExperienceModalVisible: boolean;

    deleteExperienceModalVisible: boolean;
    deleteExperienceConfirmationString?: string;

    saveExperienceModalVisible: boolean;
    activeTabId?: string;

    sendForApprovalModalVisible: boolean;
    skipApprovalModalVisible: boolean;
    multiCloneModalVisible: boolean;
    bypassDraftCheck: boolean;
}

const BLOCKED_DECISION: string = 'BLOCKED_RULES_VIOLATED';
class ExperienceDetail extends Component<IExperienceDetailProps, IExperienceDetailState> {
    constructor(props: IExperienceDetailProps) {
        super(props);

        this.state = {
            cancelExperienceModalVisible: false,
            deleteExperienceModalVisible: false,
            saveExperienceModalVisible: false,
            sendForApprovalModalVisible: false,
            skipApprovalModalVisible: false,
            multiCloneModalVisible: false,
            bypassDraftCheck: false
        };
    }

    private getGlobalIntentMetrics() {
        const { dispatch, experience } = this.props;

        if (!experience) {
            return;
        }

        const experienceIds = ['ALL-' + (experience.marketplace ? experience.marketplace : 'US')];
        const metricFilter = ([CifMetricDDBNames.InterruptionRate, CifMetricDDBNames.NegativeFeedback, CifMetricDDBNames.NumberOfCampaigns, CifMetricDDBNames.AvgImpressionPerCampaign]);

        dispatch(getCXWeeklyMetricsAction(experienceIds, 'CIF', ExperienceMetricFrequency.Week, 4, metricFilter));
    }

    public componentDidMount() {
        const { experience, dispatch } = this.props;

        if (!_.isEmpty(experience?.operatorGroup)) {
            dispatch(getPermissionAction(experience?.operatorGroup));
        } else {
            dispatch(getPermissionAction());
        }

        if (experience && experience.type === 'DeviceNotification') {
            dispatch(getPermissionAction(PROACTIVE_NOTIFICATIONS_USER_GROUP));
        }

        if (experience && experience.type === 'AppNotification') {
            dispatch(getPermissionAction(MOBILE_PUSH_URL_USER_GROUP));
        }

        if (experience) {
            dispatch(getEventTrailAction(experience.id));
        }

        if (experience && experience.region
            && experience.enabledFilter && experience.enabledFilter.bullseye) {
            dispatch(getBullseyeAction(experience.region, experience.enabledFilter.bullseye));
        }

        if (experience && experience.region && isLiveExperience(experience.status)) {
            dispatch(getChangeControlDecisionAction(new Date().toISOString(), experience.region));
        }

        this.getGlobalIntentMetrics();
    }

    public componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(cancelEditingAction());
    }

    private editButtonClickHandler() {
        const { dispatch, experience } = this.props;

        if (experience) {
            dispatch(enableEditingAction(experience));
        }
    }

    private cloneButtonClickHandler() {
        const { experience } = this.props;

        if (experience) {
            window.location.assign(getLinkableUrl(PAGE.NewExperience) + '/' + experience.id);
        }
    }

    private cancelButtonClickHandler () {
        const { dispatch, experience } = this.props;
        dispatch(cancelEditingAction());
        if (experience && experience.region) {
            dispatch(getChangeControlDecisionAction(new Date().toISOString(), experience.region));
        }
    }

    private saveButtonClickHandler() {
        const { dispatch, experience, updateCandidate } = this.props;

        if (experience && experience.status !== 'PAUSED' && !enforceStatusTransition(experience, updateCandidate)) {
            dispatch(saveExperienceAction());
        } else if (experience && enforceStatusTransition(experience, updateCandidate) && isLiveExperience(experience.status) && !this.state.bypassDraftCheck) {
            this.setState({ saveExperienceModalVisible: true });
        } else {
            dispatch(saveExperienceAction());
        }
    }

    private saveKickBackToDraft() {
        const { dispatch, updateCandidate } = this.props;
        updateCandidate?.setStatus('DRAFT');
        dispatch(saveExperienceAction());
        this.setState({ saveExperienceModalVisible: false });
    }

    private toggleCancelModalVisibility() {
        const { cancelExperienceModalVisible } = this.state;
        this.setState({ cancelExperienceModalVisible: !cancelExperienceModalVisible });
    }

    private toggleDeleteModalVisibility() {
        const { deleteExperienceModalVisible } = this.state;
        this.setState({ deleteExperienceModalVisible: !deleteExperienceModalVisible });
    }

    private toggleSaveModalVisibility() {
        const { saveExperienceModalVisible } = this.state;
        this.setState({ saveExperienceModalVisible: !saveExperienceModalVisible });
    }

    private toggleSendForApprovalModalVisibility() {
        const { sendForApprovalModalVisible } = this.state;
        this.setState({ sendForApprovalModalVisible: !sendForApprovalModalVisible });
    }

    private toggleSkipApprovalModalVisibility() {
        const { skipApprovalModalVisible } = this.state;
        this.setState({ skipApprovalModalVisible: !skipApprovalModalVisible });
    }

    private toggleMultiCloneModalVisibility() {
        const { multiCloneModalVisible } = this.state;
        this.setState({ multiCloneModalVisible: !multiCloneModalVisible });
    }

    private bypassDraftClickHandler(bypass: boolean) {
        this.setState({bypassDraftCheck: bypass});
    }

    private isSetupNotificationExperience(experience: IFlattenedExperience) {
        if (experience.type === 'CIF') {
            return false;
        }
        return isLiveExperience(experience.status) || experience.status === 'SCHEDULED' || experience.status === 'SCHEDULING' || experience.status === 'EXPIRED';
    }

    private shouldDisableEdit_BlockDay(experience: IFlattenedExperience) {
        const { changeControlDecision } = this.props;

        return isLiveExperience(experience.status) && BLOCKED_DECISION === changeControlDecision && getRole(experience.permissionRole) !== PermissionRole.ADMIN;
    }

    public render() {
        const { dispatch, experience, isEditing, updateError, id, updateCandidate, permissions, shouldDisableSave} = this.props;

        const { sendForApprovalModalVisible, cancelExperienceModalVisible, deleteExperienceModalVisible,
            skipApprovalModalVisible, multiCloneModalVisible } = this.state;

        if (!experience) {
            return null;
        }

        const isCanceled = experience.status === 'CANCELED';
        const isViewer = getRole(experience.permissionRole) === 'VIEWER';
        const eligibleForBypass = !['DRAFT', 'TESTABLE', 'UNDER_REVIEW'].includes(experience.status);
        const requiresEmailApproval = experience.referralContent?.actionType === ActionType.EMAIL;

        const typeIconPath = getTypeIconPath(experience.type);
        const isAdmin: boolean = (permissions.includes('ADMIN'));

        const isPostPaused = experience.weblab.launchTreatmentPercentage === undefined
            ? false
            : experience.weblab.launchTreatmentPercentage === 0;

        const isHintAndCapabilityEmpty = _.isEmpty(experience.capabilityId) && _.isEmpty(experience.hintId);

        const switchToPromotedCapability = () => {
            let saveOnConfirm = false;

            if (!isEditing && experience) {
                saveOnConfirm = true;
                dispatch(enableEditingAction(experience));
            }
            dispatch(toggleCapabilitySearchModalVisibility(saveOnConfirm));
        };

        return <div>
            {this.isSetupNotificationExperience(experience) && getRole(experience.permissionRole) !== 'ADMIN' && <Flashbar items={[{
                content: 'Reach out to the Odyssey team if you want to edit this experience',
                type: 'info',
                dismissible: true
            }]}/>}
            {updateError && <Flashbar items={[{
                header: 'Save failed',
                // TODO: get rid of the ternary operator once Lambda passes back the real error message
                content: updateError.message !== 'Network Error' ? updateError.message : 'Failed to update',
                type: 'error',
                dismissible: true
            }]}/>}
            {id && <Flashbar items={[{
                header: 'Success',
                // TODO: get rid of the ternary operator once Lambda passes back the real error message
                content: `Experience ${id} has been updated`,
                type: 'success',
                dismissible: true
            }]}/>}
            {isEditing && (experience.status === 'DRAFT' || experience.status === 'TESTABLE') && <Flashbar items={[{
                content: <div>
                    <p>You will be required to set a Locale for this experience before making changes to it.</p>
                    <p>Once you have configured all required information and save your draft,
                    your experience will be moved into TESTABLE state.
                    At this point, you can enter a Testing Account (under the Testing tab)
                    and preview your experience on a live device.</p>
                    <p>After you have completed setup and tested your configuration,
                        you may submit this experience for approval.</p>
                </div>,
                type: 'info'
            }]}/>}

            <Alert visible={isHintAndCapabilityEmpty} type='warning'>
                This experience does not have a CapabilityID associated with it. A CapabilityID is required for this experience to be Testable.
                <span className='awsui-util-f-r'><Button className='awsui-alert-action-button' text='Associate a CapabilityID' variant='primary' onClick={switchToPromotedCapability} /></span>
            </Alert>

            <CapabilitySearchModal />
            <SendForApprovalModal isVisible={sendForApprovalModalVisible} requiresEmailApproval={requiresEmailApproval}
                hideModal={() => this.toggleSendForApprovalModalVisibility()} isPostPaused={isPostPaused} />
            <CancelExperienceModal isVisible={cancelExperienceModalVisible} hideModal={() => this.toggleCancelModalVisibility()} />
            <DeleteExperienceModal isVisible={deleteExperienceModalVisible} hideModal={() => this.toggleDeleteModalVisibility()} />
            <SkipApprovalModal isVisible={skipApprovalModalVisible} isException={false} hideModal={() => this.toggleSkipApprovalModalVisibility()} />
            <MultiCloneModal isVisible={multiCloneModalVisible} hideModal={() => this.toggleMultiCloneModalVisibility()} />

            <Modal
                id='modal.save-experience'
                visible={this.state.saveExperienceModalVisible}
                header='Save Experience'
                onDismiss={this.toggleSaveModalVisibility.bind(this)}
                footer={<span className='awsui-util-f-r'>
                    <Button
                        id='button.kick-back-live-to-draft'
                        variant='primary'
                        icon='undo'
                        onClick={this.saveKickBackToDraft.bind(this)}>
                        Kick back to DRAFT
                    </Button>
                </span>}
            >
                <StatusBadge status={this.props.experience?.status || 'LIVE'} />&nbsp;&nbsp;&nbsp;&nbsp;
                <Icon name='angle-right-double' variant='subtle' />&nbsp;&nbsp;&nbsp;&nbsp;
                <StatusBadge status='DRAFT' />
                <p>
                    You are about to update a {StatusBadgeMap[this.props.experience?.status || 'LIVE'].beautifiedName} experience.
                    This will put the {StatusBadgeMap[this.props.experience?.status || 'LIVE'].beautifiedName} experience back to
                    DRAFT.
                </p>
                <p className='awsui-text-secondary'>
                    This prevents any disruptive changes
                    to go LIVE immediately without approval.
                </p>
            </Modal>

            <div className='awsui-grid'>
                <div className='awsui-util-mb-m' >
                    {(experience.status === 'UNDER_REVIEW' && experience.approvalId) &&
                        (experience.approvalInfo?.simDocumentId
                            ?  <Alert id='alert.pending-approval' dismissible={false} type='info' header={'Pending ' + (isPostPaused ? 'Re-Approval' : 'Approval')}>
                                Your experience is waiting for {isPostPaused ? 're-' : ''}approval - <LinkedApproval approvalId={experience.approvalId}/>,
                                SIM ticket is <LinkedSimTicket simDocumentId={experience.approvalInfo?.simDocumentId} />
                            </Alert>
                            : <Alert id='alert.pending-approval' dismissible={false} type='info' header={'Pending ' + (isPostPaused ? 'Re-Approval' : 'Approval')}>
                                Your experience is waiting for {isPostPaused ? 're-' : ''}approval: Approval ID is <LinkedApproval approvalId={experience.approvalId} />&nbsp;
                                Make sure to cut a <ExternalLink
                                href={`https://sim.amazon.com/issues/create?template=${APPROVAL_SIM_TEMPLATE_IDS[experience.type]}`}>sim intake</ExternalLink> to get your experience approved
                            </Alert>
                        )
                    }
                    {(experience.status === 'CANCELED' && experience.cancellationReason) &&
                        <Alert type='info' header='Cancellation Reason' dismissible={false} >
                            {experience.cancellationReason}
                        </Alert>
                    }
                    {(experience.status === 'PAUSED' && experience.pausedReason) &&
                        <Alert type='info' header='Pause Reason' dismissible={false} id='alert.paused-reason'>
                            {experience.pausedReason}
                        </Alert>
                    }
                    {(!isNullOrUndefined(experience.launchTreatmentPercentage) && experience.status !== 'UNDER_REVIEW' && experience.approvalId) &&
                        (experience.approvalInfo?.simDocumentId
                            ?  <Alert dismissible={false} type='info' header='Pending Approval'>
                                Your experience is waiting for exceptional approval - <LinkedApproval approvalId={experience.approvalId}/>, SIM ticket is <LinkedSimTicket
                                simDocumentId={experience.approvalInfo?.simDocumentId} />
                            </Alert>
                            : <Alert dismissible={false} type='info' header='Pending Approval'>
                                Your experience is waiting for exceptional approval: Approval ID is <LinkedApproval
                                    approvalId={experience.approvalId} />&nbsp;
                                Make sure to cut a <ExternalLink
                                href={`https://sim.amazon.com/issues/create?template=${APPROVAL_SIM_TEMPLATE_IDS[experience.type]}`}>sim intake</ExternalLink> to get your experience approved
                            </Alert>
                        )
                    }
                </div>
                <div className='awsui-util-mb-m' >
                    {(experience.status === 'UNDER_REVIEW' && experience.type === 'CIF' && experience.visualContent && experience.visualContent !== null) &&
                            <Alert id='alert.pending-vcif-approval' dismissible={false} type='info'>
                                Please attach screenshots of all VCIF cards being rendered on your test Multimodal device to the <ExternalLink
                                href={`https://issues.amazon.com/issues/${experience.approvalInfo?.simDocumentId}`}>SIM</ExternalLink>. This is required for campaign approval.
                            </Alert>
                    }
                </div>
                <h1 id='experience-title'>
                    <img src={typeIconPath} alt='' style={{height:'35px',width:'35px',verticalAlign:'bottom'}} className='awsui-util-mr-xs' />{experience.title}
                </h1>
                <ExperienceDetailGlobalView
                    experience={experience}
                    isEditing={isEditing}
                    updateCandidate={updateCandidate} />
                <ExposureControlView/>
                <div className='awsui-row'>
                    <div className='col-8'>
                        {isEditing && getRole(experience.permissionRole) === 'ADMIN' && eligibleForBypass &&
                        <div className='awsui-row' style={{ marginBottom: '20px' }} >
                            <div className='col-4'>
                                <Checkbox
                                    id='control.bypass-draft'
                                    controlId='control.bypass-draft'
                                    onChange={e => this.bypassDraftClickHandler(e.detail.checked)}>
                                    Bypass Draft (ADMIN ONLY)
                                </Checkbox>
                            </div>
                        </div>}
                    </div>
                    <div className='col-4'>
                        <span className='awsui-util-f-r'>
                            {/* valid feature but wait until edit view is ready */}
                            {isEditing
                                ? <>
                                    <Button
                                        id='button.cancel-editing'
                                        icon='undo'
                                        onClick={this.cancelButtonClickHandler.bind(this)}
                                    >Cancel</Button>
                                    <Button
                                        id='button.save-experience'
                                        disabled={shouldDisableSave}
                                        icon='upload'
                                        variant='primary'
                                        onClick={this.saveButtonClickHandler.bind(this)}
                                        >Save</Button>
                                </>
                                : <>
                                    <Button
                                        id='button.clone-experience'
                                        icon='copy'
                                        onClick={this.toggleMultiCloneModalVisibility.bind(this)}>
                                        Clone
                                    </Button>
                                    {<Button
                                        icon='edit'
                                        id='button.edit-experience'
                                        disabled={isCanceled || isViewer || this.shouldDisableEdit_BlockDay(experience)
                                            || (this.isSetupNotificationExperience(experience) && getRole(experience.permissionRole) !== PermissionRole.ADMIN)}
                                        onClick={this.editButtonClickHandler.bind(this)}>Edit</Button>}
                                    {(getRole(experience.permissionRole) === 'ADMIN'
                                        && experience.status === 'CANCELED')
                                        && <Button
                                            icon='lock-private'
                                            id='button.delete-experience'
                                            variant='primary'
                                            onClick={this.toggleDeleteModalVisibility.bind(this)}
                                        >Delete Experience</Button>}
                                </>}
                        </span>
                    </div>
                </div>
                {generateTabsView(experience, isAdmin, this, permissions)}
                <div className='awsui-row'>
                    <div className='col-6'>
                        {!isEditing && (experience.status !== 'CANCELED' && experience.status !== 'UNDER_REVIEW') &&
                            <Button
                                id='button.cancel-experience'
                                onClick={this.toggleCancelModalVisibility.bind(this)}
                                disabled={isViewer}
                            >Cancel Experience</Button>}
                    </div>
                    <div className='col-6 awsui-util-t-r'>
                        <div className='awsui-util-f-r'>
                        {!isEditing && experience.status === 'TESTABLE' && experience.type === 'CIF' && getRole(experience.permissionRole) === 'ADMIN' &&
                            <span className='awsui-util-f-l awsui-util-pr-m'>
                                <Button
                                    icon='status-warning'
                                    id='button.skip-approval'
                                    variant='primary'
                                    onClick={this.toggleSkipApprovalModalVisibility.bind(this)}
                                    disabled={isViewer}
                                >Skip Approval (ADMIN ONLY)</Button>
                            </span>}
                        {!isEditing && experience.status === 'TESTABLE' &&
                            <span className='awsui-util-f-l'>
                                <Button
                                    icon='envelope'
                                    id='button.send-for-approval'
                                    variant='primary'
                                    onClick={this.toggleSendForApprovalModalVisibility.bind(this)}
                                    disabled={isViewer}
                                >Send for Approval</Button>
                            </span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

const generateTabsView = (experience: IFlattenedExperience, isAdmin: boolean, component: Component<IExperienceDetailProps, IExperienceDetailState>, permissions: string[]) => {

    if (experience.type === 'CIF') {
        if (experience.visualContent && experience.visualContent !== null) {
            return <VisualCifExperienceTabsView
                experience={experience}
                activeTabId={component.state.activeTabId || 'visual-cif-content'}
                onTabChange={(d) => {
                    component.setState(prevState => {
                        return {...prevState, activeTabId: d.activeTabId};
                    });
            }} />;
        }

        return <CifExperienceTabsView
            experience={experience}
            isAdmin={isAdmin}
            activeTabId={component.state.activeTabId || 'cif-injection'}
            onTabChange={(d) => {
                component.setState(prevState => {
                    return {...prevState, activeTabId: d.activeTabId};
                });
        }} />;
    } else if (experience.type === 'AppNotification' || experience.type === 'DeviceNotification') {
        return <NotificationExperienceTabsView
            experience={experience}
            isAdmin={isAdmin}
            activeTabId={component.state.activeTabId || 'notification-content'}
            onTabChange={(d) => {
                component.setState(prevState => {
                    return {...prevState, activeTabId: d.activeTabId};
                });
        }} />;
    }
};

const mapStateToProps = ({ experienceDetailViewState, authenticationState, changeControlDecisionState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isLoading has default value set in reducer, so no default
        // value needed
        experience: experienceDetailViewState.experience || undefined,
        isEditing: experienceEditViewState.isEditing,
        updateError: experienceEditViewState.error || undefined,
        id: experienceEditViewState.id || undefined,
        permissions: authenticationState.permissions || [],
        changeControlDecision: changeControlDecisionState.message || undefined,
        isUpdating: experienceEditViewState.isUpdating,
        updateCandidate: experienceEditViewState.updateCandidate,
        shouldDisableSave: experienceEditViewState.shouldDisableSave
    };
};

export default connect(mapStateToProps)(ExperienceDetail);
