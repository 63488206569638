import moment from 'moment';
import { ExperienceType } from '../../Experience';


export enum RcpMetricNames {
    Impression = 'Impressions',
    Voice = 'Voice Actions',
    Touch = 'Touch Actions',
    Total = 'Total Actions',
    VoiceRate = 'Voice Action Rate',
    TouchRate = 'Touch Action Rate',
    TotalRate = 'Total Action Rate'
}

export enum RcpMetricDDBNames {
    Impression = 'impression',
    Voice = 'voice',
    Touch = 'touch'
}

export enum RCPMetricsTimeRange {
    days = 'days',
    weeks = 'weeks'
}

export enum OdysseyExperienceMetricFrequency {
    Day = 'DAY',
    Week = 'WEEK'
}

export const monthName = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];


export interface IRcpMetricsTypes {
    impression?: number;
    voice?: number;
    touch?: number;
}

export interface IRcpData {
    period: string;
    reportedAt: number;
    weekStart?: string;
    weekEnd?: string;
    data: IRcpMetricsTypes;
}

export interface IRawRcpMetricsData {
    experienceId: string;
    experienceType: ExperienceType;
    periodType: OdysseyExperienceMetricFrequency;
    metrics: IRcpData[];
}

export interface IRcpMetric {
    metricName: RcpMetricNames;
    values: (number | undefined)[];
}

// helper function to create headers for days with missing data - starting with the most recent available day, it constructs a header if the previous day is unavailable
export const constructDailyMetricsForTableView = (rcpMetrics: IRawRcpMetricsData[]) : IRawRcpMetricsData[] => {

    const RCP_METRICS_PERIODS_INDEX_RANGE = 6;

    if (rcpMetrics[0]) {
        const constructedDailyMetrics = JSON.parse(JSON.stringify(rcpMetrics));
        for (let dailyMetricsDataIterator = 0; dailyMetricsDataIterator < RCP_METRICS_PERIODS_INDEX_RANGE; dailyMetricsDataIterator++){
            const currentDailyMetricsPeriod = moment(constructedDailyMetrics[0].metrics[dailyMetricsDataIterator].period);
            const indexOfNextPeriod = rcpMetrics[0].metrics.findIndex(metric => ((metric.period === (moment(currentDailyMetricsPeriod).subtract(1, 'days').format('YYYY-MM-DD')))));
            if (indexOfNextPeriod === -1) {
                const constructedDailyMetricsForMissingPeriod = {
                    period: moment(currentDailyMetricsPeriod).subtract(1, 'days').format('YYYY-MM-DD'),
                    reportedAt: moment().format('MM/DD/YYYY hh:mm A z'),
                    data: {}
                };
                constructedDailyMetrics[0].metrics[dailyMetricsDataIterator + 1] = constructedDailyMetricsForMissingPeriod;
            } else {
                constructedDailyMetrics[0].metrics[dailyMetricsDataIterator + 1] = rcpMetrics[0].metrics[indexOfNextPeriod];
            }
        }
        return constructedDailyMetrics;
    }
    return [];
};

// helper function to create headers for weeks with missing data - starting with the most recent available week, it constructs a header if the previous week is unavailable
// Finds whether the current week's previous week number has associated data
export const constructWeeklyMetricsForTableView = (rcpMetrics: IRawRcpMetricsData[]) : IRawRcpMetricsData[] => {

    const RCP_METRICS_PERIODS_INDEX_RANGE = 6;

    if (rcpMetrics[0]) {
        const constructedWeeklyMetrics = JSON.parse(JSON.stringify(rcpMetrics));

        for (let weeklyMetricsDataIterator = 0;  weeklyMetricsDataIterator < RCP_METRICS_PERIODS_INDEX_RANGE; weeklyMetricsDataIterator++) {
            const { weekStart, weekEnd } = constructedWeeklyMetrics[0].metrics[weeklyMetricsDataIterator];
            const indexOfNextPeriod = rcpMetrics[0].metrics.findIndex(metric => ((moment(metric.weekEnd).isoWeek() === (moment(weekStart).isoWeek()))));
            if (indexOfNextPeriod === -1) {
                const weekPrefixString = (moment(weekStart).isoWeek() <= 10) ? 'W0' : 'W';
                const constructedWeeklyMetricsForMissingPeriod = {
                    period: moment(weekStart).subtract(7, 'days').year() + '-' + weekPrefixString + moment(weekStart).isoWeek(),
                    reportedAt: moment().format('MM/DD/YYYY hh:mm A z'),
                    weekStart: moment(weekStart).subtract(7, 'days').format('YYYY-MM-DD'),
                    weekEnd: moment(weekEnd).subtract(7, 'days').format('YYYY-MM-DD'),
                    data: {}
                };
                constructedWeeklyMetrics[0].metrics[weeklyMetricsDataIterator + 1] = constructedWeeklyMetricsForMissingPeriod;
            } else {
                constructedWeeklyMetrics[0].metrics[weeklyMetricsDataIterator + 1] = rcpMetrics[0].metrics[indexOfNextPeriod];
            }
        }
        return constructedWeeklyMetrics;
    }
    return [];
};

export const createMappedRcpMetrics = (rcpMetrics: IRawRcpMetricsData[]): IRcpMetric[] => {
    const metricNames = [RcpMetricNames.Impression, RcpMetricNames.Total, RcpMetricNames.Voice, RcpMetricNames.Touch, RcpMetricNames.TotalRate, RcpMetricNames.VoiceRate, RcpMetricNames.TouchRate];
    const impression:(number | undefined)[] = [];
    const voice:(number | undefined)[] = [];
    const touch:(number | undefined)[] = [];
    const total:(number | undefined)[] = [];
    const voiceRate:(number | undefined)[] = [];
    const touchRate:(number | undefined)[] = [];
    const totalRate:(number | undefined)[] = [];

    if (rcpMetrics[0]) {
        const { metrics } = (rcpMetrics[0]);

        metrics.forEach((entry: IRcpData) =>
            impression.push(entry.data.impression)
            && voice.push(entry.data.voice)
            && touch.push(entry.data.touch)
            && total.push((entry.data.voice === undefined && entry.data.touch === undefined) ? undefined : (entry.data.voice || 0) + (entry.data.touch || 0))
            && voiceRate.push((entry.data.impression || 0) !== 0 ? ((entry.data.voice || 0) / (entry.data.impression || 0)) : undefined)
            && touchRate.push((entry.data.impression || 0) !== 0 ? ((entry.data.touch || 0) / (entry.data.impression || 0)) : undefined)
            && totalRate.push((entry.data.impression || 0) !== 0 ? (((entry.data.voice || 0) + (entry.data.touch || 0)) / (entry.data.impression || 0)) : undefined)
        );
    }
    const metricValues = [impression, total, voice, touch, totalRate, voiceRate, touchRate];

    const mappedRcpMetrics = metricNames.map((metricName, index) => ({
        metricName,
        values: metricValues[index]
    }));

    return mappedRcpMetrics;
};
