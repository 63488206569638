import * as React from 'react';
import { FormField, ColumnLayout, Select } from '@amzn/awsui-components-react';
import { OptionalField, RequiredField } from './DescriptionAnnotations';
import { LinkedMediaCenterWiki } from './LinksRenderHelper';
import { IMobileHomeCardContent } from '../../models/mobileHomeCard/MobileHomeCardContent';
import { DiscoveryTitleCharLimit, DiscoverySubtitleCharLimit, DiscoveryLabelTextCharLimit, BackgroundColorOption } from '../../constants/mobileHomeCardRelatedConstant';
import { InputWrapper } from './InputWrapper';
import { CommonValidator } from '../../util/CommonValidator';
import { TextRenderDirection } from '../../models/htmlElementProps';
import { isNullOrUndefined } from 'util';
import { CharLimit } from '../../containers/sections/MobileHomeCardDisplayElementsSectionView';

interface MobileHomeCardDiscoveryElementComponentProps {
    mobileHomeCardContent?: IMobileHomeCardContent;
    textDirection: TextRenderDirection;
    shouldDisableInput: boolean;
    updateMobileHomeCardContent: (mobileHomeCardContent?: IMobileHomeCardContent) => void;
    onCardActionAndCharLimitCheckedUpdate?: (cardActionChecked?: boolean, charLimitChecked?: boolean) => void;
    disableNextButton: (characterLimit: CharLimit, cardActionConfirmation?: boolean, onCardActionAndCharLimitCheckedUpdate?: (cardActionChecked?: boolean, charLimitChecked?: boolean) => void) => void;
    isEditing: boolean;
}

export const MobileHomeCardDiscoveryElementComponent = (props: MobileHomeCardDiscoveryElementComponentProps) => {
    const { mobileHomeCardContent, textDirection, shouldDisableInput, updateMobileHomeCardContent, isEditing } = props;

    return <div>
        <img src='/images/DiscoveryTemplate.jpg' alt='Conversation Icon' />
        <div className='awsui-row'>
            <div className='col-10'>
                <ColumnLayout columns={2}>
                    <div data-awsui-column-layout-root='true' style={{ marginBottom: '10px' }}>
                        <FormField
                            label={<RequiredField fieldName='Title' />}
                            description='30 characters or less recommended. Longer text will wrap to 2 lines and then truncate'>
                            <InputWrapper
                                id='experience.cardTitle'
                                placeholder='e.g. Type with Alexa'
                                readonly={shouldDisableInput}
                                textRenderDirection={textDirection}
                                value={mobileHomeCardContent?.cardTitle}
                                characterLimit={DiscoveryTitleCharLimit}
                                onInput={(input: string) => {
                                    updateMobileHomeCardContent({ ...mobileHomeCardContent, cardTitle: input });
                                }}
                                validate={(value) => { return CommonValidator.isStringLengthExceeded(DiscoveryTitleCharLimit, value); }}
                            />
                        </FormField>

                        <FormField
                            label={<OptionalField fieldName='Subtitle' />}
                            description='30 characters or less recommended. Longer text will truncate'>
                            <InputWrapper
                                id='experience.discoverySubtitle'
                                placeholder='e.g. Now you can speak ...'
                                readonly={shouldDisableInput}
                                textRenderDirection={textDirection}
                                value={mobileHomeCardContent?.cardSubtitle}
                                characterLimit={DiscoverySubtitleCharLimit}
                                onInput={(input: string) => {
                                    updateMobileHomeCardContent({ ...mobileHomeCardContent, cardSubtitle: input });
                                }}
                                validate={(value) => { return CommonValidator.isStringLengthExceeded(DiscoverySubtitleCharLimit, value); }}
                            />
                        </FormField>

                        <FormField
                            label={<RequiredField fieldName='Image URL' />}
                            description={<span>Upload your image to <LinkedMediaCenterWiki />&nbsp;and copy the URL. 116×64px recommended.</span>}>
                            <InputWrapper
                                id='experience.imageUrl'
                                placeholder='https://m.media-amazon.com/images/G/01/AlexaSkills/2022/LandingPages/SoundDetection/App_invitation_routine_1025x768.png'
                                readonly={shouldDisableInput}
                                value={mobileHomeCardContent?.cardImageUrl}
                                onChange={(input: string) => {
                                    updateMobileHomeCardContent({ ...mobileHomeCardContent, cardImageUrl: input.trim() });
                                }}
                                validate={(value) => { return CommonValidator.isImageUrlValid(value); }}
                            />
                        </FormField>

                        <FormField
                            label={<OptionalField fieldName='Icon URL' />}
                            description={<span>Upload your image to <LinkedMediaCenterWiki />&nbsp;and copy the URL. 24x24px - 32x32px recommended.
                                If no icon used, then Title and Subtitle floats left.</span>}>
                            <InputWrapper
                                id='experience.iconUrl'
                                placeholder='https://m.media-amazon.com/images/G/01/AlexaSkills/2022/LandingPages/SoundDetection/App_invitation_routine_1025x768.png'
                                readonly={shouldDisableInput}
                                value={mobileHomeCardContent?.leftIconUrl}
                                onChange={(input: string) => {
                                    updateMobileHomeCardContent({ ...mobileHomeCardContent, leftIconUrl: input.trim() });
                                }}
                                validate={(value) => { return CommonValidator.isImageUrlValid(value); }}
                            />
                        </FormField>

                        <FormField
                            label={<RequiredField fieldName='Background Color' />}
                            description={<span>Select color</span>}>
                            <Select
                                id='experience.cardBgColor'
                                controlId='experience.cardBgColor'
                                options={getBgColorOption(BackgroundColorOption, true, isEditing, mobileHomeCardContent?.cardBgColor)}
                                disabled={shouldDisableInput}
                                selectedId={mobileHomeCardContent?.cardBgColor}
                                onChange={e => {
                                    updateMobileHomeCardContent({ ...mobileHomeCardContent, cardBgColor: e.detail.selectedId });
                                }}
                            />
                        </FormField>

                        <FormField
                            label={isNullOrUndefined(mobileHomeCardContent?.labelText) ? <OptionalField fieldName='Label Background Color' /> :
                                <RequiredField fieldName='Label Background Color' />}
                            description={<span>Must be used with Label Text, otherwise there won't be a label</span>}>
                            <Select
                                id='experience.labelBgColor'
                                controlId='experience.labelBgColor'
                                options={getBgColorOption(BackgroundColorOption, false, isEditing, mobileHomeCardContent?.cardBgColor)}
                                disabled={shouldDisableInput || isNullOrUndefined(mobileHomeCardContent?.labelText)}
                                selectedId={mobileHomeCardContent?.labelBgColor}
                                onChange={e => {
                                    updateMobileHomeCardContent({ ...mobileHomeCardContent, labelBgColor: e.detail.selectedId });
                                }}
                            />
                        </FormField>

                        <FormField
                            label={<OptionalField fieldName='Label Text' />}
                            description={<span>Must be used with Label Background Color, otherwise there won't be a label. 20 characters or less recommended.</span>}>
                            <InputWrapper
                                id='experience.labelText'
                                placeholder='e.g. subscription'
                                readonly={shouldDisableInput}
                                textRenderDirection={textDirection}
                                value={mobileHomeCardContent?.labelText}
                                characterLimit={DiscoveryLabelTextCharLimit}
                                onInput={(input: string) => {
                                    updateMobileHomeCardContent({ ...mobileHomeCardContent, labelText: input });
                                }}
                                validate={(value) => { return CommonValidator.isStringLengthExceeded(DiscoveryLabelTextCharLimit, value); }}
                            />
                        </FormField>

                    </div>
                </ColumnLayout>
            </div>
        </div>
    </div>;
};


export const getBgColorOption = (bgColorOptions: Select.Option[], required = false, isEditing = false, currentBgColor: string | undefined): Select.Option[] => {
    const deprecatedBgColor = ['Cyan', 'Blue', 'Green', 'Yellow'];
    return bgColorOptions.filter(bgColor => {
        return (required && bgColor.label === 'None' ) ? false : true;
    }).filter(bgColor =>{
        // if isEditing is true, stop showing deprecatedBgColor, also compatible with exisiting expenience using deprecatedBgColor
        return (isEditing && deprecatedBgColor.includes(bgColor.label) && bgColor.id !== currentBgColor) ? false : true;
    });
};