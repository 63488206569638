import { axios } from './axios';
import { API_ENDPOINT, TestMobileHomeCardPath } from '../constants/lambdaEndpoints';

export const sendMobileHomeCard = async (
    cid: string,
    cardItem: string,
    experimentId: string,

): Promise<string> => {
    const searchParams = new URLSearchParams();

    searchParams.append('customerId', cid);
    searchParams.append('cardItem', cardItem);
    searchParams.append('experimentId', 'preview-' + experimentId);

    const response = await axios.post(`${API_ENDPOINT}${TestMobileHomeCardPath}?${searchParams.toString()}`);
    return String(response.data.status);
};
