import React from 'react';
import { Input } from '@amzn/awsui-components-react';
import { CharacterCount } from './CharacterCount';
import { TextRenderDirection } from '../../models/htmlElementProps';

export interface IInputWrapperProps {
    id: string;
    value?: string;
    readonly: boolean;
    placeholder?: string;
    characterLimit?: number;
    textRenderDirection?: TextRenderDirection;
    validate: (input?: string) => void;
    onInput?: (inputString: string) => void;
    onChange?: (inputString: string) => void;
}

export class InputWrapper extends React.Component<IInputWrapperProps> {
    render () {
        const {
            id, value, readonly, characterLimit, textRenderDirection,
            placeholder, validate, onInput, onChange
        } = this.props;

        let errorMessage = '';
        let isValid: boolean = true;

        if (!readonly) {
            try {
                validate(value);
                isValid = true;
            } catch (error) {
                isValid = false;
                errorMessage = 'Warning: ' + error.message;
            }
        }

        if (value && characterLimit) {
            isValid = isValid && (value.length <=  characterLimit);
        }

        const RightToLeftRenderedInput = <input
            dir='rtl'
            style={{ direction: 'rtl' }}
            id={id}
            readOnly={readonly}
            value={value}
            placeholder={placeholder}
            className={`awsui-input ${readonly ? 'awsui-input-readonly' : ''} awsui-input-${isValid ? '' : 'in'}valid awsui-input-type-text`}
            type='text'
            aria-describedby={id}
            onInput={(event: React.FormEvent<HTMLInputElement>) => {
                if (onInput) {
                    onInput((event.target as HTMLInputElement).value);
                }
            }}
            onChange={(event: React.FormEvent<HTMLInputElement>) => {
                if (onChange) {
                    onChange((event.target as HTMLInputElement).value);
                }
            }}
        />;

        const LeftToRightRenderedInput = <Input
            controlId={id}
            id={id}
            readonly={readonly}
            value={value}
            placeholder={placeholder}
            invalid={!isValid}
            onInput={(event: CustomEvent<Input.ChangeDetail>) => {
                if (onInput) {
                    onInput(event.detail.value);
                }
            }}
            onChange={(event: CustomEvent<Input.ChangeDetail>) => {
                if (onChange) {
                    onChange(event.detail.value);
                }
            }} />;

        return <div>
            {textRenderDirection === 'rtl' ? RightToLeftRenderedInput : LeftToRightRenderedInput}

            <div className='awsui-grid'>
                <div className='awsui-row'>
                    <div className={characterLimit ? 'col-6' : 'col-12'}>
                        {errorMessage && <p className='awsui-util-status-negative'>{errorMessage}</p>}
                    </div>
                    <div className={errorMessage ? 'col-6' : 'col-12'}>
                        {characterLimit && <div className='awsui-util-t-r'>
                            <CharacterCount characters={value? value.length : 0} limit={characterLimit} />
                        </div>}
                    </div>
                </div>
            </div>
        </div>;
    }
}