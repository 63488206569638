import * as React from 'react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ColumnLayout } from '@amzn/awsui-components-react';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { connect } from 'react-redux';
import { AppState } from '../../reducers/index';
import { KeyValuePairView } from '../../components/common/GridKeyValuePair';
import { LinkedCapabilityId } from './LinksRenderHelper';


interface IPromotedCapabilityProps {
    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

export class PromotedCapability extends React.Component<IPromotedCapabilityProps> {
    render() {
        const { experience, updateCandidate, isEditing } = this.props;

        let capabilityId;

        if (experience?.type === 'RotatingContentPanel') {
            capabilityId = isEditing ? updateCandidate?.getRotatingContentPanelContent()?.contentId : (experience.contents ? experience.contents[0].contentId : '-');
        } else {
            capabilityId = isEditing ? updateCandidate?.getPromotedCapability() : experience?.capabilityId;
        }

        const locale = experience?.locale || 'en_US';

        return (
            <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root='true'>
                    <KeyValuePairView k={'Capability ID'} v={
                        <LinkedCapabilityId capabilityId={capabilityId} locale={locale} />
                    }></KeyValuePairView>
                </div>
            </ColumnLayout>
        );
    }
}

const mapStateToProps = ({ experienceDetailViewState, experienceEditViewState, odysseyExperienceDetailViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: experienceDetailViewState.experience || odysseyExperienceDetailViewState.experience,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(PromotedCapability);
