import * as React from 'react';
import { Button, Modal, Select, ColumnLayout, FormField, Alert } from '@amzn/awsui-components-react';
import { AppState } from '../../reducers';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import {
    buildCloneExperience, IMultiCloneExperienceMetadata, isDisplayContentValidForMultiClone, isNotificationTitleValidForMultiClone,
    isSpokenContentValidForMultiClone, isTitleValidForMultiClone, isUtteranceValidForMultiClone
} from '../../util/MultiCloneUtils';
import { connect } from 'react-redux';
import { createMultiCloneExperienceAction, updateMultiCloneExperiences } from '../../actions/experienceDetailViewActions';
import { FormSectionView } from '../common/FormSectionView';
import { RequiredField } from '../common/DescriptionAnnotations';
import { toSelectOptions } from '../../util/stringAndMappingHelper';
import { isRightToLeftLocale, LOCALE_TO_MARKETPLACE } from '../../constants/locales';
import { MARKETPLACE_TO_REGION } from '../../constants/marketplaces';
import { TextAreaWrapper } from '../common/TextAreaWrapper';
import { contentCharLimit, notificationContentLimit, notificationHeaderLimit, referralContentCharLimit, spokenContentLimit } from '../../constants/componentConstants';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { ExperienceType } from '../../models/Experience';
import { LinkedPermissionGroup } from '../common/LinksRenderHelper';
import { createMultiCloneOdysseyExperienceAction } from '../../actions/v2/odysseyExperienceDetailViewActions';

interface MultiCloneModalProps {
    dispatch: any;
    experience?: IFlattenedExperience;
    multiCloneExperiences: IMultiCloneExperienceMetadata[];
    isVisible: boolean;
    hideModal: () => void;
}

interface IMultiCloneModalState {
    currentCloneIndex: number;
    [key: string]: any;
}

class MultiCloneModal extends React.Component<MultiCloneModalProps, IMultiCloneModalState> {

    private static INITIAL_MODAL_STATE: IMultiCloneModalState = {
        currentCloneIndex: 0
    };

    constructor(props: MultiCloneModalProps) {
        super(props);
        this.state = MultiCloneModal.INITIAL_MODAL_STATE;
    }

    updateState(object: { [key: string]: any }) {
        this.setState(object);
    }

    componentDidUpdate(prevProps: MultiCloneModalProps) {
        if (this.props.isVisible === true && prevProps.isVisible === false) {
            this.setState(MultiCloneModal.INITIAL_MODAL_STATE);
        }
    }

    private updateMultiCloneExperience(multiCloneExperiences: IMultiCloneExperienceMetadata[]) {
        const { dispatch } = this.props;
        dispatch(updateMultiCloneExperiences(multiCloneExperiences));
        this.setState(prevState => {
            return {
                ...prevState
            };
        });
    }

    private validateModalCompletion(type: ExperienceType, isReferralContent: boolean): boolean {
        const { multiCloneExperiences } = this.props;
        for (const exp of multiCloneExperiences) {
            try {
                isTitleValidForMultiClone(exp.title);
                if ((type === 'AppNotification' || type === 'DeviceNotification') && exp.notificationContent) {
                    isNotificationTitleValidForMultiClone(exp.notificationContent?.title);
                    isDisplayContentValidForMultiClone(exp.notificationContent?.displayContent);
                    if (type === 'DeviceNotification') {
                        isSpokenContentValidForMultiClone(exp.notificationContent?.spokenContent);
                    }
                }
            } catch (error) {
                return false;
            }
        }
        return true;
    }

    private createCloneButtonHandler() {
        const { dispatch, experience } = this.props;

        if (experience?.type === 'MobileHomeCard') {
            dispatch(createMultiCloneOdysseyExperienceAction());
            return;
        }

        if (experience) {
            dispatch(createMultiCloneExperienceAction());
        }

    }

    private updateCurrentCloneIndex(index: number) {
        this.setState(prevState => {
            return {
                ...prevState,
                currentCloneIndex: index
            };
        });
    }

    private shouldRenderBullseyeWarning(experience: IFlattenedExperience, selectedLocale: string, selectedMarketplace?: string) {
        // Don't need to render warning if experience has no bullseye or locale set up
        if (!experience.enabledFilter?.bullseye || experience.dataSource === 'AUTO_CREATED_SNS_TOPIC') {
            return false;
        }

        if (experience.type === 'CIF') {
            return experience.locale && experience.marketplace &&
                (selectedLocale !== experience.locale || selectedMarketplace !== experience.marketplace);
        }

        return experience.locale && selectedLocale !== experience.locale;
    }

    private shouldRenderWeblabWarning(experience: IFlattenedExperience, selectedLocale: string) {
        // Don't need to render warning if experience has no bullseye or locale set up
        if (!experience?.weblab.name || !experience?.locale) {
            return false;
        }

        return experience.locale && experience.locale !== selectedLocale &&
            LOCALE_TO_MARKETPLACE[selectedLocale] === LOCALE_TO_MARKETPLACE[experience.locale];
    }

    render() {
        const {
            isVisible,
            experience,
            multiCloneExperiences,
            hideModal
        } = this.props;

        const { currentCloneIndex } = this.state;

        const type = experience?.type;
        const shouldDisableInput = false;
        const MAX_MULTI_CLONE_SIZE = 20;

        if (experience === undefined || experience === null) {
            return <Modal>
                <div className='awsui-util-inactive'>
                    You cannot clone experience right now. Please try again later.
                </div>
            </Modal>;
        }

        if (experience.snsTopicBYORDataSource) {
            return <Modal
                id='modal.multi-clone-disabled'
                visible={isVisible}
                header='Multi Clone'
                onDismiss={hideModal.bind(this)}>
                <div className='awsui-util-inactive'>
                    <Alert
                        header='Warning'
                        type='warning'>
                    Cloning is not supported for BYOR experiences.
                    </Alert>
                </div>
            </Modal>;
        }

        if (multiCloneExperiences.length === 0) {
            multiCloneExperiences.push(buildCloneExperience(experience, 0));
            this.updateMultiCloneExperience(multiCloneExperiences);
        }

        const isReferralContent = ExperienceUpdateCandidate.extractContent(experience).contentType === 'referral';
        const shouldDisableReferralContentInput = shouldDisableInput || (isReferralContent && !ExperienceUpdateCandidate.extractContent(experience).referralQuestionContentText);
        const isDesktopNotification: boolean = ExperienceUpdateCandidate.extractNotificationContent(experience).appNotificationType === 'Desktop';
        const contentDescriptionString = `Body should include 1) Action 2) Benefit statement${isDesktopNotification
            ? '.'
            : ' 3) Call to Action - it can either be a dialog or a click/tap action.'
            } If your notification content includes a dialog, please write it within quotes, e.g. "Alexa, play the debate"`;
        const referralContentPlaceHolder = shouldDisableReferralContentInput
            ? 'You are cloning an experience with empty referral content. You can edit referral content section later after clone creation.'
            : 'Your referral data section will not be cloned to new experience if referral content is empty here. You can edit it later after clone creation.';
        const isJourneyExperience = experience.journeyMetadata !== undefined && experience.journeyMetadata !== null;

        return <Modal
            id='modal.multi-clone'
            visible={isVisible}
            header='Multi Clone'
            size='max'

            onDismiss={hideModal.bind(this)}
            footer={
                <div className='awsui-row'>
                    <div className='col-6'>
                        <span className='awsui-util-f-l'>
                            {multiCloneExperiences.length < MAX_MULTI_CLONE_SIZE &&
                                <Button
                                    id='button.add-clone-experience'
                                    icon={'add-plus'}
                                    onClick={() => {
                                        multiCloneExperiences.push(buildCloneExperience(experience, multiCloneExperiences.length));
                                        this.updateMultiCloneExperience(multiCloneExperiences);
                                        this.updateCurrentCloneIndex(multiCloneExperiences.length - 1);
                                    }}
                                >Add Another Clone</Button>
                            }
                            {multiCloneExperiences.length > 1 &&
                                <Button
                                    id='button.delete-clone-experience'
                                    onClick={() => {
                                        multiCloneExperiences.splice(currentCloneIndex, 1);
                                        this.updateMultiCloneExperience(multiCloneExperiences);
                                        this.updateCurrentCloneIndex(Math.max(0, currentCloneIndex - 1));
                                    }}
                                >Delete Selected Clone</Button>
                            }
                        </span>
                    </div>

                    <div className='col-6 awsui-util-t-r'>
                        <span className='awsui-util-f-r'>
                            <Button
                                id='button.cancel-multi-clone'
                                variant='link'
                                onClick={hideModal.bind(this)}>
                                Cancel
                            </Button>
                            <Button
                                id='button.create-multi-clone'
                                variant='primary'
                                disabled={!this.validateModalCompletion(experience.type, isReferralContent)}
                                onClick={this.createCloneButtonHandler.bind(this)}>
                                Create Clones
                            </Button>
                        </span>
                    </div>
                </div>
            }
        >

            <div className='awsui-row'>
                <ColumnLayout columns={3} variant='text-grid'>
                    <div data-awsui-column-layout-root='true'>
                        <div>
                            <div>
                                {multiCloneExperiences.length > 0 && <h4>{`Clones (${multiCloneExperiences.length})`}</h4>}
                            </div>
                            {multiCloneExperiences.map((cloneExperience, index) =>
                                <div key={'clone-experience-' + index}>
                                    <Button
                                        id={index.toString()}
                                        variant='link'
                                        disabled={index === currentCloneIndex}
                                        onClick={() => {
                                            this.updateCurrentCloneIndex(index);
                                        }}
                                    >{cloneExperience.title || 'Clone Experience ' + (index + 1)}</Button>
                                </div>
                            )}
                        </div>

                        <div>
                            <div style={{ width: '900px' }}>
                                {multiCloneExperiences.map((cloneExperience, index) =>
                                    <div key={'clone-detail-' + index}>
                                        {currentCloneIndex === index ?
                                            <FormSectionView title='Clone Detail' description='To create multiple clones, click the Add Another Clone button in the bottom left column.
                                             Title, locale, marketplace, and content can be edited here. All other fields can be edited after clone creation.'>
                                                {isJourneyExperience && <Alert header='Journey Experience Warning'>
                                                    Cloning a Journey Experience requires membership to <LinkedPermissionGroup permissionGroup='journey-experience-owners' /> LDAP group.
                                                    </Alert>}

                                                <div className='awsui-util-mb-m'>
                                                    <FormField
                                                        label={<RequiredField fieldName='Experience title' />}
                                                        description='This title was set when you first created the experience and cannot be changed. It is used for tracking and searching.'
                                                    >
                                                        <TextAreaWrapper
                                                            value={cloneExperience.title}
                                                            readonly={shouldDisableInput}
                                                            onChange={(input) => {
                                                                multiCloneExperiences[index].title = input.trim();
                                                                this.updateMultiCloneExperience(multiCloneExperiences);

                                                            }}
                                                            id='experience.multi-clone-title'
                                                            validate={(utterance) => isTitleValidForMultiClone(utterance)} />
                                                    </FormField>
                                                </div>


                                                <div className='awsui-util-mb-m'>
                                                    <div className='awsui-util-mb-m'>
                                                        <FormField
                                                            label={<RequiredField fieldName='Locale' />}
                                                            description='The language in which the content will be produced.
                                                             Please ensure that your content is localized to the newly assigned locale' >
                                                            <Select
                                                                id='experience.multi-clone-locale'
                                                                controlId='experience.multi-clone-locale'
                                                                disabled={shouldDisableInput}
                                                                options={toSelectOptions(Object.keys(LOCALE_TO_MARKETPLACE))}
                                                                selectedId={cloneExperience.locale}
                                                                onChange={(event) => {
                                                                    const selectedLocale = event.detail.selectedId;
                                                                    multiCloneExperiences[index].locale = selectedLocale;
                                                                    multiCloneExperiences[index].marketplace = LOCALE_TO_MARKETPLACE[selectedLocale];
                                                                    this.updateMultiCloneExperience(multiCloneExperiences);
                                                                }}
                                                            />
                                                        </FormField>
                                                        {<div className='awsui-util-status-inactive'>
                                                            * Once your locale and marketplace are sent for review, you may not change your locale and marketplace
                                                        </div>}
                                                    </div>


                                                    <div className='awsui-util-mb-m'>{(type === 'CIF' || type === 'MobileHomeCard') &&
                                                        <FormField
                                                            label={<div>Marketplace <i className='awsui-text-secondary' >(required)</i></div>}
                                                            description='The geographic area in which customers will be reached'>
                                                            <Select
                                                                id='experience.multi-clone-marketplace'
                                                                controlId='experience.multi-clone-marketplace'
                                                                disabled={false}
                                                                options={toSelectOptions(Object.keys(MARKETPLACE_TO_REGION))}
                                                                selectedId={cloneExperience.marketplace}
                                                                onChange={(event) => {
                                                                    const selectedMarketplace = event.detail.selectedId;
                                                                    multiCloneExperiences[index].marketplace = selectedMarketplace;
                                                                    this.updateMultiCloneExperience(multiCloneExperiences);
                                                                }}
                                                            />
                                                        </FormField>}
                                                    </div>
                                                    <div>
                                                        {type === 'CIF' ? <div>
                                                            {this.shouldRenderBullseyeWarning(experience, multiCloneExperiences[index].locale, multiCloneExperiences[index].marketplace) &&
                                                                <Alert header='Bullseye Warning'
                                                                    buttonText={'Revert Locale and Marketplace'}
                                                                    onButtonClick={() => {
                                                                        multiCloneExperiences[index].locale = experience.locale || 'en_US';
                                                                        multiCloneExperiences[index].marketplace = experience.marketplace || 'US';
                                                                        this.updateMultiCloneExperience(multiCloneExperiences);
                                                                    }
                                                                    }>
                                                                    A new Bullseye is required because you selected a new locale or marketplace.
                                                                    Please enter the new Bullseye after clone creation.
                                                                </Alert>}
                                                        </div> : <div>
                                                            {(multiCloneExperiences[index].locale !== experience.locale) &&
                                                                <Alert header='Bullseye Warning'
                                                                    buttonText={'Revert Locale'}
                                                                    onButtonClick={() => {
                                                                        multiCloneExperiences[index].locale = experience.locale || 'en_US';
                                                                        this.updateMultiCloneExperience(multiCloneExperiences);
                                                                    }
                                                                    }>
                                                                    A new Bullseye is required because you selected a new locale.
                                                                    Please enter the new Bullseye after clone creation.
                                                                </Alert>}
                                                        </div>}
                                                        {this.shouldRenderWeblabWarning(experience, multiCloneExperiences[index].locale) &&
                                                            <Alert header='Weblab Warning'
                                                                buttonText='Revert Locale'
                                                                onButtonClick={() => {
                                                                    multiCloneExperiences[index].locale = experience.locale || 'en_US';
                                                                    this.updateMultiCloneExperience(multiCloneExperiences);
                                                                }
                                                                }>
                                                                A new Weblab is required because you are cloning to a locale with a different language but the same marketplace.
                                                                Please enter the new Weblab after clone creation.
                                                            </Alert>}
                                                    </div>
                                                </div>
                                                {experience.type === 'CIF' && (isReferralContent ? <div>
                                                    <FormField label={<RequiredField fieldName='Referral Content' />} description='Referral question in SSML' >
                                                        <TextAreaWrapper
                                                            id='experience.multi-clone-referral-content'
                                                            textRenderDirection={isRightToLeftLocale(multiCloneExperiences[index].locale) ? 'rtl' : 'ltr'}
                                                            readonly={shouldDisableReferralContentInput}
                                                            value={multiCloneExperiences[index].cifContent?.referralQuestionContentText}
                                                            characterLimit={referralContentCharLimit}
                                                            placeholder={referralContentPlaceHolder}
                                                            onChange={(input: string) => {
                                                                multiCloneExperiences[index].cifContent!.referralQuestionContentText = input.trim();
                                                                this.updateMultiCloneExperience(multiCloneExperiences);
                                                            }}
                                                            validate={(utterance) => isUtteranceValidForMultiClone(utterance, (experience.templateVariables || []).map(v => v.variableName))} />
                                                    </FormField>
                                                </div> : <div>
                                                    <FormField label='Content' description='Spoken content in SSML' >
                                                        <TextAreaWrapper
                                                            id='experience.multi-clone-content'
                                                            textRenderDirection={isRightToLeftLocale(multiCloneExperiences[index].locale) ? 'rtl' : 'ltr'}
                                                            readonly={shouldDisableInput}
                                                            value={multiCloneExperiences[index].cifContent?.contentText}
                                                            characterLimit={contentCharLimit}
                                                            onChange={(input: string) => {
                                                                multiCloneExperiences[index].cifContent!.contentText = input.trim();
                                                                this.updateMultiCloneExperience(multiCloneExperiences);
                                                            }}
                                                            validate={(utterance) => isUtteranceValidForMultiClone(utterance, (experience.templateVariables || []).map(v => v.variableName))} />
                                                    </FormField>
                                                </div>)}

                                                {(experience.type === 'AppNotification' || experience.type === 'DeviceNotification') && <div>
                                                    <FormField
                                                        label={<RequiredField fieldName='Notification title' />}
                                                        description='
                                                        Try to include the feature name in Title. Title should be
                                                        short and should clearly articulate purpose of the notification.'>
                                                        <TextAreaWrapper
                                                            id='experience.multi-clone-notification-title'
                                                            readonly={shouldDisableInput}
                                                            value={multiCloneExperiences[index].notificationContent?.title}
                                                            placeholder='ex. Did you know Alexa can do this?'
                                                            characterLimit={notificationHeaderLimit}
                                                            textRenderDirection={isRightToLeftLocale(multiCloneExperiences[index].locale) ? 'rtl' : 'ltr'}
                                                            onChange={(input: string) => {
                                                                multiCloneExperiences[index].notificationContent!.title = input.trim();
                                                                this.updateMultiCloneExperience(multiCloneExperiences);
                                                            }}
                                                            validate={(title) => isNotificationTitleValidForMultiClone(title, multiCloneExperiences[index].locale)}
                                                        />
                                                    </FormField>
                                                    <FormField
                                                        label={<RequiredField fieldName='Display content' />}
                                                        description={contentDescriptionString} >
                                                        <TextAreaWrapper
                                                            id='experience.multi-clone-notification-display-content'
                                                            readonly={shouldDisableInput}
                                                            textRenderDirection={isRightToLeftLocale(multiCloneExperiences[index].locale) ? 'rtl' : 'ltr'}
                                                            placeholder='Notification content goes here'
                                                            value={multiCloneExperiences[index].notificationContent?.displayContent}
                                                            characterLimit={notificationContentLimit}
                                                            onChange={(input: string) => {
                                                                multiCloneExperiences[index].notificationContent!.displayContent = input.trim();
                                                                this.updateMultiCloneExperience(multiCloneExperiences);
                                                            }}
                                                            validate={(displayContent) => isDisplayContentValidForMultiClone(displayContent, multiCloneExperiences[index].locale)}
                                                        />
                                                    </FormField>
                                                    {experience.type === 'DeviceNotification' && <FormField
                                                        label={<RequiredField fieldName='Spoken content' />}
                                                        description={contentDescriptionString} >
                                                        <TextAreaWrapper
                                                            id='experience.multi-clone-spoken-content'
                                                            readonly={shouldDisableInput}
                                                            placeholder='Notification spoken content goes here'
                                                            textRenderDirection={isRightToLeftLocale(multiCloneExperiences[index].locale) ? 'rtl' : 'ltr'}
                                                            value={multiCloneExperiences[index].notificationContent?.spokenContent}
                                                            characterLimit={spokenContentLimit}
                                                            onChange={(input: string) => {
                                                                multiCloneExperiences[index].notificationContent!.spokenContent = input.trim();
                                                                this.updateMultiCloneExperience(multiCloneExperiences);
                                                            }}
                                                            validate={(spokenContent) => isSpokenContentValidForMultiClone(spokenContent, multiCloneExperiences[index].locale)}
                                                        />
                                                    </FormField>}
                                                </div>
                                                }
                                            </FormSectionView> : null
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </ColumnLayout>
            </div>
        </Modal>;
    }
}

const mapStateToProps = ({ odysseyExperienceDetailViewState, experienceDetailViewState, multiCloneState }: AppState) => {
    return {
        experience: experienceDetailViewState.experience || odysseyExperienceDetailViewState.experience,
        multiCloneExperiences: multiCloneState.multiCloneExperiences || []
    };
};

export default connect(mapStateToProps)(MultiCloneModal);
