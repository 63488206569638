import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Spinner } from '@amzn/awsui-components-react';
import { AppState } from '../../reducers/index';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import OdysseyExperienceRCPMetrics from '../../components/metrics/v2/OdysseyExperienceRCPMetrics';

export interface IOdysseyExperienceMetricsProps {
    dispatch: any;

    experience?: IFlattenedExperience;

    isCXDailyMetricsLoading?: boolean;
    isCXWeeklyMetricsLoading?: boolean;
}

class OdysseyExperienceMetrics extends Component<IOdysseyExperienceMetricsProps> {
    public render() {
        const { experience, isCXDailyMetricsLoading, isCXWeeklyMetricsLoading } = this.props;

        if (isCXDailyMetricsLoading || isCXWeeklyMetricsLoading) {
            return <div
                id='metrics.spinner'
                style={{ display: 'flex', justifyContent: 'center' }}
            >
                <span className='awsui-util-status-inactive'><Spinner />Loading metrics...</span>
            </div>;
        } else if (!experience) {
            return <Alert
                id='metrics.alert'
                type='error'
                content='Something happened while trying to load your metrics...'
            />;
        }

        return <>
            {experience.type === 'RotatingContentPanel' &&
                <div className='awsui-row'>
                    <div className='col-12'>
                        <OdysseyExperienceRCPMetrics />
                    </div>
                </div>
            }
        </>;
    }
}

const mapStateToProps = ({ odysseyExperienceDetailViewState, rcpCXDailyMetricsState, rcpCXWeeklyMetricsState }: AppState) => {
    return {
        experience: odysseyExperienceDetailViewState.experience || undefined,
        isCXDailyMetricsLoading: rcpCXDailyMetricsState.isLoading,
        isCXWeeklyMetricsLoading: rcpCXWeeklyMetricsState.isLoading
    };
};

export default connect(mapStateToProps)(OdysseyExperienceMetrics);
