import * as React from 'react';
import { Component } from 'react';
import { IHomeCardSuggestion } from '../../models/HomeCardSuggestion';
import { ISuggestionVariant } from '../../models/homeCardSuggestions/SuggestionVariant';
import HomeCardSuggestionDetailMediaView from './sections/suggestions/HomeCardSuggestionDetailMediaView';
import HomeCardSuggestionDetailSkillsView from './sections/suggestions/HomeCardSuggestionDetailSkillsView';
import HomeCardSuggestionDetailTextView from './sections/suggestions/HomeCardSuggestionDetailTextView';

export const documentToTemplateMapping: { [key: string]: SUGGESTIONS_TEMPLATES } = {
    'doc://alexa/apl/documents/home/cards/media': 'Media Thumbnail',
    'doc://alexa/apl/documents/home/cards/textWrapping': 'Text Wrapping',
    'doc://alexa/apl/documents/home/cards/rating': 'Skills Rating'
};

export type SUGGESTIONS_TEMPLATES = 'Media Thumbnail' | 'Text Wrapping' | 'Skills Rating';

export default class HomeCardSuggestionDetailGlobalView extends Component<{
    suggestion: IHomeCardSuggestion,
}> {
    public render() {
        const { suggestion } = this.props;
        const templateType = documentToTemplateMapping[suggestion.suggestion.variants[0].content.values[0].document.src];

        return <div className='awsui-util-container'>
        <div className='awsui-util-container-header'>
            <div className='awsui-grid'>
                <div className='awsui-row'>
                    <div className='col-12 awsui-util-t-l'>
                        <h3><b>Home card content</b></h3>
                    </div>
                </div>
            </div>
        </div>
        {templateType === 'Media Thumbnail' && <HomeCardSuggestionDetailMediaView suggestion={suggestion}/>}
        {(templateType === 'Text Wrapping'  || templateType === undefined) && <HomeCardSuggestionDetailTextView suggestion={suggestion}/>}
        {templateType === 'Skills Rating' && <HomeCardSuggestionDetailSkillsView suggestion={suggestion}/>}
    </div>;
    }
}

export const showDisplayTextValue = (variants: ISuggestionVariant[], text: string) : string => {
    if (text === 'action') {
        return variants[0].content.values[0].datasources.displayText[text];
    } else if (text === 'backgroundImageSource' && variants.length !== 0 && variants[0].content.values[0].datasources.background && variants[0].content.values[0].datasources.background[text]) {
        return variants[0].content.values[0].datasources.background[text].toString();
    } else if (variants.length !== 0 && variants[0].content.values[0].datasources.displayText[text]) {
        return variants[0].content.values[0].datasources.displayText[text].toString();
    }

    return '';
};
