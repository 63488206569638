import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { getLinkableUrl, PAGE } from '../constants/page';
import { AppState } from '../reducers/index';
import { BreadcrumbGroup, Flash, Flashbar, Tabs } from '@amzn/awsui-components-react';
import { IHomeCardSuggestion } from '../models/HomeCardSuggestion';
import HomeCardSuggestionDetail from './HomeCardSuggestionDetail';
import { getSuggestionAction } from '../actions/homeCardSuggestionsDetailViewActions';

export interface ISuggestionProps {
    match: { params: { id: string } };

    dispatch: any;

    suggestion?: IHomeCardSuggestion;
    isLoading: boolean;
    isUpdating: boolean;
    error?: Error;
}

export interface ISuggestionState {
    activeTabId: string;
}

class HomeCardSuggestion extends Component<ISuggestionProps, ISuggestionState> {
    constructor(props: ISuggestionProps) {
        super(props);

        this.state = {
            activeTabId: 'suggestion-configuration',
        };
    }

    public componentDidMount() {
        const { match, dispatch } = this.props;

        dispatch(getSuggestionAction(match.params.id));
    }

    public componentDidUpdate() {
        const { dispatch, suggestion, isLoading, error, match } = this.props;

        if (!suggestion && !isLoading && !error) {
            dispatch(getSuggestionAction(match.params.id));
        }
    }

    render() {
        const { isLoading, error, suggestion, isUpdating } = this.props;
        const { activeTabId } = this.state;

        if (error) {
            return <Flashbar id='suggestion-error-bar' items={[{
                header: 'Loading failed',
                content: 'Failed to find the suggestion',
                type: 'error'
            }]} />;
        }

        if (isLoading) {
            return <Flash id='suggestion.loading-bar' dismissible={false} >Loading...</Flash>;
        }

        if (isUpdating) {
            return <Flash id='suggestion.updating-bar' dismissible={false} >Updating...</Flash>;
        }

        if (!suggestion) {
            return <Flash dismissible={true} type='error' id='suggestion.error-bar' >
                Something happened while trying to load the suggestion...
            </Flash>;
        }

        return <div>
            <div style={{ margin: '30px' }}>
                <BreadcrumbGroup label='Breadcrumbs' items={[{
                    text: 'Odyssey',
                    href: getLinkableUrl(PAGE.Home),
                }, {
                    text: 'Home Card Suggestions',
                    href: getLinkableUrl(PAGE.HomeCardSuggestions),
                }, {
                    text: suggestion.id || '-',
                    href: getLinkableUrl(PAGE.HomeCardSuggestions) + '/' + suggestion.id
                }]} />
                <Tabs
                    activeTabId={activeTabId}
                    tabs={getSuggestionTabs(this.props)}/>
            </div>
        </div>;
    }
}

const getSuggestionTabs = (props: ISuggestionProps): Tabs.Tab[] => {
    return [{
        label: 'Suggestion Configuration',
        id: 'suggestion-configuration',
        content: <HomeCardSuggestionDetail {...props} />
    }];
};

const mapStateToProps = ({ homeCardSuggestionsDetailViewState, homeCardSuggestionsEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isLoading has default value set in reducer, so no default
        // value needed
        suggestion: homeCardSuggestionsDetailViewState.suggestion || undefined,
        isLoading: homeCardSuggestionsDetailViewState.isLoading,
        error: homeCardSuggestionsDetailViewState.error || undefined,
        isUpdating: homeCardSuggestionsEditViewState.isUpdating
    };
};

export default connect(mapStateToProps)(HomeCardSuggestion);
