import * as React from 'react';
import { useState } from 'react';

import { BreadcrumbGroup } from '@amzn/awsui-components-react';
import { getLinkableUrl, PAGE } from '../../constants/page';
import { useLocation } from 'react-router-dom';
import OdysseyExperienceTable from '../../components/list/v2/OdysseyExperienceTable';

/**
 * HLC (higher level components) for hooks
 */
export const OdysseyExperiences = () => {
    const search = useLocation().search;
    const searchCriteriaParams = new URLSearchParams(search).get('search');
    const [value, setState] = useState(searchCriteriaParams);
    const location = useLocation();

    const updateFilteringCriteria = (searchCriteriaString: string) => {
        setState(searchCriteriaString);
        window.location.assign(getLinkableUrl(`${location.pathname}?search=${searchCriteriaString}`));
    };

    return (
        <div>
            <div style={{ margin: '30px' }}>
                <div style={{ marginBottom: '20px' }}>
                    <BreadcrumbGroup label='Breadcrumbs' items={[{
                        text: 'Odyssey',
                        href: getLinkableUrl(PAGE.Home),
                    }, {
                        text: 'Experiences',
                        href: getLinkableUrl(location.pathname),
                    }]} />
                </div>
                <OdysseyExperienceTable updateFilteringCriteria={updateFilteringCriteria} searchCriteriaString={value} />
            </div>
        </div>
    );
};
