import { Action, Dispatch } from 'redux';

import * as AcopsGateway from '../api/acopsGateway';
import * as odysseyExperienceManagementGateway from '../api/odysseyExperienceManagementGateway';
import {
    CANCEL_EDITING,
    DISABLING_SAVE_BUTTON,
    ENABLE_CLONING,
    ENABLE_CREATING,
    ENABLE_EDITING,
    ERROR_UPDATING_EXPERIENCE,
    PUSH_ACKNOWLEDGE_BACKGROUND_IMAGE,
    PUSH_APPROVAL_INFO,
    PUSH_BASIC_INFO,
    PUSH_BULLSEYE,
    PUSH_BUSINESS_INFO,
    PUSH_BYOR_SNS_TOPIC_ARN,
    PUSH_BYPASS_CAPABILITY_FILTER,
    PUSH_CONTENT,
    PUSH_CONTENT_VARIABLES,
    PUSH_CUSTOM_DOMAIN_ILLUSTRATION_SOURCE,
    PUSH_DATA_SOURCE,
    PUSH_DAYTIME_WINDOW,
    PUSH_DEVICES,
    PUSH_DOMAIN_TAGS,
    PUSH_ENABLE_VOICE_CIF,
    PUSH_EXPOSURE_CONTROL_RATE,
    PUSH_FEATURE_HINT,
    PUSH_IMPRESSION_TRACKING,
    PUSH_INCLUDING_ODYSSEY_FIELDS,
    PUSH_JOURNEY_METADATA,
    PUSH_METADATA,
    PUSH_MOBILE_HOME_CARD_AB_EXPERIMENT,
    PUSH_MOBILE_HOME_CARD_ACTION,
    PUSH_MOBILE_HOME_CARD_CONSTRAINT,
    PUSH_MOBILE_HOME_CARD_CONTENT,
    PUSH_MOBILE_HOME_CARD_CONTEXT,
    PUSH_MOBILE_HOME_CARD_TARGETING,
    PUSH_NLU_OPPORTUNITY_EXCLUSION,
    PUSH_NLU_OPPORTUNITY_INCLUSION,
    PUSH_NOTIFICATION_CONTENT,
    PUSH_NOTIFICATION_GUARDRAILS,
    PUSH_OPPORTUNITY_EXCLUSION,
    PUSH_OPPORTUNITY_INCLUSION,
    PUSH_OVERRIDE_ACCOUNTS,
    PUSH_OVERRIDE_DEFAULT_BACKGROUND_IMAGE,
    PUSH_OVERRIDE_FILTERS,
    PUSH_PROMOTED_CAPABILITY,
    PUSH_REGION,
    PUSH_REMOTE_TAGS,
    PUSH_ROTATING_CONTENT_PANEL_ACTIVATION_TIME,
    PUSH_ROTATING_CONTENT_PANEL_BUSINESS_INFO,
    PUSH_ROTATING_CONTENT_PANEL_CONTEXT,
    PUSH_ROTATING_CONTENT_PANEL_DEVICES,
    PUSH_ROTATING_CONTENT_PANEL_DISPLAY_ELEMENTS,
    PUSH_ROTATING_CONTENT_PANEL_METADATA,
    PUSH_ROTATING_CONTENT_PANEL_OVERRIDE_ACCOUNTS,
    PUSH_ROTATING_CONTENT_PANEL_TOUCH_ACTIONS,
    PUSH_ROW_COUNTRY_INFO,
    PUSH_SPEAKER_DATA,
    PUSH_STATUS, PUSH_SUBSCRIPTION_UPSELL_TYPE,
    PUSH_SUGGESTED_FEATURE,
    PUSH_TYPE,
    PUSH_USE_NON_CTA_TEMPLATE,
    PUSH_USE_TOUCH_ACTION_TEMPLATE,
    PUSH_VISUAL_INTERSTITIAL_VARIABLES,
    UPDATED_EXPERIENCE,
    UPDATING_EXPERIENCE
} from '../constants/experienceEditViewActionTypes';
import { AppState } from '../reducers/index';
import {
    ExperienceUpdateCandidate,
    IBasicInfoViewAttributes,
    IContentViewAttributes,
    IDataSourceViewAttributes,
    IImpressionTrackingViewAttributes,
    IPromotedCapability,
    IRegionViewAttributes
} from '../models/ExperienceUpdateCandidate';
import { IFlattenedExperience } from '../models/FlattenedExperience';
import { ITemplateVariable } from '../models/TemplateVariable';
import { ExperienceFilterType } from '../models/Guardrails';
import { IContextualSegments } from '../models/Filter';
import { IRemoteContextualSegments } from '../models/RemoteContextualSegments';

import { INluOpportunityTargeting } from '../models/NluOpportunityTargeting';
import { INotificationExperienceContent } from '../models/appDeviceExperience/NotificationExperienceContent';
import { transformExperience, transformOdysseyExperience } from '../util/stringAndMappingHelper';
import { getLinkableUrl, getLinkableUrlByExperienceType, PAGE } from '../constants/page';
import { ExperienceStatus, ExperienceType, IExperience } from '../models/Experience';
import { EMPTY_EXPERIENCE } from '../data/EmptyExperienceData';
import { IMetadata } from '../models/Metadata';
import { ITimeRange } from '../models/TimeRange';
import { IOpportunityTrigger } from '../models/NluOpportunityTrigger';
import { ISpeakerData, UserRole } from '../models/SpeakerData';
import { INluInfo } from '../models/NluInfo';
import { ActionType } from '../models/ActionType';
import {
    errorLoadingExperienceDetail,
    loadedExperienceDetail,
    loadingExperienceDetail
} from './experienceDetailViewActions';
import { IManagementCard } from '../models/cifExperience/visualExperience/IManagementCard';
import { IRowCountryInfo } from '../models/RowCountryInfo';
import { IApprovalMetadata } from '../models/ApprovalInfo';
import { IJourneyMetadata } from '../models/JourneyMetadata';
import { REQUESTED_DIALUP_STAGE_OPTIONS } from '../util/exposureControlAllocationHelper';
import { INotificationGuardrails } from '../models/NotificationGuardrails';
import { IBusinessMetadata } from '../models/BusinessInfo';
import { dialupStageToConfigurationTypeMapping } from '../constants/configurationTypeMap';
import {
    CLEAR_CAPABILITY_SEARCH,
    SELECTED_PROMOTED_CAPABILITY,
    TOGGLE_PROMOTED_CAPABILITY_VISIBILITY
} from '../constants/capabilitySearchActionTypes';
import { IMobileHomeCardContent } from '../models/mobileHomeCard/MobileHomeCardContent';
import { IMobileHomeCardAction } from '../models/mobileHomeCard/MobileHomeCardAction';
import { IMobileHomeCardConstraint } from '../models/mobileHomeCard/MobileHomeCardConstraint';
import { IMobileHomeCardAbExperiment } from '../models/mobileHomeCard/MobileHomeCardAbExperiment';
import { IMobileHomeCardTargeting } from '../models/mobileHomeCard/MobileHomeCardTargeting';
import { IMobileHomeCardContext } from '../models/mobileHomeCard/MobileHomeCardContext';
import {
    IRCPTouchAction,
    IRotatingContentPanelBusinessContext,
    IRotatingContentPanelContent,
    IRotatingContentPanelContext,
    IRotatingContentPanelDevicesConstraint,
    IRotatingContentPanelSchedulingConstraint,
    IRotatingContentPanelTestingConstraint,
    RCPMetadata,
    TextWrappingDataSource
} from '../models/rotatingContentPanel/RotatingContentPanelStructure';
import { scrollToTopOf, validateExperienceOnSubmit } from '../util/ExperienceEditViewActionHelper';
import { SubscriptionUpsellType } from '../models/SubscriptionUpsellType';

export interface IUpdateExperienceAction extends Action {
    id?: string;
    error?: Error;
    experienceType?: ExperienceType;
    experienceStatus?: ExperienceStatus;
    metadata?: IMetadata;
    region?: IRegionViewAttributes;
    experience?: IFlattenedExperience;
    timeRanges?: { [key: number]: ITimeRange[] };
    basicInfo?: IBasicInfoViewAttributes;
    suggestedFeature?: INluInfo;
    impressionTracking?: IImpressionTrackingViewAttributes;
    promotedCapability?: IPromotedCapability;
    bypassCapabilityFilter?: boolean;
    content?: IContentViewAttributes;
    templateVariables?: ITemplateVariable[];
    devices?: string[];
    launchTreatmentPercentage?: number;
    rowCountryInfo?: IRowCountryInfo;
    approvalInfo?: IApprovalMetadata;
    businessInfo?: IBusinessMetadata;
    subscriptionUpsellType?: SubscriptionUpsellType;
    journeyMetadata?: IJourneyMetadata;

    overrideAccounts?: string[];
    overrideSelectiveFilters?: ExperienceFilterType[];
    verboseLoggingEnabled?: boolean;

    visualInterstitialCards?: IManagementCard[];
    enableVoiceCif?: boolean;
    includingOdysseyFields?: boolean;

    contextualSegments?: IContextualSegments;
    remoteContextualSegments?: IRemoteContextualSegments;

    featureHints?: string[];
    bullseye?: number;
    speakerData?: ISpeakerData;
    snsTopicBYORDataSource?: string;

    opportunityTriggerInclusions?: IOpportunityTrigger[];
    opportunityTriggerExclusions?: IOpportunityTrigger[];
    nluOpportunityTargetingInclusions?: INluOpportunityTargeting[];
    nluOpportunityTargetingExclusions?: INluOpportunityTargeting[];

    notificationContent?: INotificationExperienceContent;
    notificationGuardrails?: INotificationGuardrails;
    dataSource?: IDataSourceViewAttributes;

    isCreating?: boolean;

    isQuickCreateWorkflow?: boolean;
    isVisualCIF?: boolean;

    useTouchActionTemplate?: boolean;
    useNonCTATemplate?: boolean;

    // custom domain illustration and background image selection
    domainIllustrationCustomSource?: boolean[];
    overrideBackgroundImage?: boolean[];
    acknowledgeBackgroundImage?: boolean[];

    shouldDisableSave?: boolean;

    // mobile home card
    mobileHomeCardContent?: IMobileHomeCardContent;
    mobileHomeCardActions?: IMobileHomeCardAction[];
    mobileHomeCardConstraint?: IMobileHomeCardConstraint;
    mobileHomeCardAbExperiment?: IMobileHomeCardAbExperiment;
    mobileHomeCardTargeting?: IMobileHomeCardTargeting;
    mobileHomeCardContext?: IMobileHomeCardContext;

    // RCP
    rotatingContentPanelContent?: IRotatingContentPanelContent;
    rotatingContentPanelTestingConstraint?: IRotatingContentPanelTestingConstraint;
    rotatingContentPanelDevicesConstraint?: IRotatingContentPanelDevicesConstraint;
    rotatingContentPanelSchedulingConstraint?: IRotatingContentPanelSchedulingConstraint;
    rotatingContentPanelBusinessContext?: IRotatingContentPanelBusinessContext;
    rotatingContentPanelContext?: IRotatingContentPanelContext;
}

export const updatingExperience = (): IUpdateExperienceAction => {
    return {
        type: UPDATING_EXPERIENCE
    };
};

export const updatedExperience = (id: string, isCreating?: boolean | false): IUpdateExperienceAction => {
    return {
        type: UPDATED_EXPERIENCE,
        isCreating,
        id
    };
};

export const errorUpdatingExperience = (error: Error): IUpdateExperienceAction => {
    return {
        type: ERROR_UPDATING_EXPERIENCE,
        error
    };
};

/* Actions that should be on global edit/detail view */

export const syncDialedUpWeblabToFullExposureAction = () => async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(updatingExperience());
    const state = getState();
    if (state.experienceDetailViewState.experience) {
        const userAlias = state.navigationViewState.userAlias;
        // have to transform to update candidate and transform back
        // because there is business logic such as validating experience and updating
        // modifiedAt timestamp, modifiedBy userAlias, etc.
        const updateCandidate = new ExperienceUpdateCandidate(ExperienceUpdateCandidate.extractUpdateCandidate(state.experienceDetailViewState.experience));
        updateCandidate.setStatus(state.experienceDetailViewState.experience.status);
        updateCandidate.setLaunchTreatmentPercentage(1.0);
        const updateExperience = updateCandidate.transformToExperience(userAlias);

        try {
            const returnedId = await AcopsGateway.putExperience(updateExperience);
            const newExperience = await AcopsGateway.getExperience(returnedId);
            setTimeout(() => {
                state.experienceDetailViewState.experience = transformExperience(newExperience);
                dispatch(updatedExperience(returnedId));
                window.location.assign(getLinkableUrl(PAGE.Experiences) + '/' + returnedId);
            }, 2000);
        } catch (error) {
            dispatch(errorUpdatingExperience(error as Error));
        }
    } else {
        dispatch(errorUpdatingExperience(new Error('Bad input')));
    }
};

export const saveExperienceAction = () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    if (state.experienceEditViewState.updateCandidate) {
        try {
            validateExperienceOnSubmit(state.experienceEditViewState.updateCandidate);

            const userAlias = state.navigationViewState.userAlias;
            const experience = state.experienceEditViewState.updateCandidate.transformToExperience(userAlias);
            dispatch(updatingExperience());
            const returnedId = await AcopsGateway.putExperience(experience);
            const newExperience = await AcopsGateway.getExperience(returnedId);
            setTimeout(() => {
                state.experienceDetailViewState.experience = transformExperience(newExperience);
                dispatch(updatedExperience(returnedId));
                window.location.assign(getLinkableUrl(PAGE.Experiences) + '/' + returnedId);
            }, 2000);
        } catch (error) {
            dispatch(errorUpdatingExperience(error as Error));
            scrollToTopOf('experience.tabs');
        }
    } else {
        dispatch(errorUpdatingExperience(new Error('Bad input')));
    }
};

export const createInflightExperienceAction = () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    if (state.experienceEditViewState.updateCandidate) {
        const userAlias = state.navigationViewState.userAlias;
        const experience = state.experienceEditViewState.updateCandidate.transformToExperience(userAlias);
        state.experienceDetailViewState.experience = transformExperience(experience);

        // adding this to make sure cretion workflow work properly.
        const odysseyExperience = state.experienceEditViewState.updateCandidate.transformToOdysseyExperience(userAlias);
        state.odysseyExperienceDetailViewState.experience = transformOdysseyExperience(odysseyExperience);

        // Reset Content
        experience.content = {};
        experience.filters = {};
    } else {
        dispatch(errorUpdatingExperience(new Error('Bad input')));
    }
};

export const createExperienceAction = (usedNewCreationWorkflow: boolean = false) => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    if (state.experienceEditViewState.updateCandidate) {
        try {
            validateExperienceOnSubmit(state.experienceEditViewState.updateCandidate);

            const userAlias = state.navigationViewState.userAlias;
            const experience = state.experienceEditViewState.updateCandidate.transformToExperience(userAlias);
            dispatch(updatingExperience());

            // For users not using the new creation workflow, we must remove existing Filters/Content
            // as we expect users to fill this in as part of the editView creation flow.
            if (!usedNewCreationWorkflow) {
                experience.content = {};
                experience.filters = {};
            }

            const returnedId = await AcopsGateway.putExperience(experience);
            const newExperience = await AcopsGateway.getExperience(returnedId);
            setTimeout(() => {
                state.experienceDetailViewState.experience = transformExperience(newExperience);
                dispatch(updatedExperience(returnedId, true));
                window.location.assign(getLinkableUrl(PAGE.Experiences) + '/' + returnedId);
            }, 2000);
        } catch (error) {
            dispatch(errorUpdatingExperience(error as Error));
            scrollToTopOf('flashbar.update-error');
        }
    } else {
        dispatch(errorUpdatingExperience(new Error('Bad input')));
    }
};

export const sendExperienceForReviewAction = (
    testerAlias: string,
    testerCustomerId: string
) => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    if (state.experienceDetailViewState.experience) {
        const experience = ExperienceUpdateCandidate.deepClone(state.experienceDetailViewState.experience);

        experience.status = 'UNDER_REVIEW';
        experience.metadata.testerAlias = testerAlias;
        experience.metadata.testerCustomerId = testerCustomerId;
        dispatch(updatingExperience());

        try {
            const returnedId = await AcopsGateway.putExperience(experience);
            const newExperience = await AcopsGateway.getExperience(returnedId);
            setTimeout(() => {
                state.experienceDetailViewState.experience = transformExperience(newExperience);
                dispatch(updatedExperience(returnedId));
                window.location.assign(getLinkableUrl(PAGE.Experiences) + '/' + returnedId);
                return returnedId;
            }, 2000);
        } catch (error) {
            dispatch(errorUpdatingExperience(error as Error));
        }
    } else {
        dispatch(errorUpdatingExperience(new Error('Bad input')));
    }
};

export const cancelExperienceAction = (cancelReason: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    if (state.experienceDetailViewState.experience) {
        const experience = ExperienceUpdateCandidate.deepClone(state.experienceDetailViewState.experience);

        experience.status = 'CANCELED';
        experience.cancellationReason = cancelReason;
        dispatch(updatingExperience());

        try {
            const returnedId = await AcopsGateway.putExperience(experience);
            const newExperience = await AcopsGateway.getExperience(returnedId);
            setTimeout(() => {
                state.experienceDetailViewState.experience = transformExperience(newExperience);
                dispatch(updatedExperience(returnedId));
                window.location.assign(getLinkableUrl(PAGE.Experiences) + '/' + returnedId);
            }, 2000);
        } catch (error) {
            dispatch(errorUpdatingExperience(error as Error));
        }
    } else {
        dispatch(errorUpdatingExperience(new Error('Bad input')));
    }
};

export const generateExperienceApprovalAction = (isExceptionalApprovalRequest: boolean, requestedDialupStage?: REQUESTED_DIALUP_STAGE_OPTIONS,
    exceptionReason?: string, enableGrammarSpellingChecks?: boolean, enableSensitiveContentChecks?: boolean, simDescription?: string) => async (dispatch: Dispatch, getState: () => AppState) => {
        const state = getState();
        if (state.experienceDetailViewState.experience) {
            const experience = ExperienceUpdateCandidate.deepClone(state.experienceDetailViewState.experience);
            if (experience.approvalInfo && requestedDialupStage) {
                experience.approvalInfo.requestedDialupStage = requestedDialupStage;
            }
            dispatch(updatingExperience());

            try {
                await AcopsGateway.generateExperimentApproval(experience.id, isExceptionalApprovalRequest, requestedDialupStage, simDescription, exceptionReason, enableGrammarSpellingChecks,
                    enableSensitiveContentChecks);
                const newExperience = await AcopsGateway.getExperience(experience.id);
                setTimeout(() => {
                    state.experienceDetailViewState.experience = transformExperience(newExperience);
                    dispatch(updatedExperience(experience.id));
                    window.location.assign(getLinkableUrl(PAGE.Experiences) + '/' + experience.id);
                    return experience.id;
                }, 2000);
            } catch (error) {
                dispatch(errorUpdatingExperience(error as Error));
            }
        } else {
            dispatch(errorUpdatingExperience(new Error('Bad input')));
        }
    };

export const deleteExperienceAction = () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    if (state.experienceDetailViewState.experience) {
        const id = state.experienceDetailViewState.experience.id;

        try {
            await AcopsGateway.deleteExperience(id);
            dispatch(updatedExperience(id));
            setTimeout(() => {
                window.location.assign(getLinkableUrl(PAGE.Experiences));
            }, 2000);
        } catch (error) {
            dispatch(errorUpdatingExperience(error as Error));
        }
    } else {
        dispatch(errorUpdatingExperience(new Error('Bad input')));
    }
};

export const skipExperienceApprovalAction = (requestedStatus?: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const experience = state.experienceDetailViewState.experience;
    if (experience) {
        dispatch(updatingExperience());

        if (requestedStatus === undefined) {
            requestedStatus = experience.status === 'TESTABLE' ? 'LIVE_TEN_PERCENT' : 'LIVE_FIFTY_PERCENT';
        }
        const configurationType = dialupStageToConfigurationTypeMapping[requestedStatus];

        try {
            await AcopsGateway.configureCifExperiment(experience.id, '', configurationType);
            const newExperience = await AcopsGateway.getExperience(experience.id);
            setTimeout(() => {
                state.experienceDetailViewState.experience = transformExperience(newExperience);
                dispatch(updatedExperience(experience.id));
                window.location.assign(getLinkableUrl(PAGE.Experiences) + '/' + experience.id);
                return experience.id;
            }, 2000);
        } catch (error) {
            dispatch(errorUpdatingExperience(error as Error));
        }
    } else {
        dispatch(errorUpdatingExperience(new Error('Bad input')));
    }
};

export const enableEditingAction = (experience: IFlattenedExperience) => async (dispatch: Dispatch) => {
    dispatch({
        type: ENABLE_EDITING,
        experience
    });
};

export const cancelEditingAction = () => async (dispatch: Dispatch) => {
    dispatch({
        type: CANCEL_EDITING
    });
    dispatch({
        type: CLEAR_CAPABILITY_SEARCH
    });
};

export const enableCloningAction = (id: string) => async (dispatch: Dispatch) => {
    dispatch(loadingExperienceDetail());

    try {
        const data: IExperience = await AcopsGateway.getExperience(id);
        dispatch(loadedExperienceDetail(data));
        dispatch(enableCloning(transformExperience(data)));
    } catch (error) {
        dispatch(errorLoadingExperienceDetail(error as Error));
    }
};

export const enableCloning = (experience: IFlattenedExperience) => {
    return {
        type: ENABLE_CLONING,
        experience
    };
};

export const enableCreatingAction = () => async (dispatch: Dispatch) => {
    dispatch({
        type: ENABLE_CREATING,
        experience: transformExperience(EMPTY_EXPERIENCE)
    });
};

/* Actions that should be on Section views */

export const pushRegionSectionAction = (
    marketplace?: string, locale?: string, enabled?: boolean
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_REGION,
        region: {
            marketplace,
            locale,
            enabled
        }
    });
};

export const pushRowCountryInfoAction = (
    rowExperience?: boolean, supportedRowCountries?: string[]
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_ROW_COUNTRY_INFO,
        rowCountryInfo: {
            rowExperience,
            supportedRowCountries
        }
    });
};

export const pushDayTimeWindowSectionAction = (
    timeRanges: { [key: number]: ITimeRange[] }
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_DAYTIME_WINDOW,
        timeRanges
    });
};

export const pushTypeAction = (
    experienceType?: ExperienceType,
    isQuickCreateWorkflow?: boolean,
    isVisualCIF?: boolean
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_TYPE,
        experienceType,
        isQuickCreateWorkflow,
        isVisualCIF
    });
};

export const pushStatusAction = (
    experienceStatus?: ExperienceStatus,
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_STATUS,
        experienceStatus
    });
};

export const pushMetadataAction = (
    metadata?: IMetadata,
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_METADATA,
        metadata
    });
};

export const pushBasicInfoSectionAction = (
    title?: string,
    groupImpressions?: string,
    startDate?: string,
    endDate?: string,
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_BASIC_INFO,
        basicInfo: { title, groupImpressions, startDate, endDate }
    });
};

export const pushNotificationGuardrailsSectionAction = (
    overrideFrequencyGuardrail?: boolean,
    overrideContentGuardrail?: boolean,
    frequencyGuardrailOverrideDays?: number,
    contentGuardrailOverrideDays?: number,
    notificationExpirationOverrideDays?: number
) => {
    return {
        type: PUSH_NOTIFICATION_GUARDRAILS,
        notificationGuardrails: {
            overrideFrequencyGuardrail,
            overrideContentGuardrail,
            frequencyGuardrailOverrideDays,
            contentGuardrailOverrideDays,
            notificationExpirationOverrideDays
        }
    };
};

export const pushSuggestedFeature = (
    nluDomain?: string,
    nluIntent?: string,
    nluSlotName?: string[],
    featureUtterance?: string,
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_SUGGESTED_FEATURE,
        suggestedFeature: { nluDomain, nluIntent, nluSlotName, featureUtterance }
    });
};

export const pushImpressionTrackingSectionAction = (
    weblab?: string,
    weblabLaunched?: boolean,
    hintId?: string,
    contentId?: string
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_IMPRESSION_TRACKING,
        impressionTracking: { weblab, weblabLaunched, hintId, contentId }
    });
};

export const toggleCapabilitySearchModalVisibility = (
    saveOnConfirm?: boolean
) => async (dispatch: Dispatch) => {
    dispatch({
        type: TOGGLE_PROMOTED_CAPABILITY_VISIBILITY,
        saveOnConfirm
    });
};

export const selectPromotedCapabilityAction = (
    capability?: IPromotedCapability
) => async (dispatch: Dispatch) => {
    dispatch({
        type: SELECTED_PROMOTED_CAPABILITY,
        selectedCapability: capability
    });
};

export const pushPromotedCapabilityAction = (
    capability?: IPromotedCapability
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_PROMOTED_CAPABILITY,
        promotedCapability: capability
    });
};

export const pushBypassCapabilityFilterAction = (
    bypassChecked: boolean
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_BYPASS_CAPABILITY_FILTER,
        bypassCapabilityFilter: bypassChecked
    });
};

export const pushContentSectionAction = (
    contentType: 'content' | 'referral' | 'none',
    contentText?: string,
    referralQuestionContentText?: string,
    referralData?: string,
    rejectionReferralData?: string,
    actionType?: ActionType,
    sonarTemplateURI?: string,
    sonarTemplateJson?: string,
    campaignId?: string,
    checkCXGuidelines?: boolean,
    isEntireContentATemplateVariable?: boolean,
    customSuccessfulSendResponse?: string
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_CONTENT,
        content: {
            contentText, referralQuestionContentText, referralData, rejectionReferralData, contentType, actionType,
            sonarTemplateURI, sonarTemplateJson, campaignId, checkCXGuidelines, isEntireContentATemplateVariable, customSuccessfulSendResponse
        }
    });
};

export const pushTemplateVariablesSectionAction = (
    templateVariables?: ITemplateVariable[],
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_CONTENT_VARIABLES,
        templateVariables
    });
};

export const pushDevicesSectionAction = (
    devices?: string[],
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_DEVICES,
        devices
    });
};

export const pushOverrideAccountSectionAction = (
    overrideAccounts?: string[],
    verboseLoggingEnabled?: boolean,
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_OVERRIDE_ACCOUNTS,
        overrideAccounts,
        verboseLoggingEnabled
    });
};

export const pushOverrideFiltersSectionAction = (
    overrideSelectiveFilters?: ExperienceFilterType[],
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_OVERRIDE_FILTERS,
        overrideSelectiveFilters
    });
};

export const pushDomainTagsSectionAction = (
    contextualSegments?: IContextualSegments,
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_DOMAIN_TAGS,
        contextualSegments
    });
};

export const pushRemoteTagsSectionAction = (
    remoteContextualSegments?: IRemoteContextualSegments,
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_REMOTE_TAGS,
        remoteContextualSegments
    });
};

export const pushFeatureHintsSectionAction = (
    featureHints?: string[],
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_FEATURE_HINT,
        featureHints
    });
};

export const pushBullseyeSectionAction = (
    bullseye?: number,
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_BULLSEYE,
        bullseye
    });
};

export const pushOpportunityTriggerInclusionSectionAction = (
    opportunityTriggerInclusions?: IOpportunityTrigger[],
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_OPPORTUNITY_INCLUSION,
        opportunityTriggerInclusions
    });
};

export const pushOpportunityTriggerExclusionSectionAction = (
    opportunityTriggerExclusions?: IOpportunityTrigger[],
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_OPPORTUNITY_EXCLUSION,
        opportunityTriggerExclusions
    });
};

export const pushSpeakerData = (
    userRole?: UserRole,
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_SPEAKER_DATA,
        speakerData: {
            userRole
        }
    });
};

export const pushVisualInterstitialCards = (
    visualInterstitialCards?: IManagementCard[],
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_VISUAL_INTERSTITIAL_VARIABLES,
        visualInterstitialCards
    });
};

export const pushEnableVoiceCif = (
    enableVoiceCif?: boolean,
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_ENABLE_VOICE_CIF,
        enableVoiceCif
    });
};

export const pushIncludeOdysseyFields = (
    includingOdysseyFields?: boolean,
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_INCLUDING_ODYSSEY_FIELDS,
        includingOdysseyFields
    });
};

export const isCardConfigurationIncomplete = (
    shouldDisableSave?: boolean,
) => async (dispatch: Dispatch) => {
    dispatch({
        type: DISABLING_SAVE_BUTTON,
        shouldDisableSave
    });
};

export const pushNluOpportunityTargetingInclusionSectionAction = (
    nluOpportunityTargetingInclusions?: INluOpportunityTargeting[],
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_NLU_OPPORTUNITY_INCLUSION,
        nluOpportunityTargetingInclusions
    });
};

export const pushNluOpportunityTargetingExclusionSectionAction = (
    nluOpportunityTargetingExclusions?: INluOpportunityTargeting[],
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_NLU_OPPORTUNITY_EXCLUSION,
        nluOpportunityTargetingExclusions
    });
};

export const pushNotificationContentSectionAction = (
    notificationContent: INotificationExperienceContent
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_NOTIFICATION_CONTENT,
        notificationContent
    });
};

export const pushDataSourceSectionAction = (
    dataSource: IDataSourceViewAttributes
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_DATA_SOURCE,
        dataSource
    });
};

export const pushLaunchTreatmentPercentage = (
    launchTreatmentPercentage?: number
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_EXPOSURE_CONTROL_RATE,
        launchTreatmentPercentage
    });
};

export const pushApprovalInfo = (
    approvalInfo?: IApprovalMetadata
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_APPROVAL_INFO,
        approvalInfo
    });
};

export const pushByorSnsTopicArn = (
    snsTopicBYORDataSource: string
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_BYOR_SNS_TOPIC_ARN,
        snsTopicBYORDataSource
    });
};

export const pushJourneyMetadata = (
    journeyMetadata?: IJourneyMetadata
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_JOURNEY_METADATA,
        journeyMetadata
    });
};

export const pushBusinessInfo = (
    vertical?: string,
    domain?: string
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_BUSINESS_INFO,
        businessInfo: { vertical, domain }
    });
};

export const pushSubscriptionUpsellType = (
    subscriptionUpsellType?: SubscriptionUpsellType
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_SUBSCRIPTION_UPSELL_TYPE,
        subscriptionUpsellType
    });
};

export const pushUseTouchActionTemplate = (
    useTouchActionTemplate?: boolean,
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_USE_TOUCH_ACTION_TEMPLATE,
        useTouchActionTemplate
    });
};

export const pushUseNonCTATemplate = (
    useNonCTATemplate?: boolean,
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_USE_NON_CTA_TEMPLATE,
        useNonCTATemplate
    });
};

export const pushCustomDomainIllustrationSelection = (
    domainIllustrationCustomSource?: boolean[],
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_CUSTOM_DOMAIN_ILLUSTRATION_SOURCE,
        domainIllustrationCustomSource
    });
};

export const pushOverrideBackgroundImage = (
    overrideBackgroundImage?: boolean[],
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_OVERRIDE_DEFAULT_BACKGROUND_IMAGE,
        overrideBackgroundImage
    });
};

export const pushAcknowledgeBackgroundImage = (
    acknowledgeBackgroundImage?: boolean[],
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_ACKNOWLEDGE_BACKGROUND_IMAGE,
        acknowledgeBackgroundImage
    });
};

// mobile home card
export const pushMobileHomeCardAction = (
    mobileHomeCardActions?: IMobileHomeCardAction[]
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_MOBILE_HOME_CARD_ACTION,
        mobileHomeCardActions
    });
};

export const pushMobileHomeCardConstraint = (
    mobileHomeCardConstraint?: IMobileHomeCardConstraint
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_MOBILE_HOME_CARD_CONSTRAINT,
        mobileHomeCardConstraint
    });
};

export const pushMobileHomeCardAbExperiment = (
    mobileHomeCardAbExperiment?: IMobileHomeCardAbExperiment
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_MOBILE_HOME_CARD_AB_EXPERIMENT,
        mobileHomeCardAbExperiment
    });
};

export const pushMobileHomeCardContent = (
    mobileHomeCardContent?: IMobileHomeCardContent
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_MOBILE_HOME_CARD_CONTENT,
        mobileHomeCardContent
    });
};

export const pushMobileHomeCardTargeting = (
    mobileHomeCardTargeting?: IMobileHomeCardTargeting
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_MOBILE_HOME_CARD_TARGETING,
        mobileHomeCardTargeting
    });
};

export const pushMobileHomeCardContext = (
    mobileHomeCardContext?: IMobileHomeCardContext
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_MOBILE_HOME_CARD_CONTEXT,
        mobileHomeCardContext
    });
};

// RCP
export const pushRotatingContentPanelDisplayElements = (
    textWrappingDataSource: TextWrappingDataSource
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_ROTATING_CONTENT_PANEL_DISPLAY_ELEMENTS,
        rotatingContentPanelContent: { textWrappingDataSource }
    });
};

export const pushRotatingContentPanelTouchActions = (
    touchAction: IRCPTouchAction
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_ROTATING_CONTENT_PANEL_TOUCH_ACTIONS,
        rotatingContentPanelContent: { touchActions: [touchAction] }
    });
};

export const pushRotatingContentPanelOverrideAccounts = (
    overrideAccounts: IRotatingContentPanelTestingConstraint
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_ROTATING_CONTENT_PANEL_OVERRIDE_ACCOUNTS,
        rotatingContentPanelTestingConstraint: { ...overrideAccounts }
    });
};

export const pushRotatingContentPanelMetaData = (
    rcpMetaData: RCPMetadata
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_ROTATING_CONTENT_PANEL_METADATA,
        rotatingContentPanelContent: { rcpMetaData }
    });
};

export const pushRotatingContentPanelBusinessInfo = (
    businessInfo: IRotatingContentPanelBusinessContext
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_ROTATING_CONTENT_PANEL_BUSINESS_INFO,
        rotatingContentPanelBusinessContext: { ...businessInfo }
    });
};

export const pushRotatingContentPanelContext = (
    rcpContext: IRotatingContentPanelContext
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_ROTATING_CONTENT_PANEL_CONTEXT,
        rotatingContentPanelContext: {...rcpContext}
    });
};

export const pushRotatingContentPanelDevicesConstraint = (
    devices: string[]
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_ROTATING_CONTENT_PANEL_DEVICES,
        rotatingContentPanelDevicesConstraint: { deviceTypes: devices }
    });
};

export const pushRotatingContentPanelSchedulingConstraint = (
    schedule: IRotatingContentPanelSchedulingConstraint
) => async (dispatch: Dispatch) => {
    dispatch({
        type: PUSH_ROTATING_CONTENT_PANEL_ACTIVATION_TIME,
        rotatingContentPanelSchedulingConstraint: schedule
    });
};

// odyssey experience management
export const createOdysseyExperienceAction = () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();

    if (state.experienceEditViewState.updateCandidate) {
        try {
            const userAlias = state.navigationViewState.userAlias;

            validateExperienceOnSubmit(state.experienceEditViewState.updateCandidate);
            const experience = state.experienceEditViewState.updateCandidate.transformToOdysseyExperience(userAlias);
            dispatch(updatingExperience());

            const putOdysseyExperienceResponse = await odysseyExperienceManagementGateway.putOdysseyExperience(experience);
            const id = putOdysseyExperienceResponse.id;

            const getOdysseyExperienceResponse = await odysseyExperienceManagementGateway.getOdysseyExperience(id);
            const newExperience = getOdysseyExperienceResponse.experiment;

            setTimeout(() => {
                state.odysseyExperienceDetailViewState.experience = transformOdysseyExperience(newExperience);
                dispatch(updatedExperience(id, true));
                window.location.assign(getLinkableUrlByExperienceType(newExperience.type, newExperience.id));
            }, 2000);
        } catch (error) {
            dispatch(errorUpdatingExperience(error as Error));
            scrollToTopOf('flashbar.update-error');
        }
    } else {
        dispatch(errorUpdatingExperience(new Error('Bad input')));
    }
};

// detail page
export const saveOdysseyExperienceAction = () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    if (state.experienceEditViewState.updateCandidate) {
        try {
            validateExperienceOnSubmit(state.experienceEditViewState.updateCandidate);

            const userAlias = state.navigationViewState.userAlias;
            const experience = state.experienceEditViewState.updateCandidate.transformToOdysseyExperience(userAlias);
            dispatch(updatingExperience());

            const putOdysseyExperienceResponse = await odysseyExperienceManagementGateway.putOdysseyExperience(experience);
            const returnedId = putOdysseyExperienceResponse.id;
            const getOdysseyExperienceResponse = await odysseyExperienceManagementGateway.getOdysseyExperience(returnedId);
            const newExperience = getOdysseyExperienceResponse.experiment;
            setTimeout(() => {
                state.odysseyExperienceDetailViewState.experience = transformOdysseyExperience(newExperience);
                dispatch(updatedExperience(returnedId));
                window.location.assign(getLinkableUrlByExperienceType(newExperience.type, newExperience.id));
            }, 2000);
        } catch (error) {
            dispatch(errorUpdatingExperience(error as Error));
            scrollToTopOf('odyssey.experience.tabs');
        }
    } else {
        dispatch(errorUpdatingExperience(new Error('Bad input')));
    }
};

export const deleteOdysseyExperienceAction = () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    if (state.odysseyExperienceDetailViewState.experience) {
        const id = state.odysseyExperienceDetailViewState.experience.id;
        const type = state.odysseyExperienceDetailViewState.experience.type;

        try {
            await odysseyExperienceManagementGateway.deleteOdysseyExperience(id);
            dispatch(updatedExperience(id));
            setTimeout(() => {
                window.location.assign(getLinkableUrlByExperienceType(type));
            }, 2000);
        } catch (error) {
            dispatch(errorUpdatingExperience(error as Error));
        }
    } else {
        dispatch(errorUpdatingExperience(new Error('Bad input')));
    }
};

export const cancelOdysseyExperienceAction = (cancelReason: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    if (state.odysseyExperienceDetailViewState.experience) {

        const experience = ExperienceUpdateCandidate.deepClone(state.odysseyExperienceDetailViewState.experience);
        experience.status = 'CANCELED';
        experience.cancellationReason = cancelReason;
        const updateCandidate = new ExperienceUpdateCandidate(ExperienceUpdateCandidate.extractUpdateCandidate(experience));
        const userAlias = state.navigationViewState.userAlias;
        const cancaelExperience = updateCandidate.transformToOdysseyExperience(userAlias);
        dispatch(updatingExperience());

        try {
            const putOdysseyExperienceResponse = await odysseyExperienceManagementGateway.putOdysseyExperience(cancaelExperience);
            const returnId = putOdysseyExperienceResponse.id;
            const getOdysseyExperienceResponse = await odysseyExperienceManagementGateway.getOdysseyExperience(returnId);
            const newExperience = getOdysseyExperienceResponse.experiment;
            setTimeout(() => {
                state.odysseyExperienceDetailViewState.experience = transformOdysseyExperience(newExperience);
                dispatch(updatedExperience(putOdysseyExperienceResponse.id));
                window.location.assign(getLinkableUrlByExperienceType(newExperience.type, newExperience.id));
            }, 2000);
        } catch (error) {
            dispatch(errorUpdatingExperience(error as Error));
        }
    } else {
        dispatch(errorUpdatingExperience(new Error('Bad input')));
    }
};

export const launchOdysseyExperienceAction = () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    if (state.odysseyExperienceDetailViewState.experience) {
        const experience = ExperienceUpdateCandidate.deepClone(state.odysseyExperienceDetailViewState.experience);
        experience.status = 'LAUNCHED';
        const updateCandidate = new ExperienceUpdateCandidate(ExperienceUpdateCandidate.extractUpdateCandidate(experience));
        const userAlias = state.navigationViewState.userAlias;
        updateCandidate.setMobileHomeCardAbExperiment({ launched: true });
        const launchExperience = updateCandidate.transformToOdysseyExperience(userAlias);
        dispatch(updatingExperience());
        try {
            const putOdysseyExperienceResponse = await odysseyExperienceManagementGateway.putOdysseyExperience(launchExperience);
            const returnId = putOdysseyExperienceResponse.id;
            const getOdysseyExperienceResponse = await odysseyExperienceManagementGateway.getOdysseyExperience(returnId);
            const newExperience = getOdysseyExperienceResponse.experiment;
            setTimeout(() => {
                state.odysseyExperienceDetailViewState.experience = transformOdysseyExperience(newExperience);
                dispatch(updatedExperience(putOdysseyExperienceResponse.id));
                window.location.assign(getLinkableUrlByExperienceType(newExperience.type, newExperience.id));
            }, 2000);
        } catch (error) {
            dispatch(errorUpdatingExperience(error as Error));
        }
    } else {
        dispatch(errorUpdatingExperience(new Error('Bad input')));
    }
};

export const sendForReviewOdysseyExperienceAction = () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    if (state.odysseyExperienceDetailViewState.experience) {
        const experience = ExperienceUpdateCandidate.deepClone(state.odysseyExperienceDetailViewState.experience);
        experience.status = 'UNDER_REVIEW';
        const updateCandidate = new ExperienceUpdateCandidate(ExperienceUpdateCandidate.extractUpdateCandidate(experience));
        const userAlias = state.navigationViewState.userAlias;
        const launchExperience = updateCandidate.transformToOdysseyExperience(userAlias);
        dispatch(updatingExperience());
        try {
            const putOdysseyExperienceResponse = await odysseyExperienceManagementGateway.putOdysseyExperience(launchExperience);
            const returnId = putOdysseyExperienceResponse.id;
            const getOdysseyExperienceResponse = await odysseyExperienceManagementGateway.getOdysseyExperience(returnId);
            const newExperience = getOdysseyExperienceResponse.experiment;
            setTimeout(() => {
                state.odysseyExperienceDetailViewState.experience = transformOdysseyExperience(newExperience);
                dispatch(updatedExperience(putOdysseyExperienceResponse.id));
                window.location.assign(getLinkableUrlByExperienceType(newExperience.type, newExperience.id));
            }, 2000);
        } catch (error) {
            dispatch(errorUpdatingExperience(error as Error));
        }
    } else {
        dispatch(errorUpdatingExperience(new Error('Bad input')));
    }
};

export const generateOdysseyExperienceApprovalAction = (simDescription: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    if (state.odysseyExperienceDetailViewState.experience) {
        const experience = ExperienceUpdateCandidate.deepClone(state.odysseyExperienceDetailViewState.experience);
        dispatch(updatingExperience());

        try {
            await odysseyExperienceManagementGateway.generateOdysseyExperimentApproval(experience.id, simDescription);
            const newExperience = await odysseyExperienceManagementGateway.getOdysseyExperience(experience.id);
            setTimeout(() => {
                state.odysseyExperienceDetailViewState.experience = transformOdysseyExperience(newExperience.experiment);
                dispatch(updatedExperience(experience.id));
                window.location.assign(getLinkableUrl(PAGE.MhcExperiences) + '/' + experience.id);
                return experience.id;
            }, 5000);
        } catch (error) {
            dispatch(errorUpdatingExperience(new Error(error)));
        }
    } else {
        dispatch(errorUpdatingExperience(new Error('Bad input')));
    }
};
