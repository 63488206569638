import { getCurrRegionStage, Stage } from '../util/context';
import { getLinkableUrl, PAGE } from './page';

export const ODYSSEY_ENDPOINTS_MAP: { [S in Stage]: string } = {
    Alpha: 'https://alexa-odyssey-beta.integ.amazon.com',
    Beta: 'https://alexa-odyssey-beta.integ.amazon.com',
    Gamma: 'https://alexa-odyssey-gamma.aka.amazon.com',
    Prod: 'https://alexa-odyssey.aka.amazon.com'
};

export const POEMS_ENDPOINTS_MAP: { [S in Stage]: string } = {
    Alpha: 'https://alpha.odyssey.alexa.a2z.com',
    Beta: 'https://beta.odyssey.alexa.a2z.com',
    Gamma: 'https://gamma.odyssey.alexa.a2z.com',
    Prod: 'https://prod.odyssey.alexa.a2z.com'
};

export const getExperienceLink = (experienceId: string) => {
    return `${POEMS_ENDPOINTS_MAP[getCurrRegionStage().stage]}${getLinkableUrl(PAGE.Experiences)}/${experienceId}`;
};

export const getOdysseyExperienceLink = (experienceId: string) => {
    return `${POEMS_ENDPOINTS_MAP[getCurrRegionStage().stage]}${getLinkableUrl(PAGE.MhcExperiences)}/${experienceId}`;
};

