import * as React from 'react';
import { FormField, Checkbox, Select, Input, ColumnLayout, FormSection } from '@amzn/awsui-components-react';
import { IFlattenedExperience } from '../../models/FlattenedExperience';
import { ExperienceUpdateCandidate } from '../../models/ExperienceUpdateCandidate';
import { AppState } from '../../reducers/index';
import { connect } from 'react-redux';
import { RequiredField } from '../../components/common/DescriptionAnnotations';
import { LinkedMhcWeblabGuideline } from '../../components/common/LinksRenderHelper';
import { IMobileHomeCardAbExperiment, AbExperimentCategory, AbExperimentGroup } from '../../models/mobileHomeCard/MobileHomeCardAbExperiment';
import { pushMobileHomeCardAbExperiment } from '../../actions/experienceEditViewActions';
import { getRole } from '../../util/stringAndMappingHelper';
import { extractMobileHomeCardAbExperiment } from '../../util/mobileHomeCardHelper';

interface IMobileHomeCardAbExperimentSectionViewProps {
    dispatch: any;

    isEditing: boolean;
    isUpdating: boolean;
    experience?: IFlattenedExperience;
    updateCandidate?: ExperienceUpdateCandidate;
}

export const ExperimentCategory = [
    {
        'label': 'Single Treatment',
        'id': 'TreatmentShow',
        'description': 'For launching an experience'
    },
    {
        'label': 'Multiple Treatments',
        'id': 'CXVersion',
        'description': 'For A/B testing multiple versions'
    }
] as Select.Option[];


export const ExperimentCX = [
    {
        'label': 'Control',
        'id': 'C',
    },
    {
        'label': 'Treatment1',
        'id': 'T1',
    },
    {
        'label': 'Treatment2',
        'id': 'T2',
    },
    {
        'label': 'Treatment3',
        'id': 'T3',
    },
] as Select.Option[];


export class MobileHomeCardAbExperimentSectionView extends React.Component<IMobileHomeCardAbExperimentSectionViewProps> {

    updateMobileCardAbExperiment(mobileHomeCardAbExperiment?: IMobileHomeCardAbExperiment) {
        const { dispatch } = this.props;
        dispatch(pushMobileHomeCardAbExperiment(mobileHomeCardAbExperiment));

    }

    render() {
        const { experience, isEditing, isUpdating, updateCandidate } = this.props;

        const mobileCardAbExperiment: IMobileHomeCardAbExperiment | undefined = (isEditing && updateCandidate)
            ? updateCandidate.getMobileHomeCardAbExperiment()
            : extractMobileHomeCardAbExperiment(experience);

        const shouldDisableInput = !isEditing || isUpdating;

        return <FormSection
            header={'Configure Experiment'}
            description={<span> Enter your weblab details for launch. For A/B testing refer to this <LinkedMhcWeblabGuideline />.</span>}>
            <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root='true' style={{ marginBottom: '10px' }}>
                    <FormField label={<RequiredField fieldName='Weblab' />} description='The weblab name associated with this experience. Weblab is required to launch' >
                        <Input
                            id='experience.weblab'
                            controlId='experience.weblab'
                            readonly={shouldDisableInput}
                            placeholder='ex. ALEXA_MHC_REMINDERS_618558'
                            value={mobileCardAbExperiment?.weblabName}
                            onChange={e => {
                                this.updateMobileCardAbExperiment({ ...mobileCardAbExperiment, weblabName: e.detail.value.trim() });
                            }} />
                    </FormField>

                    <FormField label={<RequiredField fieldName='Experiment Category' />} description='Select your experiment type' >
                        <Select
                            id='experience.abExperimentCategory'
                            controlId='experience.abExperimentCategory'
                            options={ExperimentCategory}
                            disabled={shouldDisableInput}
                            placeholder={'Single Treatment'}
                            selectedId={mobileCardAbExperiment?.abExperimentCategory}
                            onChange={e => {
                                this.updateMobileCardAbExperiment({ ...mobileCardAbExperiment, abExperimentCategory: e.detail.selectedId as AbExperimentCategory });
                            }} />

                    </FormField>

                    {mobileCardAbExperiment && mobileCardAbExperiment.abExperimentCategory === 'CXVersion' &&
                        <FormField label={<RequiredField fieldName='Experiment Treatment' />}>
                            <Select
                                id='experience.treatment'
                                controlId='experience.treatment'
                                options={ExperimentCX}
                                disabled={shouldDisableInput}
                                placeholder={'T1'}
                                selectedId={mobileCardAbExperiment?.abExperimentGroup}
                                onChange={e => {
                                    this.updateMobileCardAbExperiment({ ...mobileCardAbExperiment, abExperimentGroup: e.detail.selectedId as AbExperimentGroup });
                                }} />
                        </FormField>
                    }
                </div>

                {(getRole(experience?.permissionRole) === 'ADMIN' || getRole(experience?.permissionRole) === 'MHC_ADMIN') &&
                    <Checkbox
                        id='experience.launch-experience'
                        controlId='experience.launch-experience'
                        checked={mobileCardAbExperiment?.launched || false}
                        disabled={true}
                        className='awsui-util-mt-m awsui-util-mb-xl'
                        onChange={(e) => {
                            this.updateMobileCardAbExperiment({ ...mobileCardAbExperiment, launched: e.detail.checked });
                        }}>
                        Launched
                    </Checkbox>}
            </ColumnLayout>
        </FormSection>;
    }
}

const mapStateToProps = ({ odysseyExperienceDetailViewState, experienceEditViewState }: AppState) => {
    return {
        // DO NOT set default value using value || false.
        // false is false-y, will default to default value instead.
        // isEditing and isUpdating have default value set in reducer,
        // so no default value needed
        isEditing: experienceEditViewState.isEditing,
        isUpdating: experienceEditViewState.isUpdating,
        experience: odysseyExperienceDetailViewState.experience || undefined,
        updateCandidate: experienceEditViewState.updateCandidate
    };
};

export default connect(mapStateToProps)(MobileHomeCardAbExperimentSectionView);
