import React from 'react';
import { Flashbar } from '@amzn/awsui-components-react';

export interface IFlashbarWrapperProps {
    validate: () => void;
}

export class FlashbarWrapper extends React.Component<IFlashbarWrapperProps> {
    render () {
        const { validate } = this.props;
        let errorMessage= '';
        try {
            validate();
        } catch (error) {
            errorMessage = error.message;
        }

        return <div style={{ marginBottom: '10px' }}>
            {errorMessage && <Flashbar
                items={[{
                    content: errorMessage,
                    type: 'error'
                }]} />}
        </div>;
    }
}
