import * as React from 'react';
import { Table, TableSorting, Button, TableFiltering, TablePagination, TablePreferences, TablePageSizeSelector, ColumnLayout } from '@amzn/awsui-components-react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers';
import { COLUMN_DEFINITIONS, SORTABLE_COLUMNS } from '../../../constants/v2/providerConfigsTableColumnConfiguration';
import { NoMatchDisplay } from '../../NoMatchDisplay';
import { getAllProviderConfigsAction } from '../../../actions/spiProviderOnboardingActions';
import { getLinkableUrl } from '../../../constants/page';

const ProviderConfigsTable: React.FC = () => {
    const dispatch = useDispatch();
    const { isLoading, providerConfigs } = useSelector((state: AppState) => state.spiProviderOnboardingState);
    const currentLocation = useLocation();
    const shouldStopRefresh : boolean = !(Object.entries(providerConfigs).length === 0);

    React.useEffect(() => {
        if (!shouldStopRefresh) {
                dispatch(getAllProviderConfigsAction());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocation.pathname, dispatch]);

    const onNewProviderClick = () => {
        window.location.assign(getLinkableUrl('/admin/spiProviderOnboarding/createNewProvider'));
    };

    const sortedProviderConfigs = Object.values(providerConfigs).sort((a, b) => {
        const aKey = Object.keys(providerConfigs).find(key => providerConfigs[key] === a) || '';
        const bKey = Object.keys(providerConfigs).find(key => providerConfigs[key] === b) || '';
        return aKey.localeCompare(bKey);
      });

    return (
        <Table
            id='table.spiProviders'
            header={
            <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root='true'>
                <div className='awsui-util-t-l'>
                    <h2>Provider Configurations</h2>
                </div>
                    <div className='awsui-util-t-r'>
                        <Button
                            id='button.newProvider'
                            icon='add-plus'
                            iconAlign='right'
                            onClick={onNewProviderClick}
                            >Onboard New Provider
                        </Button>
                    </div>
                </div>
            </ColumnLayout>}
            columnDefinitions={COLUMN_DEFINITIONS}
            items={sortedProviderConfigs}
            loadingText='Loading Providers'
            loading={isLoading}
            empty={
                <div className='awsui-util-pt-xs awsui-util-mb-xs'>
                <b>
                    No Providers
                </b>
            </div>
            }
            noMatch={<NoMatchDisplay />}
            resizableColumns={true}
            stickyHeader={false}
            wrapLines={false}
        >
            <TableFiltering
                filteringPlaceholder='Find Provider'
                filteringCountTextFunction={count => `${count} ${count === 1 ? 'match' : 'matches'}`}
            />
            <TableSorting
                sortableColumns={SORTABLE_COLUMNS}
            />
            <TablePagination pageSize={40} />
            <TablePreferences
                title='Preferences'
                confirmLabel='Confirm'
                cancelLabel='Cancel'
            >
                <TablePageSizeSelector
                    title='Page size'
                    options={[
                        { value: 40, label: '40 items' },
                        { value: 60, label: '60 items' },
                        { value: 80, label: '80 items' },
                        { value: 100, label: '100 items' }
                    ]}
                />
            </TablePreferences>
        </Table>
    );
};

export default ProviderConfigsTable;





