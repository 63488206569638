import React from 'react';
import { Alert } from '@amzn/awsui-components-react';
import { ExperienceStatus } from '../../models/Experience';
import { IAlexaLabMetrics } from '../../models/AlexaLabMetrics';

interface AlertContent {
    type: Alert.Type;
    content: React.ReactNode;
}

const statusAlertContentMap: { [status in ExperienceStatus]?: { withMetrics?: AlertContent, withoutMetrics: AlertContent } } = {
    CANCELED: {
        withMetrics: {
            type: 'warning',
            content: <div id='metrics.alert-content'>
                This experience has been <strong>CANCELED</strong>.
                The data seen here is the last snapshot of data collected in this experience.
            </div>,
        },
        withoutMetrics: {
            type: 'info',
            content: <div id='metrics.alert-content'>
                This experience has been <strong>CANCELED</strong>.
                You may clone this experience and send it for approval in order to generate metrics for this experience.
            </div>,
        },
    },
    DRAFT: {
        withMetrics: {
            type: 'warning',
            content: <div id='metrics.alert-content'>
                This experience has been pushed back to <strong>DRAFT</strong>.
                The data seen here is the last snapshot of data collected in this experience.
            </div>,
        },
        withoutMetrics: {
            type: 'info',
            content: <div id='metrics.alert-content'>
                This experience is in <strong>DRAFT</strong> and has not been approved yet.
                Please check back here after this experience has been <strong>LIVE</strong> for at least 7 days.
            </div>,
        },
    },
    DISABLED: {
        withMetrics: {
            type: 'warning',
            content: <div id='metrics.alert-content'>
                This experience has been <strong>DISABLED</strong>.
                The data seen here is the last snapshot of data collected in this experience.
            </div>,
        },
        withoutMetrics: {
            type: 'info',
            content: <div id='metrics.alert-content'>
                This experience has been <strong>DISABLED</strong>.
                No data has been collected.
            </div>,
        },
    },
    EXPIRED: {
        withMetrics: {
            type: 'warning',
            content: <div id='metrics.alert-content'>
                This experience has passed the experience activation end date and is now <strong>EXPIRED</strong>.
                The data seen here is the last snapshot of data collected in this experience.
            </div>,
        },
        withoutMetrics: {
            type: 'info',
            content: <div id='metrics.alert-content'>
                This experience has passed the experience activation end date and is now <strong>EXPIRED</strong>.
                It did not run for at least 7 days to generate metrics.
            </div>,
        },
    },
    LIVE: {
        withoutMetrics: {
            type: 'warning',
            content: <div id='metrics.alert-content'>
                This experience has not been running long enough to generate metrics.
                Please check back here after experience has been <strong>LIVE</strong> for at least 7 days.
            </div>,
        },
    },
    PARTIALLY_LIVE: {
        withoutMetrics: {
            type: 'warning',
            content: <div id='metrics.alert-content'>
                This experience has not been running long enough to generate metrics.
                Please check back here after experience has been <strong>LIVE</strong> for at least 7 days.
            </div>,
        },
    },
    SCHEDULED: {
        withoutMetrics: {
            type: 'warning',
            content: <div id='metrics.alert-content'>
                This experience has not been running long enough to generate metrics.
                Please check back here after experience has been <strong>LIVE</strong> for at least 7 days.
            </div>,
        },
    },
    SCHEDULING: {
        withoutMetrics: {
            type: 'warning',
            content: <div id='metrics.alert-content'>
                This experience has not been running long enough to generate metrics.
                Please check back here after experience has been <strong>LIVE</strong> for at least 7 days.
            </div>,
        },
    },
    TESTABLE: {
        withMetrics: {
            type: 'warning',
            content: <div id='metrics.alert-content'>
                This experience has been pushed back to <strong>TESTABLE</strong>.
                The data seen here is the last snapshot of data collected in this experience.
            </div>,
        },
        withoutMetrics: {
            type: 'info',
            content: <div id='metrics.alert-content'>
                This experience has not been approved yet.
                Click on the AlexaLab metrics configuration link to verify you've configured your metrics correctly before you send for approval.
            </div>,
        },
    },
    UNDER_REVIEW: {
        withMetrics: {
            type: 'warning',
            content: <div id='metrics.alert-content'>
                This experience has been pushed back to <strong>UNDER_REVIEW</strong>.
                The data seen here is the last snapshot of data collected in this experience.
            </div>,
        },
        withoutMetrics: {
            type: 'info',
            content: <div id='metrics.alert-content'>
                This experience has not been approved yet. Please check back here after this experience has been <strong>LIVE</strong> for at least 7 days.
            </div>,
        },
    },
};

export const ExperienceMetricsAlert = ({ experienceStatus, alexaLabMetrics }: { experienceStatus: ExperienceStatus, alexaLabMetrics?: IAlexaLabMetrics }) => {
    const alertContent = statusAlertContentMap[experienceStatus];

    if (alexaLabMetrics) {
        if (alertContent && alertContent.withMetrics) {
            return <Alert
                id='metrics.alert'
                type={alertContent.withMetrics.type}
                content={alertContent.withMetrics.content}
            />;
        }
    } else {
        if (alertContent) {
            return <Alert
                id='metrics.alert'
                type={alertContent.withoutMetrics.type}
                content={alertContent.withoutMetrics.content}
            />;
        }
    }

    return null;
};
