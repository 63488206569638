import { IHomeCardSuggestion } from '../models/HomeCardSuggestion';
import { ILoadSuggestionAction } from '../actions/homeCardSuggestionsDetailViewActions';
import { ERROR_LOADING_SUGGESTION_DETAIL, LOADED_SUGGESTION_DETAIL, LOADING_SUGGESTION_DETAIL } from '../constants/homeCardSuggestionsDetailViewActionTypes';

export interface ISuggestionsDetailViewState {
    isLoading: boolean;
    suggestion?: IHomeCardSuggestion;
    error?: Error;
}

export const homeCardSuggestionsDetailViewReducer = (
    state: ISuggestionsDetailViewState = { isLoading: false },
    action: ILoadSuggestionAction
): ISuggestionsDetailViewState => {
    switch (action.type) {
        case LOADING_SUGGESTION_DETAIL:
            return {
                ...state,
                isLoading: true
            };
        case LOADED_SUGGESTION_DETAIL:
            const { suggestion } = action;
            // reducer is at top level. it should resolve error
            // and get it ready for rendering.
            if (!suggestion) {
                return {
                    ...state,
                    isLoading: false,
                    error: new Error('Loaded `undefined` suggestions, something went wrong')
                };
            }
            return {
                ...state,
                isLoading: false,
                suggestion,
                error: undefined
            };
        case ERROR_LOADING_SUGGESTION_DETAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};
