/*
File that defines the region and domain context and associated utility functions of the App
 */

export type Stage = 'Alpha' | 'Beta' | 'Gamma' | 'Prod';
export type Region = 'NA' | 'EU' | 'FE' | 'CN';

// Contains a list of mappings of the following format
// [<domain name prefix>, <Region>, <Stage>]
export const contextMappings: string[][] = [
    ['alpha', 'NA', 'Alpha'],
    ['beta',  'NA', 'Beta'],
    ['gamma', 'NA', 'Gamma'],
    ['prod',  'NA', 'Prod']
];

// Return the region and stage of the current website based on the URL
export const getCurrRegionStage = function (): { region: Region; stage: Stage; } {
    const host = window.location.host;
    for (const context of contextMappings) {
        if (host.includes(context[0])) {
            return {
                region: context[1] as Region,
                stage: context[2] as Stage,
            };
        }
    }

    return {
        region: 'NA',
        // defaults to beta to have more experiences to play with
        stage: 'Beta',
    };
};
